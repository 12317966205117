import * as React from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIncon from "@material-ui/icons/Close";
import memoizeOne from "memoize-one";
import styled from "@emotion/styled";
import triggerEvent from "@app/utils/events";
import { connect } from "react-redux";
import { displayUniSubscriptionPopupAction } from "@app/actions/temp";
import { extractColorsFromGradient } from "./Admins/AdminUniEditPage/UniBackgroundType";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import {
	MiniPopupTypes,
	PopupRejectionEnum,
} from "@app/api/users/helper-schemas";
import { rejectMiniPopup } from "@app/actions/user";
import { subscribeUni } from "@app/actions/subscribed-unis";
import { UniLogo } from "./Universities/FreeUniversityPage";
import { withRouter } from "react-router-dom";
import { getLocale } from "@app/hooks/intl";

interface IOwnProps {
	history?: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDipatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	uni_id?: number;
	clickSubscribeButton: boolean;
}

class SubscriptionPopup extends React.Component<IProps, IState> {
	state: IState = { clickSubscribeButton: false };

	popupUniId = memoizeOne((uni_id?: number) => {
		setTimeout(() => {
			this.setState({
				uni_id,
				clickSubscribeButton: false,
			});
			if (uni_id) {
				triggerEvent({
					category: "Popup",
					action: "Uni Subscription MiniPopup",
					label: "display",
				});
			}
		}, 0);
		return uni_id;
	});

	getUni = memoizeOne((uni_id: number) => {
		return this.props.unis!.find(uni => uni.id === uni_id);
	});

	closePopup = () => {
		triggerEvent({
			category: "Popup",
			action: "Hide uni subscription",
			label: "Close one uni",
			value: this.state.uni_id!,
		});
		this.props.rejectMiniPopup(
			{
				rejection: PopupRejectionEnum.untilLogout,
				type: MiniPopupTypes.UniSubscription,
				uni_id: this.state.uni_id,
			},
			false
		);
		this.props.displayUniSubscriptionPopupAction(undefined);
	};

	gotoUni = (uni: IRUniversity) => (e: React.MouseEvent<any>) => {
		this.props.history!.push("/unis/" + uni.urlName);
	};

	onSubscribe = () => {
		const uni_id = this.state.uni_id!;
		this.setState({
			clickSubscribeButton: true,
		});
		this.props.subscribeUni(uni_id);
		setTimeout(this.closePopup, 700);
		triggerEvent({
			category: "Popup",
			action: "Subscribe uni",
			label: "From mini-popup",
			value: uni_id,
		});
	};

	rejectPopup = (allUnis = false) => {
		this.props.displayUniSubscriptionPopupAction(undefined);
		triggerEvent({
			category: "Popup",
			action: "Hide uni subscription",
			label: allUnis ? "Reject all unis" : "Reject one uni",
			value: allUnis ? undefined : this.state.uni_id!,
		});
		this.setState({
			uni_id: undefined,
		});
		this.props.rejectMiniPopup({
			rejection: PopupRejectionEnum.forever,
			type: MiniPopupTypes.UniSubscription,
			uni_id: allUnis ? null : this.state.uni_id,
		});
	};

	isAlreadyRejected = () => {
		if (!this.state.uni_id) return false;
		const { uni_id } = this.state;
		if (!this.props.miniPopupRejections) return false;
		return this.props.miniPopupRejections.some(
			e => !e.uni_id || e.uni_id === uni_id
		);
	};

	render() {
		const locale = getLocale();
		if (locale !== "ka") return null;
		this.popupUniId(this.props.subscriptionPopupUniId);
		if (!this.props.unis || !this.props.subscribedUnis) return null;
		if (!this.state.uni_id) return null;
		const uni = this.getUni(this.state.uni_id);
		if (!uni) return null;

		const subscribed = !!this.props.subscribedUnis[uni.id];
		if (!this.state.clickSubscribeButton && subscribed) return null; // already subscribed. no need to display popup

		if (this.isAlreadyRejected()) return null;

		const gradientColors = extractColorsFromGradient(
			uni.coverBGColor || ""
		);
		const accentColor = !gradientColors
			? "#e25371"
			: gradientColors[0] || "#e25371";

		return (
			<SubscriptionPopupContainer>
				<CloseIncon
					onClick={this.closePopup}
					style={{
						float: "right",
						cursor: "pointer",
					}}
				/>
				<UniLogo
					logoUrl={uni.logoUrl}
					logoBGColor={uni.logoBGColor}
					style={{
						width: 120,
						height: 120,
						cursor: "pointer",
						float: "left",
					}}
					onClick={this.gotoUni(uni)}
				/>
				<div
					style={{
						fontSize: 21,
						marginTop: 34,
						textAlign: "center",
					}}
				>
					გსურთ, გამოიწეროთ უნივერსიტეტის სიახლეები?
				</div>
				<div style={{ clear: "both" }} />
				{!subscribed && (
					<Button bg={accentColor} onClick={this.onSubscribe}>
						გამოწერა
					</Button>
				)}
				{subscribed && (
					<Button bg={accentColor}>
						გამოწერილია <CheckIcon />
					</Button>
				)}
				<RejectionText onClick={() => this.rejectPopup()}>
					აღარ შემეკითხო ამ უნივერსიტეტზე
				</RejectionText>
				<RejectionText onClick={() => this.rejectPopup(true)}>
					აღარ შემეკითხო არასდროს
				</RejectionText>
			</SubscriptionPopupContainer>
		);
	}
}

const SubscriptionPopupContainer = styled("div")({
	position: "fixed",
	top: 90,
	right: 20,
	maxWidth: "100%",
	zIndex: 100,
	boxShadow: "0 0 10px rgba(0,0,0,0.2)",
	borderRadius: 7,
	backgroundColor: "white",
	padding: 10,
	minWidth: 250,
	fontFamily: "RobotoBold",
	"@media (max-width: 550px)": {
		left: 20,
	},
});

const Button = styled("div")(
	{
		display: "block",
		color: "white",
		borderRadius: 7,
		boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
		textAlign: "center",
		padding: 9,
		fontSize: 21,
		cursor: "pointer",
		transition: "0.3s",
		fontFamily: "RobotoBold",
		"&:hover": {
			filter: "saturate(0.8)",
		},
		"span, svg": {
			display: "inline-block",
			verticalAlign: "middle",
		},
		svg: {
			marginTop: -7,
		},
	},
	(props: { bg: string }) => ({
		background: props.bg,
	})
);

const RejectionText = styled("div")({
	fontFamily: "RobotoUpperCase",
	textAlign: "center",
	fontSize: 16,
	marginTop: 9,
	padding: 4,
	cursor: "pointer",
});

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	subscribedUnis: state.subscribedUnis.subscribed,
	subscriptionPopupUniId: state.temp.subscriptionPopupUniId,
	miniPopupRejections: state.user.rejections
		? state.user.rejections.miniPopups
		: undefined,
});

const mapDipatchToProps = {
	subscribeUni,
	displayUniSubscriptionPopupAction,
	rejectMiniPopup,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDipatchToProps as any
)((withRouter(SubscriptionPopup as any) as any) as typeof SubscriptionPopup);
