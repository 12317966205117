import { Requests } from "@app/api";
import {
	IAPUTSavePageView,
	IAPUTSaveRequest,
	IAPUTSaveUserActivity,
	IRGETAllRequestsWithScores,
	RGETAllRequestsWithScoresSchema,
} from "./validators";

const apiPath = "/api/toulator/";
const toulator = {
	getAllRequests: (): Promise<IRGETAllRequestsWithScores> =>
		Requests.send("GET", apiPath + "requests", {}, null, {
			responseSchema: RGETAllRequestsWithScoresSchema,
		}),
	saveRequest: (data: IAPUTSaveRequest): Promise<void> =>
		Requests.send("PUT", apiPath + "request-info", data, null),
	saveUserActivity: (data: IAPUTSaveUserActivity): Promise<void> =>
		Requests.send("PUT", apiPath + "user-activity", data, null),
	savePageView: (data: IAPUTSavePageView): Promise<void> =>
		Requests.send("PUT", apiPath + "page-view", data, null),
};
export default toulator;
