import React, { useState, useEffect } from "react";
import { css } from "emotion";
import KeyBoadArrowUp from "@material-ui/icons/KeyboardArrowUp";

const FixexUpIconCSS = css`
	position: fixed !important;
	// bottom: 150px;
	bottom: 50px;
	right: 50px;
	width: 50px;
	height: 50px;
	color: white;
	background: #e83172cc;
	margin: 5px;
	border-radius: 50px;
	z-index: 99;
	div {
		position: relative;
	}

	svg {
		position: relative;
		width: 30px;
		height: 30px;
		margin: 10px;
	}

	&:hover {
		background: #e83172;
	}
`;

interface IUpIconProps {
	onClick?: () => void;
	containerClassName?: string;
	styles?: React.CSSProperties;
}

export const UpIcon: React.FC<IUpIconProps> = props => {
	const [showUpIcon, setShowUpIcon] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		if (window.scrollY > 100 && !showUpIcon) {
			setShowUpIcon(true);
		}
		if (window.scrollY < 100 && showUpIcon) {
			setShowUpIcon(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<div>
			{showUpIcon && (
				<div
					onClick={props.onClick ? props.onClick : scrollToTop}
					className={
						props.containerClassName
							? props.containerClassName
							: FixexUpIconCSS
					}
					style={props.styles}
				>
					<KeyBoadArrowUp />
				</div>
			)}
		</div>
	);
};
