import * as React from "react";
import api from "@app/api";
import fileDownload from "js-file-download";
import styled from "@emotion/styled";
import SvgLoadingWhite from "../styles/img/LoadingWhite";
import { addLoader } from "@app/commonJavascript";
import { canAccessUni } from "@app/permissions";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRUser, IPrivilegeAllUnis } from "@app/api/users/helper-schemas";
import { getUniIdOfAdmin } from "@app/permissions";
import { loadUniShortStats } from "@app/actions/temp";
import { match } from "react-router";
import "./styles/adminLineChart.min.css";
import "../styles/commonVariables.less";
import Timer from "@material-ui/icons/Timer";
import Mail from "@material-ui/icons/Mail";
import Smartphone from "@material-ui/icons/Smartphone";
import Popup, { PopupContent } from "../Widgets/Popup";

interface IOwnProps {
	history: History;
	userData: IRUser;
	match: match<{ uni_id: string }>;
}
export const StyledDiv = styled("div")({
	border: "1px solid #23263e",
	background: "linear-gradient(to right, #151722, #11121b)",
	borderRadius: 300,
	padding: 10,
	fontSize: 18,
	marginBottom: 20,
});

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IDispatchProps & IStateProps;

interface IState {
	views_verified?: number;
	views_nonverified?: number;
	latori_verified?: number;
	latori_nonverified?: number;
	canAccess: boolean;
	loaded: boolean;
}

class AdminStatsPage extends React.Component<IProps, IState> {
	uni_id = +this.props.match.params.uni_id;
	_isMounted = false;

	state: IState = { canAccess: false, loaded: false };

	constructor(props: IProps) {
		super(props);
		if (
			!canAccessUni(
				+props.match.params.uni_id,
				props.userData.permissions
			)
		) {
			this.props.history.replace("/");
		}
		// Euro Uni
		if (+props.match.params.uni_id === 66) {
			if (!props.userData.permissions!.privilege["allUnis"]) {
				this.props.history.replace("/admin/uni/66/programs");
			}
		}
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.props.uniShortStats && this.props.uniShortStats[this.uni_id]) {
			const stats = this.props.uniShortStats[this.uni_id];
			api.users
				.getActivationStatusForAdmin({
					userId: this.props.userData.id,
				})
				.then(data => {
					this.setState({
						views_verified: stats.views_verified,
						views_nonverified: stats.views_nonverified,
						latori_verified: stats.latori_verified,
						latori_nonverified: stats.latori_nonverified,
						canAccess: data.canAccess,
					});
				});
			return;
		}
		Promise.all([
			api.unis.stats.getViewersCount({ uni_id: this.uni_id }),
			api.unis.stats.getUniAdmissionsCount({ uni_id: this.uni_id }),
			api.users.getActivationStatusForAdmin({
				userId: this.props.userData.id,
			}),
		]).then(([viewsStats, admissionStats, activationStatus]) => {
			if (!this._isMounted) return;
			this.setState({
				views_verified: viewsStats.verifiedCount,
				views_nonverified: viewsStats.nonVerifiedCount,
				latori_verified: admissionStats.verifiedCount,
				latori_nonverified: admissionStats.nonVerifiedCount,
				canAccess: activationStatus.canAccess,
				loaded: true,
			});
		});
		const uniId = getUniIdOfAdmin(this.props.userData.permissions);
		if (uniId !== null && uniId !== "allUnis") {
			api.users.contractRenewal({ uni_id: uniId });
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	onFileRequest = () => {
		const removeLoader = addLoader();
		api.unis.stats
			.getExcelFile({ uni_id: this.uni_id })
			.then(data => {
				removeLoader();
				if (!this._isMounted) return;
				const now = new Date();
				let dateStr = now
					.toLocaleString()
					.replace(",", "")
					.replace(/\//g, "-")
					.replace(/:/g, "-");
				dateStr = dateStr.substr(0, dateStr.length - 3);
				fileDownload(data, `unicatalog - ${dateStr}.xlsx`);
			})
			.catch(e => {
				removeLoader();
				if (!this._isMounted) return;
				alert("დაფიქსირდა შეცდომა");
				console.error(e);
			});
	};

	// updateAll = () => api.programs.updateAll().then(data => console.log(data));

	render() {
		let hasToShowPopup =
			this.state.loaded &&
			!(
				this.props.user &&
				this.props.user.userData &&
				this.props.user.userData.permissions &&
				((this.props.user.userData.permissions
					.privilege as IPrivilegeAllUnis).allUnis ||
					this.state.canAccess)
			);
		if (
			this.props.user.userData &&
			this.props.user.userData.murtskuId === 220541
		) {
			hasToShowPopup = false;
		}
		return (
			<div>
				{hasToShowPopup && <OutDatedUniPopup onClose={() => ""} />}

				<StyledDiv>
					ვერიფიცირებული მნახველი:
					{this.state.views_verified === undefined ? (
						<span>
							{" "}
							<SvgLoadingWhite
								style={{ width: "20px", height: "20px" }}
							/>{" "}
						</span>
					) : (
						this.state.views_verified
					)}
				</StyledDiv>
				<StyledDiv>
					არავერიფიცირებული მნახველი:
					{this.state.views_nonverified === undefined ? (
						<span>
							{" "}
							<SvgLoadingWhite
								style={{ width: "20px", height: "20px" }}
							/>{" "}
						</span>
					) : (
						this.state.views_nonverified
					)}
				</StyledDiv>
				{/* <StyledDiv>
					ვერიფიცირებული ჩამწერი მურწყულატორში:
					{this.state.latori_verified === undefined ? (
						<span>
							{" "}
							<SvgLoadingWhite
								style={{ width: "20px", height: "20px" }}
							/>{" "}
						</span>
					) : (
						this.state.latori_verified
					)}
				</StyledDiv>
				<StyledDiv>
					არავერიფიცირებული ჩამწერი მურწყულატორში:
					{this.state.latori_nonverified === undefined ? (
						<span>
							{" "}
							<SvgLoadingWhite
								style={{ width: "20px", height: "20px" }}
							/>{" "}
						</span>
					) : (
						this.state.latori_nonverified
					)}
				</StyledDiv> */}
				<div style={{ textAlign: "center" }}>
					<button
						className="adminPrimaryButtonWider"
						onClick={this.onFileRequest}
					>
						ბაზის ჩამოტვირთვა
					</button>
				</div>
			</div>
		);
	}
}

const OutDatedUniPopup: React.FC<{ onClose: () => void }> = props => {
	return (
		<Popup onClose={props.onClose}>
			<PopupContent className="popupStyles">
				<div
					style={{
						width: "100%",
						color: "black",
						textAlign: "center",
					}}
				>
					<div>თქვენი ხელშეკრულების ვადა ამოიწურა.</div>

					<Timer
						style={{
							color: "#ee810a",
							width: 100,
							height: 100,
							padding: 10,
						}}
					/>

					<div
						style={{
							background: "#ee810a",
							color: "white",
							textAlign: "left",
							paddingLeft: 50,
							paddingTop: 10,
						}}
					>
						<div style={{ display: "flex" }}>
							<div
								style={{
									flex: 2,
								}}
							>
								ხელშეკრულების გასაგრძელებლად საკონტაქტო პირი:
							</div>
							<div
								style={{
									flex: 1,
								}}
							>
								თამარ არჩვაძე
							</div>
						</div>
						<div style={{ display: "flex" }}>
							<div
								style={{
									flex: 2,
								}}
							>
								<Mail
									style={{
										verticalAlign: "middle",
									}}
								/>
								t_archvadze@murtsku.com
							</div>
							<div
								style={{
									flex: 1,
								}}
							>
								<Smartphone
									style={{
										verticalAlign: "middle",
									}}
								/>
								+995 591 70 21 60
							</div>
						</div>
					</div>
				</div>
			</PopupContent>
		</Popup>
	);
};

const mapStateToProps = (state: IRootState) => ({
	uniShortStats: state.temp.uniShortStats,
	user: state.user,
});

const mapDispatchToProps = {
	loadUniShortStats,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps
)(AdminStatsPage);
