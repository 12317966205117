import { COUNTRIES_LIST } from "@app/commonJavascript";
import { getLocale, useLocale } from "@app/hooks/intl";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { FormattedMessage } from "@app/utils/locale";
import styled from "@emotion/styled";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../actions/user";
import "./en-login-popup.min.css";
import ProfileSVG from "./styles/img/profile-icon.svg";
import Popup, { PopupContent } from "./Widgets/Popup";

interface IOwnProps {
	onClose: () => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

enum ModeTypes {
	View = "View",
	Edit = "Edit",
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const ProfilePopup: React.FC<IProps> = (props: IProps) => {
	const userData = props.user.userData;
	const locale = useLocale();
	const [mode, setMode] = useState(ModeTypes.View);
	const [fullName, setFullName] = useState(
		userData
			? (userData.firstname || "") + " " + (userData.lastname || "")
			: ""
	);
	const [mobile, setMobile] = useState<number>(
		userData ? (userData.mobile ? +userData.mobile : 0) : 0
	);
	const [mail, setMail] = useState(userData ? userData.mail || "" : "");
	const [password1, setPassword1] = useState("");
	const [password2, setPassword2] = useState("");
	if (!userData) return null;
	const country = COUNTRIES_LIST.find(
		country => country.code === userData.country
	);
	console.log(COUNTRIES_LIST);
	const switchToEditMode = () => setMode(ModeTypes.Edit);
	const checkPassword = (password: string): boolean => {
		if (password.length < 8) return false;
		let hasNumber = false;
		let hasLowerCase = false;
		let hasUpperCase = false;
		let hasSymbol = false;
		const symbols = `~\`!@#$%^&*()_-+={[}]|\\:;"'<,>.?/"`;
		for (let i = 0; i < password.length; i++) {
			if (!isNaN(+password[i])) hasNumber = true;
			if (password[i] >= "a" && password[i] <= "z") hasLowerCase = true;
			if (password[i] >= "A" && password[i] <= "Z") hasUpperCase = true;
			if (password[i] >= "A" && password[i] <= "Z") hasUpperCase = true;
			if (symbols.indexOf(password[i]) >= 0) hasSymbol = true;
		}
		return hasNumber && hasLowerCase && hasUpperCase && hasSymbol;
	};
	const onSaveProfile = () => {
		const names = fullName.split(" ").filter(name => !!name);
		if (fullName && names.length < 2) {
			let message = "გთხოვთ, შეიყვანოთ როგორც სახელი, ასევე გვარი";
			if (locale !== "ka") message = "Please enter both name and surname";
			alert(message);
			return;
		}
		if (password1 && locale !== "ka" && !checkPassword(password1)) {
			const message =
				"Choose a unique password with minimum 8 characters. Include at least 1 number, a mix of lowercase and uppercase letters and 1 symbol to make your password strong";
			alert(message);
			return;
		}
		if (password1 !== password2) {
			let message = "პაროლები არ ემთხვევა! სცადეთ ახლიდან";
			if (locale !== "ka") message = "Passwords do not match! Try again";
			alert(message);
			return;
		}
		const updateObj = {};
		if (names[0]) updateObj["firstName"] = names[0];
		if (names[1]) updateObj["lastName"] = names[names.length - 1];
		if (mail) updateObj["mail"] = mail;
		if (mobile) updateObj["mobile"] = mobile;
		if (password1) updateObj["password"] = password1;
		let success, error;
		if (locale === "ka") {
			success = "პროფილი წარმატებით დარედაქტირდა";
			error = "დაფიქსირდა შეცდომა";
		} else {
			success = "Profile was successfully edited";
			error = "Error!";
		}
		props
			.updateProfile(updateObj)
			.then(() => {
				alert(success);
				props.onClose();
			})
			.catch(e => alert(error));
	};

	return (
		<Popup onClose={props.onClose}>
			<PopupContent>
				<PopupContentContainer>
					<CloseIcon className="close-icon" onClick={props.onClose} />
					<img alt="" className="profile-icon" src={ProfileSVG} />
					{mode === ModeTypes.View && (
						<>
							<div className="name">
								{userData.firstname + " " + userData.lastname}
							</div>
							<div className="all-fields-container">
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="authFields.mobile" />
									</div>
									<button className="field">
										{userData.mobile}
									</button>
								</div>
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="authFields.email" />
									</div>
									<button className="field">
										{userData.mail}
									</button>
								</div>
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="authFields.country" />
									</div>
									<button className="field">
										{country ? country.name : ""}
									</button>
								</div>
							</div>
							<br />
							<button
								className="editBttn"
								onClick={switchToEditMode}
							>
								<FormattedMessage id="profile.edit" />
							</button>
						</>
					)}
					{mode === ModeTypes.Edit && (
						<>
							<div className="name">
								{userData.firstname + " " + userData.lastname}
							</div>
							<div className="all-fields-container">
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="authFields.fullName" />
									</div>
									<input
										value={fullName}
										onChange={e =>
											setFullName(e.target.value)
										}
									/>
								</div>
								<br />
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="authFields.mobile" />
									</div>
									<input
										type="number"
										value={mobile || undefined}
										onChange={e =>
											setMobile(+e.target.value)
										}
									/>
								</div>
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="authFields.email" />
									</div>
									<input
										value={mail}
										onChange={e => setMail(e.target.value)}
									/>
								</div>
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="profile.newPassword" />
									</div>
									<input
										type="password"
										autoComplete="new-password"
										value={password1}
										onChange={e =>
											setPassword1(e.target.value)
										}
									/>
								</div>
								<div className="field-container">
									<div className="label">
										<FormattedMessage id="profile.repeatPassword" />
									</div>
									<input
										type="password"
										autoComplete="repeat-password"
										value={password2}
										onChange={e =>
											setPassword2(e.target.value)
										}
									/>
								</div>
								{locale !== "ka" && (
									<div className="password-instructions">
										Choose a unique password with minimum 8
										characters. Include at least 1 number, a
										mix of lowercase and uppercase letters
										and 1 symbol to make your password
										strong
									</div>
								)}
							</div>
							<br />
							<button
								className="editBttn"
								onClick={onSaveProfile}
							>
								<FormattedMessage id="profile.save" />
							</button>
						</>
					)}
				</PopupContentContainer>
			</PopupContent>
		</Popup>
	);
};

const PopupContentContainer = styled.div`
	width: 800px;
	max-width: 100%;
	color: #37273c;
	button,
	input {
		color: #37273c;
	}
	.close-icon {
		float: right;
		cursor: pointer;
		:hover {
			transform: scale(1.1);
			transition-duration: 0.1s;
		}
	}
	.profile-icon {
		width: 115px;
		height: 115px;
	}
	.name {
		font-family: "RobotoUpperCase";
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 23px;
		text-align: center;
		margin: 20px 0px;
	}
	.all-fields-container {
		display: inline-block;
		margin-bottom: 20px;
		width: 532px;
		max-width: 100%;
		text-align: left;
		@media screen and (max-width: 847px) {
			text-align: center;
		}
		.field-container {
			display: inline-block;
			vertical-align: middle;
			margin: 10px 5px;

			.label {
				font-size: 14px;
				font-family: "RobotoLowerCase";
				margin-left: 12px;
				margin-bottom: 10px;
				text-align: left;
			}
			.field {
				width: 256px;
				height: 50px;
				background: #f4f4f4;
				border-radius: 20px;
				font-family: "RobotoUpperCase";
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;
				border: none;
				text-align: left;
				padding: 0px 16px;
				:focus {
					outline: none;
				}
				@media screen and (max-width: 456px) {
					width: 170px;
				}
			}
			input {
				width: 256px;
				height: 50px;
				border-radius: 20px;
				border: 1px solid #37273c;
				font-family: "RobotoUpperCase";
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				text-align: left;
				padding: 0px 16px;
				:focus {
					outline: none;
					border-width: 2px;
				}
				@media screen and (max-width: 456px) {
					width: 170px;
				}
			}
		}
		.password-instructions {
			color: #685370;
			font-size: 12px;
			line-height: 16px;
			font-family: "RobotoLowerCase";
			margin-top: 5px;
			margin-bottom: 10px;
		}
	}
	.editBttn {
		width: 256px;
		height: 50px;
		max-width: 100%;
		background: #ffffff;
		border-radius: 20px;
		border: 1px solid #943ab5;
		font-family: "RobotoUpperCase";
		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		cursor: pointer;
		:hover {
			background: #943ab5;
			color: white;
		}
		:focus {
			outline: none;
		}
	}
`;

const mapStateToProps = (state: IRootState) => ({
	user: state.user,
});

const mapDispatchToProps = {
	updateProfile,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(ProfilePopup);
