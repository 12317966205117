import Joi, { SchemaToType } from "@app/utils/joi";
import { GalleryMediaSchema } from "../helpher-schemas";

// Get news validators
export const AGETNewsByIdSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});
export type IAGETNewsById = SchemaToType<typeof AGETNewsByIdSchema>;

export const RGETNewsByIdSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	text: Joi.string().required(),
	galleryMedias: Joi.array()
		.items(GalleryMediaSchema)
		.allow(null)
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});
export type IRGETNewsById = SchemaToType<typeof RGETNewsByIdSchema>;
export type INews = IRGETNewsById;

// Delete news by id validators
export const ADELETENewsByIdSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IADELETENewsById = SchemaToType<typeof ADELETENewsByIdSchema>;

export const RDELETENewsByIdSchema = Joi.object().keys({
	success: Joi.boolean().required(),
});
export type IRDELETENewsById = SchemaToType<typeof RDELETENewsByIdSchema>;

// Create or save news validators
export const APUTNewsSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.optional(),
	uni_id: Joi.number()
		.integer()
		.required(),
	newsSettings: Joi.object()
		.keys({
			text: Joi.string().required(),
			galleryMedias: Joi.array()
				.items(GalleryMediaSchema)
				.allow(null)
				.required(),
		})
		.required(),
});
export type IAPUTNews = SchemaToType<typeof APUTNewsSchema>;

export const RPUTNewsSchema = Joi.object().keys({
	success: Joi.boolean().required(),
	id: Joi.number()
		.integer()
		.required(),
});
export type IRPUTNews = SchemaToType<typeof RPUTNewsSchema>;

// Get news by uni id validator
export const AGETNewsByUniIdSchema = Joi.object()
	.keys({
		uni_id: Joi.number()
			.integer()
			.required(),
	})
	.required();
export type IAGETNewsByUniId = SchemaToType<typeof AGETNewsByUniIdSchema>;

export const RGETNewsByUniIdSchema = Joi.array()
	.items(RGETNewsByIdSchema)
	.required();
export type IRGETNewsByUniId = SchemaToType<typeof RGETNewsByUniIdSchema>;

// News image upload validator
export const APOSTNewsImagesSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAPOSTNewsImages = SchemaToType<typeof APOSTNewsImagesSchema>;

export const RPOSTNewsImagesSchema = Joi.object().keys({
	files: Joi.array()
		.items({
			fileName: Joi.string().required(),
			fileFullUrl: Joi.string().required(),
		})
		.required(),
});
export type IRPOSTNewsImages = SchemaToType<typeof RPOSTNewsImagesSchema>;
