import React from "react";
import { History } from "history";
import OffersBackground from "../img/offers-background.png";
import LiveMeetingsBackground from "../img/live-meetings-background.png";
import TimetableBackground from "../img/timetable-background.png";

const ExpoMainPageHeader: React.FC<{ history: History }> = React.memo(
	function ExpoMainPageHeader({ history }) {
		return (
			<div className="headerContainer">
				<div className="main main2 headerContent">
					<ExpoHeaderBox
						label={"უნივერსიტეტების\nშეთავაზებები"}
						onClick={() => history.push("/uni-expo/offers")}
						imageUrl={OffersBackground}
					/>
					<ExpoHeaderBox
						label={"LIVE\nჩართვები"}
						onClick={() => history.push("/uni-expo/live")}
						imageUrl={LiveMeetingsBackground}
					/>

					<ExpoHeaderBox
						label="განრიგი"
						onClick={() => history.push("/uni-expo/timetable")}
						imageUrl={TimetableBackground}
					/>
				</div>
			</div>
		);
	}
);

interface ExpoHeaderBoxProps {
	label?: string;
	imageUrl?: string;
	onClick?: () => void;
}

const ExpoHeaderBox: React.FC<ExpoHeaderBoxProps> = React.memo(
	function ExpoHeaderBox({ label, imageUrl, onClick }) {
		return (
			<div
				className="headerBox"
				style={{
					backgroundImage: `url("${imageUrl}")`,
					backgroundPosition: "center",
				}}
				onClick={onClick}
			>
				<span>{label || "სათაური"}</span>
			</div>
		);
	}
);

export default ExpoMainPageHeader;
