import Joi, { SchemaToType } from "@app/utils/joi";
import { GalleryMediaSchema } from "../helpher-schemas";

export const EventSchema = Joi.object().keys({
	id: Joi.number().integer().required(),
	uni_id: Joi.number().integer().required(),
	title: Joi.string().required(),
	text: Joi.string().required(),
	location: Joi.string().allow(null).required(),
	galleryMedias: Joi.array().items(GalleryMediaSchema).allow(null).required(),
	start_time: Joi.date().required(),
	end_time: Joi.date().allow(null).required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export const EventsSchema = Joi.array().items(EventSchema);

export type IEvent = SchemaToType<typeof EventSchema>;
