import Joi, { SchemaToType } from "@app/utils/joi";
import {
	IUniContent,
	UniContentFields,
	UniContentSchema,
} from "../helper-schemas";

export const APUTSaveSchema = Joi.object().keys({
	deletedIds: Joi.array()
		.items(Joi.number().integer())
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	changedInfo: Joi.object()
		.pattern(
			/[\da-z-]+/,
			UniContentSchema.optionalKeys(UniContentFields).keys({
				newlyCreated: Joi.boolean().optional(),
			})
		)
		.required(),
});

export interface IAPUTSave {
	deletedIds: number[];
	uni_id: number;
	changedInfo: Partial<IUniContent> & { newlyCreated?: boolean };
}

export const APOSTUploadPhotosSchema = Joi.object()
	.keys({
		uni_id: Joi.number()
			.integer()
			.required(),
	})
	.required();
export type IAPOSTUploadPhotos = SchemaToType<typeof APOSTUploadPhotosSchema>;

export const RPOSTUploadPhotosSchema = Joi.object()
	.keys({
		files: Joi.array()
			.items(
				Joi.object().keys({
					fileName: Joi.string().required(),
					fileFullUrl: Joi.string().required(),
				})
			)
			.required(),
	})
	.required();
export type IRPOSTUploadPhotos = SchemaToType<typeof RPOSTUploadPhotosSchema>;

export const RPOSTUploadFilesSchema = Joi.object()
	.keys({
		fileNames: Joi.array()
			.items(Joi.string().required())
			.required(),
	})
	.required();
export type IRPOSTUploadFiles = SchemaToType<typeof RPOSTUploadPhotosSchema>;

export const AGETUniContentsByTypeSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.optional(),
	type: Joi.string()
		.valid("PHOTO", "VIDEO", "PDF")
		.required(),
});

export interface IAGETUniContentsByType {
	uni_id?: number;
	type: "PHOTO" | "VIDEO" | "PDF";
}

export const AGETOffersSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.optional(),
});

export interface IAGETOffers {
	uni_id?: number;
}
