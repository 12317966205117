import { getUnisEssentialInfo, IGetUnisEssentialInfo } from "@app/actions/unis";
import api from "@app/api";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { IAGETAdminInfo } from "@app/api/users/helper-schemas";
import { IRootState } from "@app/reducers/root";
import { History } from "history";
import * as React from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { match } from "react-router";
import Switch from "../Widgets/MainSwitch";
import "./styles/adminPrograms.min.css";
import "./styles/allAdminsList.min.css";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	getUnisEssentialInfo: IGetUnisEssentialInfo;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	admins: IAGETAdminInfo[];
	adminActivation: { [userId: number]: { canAccess: boolean } };
	unis: IRUniversity[];
}

class AllAdminsList extends React.Component<IProps, IState> {
	uni_id: number;
	_isMounted: boolean;
	uni?: IRUniversity;

	state = {
		admins: [],
		adminActivation: {},
		unis: [],
	} as IState;

	constructor(props: IProps) {
		super(props);
		this.uni_id = +props.match.params.uni_id;
	}

	componentDidMount() {
		this._isMounted = true;
		api.users.getAllAdmins().then(data => {
			const activatedAdmins: IState["adminActivation"] = {};
			for (const admin of data) {
				activatedAdmins[admin.user.id] = {
					canAccess: !!admin.canAccess,
				};
			}
			if (this.state.unis.length === 0) {
				this.props.getUnisEssentialInfo().then(unis => {
					this.setState({
						admins: data,
						adminActivation: activatedAdmins,
						unis,
					});
				});
			} else {
				this.setState({
					admins: data,
					adminActivation: activatedAdmins,
				});
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onActivateAdmin = (id: number | string) => {
		this.setState({
			adminActivation: {
				...this.state.adminActivation,
				[id]: { canAccess: !this.state.adminActivation[id].canAccess },
			},
		});
	};

	onSave = () => {
		api.users.activateAdmins(this.state.adminActivation).then(data => {
			if (data.success) alert("ოპერაცია წარამტებით განხორციელდა");
			else alert("დაფიქსირდა შეცდომა");
		});
	};

	getUniName = (uniId: number | string): string => {
		const index = this.state.unis.findIndex(el => +el.id === +uniId);
		if (index < 0) return "";
		return this.state.unis[index].name;
	};

	render() {
		if (!this.state.admins) {
			return (
				<ContentLoader width={400} height={50}>
					<rect
						x="135"
						y="28"
						rx="4"
						ry="4"
						width="100"
						height="12"
					/>
				</ContentLoader>
			);
		}
		return (
			<div>
				<div className="main">
					<div className="header">ადმინები</div>
					<table className="adminInfoTable">
						<thead>
							<tr>
								<th>No.</th>
								<th>სახელი</th>
								<th>გვარი</th>
								<th>მეილი</th>
								<th>უნივერსიტეტი</th>
								<th>გააქტიურებული</th>
							</tr>
						</thead>
						<tbody>
							{this.state.admins.map((admin, index) => (
								<tr>
									<td>{index + 1}</td>
									<td>{admin.user.firstname}</td>
									<td>{admin.user.lastname}</td>
									<td>
										{admin.user.mail || "არაა მითითებული"}
									</td>
									<td>
										{!!admin.privilege["allUnis"]
											? "ყველა უნივერსიტეტი"
											: !!admin.privilege["uniId"] &&
											  this.getUniName(
													admin.privilege["uniId"]
											  )}
									</td>
									<td>
										<Switch
											size="small"
											checked={
												!!this.state.adminActivation[
													admin.user.id
												].canAccess
											}
											onClick={() =>
												this.onActivateAdmin(
													admin.user.id
												)
											}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<button
						className="adminPrimaryButton"
						style={{ width: 300 }}
						onClick={this.onSave}
					>
						შენახვა
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

export default connect(mapStateToProps, ({
	getUnisEssentialInfo,
} as unknown) as IDispatchProps)(AllAdminsList);
