import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "./styles/fancyMultipleSelect.min.css";

interface IProps {
	title: string;
	items: Array<{ id: string | number; name: string; [key: string]: any }>;
	defaultValue: null | Array<string | number>;
	onChange?: (itemIds: Array<number | string>) => void;
}

interface IState {
	selected: Array<string | number>;
}

class FancyMultipleSelect extends React.Component<IProps, IState> {
	state = {
		selected: [],
	} as IState;

	constructor(props: IProps) {
		super(props);
		this.state.selected = props.defaultValue || [];
	}

	changeSelect = e => {
		this.setState({
			selected: e.target.value,
		});
		if (this.props.onChange) this.props.onChange(e.target.value);
	};

	renderValues = (selected): string => {
		return selected
			.map(itemId => {
				const thisItem = this.props.items.find(
					item => item.id === itemId
				);
				return thisItem ? thisItem.name : "";
			})
			.join(", ");
	};

	render() {
		return (
			<div className="fancyInputAndTitle">
				<h2>{this.props.title}</h2>
				<div className="fancyMultipleSelectCont">
					<Select
						multiple={true}
						className="fancyMultipleSelect"
						disableUnderline={true}
						value={this.state.selected}
						onChange={this.changeSelect}
						renderValue={this.renderValues}
						classes={{
							icon: "icon",
						}}
					>
						{this.props.items.map(item => (
							<MenuItem key={item.id} value={item.id}>
								<Checkbox
									checked={
										this.state.selected.indexOf(item.id) >
										-1
									}
								/>
								<ListItemText primary={item.name} />
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
		);
	}
}

export default FancyMultipleSelect;
