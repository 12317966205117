import styled from "@emotion/styled";
import * as React from "react";
import Header from "./Header";
import { History } from "history";
import { getLocale } from "@app/hooks/intl";

const Terms: React.FC<{ history: History }> = ({ history }) => (
	<>
		<Header history={history} />
		<Container className="main">
			{getLocale() === "ka" ? (
				<>
					<h1>წესები და პირობები</h1>
					<p>
						წინამდებარე წესები და პირობები წარმოადგენს სავალდებულო
						ძალის მქონე შეთანხმებას unicatalog.ge-სა (შემდგომში{" "}
						<strong>„ უნიკატალოგი“</strong>) და ყველა იმ
						მომხმარებელს (შემდგომში <strong>„მომხმარებელი“</strong>)
						შორის, რომელიც იყენებს წინამდებარე ვებ-გვერდს და მასზე
						არსებულ სერვისებს. წინამდებარე ვებ-გვერდის გამოყენებით{" "}
						<strong>„მომხმარებელი“</strong> ადასტურებს, რომ
						ეთანხმება ამ წესებსა და პირობებს.
					</p>
					<br />
					<h2>მუხლი 1. ვებ-გვერდი და მისი სერვისები</h2>
					<p>
						1.1. unicatalog.ge წარმოადგენს სარეკომენდაციო, დამხმარე
						ვებ-გვერდს აბიტურიენტებისა და სტუდენტებისთვის,
						საქართველოში არსებული უნივერსიტეტებისა და მათი
						პროგრამების შესახებ ინფორმაციის მისაღებად
						<br />
						<br />
						1.2. წინამდებარე ვებ-გვერდის გამოყენებით{" "}
						<strong>„მომხმარებელი“</strong> ადასტურებს, რომ გაეცნო
						წინამდებარე წესებსა და პირობებს, ეთანხმება მათ და ასევე,
						აცხადებს თანხმობას მიიღოს{" "}
						<strong>„უნიკატალოგისგან“</strong> ნოტიფიკაციები,
						სიახლეები, მათ შორის სარეკლამო სიახლეები და სხვ.
					</p>
					<br />
					<h2>
						მუხლი 2. ვებ-გვერდზე რეგისტრაცია და ვებ-გვერდის
						გამოყენება
					</h2>
					<p>
						2.1. წინამდებარე ვებ-გვერდზე რეგისტრაცია შესაძლებელია
						შემდეგი პირების მიერ შემდეგი პირობებით:
						<br />
						<br />
						<Tab>
							2.1.1. არასრულწლოვანს, თავისი სახელით,
							მშობლის/მეურვის თანხმობით, რა შემთხვევაშიც,
							არასრულწლოვანი აცხადებს და გარანტიას იძლევა, რომ
							გააჩნია მშობლის/მეურვის თანხმობა;
							<br />
							<br />
							2.1.2. სრულწლოვანს, თავისი სახელით;
							<br />
							<br />
							2.1.3. არასრულწლოვანის მშობელს/მეურვეს, ამ
							არასრულწლოვნის სახელით;
							<br />
							<br />
							2.1.4. მესამე პირს, არასრულწლოვანის სახელით, ამ
							არასრულწლოვანის მშობლის/მეურვის თანხმობით, ან
							სრულწლოვანის სახელით, ამ სრულწლოვანის თანხმობით, რა
							შემთხვევაშიც, მესამე პირი აცხადებს და გარანტიას
							იძლევა, რომ გააჩნია ამ მუხლში აღნიშნული თანხმობა;
							<br />
							<br />
							2.1.5. წინამდებარე წესებისა და პირობების მხარეს
							წარმოადგენს <strong>„მომხმარებელი“</strong> ან თუკი
							ის არასრულწლოვანია, ამ მომხმარებლის მშობელი/მეურვე;
							<br />
							<br />
						</Tab>
						2.2. წინამდებარე წესებისა და პირობების მიზნებისთვის,
						მშობელი/მეურვე წარმოადგენს პირს, რომელსაც აქვს კანონიერი
						უფლებამოსილება განახორციელოს დარეგისტრირებულ
						არასრულწლოვანზე ზრუნვა, ზედამხედველობა და რომელიც
						უფლებამოსილია წარმოადგენდეს დარეგისტრირებულ
						არასრულწლოვანს გარიგებებში;
						<br />
						<br />
						2.3. ამ მუხლის 2.1. პუნქტის ქვეპუნქტებით
						გათვალისწინებული თითოეული პირი, რომელიც ახორციელებს
						რეგისტრაციას და თითოეული მშობელი/მეურვე თანხმობას
						აცხადებს და გარანტიას იძლევა, რომ დარეგისტრირებული
						არასრულწლოვანი დაიცავს წინამდებარე წესებსა და პირობებს.
						<br />
						<br />
						2.4. <strong>„მომხმარებელი“</strong> პასუხისმგებელია
						მისი სახელით unicatalog.ge-ზე განხორციელებულ ნებისმიერ
						ქმედებაზე;
						<br />
						<br />
						2.5. <strong>
							„მომხმარებელი“
						</strong> პასუხისმგებელია{" "}
						<strong>„უნიკატალოგისთვის“</strong> მიწოდებული
						ინფორმაციის (მათ შორის პერსონალური ინფორმაციის)
						სისწორეზე;
						<br />
						<br />
						2.6. <strong>„მომხმარებელი“</strong> პასუხისმგებელია
						მისი სარეგისტრაციო მონაცემების (მობილური ტელეფონი,
						ელ-ფოსტა, პაროლი და სხვ.) უსაფრთხოდ შენახვაზე;
						<br />
						<br />
						2.7. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						ჩაატაროს unicatalog.ge-ზე ტექნიკური და სხვ. სახის
						სამუშაოები, რა დროსაც unicatalog.ge შეიძლება გახდეს
						სრულად ან ნაწილობრივ ხელმიუწვდომელი.{" "}
						<strong>„უნიკატალოგი“</strong> არ არის ვალდებული
						წინასწარ შეატყობინოს <strong>„მომხმარებელს“</strong>{" "}
						აღნიშნული სამუშაოების გატარების შესახებ.
					</p>
					<br />
					<h2>მუხლი 3. პერსონალური მონაცემების დამუშავება</h2>
					<p>
						3.1. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						დაამუშაოს ყველა ის ინფორმაცია/დოკუმენტაცია საქართველოს
						კანონმდებლობის შესაბამისად, რომელიც{" "}
						<strong>„მომხმარებელმა“</strong>
						გადასცა <strong>„უნიკატალოგს“</strong> რეგისტრაციით,
						ან/და ჩატარებული საგანმანათლებლო პროექტების,
						ღონისძიებების, სატელეფონო ან სხვაგვარი კომუნიკაციის და
						სხვ. ფარგლებში;
						<br />
						<br />
						3.2. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						დაამუშაოს <strong>„მომხმარებლის“</strong> პერსონალური
						მონაცემები „პერსონალურ მონაცემთა დაცვის შესახებ“
						საქართველოს კანონის მე-2 მუხლის „დ“ ქვეპუნქტის
						შესაბამისად, ავტომატური, ნახევრად ავტომატური ან არა
						ავტომატური საშუალებების გამოყენებით შესრულებული
						ნებისმიერი მოქმედებები;
						<br />
						<br />
						3.3. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						დაამუშაოს <strong>„მომხმარებლის“</strong> მიერ
						unicatalog.ge-ზე განხორციელებული ქმედებები, მათ შორის და
						არა მხოლოდ, ინფორმაცია იმ უნივერსიტეტებსა და
						ფაკულტეტებზე, რომლითაც დაინტერესდა{" "}
						<strong>„მომხმარებელი“</strong>, ან/და რომელთაგანაც
						მოითხოვა კონსულტაცია და სხვ.
						<br />
						<br />
						3.4. პერსონალური მონაცემების დამუშავების მიზნებს
						წარმოადგენს:
						<br />
						<br />
						<Tab>
							3.4.1. <strong>„მომხმარებლის“</strong> ინფორმირება
							საგანმანათლებლო სერვისების, სიახლეების, პროექტების,
							ღონისძიებების, საჩუქრების და სხვ. შესახებ;
							<br />
							<br />
							3.4.2. იმ სასწავლო დაწესებულებებისთვის
							<strong>„მომხმარებლის“</strong> ინფორმაციის
							მიწოდება, რომლითაც დაინტერესდა თავად{" "}
							<strong>„მომხმარებელი“</strong> და გამოხატა
							კონსულტაციის ან სხვაგვარი ინფორმაციის მიღების
							სურვილი;
							<br />
							<br />
							3.4.3. პირდაპირი მარკეტინგისა და მარკეტინგული
							კამპანიების ეფექტური დაგეგმვა და განხორციელება;
							<br />
							<br />
							3.4.4. სტატისტიკური მონაცემების წარმოება;
							<br />
							<br />
							3.4.5. ჩვენი პროდუქტების, მომსახურების, ვებგვერდის
							განვითარება;
							<br />
							<br />
							3.4.6. გადახდების განხორციელება და მართვა;
							<br />
							<br />
							3.4.7. ყველა ის კანონით აუკრძალავი მიზანი, რომელიც
							შეესაბამება საგანმანათლებლო პროექტების
							განხორციელებას;
							<br />
							<br />
						</Tab>
						3.5. <strong>„უნიკატალოგი“</strong> პასუხისმგებლობას
						იღებს <strong>„მომხმარებლების“</strong> მიერ გადმოცემული
						პერსონალური ინფორმაციის უსაფრთხოებაზე;
						<br />
						<br />
						3.6. <strong>„უნიკატალოგი“</strong> უფლებამოსილია გასცეს{" "}
						<strong>„მომხმარებლის“</strong> პერსონალური მონაცემები
						კანონმდებლობის მოთხოვნით ან/და სამართლებრივ დავებში
						საკუთარი ინტერესების დასაცავად, ან/და კომპანიის
						რეორგანიზაციის, შერწყმის ან გაყიდვის შედეგად;
						<br />
						<br />
						3.7. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						დაამუშაოს <strong>„მომხმარებლის“</strong> პერსონალური
						მონაცემები მესამე პირთა სასარგებლოდ, კერძოდ, გასცეს
						ან/და გაამჟღავნოს აღნიშნული მონაცემები მესამე პირთა
						მარკეტინგული, სარეკლამო, საინფორმაციო ან სხვა სახის
						კომერციული მიზნებისთვის.
					</p>
					<br />
					<h2>მუხლი 4. საავტორო და ინტელექტუალური უფლებები</h2>
					<p>
						4.1. წინამდებარე ვებ-გვერდი, მისი სერვისები, პროგრამული
						უზრუნველყოფა, რომელიც გამოიყენება წინამდებარე
						ვებ-გვერდისა და მისი სერვისების ექსპლუატაციისთვის, ასევე
						სახელწოდება <strong>„უნიკატალოგი“</strong>, მისი
						ლოგოტიპი, ვებ-გვერდზე გამოქვეყნებული სტატიები (შემდგომში
						ჩამოთვლილი ყველაფერი წოდებული როგორც „ქონთენთი“)
						წარმოადგენს <strong>„უნიკატალოგის“</strong> საკუთრებას
						და დაცულია კანონმდებლობით.
						<br />
						<br />
						4.2. <strong>„მომხმარებელი“</strong> უფლებამოსილია
						გამოიყენოს <strong>„ქონთენთი“</strong> მხოლოდ პირადი
						მოხმარებისთვის.
						<br />
						<br />
						4.3. <strong>„მომხმარებელს“</strong> არ აქვს უფლება
						გაყიდოს, ანაზღაურებადი მიზნებისთვის გამოიყენოს,
						შეცვალოს, გადაამუშავოს, გადათარგმნოს{" "}
						<strong>„ქონთენთი“</strong>, გაავრცელოს მისი ასლები,
						მოაცილოს ბრენდული ნიშანი, ლოგო ან სახელწოდება.
					</p>
					<br />
					<h2>
						მუხლი 5. სხვა კომპანიების, პროდუქციის რეკლამირება,
						ბმულების განთავსება
					</h2>
					<p>
						5.1. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						განათავსოს unicatalog.ge-ზე სხვა კომპანიების,
						ვებ-გვერდების, სერვისების ან/და პროდუქტების (შემდგომში{" "}
						<strong>„დარეკლამებული პირები“</strong>) სარეკლამო
						ბანერები ან/და ბმულები, საქართველოს კანონმდებლობის
						შესაბამისად;
						<br />
						<br />
						5.2. <strong>„უნიკატალოგი“</strong> არ ახორციელებს
						<strong>„დარეკლამებული პირების“</strong> მონიტორინგს და
						არ იღებს პასუხისმგებლობას{" "}
						<strong>„მომხმარებლების“</strong> მიერ{" "}
						<strong>„დარეკლამებულ პირებთან“</strong> წარმოებულ
						მოლაპარაკებებზე, გარიგებებზე, მიმოწერებზე,{" "}
						<strong>„მომხმარებლების“</strong> მიერ{" "}
						<strong>„დარეკლამებული პირებისთვის“</strong> გადაცემულ
						პერსონალურ თუ სხვა სახის მონაცემებსა და ინფორმაციაზე.
						ამასთანავე, <strong>„უნიკატალოგი“</strong> არ აგებს
						პასუხს <strong>„დარეკლამებული პირების“</strong> მიერ
						გადმოცემული ინფორმაციის სისწორეზე, ან მათი ბრალეულობით
						დამდგარ ზიანზე.
					</p>
					<br />
					<h2>მუხლი 6. გარდამავალი დებულებები</h2>
					<p>
						6.1. <strong>„მომხმარებელი“</strong> ვალდებულია მიაწოდოს{" "}
						<strong>„უნიკატალოგს“</strong> ნებისმიერი მოთხოვნილი
						ინფორმაცია/დოკუმენტაცია, თუკი აღნიშნული მოთხოვნა
						შეესაბამება საქართველოს კანონმდებლობას;
						<br />
						<br />
						6.2. წინამდებარე წესებისა და პირობების რომელიმე პუნქტის
						ან ნაწილის ბათილობა არ გამოიწვევს წესებისა და პირობების
						დანარჩენი პუნქტების ან/და ნაწილების ბათილობას;
						<br />
						<br />
						6.3. <strong>„უნიკატალოგი“</strong> უფლებამოსილია
						ცალმხრივად შეიტანოს ცვლილებები წინამდებარე წესებისა და
						პირობების ტექსტში, რის შესახებაც ეცნობება ყველა{" "}
						<strong>„მომხმარებელს“</strong>, მათ წინამდებარე
						ვებ-გვერდზე არსებულ ანგარიშებზე ნოტიფიკაციების დაგზავნის
						გზით;
						<br />
						<br />
						6.4. წინამდებარე პირობების შესრულების ადგილს წარმოადგენს{" "}
						<strong>„უნიკატალოგის“</strong> იურიდიული მისამართი;
					</p>
					<br />
					<h2>მუხლი 7. დავა</h2>
					<p>
						7.1. <strong>„მომხმარებელსა“</strong> და{" "}
						<strong>„უნიკატალოგს“</strong> შორის დავა უნდა გადაწყდეს
						ურთიერთშეთანხმების გზით. თუკი შეთანხმება არ მიიღწევა 30
						(ოცდაათი) კალენდარული დღის განმავლობაში, დავას
						განიხილავს საქართველოს სასამართლო, საქართველოს მოქმედი
						კანონმდებლობის შესაბამისად.
					</p>
				</>
			) : (
				<>
					<h1>Terms and Conditions</h1>
					<p>
						The Terms and Conditions given below constitute a
						binding agreement between unicatalog.ge (hereinafter
						Unicatalog) and all the users (hereinafter User) which
						use the mentioned website and the services contained
						therein. By using Unicatalog User confirms to agree with
						the Terms and Conditions.
					</p>
					<br />
					<h2>Article 1. Website and its services</h2>
					<p>
						1.1. unicatalog.ge is a website that provides an User a
						recommendation and necessary information needed for
						entrants and students about different university
						programs in Georgia.
						<br />
						<br />
						1.2. By using this website, the User confirms that he /
						she has read the present terms and conditions, agrees to
						them and also agrees to receive notifications, updates
						and news, including advertising news, etc. from
						Unicatalog.
					</p>
					<br />
					<h2>Article 2. Registration and Use of the Website</h2>
					<p>
						2.1. Registration on this website is possible by the
						following persons under the following conditions:
						<br />
						<br />
						<Tab>
							2.1.1. A minor, on his / her own behalf, with the
							consent of the parent / guardian, in which case the
							minor declares and guarantees that he / she has the
							consent of the parent / guardian;
							<br />
							<br />
							2.1.2. An adult, in his/her own name;
							<br />
							<br />
							2.1.3. The parent / guardian of the minor, on behalf
							of that minor;
							<br />
							<br />
							2.1.4. A third party, on behalf of the minor, with
							the consent of the parent / guardian of that minor,
							or on behalf of the adult, with the consent of that
							minor, in which case the third party declares and
							guarantees that he or she has the consent referred
							to in this Article;
							<br />
							<br />
							2.1.5. The party to these Terms and Conditions is
							the User or, if he or she is a minor, the parent /
							guardian of that User;
							<br />
							<br />
						</Tab>
						2.2. For the purposes of these Terms and Conditions, a
						parent / guardian is a person who has the legal
						authority to care for, supervise a registered minor and
						who is authorized to represent a registered minor in
						transactions;
						<br />
						<br />
						2.3. According to the article 2.1 each person referred
						to in subparagraphs of the paragraph that registers and
						each parent / guardian consents and guarantees that the
						registered minor shall comply with these Terms and
						Conditions.
						<br />
						<br />
						2.4. User is responsible for any action taken on his/her
						behalf on unicatalog.ge;
						<br />
						<br />
						2.5. User is responsible for the accuracy of the
						information provided to "Unicalogue"
						<br />
						<br />
						2.6. User is responsible for the safe storage of his/her
						registration data (mobile phone, e-mail, password,
						etc.);
						<br />
						<br />
						2.7. Unicatalog is authorized to conduct technical and
						other activities on unicatalog.ge. for that time
						unicatalog.ge may become fully or partially
						inaccessible. Unicatalog is not obliged to notify the
						User in advance about the implementation of the
						mentioned works.
					</p>
					<br />
					<h2>Article 3. Processing of personal data</h2>
					<p>
						3.1. Unicatalog is authorized to process all the
						information / documentation in accordance with the
						legislation of Georgia, which User provided to
						Unicatalog by registration and / or by participating in
						educational projects, events, telephone or other
						communications, etc
						<br />
						<br />
						3.2. Unicatalog is authorized to process the personal
						data of the User in accordance with Article 2 (part D)
						of the Law of Georgia on Personal Data Protection, any
						actions performed using automatic, semi-automatic or
						non-automatic means;
						<br />
						<br />
						3.3. Unicatalog is authorized to process the actions
						taken by the User on unicatalog.ge, including and not
						limited to the information on the universities and
						faculties in which the User was interested, and / or
						from which he / she requested a consultation, etc.
						<br />
						<br />
						3.4. The purposes of personal data processing are:
						<br />
						<br />
						<Tab>
							3.4.1. Informing the User about educational
							services, news, projects, events, gifts, etc.
							<br />
							<br />
							3.4.2. Providing User information to educational
							institutions that the "user" was interested in and
							expressed a desire for consultation or other
							information;
							<br />
							<br />
							3.4.3. Effective planning and implementation of
							direct marketing and marketing campaigns;
							<br />
							<br />
							3.4.4. Collection of statistical data;
							<br />
							<br />
							3.4.5. Development of our products, services,
							website;
							<br />
							<br />
							3.4.6. Making and managing payments;
							<br />
							<br />
							3.4.7. All those purposes which are not prohibited
							by law, which are compatible with the implementation
							of educational projects;
							<br />
							<br />
						</Tab>
						3.5. Unicatalog is responsible for the security of
						personal information provided by Users;
						<br />
						<br />
						3.6. Unicatalog is authorized to disclose the “User's”
						personal data as required by law and / or to defend its
						interests in legal disputes, and / or as a result of
						reorganization, merger or sale of the Company;
						<br />
						<br />
						3.7. Unicatalog reserves the right to process the
						“User's” personal data for the benefit of third parties,
						in particular, to issue and / or disclose such data to
						third parties for marketing, advertising, information or
						other commercial purposes.
					</p>
					<br />
					<h2>Article 4. Copyright and Intellectual Rights</h2>
					<p>
						4.1. This website, its services, the software used to
						operate this website and its services, as well as the
						title Unicatalog, its logo, the articles published on
						the website (hereinafter referred to as "content") are
						Unicatalog owned and protected by law.
						<br />
						<br />
						4.2. User is entitled to use "Content" only for personal
						use.
						<br />
						<br />
						4.3. User has no right to sell, use for paid purposes,
						modify, process, translate "Content", distribute copies
						of it, remove the trademark, logo or name.
					</p>
					<br />
					<h2>
						Article 5. Advertising the products and links of other
						companies
					</h2>
					<p>
						5.1. Unicatalog is authorized to place banners and / or
						links to other companies, websites, services and / or
						products (hereinafter referred to as "Advertisers") on
						unicatalog.ge, in accordance with the legislation of
						Georgia;
						<br />
						<br />
						5.2. Unicatalog does not monitor Advertisers and is not
						responsible for the negotiations, transactions,
						correspondence as well as personal data and information
						provided by Users to "Advertisers". In addition,
						Unicatalog is not liable for the accuracy of the
						information provided by the Advertisers or for the
						damage caused by their fault.
					</p>
					<br />
					<h2>Article 6. Transitional Provisions</h2>
					<p>
						6.1. User is obliged to provide Unicatalog with any
						requested information / documentation, if the said
						request complies with the legislation of Georgia;
						<br />
						<br />
						6.2. Invalidity of any paragraph or part of these Terms
						and Conditions shall not result in the invalidity of the
						remaining paragraphs and / or parts of the Terms and
						Conditions;
						<br />
						<br />
						6.3. Unicatalog is entitled to unilaterally make changes
						to the text of these Terms and Conditions, of which all
						Users will be notified by sending notifications to the
						accounts on this website;
						<br />
						<br />
						6.4. The place of fulfillment of these conditions is the
						legal address of Unicatalog;
					</p>
					<br />
					<h2>Article 7. Dispute</h2>
					<p>
						7.1. Disputes between the User and the Unicatalog must
						be resolved by mutual agreement. If the agreement is not
						reached within 30 (thirty) calendar days, the dispute
						shall be considered by a court of Georgia in accordance
						with the current legislation of Georgia.
					</p>
				</>
			)}
		</Container>
	</>
);

const Container = styled.div`
	font-size: 16px;
	font-family: "FiraGo";
	padding: 0px 20px;

	h1 {
		font-size: 22px;
	}
	h2 {
		font-size: 18px;
	}
`;

const Tab = styled.div`
	margin-left: 30px;
`;

export default Terms;
