import React, { useCallback } from "react";
import { History } from "history";
import DummyBanner from "../img/dummy-banner.png";
import "../styles/expo-offers.min.css";

interface DummyBanner {
	imagePath: string;
	link?: string;
}

const dummybanners: DummyBanner[] = [
	{ imagePath: DummyBanner },
	{ imagePath: DummyBanner },
	{ imagePath: DummyBanner },
];

const OffersBanners: React.FC<{ history: History }> = React.memo(
	function OffersBanners({ history }) {
		const handleBannerClick = useCallback(
			(e: DummyBanner) => () => {
				if (!!e.link) history.push(e.link);
				else console.log(`banner clicked`);
			},
			[history]
		);

		return (
			<div className="offersBannersContainer">
				{dummybanners.map(e => (
					<div
						className="offerBanner"
						// style={{ backgroundImage: `url(${DummyBanner})` }}
						onClick={handleBannerClick(e)}
					>
						<img src={e.imagePath} alt="offer banner" />
					</div>
				))}
			</div>
		);
	}
);

export default OffersBanners;
