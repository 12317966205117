import * as React from "react";
import CuPopup from "./cu-popup";
import Popup, { PopupContent } from "@app/components/Widgets/Popup";
import { calculateGrant } from "../../functions";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { LatoriSuggestedProgram } from "../program-suggestion";
import { IUserScores } from "@app/reducers/lator";

interface IOwnProps {
	suggestedProgramId: number;
	electiveSubjects: number[];
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

interface IState {
	isPopupOpened: boolean;
}

const TOP500 = "ამ ქულებით უფასოდ შეგიძლია ისწავლო კავკასიის უნივერსიტეტში.";
const cuScholarship =
	"ამ ქულებით შეგიძლია CU გრანტი აიღო კავკასიის უნივერსიტეტში.";

class CuSuggested extends React.Component<IProps, IState> {
	state: IState = {
		isPopupOpened: false,
	};

	changePopupState = (state: boolean) => () =>
		this.setState({ isPopupOpened: state });

	render() {
		try {
			if (
				!this.props.unis ||
				!this.props.programs ||
				!this.props.contestScore ||
				!this.props.userScores
			)
				return null;
			const currentProgram = this.props.programs.find(
				program => program.id === this.props.suggestedProgramId
			);
			if (!currentProgram) return null;
			const currentUni = this.props.unis.find(
				uni => uni.id === currentProgram.uni_id
			);
			if (!currentUni) return null;
			console.log(this.props.userScores);
			if (
				!this.props.userScores ||
				!this.props.electiveSubjects.some(
					subjId => (this.props.userScores as IUserScores)[subjId]
				)
			)
				return null;

			const results = calculateGrant({
				electiveSubjects: this.props.electiveSubjects,
				rawScores: this.props.userScores,
			});

			let userScholarship = 0;

			for (const subjId of this.props.electiveSubjects)
				if (results[subjId].grant > userScholarship)
					userScholarship = results[subjId].grant;

			let textToShow = "მოიპოვე CU გრანტი კავკასიის უნივერსიტეტში.";
			if (this.props.contestScore > 2138.4) textToShow = TOP500;
			else if (userScholarship >= 70) textToShow = cuScholarship;

			return (
				<>
					{this.state.isPopupOpened && (
						<Popup onClose={this.changePopupState(false)}>
							<PopupContent
								style={{ padding: "0", borderRadius: "10px" }}
							>
								<CuPopup
									programId={this.props.suggestedProgramId}
									electiveSubjects={
										this.props.electiveSubjects
									}
									scores={this.props.userScores}
									contestScaledScore={this.props.contestScore}
								/>
							</PopupContent>
						</Popup>
					)}
					<LatoriSuggestedProgram
						onMainButtonClick={this.changePopupState(true)}
						program={currentProgram}
						uni={currentUni}
						textToShow={textToShow}
						titleColor="blue"
						seeMainButtonColor="blue"
					/>
				</>
			);
		} catch (e) {
			return null;
		}
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
	contestScore: state.lator.contestScore,
	userScores: state.lator.userScores,
});

export default connect<IStateProps, any, IOwnProps>(mapStateToProps)(
	CuSuggested
);
