import uniExpo from "@app/api/uni-expo/controller";
import unis from "@app/api/unis/controller";
import { ILiveMeetingWithMetadata } from "@app/api/unis/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import { IRootState } from "@app/reducers/root";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../styles/expo-timetable.min.css";
import TimetableRow from "./timetable-row";

type IStateProps = ReturnType<typeof mapStateToProps>;

export enum ExpoLiveState {
	isReserved = "reserved",
	isNotReserved = "notReserved",
}

const ExpoTimetable: React.FC<IStateProps> = React.memo(function ExpoTimetable({
	user,
}) {
	const [liveMeetings, setLiveMeetings] = useState<
		ILiveMeetingWithMetadata[]
	>();
	const [reservedLiveIds, setReservedLiveIds] = useState<number[]>([]);

	useEffect(() => {
		const removeLoader = addLoader();
		unis.getAllOngoingLiveMeetings()
			.then(data => {
				setLiveMeetings(data);
				removeLoader();
			})
			.catch(e => {
				console.log(e);
				removeLoader();
			});
	}, []);

	useEffect(() => {
		uniExpo.getUserReservations().then(data => {
			setReservedLiveIds(data.map(e => e.live_id));
		});
	}, []);

	return (
		<>
			<div className="main maintimetable">
				<h3>განრიგი</h3>
				<table className="table">
					<thead>
						<tr className="timetableHeaderContainer">
							<th className="logoDiv"></th>
							<th className="nameDiv">
								<span>უნივერსიტეტი</span>
							</th>
							<th className="topicDiv">
								<span>თემა</span>
							</th>
							<th className="timeDiv">
								<span>დასაწყისი</span>
							</th>
							<th className="buttonDiv"></th>
						</tr>
					</thead>
					<tbody>
						{liveMeetings &&
							liveMeetings.map((e: ILiveMeetingWithMetadata) => {
								const reserved = reservedLiveIds.includes(e.id);
								const state = reserved
									? ExpoLiveState.isReserved
									: ExpoLiveState.isNotReserved;
								return (
									<TimetableRow
										key={e.id}
										live={e}
										state={state}
									/>
								);
							})}
					</tbody>
				</table>
			</div>
		</>
	);
});

const mapStateToProps = (state: IRootState) => ({
	user: state.user.userData,
});

export default connect<IStateProps, null, {}>(mapStateToProps)(ExpoTimetable);
