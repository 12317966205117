import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import BackgroundTypeColorPicker, {
	castToLinearBackground,
	IGradientColor,
} from "../BackgroundTypeColorPicker";
import CheckIcon from "@material-ui/icons/Check";
import CoverUpload from "./CoverUpload";
import Navigation from "../../Widgets/Navigation";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import "./styles/UniBackgroundTypeStyles.min.css";

const titles = ["ფერი", "სურათი"];
const colors = [
	"linear-gradient(45deg, #d72525, #ff2879)",
	"linear-gradient(45deg, #7d0aff, #2840f6)",
	"linear-gradient(45deg, #1345f3, #1d72ff)",
	"linear-gradient(45deg, #1c7ff9, #10daff)",
	"linear-gradient(45deg, #0fb2fe, #21fc59)",
	"linear-gradient(45deg, #ec1393, #c01bf0)",
	"linear-gradient(45deg, #22ea5a, #b4ea22)",
	"linear-gradient(45deg, #f8f011, #ff6612)",
];

interface IProps {
	uni: IRUniversity;
	onCoverBGColorChange?: (color: string) => void;
	color?: null | string;
}

interface IState {
	tab: "color" | "image";
	selectedColorIndex: "customColor" | number;
	customGradient: null | IGradientColor[];
	gradientColorIndex: null | number;
	newColor: boolean;
}

export function extractColorsFromGradient(colorsString: string) {
	const colorMatch = /.+?\(.*?(#[0-9A-Fa-f]{3,6}).*?(#[0-9A-Fa-f]{3,6}).*\)/.exec(
		colorsString
	);
	if (!colorMatch) return null;
	return [colorMatch[1], colorMatch[2]];
}

class UniBackgroundType extends React.Component<IProps, IState> {
	state: IState = {
		tab: this.props.uni.coverUrl ? "image" : "color",
		selectedColorIndex: 0,
		customGradient: null,
		newColor: false,
		gradientColorIndex: null,
	};

	constructor(props: IProps) {
		super(props);
		if (props.color) {
			const selectedIndex = colors.findIndex(el => el === props.color);
			if (selectedIndex !== -1) {
				this.state.selectedColorIndex = selectedIndex;
			} else {
				// extract 2 hex colors from linear-gradient
				const colorMatch = extractColorsFromGradient(props.color);
				if (colorMatch !== null) {
					const firstColorHex = colorMatch[0];
					const secondColorHex = colorMatch[1];

					this.state.newColor = true;
					this.state.customGradient = [
						{ color: firstColorHex, position: 0 },
						{ color: secondColorHex, position: 1 },
					];
					this.state.gradientColorIndex = 0;
					this.state.selectedColorIndex = "customColor";
				}
			}
		}
	}

	switchTab = (tabIndex: number) => {
		this.setState({
			tab: tabIndex === 0 ? "color" : "image",
		});
	};

	handleColorClick = (index: "customColor" | number) => {
		if (index !== "customColor") {
			this.setState({
				selectedColorIndex: index,
			});
			if (this.props.onCoverBGColorChange) {
				this.props.onCoverBGColorChange(colors[index]);
			}
		} else {
			this.setState({
				selectedColorIndex: "customColor",
			});
			if (this.props.onCoverBGColorChange) {
				this.props.onCoverBGColorChange(
					castToLinearBackground(this.state.customGradient!)
				);
			}
		}
	};

	addNewColor = () => {
		const defaultGradient = [
			{ color: "#C1C834", position: 0 },
			{ color: "#0C8416", position: 1 },
		];
		this.setState({
			newColor: true,
			customGradient: defaultGradient,
			gradientColorIndex: 0,
			selectedColorIndex: "customColor",
		});
		if (this.props.onCoverBGColorChange) {
			this.props.onCoverBGColorChange(
				castToLinearBackground(defaultGradient)
			);
		}
	};

	gradientColorChange = (color: any) => {
		const newGradient = this.state.customGradient!.map((el, index) => {
			if (index !== this.state.gradientColorIndex) return el;
			return { ...el, color: color.hex };
		});
		this.setState({
			customGradient: newGradient,
			selectedColorIndex: "customColor",
		});
		if (this.props.onCoverBGColorChange) {
			this.props.onCoverBGColorChange(
				castToLinearBackground(newGradient)
			);
		}
	};

	gradientIndexChange = (index: number) => {
		this.setState({
			gradientColorIndex: index,
		});
	};

	render() {
		const container = document.getElementById(
			"backgroundTypesContainer" + this.props.uni.id
		);
		return (
			<div
				className="backgroundTypesContainer"
				id={"backgroundTypesContainer" + this.props.uni.id}
			>
				<Navigation
					titles={titles}
					onClick={this.switchTab}
					selectedIndex={this.state.tab === "color" ? 0 : 1}
				/>
				{this.state.tab === "color" ? (
					<div className="container">
						{colors.map((color, index) => {
							return (
								<ColorCard
									key={index}
									color={color}
									selected={
										this.state.selectedColorIndex === index
									}
									onClick={() => this.handleColorClick(index)}
								/>
							);
						})}
						{this.state.newColor ? (
							<React.Fragment>
								<ColorCard
									key={"customColor"}
									color={castToLinearBackground(
										this.state.customGradient!
									)}
									selected={
										this.state.selectedColorIndex ===
										"customColor"
									}
									onClick={() =>
										this.handleColorClick("customColor")
									}
								/>
								<BackgroundTypeColorPicker
									colors={this.state.customGradient!}
									currentColorIndex={
										this.state.gradientColorIndex!
									}
									onColorChange={this.gradientColorChange}
									onIndexChange={this.gradientIndexChange}
								/>
							</React.Fragment>
						) : (
							<div className="colorCardMain addNewColor">
								<div className="VM" onClick={this.addNewColor}>
									<AddIcon className="addIcon" />
								</div>
							</div>
						)}
					</div>
				) : (
					<CoverUpload
						url={this.props.uni.coverUrl || ""}
						uni_id={this.props.uni.id}
						width={container ? container.offsetWidth : 840}
						height={
							container
								? (container.offsetWidth * 7) / 32
								: 183.75
						}
					/>
				)}
			</div>
		);
	}
}

interface IColorCardProps {
	color: string;
	selected: boolean;
	onClick: () => void;
}

export const ColorCard: React.SFC<IColorCardProps> = props => (
	<div
		className="colorCardMain"
		style={{ background: props.color }}
		onClick={props.onClick}
	>
		<div className="checkIconContainer">
			{props.selected ? <CheckIcon className="checkIcon" /> : ""}
		</div>
	</div>
);

export default UniBackgroundType;
