import * as React from "react";
import attrs from "./html-attrs";
import cuLogo from "./suggestions/cuniversity/styles/img/cu-logo.png";
import Header from "../Header";
import Helmet from "react-helmet";
import LatoriSliders from "./sliders";
import memoizeOne from "memoize-one";
import ProgramPicker from "./program-picker-panel";
import SubjectPicker from "./subject-picker-panel";
import {
	animateWindowScroll,
	getHTMLElementCoords,
} from "@app/commonJavascript";
import { connect } from "react-redux";
import { css } from "emotion";
import { CUUniId } from "./consts";
import { getUniByUrlName, getUnisAndPrograms } from "@app/actions/unis";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IUniMainInfo } from "@app/api/unis/helper-schemas";
import { loadUserLatorInfo } from "@app/actions/lator";
import { match } from "react-router";
import "./styles/resultsPage.min.css";

interface IOwnProps {
	history: History;
	match: match<{ urlName: string }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	selectedSubjectId: number | null;
	selectedProgramId: number | null;
	subjectIds: number[];
	mainInfo?: IUniMainInfo;
}

class LatoriUniPage extends React.PureComponent<IProps, IState> {
	state: IState = {
		selectedSubjectId: null,
		selectedProgramId: null,
		subjectIds: (this.props.isLogged && this.props.subjectIds) || [6, 5, 4],
	};

	uniId: null | number = null;

	getUniPrograms = memoizeOne((programs: IRProgram[]) => {
		if (!this.uniId) return;
		return programs.filter(p => p.uni_id === this.uniId);
	});

	toProgramIds = memoizeOne((programs: IRProgram[]): number[] => {
		return programs.map(i => i.id);
	});

	slidersRef: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: IProps) {
		super(props);
		if (this.props.subjectIds) {
			this.state.subjectIds = this.props.subjectIds;
		}
	}

	componentDidMount() {
		if (
			this.props.isLogged &&
			(!this.props.subjectIds || !this.props.programIds)
		) {
			this.props.loadUserLatorInfo().then();
		}

		this.props
			.getUniByUrlName({
				urlName: this.props.match.params.urlName,
				getEssentialInfos: true,
				getMainInfos: true,
				getContents: false,
				getPrograms: true,
			})
			.then(uni => {
				this.uniId = uni.id;
				this.setState({
					mainInfo: uni.mainInfo!,
				});
			});
	}

	onSubjectSelect = (subjectId: number | null) => {
		this.setState({
			selectedSubjectId: subjectId,
		});
		if (this.state.selectedProgramId) this.slideToSliders();
	};

	onProgramSelect = (programId: number | null) => {
		this.setState({
			selectedProgramId: programId,
		});
		if (this.state.selectedSubjectId) this.slideToSliders();
	};

	slideToSliders = () => {
		if (!this.slidersRef || !this.slidersRef.current) return;
		animateWindowScroll(
			getHTMLElementCoords(this.slidersRef.current).top - 150,
			300
		);
	};

	getCustomNav = () => {
		if (!this.props.unis) return null;
		const { mainInfo } = this.state;
		const uni = this.props.unis.find(
			u => u.urlName === this.props.match.params.urlName
		);

		if (!mainInfo || !uni) return null;
		return (
			<div>
				<span
					className="header_buttons"
					onClick={() =>
						window.open(`http://${mainInfo.web}`, "__blank")
					}
				>
					{mainInfo.web}
				</span>
			</div>
		);
	};

	render() {
		if (!this.uniId || !this.props.programs) return null;
		const programs = this.getUniPrograms(this.props.programs);
		if (!programs) return null;
		const programIds = this.toProgramIds(programs);
		return (
			<>
				<Header
					history={this.props.history}
					customNav={this.getCustomNav()}
				/>
				<div className="main main2">
					<Helmet {...attrs} title="მურწყულატორის შედეგები" />
					<div className="latoriResultsPage">
						<div className="left">
							<SubjectPicker
								onSubjectChange={this.onSubjectSelect}
								selectedSubjectId={this.state.selectedSubjectId}
								programId={this.state.selectedProgramId}
							/>
							<ProgramPicker
								onChange={this.onProgramSelect}
								value={this.state.selectedProgramId}
								defaultProgramIds={programIds}
								disallowAdding={true}
								title="პროგრამები"
							/>
						</div>
						<div className="right" ref={this.slidersRef}>
							{!!this.state.selectedSubjectId &&
								!!this.state.selectedProgramId && (
									<LatoriSliders
										selectedSubjectId={
											this.state.selectedSubjectId
										}
										programId={this.state.selectedProgramId}
										history={this.props.history}
										onProgramPage={false}
										hideExtraInfoButton={false}
									/>
								)}
							{(!this.state.selectedSubjectId ||
								!this.state.selectedProgramId) && (
								<div
									className="latoriResultContainer"
									style={{ fontSize: 22 }}
								>
									ქულისა და გრანტის დასათვლელად აირჩეთ საგანი
									და საბაკალავრო პროგრამა
								</div>
							)}
							{this.uniId === CUUniId && (
								<img
									src={cuLogo}
									className={cuLogoCss}
									alt=""
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const cuLogoCss = css`
	max-width: 90%;
	display: block;
	margin: 0 auto;
	margin-bottom: 20px;
`;

const mapStateToProps = (state: IRootState) => ({
	isLogged: !!state.user.loggedIn,
	unis: state.unis.info,
	programs: state.programs.info,
	subjectIds: state.lator.subjectIds,
	programIds: state.lator.programIds,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
	loadUserLatorInfo,
	getUniByUrlName,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(LatoriUniPage);
