import {
	CAN_ACCESS_UNI_NEWS_AND_EVENTS,
	CAN_EDIT_UNI_CONTENT,
	CAN_EDIT_UNI_SETTINGS,
	CAN_SEND_UNI_SMS,
} from "./api/unis/consts";
import {
	CAN_EDIT_PROGRAM_CONTENT,
	CAN_EDIT_PROGRAM_SETTINGS,
} from "./api/programs/consts";
import {
	IPrivilegeAllUnis,
	IPrivilegeOneUniAllPrograms,
	IPrivilegeOneUniSeveralPrograms,
	IRUserPermissions,
	IRUserPrivilege,
} from "@app/api/users/helper-schemas";

export function canAccessAllUnis(
	privilege?: IRUserPrivilege | null
): privilege is IPrivilegeAllUnis {
	if (!privilege) return false;
	return (privilege as IPrivilegeAllUnis).allUnis === true;
}
export function canAccessAllProgramsOfUni(
	privilege?: IRUserPrivilege | null
): privilege is IPrivilegeOneUniAllPrograms {
	if (!privilege) return false;
	return (privilege as IPrivilegeOneUniAllPrograms).allPrograms === true;
}
export function canAccessSeveralProgramsOfUni(
	privilege?: IRUserPrivilege | null
): privilege is IPrivilegeOneUniSeveralPrograms {
	if (!privilege) return false;
	return Array.isArray(
		(privilege as IPrivilegeOneUniSeveralPrograms).programs
	);
}

export function getUniIdOfAdmin(
	permissions?: IRUserPermissions | null
): null | number | "allUnis" {
	if (!permissions) return null;
	if (!permissions.privilege) return null;
	if (canAccessAllUnis(permissions.privilege)) {
		return "allUnis";
	}
	return permissions.privilege.uniId;
}

export function canAccessUni(
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	const adminUniId = getUniIdOfAdmin(permissions);
	if (adminUniId === "allUnis") return true;
	return adminUniId === uni_id;
}
export function canAccessProgram(
	program_id: number,
	uni_id: number,
	permissions: IRUserPermissions | null
): boolean {
	if (!canAccessUni(uni_id, permissions)) return false;
	const { privilege } = permissions!;
	if (canAccessSeveralProgramsOfUni(privilege)) {
		return !!privilege.programs.find(prog => prog.id === program_id);
	}
	return true;
}

// tslint:disable-next-line:max-line-length
export function getPermissionsOfProgram(
	program_id: number,
	uni_id: number,
	permissions?: IRUserPermissions | null
): null | number {
	if (!permissions) return null;
	if (!permissions.privilege) return null;
	if (canAccessAllUnis(permissions.privilege)) {
		return permissions.privilege.allProgramsPermissions;
	}
	if (canAccessAllProgramsOfUni(permissions.privilege)) {
		if (!canAccessUni(uni_id, permissions)) return null;
		return permissions.privilege.allProgramsPermissions;
	}
	if (canAccessSeveralProgramsOfUni(permissions.privilege)) {
		if (!canAccessUni(uni_id, permissions)) return null;
		const program = permissions.privilege.programs.find(
			prog => prog.id === program_id
		);
		if (!program) return null;
		return program.permissions;
	}
	return null;
}

export function canEditUniContent(
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	if (!permissions) return false;
	if (!canAccessUni(uni_id, permissions)) return false;

	return canEditUniContentByPermission(permissions.uniPermissions);
}

export function canEditUniSettings(
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	if (!permissions) return false;
	if (!canAccessUni(uni_id, permissions)) return false;

	return canEditUniSettingsByPermission(permissions.uniPermissions);
}

export function canSendUniSMS(
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	if (!permissions) return false;
	if (!canAccessUni(uni_id, permissions)) return false;

	return canSendUniSMSByPermission(permissions.uniPermissions);
}

export function canAccessUniNewsAndEVents(
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	if (!permissions) return false;
	if (!canAccessUni(uni_id, permissions)) return false;

	return canAccessUniNewsAndEVentsByPermission(permissions.uniPermissions);
}

export function canEditUniSettingsByPermission(
	uniPermissions: number
): boolean {
	const grantAccess = uniPermissions & CAN_EDIT_UNI_SETTINGS;
	return grantAccess === CAN_EDIT_UNI_SETTINGS;
}

export function canEditUniContentByPermission(uniPermissions: number): boolean {
	const grantAccess = uniPermissions & CAN_EDIT_UNI_CONTENT;
	return grantAccess === CAN_EDIT_UNI_CONTENT;
}

export function canEditProgramContent(
	program_id: number,
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	const programPermissions = getPermissionsOfProgram(
		program_id,
		uni_id,
		permissions
	);
	if (!programPermissions) return false;
	return canEditProgramContentByPermission(programPermissions);
}

export function canEditProgramSettings(
	program_id: number,
	uni_id: number,
	permissions?: IRUserPermissions | null
): boolean {
	const programPermissions = getPermissionsOfProgram(
		program_id,
		uni_id,
		permissions
	);
	if (!programPermissions) return false;
	return canEditProgramSettingsByPermission(programPermissions);
}

export function canEditProgramContentByPermission(
	programPermissions: number
): boolean {
	const grantAccess = programPermissions & CAN_EDIT_PROGRAM_CONTENT;
	return grantAccess === CAN_EDIT_PROGRAM_CONTENT;
}

export function canEditProgramSettingsByPermission(
	programPermissions: number
): boolean {
	const grantAccess = programPermissions & CAN_EDIT_PROGRAM_SETTINGS;
	return grantAccess === CAN_EDIT_PROGRAM_SETTINGS;
}

export function canSendUniSMSByPermission(uniPermissions: number): boolean {
	const grantAccess = uniPermissions & CAN_SEND_UNI_SMS;
	return grantAccess === CAN_SEND_UNI_SMS;
}

export function canAccessUniNewsAndEVentsByPermission(
	uniPermissions: number
): boolean {
	const grantAccess = uniPermissions & CAN_ACCESS_UNI_NEWS_AND_EVENTS;
	return grantAccess === CAN_ACCESS_UNI_NEWS_AND_EVENTS;
}
