import * as React from "react";
import api from "@app/api";
import ContentMedias from "./ContentMedias";
import ReactQuill from "react-quill";
import SnackNotification from "../Widgets/SnackNotification";
import { addLoader } from "@app/commonJavascript";
import { addNews, newsChangeSettings } from "@app/actions/temp";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IUniContent } from "@app/api/unis/helper-schemas";
import { match } from "react-router";
import "@app/components/styles/quillCustomTheme.min.css";
import "react-quill/dist/quill.snow.css";
import "./styles/adminNewsEditPage.min.css";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string; news_id?: string }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	error?: string;
	newsId?: number;
	newsInformation: string;
	galleryMedias: IUniContent["galleryMedias"];
}

class AdminNewsEditPage extends React.Component<IProps, IState> {
	state: IState = {
		newsInformation: "",
		galleryMedias: null,
	};

	uni_id: number = +this.props.match.params.uni_id;

	constructor(props) {
		super(props);

		const news_id: number = +props.match.params.news_id;

		if (props.match.params.news_id !== undefined) {
			api.news
				.getById({ id: news_id })
				.then(news => {
					this.setState({
						newsId: news.id,
						newsInformation: news.text,
						galleryMedias: news.galleryMedias,
					});
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	onTextChange = (text: string) => this.setState({ newsInformation: text });

	onGalleryMediaChange = media => {
		this.setState({ galleryMedias: media });
	};

	saveNews = () => {
		const removeLoader = addLoader();
		const { newsInformation: text, galleryMedias, newsId } = this.state;
		if (text.length <= 0 || text === "<p><br></p>") {
			this.setState({ error: "შეავსეთ ინფორმაცია სიახლეზე" });
			return;
		}

		const newsObject = {
			id: newsId,
			uni_id: this.uni_id,
			newsSettings: {
				text,
				galleryMedias,
			},
		};

		api.news
			.save(newsObject)
			.then(data => {
				removeLoader();
				if (!newsId) {
					this.props.addNews({
						id: data.id,
						uni_id: this.uni_id,
						text,
						galleryMedias,
						created_at: new Date(),
						updated_at: new Date(),
					});
				} else {
					this.props.newsChangeSettings(
						newsId,
						{ text, galleryMedias },
						this.uni_id
					);
				}
				this.props.history.push(
					"/admin/uni/" + this.uni_id + "/programs"
				);
			})
			.catch(err => {
				removeLoader();
				alert("დაფიქსირდა შეცდომა");
				console.log(err);
			});
	};

	uploadPhotos = (data: FormData) => {
		data.append("uni_id", "" + this.uni_id);
		return api.news.uploadPhotos(data as any);
	};

	closeNotification = () => this.setState({ error: undefined });

	render() {
		const { news_id } = this.props.match.params;
		const { galleryMedias, newsInformation, error } = this.state;

		return (
			<div className="adminNewsEditPage">
				{typeof error !== "undefined" && error.length > 0 && (
					<SnackNotification
						onClose={this.closeNotification}
						message={error}
						variant="error"
						open={typeof error !== "undefined" && error.length > 0}
					/>
				)}
				<p className="center title">სიახლის დამატება</p>
				<p>შეიყვანეთ ინფორმაცია</p>
				<div className="QuillContainer quill--white--theme">
					<ReactQuill
						placeholder="შეიყვანეთ ინფორმაცია სიახლეზე"
						onChange={this.onTextChange}
						value={newsInformation}
					/>
				</div>
				{(news_id === undefined ||
					(news_id !== undefined && newsInformation.length > 0)) && (
					<ContentMedias
						galleryMedias={galleryMedias}
						onChange={this.onGalleryMediaChange}
						uploadImageFunc={this.uploadPhotos}
						galleryPath="etc"
					/>
				)}
				<div className="addNewsButtonContainer">
					<button
						className="adminPrimaryButton add"
						onClick={this.saveNews}
					>
						შენახვა
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
	addNews,
	newsChangeSettings,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(AdminNewsEditPage);
