import { INews } from "@app/api/news/validators";
import { FormattedMessage } from "@app/utils/locale";
import Cancel from "@material-ui/icons/CancelOutlined";
import * as React from "react";
import Popup from "../Widgets/Popup";
import { PopupImageOrVideo } from "./event-popup";
import "./styles/popup.min.css";

interface IProps {
	news: INews;
	onClose: () => void;
}

class NewsPopup extends React.Component<IProps> {
	render() {
		return (
			<Popup onClose={this.props.onClose}>
				<div className="popup_child">
					<div className="popup_header">
						<h2>
							<FormattedMessage id="newsSingular" />
						</h2>
						<Cancel
							className="cancelIcon"
							onClick={this.props.onClose}
						/>
					</div>

					{!!this.props.news.text && (
						<PopupTextArea text={this.props.news.text} />
					)}
					{!!this.props.news.galleryMedias &&
						this.props.news.galleryMedias.map(
							media =>
								(media.type === "VIDEO" ||
									media.type === "PHOTO") && (
									<PopupImageOrVideo
										key={media.url}
										url={media.url}
										type={media.type}
									/>
								)
						)}
				</div>
			</Popup>
		);
	}
}

interface IPopupTextAreaProps {
	text: string;
}
const PopupTextArea: React.FC<IPopupTextAreaProps> = props => (
	<div
		className="popup_text_area"
		dangerouslySetInnerHTML={{ __html: props.text }}
		style={{ border: "none" }}
	/>
);

export default NewsPopup;
