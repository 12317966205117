import * as React from "react";
import styled from "@emotion/styled";

interface IProps {
	userPicture?: string;
	userMessage: string;
	userName?: string;
	style?: IMessageContainerStyle;
}

export interface IMessageContainerStyle {
	userColor?: string;
	otherColor?: string;
}

const UserWrapperContainer = styled.div`
	margin: 5px 0;
	margin-left: auto;
	min-height: min-content;
`;

const LeftWrapperContainer = styled.div`
	margin: 5px 0;
	margin-right: auto;
	min-height: min-content;
`;

const UserPictureContainer = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	background-color: #2e86a8;
	background-size: cover;
	background-image: ${(props: { url?: string }) =>
		props.url ? "url(" + props.url + ")" : "none"};
	line-height: 38px;
	color: white;
	text-align: center;
	font-size: 20px;
`;

const MessageWrapper = styled.div`
	display: inline-block;
	vertical-align: top;
	max-width: calc(100% - 58px);
	padding: 10px 15px;
	border-radius: 20px;
	margin: 0 10px;
	color: white;
	background: ${(props: any) =>
		props.backgroundColor ? props.backgroundColor : "#a9a9a9"};
`;

export const UserMessageWrapper: React.SFC<IProps> = props => (
	<UserWrapperContainer>
		<MessageWrapper
			backgroundColor={props.style ? props.style.userColor : undefined}
		>
			<div dangerouslySetInnerHTML={{ __html: props.userMessage }} />
		</MessageWrapper>
		<UserPictureContainer url={props.userPicture}>
			{props.userName && !props.userPicture ? props.userName[0] : ""}
		</UserPictureContainer>
	</UserWrapperContainer>
);

export const LeftMessageWrapper: React.SFC<IProps> = props => (
	<LeftWrapperContainer>
		<UserPictureContainer url={props.userPicture}>
			{props.userName && !props.userPicture ? props.userName[0] : ""}
		</UserPictureContainer>
		<MessageWrapper
			backgroundColor={props.style ? props.style.otherColor : undefined}
		>
			<div dangerouslySetInnerHTML={{ __html: props.userMessage }} />
		</MessageWrapper>
	</LeftWrapperContainer>
);

export const CourseMessageWrapper: React.SFC<IProps> = props => (
	<LeftWrapperContainer>
		<img
			style={{ width: "38px", verticalAlign: "bottom" }}
			src={props.userPicture}
		/>
		<MessageWrapper
			backgroundColor={props.style ? props.style.otherColor : undefined}
		>
			<div dangerouslySetInnerHTML={{ __html: props.userMessage }} />
		</MessageWrapper>
	</LeftWrapperContainer>
);
