import Joi from "@app/utils/joi";

export const UserMobileSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	user_id: Joi.number()
		.integer()
		.required(),
	mobile: Joi.number()
		.integer()
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export interface IUserMobile {
	id: number;
	user_id: number;
	mobile: number;
	created_at: Date;
	updated_at: Date;
}

export const APOSTUserMobileSchema = UserMobileSchema.forbiddenKeys(
	"id",
	"user_id",
	"created_at",
	"updated_at"
);

export interface IAPOSTUserMobile {
	mobile: number;
}

export const RGetAllUserMobilesSchema = Joi.array()
	.items(UserMobileSchema)
	.required();
