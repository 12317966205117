import Joi, { SchemaToType } from "@app/utils/joi";
import { ProgramSubjectScoresSchema } from "../lator/helper-schemas";
import {
	ExamScoresSchema,
	IProgramContent,
	IRProgram,
	ProgramContentSchema,
	RProgramFields,
	RProgramSchema,
} from "./helper-schemas";
///

export const AGETProgramSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	getEssentialInfo: Joi.boolean(),
	getContents: Joi.boolean(),
});

export type IAGETProgram = SchemaToType<typeof AGETProgramSchema>;

export const RGETProgramSchema = RProgramSchema.optionalKeys(
	RProgramFields
).keys({
	id: Joi.number()
		.integer()
		.required(),
	contents: Joi.array().items(ProgramContentSchema),
});

export type IRGETProgram = Partial<IRProgram> & {
	id: number;
	contents?: IProgramContent[];
	programs?: IRProgram[];
};

///

export const AGETManySchema = RProgramSchema.optionalKeys(RProgramFields).keys({
	offset: Joi.number().integer(),
	limit: Joi.number().integer(),
	getEssentialInfos: Joi.boolean(),
	getContents: Joi.boolean(),
	locale: Joi.string()
		.valid("ka", "en")
		.optional(),
});
export type IAGETMany = Partial<IRProgram> & {
	offset?: number;
	limit?: number;
	getEssentialInfos?: boolean;
	getContents?: boolean;
	locale?: "ka" | "en";
};

export const RGETManySchema = Joi.array()
	.items(RGETProgramSchema)
	.required();
export type IRGETMany = IRGETProgram[];

///

export const APUTSaveSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.optional(),
	uni_id: Joi.number()
		.integer()
		.required(),
	programSettings: RProgramSchema.optionalKeys(RProgramFields)
		.keys({})
		.required(),
});
export interface IAPUTSave {
	id?: number;
	uni_id: number;
	programSettings: Partial<IRProgram>;
}

export const RPUTSaveSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});
export type IRPUTSave = SchemaToType<typeof RPUTSaveSchema>;

///

export const ADELETEProgramSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});

export type IADELETEProgram = SchemaToType<typeof ADELETEProgramSchema>;

///

export const APUTChangeContentSchema = Joi.object().keys({
	program_id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAPUTChangeContent = SchemaToType<typeof APUTChangeContentSchema>;

export const RPUTChangeContent = Joi.object().keys({
	program: RProgramSchema,
	contents: Joi.array().items(ProgramContentSchema),
});
export type IRPUTChangeContent = SchemaToType<typeof RPUTChangeContent>;

///

export const APOSTViewsSchema = Joi.object().keys({
	uni_id: Joi.number().required(),
	program_id: Joi.number().required(),
});
export type IAPOSTViews = SchemaToType<typeof APOSTViewsSchema>;

export type IRPOSTViews = void;

///

export const AGETSubjectScoresSchema = Joi.object().keys({
	subject_id: Joi.number()
		.integer()
		.required(),
	program_id: Joi.number()
		.integer()
		.required(),
});
export type IAGETSubjectScores = SchemaToType<typeof AGETSubjectScoresSchema>;

export const RGETSubjectScoresSchema = Joi.object().keys({
	scores: Joi.array()
		.items(ExamScoresSchema)
		.required(),
});
export type IRGETSubjectScores = SchemaToType<typeof RGETSubjectScoresSchema>;

export interface IProgramSubjectScores {
	uni_id: number;
	program_id: number;
	elective_subject_ids: number[] | null;
	scores: { [subj_id: number]: number };
	total_score: number;
	scholarship: number;
}

export const AGETProgramSubjectScoresSchema = Joi.object().keys({
	elective_subject_ids: Joi.array()
		.items(Joi.number().integer())
		.allow(null)
		.required(),
	program_id: Joi.number()
		.integer()
		.required(),
});

export interface IAGETProgramSubjectScores {
	program_id: number;
	elective_subject_ids: number[] | null;
}

export const RGETProgramSubjectScoresSchema = Joi.array()
	.items(ProgramSubjectScoresSchema)
	.required();

export type IRGETProgramSubjectScores = IProgramSubjectScores[];

// Program save validators
export const APUTProgramSaveSchema = Joi.object().keys({
	id: Joi.number()
		.allow(null)
		.integer(),
	uni_id: Joi.number()
		.integer()
		.required(),
	programSettings: Joi.object()
		.keys({
			name: Joi.string().optional(),
			code: Joi.string().optional(),
			officialName: Joi.string().optional(),
			urlName: Joi.string().optional(),
			alias: Joi.array()
				.items(Joi.string())
				.optional(),
			price: Joi.number().optional(),
			accreditation: Joi.boolean().optional(),
			fullyFinancedPlaces: Joi.number().optional(),
			financed: Joi.boolean().optional(),
			city: Joi.number().optional(),
			sector: Joi.number().optional(),
			fields: Joi.array()
				.items(Joi.number().integer())
				.optional(),
			foreignLangs: Joi.array()
				.items(Joi.string())
				.optional(),
			subjects: Joi.array()
				.items(Joi.number().integer())
				.optional(),
			numsBySubjs: Joi.array()
				.items(Joi.number().integer())
				.optional(),
			coefficients: Joi.array()
				.items(Joi.number().integer())
				.optional(),
			minScoreLimits: Joi.array()
				.items(Joi.number().integer())
				.optional(),
			coverBGColor: Joi.string().optional(),
			intraUniPositionCoefficient: Joi.number().optional(),
			uniMainCoefficient: Joi.number().optional(),
		})
		.required(),
});
export type IAPUTProgramSave = SchemaToType<typeof APUTProgramSaveSchema>;

export const RPUTProgramSaveSchema = Joi.object().keys({
	success: Joi.boolean(),
	id: Joi.number().integer(),
});
export type IRPUTProgramSave = SchemaToType<typeof RPUTProgramSaveSchema>;

// Program logo or cover save validators
export const APOSTUploadPhotoSchema = Joi.object()
	.keys({
		uni_id: Joi.number()
			.integer()
			.optional(),
		program_id: Joi.number()
			.integer()
			.optional(),
		picType: Joi.string()
			.valid("photo", "cover")
			.required(),
	})
	.required();
export type IAPOSTUploadPhoto = SchemaToType<typeof APOSTUploadPhotoSchema>;

export const RPOSTUploadPhotoSchema = Joi.object().keys({
	fileName: Joi.string(),
	fileFullUrl: Joi.string(),
});
export type IRPOSTUploadPhoto = SchemaToType<typeof RPOSTUploadPhotoSchema>;
