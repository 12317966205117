import { FavoriteProgramsActions } from "@app/actions/favorite-programs";
import { IFavPrograms } from "@app/api/users/programs/validators";
import {
	LOAD_FAVORITE_PROGRAMS,
	ADD_FAVORITE_PROGRAM,
	REMOVE_FAVORITE_PROGRAM,
	USER_LOG_OUT,
} from "../action-types";

function getNewFavoriteProgramsState(
	state: IStateFavoritePrograms = {},
	action: FavoriteProgramsActions
): IStateFavoritePrograms {
	if (typeof action.type === "undefined") return state;
	switch (action.type) {
		case LOAD_FAVORITE_PROGRAMS:
			return {
				...state,
				favorites: action.programs,
				lastFavUpdate: action.lastUpdate,
			};

		case ADD_FAVORITE_PROGRAM:
			return {
				...state,
				favorites: {
					...(state.favorites || {}),
					[action.program_id]: {
						program_id: action.program_id,
						uni_id: action.uni_id,
						time: new Date(),
					},
				},
			};

		case REMOVE_FAVORITE_PROGRAM:
			if (typeof state.favorites === "undefined")
				return { ...state, favorites: {} };
			const newFavs = { ...state.favorites };
			delete newFavs[action.program_id];
			return { ...state, favorites: newFavs };

		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function favoritePrograms(
	state = {},
	action: FavoriteProgramsActions
): IStateFavoritePrograms {
	const newState = getNewFavoriteProgramsState(state, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("favoritePrograms", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export interface IStateFavoritePrograms {
	favorites?: IFavPrograms;
	lastFavUpdate?: Date;
}
