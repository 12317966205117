import { IRUser } from "@app/api/users/helper-schemas";
import {
	canAccessUni,
	canAccessUniNewsAndEVents,
	canEditUniContent,
	canEditUniSettings,
	canSendUniSMS,
} from "@app/permissions";
import AccessAlarm from "@material-ui/icons/AccessAlarm";
import UniIcon from "@material-ui/icons/AccountBalance";
import EduFairIcon from "@material-ui/icons/SchoolRounded";
import UserIcon from "@material-ui/icons/AccountBox";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Chat from "@material-ui/icons/Chat";
import BurgerIcon from "@material-ui/icons/Dehaze";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import LiveVideoIcon from "@material-ui/icons/OndemandVideo";
import VideoIcon from "@material-ui/icons/Videocam";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ViewListIcon from "@material-ui/icons/ViewList";
import { History } from "history";
import * as React from "react";
import { match } from "react-router";
import "./styles/adminLeftNavigation.min.css";
import SMSIcon from "@material-ui/icons/Sms";
import CalculatorSVG from "./styles/img/Calculator";
import HelpIcon from "@material-ui/icons/LiveHelp";

interface IProps {
	history: History;
	match: match<any>;
	userData: IRUser;
}

const mainAdminPaths = [
	"/admin/",
	"/admin/all-admins",
	"/admin/toulator",
	"/admin/lator",
	"/admin/expo",
];

class AdminLeftNavigation extends React.Component<IProps> {
	state = {
		fixed_bar: false,
		navbarShorten: false,
		shortNavBarOpen: false,
		shortNavBarRemoving: false,
	};

	componentDidMount() {
		this.checkForResize();
		window.addEventListener("resize", this.checkForResize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.checkForResize);
	}

	checkForResize = () => {
		const wid = window.innerWidth;
		if (wid <= 1200 && !this.state.navbarShorten) {
			this.setState({ navbarShorten: true, shortNavBarOpen: false });
		} else if (wid > 1200 && this.state.navbarShorten) {
			this.setState({ navbarShorten: false, shortNavBarOpen: false });
		}
	};

	toggleNavBar = () => {
		this.setState({
			shortNavBarOpen: !this.state.shortNavBarOpen,
		});
	};

	onBlackClick = () => {
		if (!this.state.navbarShorten || !this.state.shortNavBarOpen) return;
		this.setState({
			shortNavBarOpen: false,
		});
	};

	preventBubble = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	render() {
		const { path, params } = this.props.match;
		const { userData } = this.props;
		const showNavComponenets = {
			showStatistics: false,
			showEditUni: false,
			showEditUniContent: false,
			showSMSGroups: false,
			showNews: false,
			showEvents: false,
		};
		if (
			params &&
			params.uni_id &&
			canAccessUni(+params.uni_id, userData.permissions)
		) {
			if (canEditUniSettings(+params.uni_id, userData.permissions)) {
				showNavComponenets.showEditUni = true;
			}
			if (canEditUniContent(+params.uni_id, userData.permissions)) {
				showNavComponenets.showEditUniContent = true;
			}
			if (canSendUniSMS(+params.uni_id, userData.permissions)) {
				showNavComponenets.showSMSGroups = true;
			}
			if (
				canAccessUniNewsAndEVents(+params.uni_id, userData.permissions)
			) {
				showNavComponenets.showNews = true;
				showNavComponenets.showEvents = true;
			}
		}
		showNavComponenets.showStatistics = false;

		const adminUniPath = "/admin/uni/";
		return (
			<div className="adminLeftNavigationWrapper">
				{this.state.navbarShorten && (
					<div className="burgerIcon" onClick={this.toggleNavBar}>
						<BurgerIcon style={{ fill: "white" }} />
					</div>
				)}
				{(!this.state.navbarShorten || this.state.shortNavBarOpen) && (
					<div
						className="adminLeftNavigationBlack"
						onClick={this.onBlackClick}
					>
						<div
							className="adminLeftNavigation"
							onClick={this.preventBubble}
						>
							<NavBarComponent
								Icon={ViewListIcon}
								descriptionName="კატალოგი"
								onClick={() => this.props.history.push("/")}
							/>
							{mainAdminPaths.includes(path) && (
								<React.Fragment>
									<NavBarComponent
										Icon={UniIcon}
										descriptionName="უნივერსიტეტები"
										onClick={() =>
											this.props.history.push("/admin/")
										}
									/>
									<NavBarComponent
										Icon={UserIcon}
										descriptionName="ადმინები"
										onClick={() =>
											this.props.history.push(
												"/admin/all-admins"
											)
										}
									/>
									<NavBarComponent
										Icon={CalculatorSVG}
										descriptionName="თოულატორი"
										onClick={() =>
											this.props.history.push(
												"/admin/toulator"
											)
										}
									/>
									<NavBarComponent
										Icon={CalculatorSVG}
										descriptionName="მურწყულატორი"
										onClick={() =>
											this.props.history.push(
												"/admin/lator"
											)
										}
									/>
									<NavBarComponent
										Icon={EduFairIcon}
										descriptionName="უნივერსიტეტების გამოფენა"
										onClick={() =>
											this.props.history.push(
												"/admin/expo"
											)
										}
									/>
									<NavBarComponent
										Icon={HelpIcon}
										descriptionName="კონსულტაციის მოთხოვნები"
										onClick={() =>
											this.props.history.push(
												"/admin/consultation-requests"
											)
										}
									/>
								</React.Fragment>
							)}
							{path === "/admin/" ? null : !!params &&
							  !!params.uni_id ? (
								<React.Fragment>
									{showNavComponenets.showStatistics && (
										<NavBarComponent
											Icon={ShowChartIcon}
											descriptionName="სტატისტიკა"
											onClick={() =>
												this.props.history.push(
													adminUniPath + params.uni_id
												)
											}
										/>
									)}
									<NavBarComponent
										Icon={ListIcon}
										descriptionName="ფაკულტეტები"
										onClick={() =>
											this.props.history.push(
												adminUniPath +
													params.uni_id +
													"/programs"
											)
										}
									/>
									{showNavComponenets.showEditUniContent && (
										<NavBarComponent
											Icon={EditIcon}
											descriptionName="კონტენტის შეცვლა"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/edit_content"
												)
											}
										/>
									)}
									{showNavComponenets.showEditUniContent && (
										<NavBarComponent
											Icon={LiveVideoIcon}
											descriptionName="LIVE ვიდეოები"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/live-videos"
												)
											}
										/>
									)}
									{showNavComponenets.showEditUniContent && (
										<NavBarComponent
											Icon={VideoIcon}
											descriptionName="საგამოფენო ვიდეო"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/expo-video"
												)
											}
										/>
									)}
									{showNavComponenets.showEditUni && (
										<NavBarComponent
											Icon={SettingsIcon}
											descriptionName="უნივერსიტეტის შეცვლა"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/edit"
												)
											}
										/>
									)}
									{showNavComponenets.showSMSGroups && (
										<NavBarComponent
											Icon={SMSIcon}
											descriptionName="SMS-ები"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/sms_groups"
												)
											}
										/>
									)}
									{showNavComponenets.showNews && (
										<NavBarComponent
											Icon={AccessAlarm}
											descriptionName="სიახლეები"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/news"
												)
											}
										/>
									)}
									{showNavComponenets.showEvents && (
										<NavBarComponent
											Icon={CalendarToday}
											descriptionName="ღონისძიებები"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/events"
												)
											}
										/>
									)}
									{!!userData.permissions &&
										userData.permissions.canAddAdmins && (
											<NavBarComponent
												Icon={PersonAddIcon}
												descriptionName="ადმინის დანიშვნა"
												onClick={() =>
													this.props.history.push(
														adminUniPath +
															params.uni_id +
															"/add_admin"
													)
												}
											/>
										)}
									{/* TODO: Show this only for particular admins */}
									{(+params.uni_id !== 66 ||
										userData.permissions!.privilege[
											"allUnis"
										]) && (
										<NavBarComponent
											Icon={Chat}
											descriptionName="ჩატი"
											onClick={() =>
												this.props.history.push(
													adminUniPath +
														params.uni_id +
														"/chat"
												)
											}
										/>
									)}
								</React.Fragment>
							) : null}
						</div>
					</div>
				)}
			</div>
		);
	}
}

interface INavBarComponentProps {
	Icon: any;
	descriptionName: string;
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const NavBarComponent: React.FC<INavBarComponentProps> = props => {
	const { Icon } = props;
	return (
		<div className="general_css_tabnames" onClick={props.onClick}>
			<span className="general_css_icon_settings">
				<Icon />
			</span>
			<span>{props.descriptionName}</span>
		</div>
	);
};

export default AdminLeftNavigation;
