/* eslint-disable no-restricted-globals */
import { uniRemoveInfoFromTemp } from "@app/actions/temp";
import {
	getUniById,
	getUniByUrlName,
	IGetUniById,
	IGetUniByUrlName,
} from "@app/actions/unis";
import api from "@app/api";
import {
	IRUniversity,
	IUniContent,
	IUniMainInfo,
} from "@app/api/unis/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import "@app/components/styles/quillCustomTheme.min.css";
import {
	canEditUniContent,
	canAccessUni,
	canAccessAllUnis,
	canAccessAllProgramsOfUni,
} from "@app/permissions";
import { IRootState } from "@app/reducers/root";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { History } from "history";
import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { match } from "react-router";
import * as uuid from "uuid/v4";
import FancyInput from "../Widgets/FancyInput";
import SnackNotification from "../Widgets/SnackNotification";
import { AdminProgramAndUniContentLoader } from "./AdminProgramContentEditPage";
import ContentMedias from "./ContentMedias";
import "./styles/contentEdit.min.css";
import {
	DragDropContext,
	Droppable,
	Draggable,
	DropResult,
} from "react-beautiful-dnd";
import SimpleExpansionPanel from "../Widgets/SimpleExpansionPanel";
import { NotUndefined } from "@app/utils/generics";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	getUniById: IGetUniById;
	getUniByUrlName: IGetUniByUrlName;
	uniRemoveInfoFromTemp: typeof uniRemoveInfoFromTemp;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	loadingInfo: boolean;
	essentialInfo?: IRUniversity;
	mainInfo?: IUniMainInfo;
	contents?: { [id: string]: IUniContent & { newlyCreated?: true } };
	errorDialogOpen: boolean;
	errorMessage: string;
}

class AdminUniContentEditPage extends React.Component<IProps, IState> {
	uni_id: number;
	_isMounted?: boolean;

	contentChangedInfo: {
		[id: string]: Partial<IUniContent> & { newlyCreated?: true };
	} = {};
	contentDeletedIds: number[] = [];
	allowDrag = false;

	state = {
		loadingInfo: true,
		errorDialogOpen: false,
		errorMessage: "",
	} as IState;

	constructor(props: IProps) {
		super(props);
		const { uni_id } = props.match.params;
		if (!uni_id) {
			return;
		}
		this.uni_id = +uni_id;
		if (!canEditUniContent(this.uni_id, props.userData.permissions)) {
			this.props.history.replace("/admin/uni/" + this.uni_id);
			return;
		}
		if (this.props.unis) {
			const uni = this.props.unis.find(each => each.id === this.uni_id);
			if (typeof uni !== "undefined") {
				this.state.essentialInfo = uni;
			}
		}
	}

	getUni = (): ReturnType<IGetUniById> => {
		return api.unis.getById({
			id: this.uni_id,
			getContents: true,
		});
	};

	componentDidMount = () => {
		api.unis.content
			.getUniContentByType({ type: "PDF" })
			.then(data => console.log(data));
		this._isMounted = true;
		this.getUni().then(data => {
			if (!this._isMounted) return;
			const additionalFields = {} as IState;
			const { contents, ...essentials } = data;
			if (Object.keys(essentials).length)
				additionalFields.essentialInfo = essentials as IState["essentialInfo"];
			if (contents) {
				const contentsObj = {};
				contents.forEach(cont => (contentsObj[cont.id] = cont));
				additionalFields.contents = contentsObj;
			}
			this.uni_id = data.id;
			if (this.props.userData.permissions) {
				if (
					canAccessUni(
						this.uni_id,
						this.props.userData.permissions
					) &&
					(canAccessAllUnis(
						this.props.userData.permissions.privilege
					) ||
						canAccessAllProgramsOfUni(
							this.props.userData.permissions.privilege
						))
				) {
					this.allowDrag = true;
				} else {
					this.allowDrag = false;
				}
			}
			this.setState({
				loadingInfo: false,
				...additionalFields,
			});
		});
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	onTextChange = (id: number | string) => (text: string) => {
		if (!this.contentChangedInfo[id]) this.contentChangedInfo[id] = {};
		this.contentChangedInfo[id].text = text;
	};

	onTitleChange = (id: number | string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!this.contentChangedInfo[id]) this.contentChangedInfo[id] = {};
		this.contentChangedInfo[id].title = e.target.value;
	};

	addContent = () => {
		let lastPos = 0;
		if (this.state.contents) {
			for (const id of Object.keys(this.state.contents)) {
				lastPos = Math.max(lastPos, this.state.contents[id].position);
			}
		}
		const newContent = {
			id: (uuid() as any) as number,
			galleryMedias: null,
			position: lastPos + 1,
			text: "",
			title: null,
			uni_id: this.uni_id,
			newlyCreated: true as true,
			created_at: new Date(),
			updated_at: new Date(),
		};
		this.setState({
			contents: {
				...this.state.contents!,
				[newContent.id]: newContent,
			},
		});
		this.contentChangedInfo[newContent.id] = newContent;
		delete this.contentChangedInfo[newContent.id].id;
	};

	deleteContent = (id: number | string) => {
		if (confirm("ნამდვილად გსურთ ამოშლა?")) {
			if (!this.state.contents![id].newlyCreated) {
				this.contentDeletedIds.push(+id);
			}
			delete this.contentChangedInfo[id];
			const newContents: IState["contents"] = { ...this.state.contents };
			delete newContents[id];
			this.setState({
				contents: newContents,
			});
		}
	};

	handleErrorDialogClose = () => {
		this.setState({
			errorDialogOpen: false,
		});
	};

	saveUniContent = () => {
		if (
			Object.keys(this.contentChangedInfo).length === 0 &&
			this.contentDeletedIds.length === 0
		)
			return;
		const removeLoader = addLoader();
		api.unis.content
			.saveContent({
				uni_id: this.uni_id,
				changedInfo: this.contentChangedInfo,
				deletedIds: this.contentDeletedIds,
			})
			.then(() => {
				removeLoader();
				this.props.uniRemoveInfoFromTemp(this.uni_id);
				if (!this._isMounted) return;
				this.props.history.push(
					"/unis/" + this.state.essentialInfo!.urlName
				);
			})
			.catch(err => {
				removeLoader();
				if (!this._isMounted) return;
				if (err.response && Array.isArray(err.response.data)) {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "" + err.response.data[0].errorText, // TODO: translate in Georgian
					});
				} else {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "დაფიქსირდა შეცდომა",
					});
				}
			});
	};

	onGalleryChange = (id: number | string) => (
		galleryMedia: IUniContent["galleryMedias"]
	) => {
		if (this.contentChangedInfo[id] === undefined)
			this.contentChangedInfo[id] = {};
		this.contentChangedInfo[id].galleryMedias = galleryMedia;
	};

	onDragEnd = (result: DropResult) => {
		if (!result.destination || !this.state.contents) {
			return;
		}
		const endIndex: number = result.destination.index;
		const currentIndex: number = result.source.index;
		const newContentPositions: { [id: string]: number } = {};
		const contents: IState["contents"] = this.state.contents;
		const orderedContentIds: string[] = Object.keys(
			this.state.contents
		).sort((id1, id2) => contents[id1].position - contents[id2].position);
		if (endIndex > currentIndex) {
			for (let i = currentIndex + 1; i <= endIndex; i++) {
				const contentId = contents[orderedContentIds[i]].id;
				if (!this.contentChangedInfo[contentId])
					this.contentChangedInfo[contentId] = {};
				const newPos = contents[contentId].position - 1;
				this.contentChangedInfo[contentId].position = newPos;
				newContentPositions[contentId] = newPos;
			}
		}
		if (endIndex < currentIndex) {
			for (let i = currentIndex - 1; i >= endIndex; i--) {
				const contentId = contents[orderedContentIds[i]].id;
				if (!this.contentChangedInfo[contentId])
					this.contentChangedInfo[contentId] = {};
				const newPos = contents[contentId].position + 1;
				this.contentChangedInfo[contentId].position = newPos;
				newContentPositions[contentId] = newPos;
			}
		}
		const currContentId = orderedContentIds[currentIndex];
		const endPosition = contents[orderedContentIds[endIndex]].position;
		if (!this.contentChangedInfo[currContentId])
			this.contentChangedInfo[currContentId] = {};
		this.contentChangedInfo[currContentId].position = endPosition;
		newContentPositions[currContentId] = endPosition;
		const newContents: IState["contents"] = {};
		for (const id of Object.keys(this.state.contents)) {
			newContents[id] = {
				...this.state.contents[id],
				position:
					newContentPositions[id] || this.state.contents[id].position,
			};
		}
		this.setState({
			contents: newContents,
		});
	};
	render() {
		const contents: IState["contents"] = this.state.contents;
		if (!contents) return <AdminProgramAndUniContentLoader />;
		const contentIds: string[] = Object.keys(contents).sort(
			(id1, id2) => contents[id1].position - contents[id2].position
		);
		return (
			<div className="contentEditPage">
				<div className="main programWrapper">
					<div className="header">პანელები</div>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div ref={provided.innerRef}>
									{!!contents &&
										contentIds.map((id, index) => (
											<Draggable
												isDragDisabled={!this.allowDrag}
												key={id}
												draggableId={id.toString()}
												index={index}
											>
												{(provided2, snapshot2) => (
													<div
														ref={provided2.innerRef}
														{...provided2.draggableProps}
														{...provided2.dragHandleProps}
													>
														<SimpleExpansionPanel
															title={
																contents[id]
																	.title || ""
															}
														>
															<SingleContent
																id={id}
																contents={
																	contents
																}
																deleteContent={
																	this
																		.deleteContent
																}
																onTitleChange={this.onTitleChange(
																	id
																)}
																onTextChange={this.onTextChange(
																	id
																)}
																onGalleryChange={
																	this
																		.onGalleryChange
																}
															/>
														</SimpleExpansionPanel>
													</div>
												)}
											</Draggable>
										))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				<button className="addContent" onClick={this.addContent}>
					<span>პანელის დამატება</span>
					<AddIcon />
				</button>
				<br />
				<button
					className="adminPrimaryButton"
					onClick={this.saveUniContent}
				>
					შენახვა
				</button>
				<SnackNotification
					autoHideDuration={6000}
					onClose={this.handleErrorDialogClose}
					message={this.state.errorMessage}
					variant="error"
					open={this.state.errorDialogOpen}
				/>
			</div>
		);
	}
}

interface ISingleContentProps {
	id: number | string;
	contents: NotUndefined<IState["contents"]>;
	deleteContent: (id: number | string) => void;
	onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onTextChange: (text: string) => void;
	onGalleryChange: (
		id: number | string
	) => (galleryMedia: IUniContent["galleryMedias"]) => void;
}
const SingleContent: React.FC<ISingleContentProps> = (
	props: ISingleContentProps
) => (
	<div className="editDiv">
		<button
			className="deleteContent"
			onClick={() => props.deleteContent(props.id)}
		>
			<span>ამოშლა</span>
			<DeleteIcon />
		</button>
		<div className="stick lh" />
		<div className="stick lv" />
		<div className="stick rh" />
		<div className="stick rv" />
		<FancyInput
			defaultValue={props.contents[props.id].title || ""}
			title="პანელის სახელი"
			onChange={props.onTitleChange}
		/>
		<h1>ტექსტი</h1>
		<div className="QuillContainer quill--white--theme">
			<ReactQuill
				defaultValue={props.contents[props.id].text || ""}
				onChange={props.onTextChange}
			/>
		</div>
		<ContentMedias
			galleryMedias={props.contents[props.id].galleryMedias}
			onChange={props.onGalleryChange(props.id)}
			uploadImageFunc={api.unis.content.uploadPhotos as any}
			uploadPdfFunc={api.unis.content.uploadPDFs as any}
			galleryPath="gallery"
		/>
	</div>
);

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	tempUnis: state.temp.unis,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({
		getUniById,
		getUniByUrlName,
		uniRemoveInfoFromTemp,
	} as unknown) as IDispatchProps
)(AdminUniContentEditPage);
