import { ISubscribedUnis } from "@app/api/users/unis/validators";
import { SubscribedUnisActions } from "@app/actions/subscribed-unis";
import {
	LOAD_SUBSCRIBED_UNIS,
	ADD_SUBSCRIPTION_TO_UNI,
	REMOVE_SUBSCRIPTION_FROM_UNI,
	USER_LOG_OUT,
} from "../action-types";

function getNewSubscribedUnisState(
	state: IStateSubscribedUnis = {},
	action: SubscribedUnisActions
): IStateSubscribedUnis {
	if (typeof action.type === "undefined") return state;
	switch (action.type) {
		case LOAD_SUBSCRIBED_UNIS:
			return {
				...state,
				subscribed: action.unis,
				lastUpdate: action.lastUpdate,
			};

		case ADD_SUBSCRIPTION_TO_UNI:
			return {
				...state,
				subscribed: {
					...(state.subscribed || {}),
					[action.id]: {
						uni_id: action.id,
						time: new Date(),
					},
				},
			};

		case REMOVE_SUBSCRIPTION_FROM_UNI:
			if (typeof state.subscribed === "undefined")
				return { ...state, subscribed: {} };
			const newFavs = { ...state.subscribed };
			delete newFavs[action.id];
			return { ...state, subscribed: newFavs };

		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function subscribedUnis(
	state = {},
	action: SubscribedUnisActions
): IStateSubscribedUnis {
	const newState = getNewSubscribedUnisState(state, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("subscribedUnis", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export interface IStateSubscribedUnis {
	subscribed?: ISubscribedUnis;
	lastUpdate?: Date;
}
