import { Requests } from "@app/api";
import {
	IAPUTInsertOrUpdateFavorite,
	IRGETFavorites,
	IRPUTInsertOrUpdateFavorite,
	RGETFavoritesSchema,
	RPUTInsertOrUpdateFavoriteSchema,
} from "./validators";

const usersPrograms = {
	getFavorites: (): Promise<IRGETFavorites> =>
		Requests.send("GET", "/api/users/favorites", {}, null, {
			responseSchema: RGETFavoritesSchema,
		}),
	insertOrUpdateFovorite: (
		data: IAPUTInsertOrUpdateFavorite
	): Promise<IRPUTInsertOrUpdateFavorite> =>
		Requests.send("PUT", "/api/users/favorites", data, null, {
			responseSchema: RPUTInsertOrUpdateFavoriteSchema,
		}),
};
export default usersPrograms;
