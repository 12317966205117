import * as React from "react";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import { CalendarBody, ICalendarProps } from "./CalendarCommonParts";
import { monthNamesGeo } from "@app/commonJavascript";
import "./styles/calendars.min.css";

interface IProps extends ICalendarProps{
	headerBgColor: string;
	onEventDayClick?: (event_date: Date) => void;
	eventDates?: {
		[key: string]: string;
	};
	onSeeMore: () => void;
}

class CalendarOfEvents extends React.Component<IProps> {
	static defaultProps = {
		bgColor: "#fff",
		headerBgColor: "linear-gradient(90deg, #7c169c, #f14391)",
		mainColor: "#f830fb",
		textColor: "#666",
		textColor2: "#fff"
	};

	state = {
		selectedDate: new Date(),
		displayDate: new Date(),
	};

	nextMonth = () => {
		const newDate = new Date(this.state.displayDate.getTime());
		newDate.setMonth(newDate.getMonth() + 1);
		this.setState({
			displayDate: newDate
		});
	};

	prevMonth = () => {
		const newDate = new Date(this.state.displayDate.getTime());
		newDate.setMonth(newDate.getMonth() - 1);
		this.setState({
			displayDate: newDate
		});
	};

	onSelectedDateChange = (time) => {
		this.setState({
			selectedDate: new Date(time),
			displayDate: new Date(time)
		});
		if (this.props.onEventDayClick) {
			this.props.onEventDayClick(new Date(time));
		}
	}

	render() {
		return (
			<div className="mur-calendarOfEvents mur-calendarWidget" style={{ background: this.props.bgColor }}>
				<CalendarOfEventsHeader
					displayDate={this.state.displayDate}
					selectedDate={this.state.selectedDate}
					nextMonth={this.nextMonth}
					prevMonth={this.prevMonth}
					bgColor={this.props.headerBgColor}
				/>
				<div className="bodyCont">
					<CalendarBody
						displayDate={this.state.displayDate}
						selectedDate={this.state.selectedDate}
						onSelectedDateChange={this.onSelectedDateChange}
						mainColor={this.props.mainColor}
						textColor={this.props.textColor}
						textColor2={this.props.textColor2}
						eventDates={this.props.eventDates}
						onEventDayClick={this.props.onEventDayClick}
					/>
				</div>
				<button className="main-button" onClick={this.props.onSeeMore}>ყველას ნახვა</button>
			</div>
		);
	}
}

const CalendarOfEventsHeader = (props) => {
	return (
		<div className="header" style={{
			background: `linear-gradient(60deg, #ffffff00, #ffffff14 50%, #ffffff40 50%, #ffffff00 70%), ${props.bgColor}`
		}}>
			<div className="title">ღონისძიებები</div>
			<div className="month">
				<div className="date prev" onClick={props.prevMonth}><LeftIcon /></div>
				<div className="date">{monthNamesGeo[props.displayDate.getMonth()]}</div>
				<div className="date next" onClick={props.nextMonth}><RightIcon /></div>
			</div>
		</div>
	);
};

export default CalendarOfEvents;
