import api from "@app/api";
import { getExpoBannerUrl } from "@app/commonJavascript";
import ImageCrop from "@app/components/Widgets/ImageCrop";
import UploadButton from "@app/components/Widgets/UploadButton";
import * as React from "react";

interface IProps {
	url: string | null;
	uni_id: number;
	onUpload: (fileName: string) => void;
	width?: number;
	height?: number;
	borderRadius?: number;
	type: "expoMainBanner" | "expoSecondaryBanner";
	buttonText?: string;
}

interface IState {
	pictureIsUploaded: boolean;
	file: null | File;
	uploading: boolean;
	lastSuccessPhoto?: string;
}

class BannerUpload extends React.Component<IProps, IState> {
	state: IState = {
		pictureIsUploaded: false,
		file: null,
		uploading: false,
	};

	width = this.props.width || 250;
	height = this.props.height || 250;
	borderRadius = this.props.borderRadius || 0;

	fileChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
		const file = e.target.files[0];
		this.setState({
			pictureIsUploaded: true,
			file,
		});
	};

	cancelImageUpload = () => {
		this.setState({
			pictureIsUploaded: false,
			file: null,
		});
	};

	uploadImage = (img: Blob) => {
		const formData = new FormData();
		formData.append("pict", img);
		formData.append("uni_id", "" + this.props.uni_id);
		formData.append("picType", this.props.type);
		formData.append("width", `${this.width}`);
		formData.append("height", `${this.height}`);
		console.log([...(formData as any).entries()]);

		api.unis
			.uploadPhotos(formData as any)
			.then(data => {
				this.props.onUpload(data.fileName);
				this.setState({
					uploading: false,
					pictureIsUploaded: false,
					file: null,
					lastSuccessPhoto: data.fileName,
				});
			})
			.catch(err => {
				this.setState({
					uploading: false,
				});
			});
	};
	render() {
		return (
			<div style={{ margin: "40px 0px", textAlign: "center" }}>
				<h3 style={{ margin: 0 }}>
					{this.width}X{this.height}
				</h3>
				{!this.state.pictureIsUploaded && (
					<React.Fragment>
						{(this.state.lastSuccessPhoto || this.props.url) && (
							<div
								className="logo"
								style={{
									backgroundImage: `url(${getExpoBannerUrl(
										(this.state.lastSuccessPhoto ||
											this.props.url) as string
									)})`,
									backgroundPosition: "center",
									backgroundRepeat: "no-repeat",
									backgroundSize: "90%",
									borderRadius: this.borderRadius,
									width: this.width,
									height: this.height,
									display: "inline-block",
								}}
							/>
						)}
						<br />
						<UploadButton
							onChange={this.fileChangedHandler}
							multiple={false}
							text={this.props.buttonText}
						/>
						{this.state.lastSuccessPhoto && (
							<div>ბანერი წარმატებით აიტვირთა</div>
						)}
					</React.Fragment>
				)}
				{!!this.state.pictureIsUploaded && !!this.state.file && (
					<div>
						<ImageCrop
							bgColor={"transparent"}
							border={0}
							borderRadius={this.borderRadius}
							width={this.width}
							height={this.height}
							image={this.state.file}
							onCancel={this.cancelImageUpload}
							onSubmit={this.uploadImage}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default BannerUpload;
