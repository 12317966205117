import Joi, { SchemaToType } from "@app/utils/joi";

const RGETReservationSchema = Joi.object().keys({
	id: Joi.number().required(),
	user_id: Joi.number().required(),
	live_id: Joi.number().required(),
	/// TODO: remove below two
	created_at: Joi.date(),
	updated_at: Joi.date(),
});

export type IRGETReservation = SchemaToType<typeof RGETReservationSchema>;

export const RGETReservationsSchema = Joi.array()
	.items(RGETReservationSchema)
	.required();

///

export const RGETReservationsCountSchema = Joi.object().keys({
	count: Joi.number().required(),
});

///

export const APOSTReservationSchema = Joi.object().keys({
	live_id: Joi.number()
		.integer()
		.required(),
});

export const RPOSTReservationSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	user_id: Joi.number()
		.integer()
		.required(),
	live_id: Joi.number()
		.integer()
		.required(),
	/// TODO: remove below two
	created_at: Joi.date(),
	updated_at: Joi.date(),
});

export type IAPOSTReservation = SchemaToType<typeof APOSTReservationSchema>;
export type IRPOSTReservation = SchemaToType<typeof RPOSTReservationSchema>;
///
