import Joi, { SchemaToType } from "@app/utils/joi";

export const GalleryMediaSchema = Joi.object().keys({
	type: Joi.string()
		.valid("PHOTO", "VIDEO", "PDF")
		.required(),
	url: Joi.string().required(),
	isLarge: Joi.boolean(),
	title: Joi.string(),
	alt: Joi.string(),
});

export type IGalleryMedia = SchemaToType<typeof GalleryMediaSchema>;

export enum UserType {
	DEVELOPER = "DEVELOPER",
	ADMIN = "ADMIN",
	UNIADMIN = "UNIADMIN",
	USER = "USER",
}

export enum UserTypes {
	GRADE_11 = "11TH GRADER",
	GRADE_12 = "12TH GRADER",
	HIGH_SCHOOL_GRADUATE = "HIGH SCHOOL GRADUATE",
	STUDENT = "STUDENT",
	TEACHER_OR_TUTOR = "TEACHER/TUTOR",
	OTHER = "OTHER",
}
