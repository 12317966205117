import * as React from "react";

const AllOffersPopup = props => (
	<div className="all-offers-popup-container">
		<div className="top-offers">
			<div className="offer">
				<div className="header">TOP 500</div>
				<div className="content">
					<div>
						<button onClick={props.closePopup}>
							უკან დაბრუნება
						</button>
					</div>
					<div>
						<p>
							აბიტურიენტი, რომელიც კავკასიის უნივერსიტეტის
							ნებისმიერ საგანმანათლებლო პროგრამას პირველ
							პრიორიტეტად აირჩევს და ეროვნულიგამოცდების საკონკურსო
							ქულით 500 საუკეთესოს შორის მოხვდება, 4 წლის
							განმავლობაში ისწავლის უფასოდ!
						</p>
					</div>
				</div>
			</div>
			<div className="offer">
				<div className="header">CU გრანტი</div>
				<div className="content">
					<div>
						<button onClick={props.closePopup}>
							უკან დაბრუნება
						</button>
					</div>
					<div>
						<p>
							აბიტურიენტი, რომელიც კავკასიის უნივერსიტეტის
							პროგრამებს პირველ პრიორიტეტად აირჩევს და მოიპოვებს
							100%-იან ან 70%-იან სახელმწიფო გრანტს, მიიღებს შიდა
							საუნივერსიტეტო დაფინანსებას 4 წლის განმავლობაში.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div className="bottom-offers">
			<div className="offer">
				<div className="header">კურსის რეიტინგული სამეული</div>
				<div className="content">
					<div>
						<button onClick={props.closePopup}>
							უკან დაბრუნება
						</button>
					</div>
					<div>
						<p>
							100%, 50% და 30%-იანი დაფინანსება ყველა
							ფაკულტეტის/სკოლის თითოეული კურსის TOP სამეულს.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AllOffersPopup;
