import * as React from "react";
import memoizeOne from "memoize-one";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { getUnisEssentialInfo } from "@app/actions/unis";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { UniLogo } from "../Universities/FreeUniversityPage";

const PremiumUniLogoLists = styled("div")({
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "center",
	div: {
		width: 100,
		height: 100,
		borderRadius: 20,
		margin: 10,
		cursor: "pointer",
		transition: "0.3s",
		"&:hover": {
			transform: "scale(1.1)",
		},
		"@media (max-width: 500px)": {
			width: 50,
			height: 50,
			borderRadius: 7,
			margin: 5,
		},
	},
});

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

class PremiumUnisLogos extends React.PureComponent<IProps> {
	getPremiumUnis = memoizeOne((unis: IRUniversity[]): IRUniversity[] => {
		return unis
			.filter(uni => uni.premium)
			.sort(
				(uni1, uni2) =>
					uni2.pagePositionCoefficient - uni1.pagePositionCoefficient
			);
	});

	componentDidMount() {
		if (!this.props.unis) {
			this.props.getUnisEssentialInfo().then();
		}
	}

	redirect = link => {
		this.props.history.push(link);
	};

	render() {
		const unis = this.props.unis;
		if (!unis) return null;
		const premiumUnis = this.getPremiumUnis(unis);
		return (
			<div className="main main2">
				<PremiumUniLogoLists>
					{premiumUnis.map(uni => (
						<UniLogo
							key={uni.id}
							logoUrl={uni.logoUrl}
							logoBGColor={uni.logoBGColor}
							onClick={() =>
								this.redirect(`/unis/${uni.urlName}`)
							}
						/>
					))}
				</PremiumUniLogoLists>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

const mapDispatchToProps = {
	getUnisEssentialInfo,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(PremiumUnisLogos);
