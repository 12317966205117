import React, { useCallback, useMemo, useState } from "react";
import "../styles/expo-main-page.min.css";
import SearchIcon from "../img/SearchIcon.svg";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
	UniversityWithCoordinates,
	useUniversitiesWithCoordinates,
} from "../hooks/unis-with-coordinates";

interface ExpoMainSearchProps {
	setFocusedUni: (x: UniversityWithCoordinates) => void;
}

const ExpoMainSearch: React.FC<ExpoMainSearchProps> = React.memo(
	function ExpoMainSearch({ setFocusedUni }) {
		const [showUnis, setShowUnis] = useState<boolean>(false);
		const [inputValue, setInputValue] = useState<string>("");
		const universities = useUniversitiesWithCoordinates();

		const filteredUnis = useMemo(() => {
			return universities
				? universities.filter(uni =>
						uni.name ? uni.name.includes(inputValue) : false
				  )
				: [];
		}, [inputValue, universities]);

		const handleInputChange = useCallback((newInput: string) => {
			setInputValue(newInput);
			setShowUnis(true);
		}, []);

		const handleSearchUniClick = useCallback(
			(e: UniversityWithCoordinates) => () => {
				setInputValue(e.name!);
				setFocusedUni(e);
				setShowUnis(false);
			},
			[setFocusedUni]
		);

		return (
			<>
				<div className="searchContainer">
					<img src={SearchIcon} alt="search" />
					<input
						placeholder=" მოძებნე უნივერსიტეტი"
						value={inputValue}
						onChange={e => handleInputChange(e.target.value)}
					/>
					{showUnis && (
						<div onClick={() => setShowUnis(false)}>
							<ArrowUpIcon />
						</div>
					)}
					{!showUnis && (
						<div onClick={() => setShowUnis(true)}>
							<ArrowDownIcon />
						</div>
					)}
				</div>
				<div className="searchOptionsContainer">
					{showUnis &&
						filteredUnis.map((e, i) => (
							<div
								className="searchOption"
								key={i}
								onClick={handleSearchUniClick(e)}
							>
								{e.name!}
							</div>
						))}
				</div>
			</>
		);
	}
);

export default ExpoMainSearch;
