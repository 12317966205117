import * as React from "react";
import * as SearchWorker from "@app/workers/SearchWorker";
import CloseIcon from "@material-ui/icons/Close";
import memoizeOne from "memoize-one";
import SearchIcon from "@material-ui/icons/Search";
import SmallProgram from "@app/components/Programs/ProgramViews";
import triggerEvent from "@app/utils/events";
import { _console } from "@app/commonJavascript";
import { FOnInputSeach } from "../MainPage";
import { History } from "history";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { sortProgramsByCoeff } from "../MainPageComponents/search/searchAlgorithms";
import "./styles/uni_programs.min.css";
import "./styles/freeUni.min.css";
import { getFormattedMessage } from "@app/utils/locale";

interface IProps {
	programs?: IRProgram[];
	history: History;
}

interface IState {
	showSearchResults: boolean;
	SearchResults: { programs: IRProgram[] };
}

class UniPrograms extends React.Component<IProps, IState> {
	state = {
		showSearchResults: false,
	} as IState;

	lastQuerySearchInfo = {
		lastSearchDate: new Date(0),
		lastChangeDate: new Date(0),
		lastSearchedQuery: "",
		lastQuery: "",
	};

	_isMounted: boolean;

	sortPrograms = memoizeOne(
		(programs: IRProgram[] | undefined): IRProgram[] => {
			if (!programs) return [];
			return sortProgramsByCoeff(programs);
		}
	);

	programsLoaded = memoizeOne(programs => {
		if (programs) {
			this.addSearchListener(programs);
			return true;
		}
		return false;
	});

	componentDidMount() {
		this._isMounted = true;
		if (this.props.programs) {
			this.addSearchListener();
		}
	}

	onSearchResult = data => {
		if (!this._isMounted) return;
		if (Array.isArray(data)) {
			this.setState({
				SearchResults: { programs: data },
				showSearchResults: true,
			});
		}
	};

	addSearchListener = (programs?: IRProgram[]) => {
		if (!(programs || this.props.programs)) return;
		SearchWorker.addListener(this.onSearchResult);
		if (programs) {
			SearchWorker.loadPrograms(programs);
		} else if (this.props.programs) {
			SearchWorker.loadPrograms(this.props.programs);
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
		SearchWorker.removeListener(this.onSearchResult);
	}

	goToProgram = (progUrlName: string) => (
		e: React.MouseEvent<HTMLDivElement>
	) => {
		this.props.history.push(`/programs/${progUrlName}`);
		let uni_id = undefined as undefined | number;
		if (this.props.programs) {
			const program = this.props.programs.find(
				p => p.urlName === progUrlName
			);
			if (program) uni_id = program.uni_id;
		}
		triggerEvent(
			{
				category: "Button",
				action: "Goto Program page",
				label: "From uni page",
			},
			{ progUrlName, uni_id }
		);
	};

	onSeachInputChange: FOnInputSeach = (
		query: string,
		forceSearch: boolean
	) => {
		let searchNow: boolean = forceSearch;
		const tresholdTime = 0.5 * 1000;
		const tresholdCharacterChangeLength = 5;
		if (this.lastQuerySearchInfo.lastSearchedQuery === query) {
			return;
		}
		this.lastQuerySearchInfo.lastQuery = query;
		this.lastQuerySearchInfo.lastChangeDate = new Date();
		if (!forceSearch) {
			if (
				Math.abs(
					this.lastQuerySearchInfo.lastSearchedQuery.length -
						query.length
				) > tresholdCharacterChangeLength
			) {
				searchNow = true;
			} else if (
				Date.now() -
					this.lastQuerySearchInfo.lastChangeDate.getTime() >=
					tresholdTime &&
				this.lastQuerySearchInfo.lastChangeDate.getTime() !== 0
			) {
				searchNow = true;
			} else {
				const cachedQuery = query;
				setTimeout(() => {
					const currentQueryValue = this.lastQuerySearchInfo
						.lastQuery;
					if (currentQueryValue !== cachedQuery) return;
					this.onSeachInputChange(currentQueryValue, true);
				}, Math.max(0, tresholdTime - (Date.now() - this.lastQuerySearchInfo.lastChangeDate.getTime())));
			}
		}
		if (searchNow) {
			if (query) {
				SearchWorker.query(query, "programs");
				triggerEvent(
					{
						category: "Search",
						action: "Type in searchbar",
						label: "",
					},
					{
						query,
					}
				);
			} else {
				this.setState({
					showSearchResults: false,
				});
			}
			this.lastQuerySearchInfo = {
				lastSearchDate: new Date(),
				lastChangeDate: new Date(),
				lastQuery: query,
				lastSearchedQuery: query,
			};
		}
	};

	render() {
		const loaded = this.programsLoaded(this.props.programs);
		const programs = this.sortPrograms(this.props.programs);
		return this.props.programs ? (
			<div className="main">
				{programs.length > 3 && (
					<SearchProgram
						onSeachInputChange={this.onSeachInputChange}
					/>
				)}
				{!this.state.showSearchResults && (
					<div className="uniPrograms" id="uniPrograms">
						{programs.map(prog => (
							<SmallProgram
								history={this.props.history}
								key={prog.id}
								onClick={this.goToProgram(prog.urlName)}
								program={prog}
							/>
						))}
					</div>
				)}
				{this.state.showSearchResults &&
					!!this.state.SearchResults &&
					(this.state.SearchResults.programs.length === 0 ? (
						<div style={{ textAlign: "center" }}>
							<h1>
								ფაკულტეტები არ მოიძებნა. დაწერეთ საძიებო
								სიტყვები სრულად, სცადეთ ძიება სხვა სიტყვებით ან
								შეამოწმეთ ორთოგრაფია
							</h1>
						</div>
					) : (
						<div className="uniPrograms" id="uniPrograms">
							{this.state.SearchResults.programs.map(prog => (
								<SmallProgram
									history={this.props.history}
									key={prog.id}
									onClick={this.goToProgram(prog.urlName)}
									program={prog}
								/>
							))}
						</div>
					))}
			</div>
		) : (
			<div />
		);
	}
}

interface ISearchProgram {
	onSeachInputChange: FOnInputSeach;
}
class SearchProgram extends React.Component<ISearchProgram> {
	state = {
		value: "",
	};
	onSetValue = (value: string) => {
		this.props.onSeachInputChange(value, false);
		this.setState({ value });
	};
	onValueChange = e => {
		const { value } = e.target;
		this.onSetValue(value);
	};
	resetValue = () => {
		this.onSetValue("");
	};

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			this.props.onSeachInputChange(this.state.value, true);
		}
	};
	onSearchClick = () => {
		this.props.onSeachInputChange(this.state.value, true);
	};

	render() {
		return (
			<div
				className="progsSearchBarContainer"
				id="progsSearchBarContainer"
			>
				<div className="partOfSearchBar">
					<input
						className="searchBar"
						type="text"
						value={this.state.value}
						placeholder={getFormattedMessage("searchPrograms")}
						onChange={this.onValueChange}
						onKeyPress={this.onKeyPress}
					/>
					{this.state.value.length > 0 && (
						<CloseIcon
							className="searchBarClose"
							onClick={this.resetValue}
						/>
					)}
				</div>
				<div className="searchIcon">
					<div className="VM" onClick={this.onSearchClick}>
						<SearchIcon style={{ fontSize: "3em" }} />
					</div>
				</div>
			</div>
		);
	}
}

export default UniPrograms;
