import { IUniContent } from "@app/api/unis/helper-schemas";
import {
	extractVideoIdFromYoutubeLink,
	getGalleryMediaUrl,
} from "@app/commonJavascript";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import OpenIcon from "@material-ui/icons/OpenInNewRounded";
import { css } from "emotion";
import * as React from "react";
import YouTube from "react-youtube";
import PDF from "../Widgets/pdf";
import GalleryLightbox from "./gallery-lightbox";

interface IProps {
	galleryMedias: IUniContent["galleryMedias"];
}

interface IState {
	mediaIndex: number;
	isOpen: boolean;
}

export const galleryMediaUrlToRealUrl = (url: string) => {
	return `/photos/gallery/${url}`;
};

export default class GalleryMedias extends React.Component<IProps, IState> {
	state: IState = {
		mediaIndex: 0,
		isOpen: false,
	};

	onCloseRequest = () => {
		this.setState({ isOpen: false });
	};

	openLightBox = (index: number) => {
		this.setState({ isOpen: true, mediaIndex: index });
	};

	redirectInNewTab = (url: string) => {
		if (window.location.hostname === "localhost")
			window.open(`http://localhost:8090${url}`, "_blank");
		else window.open(`https://unicatalog.ge${url}`, "_blank");
	};

	render() {
		if (!this.props.galleryMedias) return null;
		const { mediaIndex, isOpen } = this.state;
		const { galleryMedias } = this.props;
		return (
			<div className="galleryMediasContainer">
				{isOpen && (
					<GalleryLightbox
						type="UnisAndPrograms"
						galleryMedias={galleryMedias}
						onClose={this.onCloseRequest}
						defaultMediaIndex={mediaIndex}
					/>
				)}
				<div className="galleryMedias">
					{galleryMedias.map((media, index) => {
						if (!media.isLarge) return null;
						if (media.type === "PHOTO")
							return (
								<div
									key={media.url}
									className={largeMediaContainer}
								>
									{media.title && <span>{media.title}</span>}
									<img
										alt=""
										src={getGalleryMediaUrl(
											media.type,
											media.url
										)}
										onClick={() => this.openLightBox(index)}
									/>
								</div>
							);
						else if (media.type === "VIDEO")
							return (
								<div
									className="YoutubeVideoContainer"
									key={media.url}
								>
									{media.title && <span>{media.title}</span>}
									<YouTube
										videoId={extractVideoIdFromYoutubeLink(
											media.url
										)}
									/>
								</div>
							);
						else if (media.type === "PDF")
							return (
								<div className="pdf-container" key={media.url}>
									{media.title && <span>{media.title}</span>}
									<br />
									<OpenIcon
										className="icon"
										onClick={() =>
											this.redirectInNewTab(
												getGalleryMediaUrl(
													media.type,
													media.url
												)
											)
										}
									/>
									<PDF
										url={getGalleryMediaUrl(
											media.type,
											media.url
										)}
										pages={[1]}
										documentClassname="pdf-media-document"
										pageClassName="pdf-media-page"
									/>
								</div>
							);
					})}
					{galleryMedias.map(
						(media, index) =>
							!media.isLarge &&
							(media.type === "VIDEO" ? (
								<div
									style={{ display: "inline-block" }}
									key={media.url}
								>
									{media.title && (
										<span style={{ fontSize: 18 }}>
											{media.title}
										</span>
									)}
									<br />
									<div
										className="media"
										key={media.url}
										style={{
											backgroundImage: `url(${getGalleryMediaUrl(
												media.type,
												media.url
											)})`,
										}}
										onClick={() => this.openLightBox(index)}
									>
										<div className="videoIconContainer">
											<VideoLibraryIcon />
										</div>
									</div>
								</div>
							) : media.type === "PHOTO" ? (
								<div
									style={{ display: "inline-block" }}
									key={media.url}
								>
									{media.title && (
										<span style={{ fontSize: 18 }}>
											{media.title}
										</span>
									)}
									<br />
									<img
										key={media.url}
										src={getGalleryMediaUrl(
											media.type,
											media.url
										)}
										alt=""
										style={{
											height: 200,
											margin: 10,
											cursor: "pointer",
										}}
										onClick={() => this.openLightBox(index)}
									/>
								</div>
							) : media.type === "PDF" ? (
								<div className="pdf-container" key={media.url}>
									{media.title && (
										<span style={{ fontSize: 18 }}>
											{media.title}
										</span>
									)}
									<br />
									<OpenIcon
										className="icon"
										onClick={() =>
											this.redirectInNewTab(
												getGalleryMediaUrl(
													media.type,
													media.url
												)
											)
										}
									/>
									<PDF
										url={getGalleryMediaUrl(
											media.type,
											media.url
										)}
										pages={[1]}
										documentClassname="pdf-media-document small"
										pageClassName="pdf-media-page small"
									/>
								</div>
							) : null)
					)}
				</div>
			</div>
		);
	}
}

const largeMediaContainer = css`
	img {
		cursor: pointer;
		max-width: 100%;
		max-height: 500px;
	}
`;
