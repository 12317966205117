import api, { ICredentials, Requests } from "@app/api";
import {
	IAPOSTLogin,
	IAPOSTRegister,
	IAPUTLoginByAccessToken,
	IRPOSTLogin,
	IRPUTAccessTokens,
} from "@app/api/auth/validators";
import { IRMiniPopupRejection } from "@app/api/users/helper-schemas";
import { IRootActions, IRootState } from "@app/reducers/root";
import { IStateUser } from "@app/reducers/user";
import {
	LOAD_USER_INFO,
	LOAD_USER_REJECTIONS,
	REJECT_MINI_POPUP,
	UPDATE_USER_MOBILE,
	UPDATE_USER_PROFILE,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
} from "@app/action-types";
import { loadFavoritePrograms } from "./favorite-programs";
import { loadSubscribedUnis } from "./subscribed-unis";
import { setUserForAnalytics } from "@app/utils/events";
import { socketConnection } from "@app/utils/chat-functions";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IAPUTProfile } from "@app/api/users/validators";

interface ILoginAction {
	type: typeof USER_AUTHORIZATION_SUCCESS;
	userData: IRPOSTLogin["userData"];
}

const loginAction = (userData: IRPOSTLogin["userData"]): ILoginAction => {
	setUserForAnalytics(userData);
	return {
		type: USER_AUTHORIZATION_SUCCESS,
		userData,
	};
};

interface IUpdateProfileAction {
	type: typeof UPDATE_USER_PROFILE;
	data: IAPUTProfile;
}

const updateProfileAction = (data: IAPUTProfile): IUpdateProfileAction => {
	return {
		type: UPDATE_USER_PROFILE,
		data,
	};
};

interface ILoadUserRejectionsAction {
	type: typeof LOAD_USER_REJECTIONS;
	rejections: IRPOSTLogin["additionalInfo"]["rejections"];
}

const loadUserRejectionsAction = (
	rejections: IRPOSTLogin["additionalInfo"]["rejections"]
): ILoadUserRejectionsAction => {
	return {
		type: LOAD_USER_REJECTIONS,
		rejections,
	};
};

const dipatchLoginActions = (
	dispatch: ThunkDispatch<IRootState, null, IRootActions>,
	res: IRPOSTLogin
) => {
	console.log(res);
	renewCredentials({
		userId: res.userData.id,
		accessToken: res.accessToken,
		refreshToken: res.refreshToken,
	});
	dispatch(loginAction(res.userData));
	dispatch(loadFavoritePrograms(res.additionalInfo.favoritePrograms));
	dispatch(loadSubscribedUnis(res.additionalInfo.subscribedUnis));
	dispatch(loadUserRejectionsAction(res.additionalInfo.rejections));
};

export type ILogin = (data: IAPOSTLogin) => Promise<IRPOSTLogin>;
// tslint:disable-next-line:max-line-length
export const login = (
	data: IAPOSTLogin
): ThunkAction<ReturnType<ILogin>, IRootState, null, IRootActions> => (
	dispatch
): ReturnType<ILogin> => {
	return api.auth.login(data).then(res => {
		dipatchLoginActions(dispatch, res);
		api.auth
			.getChatAccessToken()
			.then(token => {
				socketConnection(token);
			})
			.catch(err => {
				console.log(err);
			});
		return res;
	});
};

export type IRegister = (data: IAPOSTRegister) => Promise<IRPOSTLogin>;
// tslint:disable-next-line:max-line-length
export const register = (
	data: IAPOSTRegister
): ThunkAction<ReturnType<ILogin>, IRootState, null, IRootActions> => (
	dispatch
): ReturnType<ILogin> => {
	return api.auth.register(data).then(res => {
		dipatchLoginActions(dispatch, res);
		api.auth
			.getChatAccessToken()
			.then(token => {
				socketConnection(token);
			})
			.catch(err => {
				console.log(err);
			});
		return res;
	});
};

// tslint:disable-next-line:max-line-length
export const updateProfile = (
	data: IAPUTProfile
): ThunkAction<Promise<void>, IRootState, null, IRootActions> => (
	dispatch
): Promise<void> => {
	return api.users.updateProfile(data).then(res => {
		dispatch(updateProfileAction(data));
	});
};

export type ILoginByToken = (
	data: IAPUTLoginByAccessToken
) => Promise<IRPUTAccessTokens>;
// tslint:disable-next-line:max-line-length
export const loginByToken = (
	data: IAPUTLoginByAccessToken
): ThunkAction<ReturnType<ILoginByToken>, IRootState, null, IRootActions> => (
	dispatch
): ReturnType<ILoginByToken> => {
	return api.auth.loginByToken(data).then(res => {
		dipatchLoginActions(dispatch, res);
		return res;
	});
};
export interface ILogoutAction {
	type: typeof USER_LOG_OUT;
}
const logout = (): ILogoutAction => {
	try {
		const credentials = JSON.parse(localStorage.credentials);
		api.auth
			.logout({
				userId: credentials.userId,
				refreshToken: credentials.refreshToken,
			})
			.then()
			.catch();
	} finally {
		localStorage.removeItem("credentials");
		localStorage.removeItem("user");
		localStorage.removeItem("favoritePrograms");
		localStorage.removeItem("subscribedUnis");
		localStorage.removeItem("lator");
	}
	window.scroll(0, 0);
	return {
		type: USER_LOG_OUT,
	};
};
export { logout };

interface ILoadUserAction {
	type: typeof LOAD_USER_INFO;
	user: IStateUser;
}
export const loadUser = (user: IStateUser): ILoadUserAction => {
	api.auth
		.getChatAccessToken()
		.then(token => {
			socketConnection(token);
		})
		.catch(err => {
			// console.log(err);
		});
	return {
		type: LOAD_USER_INFO,
		user,
	};
};

interface IRejectMiniPopupAction {
	type: typeof REJECT_MINI_POPUP;
	data: IRMiniPopupRejection;
}

export const rejectMiniPopup = (
	data: IRMiniPopupRejection,
	sendRequest = true
): IRejectMiniPopupAction => {
	if (sendRequest) {
		api.users.rejectMiniPopup(data).then();
	}
	return {
		type: REJECT_MINI_POPUP,
		data,
	};
};

export const renewCredentials = (credentials: ICredentials) => {
	localStorage.setItem(
		"credentials",
		JSON.stringify({
			userId: credentials.userId,
			accessToken: credentials.accessToken,
			refreshToken: credentials.refreshToken,
		})
	);
	Requests.renewConfigByCredentials(credentials);
};

///
interface IUpdateUserMobileAction {
	type: typeof UPDATE_USER_MOBILE;
	data: { mobile: string };
}

export const updateUserMobile = (data: {
	mobile: string;
}): IUpdateUserMobileAction => {
	return {
		type: UPDATE_USER_MOBILE,
		data,
	};
};

///
export type UserActions =
	| ILoginAction
	| ILoadUserAction
	| ILogoutAction
	| ILoadUserRejectionsAction
	| IRejectMiniPopupAction
	| IUpdateUserMobileAction
	| IUpdateProfileAction;
