import {
	ADD_LATORI_CONTEST_SCORE,
	ADD_LATORI_USER_SCORE,
	LATOR_ADD_FIXED_SCORE,
	LATOR_ADD_PROGRAM,
	LATOR_ADD_PROGRAMS,
	LATOR_ADD_SUBJECT,
	LATOR_ADD_SUBJECTS,
	LATOR_REMOVE_FIXED_SCORE,
	LATOR_REMOVE_PROGRAM,
	LATOR_REMOVE_SUBJECT,
	LOAD_LATOR,
	LOAD_USER_LATOR_INFO,
	USER_LOG_OUT,
} from "../action-types";
import { LatorActions } from "@app/actions/lator";

function getNewLatorState(
	state: IStateLator = {},
	action: LatorActions
): IStateLator {
	if (typeof action.type === "undefined") return state;
	switch (action.type) {
		case LOAD_USER_LATOR_INFO:
			return {
				...state,
				subjectIds: action.data.subjectIds,
				fixedScores: action.data.fixedSubjectScores,
				programIds: action.data.programIds,
				lastUpdate: new Date(),
			};
		case LOAD_LATOR:
			return {
				...action.data,
			};
		case LATOR_ADD_SUBJECT:
			return {
				...state,
				subjectIds: !state.subjectIds
					? [action.id]
					: [...state.subjectIds, action.id],
			};
		case LATOR_ADD_SUBJECTS:
			return {
				...state,
				subjectIds: action.ids,
			};
		case LATOR_REMOVE_SUBJECT: {
			if (!state.subjectIds) return state;
			return {
				...state,
				subjectIds: state.subjectIds.filter(sId => sId !== action.id),
			};
		}

		case LATOR_ADD_PROGRAM:
			return {
				...state,
				programIds: !state.programIds
					? [action.id]
					: [...state.programIds, action.id],
			};
		case LATOR_ADD_PROGRAMS:
			return {
				...state,
				programIds: [...(state.programIds || []), ...action.ids],
			};
		case LATOR_REMOVE_PROGRAM: {
			if (!state.programIds) return state;
			return {
				...state,
				programIds: state.programIds.filter(sId => sId !== action.id),
			};
		}

		case LATOR_ADD_FIXED_SCORE:
			return {
				...state,
				fixedScores: {
					...(state.fixedScores || {}),
					[action.subjectId]: action.score,
				},
			};
		case LATOR_REMOVE_FIXED_SCORE: {
			if (!state.fixedScores) return state;
			const {
				[action.subjectId]: blaa,
				...restFixedScores
			} = state.fixedScores;
			return {
				...state,
				fixedScores: restFixedScores,
			};
		}
		case ADD_LATORI_CONTEST_SCORE:
			return {
				...state,
				contestScore: action.contestScore,
			};
		case ADD_LATORI_USER_SCORE:
			return {
				...state,
				userScores: {
					...state.userScores,
					...action.userScores,
				},
			};
		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function lator(state = {}, action: LatorActions): IStateLator {
	const newState = getNewLatorState(state, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("lator", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export interface IUserScores {
	[subjectId: string]: number; // subjectId: score
}

export interface IStateLator {
	subjectIds?: number[];
	fixedScores?: IUserScores;
	programIds?: number[];
	contestScore?: number;
	userScores?: IUserScores;
	lastUpdate?: Date;
}
