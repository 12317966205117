import unis from "@app/api/unis/controller";
import { IRGETUni } from "@app/api/unis/validators";
import { useEffect, useState } from "react";

export type UniversityWithCoordinates = IRGETUni & {
	coordinate: { top: number; left: number };
};

const coordinates: { top: number; left: number }[] = [
	// first circle coordinates
	{ top: 560, left: 1170 },
	{ top: 655, left: 1335 },
	{ top: 740, left: 1175 },
	{ top: 655, left: 1005 },

	// second circle coordinates
	{ top: 420, left: 1170 },
	{ top: 500, left: 1335 },
	{ top: 550, left: 1490 },
	{ top: 660, left: 1650 },
	{ top: 750, left: 1490 },
	{ top: 830, left: 1335 },
	{ top: 920, left: 1170 },
	{ top: 830, left: 1005 },
	{ top: 740, left: 850 },
	{ top: 645, left: 700 },
	{ top: 560, left: 850 },
	{ top: 500, left: 1000 },

	// third circle coordinates
	{ top: 260, left: 1170 },
	{ top: 340, left: 1335 },
	{ top: 420, left: 1485 },
	{ top: 510, left: 1640 },
	{ top: 580, left: 1805 },
	{ top: 660, left: 1960 },
	{ top: 750, left: 1795 },
	{ top: 830, left: 1640 },
	{ top: 910, left: 1485 },
	{ top: 1000, left: 1325 },
	{ top: 1080, left: 1170 },
	{ top: 990, left: 1015 },
	{ top: 910, left: 865 },
	{ top: 835, left: 715 },
	{ top: 750, left: 545 },
	{ top: 650, left: 380 },
	{ top: 580, left: 535 },
	{ top: 510, left: 700 },
	{ top: 420, left: 850 },
	{ top: 340, left: 1005 },
];

export const useUniversitiesWithCoordinates = () => {
	const [universities, setUniversities] = useState<
		UniversityWithCoordinates[]
	>();

	useEffect(() => {
		unis.getMany({}).then(data => {
			if (data.length >= coordinates.length) {
				setUniversities(
					data.slice(0, coordinates.length).map((e, i) => ({
						...e,
						coordinate: { ...coordinates[i] },
					}))
				);
			} else {
				setUniversities(
					data.map((e, i) => ({
						...e,
						coordinate: { ...coordinates[i] },
					}))
				);
			}
		});
	}, []);

	return universities;
};
