import * as React from "react";
import AdminHeader from "@app/components/Admins/AdminHeader";
import AdminLeftNavigation from "@app/components/Admins/AdminLeftNavigation";
import LoginPopup from "@app/components/LoginPopup";
import { connect } from "react-redux";
import { History, Path } from "history";
import { IRootState } from "@app/reducers/root";
import { Redirect, Route } from "react-router-dom";
import "@app/components/styles/adminRoute.min.css";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const AdminRoute = ({
	component: Component,
	isAdmin,
	isAuthenticated,
	location,
	user,
	...rest
}: IProps) => {
	// window.scroll(0, 0);
	return (
		<Route
			location={location}
			{...rest}
			render={props =>
				user.userData && isAdmin ? (
					<div>
						<div className="adminPage">
							<AdminHeader />
							<div className="adminPageWithoutHeader">
								<AdminLeftNavigation
									history={props.history}
									match={props.match}
									userData={user.userData}
								/>
								<div className="adminRightWrapper">
									<Component
										{...{ ...rest, ...props }}
										userData={user.userData}
									/>
								</div>
							</div>
						</div>
					</div>
				) : !isAuthenticated ? (
					<LoginPopup onClose={() => window.location.reload()} />
				) : (
					<Redirect to="/programs" />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	isAdmin: !!state.user.userData && !!state.user.userData.permissions,
	user: state.user,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	AdminRoute
);
