import {
	addNewUni,
	changeUniPositionCoefficient,
	getUniById,
	getUnisEssentialInfo,
	IChangeUniPositionCoefficient,
	IGetUniById,
	IGetUnisEssentialInfo,
} from "@app/actions/unis";
import api from "@app/api";
import { IEvent } from "@app/api/events/helpher-schemas";
import { IRUniversity, IUniContent } from "@app/api/unis/helper-schemas";
import { IAPOSTCreateSchema } from "@app/api/unis/validators";
import { IRUser } from "@app/api/users/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import { getLocale } from "@app/hooks/intl";
import { getUniIdOfAdmin } from "@app/permissions";
import { IRootState } from "@app/reducers/root";
import { History } from "history";
import memoize from "memoize-one";
import * as React from "react";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import { connect } from "react-redux";
import { match } from "react-router";
import { sortUnisByCoeff } from "../MainPageComponents/search/searchAlgorithms";
import { UniCard } from "../MainPageComponents/UniList";
import "./styles/adminUniList.min.css";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	getUniById: IGetUniById;
	changeUniPositionCoefficient: IChangeUniPositionCoefficient;
	getUnisEssentialInfo: IGetUnisEssentialInfo;
	addNewUni: typeof addNewUni;
}

type IPage = "უნივერსიტეტები" | "პროფილები";

type IEventChangedInfo = Partial<IEvent>;

interface IState {
	loading: boolean;
	unis: IRUniversity[];
	contents?: IUniContent[];
	page: IPage;
	isAddingProfile: boolean;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

function reorder<T extends any[]>(
	list: T,
	startIndex: number,
	endIndex: number
): T {
	const result = [...list] as T;
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

class AdminUniList extends React.Component<IProps, IState> {
	state: IState = {
		loading: !!this.props.unis,
		unis: [],
		page: "უნივერსიტეტები",
		isAddingProfile: false,
		// contents: []
	};

	sortUnis = memoize((unis: IRUniversity[] | undefined) => {
		if (unis && this.state.loading) {
			if (this._isMounted) this.setState({ loading: false });
			else {
				setTimeout(() => {
					this.setState({ loading: false });
				}, 0);
			}
		}
		if (!Array.isArray(unis)) return unis;
		const newUnis = sortUnisByCoeff(unis);
		setTimeout(() => {
			if (this._isMounted) this.setState({ unis: newUnis });
		}, 0);
		return newUnis;
	});

	_isMounted: boolean;
	unsubscribeHistory: Function;
	listContainerRef: HTMLDivElement;

	constructor(props: IProps) {
		super(props);

		if (!props.userData.permissions) {
			this.props.history.replace("/");
			return;
		}

		const uniId = getUniIdOfAdmin(props.userData.permissions);
		if (uniId !== "allUnis") {
			if (uniId === null) this.props.history.replace("/");
			else {
				if (uniId !== 57) {
					this.props.history.replace("/admin/uni/" + uniId);
				} else {
					this.props.history.replace(
						"/admin/uni/" + uniId + "/edit/"
					);
				}
			}
			return;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.state.unis.length === 0) {
			this.props.getUnisEssentialInfo().then(unis => {
				if (this._isMounted) this.setState({ unis });
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	gotoUni = uniId => (e: React.MouseEvent<any>) => {
		this.props.history.push("/admin/uni/" + uniId);
	};

	onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const endIndex: number = result.destination.index;
		const currentIndex: number = result.source.index;
		let newCoefficient = 0;

		if (endIndex === currentIndex) return;

		if (endIndex < currentIndex) {
			if (endIndex === 0) {
				newCoefficient =
					this.state.unis[endIndex].pagePositionCoefficient + 1;
			} else {
				newCoefficient =
					(this.state.unis[endIndex].pagePositionCoefficient +
						this.state.unis[endIndex - 1].pagePositionCoefficient) /
					2;
			}
		} else if (endIndex > currentIndex) {
			if (endIndex === this.state.unis.length - 1) {
				newCoefficient =
					this.state.unis[endIndex].pagePositionCoefficient / 2;
			} else {
				newCoefficient =
					(this.state.unis[endIndex].pagePositionCoefficient +
						this.state.unis[endIndex + 1].pagePositionCoefficient) /
					2;
			}
		}

		this.props.changeUniPositionCoefficient({
			uni_id: this.state.unis[result.source.index].id,
			newCoefficient,
		});

		const unis = reorder(
			this.state.unis,
			result.source.index,
			result.destination.index
		);

		this.setState({
			unis,
		});
	};

	goToUnipage = () => {
		if (this.state.page === "უნივერსიტეტები") return null;
		this.setState({
			...this.state,
			page: "უნივერსიტეტები",
		});
	};

	goToProfilepage = () => {
		if (this.state.page === "პროფილები") return null;
		this.setState({
			page: "პროფილები",
		});
	};

	onProfilesAdd = () => {
		this.setState({
			isAddingProfile: !this.state.isAddingProfile,
		});
	};

	onAddUni = () => {
		const removeLoader = addLoader();
		const newUni: IAPOSTCreateSchema = {
			code: "000",
			name: "New University",
			officialName: "New University",
			urlName: "000_New_University",
			alias: null,
			shortDescription: null,
			facebookUrl: null,
			cities: null,
			sector: 1,
			fields: null,
			logoUrl: "logo66.png",
			expoMainBannerUrl: null,
			expoSecondaryBannerUrl: null,
			logoBGColor: null,
			expoRoomColor: null,
			coverUrl: null,
			coverBGColor: null,
			yearOfUpdatedInfo: new Date().getFullYear(),
			premium: 0,
			pagePositionCoefficient: 0,
			mainCoefficient: 0,
			locale: getLocale() as "ka" | "en",
			address: "მისამართი",
			phone: "ტელეფონი",
			email: "ელ-ფოსტა",
			web: "ვებსაიტი",
		};
		api.unis
			.create(newUni)
			.then(data => {
				this.props.addNewUni({ ...newUni, ...data });
				removeLoader();
			})
			.catch(e => {
				console.log(e);
				removeLoader();
			});
	};

	render() {
		const unis = this.sortUnis(this.props.unis);
		if (!unis) {
			return <div className="main loaderProgramWrapper">123123123</div>;
		}
		return (
			<div>
				<div className="main programWrapper">
					<div className="header" style={{ margin: "10px" }}>
						<div
							className="page"
							style={{}}
							onClick={this.goToUnipage}
						>
							უნივერსიტეტები{" "}
						</div>
					</div>
					{this.state.page === "უნივერსიტეტები" && (
						<>
							<UniversitiesPage
								onDragEnd={this.onDragEnd}
								unis={unis}
								gotoUni={this.gotoUni}
							/>
							<button
								className="adminPrimaryButton"
								onClick={this.onAddUni}
							>
								დამატება
							</button>
						</>
					)}
				</div>
			</div>
		);
	}
}

interface IUniversitiesPage {
	onDragEnd: (result: DropResult) => void;
	unis: any; //FIXME
	gotoUni: (uniId: any) => (e) => void;
}

const UniversitiesPage: React.FC<IUniversitiesPage> = props => {
	return (
		<DragDropContext onDragEnd={props.onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div ref={provided.innerRef}>
						<div className="UniListContainer">
							<div className="main main2">
								<div className="adminUniCardsGrid">
									{props.unis.map((uni, index) => (
										<Draggable
											key={uni.id}
											draggableId={uni.id.toString()}
											index={index}
										>
											{(provided2, snapshot2) => (
												<div
													ref={provided2.innerRef}
													{...provided2.draggableProps}
													{...provided2.dragHandleProps}
												>
													<UniCard
														key={uni.id}
														uni={uni}
														onClick={props.gotoUni(
															uni.id
														)}
													/>
													{(index + 1) % 12 === 0 && (
														<div className="separatorLine" />
													)}
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							</div>
						</div>
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

export default connect(mapStateToProps, ({
	getUniById,
	changeUniPositionCoefficient,
	getUnisEssentialInfo,
	addNewUni,
} as unknown) as IDispatchProps)(AdminUniList);
