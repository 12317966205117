import Joi, { SchemaToType } from "@app/utils/joi";
import { RSenderSchema } from "../helper-schemas";

/* GetSendersByUniId */
export const AGETSMSSendersByUniId = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});

export type IAGETSMSSendersByUniId = SchemaToType<typeof AGETSMSSendersByUniId>;

export const RGETSMSSendersByUniId = Joi.object().keys({
	sender: RSenderSchema.optional(),
});

export type IRGETSMSSendersByUniId = SchemaToType<typeof RGETSMSSendersByUniId>;

///

export const APOSTAddSMSSenderForUniSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	senderName: Joi.string().required(),
});
export type IAPOSTAddSMSSenderForUni = SchemaToType<
	typeof APOSTAddSMSSenderForUniSchema
>;

export const RPOSTAddSMSSenderForUniSchema = Joi.object().keys({
	success: Joi.boolean().required(),
});
export type IRPOSTAddSMSSenderForUni = SchemaToType<
	typeof RPOSTAddSMSSenderForUniSchema
>;
