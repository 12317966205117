import * as origianljoi from "typesafe-joi";
import auth from "./auth/controller";
import axios from "axios";
import events from "./events/controller";
import Joi from "../utils/joi";
import lator from "./lator/controller";
import news from "./news/controller";
import programs from "./programs/controller";
import toulator from "./toulator/controller";
import sms from "./sms/controller";
import unis from "./unis/controller";
import users from "./users/controller";
import uniExpo from "./uni-expo/controller";
import { logout, renewCredentials } from "../actions/user";
import { readFile } from "../utils/file-reader";
import { requireLoginForActionPromise } from "../components/requireLoginForAction";
import { store } from "index";

const api = {
	programs,
	unis,
	lator,
	users,
	news,
	events,
	sms,
	auth,
	toulator,
	uniExpo,
};

export default api;

const getAccessToken = (): string | undefined => {
	try {
		const credentials = JSON.parse(localStorage.credentials);
		return "" + credentials.accessToken;
	} catch (e) {
		return undefined;
	}
};

const logoutUser = () => {
	store.dispatch(logout());
};

export interface ICredentials {
	userId: number;
	accessToken: string;
	refreshToken: string;
}

// tslint:disable-next-line:cognitive-complexity
function mergeRecursive(object1: {}, object2: {}): {} {
	const obj1 = { ...object1 };
	const obj2 = { ...object2 };
	for (const p in obj2) {
		if (obj2.hasOwnProperty(p)) {
			try {
				// Property in destination object set; update its value.
				if (obj2[p].constructor === Object) {
					obj1[p] = mergeRecursive(obj1[p], obj2[p]);
				} else {
					obj1[p] = obj2[p];
					if (obj1[p] === undefined) delete obj1[p];
				}
			} catch (e) {
				// Property in destination object not set; create it and set its value.
				obj1[p] = obj2[p];
				if (obj1[p] === undefined) delete obj1[p];
			}
		}
	}

	return obj1;
}

interface IValidators {
	requestSchema?: origianljoi.Schema;
	responseSchema?: origianljoi.Schema;
}

function validate<T>(data: T, schema?: origianljoi.Schema) {
	if (!schema) return data;
	const validatorResult = Joi.validate(data, schema, {
		// stripUnknown: true,
	});
	if (validatorResult.error !== null || validatorResult.value === undefined) {
		console.log(data);
		console.error(validatorResult.error);
		throw validatorResult.error;
	}
	return validatorResult.value;
}

type MethodType = "GET" | "POST" | "PUT" | "DELETE";

function getPromise(method: MethodType, url: string, data?: {}, config?: {}) {
	if (method === "GET") {
		return axios.get(url, config);
	}
	if (method === "POST") {
		return axios.post(url, data, config);
	}
	if (method === "DELETE") {
		return axios.delete(url, config);
	}
	return axios.put(url, data, config);
}

class Requests {
	public static defaultConfig = {
		headers: {
			access_token: getAccessToken(),
		},
	};

	// tslint:disable-next-line: cognitive-complexity
	public static send<Obj extends {}, Obj2 extends {}>(
		method: MethodType,
		baseUrl: string,
		data?: FormData | Obj | undefined,
		customConfig?: null | Obj2,
		validators?: IValidators
	) {
		let bodyOrQuery = { ...(data || {}) };
		if (data instanceof FormData) {
			data.forEach((val, key) => {
				bodyOrQuery[key] = val;
			});
		}
		// example: api/unis/:uni_id/ => api/unis/7/
		baseUrl = baseUrl.replace(/:([^\/\s]+)/g, (str, match) => {
			if (bodyOrQuery[match] !== undefined) {
				const val = bodyOrQuery[match];
				delete bodyOrQuery[match];
				return val;
			}
			return str;
		});
		let url = baseUrl;
		if (method === "GET" || method === "DELETE") {
			let queryString = "";
			if (typeof bodyOrQuery === "object" && bodyOrQuery !== null) {
				queryString =
					"?" +
					Object.keys(bodyOrQuery)
						.map(
							key =>
								key + "=" + encodeURIComponent(bodyOrQuery[key])
						)
						.join("&");
				if (queryString.length === 1) queryString = "";
			}
			url = baseUrl + queryString;
		}
		if (data instanceof FormData) bodyOrQuery = data;

		const { defaultConfig } = Requests;
		const config = mergeRecursive(defaultConfig, customConfig || {});

		const mainPromise = getPromise(method, url, bodyOrQuery, config)
			.then(res => res.data)
			.then(d =>
				!validators ? d : validate(d, validators.responseSchema)
			);
		const promise = mainPromise.catch(err =>
			Requests.error(err, () =>
				getPromise(method, url, bodyOrQuery, config)
					.then(res => res.data)
					.then(d =>
						!validators ? d : validate(d, validators.responseSchema)
					)
			)
		);
		if (!Requests.sendingResponse) {
			return promise;
		}
		return Requests.sendingResponse.then(() => promise);
	}

	public static sendNewAccessTokenRequest(callback: () => Promise<any>) {
		try {
			if (!localStorage.credentials) throw new Error("no credentials");
			const credentials = JSON.parse(localStorage.credentials);
			if (Requests.sendingResponse) {
				return Requests.sendingResponse
					.then(data => {
						delete Requests.sendingResponse;
						return callback();
					})
					.catch(e => {
						delete Requests.sendingResponse;
						throw e;
					});
			}
			Requests.sendingResponse = api.auth
				.updateAccessToken({
					userId: credentials.userId,
					refreshToken: credentials.refreshToken,
				})
				.then(data => {
					delete Requests.sendingResponse;
					renewCredentials({
						userId: credentials.userId,
						accessToken: data.accessToken,
						refreshToken: data.refreshToken,
					});
					return callback();
				})
				.catch(e => {
					delete Requests.sendingResponse;
					throw e;
				});
			return Requests.sendingResponse;
		} catch (e) {
			throw e;
		}
	}
	public static renewConfigByCredentials(credentials: ICredentials) {
		if (typeof credentials.accessToken !== "undefined") {
			Requests.defaultConfig.headers.access_token =
				credentials.accessToken;
		}
	}
	public static async error(
		err: any,
		callback: () => Promise<any>
	): Promise<any> {
		let data = err.response ? err.response.data : undefined;
		if (data instanceof Blob) {
			data = await readFile(data);
			// console.log((file));
		}
		if (err.response && err.response.status === 401 && data) {
			if (data === "access token expired") {
				return Requests.sendNewAccessTokenRequest(callback);
			}
			if (
				data === "invalid refresh token" ||
				data === "authentication failed"
			) {
				logoutUser();
				Requests.sendingResponse = requireLoginForActionPromise()
					.then(() => {
						delete Requests.sendingResponse;
						return callback();
					})
					.catch(e => {
						delete Requests.sendingResponse;
						throw e;
					});
				return Requests.sendingResponse;
			}
		}
		throw err;
	}
	private static sendingResponse?: Promise<any>;
}

if (typeof Requests.defaultConfig.headers.access_token !== "string") {
	delete Requests.defaultConfig.headers.access_token;
}

window.addEventListener("storage", (e: StorageEvent) => {
	if (e.key !== "credentials") return;
	if (!Requests || !Requests.defaultConfig || !Requests.defaultConfig.headers)
		return;
	const accessToken = getAccessToken();
	if (typeof accessToken === "string") {
		Requests.defaultConfig.headers.access_token = accessToken;
	} else {
		delete Requests.defaultConfig.headers.access_token;
	}
});

export { Requests };
export type RequestsType = typeof Requests;
