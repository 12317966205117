import * as React from "react";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import GlowingBar from "./glowing-bar";
import Slider from "rc-slider";
import triggerEvent from "@app/utils/events";

interface IPlaceSliderProps {
	places: number;
	defaultPlace: number;
	onChange: (place?: number) => void;
	isDisabled: boolean;
}
interface IPlaceSliderState {
	currentPlace: number | null;
}

let hasMoved = false;

export default class PlaceSlider extends React.PureComponent<
	IPlaceSliderProps,
	IPlaceSliderState
> {
	state: IPlaceSliderState = {
		currentPlace: this.props.defaultPlace,
	};

	onQuickChange = (place: number) => {
		if (this.state.currentPlace === null) return;
		this.setState({
			currentPlace: place,
		});
		hasMoved = true;
	};
	onFinalChange = (place: number) => {
		if (this.state.currentPlace === null) return;
		this.setState({
			currentPlace: place,
		});
		hasMoved = true;
		this.props.onChange(place);

		triggerEvent(
			{
				category: "Slider",
				action: "Lator: place change",
				label: "",
			},
			{ place }
		);
	};
	onChangeFromParent = (place: number | null) => {
		this.setState({
			currentPlace: place,
		});
	};
	render() {
		const numOfLines = Math.min(this.props.places, 30);
		const isDisabled =
			this.state.currentPlace === null || this.props.isDisabled;

		return (
			<div className="placeSliderContainer">
				<div
					className={`slider ${
						!this.state.currentPlace ? "cannotBeAdmitted" : ""
					}`}
				>
					<Slider
						min={1}
						max={this.props.places}
						value={this.state.currentPlace || this.props.places + 1}
						onChange={this.onQuickChange}
						onAfterChange={this.onFinalChange}
						handle={PlacesHanlder}
						disabled={isDisabled}
					/>
				</div>
				<GlowingBar numOfLines={numOfLines} />
				{this.props.places > 1 && (
					<div className="places">
						<span>1</span>
						<span>{isDisabled ? "ადგილი" : "← ადგილი →"}</span>
						<span>{this.props.places}</span>
					</div>
				)}
			</div>
		);
	}
}

interface IPlacesHanlderProps {
	min: number;
	max: number;
	value: number;
	[key: string]: any;
}

export const PlacesHanlder = ({
	min,
	max,
	value,
	...restProps
}: IPlacesHanlderProps) => {
	return (
		<div
			style={{
				left: `${restProps.offset}%`,
				cursor: restProps.disabled ? "default" : "pointer",
			}}
			className="placeSliderHandle"
		>
			<span>{value}</span>
			<PointSVG />
			{!restProps.disabled && (
				<div className="arrows">
					<ArrowLeftIcon />
					<ArrowRightIcon />
				</div>
			)}
		</div>
	);
};

export const PointSVG = ({ backgroundColor = "#fe2f73" }) => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 40 48"
	>
		{/*tslint:disable-next-line:max-line-length*/}
		<path
			d="M34.1,34L20,48L5.9,34l0,0C-1.9,26.3-2,13.7,5.7,6c0,0,0.1-0.1,0.1-0.1c7.8-7.8,20.5-7.8,28.3,0c7.8,7.7,7.8,20.2,0.1,28C34.2,33.9,34.2,33.9,34.1,34z"
			style={{ fill: backgroundColor }}
		/>
	</svg>
);
