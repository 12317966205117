import { Dispatch } from "redux";
import { IRootActions } from "./reducers/root";

const handleActionOfOtherTab = (
	e: StorageEvent,
	dispatch: Dispatch<IRootActions>
) => {
	if (e.key !== "lastAction") return;
	const lastAction = JSON.parse(localStorage.lastAction);
	dispatch(lastAction);
	// console.log(lastAction);
};
export default handleActionOfOtherTab;
