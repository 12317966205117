import AdminStatsPage from "@app/components/Admins/admin-stats";
import AdminEventEditPage from "@app/components/Admins/AdminEventEditPage";
import AdminProgramEditPage from "@app/components/Admins/AdminProgramEditPage";
import AdminPrograms from "@app/components/Admins/AdminPrograms";
import AdminUniEditPage from "@app/components/Admins/AdminUniEditPage";
import MainPage from "@app/components/MainPage";
import ProgramPage from "@app/components/Programs/ProgramPage";
import UniPage from "@app/components/uni-page";
import AdminRoute from "@app/routes/AdminRoute";
import UserRoute from "@app/routes/UserRoute";
import { History } from "history";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import momentUtils from "material-ui-pickers/utils/moment-utils";
import "moment/locale/ka";
import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import addAdminPage from "./components/Admins/addAdminPage";
import { AdminExpoStatsPage } from "./components/Admins/admin-expo-stats";
import { AdminLatorPage } from "./components/Admins/admin-lator-page";
import AdminChat from "./components/Admins/AdminChat";
import AdminEvents from "./components/Admins/AdminEvents";
import AdminLiveVideosPage from "./components/Admins/AdminLiveVideosPage";
import AdminNewsEditPage from "./components/Admins/AdminNewsEditPage";
import AdminNewsPage from "./components/Admins/AdminNewsPage";
import AdminProgramContentEditPage from "./components/Admins/AdminProgramContentEditPage";
import AdminUniContentEditPage from "./components/Admins/AdminUniContentEditPage";
import AdminUniExpoVideosPage from "./components/Admins/AdminUniExpoVideosPage";
import AdminUniList from "./components/Admins/AdminUniList";
import AllAdminsList from "./components/Admins/AllAdminsPage";
import { AdminConsultationRequests } from "./components/Admins/consultation-requests";
import SendSMSPage from "./components/Admins/sms/send-sms-page";
import SMSGroupPage from "./components/Admins/sms/sms-group-page";
import SMSGroupsPage from "./components/Admins/sms/sms-groups-page";
import { AdminToulatorResults } from "./components/Admins/TOUlator";
import { DoAdminStuff } from "./components/do-admin-stuff";
import HistoryPopup from "./components/HistoryPopup";
import LoginByTokenPage from "./components/LoginByTokenPage";
import OriginalLatoriPage from "./components/murtskulatori";
import ChooseSubjects from "./components/murtskulatori/choose-subjects";
import LatoriUniPage from "./components/murtskulatori/uni-page";
import { SpecialProgramsList } from "./components/Programs/special-list";
import SMSPage from "./components/sms-page";
import Terms from "./components/Terms";
import { TouLatorPageLanding } from "./components/TOU";
import TouLatorResultFail from "./components/TOU/lator-result-fail";
import ExpoLive from "./components/uni-expo/expo-live";
import ExpoMainPage from "./components/uni-expo/expo-main-page";
import ExpoOffers from "./components/uni-expo/expo-offers";
import ExpoTimetable from "./components/uni-expo/expo-timetable";
import ExpoUniPage from "./components/uni-expo/expo-uni-page";
import FavoritePrograms from "./components/users/favorite-programs";
import { IRootState } from "./reducers/root";

const App = ({
	location,
	isAuthenticated,
	isAdmin,
}: {
	location: History["location"];
	isAuthenticated: boolean;
	isAdmin: boolean;
}) => (
	<React.Fragment>
		<MuiPickersUtilsProvider utils={momentUtils} locale="ka">
			<Route
				location={location}
				path="/"
				exact={true}
				component={MainPage}
			/>
			<Route
				location={location}
				path="/unis"
				exact={true}
				component={MainPage}
			/>
			<Route
				location={location}
				path="/terms-of-use"
				exact={true}
				component={Terms}
			/>
			<Route
				location={location}
				path="/sms/:smsType"
				exact={true}
				component={SMSPage}
			/>
			<Route
				location={location}
				path="/historyPopup"
				exact={true}
				component={HistoryPopup}
			/>
			<UserRoute
				location={location}
				path="/latori/choose-subjects"
				exact={true}
				component={ChooseSubjects}
			/>
			<UserRoute
				location={location}
				path="/latori"
				exact={true}
				component={OriginalLatoriPage}
			/>
			<Route
				location={location}
				path="/latori/unis/:urlName"
				exact={true}
				component={LatoriUniPage}
			/>
			<Route
				location={location}
				path="/unis/:urlName"
				exact={true}
				component={UniPage}
			/>
			<Route
				location={location}
				path="/programs/:urlName"
				exact={true}
				component={ProgramPage}
			/>
			<UserRoute
				location={location}
				path="/user/favorites"
				exact={true}
				component={FavoritePrograms}
			/>
			<AdminRoute
				location={location}
				path="/admin/"
				exact={true}
				component={AdminUniList}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/programs"
				exact={true}
				component={AdminPrograms}
			/>
			<AdminRoute
				location={location}
				path="/admin/all-admins"
				exact={true}
				component={AllAdminsList}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/events"
				exact={true}
				component={AdminEvents}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id"
				exact={true}
				component={AdminStatsPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/edit"
				exact={true}
				component={AdminUniEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/event/:event_id/edit"
				exact={true}
				component={AdminEventEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/event/add/"
				exact={true}
				component={AdminEventEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/program/:program_id/edit"
				exact={true}
				component={AdminProgramEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/programs/add"
				exact={true}
				component={AdminProgramEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/stuff"
				exact={true}
				component={DoAdminStuff}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/program/:program_id/edit_content"
				exact={true}
				component={AdminProgramContentEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/chat"
				exact={true}
				component={AdminChat}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/edit_content"
				exact={true}
				component={AdminUniContentEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/add_admin"
				exact={true}
				component={addAdminPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/send_sms"
				exact={true}
				component={SendSMSPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/sms_group/:sms_group_id"
				exact={true}
				component={SMSGroupPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/sms_groups"
				exact={true}
				component={SMSGroupsPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/news"
				exact={true}
				component={AdminNewsPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/live-videos"
				exact={true}
				component={AdminLiveVideosPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/expo-video"
				exact={true}
				component={AdminUniExpoVideosPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/news/add"
				exact={true}
				component={AdminNewsEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/uni/:uni_id/news/edit/:news_id"
				exact={true}
				component={AdminNewsEditPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/toulator"
				exact={true}
				component={AdminToulatorResults}
			/>
			<AdminRoute
				location={location}
				path="/admin/lator"
				exact={true}
				component={AdminLatorPage}
			/>
			<AdminRoute
				location={location}
				path="/admin/consultation-requests"
				exact={true}
				component={AdminConsultationRequests}
			/>
			<AdminRoute
				location={location}
				path="/admin/expo"
				exact={true}
				component={AdminExpoStatsPage}
			/>
			<Route
				location={location}
				path="/loginByToken/:murtskuUserId/:token"
				exact={true}
				component={LoginByTokenPage}
			/>
			<Route
				location={location}
				path="/sprograms/"
				exact={true}
				component={SpecialProgramsList}
			/>
			<Route
				location={location}
				path="/toulator/:user_id?"
				exact={true}
				component={TouLatorPageLanding}
			/>
			<Route
				location={location}
				path="/toulator-res"
				exact={true}
				component={TouLatorResultFail}
			/>
			<Route
				location={location}
				path="/uni-expo"
				exact={true}
				component={ExpoMainPage}
			/>
			<Route
				location={location}
				path="/uni-expo/offers/:uniId?"
				exact={true}
				component={ExpoOffers}
			/>
			<Route
				location={location}
				path="/uni-expo/:uni_id/room"
				exact={true}
				component={ExpoUniPage}
			/>
			<Route
				location={location}
				path="/uni-expo/timetable"
				exact={true}
				component={ExpoTimetable}
			/>
			<Route
				location={location}
				path="/uni-expo/live"
				exact={true}
				component={ExpoLive}
			/>
		</MuiPickersUtilsProvider>
	</React.Fragment>
);

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	isAdmin: !!state.user.userData && !!state.user.userData.permissions,
});

export default connect(mapStateToProps)(App);
