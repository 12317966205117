import * as React from "react";
import FaceIcon from "@material-ui/icons/Face";
import LogoutIcon from "../styles/img/Logout";
import { connect } from "react-redux";
import { IRootState } from "../../reducers/root";
import { logout } from "@app/actions/user";
import "./styles/adminHeader.min.css";
import { LocaleSwitcher } from "../locale-switcher";

interface IOwrnProps {}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	logout: typeof logout;
}

type IProps = IOwrnProps & IStateProps & IDispatchProps;

class AdminHeader extends React.Component<IProps> {
	render() {
		return (
			<div className="adminHeader">
				<AdminName userName={this.props.userName} />
				<div>
					<Logout onLogout={this.props.logout} />
					<div style={{ width: 20 }} />
					<LocaleSwitcher />
				</div>
			</div>
		);
	}
}

const AdminName: React.SFC<{
	userName?: string;
	fullName?: string;
}> = props => (
	<div className="adminNameAndIcon">
		<span>
			<FaceIcon style={{ width: "32px", height: "32px" }} />
		</span>
		<span>{props.userName ? props.userName : props.fullName}</span>
	</div>
);

const Logout: React.SFC<{ onLogout: () => void }> = props => (
	<div>
		<div className="logout" onClick={props.onLogout}>
			<span className="header_buttons header_logout">
				<span>გამოსვლა</span>
				<LogoutIcon width={27} />
			</span>
		</div>
	</div>
);
const mapStateToProps = (state: IRootState) => ({
	userName: state.user.userData!.username,
	fullName:
		state.user.userData!.firstname ||
		" " + state.user.userData!.lastname ||
		"",
});

export default connect<IStateProps, IDispatchProps, IOwrnProps>(
	mapStateToProps,
	{
		logout,
	}
)(AdminHeader);
