import * as openSocket from "socket.io-client";
import IAdminAuth from "./interfaces/admin-auth";
import INewChat from "./interfaces/new-chat";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import rootReducer, { IChatRootActions, IChatRootState } from "./rootReducer";
import uuid from "uuid";
import {
	addNewChatroomInStorage,
	addReceivedMessageInStorage,
	addTempChatroomInStorage,
	replaceTempChatroomInStorage,
	saveAdminMessagesInStorage,
	saveMessagesInStorage,
} from "./actions/chatrooms";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export const chatStore = createStore(
	rootReducer,
	composeWithDevTools(
		applyMiddleware(reduxThunk as ThunkMiddleware<
			IChatRootState,
			IChatRootActions
		>)
	)
);
let socket;

interface ISocketOptions {
	onTokenExpired?: () => void;
	onInvalidToken?: () => void;
	path?: string;
}

interface ISocketInfo {
	socketServer: string;
	userToken: string;
	options?: ISocketOptions;
}

export const initializeConnection = (socketInfo: ISocketInfo) => {
	socket = (openSocket as any)(socketInfo.socketServer, {
		transports: ["websocket"],
		query: "token=" + socketInfo.userToken,
		path: socketInfo.options ? socketInfo.options.path : undefined,
	});

	socket.on("connect", () => {
		socket.on("initialize", data => {
			try {
				const userChatroomsInfo = {};

				for (let i = 0; i < data.userChatRooms.length; i++) {
					userChatroomsInfo[data.userChatRooms[i]._id] = {
						_id: data.userChatRooms[i]._id,
						name: data.userChatRooms[i].name,
						participantIds: data.userChatRooms[i].participantIds,
						participantNames:
							data.userChatRooms[i].participantNames,
						messages: data.chatroomMessages.filter(
							message =>
								message.chatRoomId === data.userChatRooms[i]._id
						),
					};
				}

				chatStore.dispatch(saveMessagesInStorage(userChatroomsInfo));
			} catch (e) {
				console.error(e);
			}
		});

		socket.on("new chat", data => {
			if (data.tempId) {
				chatStore.dispatch(
					replaceTempChatroomInStorage(data.tempId, data.chatroom._id)
				);
				return;
			}
			const chatroomObject = {
				...data.chatroom,
				messages: [],
			};
			chatStore.dispatch(addNewChatroomInStorage(chatroomObject));
		});

		socket.on("new message", data => {
			chatStore.dispatch(
				addReceivedMessageInStorage(data.chatroom, data.msg)
			);
		});

		socket.on("admin initialize", data => {
			try {
				const userChatroomsInfo = {};

				for (let i = 0; i < data.userChatRooms.length; i++) {
					userChatroomsInfo[data.userChatRooms[i]._id] = {
						_id: data.userChatRooms[i]._id,
						name: data.userChatRooms[i].name,
						participantIds: data.userChatRooms[i].participantIds,
						participantNames:
							data.userChatRooms[i].participantNames,
						messages: data.chatroomMessages.filter(
							message =>
								message.chatRoomId === data.userChatRooms[i]._id
						),
					};
				}

				chatStore.dispatch(
					saveAdminMessagesInStorage(userChatroomsInfo)
				);
			} catch (e) {
				console.error(e);
			}
		});
	});

	socket.on("error", error => {
		if (
			error.type === "UnauthorizedError" &&
			error.message === "jwt expired"
		) {
			if (
				socketInfo.options !== undefined &&
				socketInfo.options.onTokenExpired !== undefined
			) {
				socketInfo.options.onTokenExpired();
			} else {
				console.log("Token has expired");
			}
		} else if (
			error.type === "UnauthorizedError" &&
			error.message === "invalid token"
		) {
			if (
				socketInfo.options !== undefined &&
				socketInfo.options.onInvalidToken !== undefined
			) {
				socketInfo.options.onInvalidToken();
			} else {
				console.log("Invalid token");
			}
		}
	});

	socket.on("auth error", error => {
		if (
			error.type === "UnauthorizedError" &&
			error.message === "jwt expired"
		) {
			if (
				socketInfo.options !== undefined &&
				socketInfo.options.onTokenExpired !== undefined
			) {
				socketInfo.options.onTokenExpired();
			} else {
				console.log("Token has expired");
			}
		} else if (
			error.type === "UnauthorizedError" &&
			error.message === "invalid token"
		) {
			if (
				socketInfo.options !== undefined &&
				socketInfo.options.onInvalidToken !== undefined
			) {
				socketInfo.options.onInvalidToken();
			} else {
				console.log("Invalid token");
			}
		}
	});
};

export const sendMessage = data => {
	if (!socket) return;
	socket.emit("new message", data);
};

export const startDialogue = (
	userId: string,
	userName: string,
	data: INewChat
) => {
	if (!socket) return;
	socket.emit("new chat", data);
	if (!data.tempId || !data.firstMessage) return;

	const tempChatroom = {
		_id: data.tempId,
		participantIds: [userId, (data.destinationId as unknown) as string],
		participantNames: [userName, data.destinationName],
		messages: [
			{
				_id: uuid(),
				chatRoomId: data.tempId,
				senderId: userId,
				message: data.firstMessage,
				seenBy: [userId],
				createdAt: new Date(),
			},
		],
	};
	chatStore.dispatch(addTempChatroomInStorage(data.tempId, tempChatroom));
};

export const markSeen = (userId, messageIds) => {
	if (!socket) return;
	socket.emit("mark seen", { userId, messageIds });
};

export const loadAdminChatrooms = (data: IAdminAuth) => {
	if (!socket) return;

	socket.emit("admin auth", data);
};
