import { IEvent } from "@app/api/events/helpher-schemas";
import {
	extractVideoIdFromYoutubeLink,
	getNewsOrEventMediaUrl,
} from "@app/commonJavascript";
import AddCircle from "@material-ui/icons/AddCircleRounded";
import Cancel from "@material-ui/icons/CancelOutlined";
import CheckIcon from "@material-ui/icons/CheckRounded";
import * as React from "react";
import YouTube from "react-youtube";
import DateCalendar from "../Widgets/date_calendar";
import Popup from "../Widgets/Popup";
import "./styles/popup.min.css";

interface IProps {
	event: IEvent;
	is_reserved: boolean;
	onClose: () => void;
	onReserve: (id: number, is_reserved: boolean, label: string) => void;
}

class EventPopup extends React.Component<IProps> {
	render() {
		return (
			<Popup onClose={this.props.onClose}>
				<div className="popup_child">
					<div className="popup_header">
						<h2>ღონისძიება</h2>
						<Cancel
							className="cancelIcon"
							onClick={this.props.onClose}
						/>
					</div>
					<div className="event_info">
						<p className="event_title">{this.props.event.title}</p>
						<div className="event_date_and_data_container">
							<DateCalendar date={this.props.event.start_time} />
							<EventWrittenData
								date={this.props.event.start_time}
								location={this.props.event.location || null}
							/>
						</div>
					</div>
					{this.props.event.start_time > new Date() && (
						<EventReservation
							event_id={this.props.event.id}
							is_reserved={this.props.is_reserved}
							onEventButtonClick={this.props.onReserve}
							start_time={this.props.event.start_time}
						/>
					)}
					{!!this.props.event.text && (
						<EventPopupTextArea text={this.props.event.text} />
					)}
					{!!this.props.event.galleryMedias &&
						this.props.event.galleryMedias.map(
							media =>
								(media.type === "VIDEO" ||
									media.type === "PHOTO") && (
									<PopupImageOrVideo
										key={media.url}
										url={media.url}
										type={media.type}
									/>
								)
						)}
				</div>
			</Popup>
		);
	}
}

interface IEventWrittenDataProps {
	date: Date;
	location: string | null;
}
export class EventWrittenData extends React.PureComponent<
	IEventWrittenDataProps
> {
	oClock = (date: Date) =>
		this.prependZero(date.getHours()) +
		":" +
		this.prependZero(date.getMinutes()) +
		":" +
		this.prependZero(date.getSeconds());
	fullDate = (date: Date) =>
		this.prependZero(date.getDate()) +
		"." +
		this.prependZero(date.getMonth() + 1) +
		"." +
		date.getFullYear();
	prependZero = (num: number) => (num >= 0 && num < 10 ? 0 + "" + num : num);
	render() {
		return (
			<div className="event_written_data">
				<div className="event_info_field">
					თარიღი: {this.fullDate(this.props.date)}
				</div>
				{this.oClock(this.props.date) !== "00:00:00" && (
					<div className="event_info_field">
						დრო: {this.oClock(this.props.date)}
					</div>
				)}
				{this.props.location && (
					<div className="event_info_field">
						ადგილი: {this.props.location}
					</div>
				)}
			</div>
		);
	}
}

interface IEventReservationProps {
	event_id: number;
	is_reserved: boolean;
	onEventButtonClick: (
		id: number,
		is_reserved: boolean,
		label: string
	) => void;
	start_time: Date;
}
const EventReservation: React.FC<IEventReservationProps> = props => (
	<div className="reservation_field">
		<button
			className={
				"reservation_button" + (props.is_reserved ? " reserved" : "")
			}
			onClick={() =>
				props.onEventButtonClick(
					props.event_id,
					props.is_reserved,
					"event-popup"
				)
			}
		>
			<span>{props.is_reserved ? "დაჯავშნილია" : "დაჯავშნა"}</span>
			{props.is_reserved ? (
				<CheckIcon className="reserve_icon" />
			) : (
				<AddCircle className="reserve_icon" />
			)}
		</button>
	</div>
);

interface IEventPopupTextAreaProps {
	text: string;
}
const EventPopupTextArea: React.FC<IEventPopupTextAreaProps> = props => (
	<div
		className="popup_text_area"
		dangerouslySetInnerHTML={{ __html: props.text }}
	/>
);

interface IPopupImageOrVideoProps {
	url: string;
	type: "PHOTO" | "VIDEO";
}

export const PopupImageOrVideo: React.FC<IPopupImageOrVideoProps> = props => (
	<div className="image_or_video_container">
		{props.type === "PHOTO" ? (
			<img
				src={getNewsOrEventMediaUrl(props.type, props.url)}
				style={{ borderRadius: 22 }}
			/>
		) : (
			<YouTube videoId={extractVideoIdFromYoutubeLink(props.url)} />
		)}
	</div>
);

export default EventPopup;
