import { getUniById, IGetUniById } from "@app/actions/unis";
import api from "@app/api";
import { IRSMSGroup } from "@app/api/sms/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import FancyInput from "@app/components/Widgets/FancyInput";
import { canSendUniSMS } from "@app/permissions";
import { IRootState } from "@app/reducers/root";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { History } from "history";
import * as React from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { match } from "react-router";
import "../styles/adminPrograms.min.css";
import { isMainAdmin } from "@app/commonJavascript";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	getUniById: IGetUniById;
}

const textNameMaxLength = 60;
const shortText = (name: string): string => {
	return name.length <= textNameMaxLength
		? name
		: name.substr(0, textNameMaxLength - 2).trim() + "..";
};

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	smsGroups: null | IRSMSGroup[];
	sentSMSnumber: number;
	smsSender: string;
}

class SMSGroupsPage extends React.Component<IProps, IState> {
	uni_id = +this.props.match.params.uni_id;
	_isMounted: boolean;

	state: IState = {
		smsGroups: null,
		sentSMSnumber: 0,
		smsSender: "",
	};

	constructor(props: IProps) {
		super(props);
		if (!canSendUniSMS(this.uni_id, props.userData.permissions)) {
			this.props.history.replace("/admin/uni/" + this.uni_id);
			return;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		Promise.all([
			api.sms.getGroups({ uni_id: this.uni_id }),
			api.sms.getSentSMSNumberForUni({ uni_id: this.uni_id }),
			api.sms.getSender({ uni_id: this.uni_id }),
		]).then(([groupData, smsData, senderData]) => {
			this.setState({
				smsGroups: groupData.smsGroups,
				sentSMSnumber: smsData.count,
				smsSender: senderData.sender ? senderData.sender.title : "",
			});
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	redirectToAddPage = () => {
		if (!this.state.smsSender) alert("გამგზავნის სახელი არაა მითითებული");
		else this.props.history.push("send_sms");
	};

	onSMSSenderNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			smsSender: e.target.value,
		});
	};

	onSMSSenderAdd = () => {
		api.sms
			.setSMSSender({
				uni_id: this.uni_id,
				senderName: this.state.smsSender,
			})
			.then(data => {
				if (data.success) alert("ოპერაცია წარმატებით განხორციელდა");
			});
	};

	render() {
		if (!this.state.smsGroups) {
			return (
				<div className="main">
					<ContentLoader width={400} height={50}>
						<rect
							x="135"
							y="28"
							rx="4"
							ry="4"
							width="100"
							height="12"
						/>
					</ContentLoader>
					<SingleSMSGroupLoader />
					<SingleSMSGroupLoader />
					<SingleSMSGroupLoader />
					<SingleSMSGroupLoader />
				</div>
			);
		}
		return (
			<div>
				<div className="main programWrapper">
					<div className="header">
						გაგზავნილი SMS-ების რაოდენობა:{" "}
						{this.state.sentSMSnumber}
						<br />
					</div>
					{this.state.smsGroups.map((smsGroup, index) => (
						<div className="program" key={smsGroup.id}>
							<div className="programNumber">{index + 1}</div>
							<div className="adminSingleItemContainer">
								<div
									style={{ width: "100%", textAlign: "left" }}
								>
									<Tooltip
										title={smsGroup.text}
										placement="bottom"
									>
										<div>
											<span>
												{shortText(smsGroup.text)}
											</span>
											<span style={{ float: "right" }}>
												სულ: {smsGroup.total_numbers}.
												წარმატებით: {smsGroup.success}
											</span>
										</div>
									</Tooltip>
								</div>
							</div>
							<div
								className="penIcon"
								onClick={() =>
									this.props.history.push(
										`sms_group/${smsGroup.id}`
									)
								}
							>
								<VisibilityIcon />
							</div>
						</div>
					))}
					<button
						className="adminPrimaryButton"
						onClick={this.redirectToAddPage}
					>
						ახლის გაგზავნა
					</button>
					<br />
					{!!isMainAdmin(this.props.userData.permissions) && (
						<React.Fragment>
							<FancyInput
								defaultValue={this.state.smsSender}
								placeholder={"გამგზავნის სახელი"}
								onChange={this.onSMSSenderNameChange}
							/>
							<button
								className="adminPrimaryButton"
								onClick={this.onSMSSenderAdd}
							>
								<span>დასტური</span>
							</button>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

const SingleSMSGroupLoader: React.SFC<{}> = props => (
	<ContentLoader width={400} height={35}>
		<rect x="0" y="5" rx="5" ry="5" width="350" height="15" />
		<circle cx="365" cy="12" r="10" />
	</ContentLoader>
);

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

export default connect(mapStateToProps, ({
	getUniById,
} as unknown) as IDispatchProps)(SMSGroupsPage);
