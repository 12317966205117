import Joi, { SchemaToType } from "@app/utils/joi";
import { GalleryMediaSchema } from "../helpher-schemas";
import { Cast } from "typesafe-joi";

export const RProgramSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	code: Joi.string().required(),
	name: Joi.string().required(),
	urlName: Joi.string().required(),
	officialName: Joi.string().required(),
	alias: Joi.array()
		.items(Joi.string().required())
		.allow(null)
		.required(),
	accreditation: Joi.boolean().required(),
	financed: Joi.boolean().required(),
	fullyFinancedPlaces: Joi.number()
		.integer()
		.required(),
	price: Joi.number()
		.integer()
		.required(),
	studyDuration: Joi.number()
		.integer()
		.required(),
	foreignLangs: Joi.array()
		.items(Joi.string().valid("ENG", "RUS", "DEU", "FRA"))
		.allow(null)
		.required(),
	languageOfInstruction: Joi.string()
		.valid("ENG", "RUS", "DEU", "FRA")
		.allow(null)
		.required(),
	subjects: (Joi.array()
		.items(Joi.array().items(Joi.number().integer()))
		.required() as any) as Cast.Array<number[][]>,
	grant_subjects: Joi.array()
		.items(
			Joi.number()
				.integer()
				.required()
		)
		.allow(null),
	mandatory_tours: Joi.array()
		.items(
			Joi.number()
				.integer()
				.required()
		)
		.allow(null),
	totalPlaces: Joi.number().required(),
	numsBySubjs: Joi.array()
		.items(Joi.number().integer())
		.allow(null)
		.optional(),
	numsBySubjCombs: Joi.object()
		.pattern(/^[0-9,]*$/, Joi.number().required())
		.allow(null)
		.optional(),
	coefficients: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	minScoreLimits: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	minScores: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	medianScores: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	city: Joi.number()
		.integer()
		.required(),
	sector: Joi.number()
		.integer()
		.valid(0, 1)
		.required(),
	fields: Joi.array()
		.items(Joi.number().integer())
		.allow(null)
		.required(),
	photoUrl: Joi.string()
		.allow(null)
		.required(),
	yearOfUpdatedInfo: Joi.number()
		.integer()
		.allow(null)
		.required(),
	premium: Joi.number().required(),
	pagePositionCoefficient: Joi.number().required(),
	intraUniPositionCoefficient: Joi.number().required(),
	uniMainCoefficient: Joi.number().required(),
	locale: Joi.string()
		.valid("ka", "en")
		.required(),
});
export type IRProgram = SchemaToType<typeof RProgramSchema>;
type a = IRProgram["subjects"];
// tslint:disable-next-line:max-line-length
const RProgramFields: Array<keyof IRProgram> = [
	"id",
	"uni_id",
	"code",
	"name",
	"urlName",
	"officialName",
	"alias",
	"accreditation",
	"financed",
	"fullyFinancedPlaces",
	"price",
	"studyDuration",
	"foreignLangs",
	"languageOfInstruction",
	"subjects",
	"grant_subjects",
	"mandatory_tours",
	"totalPlaces",
	"numsBySubjs",
	"numsBySubjCombs",
	"coefficients",
	"minScoreLimits",
	"minScores",
	"medianScores",
	"city",
	"sector",
	"fields",
	"photoUrl",
	"yearOfUpdatedInfo",
	"premium",
	"pagePositionCoefficient",
	"intraUniPositionCoefficient",
	"uniMainCoefficient",
	"locale",
];
export { RProgramFields };

export const ProgramContentSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	program_id: Joi.number()
		.integer()
		.required(),
	title: Joi.string()
		.allow("", null)
		.required(),
	position: Joi.number()
		.integer()
		.required(),
	text: Joi.string()
		.allow("", null)
		.required(),
	galleryMedias: Joi.array()
		.items(GalleryMediaSchema)
		.allow(null)
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});
export type IProgramContent = SchemaToType<typeof ProgramContentSchema>;

export const ExamScoresSchema = Joi.object()
	.keys({
		first: Joi.number().required(),
		second: Joi.number().required(),
		third: Joi.number().required(),
		fourth: Joi.number().required(),
		total: Joi.number().required(),
	})
	.required();
export type IExamScores = SchemaToType<typeof ExamScoresSchema>;
