import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import memoizeOne from "memoize-one";
import Select from "react-select";
import { addSubjectAction, removeSubjectAction } from "@app/actions/lator";
import { connect } from "react-redux";
import { customTheme } from "./choose-programs";
import { CUUniId } from "./consts";
import { erovnulSubjectNames, erovnulSubjects } from "@app/commonJavascript";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { inject } from "@app/modules";
import { useCallback, useEffect, useState } from "react";

interface IOwnProps {
	onSubjectChange: (subjectId: number | null) => void;
	onSubjectGroupChange?: (subjGroup: number[] | null) => void;
	selectedSubjectId: number | string | null;
	selectedSubjectGroup?: number[] | null;
	programId?: number | null;
	label?: string;
	grant?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	displayedSubjectIds: number[];
	displayedSubjCombs: number[][];
	isAddingNewSubject: boolean;
}

const SubjectPicker: React.FC<IProps> = props => {
	const [displayedSubjectIds, setDisplayedSubjectIds] = useState<
		IState["displayedSubjectIds"]
	>([]);
	const [isAddingNewSubject, setIsAddingNewSubjectStatus] = useState<
		IState["isAddingNewSubject"]
	>(false);
	const [displayedSubjCombs, setDisplayedSubjCombs] = useState<
		IState["displayedSubjCombs"]
	>([]);

	const getProgram = memoizeOne(
		(programId?: number | null, programs?: IRProgram[]) => {
			if (programId === undefined || programId === null || !programs)
				return undefined;
			return programs.find(program => program.id === props.programId);
		}
	);

	const LatoriProgramModel = inject("LatoriProgramModel");
	useEffect(() => {
		const program =
			props.programId && getProgram(props.programId, props.programs);
		if (program) {
			if (program.grant_subjects && props.grant) {
				setDisplayedSubjectIds(program.grant_subjects);
			} else if ((program.subjects[1] as number[]).length === 0) {
				setDisplayedSubjectIds(
					(program.subjects[0] as number[]).filter(subjId =>
						erovnulSubjects.find(
							subj => subj.id === subjId && !subj.isCompulsory
						)
					)
				);
				setDisplayedSubjCombs([]);
			} else {
				if ((program.subjects[2] as number[]).length === 0) {
					if ((program.subjects[0] as number[]).length > 2) {
						setDisplayedSubjectIds([]);
						setDisplayedSubjCombs(
							(program.subjects[0] as number[])
								.slice(2)
								.map(subj0 =>
									(program
										.subjects[1] as number[]).map(subj1 => [
										subj0,
										subj1,
									])
								)
								.flat()
						);
					} else {
						setDisplayedSubjectIds(program.subjects[1] as number[]);
						setDisplayedSubjCombs([]);
					}
				} else {
					setDisplayedSubjectIds([]);
					setDisplayedSubjCombs(
						(program.subjects[0] as number[]).length <= 2
							? (program.subjects[1] as number[])
									.map(subj1 =>
										(program
											.subjects[2] as number[]).map(
											subj2 => [subj1, subj2]
										)
									)
									.flat()
							: (program.subjects[1] as number[])
									.slice(2)
									.map(subj0 =>
										(program.subjects[1] as number[])
											.map(subj1 =>
												(program
													.subjects[2] as number[]).map(
													subj2 => [
														subj0,
														subj1,
														subj2,
													]
												)
											)
											.flat()
									)
									.flat()
					);
				}
			}
		}
	}, [LatoriProgramModel, props.grant, props.programId, props.programs]);

	const onSubjectRemove = useCallback(
		(subjectId: number) => {
			if (displayedSubjectIds.length === 1) {
				setIsAddingNewSubjectStatus(true);
			}
			setDisplayedSubjectIds(displayedSubjectIds =>
				displayedSubjectIds.filter(e => e !== subjectId)
			);
			if (subjectId === props.selectedSubjectId) {
				// selected subject is being deleted
				if (displayedSubjectIds.length === 1) {
					props.onSubjectChange(null);
				} else {
					const currentProgram = !props.programId
						? undefined
						: LatoriProgramModel.findByIdSync(props.programId);
					const availableSubjects =
						currentProgram === undefined
							? erovnulSubjects.map(s => s.id)
							: currentProgram.subjects;
					const firstAvailableSubjId = displayedSubjectIds.find(
						subjId => {
							if (subjId === subjectId) return false;
							if (availableSubjects.indexOf(subjId) > -1)
								return true;
							return false;
						}
					);
					props.onSubjectChange(
						firstAvailableSubjId ||
							displayedSubjectIds.find(e => e !== subjectId)!
					);
				}
			}
			props.removeSubjectAction(subjectId);
		},
		[LatoriProgramModel, displayedSubjectIds, props]
	);

	const onSubjectAdd = useCallback(
		(subjectId: number) => {
			setDisplayedSubjectIds(displayedSubjectIds => [
				...displayedSubjectIds,
				subjectId,
			]);
			setIsAddingNewSubjectStatus(false);
			props.onSubjectChange(subjectId);
			props.addSubjectAction(subjectId);
		},
		[props]
	);

	const showAddSubject = useCallback(
		() => setIsAddingNewSubjectStatus(true),
		[]
	);

	const getAvailableSubjects = useCallback(() => {
		return erovnulSubjects
			.filter(s => !s.isCompulsory)
			.filter(s => displayedSubjectIds.indexOf(s.id) === -1)
			.map(s => ({
				value: s.id,
				label: s.name,
			}));
	}, [displayedSubjectIds]);

	const currentProgram = !props.programId
		? undefined
		: getProgram(props.programId, props.programs);
	const currentProgramUniId =
		currentProgram === undefined ? null : currentProgram.uni_id;
	const availableSubjects =
		currentProgram === undefined
			? erovnulSubjects.map(s => s.id)
			: currentProgram.subjects;
	return (
		<div className="subject_programs_picker">
			<div className="title">{props.label || "საგნები"}</div>
			<div>
				{displayedSubjectIds.map((subjectId, index) => {
					const subject = erovnulSubjects.find(
						subj => subj.id === subjectId
					);
					if (!subject) return null;
					if (subject.isCompulsory) return null;
					return (
						<div
							key={subjectId}
							className={
								"itemContainer" +
								(props.selectedSubjectId === subjectId
									? " selected"
									: "") +
								""
								// (props.value === subjectId &&
								// currentProgramUniId === CUUniId
								// 	? " cuStyle"
								// 	: "") +
								// (availableSubjects.indexOf(subjectId) === -1
								// 	? " pale"
								// 	: "")
							}
							onClick={() => props.onSubjectChange(subjectId)}
						>
							<div
								className={
									"item" +
									(currentProgramUniId === CUUniId
										? " cuStyleItem"
										: "")
								}
								style={
									index === displayedSubjectIds.length - 1
										? { borderBottom: "none" }
										: {}
								}
							>
								<div className="name">{subject.name}</div>
								{/* <div
									className="closeIcon"
									onClick={e => {
										e.stopPropagation();
										onSubjectRemove(subjectId);
									}}
								>
									<CloseIcon />
								</div> */}
							</div>
						</div>
					);
				})}
				{props.onSubjectGroupChange &&
					displayedSubjCombs.map((subjGroup, index) => {
						const names = subjGroup
							.map(id => erovnulSubjectNames[id])
							.join(" და ");
						const key = subjGroup.sort().join("-");
						const searchKey = (props.selectedSubjectGroup || [])
							.sort()
							.join("-");
						return (
							<div
								key={key}
								className={
									"itemContainer" +
									(searchKey === key ? " selected" : "")
								}
								onClick={() =>
									props.onSubjectGroupChange!(subjGroup)
								}
							>
								<div
									className={
										"item" +
										(currentProgramUniId === CUUniId
											? " cuStyleItem"
											: "")
									}
									style={
										index === displayedSubjCombs.length - 1
											? { borderBottom: "none" }
											: {}
									}
								>
									<div className="name">{names}</div>
								</div>
							</div>
						);
					})}
			</div>
			{/* <div
				className={
					"add" + (currentProgramUniId === CUUniId ? " cuStyle" : "")
				}
			>
				{!isAddingNewSubject && <AddIcon onClick={showAddSubject} />}
				{isAddingNewSubject && (
					<div>
						<Select
							value={undefined}
							onChange={selected => {
								if (selected)
									onSubjectAdd((selected as any).value);
							}}
							isSearchable={true}
							options={getAvailableSubjects()}
							placeholder="აირჩიეთ საგანი"
							theme={customTheme}
						/>
					</div>
				)}
			</div> */}
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	subjectIds: state.lator.subjectIds,
	fixedScores: state.lator.fixedScores,
	programs: state.programs.info,
});

const mapDispatchToProps = {
	addSubjectAction,
	removeSubjectAction,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(SubjectPicker);
