import Joi, { SchemaToType } from '@app/utils/joi';
import { UserPersonalInfoSchema } from '@app/api/users/validators';

///

export const AGETRecentStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required(),
});
export type IAGETRecentStats = SchemaToType<typeof AGETRecentStatsSchema>;
export type IRGETRecentStats = Array<IRGETSubscribedUnisStats & IRGETViewsStats & { date: string }>;

///

const AGETSubscriptionsStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required(),
	date: Joi.date().required(),
	forceUpdate: Joi.boolean().optional()
});
export type IAGETSubscriptionsStats = SchemaToType<typeof AGETSubscriptionsStatsSchema>;

///

export const AGETViewsStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required(),
	date: Joi.date().required(),
	forceUpdate: Joi.boolean().optional()
});
export type IAGETViewsStats = SchemaToType<typeof AGETViewsStatsSchema>;

export const RGETViewsStatsSchema = Joi.object().keys({
	uni_views_count: Joi.number().integer().required(),
	programs_views_count: Joi.number().integer().required(),
	uni_views_count_unique: Joi.number().integer().required(),
	programs_views_count_unique: Joi.number().integer().required(),
	uni_and_programs_views_count_unique: Joi.number().integer().required(),
});
export type IRGETViewsStats = SchemaToType<typeof RGETViewsStatsSchema>;

///

export const AGETSubscribedUnisStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required(),
	date: Joi.date().required(),
	forceUpdate: Joi.boolean().optional()
});
export type IAGETSubscribedUnisStats = SchemaToType<typeof AGETSubscribedUnisStatsSchema>;

export const RGETSubscribedUnisStatsSchema = Joi.object().keys({
	subscribes_count: Joi.number().integer().required(),
	unsubscribes_count: Joi.number().integer().required(),
	favorite_programs_count: Joi.number().integer().required(),
	favorite_programs_count_unique: Joi.number().integer().required(),
	unfavorite_programs_count: Joi.number().integer().required(),
	unfavorite_programs_count_unique: Joi.number().integer().required(),
	total_subscribes_count: Joi.number().integer().required(),
	total_unsubscribes_count: Joi.number().integer().required(),
	total_favorite_programs_count: Joi.number().integer().required(),
	total_unfavorite_programs_count: Joi.number().integer().required(),
	total_favorite_programs_count_unique: Joi.number().integer().required(),
	total_unfavorite_programs_count_unique: Joi.number().integer().required(),
});
export type IRGETSubscribedUnisStats = SchemaToType<typeof RGETSubscribedUnisStatsSchema>;

///

export const AGETStudentsStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required(),
});

export type IAGETStudentsStats = SchemaToType<typeof AGETStudentsStatsSchema>;

export const StudentStatsSchema = Joi.object().keys({
	personalInfo: UserPersonalInfoSchema.allow(null),
	subscribed: Joi.boolean().required(),
	favoritePrograms: Joi.array().items(Joi.number().integer()).required(),
	admissionPrograms: Joi.array().items(Joi.number().integer()).required(),
});

export type IStudentStats = SchemaToType<typeof StudentStatsSchema>;

export const RGETStudentsStatsSchema = Joi.object().pattern(
	/^[0-9]*$/,
	StudentStatsSchema
);

export type IRGETStudentsStats = SchemaToType<typeof RGETStudentsStatsSchema>;

///

export const AGETUniAdmissionStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required()
});

export type IAGETUniAdmissionStats = SchemaToType<typeof AGETUniAdmissionStatsSchema>;

export const RGETUniAdmissionStatsSchema = Joi.object().keys({
	totalCount: Joi.number().integer().required(),
	verifiedCount: Joi.number().integer().required(),
	nonVerifiedCount: Joi.number().integer().required()
});

export type IRGETUniAdmissionStats = SchemaToType<typeof RGETUniAdmissionStatsSchema>;

///

export const AGETUniViewStatsSchema = Joi.object().keys({
	uni_id: Joi.number().integer().required()
});

export type IAGETUniViewStats = SchemaToType<typeof AGETUniViewStatsSchema>;

export const RGETUniViewStatsSchema = Joi.object().keys({
	totalCount: Joi.number().integer().required(),
	verifiedCount: Joi.number().integer().required(),
	nonVerifiedCount: Joi.number().integer().required()
});

export type IRGETUniViewStats = SchemaToType<typeof RGETUniViewStatsSchema>;
