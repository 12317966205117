import { Requests } from "@app/api";
import {
	CountSchema,
	IAPOSTUserAction,
	ICount,
	IRGETUserAction,
	IRPOSTUserAction,
	RGETUserActionSchema,
	RPOSTUserActionSchema,
} from "./validators";

const userActions = {
	getUserAction: (): Promise<IRGETUserAction> =>
		Requests.send("GET", "/api/users/user-actions/", {}, null, {
			responseSchema: RGETUserActionSchema,
		}),
	performUserAction: (data: IAPOSTUserAction): Promise<IRPOSTUserAction> =>
		Requests.send("POST", "/api/users/user-actions/", data, null, {
			responseSchema: RPOSTUserActionSchema,
		}),
	getActionCount: (): Promise<{ count: number }> =>
		Requests.send("GET", "/api/users/user-actions/action-count", {}, null, {
			responseSchema: CountSchema,
		}),
	importUserSubscriptions: (): Promise<any> =>
		Requests.send(
			"GET",
			"/api/users/user-actions/import-latori-subscriptions",
			{},
			{
				responseType: "blob",
			}
		),
	getLatorUsersCount: (): Promise<ICount> =>
		Requests.send(
			"GET",
			"/api/users/user-actions/lator-users-count",
			{},
			{
				CountSchema,
			}
		),
	importAllUsersLatorInfo: (): Promise<any> =>
		Requests.send(
			"GET",
			"/api/users/user-actions/all-users-lator-info",
			{},
			{
				responseType: "blob",
			}
		),
};
export default userActions;
