import React, { useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const PDF: React.FC<{
	url: string;
	pages: number[];
	documentClassname?: string;
	pageClassName?: string;
}> = ({ url, pages, documentClassname, pageClassName }) => {
	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	}, []);
	return (
		<Document
			file={{ url }}
			{...(documentClassname && { className: documentClassname })}
		>
			{pages.map(pageNumber => (
				<Page
					key={`page${pageNumber}`}
					pageNumber={pageNumber}
					{...(pageClassName && { className: pageClassName })}
				/>
			))}
		</Document>
	);
};

export default PDF;
