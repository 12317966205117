import { css } from "emotion";
import React, { useState } from "react";
import Popup, { PopupContent } from "../Widgets/Popup";
import IBSUBanner from "./styles/img/IBSU_Banner.jpg";
import CloseIcon from "@material-ui/icons/Close";

const IBSUPopup = (): JSX.Element => {
	const [isOpen, setIsOpen] = useState(true);
	const close = () => setIsOpen(false);
	const redirect = () =>
		window.open(
			"https://ibsu.edu.ge/ge/%e1%83%92%e1%83%a0%e1%83%90%e1%83%9c%e1%83%a2%e1%83%94%e1%83%91%e1%83%98/",
			"_blank"
		);
	if (!isOpen) return <></>;
	return (
		<Popup onClose={close}>
			<PopupContent className={PopupStyles}>
				<div onClick={close} className={CloseIconStylesCSS}>
					<CloseIcon style={{ height: "100%" }} />
				</div>
				<img src={IBSUBanner} alt="ibsu" className={ImageStyles} />
				<button onClick={redirect} className={ButtonStyles}>
					გაიგე მეტი
				</button>
			</PopupContent>
		</Popup>
	);
};

const ButtonStyles = css`
	position: absolute;
	left: 50%;
	bottom: 12%;
	transform: translateX(-50%);
	border-radius: 20px;
	color: white;
	background-color: #0254b7;
	font-size: 20px;
	font-weight: bold;
	font-family: "RobotoUpperCase";
	cursor: pointer;
	outline: none;
	padding: 10px 20px;
	border: none;

	@media screen and (max-width: 700px) {
		font-size: 16px;
		padding: 6px 12px;
		bottom: 15%;
	}

	@media screen and (max-width: 500px) {
		font-size: 14px;
		padding: 4px 8px;
	}
`;

const PopupStyles = css`
	width: 900px !important;
	max-width: calc(100% - 30px);
`;

const CloseIconStylesCSS = css`
	position: absolute;
	top: -10px;
	right: -10px;
	background-color: white;
	box-shadow: 0 0 2px 2px #c2c2c2;
	cursor: pointer;
	width: 32px;
	height: 32px;
	border-radius: 30px;
	text-align: center;
`;

const ImageStyles = css`
	width: 100%;
	height: auto;
`;

export default IBSUPopup;
