import * as React from "react";
import styled from "@emotion/styled";
import TextareaAutosize from "react-textarea-autosize";
import Send from "@material-ui/icons/Send";

const Container = styled.div`
	width: 100%;
	padding: 5px 15px 10px 15px;
	border-radius: ${(props: any) =>
		props.borderRadius ? props.borderRadius : "0 0 20px 20px"};
	z-index: 99;
	background-color: white;
`;

const TextAreaContainer = styled.div`
	width: 100%;
	padding: 2px 0;
	border-radius: ${(props: any) =>
		props.borderRadius ? props.borderRadius : "20px"};
	border: 1px solid #a9a9a9;
`;

const textAreaStyle = {
	width: "calc(100% - 30px)",
	resize: "none" as "none",
	padding: "7px 13px",
	outline: "none",
	border: "none",
	background: "transparent",
	overflowY: "hidden" as "hidden",
	verticalAlign: "middle",
};

export interface ITextAreaContainerStyle {
	borderRadius?: string;
}
export type ITextAreaStyle = ITextAreaContainerStyle;

interface IOwnProps {
	containerStyle?: ITextAreaContainerStyle;
	textareaStyle?: ITextAreaStyle;
	onSend: (messageText: string) => void;
}

type IProps = IOwnProps;

interface IState {
	messageText: string;
}

class MiniChatTextArea extends React.Component<IProps, IState> {
	state: IState = {
		messageText: "",
	};
	textarea;

	handleKeyDown = event => {
		if (event.keyCode !== 13) return;
		if (event.shiftKey) return;
		if (this.state.messageText.length <= 0) return;
		if (this.state.messageText.replace(/\s/g, "").length <= 0) {
			this.setState({ messageText: "" });
			return;
		}
		this.props.onSend(this.state.messageText);
		this.setState({ messageText: "" });
	};

	onMessageTextChane = event => {
		if (event.target.value === "\n") return;
		this.setState({ messageText: event.target.value });
	};

	render() {
		if (this.textarea) this.textarea.focus();
		const borderRadius = this.props.containerStyle
			? this.props.containerStyle.borderRadius
			: undefined;
		const areaBorderRadius = this.props.textareaStyle
			? this.props.textareaStyle.borderRadius
			: undefined;

		return (
			<Container borderRadius={borderRadius}>
				<TextAreaContainer borderRadius={areaBorderRadius}>
					<TextareaAutosize
						style={textAreaStyle}
						minRows={1}
						maxRows={3}
						value={this.state.messageText}
						placeholder="შეიყვანეთ შეტყობინება"
						onChange={this.onMessageTextChane}
						onKeyDown={this.handleKeyDown}
						inputRef={tag => (this.textarea = tag)}
						autoFocus={true}
					/>
					<Send
						style={{ verticalAlign: "middle", fill: "#a9a9a9" }}
						onClick={() =>
							this.props.onSend(this.state.messageText)
						}
					/>
				</TextAreaContainer>
			</Container>
		);
	}
}

export default MiniChatTextArea;
