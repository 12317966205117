import * as React from "react";
import api from "@app/api";
import { addLoader } from "@app/commonJavascript";
import { canSendUniSMS } from "@app/permissions";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRUser } from "@app/api/users/helper-schemas";
import { match } from "react-router";
import "../styles/adminPrograms.min.css";
import "../styles/smsGroups.min.css";
import RefreshIcon from "@material-ui/icons/Refresh";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string; sms_group_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

interface IState {
	text: string;
	success: number;
	failures: number;
	total_number: number;
	loading: boolean;
}

class SMSGroupPage extends React.Component<IProps, IState> {
	uni_id: number = +this.props.match.params.uni_id;
	_isMounted: boolean;
	smsGroupId: number = +this.props.match.params.sms_group_id;

	state: IState = {
		text: "",
		loading: true,
		success: 0,
		failures: 0,
		total_number: 0,
	};

	constructor(props: IProps) {
		super(props);
		const { uni_id } = props.match.params;
		if (!uni_id) {
			return;
		}
		this.uni_id = +uni_id;
		if (!canSendUniSMS(this.uni_id, props.userData.permissions)) {
			this.props.history.replace("/admin/uni/" + this.uni_id);
			return;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		this.loadData();
	}

	loadData = () => {
		const removeLoader = addLoader();
		if (!this.state.loading) {
			this.setState({
				loading: true,
			});
		}
		api.sms
			.getGroup({
				sms_group_id: this.smsGroupId,
				uni_id: this.uni_id,
			})
			.then(data => {
				removeLoader();
				if (!this._isMounted) return;
				this.setState({
					loading: false,
					text: data.text,
					success: data.success,
					failures: data.failures,
					total_number: data.total_number,
				});
			})
			.catch(err => {
				removeLoader();
				if (!this._isMounted) return;
				alert("დაფიქსირდა შეცდომა");
			});
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		if (this.state.loading) return "იტვირთება...";
		if (this.state.total_number === 0) return "არავისთანაა გაგზავნილი";
		return (
			<div style={{ textAlign: "center" }}>
				<div className="main">
					<div className="sms-info-container">
						ტექსტი:{" "}
						<div className="sms-text-container">
							{this.state.text}
						</div>
						<br />
						უნდა გაიგზავნოს {this.state.total_number} ნომერთან
						<br />
						წარმატებით გაიგზავნა {this.state.success} ნომერთან (
						{(
							(this.state.success / this.state.total_number) *
							100
						).toFixed(1)}
						%)
						<br />
						არ მივიდა {this.state.failures} ნომერთან (
						{(
							(this.state.failures / this.state.total_number) *
							100
						).toFixed(1)}
						%)
						<br />
						{this.state.success + this.state.failures !==
							this.state.total_number && (
							<button
								className="adminPrimaryButton"
								onClick={this.loadData}
								style={{
									width: 35,
									height: 35,
									borderRadius: 20,
								}}
							>
								<RefreshIcon style={{ marginBottom: 4 }} />
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

export default connect(mapStateToProps)(SMSGroupPage);
