import { History } from "history";
import React from "react";
import { match } from "react-router-dom";
import Header from "../Header";
import "./styles/lator-page.min.css";

interface IProps {
	history: History;
	match: match;
	handleCallRequest: () => void;
	programName?: string;
	programId?: number;
}

const TouLatorResultSuccess: React.FC<IProps> = (props: IProps) => {
	return (
		<div>
			<Header showOnlyLogo history={props.history} />
			<div className="toulator-container">
				<div className="progress-bar">
					<div
						className="progress-ball success"
						style={{ left: "80%" }}
					/>
				</div>
				<div className="tou-image success results-page-div"></div>
				<div className="results-page-text results-page-div">
					<h1>გილოცავთ!</h1>
					<p>
						შენი შედეგების მიხედვით <br />
						<span>
							{`შენ 100%-ით გარანტირებულად გახდები თბილისის ღია უნივერსიტეტის ${
								props.programName
									? props.programId !== -1
										? props.programName.substr(
												0,
												props.programName.length - 1
										  ) + "ის ფაკულტეტის"
										: "ნებისმიერი ფაკულტეტის"
									: ""
							} 
							სტუდენტი!`}
						</span>
					</p>
				</div>
				<div className="mobile_wrapper">
					<div className="results-page-div">
						<iframe
							title="tou video"
							className={"video_container"}
							allowTransparency={true}
							frameBorder={0}
							style={{ backgroundColor: "white" }}
							allow="autoplay"
							src={`https://www.youtube.com/embed/a3aOV9tZDPE?rel=0;autoplay=1&mute=0&enablejsapi=1;playlist=a3aOV9tZDPE&loop=1;allow='autoplay'`}
						></iframe>
						<br />
					</div>
					<div className="results-page-div">
						{/* <div className="header">საჩუქარი შენთვის</div> */}
						<div className="header-image warrantry" />
						<h2>
							მოითხოვე დეტალური ინფორმაცია ღია უნივერსიტეტის
							მენეჯერისგან
						</h2>
						<button
							className="primary-button"
							style={{ marginTop: "-10px" }}
							onClick={props.handleCallRequest}
						>
							მოთხოვნის გაგზავნა
						</button>
						<br />
						<br />
						<br />
					</div>
				</div>
			</div>
		</div>
	);
};
export default TouLatorResultSuccess;
