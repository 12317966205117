import React, { useEffect, useRef, useState } from "react";
import { IntlProvider, updateIntl } from "react-intl-redux";
import { store } from "../index";
import { loadLocale } from "../locale";
import { getUnisAndPrograms } from "./actions/unis";
import { useStoreProperty } from "./hooks/store";
import { ToDispatchType } from "@app/reducers/root";
import { connect } from "react-redux";

interface IOwnProps {
	children: React.ReactNode | React.ReactNode[];
}
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps;
const IntlWrapper = React.memo<IProps>(function IntlWrapper(props) {
	const wasMounted = useRef(false);
	const intl = useStoreProperty("intl")!;
	const locale = intl.locale as "ka" | "en";

	useEffect(() => {
		wasMounted.current = true;
	}, []);

	const [isLoaded, setisLoaded] = useState(false);

	useEffect(() => {
		setisLoaded(false);
		let isCancelled = false;
		loadAndUpdateIntlMessages(locale, null).then(() => {
			if (isCancelled) return;
			props.getUnisAndPrograms(true).then();
			setisLoaded(true);
		});
		return () => {
			isCancelled = true;
		};
	}, [locale, props]);

	if (!isLoaded) {
		return null;
	}

	return <IntlProvider {...intl}>{props.children}</IntlProvider>;
});

const updateIntlMessages = (locale: "ka" | "en", messages: any) => {
	store.dispatch(updateIntl({ locale, messages }));
	localStorage.locale = locale;
};

export const loadAndUpdateIntlMessages = async (
	locale: "ka" | "en",
	specialFlags: string[] | null
) => {
	const { messages } = await loadLocale(locale, specialFlags);
	updateIntlMessages(locale, messages);
};

(window as any).loadAndUpdateIntlMessages = loadAndUpdateIntlMessages;
const mapDispatchToProps = {
	getUnisAndPrograms,
};

export default connect<null, IDispatchProps, {}>(
	null,
	mapDispatchToProps as any
)(IntlWrapper);
