import memoizeOne from "memoize-one";
import {
	erovnulSubjects,
	grantLimitScores,
	scaledScoreToScore,
	scoresToScaledScore,
	toScaledScore,
} from "@app/commonJavascript";
import { IUserScores } from "@app/reducers/lator";

const TOT_SCORE_ERR_THRESHOLD = 10;
const DEV_THRESHOLD = 0.00005;
const TOO_HIGH_SCORES = "TOO_HIGH_SCORES";

interface IArgs {
	admissionScores: IExamData[];
	essentialScores: number[] | null;
	coefficients: ICoefficients;
	userRawScores: IUserScores;
	electiveSubjectIds: number[];
	place?: number;
	rightBoundary?: number; // only for binary search purposes
	leftBoundary?: number; // only for binary search purposes
	recursiveCall?: boolean;
}

export enum ResultType {
	CALCULATED_SCORES,
	CALCULATED_PLACE,
	NOT_ENOUGH_SCORES_FOR_LAST_PLACE,
	SCORES_LESS_THAN_LIMITS,
}

export type IResults =
	| {
			type: ResultType.CALCULATED_SCORES;
			scores: IUserScores;
			place: number;
			contestScaledScore: number;
	  }
	| {
			type: ResultType.CALCULATED_PLACE;
			place: number;
			contestScaledScore: number;
	  }
	| {
			type: ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE;
			myScaledScore?: number;
			requiredScaledScore: number;
	  }
	| {
			type: ResultType.SCORES_LESS_THAN_LIMITS;
			subjectId: number;
			requiredScore: number;
	  };

export type ICoefficients = number[];

export interface IExamData extends IUserScores {
	total: number;
}

interface IBound {
	low: number;
	high: number;
}

interface IScoreBounds {
	[subjectId: string]: IBound;
	total: IBound;
}

const findMediansInEverySubject = memoizeOne(
	(programScores: IExamData[]): IUserScores => {
		const size = programScores.length;

		const medians: IUserScores = {};

		Object.keys(programScores[0]).forEach(subjId => {
			if (String(Number(subjId)) !== subjId) return; // keys of programScores are either subjectIds or "total"
			programScores.sort((a, b) => a[subjId] - b[subjId]);
			let medianScore = 0;
			if (size % 2 === 0) {
				medianScore =
					(programScores[Math.floor((size - 1) / 2)][subjId] +
						programScores[Math.floor(size / 2)][subjId]) /
					2;
			} else medianScore = programScores[Math.floor(size / 2)][subjId];
			medians[subjId] = medianScore;
		});

		return medians;
	}
);

export function findMedians(
	programScores: IExamData[],
	subjectIds: number[],
	userScores: IUserScores,
	coefficients: ICoefficients
): IExamData {
	const medians: IExamData = { total: 0 };
	const size = programScores.length;

	let medianTotalScore = 0;

	const medScores = findMediansInEverySubject(programScores);

	subjectIds.forEach(id => {
		medians[id] = medScores[id];
		medianTotalScore += medScores[id] * coefficients[id];
	});

	for (const subjectId in userScores) {
		if (userScores.hasOwnProperty(subjectId)) {
			medianTotalScore += userScores[subjectId] * coefficients[subjectId];
		}
	}

	medians.total = medianTotalScore;

	return medians;
}

function calculateFractions(
	userScores: IUserScores,
	medians: IExamData,
	coefficients: ICoefficients
): IUserScores {
	const subjectMedians: IUserScores = {};
	Object.assign(subjectMedians, medians);
	delete subjectMedians.total;

	const result = {};

	for (const key in userScores) {
		if (userScores.hasOwnProperty(key)) {
			const subjectId = parseInt(key, 10);

			if (typeof subjectMedians[subjectId] !== "undefined") {
				result[subjectId] =
					(subjectMedians[subjectId] * coefficients[subjectId]) /
					(medians.total * 1.0);
			} else {
				result[subjectId] =
					(userScores[subjectId] * coefficients[subjectId]) /
					(medians.total * 1.0);
			}
		}
	}

	for (const key in subjectMedians) {
		if (subjectMedians.hasOwnProperty(key)) {
			const subjectId = parseInt(key, 10);

			if (typeof result[subjectId] === "undefined") {
				result[subjectId] =
					(subjectMedians[subjectId] * coefficients[subjectId]) /
					(medians.total * 1.0);
			}
		}
	}

	return result;
}

function getBounds(
	userScores: IUserScores,
	freeSubjects: number[],
	programScores: IExamData[],
	essentialScores: IUserScores,
	place?: number
): IScoreBounds {
	const result = {} as IScoreBounds;
	result.total = {} as IBound;

	// if place is not provided, choose the worst score
	result.total.low =
		programScores[
			typeof place !== "undefined" ? programScores.length - place : 0
		].total;
	result.total.high = result.total.low + TOT_SCORE_ERR_THRESHOLD;

	freeSubjects.forEach(subjectId => {
		// TODO: maybe check subjects that do not have min/max scores

		const subject = erovnulSubjects.find(s => s.id === subjectId);
		if (!subject) throw new Error(`subject ${subjectId} not found`);
		const low = Math.max(
			subject.minScore!,
			essentialScores[subjectId] || 0
		);
		const high = subject.maxScore!;

		result[subjectId] = { low, high };
	});

	for (const subjectId in userScores) {
		if (userScores.hasOwnProperty(subjectId)) {
			// not scaled
			const score = scaledScoreToScore(
				userScores[subjectId],
				parseInt(subjectId, 10)
			);

			result[subjectId] = {
				low: score,
				high: score,
			};
		}
	}

	return result;
}

// tslint:disable-next-line:cognitive-complexity
function getNeededScores4(
	bounds: IScoreBounds,
	coeffObj: ICoefficients,
	fractions: IUserScores
) {
	function calculateError(
		subjectId: number,
		score: number,
		scaledTotal: number
	) {
		return (
			(toScaledScore(score, subjectId) * coeffObj[subjectId]) /
				scaledTotal -
			fractions[subjectId]
		);
	}

	let minStdErr = Infinity;
	let hasFound = false;
	let canBeAdmitted = false;
	const scoresNeeded: IUserScores = {} as any;

	const ids = Object.keys(bounds)
		.filter(key => key !== "total")
		.map(id => parseInt(id, 10));
	const coefficients = [] as number[];
	for (const id of ids) coefficients[id] = coeffObj[id];
	for (
		let i = bounds[ids[0]].low;
		i <= bounds[ids[0]].high && minStdErr > DEV_THRESHOLD;
		i++
	) {
		for (
			let j = bounds[ids[1]].low;
			j <= bounds[ids[1]].high && minStdErr > DEV_THRESHOLD;
			j++
		) {
			const scaledHigh1 = scoresToScaledScore(
				[i, j, bounds[ids[2]].high, bounds[ids[3]].high],
				ids,
				coefficients
			);
			if (scaledHigh1 < bounds.total.low) {
				continue;
			}
			const scaledLow1 = scoresToScaledScore(
				[i, j, bounds[ids[2]].low, bounds[ids[3]].low],
				ids,
				coefficients
			);
			if (scaledLow1 > bounds.total.high) {
				canBeAdmitted = true;
				continue;
			}
			for (
				let k = bounds[ids[2]].low;
				k <= bounds[ids[2]].high && minStdErr > DEV_THRESHOLD;
				k++
			) {
				const scaledHigh2 = scoresToScaledScore(
					[i, j, k, bounds[ids[3]].high],
					ids,
					coefficients
				);
				if (scaledHigh2 < bounds.total.low) {
					continue;
				}
				const scaledLow2 = scoresToScaledScore(
					[i, j, k, bounds[ids[3]].low],
					ids,
					coefficients
				);
				if (scaledLow2 > bounds.total.high) {
					canBeAdmitted = true;
					continue;
				}
				for (
					let c = bounds[ids[3]].low;
					c <= bounds[ids[3]].high && minStdErr > DEV_THRESHOLD;
					c++
				) {
					const scaledTotal = scoresToScaledScore(
						[i, j, k, c],
						ids,
						coefficients
					);
					if (
						scaledTotal >= bounds.total.low &&
						scaledTotal <= bounds.total.high
					) {
						const err0 = calculateError(ids[0], i, scaledTotal);
						const err1 = calculateError(ids[1], j, scaledTotal);
						const err2 = calculateError(ids[2], k, scaledTotal);
						const err3 = calculateError(ids[3], c, scaledTotal);
						const stdErr =
							err0 * err0 +
							err1 * err1 +
							err2 * err2 +
							err3 * err3;
						if (stdErr < minStdErr) {
							minStdErr = stdErr;
							scoresNeeded[ids[0]] = i;
							scoresNeeded[ids[1]] = j;
							scoresNeeded[ids[2]] = k;
							scoresNeeded[ids[3]] = c;
							hasFound = true;
						}
					} else if (
						scaledTotal > bounds.total.low &&
						!canBeAdmitted
					) {
						canBeAdmitted = true;
					} else if (bounds.total.low - scaledTotal > 20) {
						c += Math.max(
							0,
							Math.floor((bounds.total.low - scaledTotal) / 15) -
								1
						);
					}
				}
			}
		}
	}

	return { scoresNeeded, canBeAdmitted, hasFound };
}

// tslint:disable-next-line:cognitive-complexity
function getNeededScores2(
	bounds: IScoreBounds,
	coeffObj: ICoefficients,
	fractions: IUserScores
) {
	function calculateError(
		subjectId: number,
		score: number,
		scaledTotal: number
	) {
		return (
			(toScaledScore(score, subjectId) * coeffObj[subjectId]) /
				scaledTotal -
			fractions[subjectId]
		);
	}

	let minStdErr = Infinity;
	let hasFound = false;
	let canBeAdmitted = false;
	const scoresNeeded: IUserScores = {} as any;

	const ids = Object.keys(bounds)
		.filter(key => key !== "total")
		.map(id => parseInt(id, 10));
	const coefficients = [] as number[];
	for (const id of ids) coefficients[id] = coeffObj[id];

	for (
		let i = bounds[ids[0]].low;
		i <= bounds[ids[0]].high && minStdErr > DEV_THRESHOLD;
		i++
	) {
		for (
			let j = bounds[ids[1]].low;
			j <= bounds[ids[1]].high && minStdErr > DEV_THRESHOLD;
			j++
		) {
			const scaledTotal = scoresToScaledScore([i, j], ids, coefficients);
			if (
				scaledTotal >= bounds.total.low &&
				scaledTotal <= bounds.total.high
			) {
				const err0 = calculateError(ids[0], i, scaledTotal);
				const err1 = calculateError(ids[1], j, scaledTotal);
				const stdErr = err0 * err0 + err1 * err1;
				if (stdErr < minStdErr) {
					minStdErr = stdErr;
					scoresNeeded[ids[0]] = i;
					scoresNeeded[ids[1]] = j;
					hasFound = true;
				}
			} else if (scaledTotal > bounds.total.low && !canBeAdmitted) {
				canBeAdmitted = true;
			} else if (bounds.total.low - scaledTotal > 20) {
				j += Math.max(
					0,
					Math.floor((bounds.total.low - scaledTotal) / 15) - 1
				);
			}
		}
	}

	return { scoresNeeded, canBeAdmitted, hasFound };
}

// tslint:disable-next-line:cognitive-complexity
function getNeededScores3(
	bounds: IScoreBounds,
	coeffObj: ICoefficients,
	fractions: IUserScores
) {
	function calculateError(
		subjectId: number,
		score: number,
		scaledTotal: number
	) {
		return (
			(toScaledScore(score, subjectId) * coeffObj[subjectId]) /
				scaledTotal -
			fractions[subjectId]
		);
	}

	let minStdErr = Infinity;
	let hasFound = false;
	let canBeAdmitted = false;
	const scoresNeeded: IUserScores = {} as any;

	const ids = Object.keys(bounds)
		.filter(key => key !== "total")
		.map(id => parseInt(id, 10));
	const coefficients = [] as number[];
	for (const id of ids) coefficients[id] = coeffObj[id];

	for (
		let i = bounds[ids[0]].low;
		i <= bounds[ids[0]].high && minStdErr > DEV_THRESHOLD;
		i++
	) {
		for (
			let j = bounds[ids[1]].low;
			j <= bounds[ids[1]].high && minStdErr > DEV_THRESHOLD;
			j++
		) {
			const scaledHigh1 = scoresToScaledScore(
				[i, j, bounds[ids[2]].high],
				ids,
				coefficients
			);
			if (scaledHigh1 < bounds.total.low) {
				continue;
			}
			const scaledLow1 = scoresToScaledScore(
				[i, j, bounds[ids[2]].low],
				ids,
				coefficients
			);
			if (scaledLow1 > bounds.total.high) {
				canBeAdmitted = true;
				continue;
			}
			for (
				let k = bounds[ids[2]].low;
				k <= bounds[ids[2]].high && minStdErr > DEV_THRESHOLD;
				k++
			) {
				const scaledTotal = scoresToScaledScore(
					[i, j, k],
					ids,
					coefficients
				);
				if (
					scaledTotal >= bounds.total.low &&
					scaledTotal <= bounds.total.high
				) {
					const err0 = calculateError(ids[0], i, scaledTotal);
					const err1 = calculateError(ids[1], j, scaledTotal);
					const err2 = calculateError(ids[2], k, scaledTotal);
					const stdErr = err0 * err0 + err1 * err1 + err2 * err2;
					if (stdErr < minStdErr) {
						minStdErr = stdErr;
						scoresNeeded[ids[0]] = i;
						scoresNeeded[ids[1]] = j;
						scoresNeeded[ids[2]] = k;
						hasFound = true;
					}
				} else if (scaledTotal > bounds.total.low && !canBeAdmitted) {
					canBeAdmitted = true;
				} else if (bounds.total.low - scaledTotal > 20) {
					k += Math.max(
						0,
						Math.floor((bounds.total.low - scaledTotal) / 15) - 1
					);
				}
			}
		}
	}

	return { scoresNeeded, canBeAdmitted, hasFound };
}

function rawScoresToScaledScores(rawScores: IUserScores): IUserScores {
	const userScores = {} as IUserScores;
	for (const subjId in rawScores) {
		if (rawScores.hasOwnProperty(subjId)) {
			userScores[subjId] = toScaledScore(rawScores[subjId], +subjId);
		}
	}
	return userScores;
}

// tslint:disable-next-line:cognitive-complexity
function searchPosition(value: number, array: number[]): number {
	let low = 0;
	let high = array.length - 1;

	if (typeof array[0] !== "undefined" && value <= array[0]) {
		return 0;
	}

	if (
		typeof array[array.length - 1] !== "undefined" &&
		value > array[array.length - 1]
	) {
		return array.length;
	}

	while (low <= high) {
		const mid = Math.floor((low + high) / 2);
		const current = array[mid];
		const previous = array[mid - 1] || Infinity;
		const next = array[mid + 1] || -Infinity;

		if (value === previous) {
			return mid - 1;
		}
		if (value > previous && value <= current) {
			return mid;
		}
		if (value > current && value <= next) {
			return mid + 1;
		}

		if (value < current) {
			high = mid - 1;
		} else if (value > current) {
			low = mid + 1;
		} else {
			return mid;
		}
	}

	return -1;
}

function calcTotalScaledScores(
	userRawScores: IUserScores,
	coefficients: ICoefficients
): number {
	const scores = [] as number[];
	const subjects = Object.keys(userRawScores).map(Number);

	for (let i = 0; i < subjects.length; i++) {
		const score = userRawScores[subjects[i]];
		scores[i] = score;
	}

	const scaledTotal = scoresToScaledScore(scores, subjects, coefficients);
	return scaledTotal;
}

function calculatePlaceByFullScores(
	userRawScores: IUserScores,
	coefficients: ICoefficients,
	programScores: IExamData[]
): IResults {
	const scaledTotal = calcTotalScaledScores(userRawScores, coefficients);

	const scaledScoresSorted = programScores
		.map(e => e.total)
		.sort((a, b) => a - b);

	if (scaledTotal < scaledScoresSorted[0]) {
		return {
			type: ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE,
			myScaledScore: scaledTotal,
			requiredScaledScore: scaledScoresSorted[0],
		};
	}

	const place =
		scaledScoresSorted.length -
		searchPosition(scaledTotal, scaledScoresSorted);

	return {
		type: ResultType.CALCULATED_PLACE,
		place: place + 1,
		contestScaledScore: scaledTotal,
	};
}

function toLimitScores(essentialScores: number[] | null): IUserScores {
	const scoreLimits: IUserScores = {};
	if (essentialScores === null) {
		return {};
	}
	essentialScores.forEach((limit, subjectId) => {
		if (!limit) return;
		const subject = erovnulSubjects.find(s => s.id === subjectId);
		if (!subject) throw new Error(`subject ${subjectId} not found`);
		scoreLimits[subjectId] =
			Math.floor((subject.maxScore! * limit) / 100) + 1;
	});
	return scoreLimits;
}

export function areScoresPassingLimits(
	essentialScores: number[] | null,
	userRawScores: IUserScores
) {
	const scoreLimtis = toLimitScores(essentialScores);
	let limitsAreSatisfied = true;
	Object.keys(scoreLimtis).forEach(subjectId => {
		if (userRawScores[subjectId] < scoreLimtis[subjectId]) {
			limitsAreSatisfied = false;
		}
	});
	return limitsAreSatisfied;
}

// tslint:disable-next-line:cognitive-complexity
export function calculateLatorResults(args: IArgs): IResults {
	const {
		admissionScores,
		essentialScores,
		coefficients,
		userRawScores,
		electiveSubjectIds,
		place,
		recursiveCall,
	} = args;
	const userScores = rawScoresToScaledScores(userRawScores);
	const essentialSubjects = erovnulSubjects
		.filter(e => e.isCompulsory)
		.map(e => e.id);
	const userSubjects = Object.keys(userScores).map(Number);

	const scoreLimits = toLimitScores(essentialScores);

	let limitsAreSatisfied = true;
	let unsatisfiedSubjectId = -1;
	Object.keys(scoreLimits).forEach(subjectId => {
		if (userRawScores[subjectId] < scoreLimits[subjectId]) {
			limitsAreSatisfied = false;
			unsatisfiedSubjectId = +subjectId;
		}
	});
	if (!limitsAreSatisfied) {
		return {
			type: ResultType.SCORES_LESS_THAN_LIMITS,
			requiredScore: scoreLimits[unsatisfiedSubjectId],
			subjectId: unsatisfiedSubjectId,
		};
	}

	const freeSubjects = essentialSubjects.filter(
		e => userSubjects.indexOf(e) === -1
	);

	for (const id of electiveSubjectIds) {
		if (
			userSubjects.indexOf(id) === -1 &&
			erovnulSubjects.findIndex(
				subj => subj.id === id && subj.noScores
			) === -1
		) {
			freeSubjects.push(id);
		}
	}

	if (freeSubjects.length === 0) {
		return calculatePlaceByFullScores(
			userRawScores,
			coefficients,
			admissionScores
		);
	}

	const medians: IExamData = findMedians(
		admissionScores,
		freeSubjects,
		userScores,
		coefficients
	);

	admissionScores.sort((a, b) => a.total - b.total);
	const fractions: IUserScores = calculateFractions(
		userScores,
		medians,
		coefficients
	);
	const bounds: IScoreBounds = getBounds(
		userScores,
		freeSubjects,
		admissionScores,
		scoreLimits,
		place
	);

	if (fractions.hasOwnProperty(13)) delete fractions[13];
	const getNeededScores =
		Object.keys(fractions).length === 4
			? getNeededScores4
			: Object.keys(fractions).length === 3
			? getNeededScores3
			: getNeededScores2;
	const { scoresNeeded, hasFound, canBeAdmitted } = getNeededScores(
		bounds,
		coefficients,
		fractions
	);

	if (!hasFound && canBeAdmitted) {
		if (place === 1) {
			[...essentialSubjects, ...electiveSubjectIds].forEach(subjectId => {
				const subject = erovnulSubjects.find(s => s.id === subjectId);
				if (!subject) throw new Error(`subject ${subjectId} not found`);

				scoresNeeded[subjectId] = userScores[subjectId]
					? scaledScoreToScore(userScores[subjectId], subjectId)
					: subject.minScore!;
			});

			const scaledTotal = calcTotalScaledScores(
				scoresNeeded,
				coefficients
			);

			return {
				type: ResultType.CALCULATED_SCORES,
				scores: scoresNeeded,
				place: 1,
				contestScaledScore: scaledTotal,
			};
		}

		if (recursiveCall) {
			return {
				type: ResultType.CALCULATED_SCORES,
				scores: getMinScores(
					{ ...userRawScores, ...scoresNeeded },
					scoreLimits,
					electiveSubjectIds
				),
				place: -Infinity,
				contestScaledScore: 0,
			};
		}

		let leftBoundary = args.leftBoundary || 1;
		let rightBoundary =
			args.rightBoundary || place || admissionScores.length;
		let lastFoundPlace = -1;
		while (leftBoundary <= rightBoundary) {
			const middlePlace = Math.floor((leftBoundary + rightBoundary) / 2);
			const searchResults = calculateLatorResults({
				...args,
				place: middlePlace,
				leftBoundary,
				recursiveCall: true,
			});
			if (
				searchResults.type ===
				ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE
			) {
				leftBoundary = middlePlace + 1;
			} else if (searchResults.type === ResultType.CALCULATED_SCORES) {
				if (searchResults.place !== -Infinity) {
					lastFoundPlace = middlePlace;
					leftBoundary = middlePlace + 1;
				} else rightBoundary = middlePlace - 1;
			} else return searchResults;
		}

		const returningScores = getMinScores(
			{ ...userRawScores, ...scoresNeeded },
			scoreLimits,
			electiveSubjectIds
		);

		const scaledTotal = calcTotalScaledScores(
			returningScores,
			coefficients
		);
		return {
			type: ResultType.CALCULATED_SCORES,
			scores: returningScores,
			place: Math.max(1, lastFoundPlace),
			contestScaledScore: scaledTotal,
		};
	}
	if (!hasFound) {
		if (typeof place === "undefined" || place === admissionScores.length) {
			return {
				type: ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE,
				requiredScaledScore: admissionScores[0].total,
			};
		}

		if (recursiveCall) {
			return {
				type: ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE,
				requiredScaledScore: Infinity,
			};
		}

		let leftBoundary = args.leftBoundary || place;
		let rightBoundary = args.rightBoundary || admissionScores.length;
		let lastFoundResult: null | IResults = null;
		while (leftBoundary <= rightBoundary) {
			const middlePlace = Math.floor((leftBoundary + rightBoundary) / 2);
			const searchResults = calculateLatorResults({
				...args,
				place: middlePlace,
				rightBoundary,
				recursiveCall: true,
			});
			if (
				searchResults.type ===
				ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE
			) {
				leftBoundary = middlePlace + 1;
			} else if (searchResults.type === ResultType.CALCULATED_SCORES) {
				if (searchResults.place !== -Infinity)
					lastFoundResult = searchResults;
				rightBoundary = middlePlace - 1;
			} else return searchResults;
		}
		if (lastFoundResult === null) {
			return {
				type: ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE,
				requiredScaledScore: admissionScores[0].total,
			};
		}
		return lastFoundResult;
	}

	const scaledTotal = calcTotalScaledScores(scoresNeeded, coefficients);

	return {
		type: ResultType.CALCULATED_SCORES,
		scores: scoresNeeded,
		place: place || admissionScores.length,
		contestScaledScore: scaledTotal,
	};
}

function getMinScores(
	fixedScores: IUserScores,
	scoreLimtis: IUserScores,
	electiveSubjects: number[]
): IUserScores {
	const compulsorySubjects = erovnulSubjects.filter(s => s.isCompulsory);
	const scores: IUserScores = { ...fixedScores };
	compulsorySubjects.forEach(subj => {
		if (typeof fixedScores[subj.id] !== "undefined") return;
		scores[subj.id] = scoreLimtis[subj.id] || subj.minScore!;
	});

	for (const id of electiveSubjects) {
		if (typeof fixedScores[id] !== "undefined") {
			return scores;
		}
	}

	for (const id of electiveSubjects) {
		const fourthSubject = erovnulSubjects.find(s => s.id === id);
		if (!fourthSubject) throw new Error(`subject ${id} not found`);

		scores[id] = scoreLimtis[fourthSubject.id] || fourthSubject.minScore!;
	}
	return scores;
}

interface IACalculateGrant {
	rawScores: IUserScores;
	electiveSubjects: number[];
}

export type grantTypes = 0 | 50 | 70 | 100;

export interface IGrants {
	[subjectId: number]: {
		totalScaledScore: number;
		grant: grantTypes;
	};
}

export interface IGrant {
	totalScaledScore: number;
	grant: grantTypes;
}

const COMPULS_SUBJ_COEFF_FOR_GRANT = 10;
const ELECT_SUBJ_COEFF_FOR_GRANT = 15;

export function calculateGrant(args: IACalculateGrant): IGrants {
	const { rawScores, electiveSubjects } = args;
	if (!electiveSubjects.some(subjId => grantLimitScores[subjId])) {
		throw new Error(
			`grant information does not exist on subjects ${electiveSubjects}`
		);
	}
	const userScores = rawScoresToScaledScores(rawScores);

	const compulsorySubjects = erovnulSubjects.filter(e => e.isCompulsory);

	let compulsorySubjectsScaledScore = 0;
	const grants: IGrants = {};
	compulsorySubjects.forEach(subj => {
		if (userScores[subj.id] === undefined) {
			throw new Error(
				`score is not provided for compulsory subject ${subj.id}`
			);
		}
		compulsorySubjectsScaledScore +=
			userScores[subj.id] * COMPULS_SUBJ_COEFF_FOR_GRANT;
	});

	if (electiveSubjects.some(subjId => userScores[subjId] === undefined)) {
		throw new Error(
			`score is not provided for fourth subject ${electiveSubjects}`
		);
	}

	for (const subjId of electiveSubjects) {
		const electiveSubjectScaledScore =
			userScores[subjId] * ELECT_SUBJ_COEFF_FOR_GRANT;
		const totalScaledScore =
			compulsorySubjectsScaledScore + electiveSubjectScaledScore;
		let grant: grantTypes = 0;
		if (totalScaledScore >= grantLimitScores[subjId][0]) grant = 100;
		else if (totalScaledScore >= grantLimitScores[subjId][1])
			grant = 70;
		else if (
			grantLimitScores[subjId][2] > 0 &&
			totalScaledScore >= grantLimitScores[subjId][2]
		)
			grant = 50;
		grants[subjId] = {
			totalScaledScore,
			grant,
		};
	}

	return grants;
}
