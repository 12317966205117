import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import memoizeOne from "memoize-one";
import { addProgramAction, removeProgramAction } from "@app/actions/lator";
import { ChooseProgramAndUni } from "./choose-programs";
import { connect } from "react-redux";
import { CUUniId } from "./consts";
import { getUnisAndPrograms } from "@app/actions/unis";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { shortenName } from "@app/commonJavascript";
import Tooltip from "@material-ui/core/Tooltip";
import { UniLogo } from "../Universities/FreeUniversityPage";
import { inject } from "@app/modules";
import { IRProgram } from "@app/api/programs/helper-schemas";

interface IOwnProps {
	onChange: (programId: number | null) => void;
	value: number | null;
	defaultProgramIds?: number[];
	disallowAdding?: boolean;
	title?: string;
	listenToReduxState?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

type ISelectedValue = {
	value: number;
	label: string;
} | null;

interface IState {
	displayedProgramIds: number[];
	isAddingNewProgram: boolean;
	selectedUni: ISelectedValue;
}

class ProgramPicker extends React.PureComponent<IProps, IState> {
	state: IState = {
		displayedProgramIds:
			this.props.defaultProgramIds || this.props.programIds || [],
		isAddingNewProgram: false,
		selectedUni: null,
	};

	getUniOptions = memoizeOne((unis: IRootState["unis"]["info"]) => {
		if (!unis) return [];
		return unis.map(uni => ({
			value: uni.id,
			label: uni.code + " - " + uni.officialName,
		}));
	});

	onProgramsChange = memoizeOne((programIds: number[] | undefined) => {
		if (programIds && this.props.listenToReduxState) {
			setTimeout(() => {
				this.setState({ displayedProgramIds: programIds });
			}, 0);
		}
	});

	componentDidMount() {
		if (!this.props.unis) {
			this.props.getUnisAndPrograms();
		}
	}

	onProgramRemove = (programId: number) => {
		if (this.state.displayedProgramIds.length === 1) {
			this.setState({
				isAddingNewProgram: true,
			});
		}
		this.setState({
			displayedProgramIds: this.state.displayedProgramIds.filter(
				e => e !== programId
			),
		});
		if (programId === this.props.value) {
			// selected Program is being deleted
			if (this.state.displayedProgramIds.length === 1) {
				this.props.onChange(null);
			} else {
				this.props.onChange(
					this.state.displayedProgramIds.find(e => e !== programId)!
				);
			}
		}
		this.props.removeProgramAction(programId);
	};

	onProgramAdd = (programId: number) => {
		if (this.state.displayedProgramIds.indexOf(programId) > -1) {
			this.setState({
				isAddingNewProgram: false,
			});
			return;
		}
		this.setState({
			displayedProgramIds: [...this.state.displayedProgramIds, programId],
			isAddingNewProgram: false,
		});
		this.props.onChange(programId);
		this.props.addProgramAction(programId);
	};

	showAddProgram = () => {
		this.setState({
			isAddingNewProgram: true,
		});
	};

	onUniSelect = (index: number, selectedValue: ISelectedValue) => {
		this.setState({
			selectedUni: selectedValue,
		});
	};

	onProgramSelect = (index: number, selectedValue: ISelectedValue) => {
		if (!selectedValue) return;
		this.onProgramAdd(selectedValue.value);
	};

	getProgram = memoizeOne((programId: number, programs?: IRProgram[]) => {
		return !programs ? undefined : programs.find(p => p.id === programId);
	});

	// LatoriProgramModel = inject("LatoriProgramModel");

	render() {
		const { unis, programs } = this.props;
		if (!unis || !programs) return null;
		this.onProgramsChange(this.props.programIds);

		const uniOptions = this.getUniOptions(unis);
		const currentProgram = !this.props.value
			? undefined
			: this.getProgram(this.props.value, programs);

		const currentProgramUniId =
			currentProgram === undefined ? null : currentProgram.uni_id;
		const currentUni =
			currentProgramUniId &&
			this.props.unis &&
			this.props.unis[currentProgramUniId];
		let i = 0;
		return (
			<div className="subject_programs_picker">
				<div className="title">{this.props.title || "ფაკულტეტები"}</div>
				<div>
					{this.state.displayedProgramIds.map(programId => {
						const program = this.getProgram(programId, programs);
						const catalogProgram = programs.find(
							e => e.id === programId
						);
						if (!program || !catalogProgram) return null;
						const uni = unis.find(e => e.id === program.uni_id);
						if (!uni) return null;
						i++;
						return (
							<div
								key={programId}
								className={
									"itemContainer" +
									(this.props.value === programId
										? " selected "
										: "") +
									(this.props.value === programId &&
									currentProgramUniId === CUUniId
										? " cuStyle"
										: "")
								}
								onClick={() => this.props.onChange(programId)}
							>
								<div
									className={
										"item" +
										(currentProgramUniId === CUUniId
											? " cuStyleItem"
											: "")
									}
								>
									<div className="name">
										{i}.
										<Tooltip
											title={
												<span style={{ fontSize: 18 }}>
													{uni.name}
												</span>
											}
											placement="bottom"
										>
											<UniLogo
												logoUrl={uni.logoUrl}
												logoBGColor={uni.logoBGColor}
												className="uniLogo"
											/>
										</Tooltip>
										{shortenName(catalogProgram.name, 600)}
									</div>
									<div
										className="closeIcon"
										onClick={e => {
											e.stopPropagation();
											this.onProgramRemove(programId);
										}}
									>
										<CloseIcon />
									</div>
								</div>
							</div>
						);
					})}
				</div>
				{!this.props.disallowAdding && (
					<div
						className={
							"add" +
							(currentProgramUniId === CUUniId ? " cuStyle" : "")
						}
					>
						{!this.state.isAddingNewProgram && (
							<AddIcon onClick={this.showAddProgram} />
						)}
						{this.state.isAddingNewProgram && (
							<ChooseProgramAndUni
								index={0}
								uniOptions={uniOptions}
								selectedUni={this.state.selectedUni}
								selectedProgram={null}
								onUniChange={this.onUniSelect}
								onProgramChange={this.onProgramSelect}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
	programIds: state.lator.programIds,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
	addProgramAction,
	removeProgramAction,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(ProgramPicker);
