import React from "react";
import { IAnyObj } from "@app/utils/generics";
import {
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	TopStickyTableHeadCell,
} from "./table";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import classNames from "classnames";

type Label = string | JSX.Element | number | null | undefined;

export interface HeaderInfo {
	label: Label;
	value: string;
	isRequired?: boolean;
}

interface ICellProps<RowInfo extends {}> {
	colKey: keyof RowInfo;
	row: RowInfo;
	isCheked?: boolean;
}

type IProps<RowInfo extends {}, IdKey extends keyof RowInfo> = {
	rows: RowInfo[];
	cellRenderComponents?: {
		[key in keyof RowInfo]?:
			| React.ComponentType<ICellProps<RowInfo>>
			| {
					component: React.ComponentType<
						ICellProps<RowInfo> & IAnyObj
					>;
					props: IAnyObj;
			  };
	};
	headerValues: HeaderInfo[];
	showButtonsOnHover?: boolean;
	onHeaderClick?: (headerValue: string) => void;
	rowButtons?: (id: RowInfo[IdKey], row: RowInfo) => JSX.Element;
	rowClassName?: string | ((row: RowInfo) => string);
	idKey: IdKey;
	doNotCheckOnRowClick?: boolean;
};

export const GeneralTable = <RowInfo extends {}, IdKey extends keyof RowInfo>(
	props: IProps<RowInfo, IdKey>
) => {
	const {
		onHeaderClick,
		rowButtons,
		rows,
		headerValues,
		showButtonsOnHover,

		cellRenderComponents,
		rowClassName,
		idKey,
	} = props;

	return (
		<Table style={{ width: "100%" }}>
			<GeneralTableHead
				hasRowButtons={!!rowButtons}
				onHeaderClick={onHeaderClick}
				headerValues={headerValues}
			/>
			<TableBody>
				{rows.map((row, i) => (
					<TableRowData
						row={row}
						key={row[idKey] as any}
						className={rowClassName}
						rowButtons={rowButtons}
						cellRenderComponents={cellRenderComponents}
						showButtonsOnHover={showButtonsOnHover}
						idKey={i}
						headerValues={headerValues}
					/>
				))}
			</TableBody>
		</Table>
	);
};

type GeneralTableHeadComponent = <RowInfo extends {}>(props: {
	headerValues: HeaderInfo[];
	beforeThisHeader?: string;
	hasRowButtons: boolean;
	onHeaderClick?: (headerValue: string) => void;
}) => JSX.Element | null;

const GeneralTableHead: GeneralTableHeadComponent = React.memo(
	({ hasRowButtons, onHeaderClick, headerValues }) => {
		return (
			<TableHead>
				<TableRow>
					{headerValues.map(cur => {
						return (
							<React.Fragment key={cur.value}>
								<TopStickyTableHeadCell
									onClick={() =>
										onHeaderClick &&
										onHeaderClick(cur.value)
									}
								>
									{cur.label}
									{onHeaderClick && <ArrowDropDownIcon />}
								</TopStickyTableHeadCell>
							</React.Fragment>
						);
					})}
				</TableRow>
			</TableHead>
		);
	}
);

type TableRowDataComponent = <
	RowInfo extends {},
	IdKey extends keyof RowInfo
>(props: {
	row: RowInfo;
	doNotCheckOnRowClick?: boolean;
	headerValues: HeaderInfo[];
	rowButtons?: (id: RowInfo[IdKey], row: RowInfo) => JSX.Element;
	cellRenderComponents: IProps<RowInfo, IdKey>["cellRenderComponents"];
	showButtonsOnHover?: boolean;
	className?: string | ((row: RowInfo) => string);
	idKey: number;
}) => JSX.Element | null;

const TableRowData: TableRowDataComponent = React.memo(
	({
		row,
		headerValues,
		rowButtons,
		cellRenderComponents,
		showButtonsOnHover,
		className,
		idKey,
	}) => {
		const rowClassname =
			!className || typeof className === "string"
				? className
				: className(row);

		return (
			<TableRow key={idKey}>
				{headerValues.map(header => {
					let cellDatum: any = row[header.value];
					const Component =
						cellRenderComponents &&
						cellRenderComponents[header.value];
					if (Component) {
						if (typeof Component === "function") {
							cellDatum = (
								<Component colKey={header.value} row={row} />
							);
						} else {
							cellDatum = (
								<Component.component
									colKey={header.value}
									row={row}
									{...Component.props}
								/>
							);
						}
					}
					return (
						<React.Fragment key={header.value}>
							<TableCell>
								{Array.isArray(cellDatum)
									? cellDatum.join(", ")
									: cellDatum}
							</TableCell>
						</React.Fragment>
					);
				})}
				{rowButtons && (
					<TableCell>{rowButtons(row[idKey], row)}</TableCell>
				)}
			</TableRow>
		);
	}
);
