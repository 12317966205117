import Joi, { SchemaToType } from "@app/utils/joi";
import { GalleryMediaSchema, IGalleryMedia } from "../helpher-schemas";

export const uniMainInfoSchema = Joi.object().keys({
	address: Joi.string()
		.allow("")
		.required(),
	phone: Joi.string()
		.allow("")
		.required(),
	web: Joi.string()
		.allow("")
		.required(),
	email: Joi.string()
		.allow("")
		.required(),
});

export type IUniMainInfo = SchemaToType<typeof uniMainInfoSchema>;

const MainInfoFields: Array<keyof IUniMainInfo> = [
	"address",
	"phone",
	"web",
	"email",
];
export { MainInfoFields };

///

const expoRoomColors = ["Blue", "Green", "LightBlue", "Pink", "Red", "Yellow"];
export type IExpoRoomColor =
	| "Blue"
	| "Green"
	| "LightBlue"
	| "Pink"
	| "Red"
	| "Yellow";

export const RUniversitySchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	code: Joi.alternatives([
		Joi.string(),
		Joi.array().items(Joi.string()),
	]).required(),
	name: Joi.string()
		.max(255)
		.required(),
	officialName: Joi.string()
		.max(400)
		.required(),
	urlName: Joi.string()
		.max(160)
		.required(),
	alias: Joi.array()
		.items(Joi.string())
		.allow(null)
		.required(),
	shortDescription: Joi.string()
		.allow(null)
		.required(),
	facebookUrl: Joi.string()
		.allow(null)
		.required(),
	cities: Joi.array()
		.items(Joi.number().integer())
		.allow(null)
		.required(),
	sector: Joi.number()
		.integer()
		.required(),
	fields: Joi.array()
		.items(Joi.number().integer())
		.allow(null)
		.required(),
	logoUrl: Joi.string()
		.max(60)
		.required(),
	expoMainBannerUrl: Joi.string()
		.max(60)
		.required()
		.allow(null),
	expoSecondaryBannerUrl: Joi.string()
		.max(60)
		.required()
		.allow(null),
	logoBGColor: Joi.string()
		.max(60)
		.allow(null)
		.required(),
	expoRoomColor: Joi.string()
		.valid(...expoRoomColors)
		.allow(null)
		.required(),
	coverUrl: Joi.string()
		.max(50)
		.allow(null)
		.required(),
	coverBGColor: Joi.string()
		.max(50)
		.allow(null)
		.required(),
	yearOfUpdatedInfo: Joi.number()
		.integer()
		.required(),
	premium: Joi.number()
		.integer()
		.required(),
	pagePositionCoefficient: Joi.number().required(),
	mainCoefficient: Joi.number().required(),
	locale: Joi.string()
		.valid("ka", "en")
		.required(),
});
export type IRUniversity = SchemaToType<typeof RUniversitySchema>;

// tslint:disable-next-line:max-line-length
export const RUniversityFields: Array<keyof IRUniversity> = [
	"id",
	"code",
	"name",
	"officialName",
	"urlName",
	"alias",
	"shortDescription",
	"facebookUrl",
	"cities",
	"sector",
	"fields",
	"logoUrl",
	"expoMainBannerUrl",
	"expoSecondaryBannerUrl",
	"logoBGColor",
	"expoRoomColor",
	"coverUrl",
	"coverBGColor",
	"yearOfUpdatedInfo",
	"premium",
	"pagePositionCoefficient",
	"mainCoefficient",
	"locale",
];

export const UniContentSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	title: Joi.string()
		.allow("", null)
		.required(),
	position: Joi.number()
		.integer()
		.required(),
	text: Joi.string()
		.allow("", null)
		.required(),
	galleryMedias: Joi.array()
		.items(GalleryMediaSchema)
		.allow(null)
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});
export type IUniContent = SchemaToType<typeof UniContentSchema>;
// tslint:disable-next-line:max-line-length
export const UniContentFields: Array<keyof IUniContent> = [
	"id",
	"uni_id",
	"title",
	"position",
	"text",
	"galleryMedias",
	"created_at",
	"updated_at",
];

/* SMS senders */
export const RSenderSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	title: Joi.string().required(),
});

export type IRSender = SchemaToType<typeof RSenderSchema>;

export const ISenderSchema = RSenderSchema.keys({
	active: Joi.boolean().required(),
	author: Joi.number().required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export type ISender = SchemaToType<typeof ISenderSchema>;

///

export enum SocialMediaTypes {
	Facebook = "Facebook",
	YouTube = "YouTube",
}
export const socialMedias = [
	SocialMediaTypes.Facebook,
	SocialMediaTypes.YouTube,
];

export const LiveMeetingSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	title: Joi.string().required(),
	videoURL: Joi.string().required(),
	zoomURL: Joi.string()
		.required()
		.allow(null),
	social_media: Joi.string()
		.valid(...socialMedias)
		.required(),
	start_time: Joi.date().required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export const LiveMeetingWithMetadataSchema = LiveMeetingSchema.keys({
	status: Joi.string()
		.valid("live", "none", "upcoming")
		.required(),
});

export interface ILiveMeeting {
	id: number;
	uni_id: number;
	title: string;
	videoURL: string;
	zoomURL: string | null;
	social_media: SocialMediaTypes;
	start_time: Date;
	created_at: Date;
	updated_at: Date;
}

export interface ILiveMeetingMetadata {
	status: "live" | "none" | "upcoming";
}

export type ILiveMeetingWithMetadata = ILiveMeeting & ILiveMeetingMetadata;

///

export const UniExpoVideoSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	videoURL: Joi.string().required(),
	social_media: Joi.string()
		.valid(...socialMedias)
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export interface IUniExpoVideo {
	id: number;
	uni_id: number;
	videoURL: string;
	social_media: SocialMediaTypes;
	created_at: Date;
	updated_at: Date;
}

export const UniOfferSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	title: Joi.string().optional(),
	type: Joi.string()
		.valid("PHOTO", "VIDEO", "PDF")
		.required(),
	url: Joi.string().required(),
});

export interface IUniOffer {
	uni_id: number;
	title?: string;
	type: IGalleryMedia["type"];
	url: string;
}
