import Joi, { SchemaToType } from "@app/utils/joi";

///

export const RGETFavoritesSchema = Joi.object().pattern(
	/^[0-9]*$/,
	Joi.object().keys({
		program_id: Joi.number()
			.integer()
			.required(),
		uni_id: Joi.number()
			.integer()
			.required(),
		time: Joi.date().required(),
	})
);

export type IRGETFavorites = SchemaToType<typeof RGETFavoritesSchema>;
export type IFavPrograms = IRGETFavorites;

///

export const APUTInsertOrUpdateFavoriteSchema = Joi.object().keys({
	uni_id: Joi.number().required(),
	program_id: Joi.number().required(),
});
export type IAPUTInsertOrUpdateFavorite = SchemaToType<
	typeof APUTInsertOrUpdateFavoriteSchema
>;

export const RPUTInsertOrUpdateFavoriteSchema = Joi.object().keys({
	success: Joi.boolean()
		.required()
		.required(),
});
export type IRPUTInsertOrUpdateFavorite = SchemaToType<
	typeof RPUTInsertOrUpdateFavoriteSchema
>;

///

export const APOSTUserAdmissionProgramSchema = Joi.object().keys({
	program_id: Joi.number()
		.integer()
		.required(),
});
export type IAPOSTUserAdmissionProgram = SchemaToType<
	typeof APOSTUserAdmissionProgramSchema
>;

///

export const APOSTUserAdmissionProgramsSchema = Joi.object().keys({
	programs: Joi.array()
		.items(Joi.number().integer())
		.required(),
});
export type IAPOSTUserAdmissionPrograms = SchemaToType<
	typeof APOSTUserAdmissionProgramsSchema
>;

///

export const ADELETEUserAdmissionProgramSchema = Joi.object().keys({
	program_id: Joi.number()
		.integer()
		.required(),
});
export type IADELETEUserAdmissionProgram = SchemaToType<
	typeof ADELETEUserAdmissionProgramSchema
>;
