import api from "@app/api";
import { IRSender } from "@app/api/sms/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import { canSendUniSMS } from "@app/permissions";
import { IRootState } from "@app/reducers/root";
import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import "../styles/adminPrograms.min.css";
import LoadingSVG from "@app/components/styles/img/LoadingWhite";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

interface IState {
	text: string;
	numbers: string[];
	mode: "edit" | "review" | "sent";
	correctNumbers: number;
	loading: boolean;
	sender?: IRSender;
	messageCount: number;
	containsNonLathinSymbols: boolean;
}

export function isCorrectNumber(num: string): boolean {
	if (num.length !== 9) return false;
	if (num < "514000000" || num > "599999999") return false;
	return true;
}

function checkIfContainsNonLathinSymbols(str: string): boolean {
	for (let i = 0; i < str.length; ++i) {
		if (str.charCodeAt(i) > 126) return true;
	}
	return false;
}

function countMessage(n: number, containsNonLathinSymbols: boolean): number {
	if (n === 0) return 0;
	if (containsNonLathinSymbols) {
		if (n <= 70) return 1;
		if (n <= 134) return 2;
		return 3 + Math.floor((n - 135) / 67);
	}
	if (n <= 160) return 1;
	if (n <= 306) return 2;
	return 3 + Math.floor((n - 307) / 153);
}

function countMessageStrLength(str: string): number {
	if (str.indexOf("\n") === -1) return str.length;
	return str.length + (str.match(/\n/g) || []).length;
}

class SendSMSPage extends React.Component<IProps, IState> {
	uni_id: number = +this.props.match.params.uni_id;
	_isMounted: boolean;

	state: IState = {
		text: "",
		numbers: [],
		mode: "edit",
		correctNumbers: 0,
		loading: false,
		messageCount: 0,
		containsNonLathinSymbols: false,
	};

	constructor(props: IProps) {
		super(props);
		const { uni_id } = props.match.params;
		if (!uni_id) {
			return;
		}
		this.uni_id = +uni_id;
		if (!canSendUniSMS(this.uni_id, props.userData.permissions)) {
			this.props.history.replace("/admin/uni/" + this.uni_id);
			return;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		Promise.all([
			api.sms.getSender({
				uni_id: this.uni_id,
			}),
			api.users.getAllStudentsPhoneNumbers(),
		]).then(([senderData, phoneData]) => {
			this.setState({
				numbers: phoneData.map(el => el.phone + ""),
				sender: senderData.sender,
			});
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const containsNonLathinSymbols = checkIfContainsNonLathinSymbols(
			e.target.value
		);
		this.setState({
			text: e.target.value,
			containsNonLathinSymbols,
			messageCount: countMessage(
				countMessageStrLength(e.target.value),
				containsNonLathinSymbols
			),
		});
	};

	/*onNumbersChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({
			numbers: e.target.value,
		});
	};*/

	toReviewMode = () => {
		if (this.state.text.indexOf("NO 91146") === -1) {
			alert("დარწმუნდით, რომ ტექსტში გიწერიათ NO 91146");
			return;
		}
		this.setState({
			mode: "review",
			correctNumbers: new Set(this.state.numbers.filter(isCorrectNumber))
				.size,
		});
	};

	toEditMode = () => {
		this.setState({
			mode: "edit",
		});
	};

	send = () => {
		if (!this.state.sender) {
			alert("გამგზავნის სახელი არაა მითითებული");
			return;
		}
		const removeLoader = addLoader();
		this.setState({ loading: true });
		api.sms
			.send({
				numbers: [
					...new Set(this.state.numbers.filter(isCorrectNumber)),
				],
				text: this.state.text,
				uni_id: this.uni_id,
				sender_id: this.state.sender.id,
			})
			.then(data => {
				removeLoader();
				if (!this._isMounted) return;
				this.props.history.push(
					`/admin/uni/${this.uni_id}/sms_group/${data.smsGroupId}`
				);
			})
			.catch(err => {
				removeLoader();
				if (!this._isMounted) return;
				if (err && err.response && err.response.status === 413) {
					alert(
						"ნომრების რაოდენობა ძალიან ბევრია. სცადეთ რამდენიმე ნაწილად დაყოფა"
					);
				}
			});
	};

	render() {
		const buttonStyles = {
			width: 180,
			height: 50,
			fontSize: 20,
			marginRight: 20,
		};
		if (!this.state.sender)
			return (
				<div style={{ textAlign: "center" }}>
					<LoadingSVG width={100} height={100} />
				</div>
			);
		return (
			<div style={{ textAlign: "center" }}>
				<div className="main">
					{this.state.mode === "edit" && (
						<div>
							{!!this.state.sender &&
								"გაიგზავნება სახელით: " +
									this.state.sender.title}
							<textarea
								className="dark_textarea"
								placeholder="ტექსტი (უმჯობესია ლათინური ასოებით)"
								value={this.state.text}
								onChange={this.onTextChange}
							/>
							ტექსტი შეიცავს{" "}
							{countMessageStrLength(this.state.text)} ასონიშანს.
							დაიხარჯება {this.state.messageCount} მესიჯი
							{this.state.containsNonLathinSymbols && (
								<>
									<br />
									ტექსტი შეიცავს არალათინურ ასონიშნებს.
									შესაძლოა ხარჯის ზრდა.
								</>
							)}
							{this.state.text.indexOf("NO 91146") === -1 && (
								<div>ტექსტი არ შეიცავს NO 91146-ს!</div>
							)}
							<br />
							<button
								className="adminPrimaryButton"
								onClick={this.toReviewMode}
								style={buttonStyles}
							>
								შემოწმება
							</button>
						</div>
					)}
					{this.state.mode === "review" && (
						<div className="sms-info-container">
							ტექსტი:{" "}
							<div className="sms-text-container">
								{this.state.text}
							</div>
							<br />
							<br />
							მიმღები ნომრების რაოდენობა:{" "}
							{this.state.correctNumbers}
							<br />
							<button
								className="adminPrimaryButton"
								onClick={this.toEditMode}
								style={buttonStyles}
							>
								რედაქტირება
							</button>
							{!!this.state.correctNumbers && (
								<button
									className="adminPrimaryButton"
									onClick={this.send}
									style={buttonStyles}
								>
									გაგზავნა
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

export default connect(mapStateToProps)(SendSMSPage);
