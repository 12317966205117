import Joi from "@app/utils/joi";
import validateSchema from "@app/utils/validate-schema";
import {
	ADD_EVENT,
	ADD_NEWS,
	CHANGE_EVENT_SETTINGS,
	CHANGE_NEWS_SETTINGS,
	DISPLAY_UNI_SUBSCRIPTION_POPUP,
	LOAD_DASHBOARD_STATISTICS,
	LOAD_EVENTS,
	LOAD_EVENTS_FOR_UNI,
	LOAD_NEWS,
	LOAD_NEWS_FOR_UNI,
	LOAD_TEMP_INFO,
	LOAD_UNI_DISPLAY_INFO,
	LOAD_UNI_SHORT_STATS,
	LOAD_USER_RESERVED_EVENTS,
	REMOVE_EVENT_BY_ID,
	REMOVE_UNI_DISPLAY_INFO,
	RESERVE_EVENT,
	UNRESERVE_EVENT,
	USER_LOG_OUT,
	VIEW_PROGRAM_PAGE,
	VIEW_UNI_PAGE,
} from "../action-types";
import { EventsSchema, IEvent } from "@app/api/events/helpher-schemas";
import { INews, RGETNewsByUniIdSchema } from "@app/api/news/validators";
import { IRGETRecentStats } from "@app/api/unis/stats/validators";
import { IUniContent, IUniMainInfo } from "@app/api/unis/helper-schemas";
import { TempActions } from "@app/actions/temp";

function getNewTempState(
	state: IStateTemp = {},
	action: TempActions
): IStateTemp {
	if (typeof action.type === "undefined") return state;
	const unis = state.unis || {};
	const viewedUnis = state.viewedUnis || {};
	const viewedPrograms = state.viewedPrograms || {};
	switch (action.type) {
		case LOAD_TEMP_INFO:
			return {
				...state,
				...action.temp,
				news_by_unis: !action.temp.news_by_unis
					? undefined
					: validateSchema(
							action.temp.news_by_unis,
							NewsByUnisSchema
					  ),
				events_by_unis: !action.temp.events_by_unis
					? undefined
					: validateSchema(
							action.temp.events_by_unis,
							EventsByUnisSchema
					  ),
			};

		case LOAD_UNI_DISPLAY_INFO:
			return {
				...state,
				unis: {
					...unis,
					[action.uni_id]: {
						uni_id: action.uni_id,
						contents: action.contents,
						mainInfo: action.mainInfo,
						loadTime: action.loadTime,
					},
				},
			};
		case VIEW_UNI_PAGE:
			return {
				...state,
				viewedUnis: {
					...viewedUnis,
					[action.uni_id]: {
						uni_id: action.uni_id,
						date: action.date,
					},
				},
			};
		case VIEW_PROGRAM_PAGE:
			return {
				...state,
				viewedPrograms: {
					...viewedPrograms,
					[action.program_id]: {
						program_id: action.program_id,
						uni_id: action.uni_id,
						date: action.date,
					},
				},
			};

		case REMOVE_UNI_DISPLAY_INFO:
			return { ...state, unis: { ...unis, [action.uni_id]: undefined } };

		case LOAD_DASHBOARD_STATISTICS:
			return {
				...state,
				dashboardStatistics: {
					...(state.dashboardStatistics || {}),
					[action.uni_id]: action.data,
				},
			};
		case LOAD_EVENTS:
			return {
				...state,
				user_events: action.user_events,
				userEventsLoadTime: new Date().getTime(),
			};
		case LOAD_EVENTS_FOR_UNI:
			if (!state.events_by_unis) {
				return {
					...state,
					events_by_unis: { [action.uni_id]: action.events },
				};
			}
			if (!state.events_by_unis[action.uni_id]) {
				return {
					...state,
					events_by_unis: {
						...state.events_by_unis,
						[action.uni_id]: action.events,
					},
				};
			}
			return {
				...state,
				events_by_unis: {
					...state.events_by_unis,
					[action.uni_id]: [
						...state.events_by_unis[action.uni_id],
						action.events,
					],
				},
			};
		case REMOVE_EVENT_BY_ID:
			if (!state.events_by_unis) return state;
			return {
				...state,
				events_by_unis: {
					...state.events_by_unis,
					[action.uni_id]: state.events_by_unis[action.uni_id].filter(
						event => event.id !== action.id
					),
				},
			};
		case LOAD_USER_RESERVED_EVENTS:
			return {
				...state,
				user_reserved_events: action.user_reserved_events,
			};
		case RESERVE_EVENT:
			return {
				...state,
				user_reserved_events: [
					...(state.user_reserved_events || []),
					action.event_id,
				],
			};
		case UNRESERVE_EVENT:
			if (!state.user_reserved_events) return state;
			return {
				...state,
				user_reserved_events: state.user_reserved_events.filter(
					id => id !== action.event_id
				),
			};
		case ADD_EVENT:
			if (!state.events_by_unis) {
				return {
					...state,
					events_by_unis: { [action.uni_id]: [action.event] },
				};
			}
			if (!state.events_by_unis[action.uni_id]) {
				return {
					...state,
					events_by_unis: {
						...state.events_by_unis,
						[action.uni_id]: [action.event],
					},
				};
			}
			return {
				...state,
				events_by_unis: {
					...state.events_by_unis,
					[action.uni_id]: [
						...state.events_by_unis[action.uni_id],
						action.event,
					],
				},
			};
		case CHANGE_EVENT_SETTINGS:
			if (!state.events_by_unis || !state.events_by_unis[action.uni_id])
				return state;
			return {
				...state,
				events_by_unis: {
					...state.events_by_unis,
					[action.uni_id]: state.events_by_unis[
						action.uni_id
					].map(event =>
						event.id === action.event_id
							? { ...event, ...action.info }
							: event
					),
				},
			};
		case CHANGE_NEWS_SETTINGS:
			if (!state.news_by_unis || !state.news_by_unis[action.uni_id])
				return state;
			return {
				...state,
				news_by_unis: {
					...state.news_by_unis,
					[action.uni_id]: state.news_by_unis[
						action.uni_id
					].map(news =>
						news.id === action.news_id
							? { ...news, ...action.info }
							: news
					),
				},
			};
		case ADD_NEWS:
			if (!state.news_by_unis) {
				return {
					...state,
					news_by_unis: { [action.news.uni_id]: [action.news] },
				};
			}
			if (!state.news_by_unis[action.news.uni_id]) {
				return {
					...state,
					news_by_unis: {
						...state.news_by_unis,
						[action.news.uni_id]: [action.news],
					},
				};
			}
			return {
				...state,
				news_by_unis: {
					...state.news_by_unis,
					[action.news.uni_id]: [
						...state.news_by_unis[action.news.uni_id],
						action.news,
					],
				},
			};
		case LOAD_NEWS:
			return {
				...state,
				user_news: action.user_news,
				userNewsLoadTime: new Date().getTime(),
			};
		case LOAD_NEWS_FOR_UNI:
			if (!state.news_by_unis) {
				return {
					...state,
					news_by_unis: { [action.uni_id]: action.news },
				};
			}
			if (!state.news_by_unis[action.uni_id]) {
				return {
					...state,
					news_by_unis: {
						...state.news_by_unis,
						[action.uni_id]: action.news,
					},
				};
			}
			return {
				...state,
				news_by_unis: {
					...state.news_by_unis,
					[action.uni_id]: [
						...state.news_by_unis[action.uni_id],
						action.news,
					],
				},
			};
		case LOAD_UNI_SHORT_STATS:
			return {
				...state,
				uniShortStats: {
					...(state.uniShortStats || {}),
					[action.uni_id]: {
						views_verified: action.views_verified,
						views_nonverified: action.views_nonverified,
						latori_verified: action.latori_verified,
						latori_nonverified: action.latori_nonverified,
					},
				},
			};
		case DISPLAY_UNI_SUBSCRIPTION_POPUP:
			return {
				...state,
				subscriptionPopupUniId: action.uni_id,
			};

		case USER_LOG_OUT:
			return {
				...state,
				user_reserved_events: undefined,
				userNewsLoadTime: undefined,
				user_news: undefined,
				user_events: undefined,
				userEventsLoadTime: undefined,
			};
		default:
			return state;
	}
}

export default function temp(state = {}, action: TempActions): IStateTemp {
	const newState = getNewTempState(state, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		sessionStorage.setItem("temp", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export interface IStateTemp {
	unis?: {
		[uni_id: string]: {
			uni_id: number;
			contents?: IUniContent[];
			mainInfo?: IUniMainInfo;
			loadTime: Date;
		};
	};
	viewedUnis?: {
		[uni_id: string]: {
			uni_id: number;
			date: Date;
		};
	};
	viewedPrograms?: {
		[program_id: string]: {
			program_id: number;
			uni_id: number;
			date: Date;
		};
	};
	dashboardStatistics?: {
		[uni_id: string]: IRGETRecentStats;
	};
	events_by_unis?: { [uni_id: string]: IEvent[] };
	user_events?: IEvent[];
	userEventsLoadTime?: number;
	user_reserved_events?: number[];
	news_by_unis?: { [uni_id: string]: INews[] };
	user_news?: INews[];
	userNewsLoadTime?: number;
	uniShortStats?: {
		[uni_id: string]: {
			views_verified: number;
			views_nonverified: number;
			latori_verified: number;
			latori_nonverified: number;
		};
	};
	subscriptionPopupUniId?: number;
}

const EventsByUnisSchema = Joi.object().pattern(
	/[\da-z-]+/,
	Joi.array().items(EventsSchema)
);
const NewsByUnisSchema = Joi.object().pattern(
	/[\da-z-]+/,
	Joi.array().items(RGETNewsByUniIdSchema)
);
