import { useStoreProperty } from "./store";
import { store } from "index";

export const useLocale = () => {
	return useStoreProperty("intl")!.locale;
};

export const getLocale = () => {
	return store.getState()["intl"].locale;
};
