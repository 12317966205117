import { uniDisplayInfo } from "@app/actions/temp";
import { uniChangeSettings } from "@app/actions/unis";
import api from "@app/api";
import {
	IExpoRoomColor,
	IRUniversity,
	IUniMainInfo,
} from "@app/api/unis/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { addLoader, cities } from "@app/commonJavascript";
import { canEditUniSettings } from "@app/permissions";
import { IRootState } from "@app/reducers/root";
import { History } from "history";
import * as React from "react";
import ContentLoader from "react-content-loader";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { match } from "react-router";
import FancyInput from "../../Widgets/FancyInput";
import FancyMultipleSelect from "../../Widgets/FancyMultipleSelect";
import Switch from "../../Widgets/MainSwitch";
import SnackNotification from "../../Widgets/SnackNotification";
import BannerUpload from "./BannerUpload";
import "./styles/uniEditPage.min.css";
import UniBackgroundType, { ColorCard } from "./UniBackgroundType";
import UniLogoPicker from "./UniLogoPicker";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	uniChangeSettings: typeof uniChangeSettings;
	uniDisplayInfo: typeof uniDisplayInfo;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	uni?: IRUniversity;
	mainInfo?: IUniMainInfo;
	errorDialogOpen: boolean;
	errorMessage: string;
	selectedExpoRoomColor: IExpoRoomColor | null;
	selectedLocale: "ka" | "en";
}

export type IUniChangedInfo = {
	[key in keyof IRUniversity]?: IRUniversity[key];
} & {
	mainInfo?: Partial<IUniMainInfo>;
};

const expoColors: { color: string; name: IExpoRoomColor }[] = [
	{ color: "#6572C7", name: "Blue" },
	{ color: "#5CC76E", name: "Green" },
	{ color: "#7DB7FE", name: "LightBlue" },
	{ color: "#F2439A", name: "Pink" },
	{ color: "#FF3846", name: "Red" },
	{ color: "#FFC33D", name: "Yellow" },
];

class AdminUniEditPage extends React.Component<IProps, IState> {
	static loaders() {
		return (
			<div style={{ textAlign: "center" }}>
				{Array(8)
					.fill(0)
					.map((e, index) => (
						<div className="fancyInputAndTitle" key={index}>
							<ContentLoader
								width={400}
								height={115}
								primaryColor="#fff"
								primaryOpacity={0.7}
								secondaryColor="#87878e"
								secondaryOpacity={1}
							>
								<rect
									y="16"
									x="25"
									width="270"
									height="23"
									rx="4"
									ry="4"
								/>
								<rect
									y="55"
									width="400"
									height="60"
									rx="27.5"
									ry="27.5"
								/>
							</ContentLoader>
						</div>
					))}
			</div>
		);
	}

	uni_id: number;
	_isMounted: boolean;
	uniNameRef: React.RefObject<HTMLInputElement> = React.createRef();
	uniChangedInfo: IUniChangedInfo = {};

	state: IState = {
		errorDialogOpen: false,
		errorMessage: "",
		selectedExpoRoomColor: null,
		selectedLocale: "ka",
	};

	constructor(props: IProps) {
		super(props);
		this.uni_id = +props.match.params.uni_id;
		if (!canEditUniSettings(this.uni_id, props.userData.permissions)) {
			this.props.history.replace("/admin/uni/" + this.uni_id);
		}
	}

	componentDidMount() {
		this._isMounted = true;
		api.unis
			.getById({
				id: this.uni_id,
				getEssentialInfo: true,
				getContents: true,
				getMainInfo: true,
				getPrograms: false,
			})
			.then(data => {
				const { mainInfo, ...essentials } = data;
				this.setState({
					mainInfo: mainInfo!,
					uni: essentials as IRUniversity,
					selectedExpoRoomColor:
						(data.expoRoomColor as IExpoRoomColor) || null,
					selectedLocale: data.locale || "ka",
				});
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	saveUni = () => {
		if (Object.keys(this.uniChangedInfo).length === 0) {
			if (this.state.uni!.urlName)
				this.props.history.push("/unis/" + this.state.uni!.urlName);
			return;
		}
		const removeLoader = addLoader();
		api.unis
			.save({ id: this.uni_id, uniSettings: this.uniChangedInfo })
			.then(() => {
				removeLoader();
				this.props.uniChangeSettings(this.uni_id, this.uniChangedInfo);
				this.props.uniDisplayInfo({
					id: this.uni_id,
					mainInfo: this.state.mainInfo
						? {
								...this.state.mainInfo,
								...this.uniChangedInfo.mainInfo,
						  }
						: undefined,
				});
				if (!this._isMounted) return;
				this.props.history.push(
					"/unis/" +
						(this.uniChangedInfo.urlName || this.state.uni!.urlName)
				);
			})
			.catch(err => {
				removeLoader();
				if (!this._isMounted) return;
				if (err.response && Array.isArray(err.response.data)) {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "" + err.response.data[0].errorText, // TODO: translate in Georgian
					});
				} else {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "დაფიქსირდა შეცდომა",
					});
				}
			});
	};

	onUniInfoChange = (name: keyof IRUniversity) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		this.uniChangedInfo[name] = e.target.value as any;
	};

	onUniMainInfoChange = (name: keyof IUniMainInfo) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (typeof this.uniChangedInfo.mainInfo === "undefined") {
			this.uniChangedInfo.mainInfo = {};
		}
		this.uniChangedInfo.mainInfo[name] = e.target.value;
	};

	onCityChange = (newCities: number[]) => {
		this.uniChangedInfo.cities = newCities;
	};

	onAliasChange = (newAlias: string[]) => {
		this.uniChangedInfo.alias = newAlias.filter(name => name.length > 0);
	};

	onShortDescriptionChange = (text: string) => {
		this.uniChangedInfo.shortDescription = text;
	};

	onFacebookUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.uniChangedInfo.facebookUrl = e.target.value;
	};

	onLogoBGColorChange = (color: string) => {
		this.uniChangedInfo.logoBGColor = color;
	};

	onCoverBGColorChange = (color: string) => {
		this.uniChangedInfo.coverBGColor = color;
	};

	onExpoRoomColorChange = (color: IExpoRoomColor) => {
		this.uniChangedInfo.expoRoomColor = color;
		this.setState({ selectedExpoRoomColor: color });
	};

	handleErrorDialogClose = () => {
		this.setState({
			errorDialogOpen: false,
		});
	};

	onMainBannerUpload = (fileName: string) => {
		this.props.uniChangeSettings(this.uni_id, {
			expoMainBannerUrl: fileName,
		});
	};

	onSecondaryBannerUpload = (fileName: string) => {
		this.props.uniChangeSettings(this.uni_id, {
			expoSecondaryBannerUrl: fileName,
		});
	};

	onSwitchLocale = () => {
		this.uniChangedInfo.locale =
			this.state.selectedLocale === "ka" ? "en" : "ka";
		this.setState({ selectedLocale: this.uniChangedInfo.locale });
	};

	render() {
		if (!this.state.uni) return AdminUniEditPage.loaders();
		if (!this.state.mainInfo) return AdminUniEditPage.loaders();
		const cityOptions = cities.map(city => ({
			id: city.id,
			name: this.state.uni!.locale === "ka" ? city.name : city.engName,
		}));
		return (
			<div>
				<div className="uniEditPage">
					<FancyInput
						defaultValue={this.state.uni.name}
						title="უნივერსიტეტის სახელი"
						onChange={this.onUniInfoChange("name")}
					/>
					<FancyInput
						defaultValue={this.state.uni.officialName}
						title="ოფიციალური სახელი"
						onChange={this.onUniInfoChange("officialName")}
					/>
					<FancyInput
						defaultValue={this.state.uni.code as string | null}
						title="კოდი"
						onChange={this.onUniInfoChange("code")}
					/>
					<FancyInput
						defaultValue={this.state.uni.urlName}
						title="url სახელი"
						onChange={this.onUniInfoChange("urlName")}
					/>
					<FancyMultipleSelect
						title={"ქალაქები"}
						defaultValue={this.state.uni.cities}
						items={cityOptions}
						onChange={this.onCityChange}
					/>
					<FancyInput
						defaultValue={this.state.mainInfo.address}
						title="მისამართი"
						onChange={this.onUniMainInfoChange("address")}
					/>
					<FancyInput
						defaultValue={this.state.mainInfo.email}
						title="ელფოსტა"
						onChange={this.onUniMainInfoChange("email")}
					/>
					<FancyInput
						defaultValue={this.state.mainInfo.phone}
						title="მობილური"
						onChange={this.onUniMainInfoChange("phone")}
					/>
					<FancyInput
						defaultValue={this.state.mainInfo.web}
						title="ვებგვერდი"
						onChange={this.onUniMainInfoChange("web")}
					/>
					<FancyInput
						defaultValue={this.state.uni.facebookUrl}
						title="Facebook გვერდი"
						onChange={this.onFacebookUrlChange}
					/>
					<div className="fancyInputAndTitle">
						<h2>ენა</h2>
						<span style={{ marginRight: 10 }}>ka</span>
						<Switch
							size="big"
							checked={this.state.selectedLocale === "en"}
							onClick={this.onSwitchLocale}
						/>
						<span style={{ marginLeft: 10 }}>en</span>
					</div>
					<UniAliasEdit
						defaultValue={this.state.uni.alias}
						onChange={this.onAliasChange}
					/>
					<div className="QuillContainer quill--white--theme">
						<h2>მოკლე აღწერა</h2>
						<ReactQuill
							defaultValue={this.state.uni.shortDescription || ""}
							onChange={this.onShortDescriptionChange}
						/>
					</div>
					<div className="expoColorPicker">
						<h2>საგამოფენო სივრცის ფერი</h2>
						<div
							className="backgroundTypesContainer"
							id={
								"backgroundTypesContainer" +
								this.props.match.params.uni_id
							}
						>
							<div className="container">
								{expoColors.map(el => (
									<ColorCard
										key={el.color}
										color={el.color}
										selected={
											this.state.selectedExpoRoomColor ===
											el.name
										}
										onClick={() =>
											this.onExpoRoomColorChange(el.name)
										}
									/>
								))}
							</div>
						</div>
					</div>
					<h2>საგამოფენო სივრცის ბანერები</h2>
					<BannerUpload
						url={
							this.uniChangedInfo.expoMainBannerUrl ||
							this.state.uni.expoMainBannerUrl ||
							null
						}
						type={"expoMainBanner"}
						uni_id={this.uni_id}
						onUpload={this.onMainBannerUpload}
						width={844}
						height={150}
						buttonText={"მთავარი ბანერი"}
					/>
					<BannerUpload
						url={
							this.uniChangedInfo.expoSecondaryBannerUrl ||
							this.state.uni.expoSecondaryBannerUrl ||
							null
						}
						type={"expoSecondaryBanner"}
						uni_id={this.uni_id}
						onUpload={this.onMainBannerUpload}
						width={134}
						height={336}
						buttonText={"მეორადი ბანერი"}
					/>
					<UniLogoPicker
						uni={this.state.uni}
						onLogoBGColorChange={this.onLogoBGColorChange}
					/>
					<UniBackgroundType
						uni={this.state.uni}
						onCoverBGColorChange={this.onCoverBGColorChange}
						color={this.state.uni.coverBGColor}
					/>
					<button
						className="adminPrimaryButton"
						onClick={this.saveUni}
					>
						შენახვა
					</button>
				</div>
				<SnackNotification
					autoHideDuration={6000}
					onClose={this.handleErrorDialogClose}
					message={this.state.errorMessage}
					variant="error"
					open={this.state.errorDialogOpen}
				/>
			</div>
		);
	}
}

interface IUniAliasEditProps {
	defaultValue: IRUniversity["alias"];
	onChange?: (alias: string[]) => void;
}

interface IUniAliasEditState {
	values: string[];
}

export class UniAliasEdit extends React.Component<
	IUniAliasEditProps,
	IUniAliasEditState
> {
	state = {
		values:
			!this.props.defaultValue || this.props.defaultValue.length === 0
				? [""]
				: [...this.props.defaultValue, ""],
	};

	onParentOnChange = () => {
		if (this.props.onChange) {
			this.props.onChange(this.state.values.slice(0, -1));
		}
	};

	onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if (index === this.state.values.length - 1) {
			this.setState(
				{
					values: [
						...this.state.values.map((value, i) =>
							index !== i ? value : e.target.value
						),
						"",
					],
				},
				this.onParentOnChange
			);
		} else {
			this.setState(
				{
					values: this.state.values.map((value, i) =>
						index !== i ? value : e.target.value
					),
				},
				this.onParentOnChange
			);
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className="uniNicknamesDiv">
					ზედმეტსახელები:
					<br />
					{this.state.values.map((nickName, index) => (
						<FancyInput
							key={index}
							defaultValue={nickName}
							onChange={e => this.onChange(e, index)}
						/>
					))}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		uniChangeSettings,
		uniDisplayInfo,
	}
)(AdminUniEditPage);
