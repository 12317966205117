import Joi from "@app/utils/joi";

export const ProgramSubjectScoresSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	program_id: Joi.number()
		.integer()
		.required(),
	elective_subject_ids: Joi.array()
		.items(
			Joi.number()
				.integer()
				.required()
		)
		.required(),
	scores: Joi.object()
		.pattern(/^[0-9]*$/, Joi.number())
		.required(),
	total_score: Joi.number().required(),
	scholarship: Joi.number()
		.integer()
		.required(),
});

export interface IProgramSubjectScores {
	uni_id: number;
	program_id: number;
	elective_subject_ids: number[] | null;
	scores: {
		[x in number]: number;
	};
	total_score: number;
	scholarship: number;
}

export interface IScores {
	[subjectId: number]: number;
}
