import Joi from "@app/utils/joi";
import { IUserAction, UserActionSchema } from "../helper-schemas";

export const RGETUserActionSchema = UserActionSchema.allow(null);
export type IRGETUserAction = IUserAction | null;

///

export const APOSTUserActionSchema = Joi.object({
	name: Joi.string().required(),
	data: Joi.any().required(),
});
export interface IAPOSTUserAction {
	name: string;
	data: any;
}

export const RPOSTUserActionSchema = UserActionSchema;
export type IRPOSTUserAction = IUserAction;

export const ADELETEUsersActionSchema = Joi.object({
	userIds: Joi.array()
		.items(Joi.number().integer())
		.optional(),
	actionName: Joi.string().required(),
});
export interface IADELETEUsersAction {
	userIds?: number[];
	actionName: string;
}

export type IRDELETEUsersAction = void;

export const CountSchema = Joi.object()
	.keys({
		count: Joi.number()
			.integer()
			.required(),
	})
	.required();

export interface ICount {
	count: number;
}
