import {
	ILiveMeeting,
	IRUniversity,
	SocialMediaTypes,
} from "@app/api/unis/helper-schemas";
import { extractVideoIdFromYoutubeLink } from "@app/commonJavascript";
import { UniLogo } from "@app/components/Universities/FreeUniversityPage";
import SocialMediaVideo from "@app/components/Widgets/SocialMediaVideo";
import memoizeOne from "memoize-one";
import React, { useCallback } from "react";
import YouTube from "react-youtube";
import FacebookLogo from "../img/FacebookLogo.svg";
import YouTubeLogo from "../img/YouTubeLogo.svg";
import ZoomIcon from "../img/ZoomIcon.svg";
import ZoomLogo from "../img/ZoomLogo.svg";

interface ExpoLiveBoxProps {
	liveMeeting: ILiveMeeting;
	uni: IRUniversity;
}

export const ExpoUpcomingLiveBox: React.FC<ExpoLiveBoxProps> = React.memo(
	function ExpoLiveBox({ liveMeeting, uni }) {
		const displayTime = liveMeeting.start_time
			? `${liveMeeting.start_time.getHours()}:${liveMeeting.start_time
					.getMinutes()
					.toString()
					.padStart(2, "0")}`
			: "00:00";

		return (
			<div className="expoLiveBoxContainer">
				<div className="expoLiveBox">
					<div className="liveTop">
						<div className="liveLogo">
							<UniLogo
								logoUrl={uni.logoUrl}
								logoBGColor={uni.logoBGColor}
								style={{
									width: "100%",
									height: "100%",
									backgroundSize: "contain",
								}}
							/>
						</div>
						<div className="zoomIcon">
							<img src={ZoomIcon} alt="zoom" />
						</div>
						<div className="liveTime">{displayTime}</div>
					</div>
					<div className="liveMiddle">
						<h4>{liveMeeting.title}</h4>
					</div>
					<div className="liveBottom">
						<span>{uni.name}</span>
					</div>
				</div>
			</div>
		);
	}
);

export const ExpoFinishedLiveBox: React.FC<{
	liveMeeting: ILiveMeeting;
}> = React.memo(function ExpoFinishedLiveBox({ liveMeeting }) {
	return (
		<div className="expoLiveBoxContainer">
			<h3>{liveMeeting.title}</h3>
			<div className="expoLiveBox expoOngoingLiveBox">
				<SocialMediaVideo
					url={liveMeeting.videoURL}
					socialMedia={liveMeeting.social_media}
					style={{
						height: 200,
						minWidth: 200,
						width: 400,
						maxWidth: "100%",
					}}
				/>
			</div>
		</div>
	);
});

export const ExpoOngoingLiveBox: React.FC<{
	liveMeeting: ILiveMeeting;
	onEnd: (liveMeeting: ILiveMeeting) => void;
}> = React.memo(function ExpoOngoingLiveBox({ liveMeeting, onEnd }) {
	const onRedirect = useCallback(
		(url: string) => () => {
			window.open(url, "_blank");
		},
		[]
	);

	const getLogo = memoizeOne((socialMedia: SocialMediaTypes) => {
		switch (socialMedia) {
			case SocialMediaTypes.Facebook:
				return FacebookLogo;
			case SocialMediaTypes.YouTube:
				return YouTubeLogo;
		}
	});

	const opts = {
		playerVars: {
			autoplay: 1,
			mute: 1,
		},
	};

	return (
		<div className="expoLiveBoxContainer">
			<h3>{liveMeeting.title}</h3>
			<div className="expoLiveBox expoOngoingLiveBox">
				<div className="liveTop">
					<div className="liveLogo"></div>
					<div className="zoomIcon"></div>
					<div
						className="liveTime"
						style={{ backgroundColor: "#8D2DB0" }}
					>
						LIVE
					</div>
				</div>
				<div className="ongoingLiveBoxBackground">
					<YouTube
						videoId={extractVideoIdFromYoutubeLink(
							liveMeeting.videoURL
						)}
						opts={opts}
						onEnd={() => onEnd(liveMeeting)}
					/>
				</div>
				<div className="liveBottom ongoingLiveBottom">
					<button onClick={onRedirect(liveMeeting.videoURL)}>
						<img
							src={getLogo(liveMeeting.social_media)}
							alt={liveMeeting.social_media}
						/>
					</button>
					{liveMeeting.zoomURL && (
						<button onClick={onRedirect(liveMeeting.zoomURL)}>
							<img src={ZoomLogo} alt="Zoom" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
});
