import api from "@app/api";
import { IRGETAllRequestsWithScores } from "@app/api/toulator/validators";
import { TOUsubjects } from "@app/components/TOU/helpers/data";
import { GeneralTable, HeaderInfo } from "@app/components/Widgets/table";
import UploadButton from "@app/components/Widgets/UploadButton";
import { copyTextToClipboard } from "@app/utils/clipboard";
import React, { useEffect, useMemo, useState } from "react";

export const AdminToulatorResults: React.FC = props => {
	const [results, setResults] = useState<IRGETAllRequestsWithScores>([]);
	useEffect(() => {
		api.toulator.getAllRequests().then(e => setResults(e));
	}, []);

	const headers = useMemo(
		(): HeaderInfo[] => [
			{ label: "სახელი", value: "name" },
			{ label: "მობილური", value: "mob" },
			{ label: "ქულები", value: "scores" },
			{ label: "პროგრამა", value: "program" },
			{ label: "ჩააბარა", value: "passed" },
			{ label: "ზარი მოითხოვა", value: "requestedCall" },
			{ label: "თარიღი", value: "createdAt" },
		],
		[]
	);

	const rows = useMemo(() => {
		if (results.length === 0) return [];
		const result: {
			id: number;
			name: string | null;
			mob: number | null;
			program: string | null;
			scores: string;
			passed: string;
			requestedCall: string;
			createdAt: string;
		}[] = [];

		for (let i = 0; i < results.length; i++) {
			const element = results[i];
			let scores = "";
			if (element.user_scores) {
				for (let a = 0; a < element.user_scores.length; a++) {
					const e = element.user_scores[a];
					if (a === 0 || e === 0) continue;
					const subject = TOUsubjects.find(c => c.id === a);
					if (!subject) continue;
					scores =
						scores +
						" " +
						subject.name.substr(0, 4) +
						" : " +
						e +
						",";
				}
			}

			result.push({
				id: element.id,
				mob: element.mobile,
				name: element.fullname,
				program: element.programName,
				scores,
				passed:
					typeof element.passed === "boolean"
						? element.passed
							? "კი"
							: "არა"
						: "",
				requestedCall: element.requestedCall ? "კი" : "არა",
				createdAt: datify(element.created_at),
			});
		}

		return result;
	}, [results]);

	const handleCopy = () => {
		const header = headers.map(e => e.label).join("\t");
		const rowTexts = rows.map(row =>
			headers.map(header => row[header.value] || "").join("\t")
		);
		copyTextToClipboard([header].concat(rowTexts).join("\n"));
	};

	return (
		<div>
			<button className="adminPrimaryButtonWider" onClick={handleCopy}>
				კოპირება
			</button>
			<br />
			<br />
			სულ {rows.length} ჩანაწერი
			<br />
			<GeneralTable headerValues={headers} rows={rows} idKey="id" />
		</div>
	);
};

const datify = (date: Date) =>
	date.getFullYear() +
	"-" +
	prependZero(date.getMonth() + 1) +
	"-" +
	prependZero(date.getDate()) +
	" " +
	prependZero(date.getHours()) +
	":" +
	prependZero(date.getMinutes());

const prependZero = (num: number) =>
	num >= 0 && num < 10 ? 0 + "" + num : num;
