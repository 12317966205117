import { _console } from "@app/commonJavascript";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";

const worker = new Worker("/js/workers/SearchWorker.js");
let listeners: Function[] = [];

function loadUnis(unis: IRUniversity[]) {
	worker.postMessage({
		messageType: "loadUnis",
		unis,
	});
}

function loadPrograms(programs: IRProgram[]) {
	worker.postMessage({
		messageType: "loadPrograms",
		programs,
	});
}

function loadUnisAndPrograms(unis: IRUniversity[], programs: IRProgram[]) {
	worker.postMessage({
		messageType: "loadUnisAndPrograms",
		unis,
		programs,
	});
}

function query(
	searchQuery: string,
	searchingFor: "unisAndPrograms" | "unis" | "programs"
) {
	worker.postMessage({
		messageType: "search",
		query: searchQuery,
		for: searchingFor,
	});
}

function terminate() {
	worker.terminate();
}

function addListener(callback: Function) {
	listeners.push(callback);
}

function removeListener(callback: Function) {
	listeners = listeners.filter(listener => listener !== callback);
}

worker.onmessage = event => {
	listeners.forEach(listener => {
		listener(event.data);
	});
};

worker.onerror = err => {
	_console.error(err);
};

export {
	loadUnis,
	loadPrograms,
	loadUnisAndPrograms,
	query,
	terminate,
	addListener,
	removeListener,
};
