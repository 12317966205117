import api from "@app/api";
import { addLoader } from "@app/commonJavascript";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";

export const AdminExpoStatsPage: React.FC<{}> = () => {
	const [subscriptionCount, setSubscriptionCount] = useState<number>(0);
	useEffect(() => {
		const removeLoader = addLoader();
		api.uniExpo.getUserReservationsCount().then(data => {
			setSubscriptionCount(data.count);
			removeLoader();
		});
	});

	const onSubscribersDownload = () => {
		const removeLoader = addLoader();
		api.uniExpo
			.exportReservations()
			.then(data => {
				removeLoader();
				const now = new Date();
				let dateStr = now
					.toLocaleString()
					.replace(",", "")
					.replace(/\//g, "-")
					.replace(/:/g, "-");
				dateStr = dateStr.substr(0, dateStr.length - 3);
				fileDownload(data, `live_subscriptions_${dateStr}.xlsx`);
			})
			.catch(e => {
				removeLoader();
				alert("დაფიქსირდა შეცდომა");
				console.error(e);
			});
	};

	return (
		<div style={{ textAlign: "center" }}>
			<div className="header">გამოფენის სტატისტიკა</div>
			<br />
			<br />
			<div>
				LIVE-ების გამომწერთა რაოდენობა:{" "}
				<span
					style={{
						fontSize: 22,
						color: "yellow",
					}}
				>
					{subscriptionCount}
				</span>
			</div>
			<button
				className="adminPrimaryButton"
				style={{ width: "initial", padding: "0px 20px" }}
				onClick={onSubscribersDownload}
			>
				<span>LIVE-ების გამომწერთა ჩამოტვირთვა</span>
				<DownloadIcon style={{ marginLeft: 5, fontSize: 35 }} />
			</button>
		</div>
	);
};
