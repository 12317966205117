import { Diff, Omit, IAnyObj } from "./generics";

export function removeKeys<T, K extends keyof T>(
	obj: T,
	...keys: K[]
): Omit<T, K> {
	const obj2 = { ...obj };
	for (let i = 0; i < keys.length; ++i) {
		delete obj2[keys[i]];
	}
	return obj2;
}

export function getUniqueId(currentIds: number[], numOfIds = 1): number[] {
	const ids = [...currentIds];
	const newIds: number[] = [];
	for (let i = 0; i < numOfIds; ++i) {
		let uid = 0;
		do {
			uid = Math.floor(Math.random() * 1e9);
		} while (ids.indexOf(uid) !== -1);
		newIds.push(uid);
		ids.push(uid);
	}
	return newIds;
}

export function objectValues<T extends {}>(
	obj: T
): Diff<T[keyof T], undefined>[] {
	const keys = Object.keys(obj);
	const arr: Diff<T[keyof T], undefined>[] = [];
	for (let i = 0; i < keys.length; ++i) {
		const val = obj[keys[i]];
		if (val === undefined) continue;
		arr.push(val);
	}
	return arr;
}

export function getUniqueValues<T>(...args: (T[] | Set<T>)[]): T[] {
	const values: T[] = [];
	for (const arg of args) {
		values.push(...arg);
	}
	return [...new Set(values)];
}

export function arrayToObject<T extends {}, K extends keyof T>(
	arr: T[],
	mainKey: K
): { [key: string]: T | undefined } {
	const obj: { [key: string]: T | undefined } = {};
	for (let i = 0; i < arr.length; ++i) {
		obj[arr[i][mainKey as any]] = arr[i];
	}
	return obj;
}

// tslint:disable-next-line:cognitive-complexity
export function mergeRecursive<T1 extends IAnyObj, T2 extends IAnyObj>(
	object1: T1,
	object2: T2
): T1 & T2 {
	const obj1 = { ...object1 };
	const obj2 = { ...object2 };
	for (const p in obj2) {
		if (obj2.hasOwnProperty(p)) {
			try {
				// Property in destination object set; update its value.
				if (obj2[p].constructor === Object) {
					obj1[p] = mergeRecursive(obj1[p], obj2[p]);
				} else {
					obj1[p] = obj2[p] as any;
					if (obj1[p] === undefined) delete obj1[p];
				}
			} catch (e) {
				// Property in destination object not set; create it and set its value.
				obj1[p] = obj2[p] as any;
				if (obj1[p] === undefined) delete obj1[p];
			}
		}
	}

	return obj1 as any;
}

export function timeDiff(date1: Date | undefined, date2?: Date): number {
	if (!date1) return Infinity;
	try {
		const time2 = date2 ? date2.getTime() : Date.now();
		return time2 - date1.getTime();
	} catch (e) {
		console.log(e);
		return Infinity;
	}
}

export const reflectPromise = <T>(p): Promise<IReflectPromiseRes<T>> =>
	p
		.then(v => ({ v, status: "resolved" }))
		.catch(e => ({ e, status: "rejected" }));
export type IReflectPromiseRes<T> =
	| { v: T; status: "resolved" }
	| { e: Error; status: "rejected" };

export const delayPromise = (milliseconds: number) =>
	new Promise(resolve => setTimeout(resolve, milliseconds));

export function roundNumberDecimalPlaces(value: number, decimals: number) {
	return Number(Math.round(+(+(value + "e" + decimals) + "e-" + decimals)));
}

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
export function jsonDateParser(key: any, value: any) {
	if (typeof value === "string") {
		const a = reISO.exec(value);
		if (a) return new Date(value);
	}
	return value;
}

export function getOldIfUnchanged<T>(newObj: T, oldObj: T): T {
	if (
		newObj === oldObj ||
		JSON.stringify(newObj) === JSON.stringify(oldObj)
	) {
		return oldObj;
	} else {
		return newObj;
	}
}

export function isValidDateString(str: string): boolean {
	return !Number.isNaN(Date.parse(str));
}

export function deepEqual(x: any, y: any) {
	if (x === y) {
		return true;
	}

	if (
		typeof x === "object" &&
		x !== null &&
		(typeof y === "object" && y !== null)
	) {
		if (x instanceof Date && y instanceof Date) {
			return x.getTime() === y.getTime();
		}

		if (Object.keys(x).length !== Object.keys(y).length) {
			return false;
		}

		for (const prop in x) {
			if (x.hasOwnProperty(prop)) {
				if (y.hasOwnProperty(prop)) {
					if (!deepEqual(x[prop], y[prop])) {
						return false;
					}
				} else {
					return false;
				}
			}
		}

		return true;
	}

	return false;
}

export const generateFakeObjectId = (
	m = Math,
	d = Date,
	h = 16,
	s = s => m.floor(s).toString(h)
) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

export function mapValues<
	KeyT extends string | number | symbol,
	ValueT,
	NewValueT
>(
	obj: Record<KeyT, ValueT>,
	mapFn: (key: ValueT) => NewValueT
): Record<KeyT, NewValueT> {
	const res = {} as Record<KeyT, NewValueT>;
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			res[key] = mapFn(obj[key]);
		}
	}
	return res;
}

export function filterKeys<ValueT>(
	obj: Record<string, ValueT>,
	filterFn: (value: ValueT) => boolean
): string[] {
	const result: string[] = [];
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const val = obj[key];
			if (filterFn(val)) {
				result.push(key);
			}
		}
	}
	return result;
}

export function getValues<KeyT extends string | number | symbol, ValueT>(
	obj: Record<KeyT, ValueT>,
	keys: KeyT[]
): ValueT[] {
	const values: ValueT[] = [];
	for (const key of keys) {
		if (obj.hasOwnProperty(key)) {
			values.push(obj[key]);
		}
	}
	return values;
}
