import React, { useCallback } from "react";
import { History } from "history";
import "../styles/catalog-main-page-buttons.min.css";

export const UniExpoHomepageButton: React.FC<{ history: History }> = React.memo(
	function UniExpoNewsfeedButton({ history }) {
		const handleClick = useCallback(() => {
			history.push("/uni-expo");
		}, [history]);

		return (
			<div
				className="uniExpoHomepageButtonContainer"
				onClick={handleClick}
			>
				<h3>უნივერსიტეტების გამოფენა</h3>
			</div>
		);
	}
);
