import * as React from "react";
import Cancel from "@material-ui/icons/CancelOutlined";
import Popup from "../Widgets/Popup";
import { IEvent } from "@app/api/events/helpher-schemas";
import { IRootState } from "@app/reducers/root";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { UserEvent } from ".";

interface IProps {
	events: IEvent[];
	onClose: () => void;
	user_reserved_events: IRootState["temp"]["user_reserved_events"];
	unis?: IRUniversity[];
	onEventButtonClick: (
		id: number,
		is_reserved: boolean,
		label: string
	) => void;
	redirect: (address: string) => void;
	title?: string;
}
const MultipleEventsPopup: React.FC<IProps> = props => {
	const geoMonthNamesFormatted = [
		"იანვრის",
		"თებერვლის",
		"მარტის",
		"აპრილის",
		"მაისის",
		"ივნისის",
		"ივლისის",
		"აგვისტოს",
		"სექტემბრის",
		"ოქტომბრის",
		"ნოემბრის",
		"დეკემბრის",
	];
	const title = props.title
		? props.title
		: props.events[0].start_time
		? props.events[0].start_time.getDate() +
		  " " +
		  geoMonthNamesFormatted[props.events[0].start_time.getMonth()] +
		  " ღონისძიებები"
		: "jghjj";
	return (
		<React.Fragment>
			<Popup onClose={props.onClose}>
				<div className="popup_child">
					<div className="popup_header">
						{title && <h2>{title}</h2>}
						<Cancel
							className="cancelIcon"
							onClick={props.onClose}
						/>
					</div>
					<div className="popup_events_container">
						{props.events
							.sort(
								(e1, e2) =>
									e2.created_at.getTime() -
									e1.created_at.getTime()
							)
							.map(event => (
								<UserEvent
									key={event.id}
									event={event}
									unis={props.unis}
									is_reserved={
										!!props.user_reserved_events &&
										props.user_reserved_events.some(
											id => id === event.id
										)
									}
									onClick={props.onEventButtonClick}
									redirect={props.redirect}
								/>
							))}
					</div>
				</div>
			</Popup>
		</React.Fragment>
	);
};

export default MultipleEventsPopup;
