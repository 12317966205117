import React from "react";

const SvgClockArrows01 = props => (
  <svg
    version={1.1}
    id="Layer_3"
    x={0}
    y={0}
    viewBox="0 0 9 84.9"
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.st0{fill:#fff}`}</style>
    <path
      className="st0"
      d="M8.5 78.4c-.2-.4-.5-.8-.9-1.2-.8-.8-1.9-1.3-3.2-1.3h-.2C1.9 76 0 78 0 80.4c0 1.7.9 3.1 2.3 3.9.1.1.2.1.3.2.6.3 1.2.4 1.9.4 2.4 0 4.3-1.9 4.5-4.2v-.3c0-.7-.2-1.4-.5-2zm-4 4.3c-1.3 0-2.4-1.1-2.4-2.4S3.2 78 4.5 78s2.4 1.1 2.4 2.4-1.1 2.3-2.4 2.3z"
    />
    <path
      className="st0"
      d="M2.3 75.9l2-48.9 2 48.9c0 1.1-.8 2-1.9 2.1s-2-.8-2.1-1.9v-.2z"
    />
  </svg>
);

export default SvgClockArrows01;
