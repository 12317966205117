import { inject } from "@app/modules";
import { RequestsType } from "..";
import { IRLatoriProgram } from "./helper-schemas";

export class LatoriProgramsController {
	private readonly Request: RequestsType;

	private readonly _LatoriProgramModel = inject("LatoriProgramModel");

	constructor(request: RequestsType) {
		this.Request = request;
	}

	getAll = (): Promise<IRLatoriProgram[]> =>
		this.Request.send("GET", "/api/programs", undefined, null).then(
			(data: IRLatoriProgram[]) => {
				this._LatoriProgramModel.loadManySync(data);
				return data;
			}
		);
}
