import api from "@app/api";
import unis from "@app/api/unis/controller";
import { IUniOffer } from "@app/api/unis/helper-schemas";
import { IRGETUni } from "@app/api/unis/validators";
import { addLoader, getGalleryMediaUrl } from "@app/commonJavascript";
import { UniLogo } from "@app/components/Universities/FreeUniversityPage";
import React, { useEffect, useState } from "react";
import { History } from "history";
import "../styles/expo-offers.min.css";

const OffersList: React.FC<{ uniId?: number; history: History }> = React.memo(
	function OffersList({ uniId, history }) {
		const [uniOffers, setUniOffers] = useState<IUniOffer[]>();

		useEffect(() => {
			const removeLoader = addLoader();
			api.unis.content
				.getOffers(uniId ? { uni_id: uniId } : {})
				.then(data => {
					setUniOffers(data);
					removeLoader();
				})
				.catch(e => {
					console.log(e);
					removeLoader();
				});
		}, [uniId]);

		return (
			<div className="offersListContainer">
				{uniOffers &&
					uniOffers.map((e, i) => <OfferItem offer={e} key={i} />)}
				<div className="buttonContainer">
					{!!uniId && (
						<button
							onClick={() => history.push("/uni-expo/offers")}
						>
							ყველა შეთავაზება
						</button>
					)}
				</div>
			</div>
		);
	}
);

interface OfferItemProps {
	offer: IUniOffer;
}

const OfferItem: React.FC<OfferItemProps> = React.memo(function OfferItem({
	offer,
}) {
	const [showUniversityName, setShowUniversityName] = useState(false);
	const [uni, setUni] = useState<IRGETUni>();

	useEffect(() => {
		unis.getById({ id: offer.uni_id }).then(data => setUni(data));
	}, [offer.uni_id]);

	const redirectInNewTab = (url: string) => {
		if (window.location.hostname === "localhost")
			window.open(`http://localhost:8090${url}`, "_blank");
		else window.open(`https://unicatalog.ge${url}`, "_blank");
	};

	if (!uni) return null;

	return (
		<div className="offerItemContainer">
			<div
				className="logoDiv"
				onMouseEnter={() => setShowUniversityName(true)}
				onMouseLeave={() => setShowUniversityName(false)}
			>
				{showUniversityName && !!uni && (
					<div className="universityName">
						<span>{uni.name}</span>
					</div>
				)}
				{uni.logoUrl && uni.logoBGColor !== undefined && (
					<UniLogo
						logoUrl={uni.logoUrl}
						logoBGColor={uni.logoBGColor}
						style={{
							height: "56px",
							width: "56px",
							borderRadius: "100%",
						}}
					/>
				)}
			</div>
			<span>{offer.title || "შეთავაზება"}</span>

			<button
				onClick={() =>
					redirectInNewTab(getGalleryMediaUrl(offer.type, offer.url))
				}
			>
				დეტალურად
			</button>
		</div>
	);
});

export default OffersList;
