import * as React from "react";
import api from "@app/api";
import FancyCheckbox from "../Widgets/FancyCheckbox";
import FancyInput from "../Widgets/FancyInput";
import FancyMultipleSelect from "../Widgets/FancyMultipleSelect";
import memoizeOne from "memoize-one";
import { addLoader } from "@app/commonJavascript";
import {
	CAN_ACCESS_UNI_NEWS_AND_EVENTS,
	CAN_EDIT_UNI_CONTENT,
	CAN_EDIT_UNI_SETTINGS,
	CAN_SEND_UNI_SMS,
} from "@app/api/unis/consts";
import {
	CAN_EDIT_PROGRAM_CONTENT,
	CAN_EDIT_PROGRAM_SETTINGS,
} from "@app/api/programs/consts";
import { connect } from "react-redux";
import { History } from "history";
import {
	IPrivilegeAllUnis,
	IPrivilegeOneUniAllPrograms,
	IPrivilegeOneUniSeveralPrograms,
	IRUser,
	IRUserPermissions,
	IRUserPrivilege,
} from "@app/api/users/helper-schemas";
import { IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { match } from "react-router";
import "./styles/addAdmingPage.min.css";
import {
	canAccessAllProgramsOfUni,
	canAccessAllUnis,
	canAccessProgram,
	canAccessUni,
	canAccessUniNewsAndEVentsByPermission,
	canEditProgramContentByPermission,
	canEditProgramSettingsByPermission,
	canEditUniContentByPermission,
	canEditUniSettingsByPermission,
	canSendUniSMSByPermission,
} from "@app/permissions";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

interface IState {
	programs: IRProgram[];
	value: string;
	unis: IRUniversity[];
	allUnis: boolean;
	showProgramsCheckbox: boolean;
	showProgramsList: boolean;
	selectedUniId: number | null;
	selectedProgramIds: number[];
	selectedUniPermissions: number;
	programPermissions: number;
	adminAddAbility: boolean;
	searcResult: null | { id: number; username: string };
	searchingUser: boolean;
	numberIsValid: boolean;
}

class AddAdminPage extends React.Component<IProps, IState> {
	uni_id: number;
	canAccessEveryProgram: boolean;
	programPermissions: number[] = [];

	state: IState = {
		value: "",
		unis: [],
		programs: [],
		allUnis: false,
		selectedUniId: null,
		showProgramsCheckbox: false,
		showProgramsList: false,
		selectedProgramIds: [],
		selectedUniPermissions: 0,
		programPermissions: 0,
		adminAddAbility: false,
		searcResult: null,
		searchingUser: false,
		numberIsValid: false,
	};

	constructor(props: IProps) {
		super(props);
		this.uni_id = +props.match.params.uni_id;
		if (!canAccessUni(this.uni_id, props.userData.permissions)) {
			props.history.push("/admin");
			return;
		}
		if (!canAccessAllUnis(props.userData.permissions!.privilege)) {
			this.state.selectedUniId = this.uni_id;
			this.canAccessEveryProgram = canAccessAllProgramsOfUni(
				props.userData.permissions!.privilege
			);
			if (this.canAccessEveryProgram) {
				this.state.showProgramsCheckbox = true;
				this.state.showProgramsList = false;
			} else {
				this.state.showProgramsCheckbox = false;
				this.state.showProgramsList = true;
			}
		} else {
			this.canAccessEveryProgram = true;
			this.state.allUnis = true;
		}
	}

	handleAdminAddAbility = () => {
		this.setState({
			adminAddAbility: !this.state.adminAddAbility,
		});
	};

	handleUniPermissionChange = (permission: number) => {
		let newPermissions = this.state.selectedUniPermissions;
		if ((newPermissions & permission) === permission) {
			// remove permission
			newPermissions -= permission;
		} else {
			// add permission
			newPermissions = newPermissions | permission;
		}
		this.setState({
			selectedUniPermissions: newPermissions,
		});
	};

	handleProgramPermissionChange = (permission: number) => {
		let newPermissions = this.state.programPermissions;
		if ((newPermissions & permission) === permission) {
			newPermissions -= permission;
		} else {
			newPermissions = newPermissions | permission;
		}
		this.setState({
			programPermissions: newPermissions,
		});
	};

	handleProgramCheckbox = () => {
		this.setState({
			showProgramsList: !this.state.showProgramsList,
		});
	};

	handeUniCheckbox = () => {
		this.setState({
			showProgramsCheckbox: !!this.state.allUnis,
			showProgramsList: this.state.allUnis
				? !this.canAccessEveryProgram
				: false,
			allUnis: !this.state.allUnis,
			selectedUniId: this.state.allUnis ? this.uni_id : null,
		});
	};

	handleProgramCheckboxChange = () => {
		this.setState({
			showProgramsCheckbox: !this.state.showProgramsCheckbox,
		});
	};

	handleProgramIdsChange = (programIds: number[]) => {
		this.setState({
			selectedProgramIds: programIds,
			showProgramsList: true,
		});
	};

	handleNumberValidity = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			value: e.target.value,
		});
		if (e.target.value.length === 9 && Number.isInteger(+e.target.value)) {
			// valid mobile number length
			this.setState({
				searchingUser: true,
			});
			api.users
				.search({
					mob: +e.target.value,
				})
				.then(data => {
					this.setState({
						searchingUser: false,
						searcResult: data,
					});
				})
				.catch(err => {
					this.setState({
						searchingUser: false,
						searcResult: null,
					});
				});
		}
	};

	addAdmin = () => {
		if (!this.state.searcResult) {
			return;
		}
		const removeLoader = addLoader();
		let privilege = {} as IRUserPrivilege;
		if (this.state.allUnis) {
			privilege = {
				allUnis: true,
				allProgramsPermissions: this.state.programPermissions,
			} as IPrivilegeAllUnis;
		} else if (!this.state.showProgramsList) {
			privilege = {
				uniId: this.state.selectedUniId,
				allPrograms: true,
				allProgramsPermissions: this.state.programPermissions,
			} as IPrivilegeOneUniAllPrograms;
		} else {
			privilege = {
				uniId: this.state.selectedUniId,
				programs: this.state.selectedProgramIds.map(progId => ({
					id: progId,
					permissions: this.state.programPermissions,
				})),
			} as IPrivilegeOneUniSeveralPrograms;
		}
		api.users
			.upsertAdminPermissions({
				canAddAdmins: this.state.adminAddAbility,
				privilege,
				uniPermissions: this.state.selectedUniPermissions,
				user_id: this.state.searcResult.id,
			})
			.then(() => {
				removeLoader();
				this.props.history.push("/admin/");
			})
			.catch(err => {
				removeLoader();
				console.log(err);
				alert("დაფიქსირდა შეცდომა");
			});
	};

	render() {
		return (
			<div className="adminPage">
				<div className="header">ადმინისტრატორის დამატება</div>
				<div className="searchAndAdd">
					<div className="phoneNumber">
						<div className="inputNumber">
							<FancyInput
								defaultValue={this.state.value}
								title="ტელეფონის ნომერი"
								onChange={this.handleNumberValidity}
							/>
						</div>
					</div>
					<div className="searchResult">
						<div>ძებნის შედეგი</div>
						<div className="result">
							{this.state.searchingUser
								? "იძებნება..."
								: this.state.searcResult
								? this.state.searcResult.username
								: "არ მოიძებნა"}
						</div>
					</div>
				</div>
				<div className="selectPermissionsHeader">
					მონიშნეთ ადმინის უფლებები
				</div>
				<div>
					<MainAdminPermissions
						handeUniCheckbox={this.handeUniCheckbox}
						handleProgramCheckbox={this.handleProgramCheckbox}
						allUnis={this.state.allUnis}
						showProgramsCheckbox={this.state.showProgramsCheckbox}
						showProgramsList={this.state.showProgramsList}
						unis={this.props.unis}
						programs={this.props.programs}
						onProgramCheckboxChange={
							this.handleProgramCheckboxChange
						}
						onProgramIdsChange={this.handleProgramIdsChange}
						selectedUniId={this.state.selectedUniId}
						selectedProgramIds={this.state.selectedProgramIds}
						userData={this.props.userData}
					/>
				</div>
				<div>
					<GeneralAdminPermissions
						selectedUniPermissions={
							this.state.selectedUniPermissions
						}
						programPermissions={this.state.programPermissions}
						handleUniPermissionChange={
							this.handleUniPermissionChange
						}
						handleProgramPermissionChange={
							this.handleProgramPermissionChange
						}
						selectedUniId={this.state.selectedUniId}
						userData={this.props.userData}
						programs={this.props.programs}
						allProgramsAreSelected={!this.state.showProgramsList}
						selectedProgramIds={this.state.selectedProgramIds}
					/>
				</div>
				<div className="ableToAddAdminPermissionWrapper">
					<FancyCheckbox
						id="ableToAddAdminPermission"
						checked={this.state.adminAddAbility}
						onChange={this.handleAdminAddAbility}
					>
						შეეძლოს ადმინების განკარგვა
					</FancyCheckbox>
				</div>
				<div>
					<button
						className="adminPrimaryButton"
						onClick={this.addAdmin}
					>
						დადასტურება
					</button>
				</div>
			</div>
		);
	}
}

interface IMainAdminPermissionsProps {
	handleProgramCheckbox: () => void;
	handeUniCheckbox: () => void;
	onProgramCheckboxChange: () => void;
	onProgramIdsChange: (programIds: number[]) => void;
	allUnis: boolean;
	showProgramsCheckbox: boolean;
	showProgramsList: boolean;
	selectedUniId: number | null;
	unis: IRootState["unis"]["info"];
	programs: IRootState["programs"]["info"];
	selectedProgramIds: number[];
	userData: IRUser;
}

const MainAdminPermissions: React.SFC<IMainAdminPermissionsProps> = props => {
	const canAccessEveryUni =
		!!props.userData.permissions &&
		!!canAccessAllUnis(props.userData.permissions.privilege);
	return (
		<div className="addAdminpermissionsWrapper">
			{canAccessEveryUni && (
				<div className="permissions">
					<FancyCheckbox
						id="allUnisCkeckbox"
						checked={props.allUnis}
						onChange={props.handeUniCheckbox}
					>
						ყველა უნივერსიტეტთან წვდომა
					</FancyCheckbox>
				</div>
			)}
			{(props.showProgramsCheckbox || props.showProgramsList) && (
				<div className="addAdminProgramListWrapper">
					<MainProgramPermissions
						handleProgramCheckbox={props.handleProgramCheckbox}
						showProgramsList={props.showProgramsList}
						showProgramsCheckbox={props.showProgramsCheckbox}
						programs={props.programs}
						selectedUniId={props.selectedUniId}
						onProgramIdsChange={props.onProgramIdsChange}
						selectedProgramIds={props.selectedProgramIds}
						userData={props.userData}
					/>
				</div>
			)}
		</div>
	);
};

interface IMainProgramPermissionsProps {
	handleProgramCheckbox: () => void;
	showProgramsList: boolean;
	showProgramsCheckbox: boolean;
	programs: IRootState["programs"]["info"];
	selectedUniId: number | null;
	onProgramIdsChange: (programIds: number[]) => void;
	selectedProgramIds: number[];
	userData: IRUser;
}

const getProgramsOfUni = memoizeOne(
	(
		programs: IRProgram[],
		uni_id: number,
		permissions: IRUserPermissions | null
	): IRProgram[] => {
		return programs.filter(
			prog =>
				prog.uni_id === uni_id &&
				canAccessProgram(prog.id, prog.uni_id, permissions)
		);
	}
);

const MainProgramPermissions: React.SFC<IMainProgramPermissionsProps> = props => {
	if (!props.programs) return null;
	const availablePrograms = getProgramsOfUni(
		props.programs,
		props.selectedUniId!,
		props.userData.permissions
	);
	return (
		<React.Fragment>
			{props.showProgramsCheckbox && (
				<div className="addAdminProgramListCheckbox">
					<div className="permissions">
						<FancyCheckbox
							id="allProgramsCheckbox"
							checked={!props.showProgramsList}
							onChange={props.handleProgramCheckbox}
						>
							ყველა ფაკულტეტთან წვდომა
						</FancyCheckbox>
					</div>
				</div>
			)}
			{props.showProgramsList && !!props.programs && (
				<div className="addAdminProgramList">
					<FancyMultipleSelect
						title="აირჩიეთ ფაკულტეტები"
						defaultValue={props.selectedProgramIds}
						items={availablePrograms}
						onChange={props.onProgramIdsChange}
					/>
				</div>
			)}
		</React.Fragment>
	);
};

interface IGeneralAdminPermissionsProps {
	selectedUniPermissions: number;
	programPermissions: number;
	handleUniPermissionChange: (permission: number | null) => void;
	handleProgramPermissionChange: (permission: number | null) => void;
	userData: IRUser;
	selectedUniId: number | null;
	programs?: IRProgram[];
	allProgramsAreSelected: boolean;
	selectedProgramIds: number[];
}

const GeneralAdminPermissions: React.SFC<IGeneralAdminPermissionsProps> = props => {
	const canChangeUniSettings = canEditUniSettingsByPermission(
		props.userData.permissions!.uniPermissions
	);
	const canChangeUniContent = canEditUniContentByPermission(
		props.userData.permissions!.uniPermissions
	);
	const canSendUniSMS = canSendUniSMSByPermission(
		props.userData.permissions!.uniPermissions
	);
	const canAcessUniNewsAndEvents = canAccessUniNewsAndEVentsByPermission(
		props.userData.permissions!.uniPermissions
	);

	let programsPermissions = 0;

	const { privilege } = props.userData.permissions!;

	if (canAccessAllUnis(privilege)) {
		programsPermissions = privilege.allProgramsPermissions;
	} else if (canAccessAllProgramsOfUni(privilege)) {
		programsPermissions = privilege.allProgramsPermissions;
		// tslint:disable-next-line:no-collapsible-if
	} else {
		if (props.selectedProgramIds.length === 0) {
			programsPermissions = 0;
		} else {
			programsPermissions = (1 << 20) - 1;
			for (let i = 0; i < privilege.programs.length; ++i) {
				if (
					props.selectedProgramIds.indexOf(
						privilege.programs[i].id
					) === -1
				)
					continue;
				programsPermissions &= privilege.programs[i].permissions;
			}
		}
	}

	const canChangeProgramSettings = canEditProgramSettingsByPermission(
		programsPermissions
	);
	const canChangeProgramContent = canEditProgramContentByPermission(
		programsPermissions
	);
	return (
		<div>
			{(canChangeUniSettings || canChangeUniContent) && (
				<div className="generalUniPermissions">
					<div className="selectPermissionsHeader">
						უნივერსიტეტის უფლებები
					</div>
					{canChangeUniSettings && (
						<div className="permissions">
							<FancyCheckbox
								id="changeUniSettings"
								checked={canEditUniSettingsByPermission(
									props.selectedUniPermissions
								)}
								onChange={() =>
									props.handleUniPermissionChange(
										CAN_EDIT_UNI_SETTINGS
									)
								}
							>
								პარამეტრების შეცვლა
							</FancyCheckbox>
						</div>
					)}
					{canChangeUniContent && (
						<div className="permissions">
							<FancyCheckbox
								id="changeUniContent"
								checked={canEditUniContentByPermission(
									props.selectedUniPermissions
								)}
								onChange={() =>
									props.handleUniPermissionChange(
										CAN_EDIT_UNI_CONTENT
									)
								}
							>
								კონტენტის შეცვლა
							</FancyCheckbox>
						</div>
					)}
					{canSendUniSMS && (
						<div className="permissions">
							<FancyCheckbox
								id="changeUniSMSPermissions"
								checked={canSendUniSMSByPermission(
									props.selectedUniPermissions
								)}
								onChange={() =>
									props.handleUniPermissionChange(
										CAN_SEND_UNI_SMS
									)
								}
							>
								SMS-ების გაგზავნა
							</FancyCheckbox>
						</div>
					)}
					{canAcessUniNewsAndEvents && (
						<div className="permissions">
							<FancyCheckbox
								id="changeUniNewsAccessPermissions"
								checked={canAccessUniNewsAndEVentsByPermission(
									props.selectedUniPermissions
								)}
								onChange={() =>
									props.handleUniPermissionChange(
										CAN_ACCESS_UNI_NEWS_AND_EVENTS
									)
								}
							>
								სიახლეებისა და ღონისძიებების განკარგვა
							</FancyCheckbox>
						</div>
					)}
				</div>
			)}
			{(canChangeProgramSettings || canChangeProgramContent) && (
				<div className="generalProgramPermissions">
					<div className="selectPermissionsHeader">
						ფაკულტეტის უფლებები
					</div>
					{/* {canAccessSeveralProgramsOfUni(props.userData.permissions!.privilege) &&
				)} */}
					{canChangeProgramSettings && (
						<div className="permissions">
							<FancyCheckbox
								id="changeProgramSettings"
								checked={canEditProgramSettingsByPermission(
									props.programPermissions
								)}
								onChange={() =>
									props.handleProgramPermissionChange(
										CAN_EDIT_PROGRAM_SETTINGS
									)
								}
							>
								პარამეტრების შეცვლა
							</FancyCheckbox>
						</div>
					)}
					{canChangeProgramContent && (
						<div className="permissions">
							<FancyCheckbox
								id="changeProgramContent"
								checked={canEditProgramContentByPermission(
									props.programPermissions
								)}
								onChange={() =>
									props.handleProgramPermissionChange(
										CAN_EDIT_PROGRAM_CONTENT
									)
								}
							>
								კონტენტის შეცვლა
							</FancyCheckbox>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
});

export default connect(mapStateToProps)(AddAdminPage);
