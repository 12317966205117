import {
	ILoadEvents,
	ILoadNews,
	ILoadUserReservedEvents,
	IReserveEvent,
	IUnReserveEvent,
	LoadEvents,
	LoadNews,
	loadUserReservedEvents,
	reserveEvent,
	unReserveEvent,
} from "@app/actions/temp";
import api from "@app/api";
import { IEvent } from "@app/api/events/helpher-schemas";
import { INews } from "@app/api/news/validators";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity, IUniContent } from "@app/api/unis/helper-schemas";
import { IFavPrograms } from "@app/api/users/programs/validators";
import { ISubscribedUnis } from "@app/api/users/unis/validators";
import {
	extractVideoIdFromYoutubeLink,
	getNewsOrEventMediaUrl,
	monthNamesGeo,
} from "@app/commonJavascript";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import htmlSubstring from "@app/utils/html_substring";
import CheckIcon from "@material-ui/icons/CheckRounded";
import { History } from "history";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import YouTube from "react-youtube";
import { DesktopAd800, MobileAd240 } from "../dev/ads";
import { sortUnisByCoeff } from "../MainPageComponents/search/searchAlgorithms";
import MurtkuLatoriImg from "../MainPageComponents/styles/img/bar.png";
import { SmallProg } from "../Programs/ProgramCard";
import "../styles/commonStyles.min.css";
import { UniExpoHomepageButton } from "../uni-expo/catalog-main-page-buttons/uni-expo-main-page-buttons";
import { UniLogo } from "../Universities/FreeUniversityPage";
import GalleryLightbox from "../Universities/gallery-lightbox";
import DateCalendar from "../Widgets/date_calendar";
import { UpIcon } from "../Widgets/upIcon";
import CalendarOfEventsContainer from "./calendar-of-events-container";
import "./styles/userCustompage.min.css";

type EventAndNews = (INews & { type: "NEWS" }) | (IEvent & { type: "EVENT" });

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	LoadEvents: ILoadEvents;
	loadUserReservedEvents: ILoadUserReservedEvents;
	reserveEvent: IReserveEvent;
	unReserveEvent: IUnReserveEvent;
	LoadNews: ILoadNews;
}

interface IState {
	murtskulatoriPopupOpen: boolean;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

class UserCustomHomePage extends React.Component<IProps, IState> {
	_isMounted = false;
	enableUniExpo = false; // TODO: change this to true when expo starts
	state: IState = {
		murtskulatoriPopupOpen: false,
	};
	sortUnis = memoizeOne((unis: IRUniversity[]): IRUniversity[] => {
		return sortUnisByCoeff(unis);
	});

	componentDidMount() {
		const locale = getLocale();
		if (locale !== "ka") {
			this.props.history.push("/unis");
		}
		this._isMounted = true;
		const {
			user_events,
			user_news,
			userEventsLoadTime,
			userNewsLoadTime,
		} = this.props;
		const minuteAfter: number = new Date().getTime() + 1000 * 60;
		if (
			!user_events ||
			!userEventsLoadTime ||
			userEventsLoadTime < minuteAfter
		) {
			this.props.LoadEvents().then(events => {
				if (!this._isMounted) return;
			});
		}
		if (
			!this.props.user_reserved_events ||
			!this.props.user_reserved_events.length
		) {
			this.props.loadUserReservedEvents().then(data => {
				if (!this._isMounted) return;
			});
		}
		if (!user_news || !userNewsLoadTime || userNewsLoadTime < minuteAfter) {
			this.props.LoadNews({}).then(news => {
				if (!this._isMounted) return;
			});
		}
		api.users.userActions.getUserAction().then(data => {
			if (
				!data ||
				!data.actions["subscribedLatoriNews"] ||
				new Date(data.actions["subscribedLatoriNews"]).getFullYear() !==
					new Date().getFullYear()
			) {
				this.setState({ murtskulatoriPopupOpen: true });
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onEventReserve = (
		event_id: number,
		is_reserved: boolean,
		label: string
	) => {
		if (is_reserved) {
			this.props.unReserveEvent(event_id);
			triggerEvent({
				category: "Event",
				action: "Unreserve",
				label,
			});
		} else {
			this.props.reserveEvent(event_id);
			triggerEvent({
				category: "Event",
				action: "Reserve",
				label,
			});
		}
	};

	redirect = (address: string) => this.props.history.push(address);

	gotoLatori = () => {
		this.props.history.push("/latori/");
		triggerEvent({
			category: "Button",
			action: "Goto Latori page",
			label: "from user page",
		});
	};

	onBannerClick = () =>
		window.open(
			"https://www.palitral.ge/books-according-to-age.html?view=catalog&age=36&lang=ka-GE",
			"_blank"
		);

	render() {
		const { user_reserved_events } = this.props;
		const eventsAndNews = joinEventsAndNews(
			this.props.user_events,
			this.props.user_news
		);

		return (
			<div className="user_custom_page_main">
				<div className="user_custom_page_left">
					<div
						className="murtskulator_logo_container"
						onClick={this.gotoLatori}
					>
						<img
							className="murtskulator_logo"
							src={MurtkuLatoriImg}
							alt="logo"
						/>
						<h3>მურწყულატორი</h3>
					</div>
					{!!this.props.unis.info && (
						<LeftNavFrame title="მეგობარი უნივერსიტეტები">
							<PremiumUnisList
								unis={this.sortUnis(this.props.unis.info)}
								redirect={this.redirect}
							/>
						</LeftNavFrame>
					)}
					<MobileAd240
						style={{
							width: "100%",
							height: 222.6,
							borderRadius: 14,
							marginTop: 27,
							marginBottom: -5,
						}}
						className={"mobile1280"}
					/>
					<div style={{ height: 27 }} />
					{this.props.user_events && (
						<CalendarOfEventsContainer
							events={this.props.user_events}
							user_reserved_events={user_reserved_events}
							unis={this.props.unis.info}
							onEventButtonClick={this.onEventReserve}
							redirect={this.redirect}
						/>
					)}
					{!!this.props.favoritePrograms.favorites &&
						Object.keys(this.props.favoritePrograms.favorites)
							.length > 0 && (
							<LeftNavFrame title="რჩეული ფაკულტეტები">
								<UserFavouriteProgramsList
									programList={
										this.props.favoritePrograms.favorites
									}
									programs={this.props.programs.info}
									redirect={this.redirect}
								/>
							</LeftNavFrame>
						)}
					{!!this.props.subscribedUnis.subscribed &&
						!!this.props.unis.info &&
						Object.keys(this.props.subscribedUnis.subscribed)
							.length > 0 && (
							<LeftNavFrame title="გამოწერილი უნივერსიტეტები">
								<UserSubscribedUnisList
									uniList={
										this.props.subscribedUnis.subscribed
									}
									unis={this.props.unis.info}
									redirect={this.redirect}
								/>
							</LeftNavFrame>
						)}
				</div>
				{eventsAndNews && (
					<div className="user_custom_page_right">
						<DesktopAd800
							style={{
								width: "95%",
								marginLeft: "-11px",
								marginBottom: 20,
								height: 120 * 0.95,
							}}
							className={"desktop1280"}
						/>
						{this.enableUniExpo && (
							<UniExpoHomepageButton
								history={this.props.history}
							/>
						)}
						{eventsAndNews.map(item => {
							if (item.type === "EVENT") {
								const event = item as IEvent;
								return (
									<UserEvent
										key={event.id}
										event={event}
										unis={this.props.unis.info}
										is_reserved={
											!!user_reserved_events &&
											user_reserved_events.some(
												id => id === event.id
											)
										}
										onClick={this.onEventReserve}
										redirect={this.redirect}
									/>
								);
							}
							if (item.type === "NEWS") {
								const news = item as INews;
								return (
									<UserNews
										key={news.id * 10000}
										news={news}
										unis={this.props.unis.info}
										redirect={this.redirect}
									/>
								);
							}
							return null;
						})}
					</div>
				)}
				<UpIcon styles={{ bottom: 50 }} />
			</div>
		);
	}
}

export const joinEventsAndNews = memoizeOne(
	(events?: IEvent[], news?: INews[]): EventAndNews[] => {
		let eventsAndNews: EventAndNews[] = [];

		if (events) {
			eventsAndNews = [
				...eventsAndNews,
				...events.map(
					(event: IEvent) =>
						({ type: "EVENT", ...event } as EventAndNews)
				),
			];
		}
		if (news) {
			eventsAndNews = [
				...eventsAndNews,
				...news.map(
					(newsObject: INews) =>
						({ type: "NEWS", ...newsObject } as EventAndNews)
				),
			];
		}

		eventsAndNews.sort(
			(a, b) =>
				new Date(b.created_at).getTime() -
				new Date(a.created_at).getTime()
		);

		return eventsAndNews;
	}
);

interface IUserFavouriteProgramsListProps {
	programList?: IFavPrograms;
	programs?: IRProgram[];
	redirect: (address: string) => void;
}

const UserFavouriteProgramsList: React.FC<IUserFavouriteProgramsListProps> = props => {
	if (!props.programList || !props.programs) return null;
	return (
		<div className="favourite_programs">
			{Object.keys(props.programList).map(program_id => {
				const favouriteProgram = props.programs!.find(
					program => program.id === +program_id
				);
				if (!favouriteProgram) return null;
				return (
					<div
						key={program_id}
						className="user_favourite_program"
						onClick={() => {
							props.redirect(
								`programs/${favouriteProgram.urlName}`
							);
							triggerEvent({
								category: "Button",
								action: "Goto program page",
								label: "from favorite programs panel",
							});
						}}
					>
						<SmallProg
							className="favourite_program_pic"
							program={favouriteProgram}
						/>
						<span>{favouriteProgram.officialName}</span>
					</div>
				);
			})}
			<div className="favourite_program_button_container">
				<button
					className="main-button"
					onClick={() => {
						props.redirect(`user/favorites`);
						triggerEvent({
							category: "Button",
							action: "Goto All Favorite Programs page",
							label: "from favorite programs panel",
						});
					}}
				>
					ყველას ნახვა
				</button>
			</div>
		</div>
	);
};

interface ILeftNavFrameProps {
	title: string;
}

const LeftNavFrame: React.FC<ILeftNavFrameProps> = props => (
	<div className="user_nav_frame">
		<div className="header">{props.title}</div>
		<div className="body">{props.children}</div>
	</div>
);

interface IUserSubscribedUnisList {
	uniList: ISubscribedUnis;
	unis: IRUniversity[];
	redirect: (address: string) => void;
}

const UserSubscribedUnisList: React.FC<IUserSubscribedUnisList> = React.memo(
	props => {
		if (!props.unis) return null;
		const { unis } = props;
		return (
			<div className="unis_list">
				{[
					Object.keys(props.uniList).map(uni_id => {
						const uni = unis.find(u => u.id === +uni_id);
						if (!uni) return null;
						return (
							<UniLogo
								key={uni_id}
								className="uni_logo"
								logoUrl={uni.logoUrl}
								logoBGColor={uni.logoBGColor}
								onClick={() => {
									props.redirect(`/unis/${uni.urlName}`);
									triggerEvent(
										{
											category: "Button",
											action: "Goto Uni page",
											label: "from subscribed unis panel",
										},
										{ uni_id }
									);
								}}
							/>
						);
					}),
				]}
			</div>
		);
	}
);

interface IPremiumUnisListProps {
	unis: IRUniversity[];
	redirect: (address: string) => void;
}

const PremiumUnisList: React.FC<IPremiumUnisListProps> = React.memo(props => {
	if (!props.unis) return null;
	return (
		<div className="unis_list">
			{[
				props.unis
					.filter(uni => uni.premium)
					.slice(0, 8)
					.map(uni => {
						return (
							<UniLogo
								key={uni.id}
								className="uni_logo"
								logoUrl={uni.logoUrl}
								logoBGColor={uni.logoBGColor}
								onClick={() => {
									props.redirect(`/unis/${uni.urlName}`);
									triggerEvent(
										{
											category: "Button",
											action: "Goto Uni page",
											label: "from premium unis panel",
										},
										{ uni_id: uni.id }
									);
								}}
							/>
						);
					}),
			]}
			<div style={{ height: 5 }} />
			<button
				className="main-button"
				onClick={() => {
					props.redirect(`/unis`);
					triggerEvent({
						category: "Button",
						action: "Goto All Unis page",
						label: "from premium unis panel",
					});
				}}
			>
				ყველას ნახვა
			</button>
		</div>
	);
});

interface IUserEventProps {
	event: IEvent;
	unis?: IRUniversity[];
	is_reserved: boolean;
	onClick: (id: number, is_reserved: boolean, label: string) => void;
	redirect: (address: string) => void;
}

export class UserEvent extends React.Component<IUserEventProps> {
	state = {
		openGallery: false,
		galleryIndex: 0,
		showFullText: false,
	};

	openGallery = () => this.setState({ openGallery: true });
	closeGallery = () => this.setState({ openGallery: false });

	render() {
		if (!this.props.unis) return null;
		const uni = this.props.unis.find(
			university => university.id === this.props.event.uni_id
		);
		if (!uni) return null;
		const start_date = new Date(this.props.event.start_time);
		const end_date = this.props.event.end_time
			? new Date(this.props.event.end_time)
			: null;

		const HTMLtext = this.state.showFullText
			? this.props.event.text
			: htmlSubstring(
					this.props.event.text,
					370,
					this.props.event.text.length > 370 ? "..." : ""
			  );

		const { openGallery } = this.state;
		const { galleryMedias } = this.props.event;

		const prependZero = (num: number) =>
			num >= 0 && num < 10 ? 0 + "" + num : num;
		const getFormattedOClock = (date: Date) => {
			const oClock =
				prependZero(date.getHours()) +
				":" +
				prependZero(date.getMinutes());
			if (oClock === "00:00") return "";
			return ", " + oClock;
		};
		const gerFormattedDate = (date: Date) =>
			date.getDate() + " " + monthNamesGeo[date.getMonth()];
		const onSeeDetailsClick = () => {
			triggerEvent(
				{
					category: "Event",
					action: "show full event",
					label: "newsfeed",
				},
				{ event_id: this.props.event.id, uni_id: uni.id }
			);
			this.setState({
				showFullText: !this.state.showFullText,
			});
		};
		return (
			<div className="user_event_block">
				{openGallery && galleryMedias !== null && (
					<GalleryLightbox
						type="NewsAndEvents"
						galleryMedias={galleryMedias}
						onClose={this.closeGallery}
						defaultMediaIndex={this.state.galleryIndex}
					/>
				)}
				<div className="left_side">
					<UniLogo
						className="event_picture"
						logoUrl={uni.logoUrl}
						logoBGColor={uni.logoBGColor}
						onClick={() => {
							this.props.redirect(`unis/${uni.urlName}`);
							triggerEvent(
								{
									category: "Button",
									action: "Goto Uni page",
									label: "from newsfeed event box",
								},
								{ uni_id: uni.id, redirected_from: "logo" }
							);
						}}
					/>
					<DateCalendar date={start_date} showSticks={false} />
				</div>
				<div className="right_side">
					<h2
						onClick={() => {
							this.props.redirect(`unis/${uni.urlName}`);
							triggerEvent(
								{
									category: "Button",
									action: "Goto Uni page",
									label: "from newsfeed event box",
								},
								{ uni_id: uni.id, redirected_from: "title" }
							);
						}}
					>
						{uni.name}
					</h2>
					<h3>{this.props.event.title}</h3>
					<div className="event_time_and_location">
						<p>
							<b>დრო: </b>
							<span className="time">
								{gerFormattedDate(start_date) +
									getFormattedOClock(start_date)}
								{end_date &&
									" — " +
										gerFormattedDate(end_date) +
										getFormattedOClock(end_date)}
							</span>
						</p>
						<p>
							<b>ადგილი: </b>
							<span className="location">
								{this.props.event.location}
							</span>
						</p>
					</div>
					<div
						className="event_text"
						dangerouslySetInnerHTML={{ __html: HTMLtext }}
					/>
					{this.props.event.text.length > 350 && (
						<span
							className="seeDetails"
							onClick={onSeeDetailsClick}
						>
							{this.state.showFullText
								? "ნაკლების ნახვა"
								: "სრულად ნახვა"}
						</span>
					)}
					{galleryMedias !== null && galleryMedias.length > 0 && (
						<div className="gallery">
							<GalleryPreview
								onClick={this.openGallery}
								gallery={galleryMedias}
							/>
						</div>
					)}
					<div className="event_button_container">
						{this.props.event.start_time > new Date() &&
							(this.props.is_reserved ? (
								<button
									className="main-button reserved"
									onClick={() =>
										this.props.onClick(
											this.props.event.id,
											true,
											"newsfeed"
										)
									}
								>
									<div className="main-button-content">
										დაჯავშნილია
										<CheckIcon />
									</div>
								</button>
							) : (
								<button
									className="main-button"
									onClick={() =>
										this.props.onClick(
											this.props.event.id,
											false,
											"newsfeed"
										)
									}
								>
									დაჯავშნა
								</button>
							))}
					</div>
				</div>
			</div>
		);
	}
}

interface IUserNewsProps {
	news: INews;
	unis?: IRUniversity[];
	redirect: (address: string) => void;
}

export class UserNews extends React.PureComponent<IUserNewsProps> {
	state = {
		showFull: false,
		openGallery: false,
		galleryIndex: 0,
	};

	openGallery = (index = 0) =>
		this.setState({ openGallery: true, galleryIndex: index });
	closeGallery = () => this.setState({ openGallery: false });

	render() {
		if (!this.props.unis) return null;
		const uni = this.props.unis.find(
			university => university.id === this.props.news.uni_id
		);
		if (!uni) return null;

		const { showFull, openGallery } = this.state;
		const { galleryMedias } = this.props.news;
		const newsTextLength = this.props.news.text.replace(/<[^>]*>/gi, "")
			.length;
		let newsTextTrimmed = htmlSubstring(this.props.news.text, 400, true);
		const newsTextLengthAfterTrim = newsTextTrimmed.replace(/<[^>]*>/gi, "")
			.length;
		while (newsTextTrimmed.match(/<[a-zA-Z][\s\w"=-]*><\/[a-zA-Z]*>/gi)) {
			newsTextTrimmed = newsTextTrimmed.replace(
				/<[a-zA-Z][\s\w"=-]*><\/[a-zA-Z]*>/gi,
				""
			);
		}

		const showFullText = () => {
			triggerEvent(
				{
					category: "News",
					action: "show full news",
					label: "newsfeed",
				},
				{ news_id: this.props.news.id, uni_id: uni.id }
			);
			this.setState({ showFull: !this.state.showFull });
		};

		return (
			<div className="user_news_block">
				{openGallery && galleryMedias !== null && (
					<GalleryLightbox
						type="NewsAndEvents"
						galleryMedias={galleryMedias}
						onClose={this.closeGallery}
						defaultMediaIndex={this.state.galleryIndex}
					/>
				)}
				<div className="left_side">
					<UniLogo
						className="news_picture"
						logoUrl={uni.logoUrl}
						logoBGColor={uni.logoBGColor}
						onClick={() => {
							this.props.redirect(`unis/${uni.urlName}`);
							triggerEvent(
								{
									category: "Button",
									action: "Goto Uni page",
									label: "from newsfeed news box",
								},
								{ uni_id: uni.id, redirected_from: "logo" }
							);
						}}
					/>
				</div>
				<div className="right_side">
					<h2
						onClick={() => {
							this.props.redirect(`unis/${uni.urlName}`);
							triggerEvent(
								{
									category: "Button",
									action: "Goto Uni page",
									label: "from newsfeed news box",
								},
								{ uni_id: uni.id, redirected_from: "title" }
							);
						}}
					>
						{uni.name}
					</h2>
					<div
						className="news_text"
						dangerouslySetInnerHTML={{
							__html:
								showFull ||
								newsTextLength <= newsTextLengthAfterTrim
									? this.props.news.text
									: newsTextTrimmed,
						}}
					/>
					{newsTextLength > newsTextLengthAfterTrim && (
						<div className="news_button_container">
							<span className="seeDetails" onClick={showFullText}>
								{!showFull ? "სრულად ნახვა" : "ნაკლების ნახვა"}
							</span>
						</div>
					)}
					{this.props.news.galleryMedias !== null &&
						this.props.news.galleryMedias.length > 0 && (
							<div className="gallery">
								<GalleryPreview
									onClick={this.openGallery}
									gallery={this.props.news.galleryMedias}
								/>
							</div>
						)}
				</div>
			</div>
		);
	}
}

interface IGalleryPreviewProps {
	gallery: IUniContent["galleryMedias"];
	onClick: (index: number) => void;
}

const GalleryPreview: React.FC<IGalleryPreviewProps> = props => {
	if (props.gallery !== null && props.gallery.length > 0) {
		const mainImgStyle = {
			width: "100%",
			display: "block",
		};

		const firstMedia = props.gallery[0];
		if (props.gallery.length === 1) {
			if (firstMedia.type === "PHOTO") {
				return (
					<div style={{ position: "relative", marginTop: "10px" }}>
						<img
							onClick={() => props.onClick(0)}
							style={mainImgStyle}
							src={getNewsOrEventMediaUrl(
								firstMedia.type,
								firstMedia.url
							)}
							alt="img"
						/>
					</div>
				);
			}
			return (
				<YouTube
					videoId={extractVideoIdFromYoutubeLink(firstMedia.url)}
				/>
			);
		}
		if (
			props.gallery.length > 1 &&
			(props.gallery[1].type === "PHOTO" ||
				props.gallery[1].type === "VIDEO")
		) {
			const secondStyle = {
				width: "30%",
				height: "30%",
				position: "absolute" as "absolute",
				bottom: "0px",
				right: "0px",
				background: `url(${getNewsOrEventMediaUrl(
					props.gallery[1].type,
					props.gallery[1].url
				)}) center/cover no-repeat`,
			};
			const flexStyle = {
				display: "flex",
				width: "100%",
				height: "100%",
				color: "white",
				backgroundColor: "rgba(0, 0, 0, 0.7)",
				fontSize: "50px",
				justifyContent: "center",
				alignItems: "center",
			};

			return (
				<div style={{ position: "relative", marginTop: "10px" }}>
					{firstMedia.type === "PHOTO" && (
						<img
							onClick={() => props.onClick(0)}
							style={mainImgStyle}
							src={getNewsOrEventMediaUrl(
								firstMedia.type,
								firstMedia.url
							)}
							alt="img"
						/>
					)}
					{firstMedia.type === "VIDEO" && (
						<YouTube
							videoId={extractVideoIdFromYoutubeLink(
								firstMedia.url
							)}
						/>
					)}
					<div style={secondStyle}>
						<div onClick={() => props.onClick(1)} style={flexStyle}>
							<p>+{props.gallery.length - 1}</p>
						</div>
					</div>
				</div>
			);
		}
	}
	return null;
};

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis,
	programs: state.programs,
	favoritePrograms: state.favoritePrograms,
	subscribedUnis: state.subscribedUnis,
	user_reserved_events: state.temp.user_reserved_events,
	user_events: state.temp.user_events,
	user_news: state.temp.user_news,
	userEventsLoadTime: state.temp.userEventsLoadTime,
	userNewsLoadTime: state.temp.userNewsLoadTime,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({
		LoadEvents,
		loadUserReservedEvents,
		reserveEvent,
		unReserveEvent,
		LoadNews,
	} as unknown) as IDispatchProps
)(UserCustomHomePage);
