import * as React from "react";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { LatoriSuggestedProgram } from "../program-suggestion";
import { areScoresPassingLimits } from "../../functions";
import triggerEvent from "@app/utils/events";

interface IOwnProps {
	suggestedProgramId: number;
	selectedSubjectId: number;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

class TouSuggested extends React.PureComponent<IProps> {
	gotoProgram = (urlName: string) => {
		if (this.props.userId) {
			triggerEvent(
				{
					action: "Goto TOU suggestion",
					category: "Button",
					label: "from suggestions",
				},
				{
					userId: this.props.userId,
				}
			);
		}
		this.props.history.push("/programs/" + urlName);
	};

	render() {
		try {
			if (
				!this.props.unis ||
				!this.props.programs ||
				!this.props.contestScore ||
				!this.props.userScores
			)
				return null;
			const currentProgram = this.props.programs.find(
				program => program.id === this.props.suggestedProgramId
			);
			if (!currentProgram) return null;
			const currentUni = this.props.unis.find(
				uni => uni.id === currentProgram.uni_id
			);
			if (!currentUni) return null;
			if (!this.props.userScores[this.props.selectedSubjectId])
				return null;

			const isLimitPassed = areScoresPassingLimits(
				currentProgram.minScoreLimits,
				this.props.fixedScores || {}
			);
			if (!isLimitPassed) return null;

			return (
				<LatoriSuggestedProgram
					onMainButtonClick={() =>
						this.gotoProgram(currentProgram.urlName)
					}
					program={currentProgram}
					uni={currentUni}
					textToShow="ამ ქულებით თბილისის ღია უნივერსიტეტში შეგიძლია ისწავლო"
					titleColor="#0099cf"
					seeMainButtonColor="#0099cf"
				/>
			);
		} catch (e) {
			return null;
		}
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
	contestScore: state.lator.contestScore,
	userScores: state.lator.userScores,
	fixedScores: state.lator.fixedScores,
	userId: state.user.userId,
});

export default connect<IStateProps, any, IOwnProps>(mapStateToProps)(
	TouSuggested
);
