import { IRUniversity } from "@app/api/unis/helper-schemas";
import { UnisActions } from "@app/actions/unis";
import {
	LOAD_UNIS_ESSENTIAL_INFO,
	LOAD_ONE_UNI_ESSENTIAL_INFO,
	CHANGE_UNI_POSITION_COEFFICIENT,
	CHANGE_UNI_SETTINGS,
	ADD_NEW_UNI,
} from "../action-types";

function getNewUnisState(
	state: IStateUnis = {},
	action: UnisActions
): IStateUnis {
	if (typeof action.type === "undefined") return state;
	switch (action.type) {
		case LOAD_UNIS_ESSENTIAL_INFO:
			return {
				...state,
				info: [...action.info],
				fullyLoaded: action.fullyLoaded,
				lastUpdate: action.lastUpdate,
			};
		case LOAD_ONE_UNI_ESSENTIAL_INFO:
			if (state.fullyLoaded) return state;
			if (typeof state.info === "undefined") {
				return {
					...state,
					info: [action.info],
					fullyLoaded: false,
					lastUpdate: new Date(),
				};
			}
			const uniIndex = state.info.findIndex(uni => uni.id === action.id);
			if (uniIndex === -1) {
				return {
					...state,
					info: [...state.info, action.info],
					lastUpdate: new Date(),
				};
			}
			return {
				...state,
				info: state.info
					.map(uni => (uni.id === action.id ? action.info : uni))
					.sort(
						(a, b) =>
							a.pagePositionCoefficient -
							b.pagePositionCoefficient
					),
				lastUpdate: new Date(),
			};
		case CHANGE_UNI_POSITION_COEFFICIENT:
			return {
				...state,
				info: state.info!.map(uni => {
					if (uni.id !== action.uni_id) return uni;
					return {
						...uni,
						pagePositionCoefficient: action.newCoefficient,
					};
				}),
			};

		case CHANGE_UNI_SETTINGS:
			return {
				...state,
				info: state.info!.map(uni => {
					if (uni.id !== action.uni_id) return uni;
					return { ...uni, ...action.info, ...action.info.mainInfo };
				}),
			};
		case ADD_NEW_UNI:
			console.log(action.newUni);
			return {
				...state,
				info: [...(state.info || []), action.newUni],
			};
		default:
			return state;
	}
}

export default function unis(state = {}, action: UnisActions): IStateUnis {
	const newState = getNewUnisState(state, action);
	if (newState !== state) {
		localStorage.setItem("unis", JSON.stringify(newState));
	}
	return newState;
}

export interface IStateUnis {
	info?: IRUniversity[];
	fullyLoaded?: boolean;
	lastUpdate?: Date;
}
