import Joi, { SchemaToType } from "@app/utils/joi";
import { EventSchema } from "./helpher-schemas";
import { GalleryMediaSchema } from "../helpher-schemas";

///

export const AGETSearchSchema = Joi.object().keys({
	uni_id: Joi.number().required(),
});
export type IAGETSearch = SchemaToType<typeof AGETSearchSchema>;

export const RGETSearchSchema = Joi.object().keys({
	events: Joi.array()
		.items(EventSchema)
		.required(),
});
export type IRGETSearch = SchemaToType<typeof RGETSearchSchema>;
export type IGETEvents = IRGETSearch;

///

export const AGETGetOneSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});
export type IAGETGetOne = SchemaToType<typeof AGETGetOneSchema>;

export const RGETGetOneSchema = Joi.object().keys({
	event: EventSchema,
});
export type IRGETGetOne = SchemaToType<typeof RGETGetOneSchema>;

///

export const RGETAllSchema = Joi.array().items(EventSchema);
export type IRGETAll = SchemaToType<typeof RGETAllSchema>;

///

export const ADELETEDeleteSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	id: Joi.number()
		.integer()
		.required(),
});
export type IADELETEDelete = SchemaToType<typeof ADELETEDeleteSchema>;

export type IRDELETEDelete = void;

///

export const APUTSaveSchema = Joi.object().keys({
	id: Joi.number().integer(),
	uni_id: Joi.number()
		.integer()
		.required(),
	eventSettings: Joi.object()
		.keys({
			title: Joi.string(),
			text: Joi.string(),
			location: Joi.string().allow(null),
			galleryMedias: Joi.array()
				.items(GalleryMediaSchema)
				.allow(null),
			start_time: Joi.date(),
			end_time: Joi.date().allow(null),
			created_at: Joi.date(),
			updated_at: Joi.date(),
		})
		.required(),
});
export type IAPUTSave = SchemaToType<typeof APUTSaveSchema>;

export const RPUTSaveSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});
export type IRPUTSave = SchemaToType<typeof RPUTSaveSchema>;

///

export const APOSTReserveSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});
export type IAPOSTReserve = SchemaToType<typeof APOSTReserveSchema>;

export type IRPOSTReserve = void;

///

export const RGETReservationsSchema = Joi.array().items(Joi.number().integer());
export type IRGETReservations = SchemaToType<typeof RGETReservationsSchema>;

///

export const APOSTNewsImagesSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAPOSTNewsImages = SchemaToType<typeof APOSTNewsImagesSchema>;

export const RPOSTNewsImagesSchema = Joi.object().keys({
	files: Joi.array()
		.items({
			fileName: Joi.string().required(),
			fileFullUrl: Joi.string().required(),
		})
		.required(),
});
export type IRPOSTNewsImages = SchemaToType<typeof RPOSTNewsImagesSchema>;

///

export const AGETExportSheetSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAGETExportSheet = SchemaToType<typeof AGETExportSheetSchema>;
