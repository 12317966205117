import * as React from "react";
import { monthNamesGeo } from "@app/commonJavascript";
import "./styles/date_calendar.min.css";

interface IProps {
	date: Date;
	showSticks?: boolean;
	hideShadow?: boolean;
}

class DateCalendar extends React.PureComponent<IProps> {
	render() {
		return (
			<div className="date_calendar_and_shadow_container">
				<div className="date_calendar_whole">
					{this.props.showSticks && (
						<React.Fragment>
							<div className="stick left">
								<div className="shadow" />
							</div>
							<div className="stick right">
								<div className="shadow" />
							</div>
						</React.Fragment>
					)}
					<div className="date_calendar_month">
						{monthNamesGeo[
							new Date(this.props.date).getMonth()
						].substr(0, 3)}
					</div>
					<div className="date_calendar_date">
						{new Date(this.props.date).getDate()}
					</div>
				</div>
				{!this.props.hideShadow && (
					<div className="date_calendar_shadow" />
				)}
			</div>
		);
	}
}

export default DateCalendar;
