import { getUnisAndPrograms, IGetUnisAndPrograms } from "@app/actions/unis";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import { getFormattedMessage } from "@app/utils/locale";
import * as SearchWorker from "@app/workers/SearchWorker";
import { History } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { MobileAd170 } from "./dev/ads";
import Header from "./Header";
import BigSearchContent from "./MainPageComponents/BigSearchContent";
import {
	ProgramList,
	ProgramListByIds,
} from "./MainPageComponents/ProgramList";
import {
	detailedSearchPrograms,
	detailedSearchUnis,
	sortUnisByCoeff,
} from "./MainPageComponents/search/searchAlgorithms";
import UniGrid, { UniCard, UniListByIds } from "./MainPageComponents/UniList";
import { UniExpoHomepageButton } from "./uni-expo/catalog-main-page-buttons/uni-expo-main-page-buttons";
import UserCustomHomePage from "./UserCustomHomePage";

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	getUnisAndPrograms: IGetUnisAndPrograms;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

export interface IUnisDeailedSearch {
	tab: "unis";
	sector: number;
	city: number;
	field: number;
}
export interface IProgramsDeailedSearch {
	tab: "programs";
	sector: number;
	city: number;
	field: number;
	subjects: number[];
	filterByScore: boolean;
	prices: [number, number];
	subjScores: number[];
}
export type FOnDetailedSearch = (
	settings: IUnisDeailedSearch | IProgramsDeailedSearch
) => void;
export type FOnInputSeach = (query: string, enterKey: boolean) => void;
export interface ISearchInputRef {
	getInputQueryValue: () => string;
	[key: string]: any;
}

type IState = {
	showSearchResults: boolean;
	activeTab?: "unis" | "programs";
} & (
	| { searchResultType?: "unis"; SearchResults: IRUniversity[] }
	| { searchResultType?: "programs"; SearchResults: IRProgram[] }
	| {
			searchResultType?: "mixed";
			SearchResults: { unis: number[]; programs: number[] };
	  }
);

const displayFoundTitle = (
	unisLength: number,
	programsLength: number
): string => {
	if (unisLength > 0 && programsLength > 0)
		return getFormattedMessage("searchResults.foundBoth", {
			unisLength,
			programsLength,
		});
	if (unisLength > 0)
		return getFormattedMessage("searchResults.foundUnis", { unisLength });
	if (programsLength > 0)
		return getFormattedMessage("searchResults.foundPrograms", {
			programsLength,
		});
	return getFormattedMessage("searchResults.notFound");
};

class MainPage extends React.Component<IProps, IState> {
	_isMounted: boolean;

	state = {
		showSearchResults: false,
	} as IState;

	lastQuerySearchInfo = {
		lastSearchDate: new Date(0),
		lastChangeDate: new Date(0),
		lastSearchedQuery: "",
		lastQuery: "",
	};

	enableUniExpo = false; // TODO: change this to true when expo starts

	onSearchResult = data => {
		if (!this._isMounted) return;
		if (
			typeof data.unis !== "undefined" &&
			typeof data.programs !== "undefined"
		) {
			const locale = getLocale();
			this.setState({
				searchResultType: "mixed",
				SearchResults: {
					unis: data.unis.filter(
						uni => uni.locale === locale || "ka"
					),
					programs: data.programs,
				},
				showSearchResults: true,
			});
		}
	};

	componentDidMount() {
		this._isMounted = true;
		if (!this.props.unisFullyLoaded || !this.props.programsFullyLoaded) {
			this.props.getUnisAndPrograms().then(({ unis, programs }) => {
				this.addSearchListener(unis, programs);
			});
		}
		if (this.props.unisFullyLoaded && this.props.programsFullyLoaded) {
			this.addSearchListener();
		}
	}

	addSearchListener = (unis?: IRUniversity[], programs?: IRProgram[]) => {
		if (!((unis && programs) || (this.props.unis && this.props.programs)))
			return;
		SearchWorker.addListener(this.onSearchResult);
		if (unis && programs) {
			SearchWorker.loadUnisAndPrograms(unis, programs);
		} else if (this.props.unis && this.props.programs) {
			SearchWorker.loadUnisAndPrograms(
				this.props.unis,
				this.props.programs
			);
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
		SearchWorker.removeListener(this.onSearchResult);
	}

	onSeachInputChange: FOnInputSeach = (
		query: string,
		forceSearch: boolean
	) => {
		let searchNow: boolean = forceSearch;
		const tresholdTime = 1 * 1000;
		const tresholdCharacterChangeLength = 10;
		if (this.lastQuerySearchInfo.lastSearchedQuery === query) {
			return;
		}
		this.lastQuerySearchInfo.lastQuery = query;
		this.lastQuerySearchInfo.lastChangeDate = new Date();
		if (!forceSearch) {
			if (
				Math.abs(
					this.lastQuerySearchInfo.lastSearchedQuery.length -
						query.length
				) > tresholdCharacterChangeLength
			) {
				searchNow = true;
			} else if (
				Date.now() -
					this.lastQuerySearchInfo.lastChangeDate.getTime() >=
					tresholdTime &&
				this.lastQuerySearchInfo.lastChangeDate.getTime() !== 0
			) {
				searchNow = true;
			} else {
				const cachedQuery = query;
				setTimeout(() => {
					const currentQueryValue = this.lastQuerySearchInfo
						.lastQuery;
					if (currentQueryValue !== cachedQuery) return;
					this.onSeachInputChange(currentQueryValue, true);
				}, Math.max(0, tresholdTime - (Date.now() - this.lastQuerySearchInfo.lastChangeDate.getTime())));
			}
		}
		if (searchNow) {
			if (query) {
				SearchWorker.query(query, "unisAndPrograms");
				triggerEvent(
					{
						category: "Search",
						action: "Type in searchbar",
						label: "",
					},
					{
						query,
					}
				);
			} else {
				this.setState({
					showSearchResults: false,
				});
			}
			this.lastQuerySearchInfo = {
				lastSearchDate: new Date(),
				lastChangeDate: new Date(),
				lastQuery: query,
				lastSearchedQuery: query,
			};
		}
	};

	onDetailedSearch: FOnDetailedSearch = (
		settings: IUnisDeailedSearch | IProgramsDeailedSearch
	) => {
		if (settings.tab === "unis") {
			if (!this.props.unis) return;
			this.setState({
				SearchResults: sortUnisByCoeff(
					detailedSearchUnis(this.props.unis, settings)
				),
				showSearchResults: true,
				searchResultType: "unis",
			});
		} else {
			if (!this.props.programs) return;
			this.setState({
				SearchResults: detailedSearchPrograms(
					this.props.programs,
					settings
				),
				showSearchResults: true,
				searchResultType: "programs",
			});
		}
		return;
	};

	onSearchBarToggle = (show: boolean, tab?: "unis" | "programs") => {
		if (show === false) {
			if (show === false) {
				this.setState({
					showSearchResults: false,
					activeTab: tab,
				});
			}
		} else {
			this.setState({
				showSearchResults: false,
				activeTab: undefined,
			});
		}
	};

	returnToUserHome = () => {
		const { pathname } = this.props.history.location;
		if (
			pathname === "/unis/" ||
			pathname === "/unis" ||
			this.props.history.location.search !== ""
		) {
			this.props.history.push("/");
		} else {
			this.props.history.replace("/");
		}
		this.setState({
			activeTab: undefined,
		});

		triggerEvent(
			{
				category: "Button",
				action: "Return to news feed button",
				label: "",
			},
			{ pathname }
		);
	};

	render() {
		const { pathname } = this.props.history.location;
		const isLogged = !!this.props.user.loggedIn;
		let showUserHomePage = isLogged;
		const locale = getLocale();

		if (this.state.showSearchResults) showUserHomePage = false;
		if (
			pathname === "/unis/" ||
			pathname === "/unis" ||
			this.props.history.location.search !== "" ||
			(pathname === "/" && locale !== "ka")
		)
			showUserHomePage = false;
		if (this.state.activeTab === "unis") showUserHomePage = false;

		return (
			<>
				<Header history={this.props.history} />
				{!!showUserHomePage && (
					<UserCustomHomePage history={this.props.history} />
				)}
				<BigSearchContent
					history={this.props.history}
					onDetailedSearch={this.onDetailedSearch}
					onSearchBarToggle={this.onSearchBarToggle}
					onSeachInputChange={this.onSeachInputChange}
				/>

				{!showUserHomePage && !this.state.showSearchResults && (
					<>
						<div style={{ textAlign: "center", marginTop: 20 }}>
							{/* <Ad2 style={{ width: "98%" }} /> */}
							<MobileAd170
								style={{
									borderRadius: 14,
								}}
								className={"mobile600"}
							/>
						</div>
						{this.enableUniExpo && (
							<div
								style={{
									marginTop: "20px",
									width: "100%",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<UniExpoHomepageButton
									history={this.props.history}
								/>
							</div>
						)}
						<UniGrid history={this.props.history} />
					</>
				)}
				{!showUserHomePage &&
					this.state.showSearchResults &&
					this.state.searchResultType === "unis" && (
						<div className="UniListContainer">
							<div className="main main2">
								<h1 className="uniHeaderTitle">
									ნაპოვნია {this.state.SearchResults.length}{" "}
									უნივერსიტეტი
								</h1>
								<div className="uniCardsGrid">
									{this.state.SearchResults.map(uni => (
										<UniCard
											key={uni.id}
											uni={uni}
											onClick={() =>
												this.props.history.push(
													"/unis/" + uni.urlName
												)
											}
										/>
									))}
								</div>
							</div>
						</div>
					)}
				{!showUserHomePage &&
					this.state.showSearchResults &&
					this.state.searchResultType === "programs" &&
					!!this.props.unis && (
						<div className="UniListContainer">
							<div className="main main2">
								<h1 className="uniHeaderTitle">
									ნაპოვნია {this.state.SearchResults.length}{" "}
									ფაკულტეტი
								</h1>
								<ProgramList
									history={this.props.history}
									programs={this.state.SearchResults}
									unis={this.props.unis}
								/>
							</div>
						</div>
					)}
				{!showUserHomePage &&
					this.state.showSearchResults &&
					this.state.searchResultType === "mixed" && (
						<div className="UniListContainer">
							<div className="main main2">
								<h1 className="uniHeaderTitle">
									{displayFoundTitle(
										this.state.SearchResults.unis.length,
										this.state.SearchResults.programs.length
									)}
								</h1>
								{this.state.SearchResults.unis.length > 0 &&
									!!this.props.unis && (
										<div className="uniCardsGrid">
											<UniListByIds
												uni_ids={
													this.state.SearchResults
														.unis
												}
												unis={this.props.unis}
												history={this.props.history}
											/>
										</div>
									)}
								{this.state.SearchResults.programs.length > 0 &&
									!!this.props.programs &&
									!!this.props.unis && (
										<div className="programCardsLists">
											<ProgramListByIds
												history={this.props.history}
												unis={this.props.unis}
												programIds={
													this.state.SearchResults
														.programs
												}
												programs={this.props.programs}
											/>
										</div>
									)}
							</div>
						</div>
					)}
				{!!showUserHomePage && (
					<UserCustomHomePage history={this.props.history} />
				)}
			</>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	unisFullyLoaded: state.unis.fullyLoaded === true,
	programs: state.programs.info,
	programsFullyLoaded: state.programs.fullyLoaded === true,
	user: state.user,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({
		getUnisAndPrograms,
	} as unknown) as IDispatchProps
)(MainPage);
