import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import triggerEvent from "@app/utils/events";
import { addSubjectsAction } from "@app/actions/lator";
import { connect } from "react-redux";
import { erovnulSubjects } from "@app/commonJavascript";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { loadUserLatorInfo } from "@app/actions/lator";
import "./styles/ChooseSubjectsPage.min.css";
import "./styles/styles.min.css";
import { History } from "history";

interface IOwnProps {
	history?: History;
}
type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	selectedSubjectIds: number[];
}

class ChooseSubjects extends React.Component<IProps, IState> {
	state: IState = {
		selectedSubjectIds: [
			...new Set([
				...(this.props.subjectIds || []),
				...erovnulSubjects
					.filter(subj => subj.isCompulsory)
					.map(subj => subj.id),
			]),
		],
	};

	componentDidMount() {
		this.props.loadUserLatorInfo().then();
	}

	onSubjectSelect = (checked: boolean, subjectId: number) => {
		if (checked) {
			this.setState({
				selectedSubjectIds: [
					...this.state.selectedSubjectIds,
					subjectId,
				],
			});
		} else {
			this.setState({
				selectedSubjectIds: this.state.selectedSubjectIds.filter(
					subjId => subjId !== subjectId
				),
			});
		}
	};

	onSave = () => {
		if (this.state.selectedSubjectIds.length === 0) {
			alert("აირჩიეთ მინიმუმ 1 საგანი");
			return;
		}
		// FIXME: every subject that have been chosen before must be deleted from redux state
		this.props.addSubjectsAction(this.state.selectedSubjectIds);
		triggerEvent(
			{
				category: "Button",
				action: "Lator: choose subjects",
				label: "",
				value: this.state.selectedSubjectIds.length,
			},
			{ numSelected: this.state.selectedSubjectIds.length }
		);
		if (
			this.props.history &&
			this.props.history.location.pathname === "/latori/choose-subjects"
		)
			this.props.history.goBack();
	};

	render() {
		return (
			<div className="chooseSubjectsContainer">
				<h2>აირჩიეთ ჩასაბარებელი საგნები</h2>
				{erovnulSubjects
					.sort(
						(subj1, subj2) =>
							subj1.displayPriority - subj2.displayPriority
					)
					.map(subject => {
						const isChecked =
							this.state.selectedSubjectIds.indexOf(subject.id) >=
							0;
						return (
							<React.Fragment key={subject.id}>
								<div
									style={{
										cursor: "pointer",
										display: "inline-block",
									}}
									onClick={() =>
										!subject.isCompulsory &&
										this.onSubjectSelect(
											!isChecked,
											subject.id
										)
									}
								>
									<Checkbox
										checked={isChecked}
										color="primary"
									/>
									{subject.name}
								</div>
								<br />
							</React.Fragment>
						);
					})}
				<button className="latoriPrimaryButton" onClick={this.onSave}>
					დასტური
				</button>
			</div>
		);
	}
}

const mapDispatchToProps = {
	addSubjectsAction,
	loadUserLatorInfo,
};

const mapStateToProps = (state: IRootState) => ({
	subjectIds: state.lator.subjectIds,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(ChooseSubjects);
