import React from "react";

const SvgTransCircle = props => (
  <svg viewBox="0 0 320 160" {...props}>
    <path
      d="M310 0c0 82.8-67.2 150-150 150S10 82.8 10 0H0v160h320V0h-10z"
      fill="#fff"
    />
  </svg>
);

export default SvgTransCircle;
