import Joi, { SchemaToType } from "@app/utils/joi";

export const RLatoriProgramSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	officialName: Joi.string().required(),
	subjects: Joi.array()
		.items(Joi.array().items(Joi.number().integer()))
		.required(),
	grant_subjects: Joi.array()
		.items(Joi.number().integer())
		.allow(null),
	numsBySubjs: Joi.array()
		.items(Joi.number().integer())
		.allow(null)
		.required(),
	numsBySubjCombs: Joi.object()
		.pattern(/^[0-9,]*$/, Joi.number().required())
		.allow(null)
		.optional(),
	coefficients: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	minScoreLimits: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	minScores: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	medianScores: Joi.array()
		.items(Joi.number())
		.allow(null)
		.required(),
	yearOfUpdatedInfo: Joi.number()
		.integer()
		.allow(null)
		.required(),
	// created_at: Joi.date().required(),
	// updated_at: Joi.date().required(),
});
export type IRLatoriProgram = SchemaToType<typeof RLatoriProgramSchema>;
