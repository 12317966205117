import Joi, { SchemaToType } from "@app/utils/joi";

export const RGETSubscribes = Joi.object().pattern(
	/^[0-9]*$/,
	Joi.object().keys({
		uni_id: Joi.number()
			.integer()
			.required(),
		time: Joi.date().required(),
	})
);
export type IRGETSubscribes = SchemaToType<typeof RGETSubscribes>;
export type ISubscribedUnis = IRGETSubscribes;

export const APUTSubscribesSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAPUTSubscribes = SchemaToType<typeof APUTSubscribesSchema>;

export type IRInsertOrUpdateUniSubscription = void;
