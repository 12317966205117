import {
	displayUniSubscriptionPopupAction,
	loadEventsByUniId,
	loadNewsByUniId,
	loadUserReservedEvents,
	reserveEvent,
	unReserveEvent,
} from "@app/actions/temp";
import api from "@app/api";
import { IEvent } from "@app/api/events/helpher-schemas";
import { INews } from "@app/api/news/validators";
import { IRProgram } from "@app/api/programs/helper-schemas";
import {
	IRUniversity,
	IUniContent,
	IUniMainInfo,
} from "@app/api/unis/helper-schemas";
import {
	animateHTMLElement,
	animateWindowScroll,
	getHTMLElementCoords,
} from "@app/commonJavascript";
import { getLocale } from "@app/hooks/intl";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import htmlSubstring from "@app/utils/html_substring";
import { FormattedMessage } from "@app/utils/locale";
import styled from "@emotion/styled";
import { History } from "history";
import memoizeOne from "memoize-one";
import * as React from "react";
import ContentLoader, { List as ListContentLoader } from "react-content-loader";
import { connect } from "react-redux";
import { throttle } from "throttle-debounce";
import { extractColorsFromGradient } from "../Admins/AdminUniEditPage/UniBackgroundType";
import { sortUniContentByPosition } from "../MainPageComponents/search/searchAlgorithms";
import { RequestConsultationButton } from "../MainPageComponents/UniList";
import requireLoginForAction from "../requireLoginForAction";
import { PremiumSidebarItemNames } from "../uni-expo/expo-uni-page";
import { joinEventsAndNews } from "../UserCustomHomePage";
import DateCalendar from "../Widgets/date_calendar";
import SnackNotification, { IVariantType } from "../Widgets/SnackNotification";
import EventPopup from "./event-popup";
import GalleryMedias from "./gallery-media";
import NewsPopup from "./news-popup";
import PremiumUniHeader from "./PremiumUniHeader";
import "./styles/premiumUni.min.css";
import UniPrograms from "./UniPrograms";

interface IOwnProps {
	history: History;
	uni_id: number;
	essentialInfo: IRUniversity;
	mainInfo?: IUniMainInfo;
	contents?: IUniContent[];
	programs?: IRProgram[];
}
type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

interface IState {
	show_popup: boolean;
	hasRequested: boolean;
	dialogOpen: boolean;
	dialogType: IVariantType;
	dialogMessage: string;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

class PremiumUniversityPage extends React.Component<IProps> {
	_isMounted = false;
	state = {
		show_popup: true,
		dialogOpen: false,
		dialogType: "info",
		dialogMessage: "",
		hasRequested: true,
	} as IState;

	loadUserReservations = memoizeOne((isLoggedIn: boolean) => {
		if (!isLoggedIn) return;
		if (
			!this.props.user_reserved_events ||
			!this.props.user_reserved_events.length
		) {
			this.props.loadUserReservedEvents().then(events => {
				if (!this._isMounted) return;
				this.setState({
					user_reserved_events: events,
				});
			});
		}
	});

	componentDidMount() {
		this.props.displayUniSubscriptionPopupAction(undefined);
		this._isMounted = true;
		const locale = getLocale();
		if (locale !== this.props.essentialInfo.locale)
			this.props.history.push("/");
		if (locale !== "ka")
			api.users
				.getRequestedConsultation({
					uni_id: this.props.uni_id,
				})
				.then(data => {
					if (!data.request) this.setState({ hasRequested: false });
				})
				.catch(e => this.setState({ hasRequested: false }));
		if (
			!this.props.events_by_unis ||
			!this.props.events_by_unis[this.props.uni_id]
		) {
			this.props
				.loadEventsByUniId(this.props.uni_id)
				.then(events_by_unis => {
					if (!this._isMounted) return;
					this.setState({
						events_by_unis,
					});
				})
				.catch(e => this.setState({ hasRequested: false }));
		}
		if (
			!this.props.news_by_unis ||
			!this.props.news_by_unis[this.props.uni_id]
		) {
			this.props.loadNewsByUniId(this.props.uni_id).then(news => {
				if (!this._isMounted) return;
				this.setState({
					news,
				});
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		if (
			this.props.subscribedUnis &&
			!this.props.subscribedUnis[this.props.uni_id]
		) {
			// leaving page without subscription
			this.props.displayUniSubscriptionPopupAction(this.props.uni_id);
		}
	}

	reserve_event = (id: number) => {
		requireLoginForAction(() => this.props.reserveEvent(id));
	};
	unreserve_event = (id: number) => {
		requireLoginForAction(() => this.props.unReserveEvent(id));
	};

	onClose = () => {
		this.setState({ show_popup: false });
	};

	onConsultationRequest = () => {
		requireLoginForAction(() => {
			api.users
				.requestConsultation({
					uni_id: this.props.uni_id,
				})
				.then(() => {
					this.setState({
						dialogOpen: true,
						dialogType: "success",
						dialogMessage: "Request was successfully sent",
						hasRequested: true,
					});
				})
				.catch(e => {
					this.setState({
						dialogOpen: true,
						dialogType: "error",
						dialogMessage: "Request could not be sent",
						hasRequested: false,
					});
				});
		});
	};

	render() {
		this.loadUserReservations(this.props.isLoggedIn);
		return (
			<div className="UniListTestPage">
				<PremiumUniHeader
					uni_id={this.props.uni_id}
					essentialInfo={this.props.essentialInfo}
					mainInfo={this.props.mainInfo}
				/>
				{getLocale() === "en" && !this.state.hasRequested && (
					<div
						style={{
							textAlign: "center",
							padding: "0px 10px",
							marginTop: 20,
						}}
					>
						<RequestConsultationButton
							style={
								this.props.essentialInfo.coverBGColor
									? {
											background: this.props.essentialInfo
												.coverBGColor,
									  }
									: undefined
							}
							onClick={this.onConsultationRequest}
						>
							Request a consultation
						</RequestConsultationButton>
					</div>
				)}
				<SnackNotification
					autoHideDuration={6000}
					onClose={() => this.setState({ dialogOpen: false })}
					message={this.state.dialogMessage}
					variant={this.state.dialogType}
					open={this.state.dialogOpen}
				/>
				<div className="pageTable main">
					<div>
						<PremiumUniSidebar
							contents={this.props.contents}
							coverBGColor={this.props.essentialInfo.coverBGColor}
							history={this.props.history}
						/>
					</div>
					<div>
						<div className="eventsAndNewsBoxContainer">
							{!!this.props.news_by_unis && (
								<PremiumUniNewsAndEvents
									events_by_unis={this.props.events_by_unis}
									news_by_unis={this.props.news_by_unis}
									user_reserved_events={
										this.props.user_reserved_events
									}
									reserve_event={this.reserve_event}
									unreserve_event={this.unreserve_event}
									uni_id={this.props.uni_id}
									coverBGColor={
										this.props.essentialInfo.coverBGColor
									}
								/>
							)}
						</div>
					</div>
					<PremiumUniDescription contents={this.props.contents} />
				</div>
				<div id="uniPrograms">
					<UniPrograms
						history={this.props.history}
						programs={this.props.programs}
					/>
				</div>
			</div>
		);
	}
}

interface IPremiumUniDescriptionProps {
	contents?: IUniContent[];
}

const sortUniContents = memoizeOne((contents?: IUniContent[]) => {
	if (!contents) return contents;
	return sortUniContentByPosition(contents);
});

function isEmprtyContent(text?: string | null) {
	if (!text) return true;
	const trimmedText = text.replace(/[\s<>/]*/g, "");
	if (trimmedText.length < 3) return true;
	return false;
}

interface IPremiumUniDescriptionState {
	isOpen: {
		[contentId: string]: boolean;
	};
}

class PremiumUniDescription extends React.PureComponent<
	IPremiumUniDescriptionProps,
	IPremiumUniDescriptionState
> {
	state: IPremiumUniDescriptionState = {
		isOpen: {},
	};

	onClick = (contentId: number) => {
		this.setState({
			isOpen: {
				...this.state.isOpen,
				[contentId]: !this.state.isOpen[contentId],
			},
		});
	};

	render() {
		if (!this.props.contents) return <ListContentLoader />;
		const contents = sortUniContents(this.props.contents);
		return (
			<div className="Description" id="description">
				{!!contents && (
					<div className="programDescriptionWrapper">
						{contents.map((content, i) => (
							<div
								className="description"
								key={i}
								id={`programContent${i}`}
							>
								<span className="descTitle">
									{content.title || "აღწერა"}
								</span>
								<div className="separatorLine" />
								{!!content.text &&
									!isEmprtyContent(content.text) && (
										<React.Fragment>
											<div
												className="descText"
												dangerouslySetInnerHTML={{
													__html: this.state.isOpen[
														content.id
													]
														? content.text
														: htmlSubstring(
																content.text,
																265
														  ),
												}}
											/>
											{content.text.length > 265 &&
												(this.state.isOpen[
													content.id
												] ? (
													<span
														style={{
															fontSize: "16px",
															cursor: "pointer",
															fontWeight: "bold",
														}}
														onClick={() =>
															this.onClick(
																content.id
															)
														}
													>
														<FormattedMessage id="uniPage.seeLess" />
													</span>
												) : (
													<span
														style={{
															fontSize: "16px",
															cursor: "pointer",
															fontWeight: "bold",
														}}
														onClick={() =>
															this.onClick(
																content.id
															)
														}
													>
														<FormattedMessage id="uniPage.seeMore" />
													</span>
												))}
											<div style={{ height: 15 }} />
										</React.Fragment>
									)}
								<GalleryMedias
									galleryMedias={content.galleryMedias}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

const NavBarLi = styled("li")(
	{
		"&:hover": {
			background: "rgba(0,0,0,0.05)",
		},
	},
	(props: { selected: boolean; coverBGColor?: string | null }) => {
		if (!props.selected) return {};
		return {
			background:
				props.coverBGColor ||
				"linear-gradient(to right, #7c169c, #f03e84) !important",
			"&:hover": {
				background:
					props.coverBGColor ||
					"linear-gradient(to right, #7c169c, #f03e84) !important",
			},
			color: "white !important",
			span: {
				borderBottomColor: "transparent !important",
			},
		};
	}
);

interface IPremiumUniSidebarProps {
	contents?: IUniContent[];
	coverBGColor?: string | null;
	history: History;
}

interface IPremiumUniSidebarState {
	position: "fixed" | "absolute";
	selectedBlock: number;
	maxHeight: number;
	navBarPositionTop: number;
	scrolled?: boolean;
}

class PremiumUniSidebar extends React.PureComponent<
	IPremiumUniSidebarProps,
	IPremiumUniSidebarState
> {
	state: IPremiumUniSidebarState = {
		position: "fixed",
		selectedBlock: -2,
		maxHeight: 0,
		navBarPositionTop: 0,
	};

	_isMounted = false;

	timesCalculated = 0;

	getOffsets = memoizeOne(
		(
			contents: IPremiumUniSidebarProps["contents"],
			bodyWidth: number,
			bodyHeight: number
		) => {
			const elementIds = [
				"uniNewsAndEvents",
				...new Array((contents || []).length)
					.fill(0)
					.map((el, index) => `programContent${index}`),
				"uniPrograms",
			];
			this.timesCalculated++;
			if (this.timesCalculated < 2) {
				setTimeout(
					() =>
						this.getOffsets(
							{ ...this.props.contents! },
							document.body.offsetWidth,
							document.body.offsetHeight
						),
					1000
				);
			}
			return elementIds.map(id => {
				const element = document.getElementById(id);
				if (!element) return Infinity;
				return getHTMLElementCoords(element).top;
			});
		}
	);

	listRef: React.RefObject<HTMLDivElement> = React.createRef();
	throttled: () => void;

	constructor(props: IPremiumUniSidebarProps) {
		super(props);
		this.throttled = throttle(20, this.onScroll);
		window.addEventListener("scroll", this.throttled);
		window.addEventListener("resize", this.throttled);
	}

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener("scroll", this.throttled);
		window.removeEventListener("resize", this.throttled);
		(this.throttled as any).cancel();
	}

	componentDidUpdate() {
		// const props = newProps;
		if (!this.state.scrolled) {
			setTimeout(() => {
				const props = this.props;
				if (!props.contents) return;
				const search = props.history.location.search.substring(1);
				const key = "content=";
				let index = search.indexOf(key);
				if (index >= 0) {
					index += key.length;
					const contentName =
						PremiumSidebarItemNames[search.substring(index)];
					if (contentName === PremiumSidebarItemNames.programs) {
						this.onClick(-1);
						this.setState({ scrolled: true });
						return;
					}
					for (let i = 0; i < props.contents.length; i++) {
						const content = props.contents[i];
						if (content.title === contentName) {
							this.onClick(i);
							this.setState({ scrolled: true });
							return;
						}
					}
				}
			}, 500);
		}
	}

	componentDidMount() {
		this._isMounted = true;
		this.onScroll();
	}

	onScroll = () => {
		if (!this._isMounted) return;
		const scrollY = window.pageYOffset;
		const offsets = this.getOffsets(
			this.props.contents,
			document.body.offsetWidth,
			document.body.offsetHeight
		);

		let {
			maxHeight,
			navBarPositionTop,
			position,
			selectedBlock,
		} = this.state;

		for (let i = 0; i < offsets.length; ++i) {
			if (offsets[i] <= scrollY + 280)
				selectedBlock =
					i === 0 ? -2 : i === offsets.length - 1 ? -1 : i - 1;
		}
		const navBar = this.listRef.current;
		if (navBar && navBar.parentElement) {
			const { top } = getHTMLElementCoords(navBar.parentElement!);
			maxHeight =
				window.innerHeight - Math.max(0, top - scrollY - 80) - 120;
			navBarPositionTop = Math.max(90, top - scrollY);
			const myTop = getHTMLElementCoords(navBar).top;
			position = "fixed";
			if (
				myTop + navBar.offsetHeight + 20 >
					offsets[offsets.length - 1] &&
				myTop + navBar.offsetHeight - 20 <
					scrollY + window.innerHeight &&
				myTop - 130 < scrollY
			) {
				position = "absolute";
				maxHeight = Math.min(
					offsets[offsets.length - 1] - 500,
					window.innerHeight - 120,
					navBar.offsetHeight
				);
				navBarPositionTop =
					offsets[offsets.length - 1] - maxHeight - 20;
			}
			if (this.state.selectedBlock !== selectedBlock) {
				try {
					const child = navBar.getElementsByTagName("li")[
						this.blockToIndex(selectedBlock)
					];
					if (child) {
						if (child.offsetTop < navBar.scrollTop) {
							animateHTMLElement(
								navBar,
								"scrollTop",
								child.offsetTop,
								50
							);
						} else if (
							child.offsetTop + child.offsetHeight >
							navBar.scrollTop + navBar.offsetHeight
						) {
							animateHTMLElement(
								navBar,
								"scrollTop",
								child.offsetTop +
									child.offsetHeight -
									navBar.offsetHeight,
								50
							);
						}
					}
				} catch (e) {
					console.error(e);
				}
			}
		}
		this.setState({
			maxHeight,
			selectedBlock,
			navBarPositionTop,
			position,
		});
	};

	blockToIndex = (block: number) => {
		const offsets = this.getOffsets(
			this.props.contents,
			document.body.offsetWidth,
			document.body.offsetHeight
		);
		return block === -2 ? 0 : block === -1 ? offsets.length - 1 : block + 1;
	};

	onClick = (block: number) => {
		const offsets = this.getOffsets(
			this.props.contents,
			document.body.offsetWidth,
			document.body.offsetHeight
		);
		const arrayIndex = this.blockToIndex(block);
		if (offsets[arrayIndex] === undefined) return;
		animateWindowScroll(offsets[arrayIndex] - 130, 200);
		triggerEvent(
			{
				category: "Button",
				action: "Choose section",
				label: "from uni panel",
			},
			{ block }
		);
	};

	render() {
		const locale = getLocale();
		return (
			<div
				className="newsList"
				style={{
					position: this.state.position,
					maxHeight: this.state.maxHeight,
					top: this.state.navBarPositionTop,
					display:
						this.state.maxHeight >= 40 &&
						this.props.contents &&
						this.props.contents.length > 1
							? "block"
							: "none",
				}}
				ref={this.listRef}
			>
				<div className="newsListBody">
					{!this.props.contents ? (
						<ContentLoader width={100} height={80}>
							<rect
								x="5"
								y="5"
								rx="4"
								ry="4"
								width="60"
								height="10"
							/>
							<rect
								x="5"
								y="25"
								rx="4"
								ry="4"
								width="90"
								height="10"
							/>
							<rect
								x="5"
								y="45"
								rx="4"
								ry="4"
								width="80"
								height="10"
							/>
							<rect
								x="5"
								y="65"
								rx="4"
								ry="4"
								width="70"
								height="10"
							/>
						</ContentLoader>
					) : (
						<ul>
							{locale === "ka" && (
								<NavBarLi
									selected={this.state.selectedBlock === -2}
									onClick={() => this.onClick(-2)}
									coverBGColor={this.props.coverBGColor}
								>
									<span>სიახლეები</span>
								</NavBarLi>
							)}
							{this.props.contents.map((textContent, index) => (
								<NavBarLi
									key={textContent.id}
									selected={
										this.state.selectedBlock === index
									}
									onClick={() => this.onClick(index)}
									coverBGColor={this.props.coverBGColor}
								>
									<span>{textContent.title || "აღწერა"}</span>
								</NavBarLi>
							))}
							{!this.props.contents ? null : (
								<NavBarLi
									selected={this.state.selectedBlock === -1}
									onClick={() => this.onClick(-1)}
									coverBGColor={this.props.coverBGColor}
								>
									<span>
										<FormattedMessage id="programs" />
									</span>
								</NavBarLi>
							)}
						</ul>
					)}
				</div>
			</div>
		);
	}
}

interface IPremiumUniNewsAndEventsProps {
	events_by_unis?: { [uni_id: string]: IEvent[] };
	news_by_unis?: { [news_id: string]: INews[] };
	uni_id: number;
	user_reserved_events?: number[];
	reserve_event: (id: number) => void;
	unreserve_event: (id: number) => void;
	coverBGColor: IRUniversity["coverBGColor"];
}
interface IPremiumUniNewsAndEventsState {
	display_events: boolean;
	display_news: boolean;
	show_event_popup_id: number;
	show_news_popup_id: number;
}

class PremiumUniNewsAndEvents extends React.PureComponent<
	IPremiumUniNewsAndEventsProps,
	IPremiumUniNewsAndEventsState
> {
	state = {
		display_events: true,
		display_news: true,
		show_event_popup_id: 0,
		show_news_popup_id: 0,
	} as IPremiumUniNewsAndEventsState;

	getSelectedEvent = memoizeOne((events: IEvent[], id: number) =>
		events.find(event => event.id === id)
	);

	getSelectedNews = memoizeOne((news: INews[], id: number) =>
		news.find(each => each.id === id)
	);

	onEventsDisplayClick = () => {
		this.setState({
			display_events: !this.state.display_events,
		});
	};
	onNewsDisplayClick = () => {
		this.setState({
			display_news: !this.state.display_news,
		});
	};
	onSeeDetailsClick = (id: number, popup_type: "event" | "news") => {
		switch (popup_type) {
			case "event":
				triggerEvent(
					{
						category: "Event",
						action: "show full event",
						label: "uniPage",
					},
					{ event_id: id, uni_id: this.props.uni_id }
				);
				this.setState({
					show_event_popup_id: id,
				});
				break;
			case "news":
				triggerEvent(
					{
						category: "News",
						action: "show full news",
						label: "uniPage",
					},
					{ news_id: id, uni_id: this.props.uni_id }
				);
				this.setState({
					show_news_popup_id: id,
				});
				break;
			default:
				break;
		}
	};
	onClose = () => {
		this.setState({
			show_event_popup_id: 0,
			show_news_popup_id: 0,
		});
	};
	onReserve = (id: number, is_reserved: boolean, label: string) => {
		if (is_reserved) {
			this.props.unreserve_event(id);
			triggerEvent(
				{
					category: "Event",
					action: "Unreserve",
					label,
				},
				{ event_id: id }
			);
		} else {
			this.props.reserve_event(id);
			triggerEvent(
				{
					category: "Event",
					action: "Reserve",
					label,
				},
				{ event_id: id }
			);
		}
	};

	render() {
		const events = this.props.events_by_unis
			? this.props.events_by_unis[this.props.uni_id]
			: undefined;
		const news = this.props.news_by_unis
			? this.props.news_by_unis[this.props.uni_id]
			: undefined;
		if (!events || !news) return null;
		const eventsAndNews = joinEventsAndNews(events, news);
		const selected_event = this.getSelectedEvent(
			events,
			this.state.show_event_popup_id
		);
		const selected_news = this.getSelectedNews(
			news,
			this.state.show_news_popup_id
		);
		const headerBackground = this.props.coverBGColor
			? { background: this.props.coverBGColor }
			: {};
		let coverBGSingleColor = "";
		if (this.props.coverBGColor) {
			const colors = extractColorsFromGradient(this.props.coverBGColor);
			if (colors) coverBGSingleColor = colors[0];
		}
		const locale = getLocale();
		return (
			<>
				{!!this.state.display_events &&
					this.state.show_event_popup_id > 0 &&
					!!selected_event && (
						<EventPopup
							event={selected_event}
							is_reserved={
								!!this.props.user_reserved_events &&
								this.props.user_reserved_events.some(
									event_id =>
										event_id ===
										this.state.show_event_popup_id
								)
							}
							onClose={this.onClose}
							onReserve={this.onReserve}
						/>
					)}
				{!!this.state.display_news &&
					!!this.state.show_news_popup_id &&
					!!selected_news && (
						<NewsPopup
							news={selected_news}
							onClose={this.onClose}
						/>
					)}
				{locale === "ka" && (
					<div className="eventsAndNewsBox" id="uniNewsAndEvents">
						<div
							className="eventsAndNewsHeader"
							style={headerBackground}
						>
							<span>
								<input
									type="Checkbox"
									checked={this.state.display_news}
									onChange={this.onNewsDisplayClick}
								/>
								<FormattedMessage id="news" />
							</span>
							<div className="checkboxVerticalSplit" />
							<span>
								<input
									type="Checkbox"
									checked={this.state.display_events}
									onChange={this.onEventsDisplayClick}
								/>
								<FormattedMessage id="events" />
							</span>
						</div>
						<div className="eventsAndUniInfo">
							{!events || !news ? (
								<ContentLoader width={100} height={45}>
									<circle cx="6" cy="6" r="5" />
									<rect
										x="13"
										y="2"
										rx="2"
										ry="2"
										width="30"
										height="3.5"
									/>
									<rect
										x="13"
										y="7"
										rx="1"
										ry="1"
										width="70"
										height="2"
									/>
									<circle cx="6" cy="17" r="5" />
									<rect
										x="13"
										y="13"
										rx="2"
										ry="2"
										width="30"
										height="3.5"
									/>
									<rect
										x="13"
										y="18"
										rx="1"
										ry="1"
										width="70"
										height="2"
									/>
									<circle cx="6" cy="28" r="5" />
									<rect
										x="13"
										y="24"
										rx="2"
										ry="2"
										width="30"
										height="3.5"
									/>
									<rect
										x="13"
										y="29"
										rx="1"
										ry="1"
										width="70"
										height="2"
									/>
									<circle cx="6" cy="39" r="5" />
									<rect
										x="13"
										y="35"
										rx="2"
										ry="2"
										width="30"
										height="3.5"
									/>
									<rect
										x="13"
										y="40"
										rx="1"
										ry="1"
										width="70"
										height="2"
									/>
								</ContentLoader>
							) : (
								<React.Fragment>
									{eventsAndNews.map(el =>
										el.type === "EVENT"
											? !!this.state.display_events && (
													<PremiumUniEventNotification
														key={
															el.id +
															" " +
															el.type
														}
														event={el}
														user_reserved_events={
															this.props
																.user_reserved_events
														}
														onClick={
															this
																.onSeeDetailsClick
														}
														onReserve={
															this.onReserve
														}
														coverBGColor={
															coverBGSingleColor
																? coverBGSingleColor
																: undefined
														}
													/>
											  )
											: el.type === "NEWS" &&
											  !!this.state.display_news && (
													<PremiumUniNewsNotification
														key={
															el.id +
															" " +
															el.type
														}
														news={el}
														onClick={
															this
																.onSeeDetailsClick
														}
													/>
											  )
									)}
								</React.Fragment>
							)}
						</div>
					</div>
				)}
			</>
		);
	}
}

interface IPremiumUniEventNotificationProps {
	event?: IEvent;
	user_reserved_events?: number[];
	onClick: (id: number, popup_type: "event" | "news") => void;
	onReserve: (event_id: number, is_reserved: boolean, label: string) => void;
	coverBGColor?: string;
}

const ReserveButton = styled("div")(
	{
		color: "#fe2f73",
		margin: "5px 5px 2px 5px",
		border: "1px solid #fe2f73",
		borderRadius: 30,
		cursor: "pointer",
		padding: "5px 13px 5px 17px",
	},
	(props: { coverBGColor: string; isReserved: boolean }) => ({
		color: props.isReserved ? "white" : props.coverBGColor,
		borderColor: props.coverBGColor,
		background: props.isReserved ? props.coverBGColor : "white",
		"&:hover":
			props.coverBGColor && !props.isReserved
				? {
						color: "white",
						background: props.coverBGColor,
				  }
				: undefined,
	})
);

const PremiumUniEventNotification: React.SFC<IPremiumUniEventNotificationProps> = props => {
	if (!props.event) return null;
	if (!props.event.text) return null;
	const isReserved =
		props.user_reserved_events &&
		props.user_reserved_events.some(
			event_id => event_id === props.event!.id
		);
	return (
		<div className="eventNotificationWrapper">
			<div className="eventNotification">
				<div className="eventPic">
					<DateCalendar
						date={props.event.start_time}
						hideShadow={true}
					/>
				</div>
				<div className="eventInfo">
					<p className="eventName">{props.event.title}</p>
					<p
						style={{ lineHeight: 2 }}
						dangerouslySetInnerHTML={{
							__html: htmlSubstring(props.event.text, 150, true),
						}}
					/>
				</div>
				<div className="eventDetails">
					<ReserveButton
						onClick={() =>
							props.onReserve(
								props.event!.id,
								!!isReserved,
								"uniPage"
							)
						}
						coverBGColor={props.coverBGColor || "#fe2f73"}
						isReserved={!!isReserved}
					>
						<span className="symbolPlus" />
						{isReserved ? "დაჯავშნილია" : "დაჯავშნა"}
					</ReserveButton>
					<div
						className="moreInfo"
						onClick={() => props.onClick(props.event!.id, "event")}
					>
						<FormattedMessage id="readMore" />
					</div>
				</div>
			</div>
			<div className="fadingLine" />
		</div>
	);
};

interface IPremiumUniNewsNotificationProps {
	news?: INews;
	onClick: (id: number, popup_type: "event" | "news") => void;
}
const PremiumUniNewsNotification: React.SFC<IPremiumUniNewsNotificationProps> = props => {
	if (!props.news || !props.news.text) return null;
	return (
		<div className="newsNotificationWrapper">
			<div className="newsNotification">
				<div className="newsPic" />
				<div className="newsInfo">
					<p
						style={{ lineHeight: 2 }}
						dangerouslySetInnerHTML={{
							__html: htmlSubstring(props.news.text, 150, true),
						}}
					/>
				</div>
				<div className="newsDetails">
					<div
						className="moreInfo"
						onClick={() => props.onClick(props.news!.id, "news")}
					>
						<FormattedMessage id="readMore" />
					</div>
				</div>
			</div>
			<div className="fadingLine" />
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isLoggedIn: !!state.user.loggedIn,
	unis: state.unis.info,
	subscribedUnis: state.subscribedUnis.subscribed,
	events_by_unis: state.temp.events_by_unis,
	user_reserved_events: state.temp.user_reserved_events,
	news_by_unis: state.temp.news_by_unis,
});

const mapDispatchToProps = {
	displayUniSubscriptionPopupAction,
	loadEventsByUniId,
	loadNewsByUniId,
	loadUserReservedEvents,
	reserveEvent,
	unReserveEvent,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(PremiumUniversityPage);
