import {
	ADD_NEW_CHATROOM,
	ADD_RECEIVED_MESSAGE,
	ADD_SENT_MESSAGE,
	ADD_TEMP_CHATROOM,
	LOAD_ADMIN_CHATROOMS_AND_MESSAGES,
	LOAD_CHATROOMS_AND_MESSAGES,
	MARK_MESSAGES_AS_SEEN,
	REPLACE_TEMP_CHATROOM,
} from "../action-types";
import { IChatroomActions } from "../actions/chatrooms";

export default function getNewChatroomsInfo(
	state: IStateChatRooms = {},
	action: IChatroomActions
): IStateChatRooms {
	if (action.type === undefined) return state;
	switch (action.type) {
		case LOAD_CHATROOMS_AND_MESSAGES:
			return {
				...state,
				info: action.info,
				lastUpdate: action.lastUpdate,
			};
		case LOAD_ADMIN_CHATROOMS_AND_MESSAGES:
			return {
				...state,
				info: {
					...state.info,
					...action.info,
				},
			};
		case ADD_SENT_MESSAGE:
			return {
				...state,
				info: {
					...state.info,
					[action.chatroomId]: {
						...state.info![action.chatroomId],
						messages: [
							...state.info![action.chatroomId].messages,
							action.message,
						],
					},
				},
			};
		case ADD_RECEIVED_MESSAGE:
			return {
				...state,
				info: {
					...state.info,
					[action.chatroomId]: {
						...state.info![action.chatroomId],
						messages: [
							...state.info![action.chatroomId].messages,
							action.message,
						],
					},
				},
			};
		case ADD_NEW_CHATROOM:
			return {
				...state,
				info: {
					...state.info,
					[action.chatroom._id]: {
						...action.chatroom,
					},
				},
			};
		case MARK_MESSAGES_AS_SEEN:
			if (!state.info) return state;
			const keys = Object.keys(state.info);

			for (let i = 0; i < keys.length; i++) {
				state.info[keys[i]] = {
					...state.info[keys[i]],
					messages: state.info[keys[i]].messages.map(message => {
						if (action.messageIds.indexOf(message._id) < 0)
							return message;
						if (message.seenBy.indexOf(action.userId) < 0) {
							return {
								...message,
								seenBy: [...message.seenBy, action.userId],
							};
						}
						return message;
					}),
				};
			}

			return state;
		case ADD_TEMP_CHATROOM:
			if (!state.info) return state;
			return {
				...state,
				info: {
					...state.info,
					[action.tempId]: action.chatroom,
				},
			};
		case REPLACE_TEMP_CHATROOM:
			if (!state.info) return state;
			const newState = {
				...state,
				info: {
					...state.info,
					[action.newId]: {
						...state.info[action.tempId],
						_id: action.newId,
					},
				},
			};
			delete newState.info[action.tempId];
			return newState;
		default:
			return state;
	}
}

export interface IStateChatroom {
	[chatroomId: string]: {
		_id: string;
		name?: string;
		participantIds: string[];
		participantNames: string[];
		messages: IMessage[];
	};
}

export interface IChatroom {
	_id: string;
	name?: string;
	participantIds: string[];
	participantNames: string[];
	messages: IMessage[];
}

export interface IStateChatRooms {
	info?: IStateChatroom;
	lastUpdate?: Date;
}

export interface IMessage {
	_id: string;
	chatRoomId: string;
	senderId: string;
	message: string;
	seenBy: string[];
	createdAt: Date;
}
