const otherFacultiesRecommendationsForTou: Record<number, null | number> = {
	"1": 446,
	"2": 447,
	"3": null,
	"4": 447,
	"9": null,
	"26": null,
	"30": 442,
	"31": null,
	"32": null,
	"33": null,
	"34": null,
	"35": null,
	"36": null,
	"37": null,
	"38": 868,
	"39": null,
	"40": null,
	"41": null,
	"42": null,
	"43": 447,
	"44": null,
	"49": 447,
	"50": 446,
	"51": 446,
	"52": 442,
	"53": 442,
	"54": 443,
	"55": 447,
	"56": null,
	"57": null,
	"58": 450,
	"59": 450,
	"60": 450,
	"61": 447,
	"64": null,
	"65": null,
	"66": null,
	"67": null,
	"68": null,
	"69": null,
	"70": null,
	"71": null,
	"72": null,
	"73": null,
	"74": null,
	"75": null,
	"76": null,
	"77": null,
	"78": null,
	"79": null,
	"80": null,
	"81": null,
	"82": null,
	"83": null,
	"84": null,
	"85": null,
	"86": null,
	"87": null,
	"88": null,
	"89": null,
	"90": null,
	"91": null,
	"92": null,
	"93": null,
	"94": null,
	"95": 868,
	"96": 868,
	"98": 868,
	"99": 446,
	"100": 446,
	"101": 868,
	"102": 868,
	"103": 868,
	"105": 868,
	"106": 868,
	"107": null,
	"108": 868,
	"109": null,
	"110": null,
	"112": null,
	"113": null,
	"114": 868,
	"115": null,
	"116": null,
	"117": null,
	"118": null,
	"119": 868,
	"120": 868,
	"121": 442,
	"122": 868,
	"123": null,
	"125": 868,
	"127": null,
	"128": null,
	"130": null,
	"131": 447,
	"132": 446,
	"133": 446,
	"134": 447,
	"135": 443,
	"136": null,
	"137": null,
	"138": 442,
	"139": null,
	"140": null,
	"142": null,
	"145": 442,
	"147": null,
	"148": null,
	"149": null,
	"150": null,
	"151": null,
	"152": null,
	"153": null,
	"154": null,
	"156": 450,
	"157": 447,
	"158": 447,
	"159": 447,
	"160": 442,
	"161": null,
	"166": null,
	"167": null,
	"170": null,
	"171": null,
	"172": null,
	"173": null,
	"174": null,
	"178": null,
	"179": 869,
	"180": 442,
	"181": 443,
	"186": 443,
	"187": null,
	"188": 442,
	"189": 447,
	"190": null,
	"191": null,
	"192": null,
	"193": null,
	"194": null,
	"195": 442,
	"196": null,
	"197": null,
	"198": null,
	"199": null,
	"200": null,
	"201": null,
	"202": null,
	"203": 446,
	"204": 869,
	"205": 869,
	"206": 869,
	"207": 869,
	"208": 869,
	"209": null,
	"210": null,
	"211": 447,
	"212": 450,
	"213": 442,
	"214": 442,
	"215": 447,
	"216": 442,
	"217": 442,
	"218": null,
	"219": null,
	"220": null,
	"221": null,
	"222": null,
	"223": null,
	"225": null,
	"226": null,
	"227": null,
	"228": null,
	"229": null,
	"230": null,
	"231": null,
	"232": null,
	"233": 868,
	"234": 868,
	"235": 442,
	"236": null,
	"237": null,
	"238": null,
	"240": null,
	"241": 442,
	"242": 443,
	"244": 443,
	"245": 443,
	"246": 443,
	"247": null,
	"248": null,
	"249": null,
	"251": 447,
	"252": 869,
	"253": 447,
	"254": 869,
	"255": null,
	"257": 442,
	"258": 450,
	"259": null,
	"260": null,
	"261": null,
	"262": null,
	"263": null,
	"264": null,
	"265": null,
	"266": null,
	"267": null,
	"268": null,
	"269": 442,
	"270": null,
	"271": null,
	"272": null,
	"273": null,
	"274": null,
	"275": null,
	"276": null,
	"277": null,
	"278": 446,
	"279": null,
	"280": null,
	"281": 869,
	"282": null,
	"283": null,
	"284": null,
	"285": null,
	"286": null,
	"287": 447,
	"288": 447,
	"289": 447,
	"290": 447,
	"293": 442,
	"294": 442,
	"295": 450,
	"296": null,
	"297": null,
	"298": null,
	"300": null,
	"301": null,
	"304": 446,
	"305": 446,
	"306": 869,
	"307": 869,
	"312": 450,
	"313": 450,
	"314": 447,
	"315": 447,
	"316": 442,
	"317": 442,
	"318": 442,
	"319": null,
	"320": 442,
	"321": null,
	"322": null,
	"323": null,
	"324": null,
	"325": null,
	"326": null,
	"327": null,
	"328": null,
	"329": null,
	"330": null,
	"331": null,
	"337": 442,
	"338": 443,
	"339": 442,
	"340": null,
	"341": null,
	"342": 450,
	"343": 446,
	"344": 869,
	"345": 442,
	"346": 443,
	"348": null,
	"349": null,
	"350": 442,
	"351": 442,
	"352": 442,
	"353": 442,
	"354": 443,
	"355": 446,
	"356": 447,
	"357": 450,
	"358": 447,
	"359": 442,
	"360": 443,
	"361": 450,
	"362": 447,
	"363": 442,
	"364": 447,
	"365": 450,
	"366": null,
	"367": null,
	"368": null,
	"369": 442,
	"370": null,
	"371": null,
	"372": null,
	"373": 450,
	"375": 442,
	"377": null,
	"379": 447,
	"380": 443,
	"381": null,
	"382": null,
	"383": null,
	"384": 868,
	"385": 868,
	"386": null,
	"390": 442,
	"391": 442,
	"392": null,
	"393": null,
	"394": null,
	"395": null,
	"413": 442,
	"415": 442,
	"417": 443,
	"419": null,
	"421": 442,
	"422": 442,
	"423": 447,
	"425": 447,
	"426": 446,
	"427": 869,
	"428": 869,
	"429": 450,
	"436": null,
	"437": null,
	"438": null,
	"439": null,
	"442": 442,
	"443": 443,
	"446": 446,
	"447": 447,
	"450": 869,
	"453": null,
	"454": 450,
	"455": 442,
	"456": 443,
	"457": 447,
	"464": 450,
	"465": 447,
	"466": 446,
	"467": null,
	"468": 869,
	"469": null,
	"470": 442,
	"472": null,
	"473": null,
	"474": null,
	"475": null,
	"476": null,
	"477": null,
	"478": null,
	"479": null,
	"480": null,
	"481": null,
	"482": null,
	"483": null,
	"484": null,
	"485": null,
	"486": null,
	"487": null,
	"488": null,
	"489": null,
	"490": null,
	"491": null,
	"492": null,
	"493": null,
	"494": null,
	"495": null,
	"496": null,
	"497": null,
	"498": null,
	"500": null,
	"501": null,
	"502": null,
	"503": null,
	"509": 442,
	"510": null,
	"511": null,
	"512": null,
	"513": null,
	"514": 442,
	"515": 447,
	"517": 447,
	"520": 447,
	"521": 450,
	"524": 447,
	"525": null,
	"526": null,
	"527": 869,
	"528": null,
	"529": null,
	"530": null,
	"531": null,
	"532": 446,
	"533": null,
	"538": null,
	"540": null,
	"543": null,
	"544": null,
	"545": null,
	"551": 442,
	"552": 442,
	"553": 443,
	"554": 450,
	"555": 447,
	"556": 446,
	"557": null,
	"558": null,
	"559": null,
	"560": 450,
	"561": 447,
	"562": null,
	"563": null,
	"564": 869,
	"565": 446,
	"566": null,
	"567": 450,
	"568": null,
	"569": null,
	"572": 446,
	"573": 869,
	"574": null,
	"575": null,
	"576": null,
	"577": 447,
	"578": null,
	"579": 447,
	"580": 447,
	"581": null,
	"582": 442,
	"583": 443,
	"584": 442,
	"585": 447,
	"586": null,
	"587": null,
	"588": 447,
	"589": 446,
	"590": 447,
	"591": 442,
	"592": 442,
	"593": 442,
	"594": 450,
	"595": 450,
	"596": 446,
	"597": 442,
	"598": null,
	"600": null,
	"601": 447,
	"602": 447,
	"604": 447,
	"605": 869,
	"606": 447,
	"607": 447,
	"608": 447,
	"609": 443,
	"610": 443,
	"611": 442,
	"612": 442,
	"618": null,
	"619": null,
	"620": null,
	"622": null,
	"624": null,
	"625": null,
	"626": 446,
	"633": null,
	"634": null,
	"635": null,
	"636": null,
	"637": null,
	"638": null,
	"639": null,
	"640": null,
	"641": null,
	"642": null,
	"643": 868,
	"644": 442,
	"646": null,
	"647": 442,
	"648": null,
	"649": null,
	"650": 446,
	"651": 869,
	"652": null,
	"653": 443,
	"654": 442,
	"655": 442,
	"656": 442,
	"657": 442,
	"658": 447,
	"659": 446,
	"660": 450,
	"661": 447,
	"662": null,
	"663": null,
	"664": null,
	"665": null,
	"666": 442,
	"667": 442,
	"668": 869,
	"669": null,
	"682": null,
	"683": null,
	"687": null,
	"688": null,
	"689": 868,
	"690": null,
	"691": 869,
	"692": null,
	"694": 450,
	"695": 447,
	"696": null,
	"697": 442,
	"698": 442,
	"699": 446,
	"700": null,
	"701": 443,
	"702": 442,
	"703": 450,
	"704": 446,
	"705": 447,
	"706": 447,
	"707": 450,
	"708": 446,
	"709": 447,
	"711": 442,
	"712": 443,
	"715": 447,
	"718": null,
	"725": 442,
	"729": 450,
	"730": null,
	"731": null,
	"732": 442,
	"733": 450,
	"735": null,
	"736": null,
	"737": null,
	"738": 447,
	"739": null,
	"740": 442,
	"741": null,
	"742": null,
	"743": null,
	"744": null,
	"745": null,
	"746": null,
	"747": null,
	"748": null,
	"761": 442,
	"762": 443,
	"763": 447,
	"764": 442,
	"765": 442,
	"766": null,
	"767": null,
	"768": 450,
	"769": null,
	"770": 450,
	"771": 442,
	"772": null,
	"773": 442,
	"774": null,
	"775": 447,
	"776": 442,
	"777": null,
	"778": null,
	"787": 447,
	"788": 442,
	"789": 442,
	"790": null,
	"791": 442,
	"792": null,
	"794": null,
	"795": 450,
	"797": 447,
	"798": 442,
	"799": 869,
	"800": null,
	"803": 447,
	"804": 442,
	"805": 447,
	"806": 447,
	"807": 442,
	"808": 442,
	"809": null,
	"810": 869,
	"813": null,
	"814": 447,
	"815": 447,
	"817": null,
	"819": null,
	"820": null,
	"821": 442,
	"823": 450,
	"824": 868,
	"825": null,
	"826": null,
	"827": null,
	"828": null,
	"829": null,
	"831": 442,
	"832": 442,
	"833": 442,
	"834": 450,
	"835": 447,
	"836": 443,
	"837": 869,
	"838": 447,
	"839": 446,
	"840": null,
	"841": null,
	"842": null,
	"843": null,
	"846": null,
	"854": 869,
	"855": 869,
	"856": 869,
	"857": 869,
	"858": 869,
	"859": 869,
	"861": 869,
	"863": 442,
	"866": 446,
	"867": 442,
	"870": 442,
	"871": null,
	"882": 442,
	"883": null,
	"885": 447,
	"887": null,
	"891": 442,
};

export const getTouSuggestedProgramId = (
	programId: number
): number | "general" | null => {
	const touProgramId = otherFacultiesRecommendationsForTou[programId];
	if (typeof touProgramId === "number") {
		return touProgramId;
	}
	if (touProgramId === null) {
		return "general";
	}
	return null;
};
