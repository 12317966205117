import { IRUser, IUserEssentials } from "@app/api/users/helper-schemas";
import {
	LOAD_USER_INFO,
	LOAD_USER_REJECTIONS,
	REJECT_MINI_POPUP,
	UPDATE_USER_MOBILE,
	UPDATE_USER_PROFILE,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
} from "@app/action-types";
import { UserActions } from "@app/actions/user";

function getNewUserState(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	switch (action.type) {
		case USER_AUTHORIZATION_SUCCESS:
			return {
				...state,
				loggedIn: true,
				userId: action.userData.id,
				userData: action.userData,
			};

		case LOAD_USER_INFO:
			return {
				...state,
				...action.user,
			};
		case LOAD_USER_REJECTIONS:
			return {
				...state,
				rejections: action.rejections,
			};
		case REJECT_MINI_POPUP:
			return {
				...state,
				rejections: {
					...(state.rejections || {}),
					miniPopups: [
						...(state.rejections
							? state.rejections.miniPopups
							: {}),
						action.data,
					],
				},
			};
		case UPDATE_USER_MOBILE:
			return {
				...state,
				userData: {
					...state.userData!,
					mobile: action.data.mobile,
				},
			};
		case UPDATE_USER_PROFILE:
			const updateObj: IRUser = { ...state.userData! };
			const { data } = action;
			if (data.firstName) updateObj.firstname = data.firstName;
			if (data.lastName) updateObj.lastname = data.lastName;
			if (data.mail) updateObj.mail = data.mail;
			if (data.mobile) updateObj.mobile = String(data.mobile);
			return {
				...state,
				userData: updateObj,
			};
		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function user(state = {}, action: UserActions): IStateUser {
	const newState = getNewUserState(state, action);
	if (
		newState !== state &&
		typeof (action as any).randomKey === "undefined"
	) {
		localStorage.setItem("user", JSON.stringify(newState));
		localStorage.setItem(
			"lastAction",
			JSON.stringify({ ...action, randomKey: Math.random() })
		);
	}
	return newState;
}

export interface IStateUser {
	loggedIn?: boolean;
	userId?: number;
	userData?: IRUser;
	rejections?: IUserEssentials["additionalInfo"]["rejections"];
}
