import * as React from "react";
import styled from "@emotion/styled";
import {
	CourseMessageWrapper,
	IMessageContainerStyle,
	LeftMessageWrapper,
	UserMessageWrapper,
} from "./message-wrappers";
import { IMessage } from "../../reducers/chatrooms";

const Container = styled.div`
	display: inline-flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 15px;
	overflow: auto;
	background-color: white;
	z-index: 99;
	flex: 1;
`;

interface IOwnProps {
	messages: IMessage[];
	userId: string;
	uniPicture: string;
	participantIds?: string[];
	participantNames?: string[];
	style?: { messageContainer?: IMessageContainerStyle };
}

type IProps = IOwnProps;

class MiniChatMessages extends React.PureComponent<IProps> {
	scrollToBottom = () => {
		const mainContainer = document.getElementById("message-container");
		if (mainContainer) mainContainer.scrollTop = mainContainer.scrollHeight;
	};

	render() {
		const userId = this.props.userId;
		const isUni = userId.indexOf("uni") >= 0 || userId[0] === "c";
		let userIndex;
		let userName;
		let otherName;
		if (this.props.participantNames && this.props.participantIds) {
			userIndex = this.props.participantIds.indexOf(userId);
			userName = this.props.participantNames[userIndex];
			otherName = this.props.participantNames[userIndex === 0 ? 1 : 0];
		}
		setTimeout(this.scrollToBottom, 0);

		return (
			<Container id="message-container">
				{this.props.messages.map(message => {
					const messageText =
						message.message.replace(/(\r\n|\n|\r)/gm, "<br />") +
						"<br />";
					if (message.senderId === userId) {
						return (
							<UserMessageWrapper
								key={message._id}
								userMessage={messageText}
								userName={userName}
								userPicture={
									isUni ? this.props.uniPicture : undefined
								}
								style={
									this.props.style
										? this.props.style.messageContainer
										: undefined
								}
							/>
						);
					}
					if (message.senderId[0] === "c") {
						return (
							<CourseMessageWrapper
								key={message._id}
								userMessage={messageText}
								userName={otherName}
								userPicture={this.props.uniPicture}
								style={
									this.props.style
										? this.props.style.messageContainer
										: undefined
								}
							/>
						);
					}
					return (
						<LeftMessageWrapper
							key={message._id}
							userMessage={messageText}
							userName={otherName}
							userPicture={
								!isUni ? this.props.uniPicture : undefined
							}
							style={
								this.props.style
									? this.props.style.messageContainer
									: undefined
							}
						/>
					);
				})}
			</Container>
		);
	}
}

export default MiniChatMessages;
