import * as React from "react";
import FreeUniversityPage from "@app/components/Universities/FreeUniversityPage";
import Header from "./Header";
import memoize from "memoize-one";
import PremiumUniversityPage from "./Universities/PremiumUniversityPage";
import { connect } from "react-redux";
import {
	displayUniSubscriptionPopupAction,
	viewUniPage,
} from "@app/actions/temp";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import {
	IRUniversity,
	IUniContent,
	IUniMainInfo,
} from "@app/api/unis/helper-schemas";
import { match } from "react-router";
import { getUniById, getUniByUrlName, IGetUniById } from "@app/actions/unis";

interface IOwnProps {
	history: History;
	match: match<{ urlName: string }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	loadingInfo: boolean;
	essentialInfo?: IRUniversity;
	mainInfo?: IUniMainInfo;
	contents?: IUniContent[];
	programs?: IRProgram[];
}

class UniPage extends React.Component<IProps, IState> {
	uni_id?: number;
	_isMounted?: boolean;

	state = {
		loadingInfo: true,
	} as IState;

	getPrograms = memoize(
		(programs: IRProgram[] | undefined, uni_id: number | undefined) => {
			if (programs && uni_id && !this.state.programs) {
				setTimeout(() => {
					this.setState({
						programs: programs.filter(
							prog => prog.uni_id === this.uni_id
						),
					});
				}, 0);
			}
		}
	);

	constructor(props: IProps) {
		super(props);
		const { urlName } = props.match.params;
		if (!urlName) {
			props.history.push("/");
		}
		// tslint:disable-next-line:variable-name
		let uni_id;
		if (this.props.unis) {
			const uni = this.props.unis.find(each => each.urlName === urlName);
			if (typeof uni !== "undefined") {
				uni_id = uni.id;
				this.state.essentialInfo = uni;
			}
		}
		if (uni_id) {
			this.uni_id = uni_id;
			if (
				typeof props.tempUnis !== "undefined" &&
				typeof props.tempUnis[uni_id] !== "undefined"
			) {
				this.state.contents = props.tempUnis[uni_id].contents;
				this.state.mainInfo = props.tempUnis[uni_id].mainInfo;
				this.recordView();
			}
			if (
				this.props.programs &&
				(this.props.programsFullyLoaded === true ||
					(typeof this.props.programsFullyLoaded === "object" &&
						this.props.programsFullyLoaded[uni_id]))
			) {
				this.state.programs = this.props.programs.filter(
					prog => prog.uni_id === this.uni_id
				);
			}
		}
	}

	recordView = () => {
		if (
			!this.props.viewedUnis ||
			!this.props.viewedUnis[this.state.essentialInfo!.id]
		) {
			// unviewed uni in this session
			this.props.viewUniPage(this.state.essentialInfo!.id);
		}
	};

	getUni = (): ReturnType<IGetUniById> => {
		if (this.state.essentialInfo) {
			return this.props.getUniById({
				id: this.state.essentialInfo.id,
				getEssentialInfo: false,
				getMainInfo: typeof this.state.mainInfo === "undefined",
				getContents: typeof this.state.contents === "undefined",
				getPrograms: typeof this.state.programs === "undefined",
			});
		}
		return this.props.getUniByUrlName({
			urlName: this.props.match.params.urlName,
			getEssentialInfos: true,
			getMainInfos: typeof this.state.mainInfo === "undefined",
			getContents: typeof this.state.contents === "undefined",
			getPrograms: typeof this.state.programs === "undefined",
		});
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.props.displayUniSubscriptionPopupAction(undefined);

		if (
			typeof this.state.essentialInfo === "undefined" ||
			typeof this.state.mainInfo === "undefined" ||
			typeof this.state.contents === "undefined" ||
			typeof this.state.programs === "undefined"
		) {
			this.getUni().then(data => {
				if (!this._isMounted) return;
				const additionalFields = {} as IState;
				const { mainInfo, contents, programs, ...essentials } = data;
				additionalFields.essentialInfo = {
					...this.state.essentialInfo,
					...essentials,
				} as IState["essentialInfo"];
				if (mainInfo) additionalFields.mainInfo = mainInfo;
				if (contents)
					additionalFields.contents = contents as IState["contents"];
				if (programs) additionalFields.programs = programs;
				this.uni_id = data.id;
				this.setState(
					{
						loadingInfo: false,
						...additionalFields,
					},
					this.recordView
				);
			});
		}
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	render() {
		this.getPrograms(this.props.programs, this.uni_id);
		return (
			<>
				<Header history={this.props.history} />
				{!this.state.essentialInfo ? (
					<div className="smallLoading" />
				) : !this.state.essentialInfo.premium ? (
					<FreeUniversityPage
						key={this.state.essentialInfo.id}
						history={this.props.history}
						uni_id={this.state.essentialInfo.id}
						essentialInfo={this.state.essentialInfo}
						mainInfo={this.state.mainInfo}
						contents={this.state.contents}
						programs={this.state.programs}
					/>
				) : (
					<PremiumUniversityPage
						key={this.state.essentialInfo.id}
						history={this.props.history}
						uni_id={this.state.essentialInfo.id}
						essentialInfo={this.state.essentialInfo}
						mainInfo={this.state.mainInfo}
						contents={this.state.contents}
						programs={this.state.programs}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	tempUnis: state.temp.unis,
	programs: state.programs.info,
	viewedUnis: state.temp.viewedUnis,
	programsFullyLoaded: state.programs.fullyLoaded,
});

const mapDispatchToProps = {
	getUniById,
	getUniByUrlName,
	viewUniPage,
	displayUniSubscriptionPopupAction,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(UniPage);
