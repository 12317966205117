import * as React from "react";
import GlowingBar from "./glowing-bar";
import memoizeOne from "memoize-one";
import SvgLoading from "@app/components/styles/img/Loading";
import { calculateGrant, grantTypes, IGrant } from "../functions";
import { grantLimitScores } from "@app/commonJavascript";
import { IUserScores } from "@app/reducers/lator";
import { PointSVG } from "./place-slider";
import { useEffect, useState } from "react";

interface IProps {
	programId: number;
	electiveSubjects: number[];
	scores: IUserScores;
	contestScaledScore: number;
}

interface IState {
	result?: IGrant;
	grantSubjectId?: number;
}

const Grant: React.FC<IProps> = props => {
	const [result, setResult] = useState<IState["result"]>();
	const [grantSubjectId, setGrantSubjectId] = useState<
		IState["grantSubjectId"]
	>();

	useEffect(() => {
		const calculate = (electiveSubjects: number[], scores: IUserScores) => {
			const results = calculateGrant({
				electiveSubjects,
				rawScores: scores,
			});
			let bestResult = results[electiveSubjects[0]];
			let grantSubjectId = electiveSubjects[0];
			for (let i = 1; i < electiveSubjects.length; i++) {
				const subjId = electiveSubjects[i];
				if (results[subjId].grant > bestResult.grant) {
					bestResult = results[subjId];
					grantSubjectId = subjId;
				}
			}
			setResult(bestResult);
			setGrantSubjectId(grantSubjectId);
		};
		calculate(props.electiveSubjects, props.scores);
	}, [props.electiveSubjects, props.scores]);

	const getStyle = memoizeOne(
		(bestResult: IGrant, grantSubjectId: number) => {
			const maxScore = Math.max(
				bestResult.totalScaledScore,
				grantLimitScores[grantSubjectId][0] + 150
			);
			const minScore = Math.min(
				bestResult.totalScaledScore - 100,
				grantLimitScores[grantSubjectId][2] - 200
			);

			const range = maxScore - minScore;

			const treshold = (40 / range) * 100;

			const grant100 =
				((maxScore - grantLimitScores[grantSubjectId][0]) / range) *
				100;
			const grant70 =
				grant100 +
				((grantLimitScores[grantSubjectId][0] -
					grantLimitScores[grantSubjectId][1]) /
					range) *
					100;
			const grant50 =
				grant70 +
				((grantLimitScores[grantSubjectId][1] -
					grantLimitScores[grantSubjectId][2]) /
					range) *
					100;

			const color100 = "#9c30a4";
			const color70 = "#ec5d6a";
			const color50 = "#f9abb2";
			const color0 = "#ffecee";

			return {
				barStyle: {
					// tslint:disable-next-line:max-line-length
					background: `linear-gradient(to right, ${color100} 0%, ${color100} ${grant100 -
						treshold}%, ${color70} ${grant100 +
						treshold}%, ${color70} ${grant70 -
						treshold}%, ${color50} ${grant70 +
						treshold}%, ${color50} ${grant50 -
						treshold}%, ${color0} ${grant50 +
						treshold}%, ${color0} 100%)`,
				},
				percentages: {
					grant100,
					grant70,
					grant50,
					me:
						((maxScore - bestResult.totalScaledScore) / range) *
						100,
				},
			};
		}
	);

	if (!result || !grantSubjectId) {
		return (
			<div className="latoriGrantResultContainer">{<SvgLoading />}</div>
		);
	}
	const { barStyle, percentages } = getStyle(result, grantSubjectId);

	return (
		<div className="latoriGrantResultContainer">
			<div>
				თქვენი საგრანტო ქულა გასული წლების სტატისტიკური მონაცემებიდან
				გამომდინარე არის <b>{result.totalScaledScore.toFixed(1)}</b>
				<br />
				<br />
				<div className="point">
					<div style={{ left: percentages.me + "%" }}>
						<PointSVG />
						<span>მე</span>
					</div>
				</div>
				<GlowingBar style={barStyle} />
				<GrantLegend percentages={percentages} />
			</div>
		</div>
	);
};

interface IGrantLegendProps {
	percentages: {
		grant100: number;
		grant70: number;
		grant50: number;
		me: number;
	};
	showArrow?: boolean;
}

export const GrantLegend: React.FC<IGrantLegendProps> = ({
	percentages,
	showArrow = true,
}) => (
	<div className="grantLegend">
		<GrantPrecent
			left={percentages.grant70}
			width={percentages.grant50 - percentages.grant70}
			grant={50}
			showArrow={showArrow}
		/>
		<GrantPrecent
			left={percentages.grant100}
			width={percentages.grant70 - percentages.grant100}
			grant={70}
			showArrow={showArrow}
		/>
		<GrantPrecent
			left={0}
			width={percentages.grant100}
			grant={100}
			showArrow={showArrow}
		/>
	</div>
);

interface IGrantPrecentProps {
	grant: grantTypes;
	width: number;
	left: number;
	showArrow: boolean;
}
const GrantPrecent: React.FC<IGrantPrecentProps> = props => (
	<div style={{ marginLeft: props.left + "%", width: props.width + "%" }}>
		<div className="stick grntBrPrBx100" />
		<div
			className="arrow"
			style={props.showArrow ? undefined : { opacity: 0 }}
		/>
		<div className="text">{props.grant}%</div>
	</div>
);

export default Grant;
