import * as React from "react";
import AddIcon from "@material-ui/icons/Add";
import api from "@app/api";
import CheckIcon from "@material-ui/icons/Check";
import styled from "@emotion/styled";
import SvgLoadingWhite from "../styles/img/LoadingWhite";
import triggerEvent from "@app/utils/events";
import { isCorrectNumber } from "../Admins/sms/send-sms-page";

interface IState {
	isSending: boolean;
	sent: boolean;
	friendNames: string[];
	friendPhones: string[];
	isHidden: boolean;
}

class LatoriInviteFriend extends React.PureComponent<{}, IState> {
	state: IState = {
		isSending: false,
		sent: false,
		isHidden: false,
		friendNames: [""],
		friendPhones: [""],
	};

	onSend = () => {
		const invitedFriends: Array<{ name: string; phone: string }> = [];
		this.state.friendNames.forEach((name, index) => {
			if (!name) return;
			if (this.state.friendPhones[index] === "") return;
			if (!isCorrectNumber(this.state.friendPhones[index])) return;
			for (let i = 0; i < name.length; i++) {
				if (name.charAt(i) > "ჰ" || name.charAt(i) < "ა") {
					alert(
						"გთხოვთ, სახელი შეიყვანოთ მხოლოდ ქართული სიმბოლოებით"
					);
					return;
				}
			}
			invitedFriends.push({
				name,
				phone: this.state.friendPhones[index],
			});
		});
		if (invitedFriends.length === 0) {
			alert("შეიყვანეთ სახელისა და ნომრის წყვილი სწორად");
			return;
		}
		this.setState({
			isSending: true,
		});
		api.lator
			.inviteFriend({
				info: invitedFriends,
			})
			.then(data => {
				this.setState({
					isSending: false,
					sent: true,
				});
				setTimeout(() => {
					this.setState({
						isHidden: true,
					});
				}, 1000);
			})
			.catch(e => {
				this.setState({
					isSending: false,
				});
				alert("დაფიქსირდა შეცდომა");
			});
		triggerEvent(
			{
				category: "Button",
				action: "Lator: invite friends",
				label: "",
				value: invitedFriends.length,
			},
			{ numInvited: invitedFriends.length }
		);
	};

	onChange = (which: "name" | "phone", index: number, value: string) => {
		if (which === "name") {
			const newNames = [...this.state.friendNames];
			newNames[index] = value;
			this.setState({
				friendNames: newNames,
			});
		} else {
			const newPhones = [...this.state.friendPhones];
			newPhones[index] = value;
			this.setState({
				friendPhones: newPhones,
			});
		}
	};

	onUserAdd = () => {
		this.setState({
			friendNames: [...this.state.friendNames, ""],
			friendPhones: [...this.state.friendPhones, ""],
		});
	};

	render() {
		if (this.state.isHidden) return null;
		return (
			<div className="inviteFriendContainer">
				<h2>დაეხმარე შენს აბიტურიენტ მეგობრებს!</h2>
				<h2>მოიწვიე ისინი მურწყულატორზე!</h2>
				{this.state.friendNames.map((each, i) => (
					<PhoneContainer key={i}>
						<div>
							<Input
								type="text"
								placeholder="მეგობრის სახელი"
								value={this.state.friendNames[i]}
								onChange={e =>
									this.onChange("name", i, e.target.value)
								}
							/>
						</div>
						<div>
							<Input
								type="text"
								placeholder="მეგობრის ტელეფონი"
								value={this.state.friendPhones[i]}
								onChange={e =>
									this.onChange("phone", i, e.target.value)
								}
							/>
						</div>
					</PhoneContainer>
				))}
				<div style={{ textAlign: "center" }}>
					<AddIcon
						onClick={this.onUserAdd}
						style={{ cursor: "pointer", color: "purple" }}
					/>
				</div>
				<div style={{ height: 10 }} />
				{!this.state.isSending && !this.state.sent && (
					<button className="main-button" onClick={this.onSend}>
						მოწვევა
					</button>
				)}
				{this.state.isSending && (
					<button className="main-button active">
						იგზავნება <SvgLoadingWhite width={18} height={18} />
					</button>
				)}
				{this.state.sent && (
					<button className="main-button active">
						გაგზავნილია <CheckIcon width={18} height={18} />
					</button>
				)}
			</div>
		);
	}
}

const PhoneContainer = styled("div")({
	display: "flex",
	"&>div": {
		width: "100%",
		marginBottom: 10,
		"&:first-child": {
			marginRight: 10,
		},
	},
	marginBottom: 10,
	"@media (max-width: 500px)": {
		display: "block",
	},
});

const Input = styled("input")({
	width: "100%",
	border: "1px solid purple",
	padding: "10px 12px",
	borderRadius: 40,
	outline: "none",
	transition: "0.3s",
	fontSize: "1em",
	"&:focus": {
		boxShadow: "0 0 3px purple",
	},
});

export default LatoriInviteFriend;
