import { IUniContent } from "@app/api/unis/helper-schemas";
import {
	getGalleryMediaUrl,
	getVideoThumbNailUrl,
} from "@app/commonJavascript";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";
import InsertLink from "@material-ui/icons/InsertLink";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { css } from "emotion";
import * as React from "react";
import FancyInput from "../Widgets/FancyInput";
import PDF from "../Widgets/pdf";
import Popup, { PopupContent } from "../Widgets/Popup";
import UploadButton, { UploadFileButton } from "../Widgets/UploadButton";

interface IProps {
	galleryMedias: IUniContent["galleryMedias"];
	onChange: (galleryMedias: IUniContent["galleryMedias"]) => void;
	uploadImageFunc: (
		data: FormData
	) => Promise<{ files: Array<{ fileName: string; fileFullUrl: string }> }>;
	uploadPdfFunc?: (data: FormData) => Promise<any>;
	galleryPath: string;
}

interface IState {
	uploading: boolean;
	galleryMedias: IUniContent["galleryMedias"];
	isVideoPopupOpen: boolean;
}

class ContentMedias extends React.Component<IProps, IState> {
	state: IState = {
		uploading: false,
		galleryMedias: this.props.galleryMedias,
		isVideoPopupOpen: false,
	};

	_isMounted = false;
	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	imageFileChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
		const { files } = e.target;
		const formData = new FormData();

		for (let i = 0; i < files.length; ++i) {
			formData.append("files", files[i]);
		}
		this.setState({
			uploading: true,
		});
		this.props
			.uploadImageFunc(formData as any)
			.then(data => {
				if (!this._isMounted) return;
				const galleryMedias = this.state.galleryMedias || [];
				const newGalleryMedia = [
					...galleryMedias,
					...data.files.map(each => ({
						type: "PHOTO" as "PHOTO",
						url: each.fileName,
					})),
				];
				this.setState({
					galleryMedias: newGalleryMedia,
					uploading: false,
				});
				this.props.onChange(newGalleryMedia);
			})
			.catch(err => {
				if (!this._isMounted) return;
				this.setState({
					uploading: false,
				});
			});
	};

	pdfFileChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
		if (!this.props.uploadPdfFunc) return;
		const { files } = e.target;
		const formData = new FormData();

		for (let i = 0; i < files.length; ++i) {
			formData.append("pdfFiles", files[i]);
		}

		this.setState({
			uploading: true,
		});
		this.props
			.uploadPdfFunc(formData as any)
			.then(data => {
				if (!this._isMounted) return;
				const galleryMedias = this.state.galleryMedias || [];
				const newGalleryMedia = [
					...galleryMedias,
					...data.fileNames.map(fileName => ({
						type: "PDF" as "PDF",
						url: fileName,
					})),
				];
				this.setState({
					galleryMedias: newGalleryMedia,
					uploading: false,
				});
				this.props.onChange(newGalleryMedia);
			})
			.catch(err => {
				if (!this._isMounted) return;
				this.setState({
					uploading: false,
				});
			});
	};

	onGalleryMediaTitleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const newGalleryMedias =
			this.state.galleryMedias &&
			this.state.galleryMedias.map((media, i) =>
				i === index ? { ...media, title: e.target.value } : media
			);
		this.setState({
			galleryMedias: newGalleryMedias,
		});
		this.props.onChange(newGalleryMedias);
	};

	onVideoPopupClose = () => {
		this.setState({
			isVideoPopupOpen: false,
		});
	};

	onVideoPopupOpen = () => {
		this.setState({
			isVideoPopupOpen: true,
		});
	};

	onMediaRemove = (index: number) => {
		let newGalleryMedia: IUniContent["galleryMedias"] = this.state.galleryMedias!.filter(
			(e, i) => i !== index
		);
		if (newGalleryMedia.length === 0) newGalleryMedia = null;
		this.setState({
			galleryMedias: newGalleryMedia,
		});
		this.props.onChange(newGalleryMedia);
	};

	onVideoLinkInsert = (link: string) => {
		this.onVideoPopupClose();
		const galleryMedias = this.state.galleryMedias || [];
		const newGalleryMedia = [
			...galleryMedias,
			{
				type: "VIDEO" as "VIDEO",
				url: link,
			},
		];
		this.setState({
			galleryMedias: newGalleryMedia,
		});
		this.props.onChange(newGalleryMedia);
	};

	onScaleChange = (index: number) => {
		const newGalleryMedias = this.state.galleryMedias!.map((element, i) => {
			if (i !== index) return element;
			return {
				...element,
				isLarge: !element.isLarge,
			};
		});
		this.setState({
			galleryMedias: newGalleryMedias,
		});
		this.props.onChange(newGalleryMedias);
	};

	render() {
		return (
			<React.Fragment>
				{this.state.isVideoPopupOpen && (
					<VideoPopup
						onClose={this.onVideoPopupClose}
						onVideoLinkInsert={this.onVideoLinkInsert}
					/>
				)}
				<h1>გალერეა</h1>
				<div className="galleryMediaEdit">
					<div className="buttons">
						<UploadButton
							onChange={this.imageFileChangedHandler}
							multiple={true}
						/>
						<span style={{ position: "relative" }}>
							<button
								className="videoUploadButton"
								onClick={this.onVideoPopupOpen}
							>
								<InsertLink />
								<span>Youtube-ის ლინკის ჩასმა</span>
							</button>
						</span>
					</div>
					<h1>PDF ფაილები</h1>
					<div>
						<div className="buttons">
							<UploadFileButton
								onChange={this.pdfFileChangedHandler}
								multiple={true}
							/>
						</div>
					</div>
					{this.state.uploading && "იტვირთება...."}
					<div className="medias">
						{this.state.galleryMedias &&
							this.state.galleryMedias.map((media, index) => {
								if (media.type === "PHOTO") {
									return (
										<div className="galleryMediaContainer">
											<div
												key={index}
												className="galleryMedia"
												style={{
													background: `url(/photos/${this.props.galleryPath}/${media.url})`,
													backgroundSize: "cover",
												}}
											>
												<CloseIcon
													className="closeIcon"
													onClick={() =>
														this.onMediaRemove(
															index
														)
													}
												/>
												{this.props.galleryPath ===
													"gallery" && (
													<SelectScale
														onCheck={() =>
															this.onScaleChange(
																index
															)
														}
														isChecked={
															!!media.isLarge
														}
													/>
												)}
											</div>
											<FancyInput
												defaultValue={
													media.title || null
												}
												title={"სათაური"}
												style={{
													width: 200,
													marginTop: -20,
													marginLeft: 0,
												}}
												onChange={e =>
													this.onGalleryMediaTitleChange(
														e,
														index
													)
												}
											/>
										</div>
									);
								} else if (media.type === "VIDEO")
									return (
										<div className="galleryMediaContainer">
											<div
												key={media.url}
												className="galleryMedia"
												style={{
													background: `url(${getVideoThumbNailUrl(
														media.url
													)})`,
													backgroundSize: "cover",
												}}
											>
												<CloseIcon
													className="closeIcon"
													onClick={() =>
														this.onMediaRemove(
															index
														)
													}
												/>
												{this.props.galleryPath ===
													"gallery" && (
													<SelectScale
														onCheck={() =>
															this.onScaleChange(
																index
															)
														}
														isChecked={
															!!media.isLarge
														}
													/>
												)}
												<div className="videoIconContainer">
													<VideoLibraryIcon />
												</div>
											</div>
											<FancyInput
												defaultValue={
													media.title || null
												}
												title={"სათაური"}
												style={{
													width: 200,
													marginTop: -20,
													marginLeft: 0,
												}}
												onChange={e =>
													this.onGalleryMediaTitleChange(
														e,
														index
													)
												}
											/>
										</div>
									);
								else if (media.type === "PDF")
									return (
										<div
											className="galleryMediaContainer"
											style={{ width: 300 }}
										>
											<div
												key={media.url}
												className="galleryMedia"
												style={{
													width: 300,
													height: 424,
													backgroundSize: "cover",
													marginTop: 20,
													verticalAlign: "top",
													overflow: "hidden",
												}}
											>
												<CloseIcon
													className="closeIcon"
													onClick={() =>
														this.onMediaRemove(
															index
														)
													}
												/>
												<SelectScale
													onCheck={() =>
														this.onScaleChange(
															index
														)
													}
													isChecked={!!media.isLarge}
												/>
												<PDF
													url={getGalleryMediaUrl(
														media.type,
														media.url
													)}
													pages={[1]}
													documentClassname="pdf-media-document"
													pageClassName="pdf-media-page"
												/>
											</div>
											<FancyInput
												defaultValue={
													media.title || null
												}
												title={"სათაური"}
												style={{
													width: 300,
													marginTop: -20,
													marginLeft: 0,
												}}
												onChange={e =>
													this.onGalleryMediaTitleChange(
														e,
														index
													)
												}
											/>
										</div>
									);
								else return null;
							})}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const ScaleContainerClassName = css`
	background: #ffffffb5;
	position: absolute;
	border-top-left-radius: 9px;
	padding: 5px;
	border-bottom-right-radius: 9px;
	z-index: 1;
	& > * {
		height: 24px;
	}
`;

interface ISelectScaleProps {
	onCheck: () => void;
	isChecked: boolean;
}
const SelectScale: React.FC<ISelectScaleProps> = props => (
	<div className={ScaleContainerClassName}>
		<FormControlLabel
			control={
				<Checkbox
					checked={props.isChecked}
					onChange={props.onCheck}
					color="primary"
				/>
			}
			label="დიდი"
		/>
	</div>
);

interface IVideoPopupProps {
	onClose: () => void;
	onVideoLinkInsert: (link: string) => void;
}
class VideoPopup extends React.Component<IVideoPopupProps> {
	state = {
		value: "",
	};

	changeValue = event => {
		this.setState({
			value: event.target.value,
		});
	};

	onSubmit = () => {
		this.props.onVideoLinkInsert(this.state.value);
	};

	render() {
		return (
			<Popup onClose={this.props.onClose}>
				<PopupContent>
					<div style={{ width: 500 }}>
						<span style={{ color: "black" }}>
							ჩასვით ვიდეოს ლინკი
						</span>
						<input
							value={this.state.value}
							onChange={this.changeValue}
							style={{ width: 500 }}
						/>
						{this.state.value &&
							(this.state.value.indexOf("youtu.be") >= 0 ||
								this.state.value.indexOf("youtube") >= 0) && (
								<button onClick={this.onSubmit}>
									ვიდეოს ჩასმა
								</button>
							)}
					</div>
				</PopupContent>
			</Popup>
		);
	}
}

export default ContentMedias;
