import { ProgramsActions } from "@app/actions/programs";
import { IRProgram } from "@app/api/programs/helper-schemas";
import {
	CHANGE_PROGRAM_SETTINGS,
	CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT,
	LOAD_PROGRAMS_ESSENTIAL_INFO,
	DELETE_PROGRAM,
} from "../action-types";

function getNewProgramsState(
	state: IStatePrograms = {},
	action: ProgramsActions
): IStatePrograms {
	if (typeof action.type === "undefined") return state;
	switch (action.type) {
		case LOAD_PROGRAMS_ESSENTIAL_INFO:
			return {
				...state,
				info: [...action.info],
				fullyLoaded: action.fullyLoaded,
				lastUpdate: action.lastUpdate,
			};
		case CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT:
			return {
				...state,
				info: state.info!.map(program => {
					if (program.id !== action.program_id) return program;
					return {
						...program,
						intraUniPositionCoefficient: action.newCoefficient,
					};
				}),
			};
		case CHANGE_PROGRAM_SETTINGS:
			return {
				...state,
				info: state.info!.map(program => {
					if (program.id !== action.program_id) return program;
					return { ...program, ...action.info };
				}),
			};
		case DELETE_PROGRAM:
			return {
				...state,
				info: state.info!.filter(
					program => program.id !== action.program_id
				),
			};
		default:
			return state;
	}
}

export default function programs(
	state = {},
	action: ProgramsActions
): IStatePrograms {
	const newState = getNewProgramsState(state, action);
	if (newState !== state) {
		localStorage.setItem("programs", JSON.stringify(newState));
	}
	return newState;
}

export interface IStatePrograms {
	info?: IRProgram[];
	fullyLoaded?: boolean | { [key: string]: boolean };
	lastUpdate?: Date;
}
