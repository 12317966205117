import {
	IADELETENewsById,
	IAGETNewsById,
	IAPOSTNewsImages,
	IAPUTNews,
	IRDELETENewsById,
	IRGETNewsById,
	IRGETNewsByUniId,
	IRPOSTNewsImages,
	RDELETENewsByIdSchema,
	RGETNewsByIdSchema,
	RGETNewsByUniIdSchema,
	RPOSTNewsImagesSchema,
	RPUTNewsSchema,
} from "./validators";
import { Requests } from "@app/api";

const apiPath = "/api/news";
const news = {
	delete: (data: IADELETENewsById): Promise<IRDELETENewsById> =>
		Requests.send("DELETE", apiPath + "/:id", data, null, {
			responseSchema: RDELETENewsByIdSchema,
		}),
	getById: (data: IAGETNewsById): Promise<IRGETNewsById> =>
		Requests.send("GET", apiPath + "/:id", data, null, {
			responseSchema: RGETNewsByIdSchema,
		}),
	save: (data: IAPUTNews): Promise<IRGETNewsById> =>
		Requests.send("PUT", apiPath + "/", data, null, {
			responseSchema: RPUTNewsSchema,
		}),
	uploadPhotos: (data: IAPOSTNewsImages): Promise<IRPOSTNewsImages> =>
		Requests.send("POST", apiPath + "/photos", data, null, {
			responseSchema: RPOSTNewsImagesSchema,
		}),
	getAll: (data: {
		limit?: number;
		offset?: number;
	}): Promise<IRGETNewsByUniId> =>
		Requests.send("GET", apiPath + "/", data, null, {
			responseSchema: RGETNewsByUniIdSchema,
		}),
};
export default news;
