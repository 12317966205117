import { Requests } from "..";
import {
	IAGETSMSGroupInfo,
	IAGETSMSGroups,
	IAPOSTSendSMS,
	IRGETSMSGroupInfo,
	IRGETSMSGroups,
	IRPOSTSendSMS,
	RGETSMSGroupInfoSchema,
	RGETSMSGroupsSchema,
	RPOSTSendSMSSchema,
} from "./groups/validators";
import {
	IAGETSMSSendersByUniId,
	IRGETSMSSendersByUniId,
	RGETSMSSendersByUniId,
	IAPOSTAddSMSSenderForUni,
	IRPOSTAddSMSSenderForUni,
	RPOSTAddSMSSenderForUniSchema,
} from "./senders/validators";
import {
	IRGETSentSMSNumberForUni,
	RGETSentSMSNumberForUniSchema,
	IAGETSentSMSNumberForUni,
} from "../unis/validators";

const sms = {
	getGroup: (data: IAGETSMSGroupInfo): Promise<IRGETSMSGroupInfo> =>
		Requests.send("GET", "/api/sms-groups/:sms_group_id", data, null, {
			responseSchema: RGETSMSGroupInfoSchema,
		}),
	send: (data: IAPOSTSendSMS): Promise<IRPOSTSendSMS> =>
		Requests.send("POST", "/api/sms-groups/send", data, null, {
			responseSchema: RPOSTSendSMSSchema,
		}),
	getGroups: (data: IAGETSMSGroups): Promise<IRGETSMSGroups> =>
		Requests.send("GET", "/api/unis/:uni_id/sms-groups", data, null, {
			responseSchema: RGETSMSGroupsSchema,
		}),
	getSender: (
		data: IAGETSMSSendersByUniId
	): Promise<IRGETSMSSendersByUniId> =>
		Requests.send("GET", "/api/unis/:uni_id/sms-sender", data, null, {
			responseSchema: RGETSMSSendersByUniId,
		}),
	setSMSSender: (
		data: IAPOSTAddSMSSenderForUni
	): Promise<IRPOSTAddSMSSenderForUni> =>
		Requests.send("POST", "/api/unis/:uni_id/set-sms-sender", data, null, {
			responseSchema: RPOSTAddSMSSenderForUniSchema,
		}),
	getSentSMSNumberForUni: (
		data: IAGETSentSMSNumberForUni
	): Promise<IRGETSentSMSNumberForUni> =>
		Requests.send(
			"GET",
			"/api/unis/:uni_id/sent-sms-number-by-uni",
			data,
			null,
			{
				responseSchema: RGETSentSMSNumberForUniSchema,
			}
		),
	sendAutomatedSMS: () =>
		Requests.send(
			"POST",
			"/api/sms-groups/send/first",
			undefined,
			null,
			{}
		),
	sendSMSafterClick: (data: { userId: number }) =>
		Requests.send("POST", "/api/sms-groups/send/second", data, null, {}),
};

export default sms;
