import programContents from "./contents/controller";
import { contentTypeMultipart } from "../consts";
import {
	IAGETMany,
	IAGETProgram,
	IAPOSTUploadPhoto,
	IAPOSTViews,
	IAPUTSave,
	IRGETMany,
	IRGETProgram,
	IRPOSTUploadPhoto,
	IRPOSTViews,
	IRPUTSave,
	RGETManySchema,
	RGETProgramSchema,
	RPOSTUploadPhotoSchema,
	RPUTSaveSchema,
	IADELETEProgram,
} from "./validators";
import { Requests } from "@app/api";

const programs = {
	content: programContents,
	getMany: (data: IAGETMany): Promise<IRGETMany> =>
		Requests.send("GET", "/api/programs", data, null, {
			responseSchema: RGETManySchema,
		}),
	getById: (data: IAGETProgram): Promise<IRGETProgram> =>
		Requests.send("GET", "/api/programs/:id", data, null, {
			responseSchema: RGETProgramSchema,
		}),
	save: (data: IAPUTSave): Promise<IRPUTSave> => {
		if (data.id) {
			return Requests.send("PUT", "/api/programs/:id", data, null, {
				responseSchema: RPUTSaveSchema,
			});
		}
		return Requests.send("POST", "/api/programs", data, null, {
			responseSchema: RPUTSaveSchema,
		});
	},
	recordView: (data: IAPOSTViews): Promise<IRPOSTViews> =>
		Requests.send("POST", "/api/programs/:program_id/views", data, null),
	uploadPhoto: (data: IAPOSTUploadPhoto): Promise<IRPOSTUploadPhoto> =>
		Requests.send(
			"POST",
			"/api/programs/photos/",
			data,
			contentTypeMultipart,
			{
				requestSchema: RPOSTUploadPhotoSchema,
			}
		),
	delete: (data: IADELETEProgram): Promise<void> =>
		Requests.send("DELETE", "/api/programs/:id", data, null),
	updateAll: (): Promise<void> =>
		Requests.send("PUT", "/api/programs/all-programs/"),
	updateSubjList: (): Promise<void> =>
		Requests.send("PUT", "/api/programs/subj-list/"),
	updateMinScoreLimits: (): Promise<void> =>
		Requests.send("PUT", "/api/programs/min-score-limits/"),
};
export default programs;
