import {
	IRLatoriProgram,
	RLatoriProgramSchema,
} from "@app/api/latori-programs/helper-schemas";
import {
	CLEAR_LATORI_PROGRAMS,
	DELETE_LATORI_PROGRAM,
	DELETE_MANY_LATORI_PROGRAMS,
	LOAD_LATORI_PROGRAM,
	LOAD_LATORI_PROGRAMS,
	UPDATE_LATORI_PROGRAM,
	UPDATE_MANY_LATORI_PROGRAMS,
} from "@app/redux/action-types";
import {
	ICRUDActionObjs,
	ICRUDActionTypes,
} from "@app/redux/common/crud-actions";
import { IReduxStateInstance } from "@app/redux/common/generics";
import { IModel, modelWrapper } from "@app/redux/common/model";
import {
	getMetaInformation,
	getStorageSchema,
	loadFromStorage,
} from "@app/redux/common/storage";
import { IStorageSettings } from "@app/redux/common/unite-reducers";
import {
	defaultReducer,
	getDefaultStorageSettings,
} from "@app/redux/default-settings";
import { OptionalKeys } from "@app/utils/generics";
import Joi, { getJoiObjectKeys, SchemaToType } from "@app/utils/joi";
import { Action, Store } from "redux";

type DOC = IRLatoriProgram;
type IdKey = "id";
type IdType = number;
const keyOfId: IdKey = "id";

// ==============ACTIONS===============

export const latoriProgramCRUDActionTypes = {
	updateOne: UPDATE_LATORI_PROGRAM,
	updateMany: UPDATE_MANY_LATORI_PROGRAMS,
	loadOne: LOAD_LATORI_PROGRAM,
	loadMany: LOAD_LATORI_PROGRAMS,
	deleteOne: DELETE_LATORI_PROGRAM,
	deleteMany: DELETE_MANY_LATORI_PROGRAMS,
	clearAll: CLEAR_LATORI_PROGRAMS,
} as const;

export type LatoriProgramActions = ICRUDActionObjs<
	IdKey,
	IdType,
	IRLatoriProgram,
	typeof latoriProgramCRUDActionTypes
>;

// ==============REDUCER==============

export type IStateLatoriPrograms = IReduxStateInstance<DOC>;

export const latoriProgramsStorageSettings: IStorageSettings = getDefaultStorageSettings(
	"latoriPrograms"
);

export const LatoriProgramsReducer = defaultReducer<
	IStateLatoriPrograms,
	LatoriProgramActions
>("LatoriProgramModel", latoriProgramsStorageSettings);

// ==============MODEL=================

const MetaInformationSchema = Joi.object({
	lastFullLoadTime: Joi.date(),
});

type IMetaInformation = SchemaToType<typeof MetaInformationSchema>;

function latoriProgramModelWrapper<
	State extends { latoriPrograms: IReduxStateInstance<DOC> },
	CRUDActions extends ICRUDActionTypes
>(store: Store<State, Action<any>>, CRUDActionTypes: CRUDActions) {
	const dockeys = getJoiObjectKeys(RLatoriProgramSchema);
	const Model = modelWrapper<IdKey, IdType, DOC, CRUDActions, State>({
		keyOfId,
		getInstances: () => store.getState()["latoriPrograms"],
		store,
		syncronousCRUDActionTypes: CRUDActionTypes,
		dockeys,
		storageSettings: latoriProgramsStorageSettings,
	});

	class LatoriPrograms extends Model<LatoriPrograms> implements DOC {
		id: DOC["id"];
		uni_id: DOC["uni_id"];
		officialName: DOC["officialName"];
		subjects: DOC["subjects"];
		numsBySubjs: DOC["numsBySubjs"];
		coefficients: DOC["coefficients"];
		minScoreLimits: DOC["minScoreLimits"];
		minScores: DOC["minScores"];
		medianScores: DOC["medianScores"];
		yearOfUpdatedInfo: DOC["yearOfUpdatedInfo"];
		// created_at: DOC["created_at"];
		// updated_at: DOC["updated_at"];

		constructor(doc: OptionalKeys<DOC, IdKey>) {
			super(doc);
			for (const key of dockeys) {
				this[key as any] = doc[key as any];
			}
		}

		static initialize() {
			const storageSchema = getStorageSchema(
				"ObjectId",
				RLatoriProgramSchema
			);
			loadFromStorage(
				LatoriPrograms as IModel,
				"latoriPrograms",
				storageSchema
			);
		}

		protected static metaInformation = getMetaInformation<IMetaInformation>(
			{},
			"latoriProgramsMetaInformation",
			MetaInformationSchema
		);
	}
	return LatoriPrograms;
}

export type ILatoriProgramModel = ReturnType<typeof latoriProgramModelWrapper>;
export type ILatoriProgramInstance = InstanceType<ILatoriProgramModel>;

export { latoriProgramModelWrapper };
