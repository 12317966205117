import * as React from "react";
import "./styles/popup.min.css";

interface IPopupProps {
	onClose: () => void;
}

class Popup extends React.Component<IPopupProps> {
	_isMounted = false;
	bodyInitialOverflow: string | null;

	componentDidMount() {
		this._isMounted = true;
		this.bodyInitialOverflow = document.body.style.overflow;
		document.body.style.overflow = "hidden";
	}

	componentWillUnmount() {
		this._isMounted = false;
		document.body.style.overflow = this.bodyInitialOverflow;
	}

	popupBlackClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) this.props.onClose();
	};

	render() {
		return (
			<div className="popupBlackDiv" onClick={this.popupBlackClick}>
				{this.props.children}
			</div>
		);
	}
}

interface IPopupContentProps {
	scrollable?: boolean;
	style?: React.CSSProperties;
	className?: string;
}

export const PopupContent: React.FC<IPopupContentProps> = ({
	scrollable = true,
	style,
	children,
	className,
}) => (
	<div
		className={`popup-content ${
			scrollable ? "scrollable" : ""
		} ${className || ""}`}
		style={style}
	>
		{children}
	</div>
);

export default Popup;
