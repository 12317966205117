import {
	getProgramsEssentialInfo,
	IGetProgramsEssentialInfo,
} from "@app/actions/programs";
import { IRootState } from "@app/reducers/root";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { History } from "history";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { StylesConfig } from "react-select/lib/styles";
import Header from "../Header";
import { IExamSubjectData, ITOUFaculty, TOUsubjects } from "./helpers/data";
import "./styles/lator-page.min.css";
import api from "@app/api";
import { match } from "react-router-dom";

interface IOwnProps {
	history: History;
	match: match<{ user_id?: string }>;
	handleCalculate: (
		programId: number,
		programName: string,
		data: IExamSubjectData[]
	) => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	getProgramsEssentialInfo: IGetProgramsEssentialInfo;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	chosenFaculty: ITOUFaculty["value"] | null;
	subjectsWithScores: IExamSubjectData[];
}

const tou_id = 26;
const disabledProgramIds: number[] = [1022];

const TouLatorPage: React.FC<IProps> = (props: IProps) => {
	const [error, setError] = useState<string>();
	const [state, setState] = useState<IState>({
		chosenFaculty: null,
		subjectsWithScores: [
			{ score: 0, subjectId: 1 },
			{ score: 0, subjectId: 2 },
		],
	});

	useEffect(() => {
		if (!props.programs) {
			props.getProgramsEssentialInfo().then(e => console.log(e));
		}
	}, [props]);

	useEffect(() => {
		setError(undefined);
	}, [state]);

	useEffect(() => {
		if (props.match.params.user_id)
			api.toulator.savePageView({
				user_id: props.match.params.user_id,
			});
	}, [props.match.params.user_id]);

	const handleChosenFacultyChange = (faculty: ITOUFaculty) =>
		setState({ ...state, chosenFaculty: faculty.value });

	const handleSubjectClick = useCallback(
		(id: number) => {
			const subject = TOUsubjects.find(e => e.id === id);
			if (!subject || subject.isCompulsory) return;
			const checked = state.subjectsWithScores.find(
				e => e.subjectId === id
			);
			if (checked) {
				setState({
					...state,
					subjectsWithScores: state.subjectsWithScores.filter(
						e => e.subjectId !== id
					),
				});
			} else if (state.subjectsWithScores.length === 2) {
				setState({
					...state,
					subjectsWithScores: [
						...state.subjectsWithScores,
						{ score: 0, subjectId: id },
					],
				});
			} else {
				setState({
					...state,
					subjectsWithScores: state.subjectsWithScores
						.splice(0, 2)
						.concat({ score: 0, subjectId: id }),
				});
			}
		},
		[state]
	);

	const facultyOptions = useMemo(
		(): ITOUFaculty[] =>
			!props.programs
				? []
				: [
						...props.programs
							.filter(
								e =>
									e.uni_id === tou_id &&
									e.foreignLangs !== null &&
									disabledProgramIds.indexOf(e.id) === -1
							)
							.map(e => ({ label: e.name, value: e.id })),
						// { label: "სხვა", value: -1 },
				  ],
		[props.programs]
	);

	const handleSubjectScoreChange = (id: number, score: number) =>
		setState({
			...state,
			subjectsWithScores: state.subjectsWithScores.map(e =>
				e.subjectId === id ? { score, subjectId: id } : e
			),
		});

	const isChecked = useCallback(
		(id: number) =>
			!!state.subjectsWithScores.find(e => e.subjectId === id),
		[state.subjectsWithScores]
	);

	const validateData = useCallback(() => {
		if (state.subjectsWithScores.length === 2) {
			setError("აირჩიეთ 3 საგანი.");
			return false;
		}

		for (let i = 0; i < state.subjectsWithScores.length; i++) {
			const element = state.subjectsWithScores[i];
			if (element.score === 0) {
				setError("გთხოვთ, ჩაწეროთ თქვენი ქულები.");
				return false;
			}
		}
		if (!state.chosenFaculty) {
			setError("გთხოვთ, აირჩიოთ ფაკულტეტი.");
			return false;
		}
		return true;
	}, [state.chosenFaculty, state.subjectsWithScores]);

	const handleCheckButtonClick = useCallback(() => {
		if (
			!validateData() ||
			!state ||
			!state.chosenFaculty ||
			!props.programs
		)
			return;
		const option = facultyOptions.find(
			e => e.value === state.chosenFaculty
		);
		const name = option ? option.label : "";
		props.handleCalculate(
			state.chosenFaculty,
			name,
			state.subjectsWithScores
		);
	}, [facultyOptions, props, state, validateData]);

	return (
		<>
			<Header showOnlyLogo history={props.history} />
			<div className="toulator-container">
				<div className="tou-banner">
					<span>
						გამოთვალე, მოხვდები თუ არა სასურველ ფაკულტეტზე !{" "}
					</span>
				</div>
				<div className="half-side">
					<span className="blue big-text">
						აირჩიე სპეციალობა, მონიშნე საგამოცდო საგნები და მიუთითე
						ქულა
					</span>
					<br />
					<div className="empty-space" />
					<div className="spectrum" />
				</div>
				<div className="half-side centered" id="right">
					<SubjectScoresSelector
						data={state.subjectsWithScores}
						handleSubjectClick={handleSubjectClick}
						isChecked={isChecked}
						handleChosenFacultyChange={handleChosenFacultyChange}
						chosenFaculty={state.chosenFaculty}
						handleScoreChange={handleSubjectScoreChange}
						facultyOptions={facultyOptions}
					/>
					{error && <div className="error-container">{error}</div>}
					<button
						className="primary-button"
						onClick={handleCheckButtonClick}
					>
						შეამოწმე
					</button>
				</div>
			</div>
		</>
	);
};

const makeBlue = (provided, state) => ({ ...provided, color: "#2792ff" });

const stylesConfig: StylesConfig = {
	indicatorSeparator: (provided, state) => ({ display: "none" }),
	dropdownIndicator: makeBlue,
	valueContainer: makeBlue,
	placeholder: makeBlue,
	singleValue: makeBlue,
};

interface SubjectScoresSelectorProps {
	data: IExamSubjectData[];
	handleSubjectClick: (id: number) => void;
	isChecked: (id: number) => boolean;
	handleChosenFacultyChange: (faculty: ITOUFaculty) => void;
	handleScoreChange: (id: number, score: number) => void;
	chosenFaculty: ITOUFaculty["value"] | null;
	facultyOptions: ITOUFaculty[];
}

const SubjectScoresSelector: React.FC<SubjectScoresSelectorProps> = React.memo(
	({
		data,
		handleSubjectClick,
		isChecked,
		chosenFaculty,
		handleChosenFacultyChange,
		handleScoreChange,
		facultyOptions,
	}) => {
		const subjectScore = useCallback(
			(id: number) => {
				const sbj = data.find(e => e.subjectId === id);
				return sbj ? sbj.score : 0;
			},
			[data]
		);

		return (
			<>
				{TOUsubjects.map(subj => (
					<OneSubjectScoreSelector
						key={subj.id}
						id={subj.id}
						name={subj.name}
						isCompulsory={subj.isCompulsory}
						isChecked={isChecked(subj.id)}
						handleClick={handleSubjectClick}
						score={subjectScore(subj.id)}
						handleScoreChange={handleScoreChange}
					/>
				))}
				<div className="program-selector">
					<CheckCircleIcon
						className={`${"check-icon"}${
							chosenFaculty ? " highlight-blue" : ""
						}`}
					/>
					<Select
						className="select"
						placeholder="აირჩიე ფაკულტეტი"
						styles={stylesConfig}
						isSearchable={false}
						options={facultyOptions}
						value={
							facultyOptions.find(
								e => e.value === chosenFaculty
							) || null
						}
						onChange={handleChosenFacultyChange}
					/>
				</div>
			</>
		);
	}
);

interface IOneSubjectScoreSelectorProps {
	id: number;
	name: string;
	isCompulsory: boolean;
	isChecked?: boolean;
	handleClick: (id: number) => void;
	handleScoreChange: (id: number, score: number) => void;
	score: number;
}

const OneSubjectScoreSelector: React.FC<IOneSubjectScoreSelectorProps> = ({
	handleClick,
	id,
	isCompulsory,
	name,
	isChecked,
	handleScoreChange,
	score,
}) => {
	const isCheckedOrComp: boolean = isCompulsory || !!isChecked;

	return (
		<div className="subject-scores-selector-container">
			<div
				className={`${"subject-selector"}${
					isCheckedOrComp ? " highlight-blue" : ""
				}`}
				onClick={() => handleClick(id)}
			>
				<CheckCircleIcon
					className="check-icon"
					style={{ color: isCheckedOrComp ? "unset" : undefined }}
				/>
				{name}
			</div>

			<div className="score-selector">
				<input
					type="number"
					value={score === 0 ? "" : score}
					onFocus={() => {
						if (!isChecked) {
							handleClick(id);
						}
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleScoreChange(id, Number(e.target.value))
					}
				/>
				ქულა
			</div>
		</div>
	);
};
const mapStateToProps = (state: IRootState) => ({
	programs: state.programs.info,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		getProgramsEssentialInfo,
	} as any
)(TouLatorPage);
