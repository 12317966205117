import * as React from "react";
import "../styles/glowing-bar.min.css";

interface IProps {
	numOfLines?: number;
	style?: React.CSSProperties;
	className?: string;
}

const GlowingBar: React.FC<IProps> = React.memo(props => {
	const arr = new Array(props.numOfLines || 0).fill(0);
	const className = `glowingBar`;
	return (
		<div className={className} style={props.style}>
			<div className="glow" />
			{props.numOfLines && (
				<div className="lines">
					{arr.map((e, i) => (
						<div key={i} />
					))}
				</div>
			)}
		</div>
	);
});

export default GlowingBar;
