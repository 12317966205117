import Joi, { SchemaToType } from "@app/utils/joi";

export const ToulatorUserActivitySchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	user_id: Joi.string()
		.allow(null)
		.required(),
	fullname: Joi.string()
		.allow(null)
		.required(),
	mobile: Joi.number()
		.integer()
		.allow(null)
		.required(),
	program_id: Joi.number()
		.integer()
		.required(),
	user_scores: Joi.array()
		.items(Joi.number().integer())
		.required(),
	passed: Joi.boolean()
		.truthy(1)
		.falsy(0)
		.allow(null)
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});
export type IToulatorUserActivity = SchemaToType<
	typeof ToulatorUserActivitySchema
>;

export const ToulatorRequestSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	user_id: Joi.string()
		.required()
		.allow(null),
	fullname: Joi.string().required(),
	mobile: Joi.number()
		.integer()
		.required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});
