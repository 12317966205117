import Joi, { SchemaToType } from "@app/utils/joi";

/* SMS groups */
export const RSMSGroupSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	sender_id: Joi.number()
		.integer()
		.required(),
	text: Joi.string().required(),
	each_message_count: Joi.number()
		.integer()
		.required(),
	total_numbers: Joi.number()
		.integer()
		.required(),
	success: Joi.number()
		.integer()
		.required(),
	failures: Joi.number()
		.integer()
		.required(),
	author: Joi.number()
		.integer()
		.required(),
});

export type IRSMSGroup = SchemaToType<typeof RSMSGroupSchema>;

export const SMSGroupSchema = RSMSGroupSchema.keys({
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export type ISMSGroup = SchemaToType<typeof SMSGroupSchema>;

/* SMS senders */
export const RSenderSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	title: Joi.string().required(),
});

export type IRSender = SchemaToType<typeof RSenderSchema>;

export const ISenderSchema = RSenderSchema.keys({
	active: Joi.boolean().required(),
	author: Joi.number().required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export type ISender = SchemaToType<typeof ISenderSchema>;
