import * as React from "react";
import img from "./styles/img/popupIcons.png";
import Popup from "./Widgets/Popup";
import { css } from "emotion";

interface IProps {
	onClose: () => void;
}

//

class HistoryPopup extends React.Component<IProps> {
	render() {
		return (
			<Popup onClose={() => "asd"}>
				<div className={Container}>
					<div className={Heading}>გინდა, ჩააბარო ისტორია?</div>
					<div className={Body}>
						<div className={ImgContainer}>
							<div className={ImgOne} />
							<div className={TextStyles}>ყველაზე სწრაფად</div>
						</div>
						<div className={ImgContainer}>
							<div className={ImgTwo} />
							<div className={TextStyles}>ყველაზე მარტივად</div>
						</div>
						<div className={ImgContainer}>
							<div className={ImgThree} />
							<div className={TextStyles}>ყველაზე იაფად</div>
						</div>
					</div>
					<button className={GoldenButton}>დააჭირე აქ</button>
				</div>
			</Popup>
		);
	}
}

const Container = css`
	width: 530px;
	margin: 15px auto;
	border-radius: 15px;
	background: white;
	padding-bottom: 5px;
`;

const Heading = css`
	background: linear-gradient(133deg, #b93efb00, #b93efb 49.5%, #ffffff00 50%),
		linear-gradient(to right, #5005af, #b818f2);
	width: 100%;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	font-family: RobotoUpperCase;
	padding: 20px;
`;

const Body = css`
	width: 100%;
	display: flex;

	@media screen and (max-width: 600px) {
		display: block;
	}
`;

const GoldenButton = css`
	width: 200px;
	margin: 20px auto;
	background: linear-gradient(135deg, #ff8500 50%, #62ff0000 50%),
		linear-gradient(to right, #ffc800, #ff8800);
	border-radius: 55px;
	border: none;
	box-shadow: 0px 3px #9e3b00;
	color: white;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	font-family: RobotoUpperCase;
	padding: 10px;
	text-shadow: 0px 2px #9e3b00;
	margin-bottom: 40px;
	display: block;
	cursor: pointer;
`;

const ImgContainer = css`
	width: 180px;
	margin: 5px;
	flex: 1;
	position: relative;
	text-align: center;

	@media screen and (max-width: 600px) {
		margin: 10px auto;
	}
`;

const ImgOne = css`
	width: 100%;
	height: 200px;
	background: url(${img});
	background-repeat: no-repeat;
	background-position-x: -4px;
`;

const ImgTwo = css`
	width: 100%;
	height: 200px;
	background: url(${img});
	background-repeat: no-repeat;
	background-position-x: -185px;
`;

const ImgThree = css`
	width: 100%;
	height: 200px;
	background: url(${img});
	background-repeat: no-repeat;
	background-position-x: -364px;
`;

const TextStyles = css`
	position: absolute;
	bottom: 0;
	color: #8911d3;
	font-weight: bold;
	text-align: center;
	font-family: RobotoUpperCase;
	left: 13%;
`;
export default HistoryPopup;
