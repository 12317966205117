import * as React from "react";
import classNames from "classnames";
import LoadingSVG from "@app/components/styles/img/LoadingWhite";
import Popup from "./Widgets/Popup";
import triggerEvent from "@app/utils/events";
import { _console } from "@app/commonJavascript";
import { connect } from "react-redux";
import { ILogin, login } from "@app/actions/user";
import { IAPOSTLogin } from "@app/api/auth/validators";

interface IOwnProps {
	onClose: (loggedInSuccessfully: boolean) => void;
	onSuccess?: Function;
}

type IStateProps = null;

interface IDispatchProps {
	login: ILogin;
}

interface IErrorType {
	mail?: boolean;
	mobile?: boolean;
	password?: boolean;
}

interface IState {
	mailOrMobile: string;
	password: string;
	signLoading: boolean;
	errors: IErrorType;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

class LoginPopup extends React.Component<IProps> {
	_isMounted: boolean;
	state = {
		mailOrMobile: "",
		password: "",
		signLoading: false,
		errors: {},
	} as IState;
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	onMailOrMobileChange = e => {
		this.setState({
			mailOrMobile: e.target.value,
			errors: { password: false, mail: false, mobile: false },
		});
	};
	onPasswordChange = e => {
		this.setState({
			password: e.target.value,
			errors: { password: false, mail: false, mobile: false },
		});
	};

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			this.onLoginClick();
		}
	};

	onLoginClick = () => {
		if (this.state.signLoading) {
			return;
		}
		if (!this.state.mailOrMobile || !this.state.password) {
			this.setState({
				errors: {
					mail: !this.state.mailOrMobile,
					mobile: !this.state.mailOrMobile,
					password: !this.state.password,
				},
			});
			return;
		}
		this.setState({
			signLoading: true,
		});
		const whereObj: IAPOSTLogin = {
			password: this.state.password,
			mail: undefined,
			mobile: undefined,
		};
		if (isNaN(+this.state.mailOrMobile))
			whereObj.mail = this.state.mailOrMobile;
		else whereObj.mobile = +this.state.mailOrMobile;
		this.props
			.login(whereObj)
			.then(data => {
				if (!this._isMounted) {
					return;
				}
				triggerEvent({
					category: "Popup",
					action: "Login",
					label: "from popup",
				});
				this.setState({
					signLoading: false,
				});
				this.props.onClose(true);
				if (this.props.onSuccess) this.props.onSuccess();
			})
			.catch(err => {
				_console.error(err);
				if (!this._isMounted) {
					return;
				}
				const errors: IErrorType = {};
				if (err.response && err.response.data) {
					if (err.response.data.mail) {
						errors.mail = true;
					}
					if (err.response.data.mobile) {
						errors.mobile = true;
					}
					if (err.response.data.password) {
						errors.password = true;
					}
				}
				this.setState({
					errors,
					signLoading: false,
				});
				triggerEvent(
					{
						category: "Popup",
						action: "Login error",
						label: "from popup",
					},
					(err.response || {}).data
				);
			});
	};

	onMurtskuRegisterClick = () => {
		triggerEvent({
			category: "Button",
			action: "Registration Click",
			label: "from popup",
		});
		window.open("https://murtsku.com/index.php?reg", "__blank");
	};

	onMurtskuResetPasswdClick = () => {
		triggerEvent({
			category: "Button",
			action: "Reset Password Click",
			label: "from popup",
		});
		window.open("https://murtsku.com/index.php?reset", "__blank");
	};

	onWhatIsMurtskuClick = () => {
		window.open("https://murtsku.com/", "__blank");
	};

	render() {
		let errorText = "";
		if (
			this.state.mailOrMobile &&
			this.state.errors.mail &&
			!this.state.errors.password
		) {
			errorText = "ელფოსტა არასწორია";
		} else if (
			this.state.password &&
			this.state.errors.password &&
			!this.state.errors.mail
		) {
			errorText = "პაროლი არასწორია";
		} else if (this.state.errors.mail || this.state.errors.password) {
			errorText = "ელფოსტა ან პაროლი არასწორია";
		}
		return (
			<Popup onClose={() => this.props.onClose(false)}>
				<div className="loginPopupContent">
					<div className="Header">მურწყუთი ავტორიზაცია</div>
					<div className="Body">
						<div className="Input">
							<input
								type="text"
								name="mail"
								className={classNames({
									"no-arrows": true,
									incorrect: !!this.state.errors.mail,
								})}
								value={this.state.mailOrMobile}
								onChange={this.onMailOrMobileChange}
								onKeyPress={this.onKeyPress}
								placeholder={"მობილური/ელფოსტა"}
							/>
							<input
								type="password"
								className={classNames({
									incorrect: !!this.state.errors.password,
								})}
								value={this.state.password}
								onChange={this.onPasswordChange}
								onKeyPress={this.onKeyPress}
								placeholder={"პაროლი"}
							/>
						</div>
						{errorText && <span>{errorText}</span>}
						<div className="Buttons">
							<button onClick={this.onLoginClick}>
								<span>შესვლა</span>
								{this.state.signLoading && (
									<LoadingSVG width={25} height={25} />
								)}
							</button>
							<button onClick={this.onMurtskuResetPasswdClick}>
								პაროლის აღდგენა
							</button>
							<button onClick={this.onMurtskuRegisterClick}>
								მურწყუზე რეგისტრაცია
							</button>
						</div>
					</div>
				</div>
			</Popup>
		);
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(null, ({
	login,
} as any) as IDispatchProps)(LoginPopup);
