import api from "@app/api";
import {
	IUniExpoVideo,
	socialMedias,
	SocialMediaTypes,
} from "@app/api/unis/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { match } from "react-router-dom";
import FancyInput from "../Widgets/FancyInput";
import FancySelect from "../Widgets/FancySelect";
import SnackNotification, { IVariantType } from "../Widgets/SnackNotification";
import SocialMediaVideo from "../Widgets/SocialMediaVideo";

const AdminUniExpoVideosPage: React.FC<{
	match: match<{ uni_id: string }>;
}> = ({ match }) => {
	const [video, setVideo] = useState<Partial<IUniExpoVideo>>({
		uni_id: +match.params.uni_id,
		social_media: SocialMediaTypes.YouTube,
	});
	const [loaded, setLoaded] = useState<boolean>(false);

	const [notificationType, setNotificationType] = useState<IVariantType>();
	const [dialogBoxOpen, setDialogBoxOpen] = useState<boolean>(false);
	const [dialogBoxMessage, setDialogMessage] = useState<string>("");

	useEffect(() => {
		const removeLoader = addLoader();
		api.unis
			.getUniExpoVideoByUniId({ uni_id: +match.params.uni_id })
			.then(data => {
				removeLoader();
				if (data) setVideo(data);
				setLoaded(true);
			});
	}, [match.params.uni_id]);

	const handleDialogClose = useCallback(() => setDialogBoxOpen(false), []);

	const onSave = () => {
		if (!video.videoURL) {
			setNotificationType("warning");
			setDialogMessage("მიუთითეთ ვიდეოს ლინკი");
			setDialogBoxOpen(true);
			return;
		}
		if (!video.social_media) {
			setNotificationType("warning");
			setDialogMessage("აირჩიეთ სოციალური მედია");
			setDialogBoxOpen(true);
			return;
		}
		const removeLoader = addLoader();
		api.unis
			.saveUniExpoVideo({
				id: video.id,
				uni_id: +match.params.uni_id,
				social_media: video.social_media,
				videoURL: video.videoURL,
			})
			.then(data => {
				setVideo(data);
				setNotificationType("success");
				setDialogMessage("ცვლილებები წარმატებით შეინახა");
				setDialogBoxOpen(true);
				removeLoader();
			})
			.catch(e => {
				setNotificationType("error");
				setDialogMessage("შენახვისას დაფიქსირდა შეცდომა: " + e);
				setDialogBoxOpen(true);
				removeLoader();
			});
	};

	const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setVideo(oldVideo => ({
			...oldVideo,
			videoURL: value,
		}));
	};

	return (
		<div className="main">
			<h2 style={{ textAlign: "center" }}>საგამოფენო ვიდეო</h2>
			<FancySelect
				defaultValue={video.social_media || null}
				title="სოციალური მედია"
				items={socialMedias.map(el => ({ id: el, name: el }))}
				onChange={(socialMedia: SocialMediaTypes) =>
					setVideo(oldVideo => ({
						...oldVideo,
						social_media: socialMedia,
					}))
				}
			/>
			<br />
			<VideoContainer>
				<FancyInput
					defaultValue={video.videoURL || null}
					title="LIVE ვიდეოს ლინკი"
					onChange={onUrlChange}
				/>
				{video.videoURL && video.social_media && (
					<SocialMediaVideo
						url={video.videoURL}
						socialMedia={video.social_media}
						style={{
							display: "inline-block",
							verticalAlign: "middle",
							// width: 300,
							// height: 200,
						}}
					/>
				)}
			</VideoContainer>
			<br />
			<button
				className="adminPrimaryButton add"
				onClick={onSave}
				style={{ margin: 30 }}
			>
				შენახვა
			</button>
			<SnackNotification
				autoHideDuration={6000}
				onClose={handleDialogClose}
				message={dialogBoxMessage}
				variant={notificationType}
				open={dialogBoxOpen}
			/>
		</div>
	);
};

const VideoContainer = styled.div`
	display: inline-block;
	vertical-align: top;
	text-align: center;
`;

export default AdminUniExpoVideosPage;
