import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
interface IProps {
	title: string;
}
const stylesObj = {
	singlePanel: {
		background: "linear-gradient(to right, #151722, #11121b)",
		border: "1px solid #23263e",
		color: "white",
		borderRadius: 0,
		overflow: "hidden",
	},
	icon: {
		color: "white",
	},
	heading: {
		fontSize: 18,
		color: "white",
	},
	content: {
		color: "white",
		width: "100%",
		margin: "0 auto",
		fontSize: "0.875rem",
		fontFamily: "RobotoUpperCase",
		fontWeight: 400,
		lineHeight: 1.5,
	},
};

const SimpleExpansionPanel: React.FC<IProps> = props => {
	return (
		<ExpansionPanel style={stylesObj.singlePanel}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon style={stylesObj.icon} />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<Typography style={stylesObj.heading}>{props.title}</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<div style={stylesObj.content}>{props.children}</div>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export default SimpleExpansionPanel;
