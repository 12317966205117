import React from "react";

const CalculatorSVG = props => (
	<>
		<svg
			version="1.1"
			id="Capa_1"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			style={{ enableBackground: "new 0 0 512 512" }}
			width={24}
			height={24}
			fill={"white"}
			{...props}
		>
			<g>
				<g>
					<path
						d="M396.911,0H115.089C87.93,0,65.834,22.096,65.834,49.255v413.49c0,27.159,22.096,49.255,49.255,49.255h281.822
			c27.159,0,49.255-22.096,49.255-49.255V49.255C446.166,22.096,424.07,0,396.911,0z M133.343,85.096c0-8.284,6.716-15,15-15
			h215.313c8.284,0,15,6.716,15,15v29.618c0,8.284-6.716,15-15,15H148.343c-8.284,0-15-6.716-15-15V85.096z M183.844,421.916
			c0,8.284-6.716,15-15,15h-20.5c-8.284,0-15-6.716-15-15v-14.212c0-8.284,6.716-15,15-15h20.5c8.284,0,15,6.716,15,15V421.916z
			 M183.844,333.146c0,8.284-6.716,15-15,15h-20.5c-8.284,0-15-6.716-15-15v-14.212c0-8.284,6.716-15,15-15h20.5
			c8.284,0,15,6.716,15,15V333.146z M282.479,421.916c0,8.284-6.716,15-15,15h-20.5c-8.285,0-15-6.716-15-15v-14.212
			c0-8.284,6.715-15,15-15h20.5c8.284,0,15,6.716,15,15V421.916z M282.479,333.146c0,8.284-6.716,15-15,15h-20.5
			c-8.285,0-15-6.716-15-15v-14.212c0-8.284,6.715-15,15-15h20.5c8.284,0,15,6.716,15,15V333.146z M282.479,244.375
			c0,8.285-6.716,15-15,15H148.343c-8.284,0-15-6.716-15-15v-14.212c0-8.284,6.716-15,15-15h119.136c8.284,0,15,6.716,15,15V244.375
			z M381.114,421.916c0,8.284-6.716,15-15,15h-20.5c-8.284,0-15-6.716-15-15v-14.212c0-8.284,6.716-15,15-15h20.5
			c8.284,0,15,6.716,15,15V421.916z M381.114,333.146c0,8.284-6.716,15-15,15h-20.5c-8.284,0-15-6.716-15-15v-14.212
			c0-8.284,6.716-15,15-15h20.5c8.284,0,15,6.716,15,15V333.146z M381.114,244.375c0,8.285-6.716,15-15,15h-20.5
			c-8.284,0-15-6.716-15-15v-14.212c0-8.284,6.716-15,15-15h20.5c8.284,0,15,6.716,15,15V244.375z"
					/>
				</g>
			</g>
		</svg>
	</>
);

export default CalculatorSVG;
