import React from "react";
import { History } from "history";
import "../styles/expo-offers.min.css";
import OffersBanners from "./offers-banners";
import OffersList from "./offers-list";
import { match } from "react-router-dom";

interface IProps {
	match: match<{ uniId?: string }>;
	history: History;
}

const ExpoOffers: React.FC<IProps> = React.memo(function ExpoOffers({
	match,
	history,
}) {
	const uniId = Number(match.params.uniId);
	return (
		<div className="main main2 mainOffers">
			<h3>უნივერსიტეტის შეთავაზებები</h3>
			<div className="offersListAndBannersContainer">
				<OffersBanners history={history} />
				<OffersList history={history} uniId={uniId || undefined} />
			</div>
		</div>
	);
});

export default ExpoOffers;
