import { UniLogo } from "@app/components/Universities/FreeUniversityPage";
import React, { useCallback, useEffect, useState } from "react";
import {
	UniversityWithCoordinates,
	useUniversitiesWithCoordinates,
} from "../hooks/unis-with-coordinates";
import "../styles/expo-main-page.min.css";
import { History } from "history";

const mapSize = { width: 2300, height: 1300 };
const INITIAL_POS = { x: -551, y: -411 };
// const mapNodeSize = { width: 50, height: 50 };

interface ExpoMainMapProps {
	focusedUni?: UniversityWithCoordinates;
	history: History;
}

const ExpoMainMap: React.FC<ExpoMainMapProps> = React.memo(
	function ExpoMainMap({ focusedUni, history }) {
		const [mapScale, setMapScale] = useState<number>(1);
		const [mapTranslate, setMapTranslate] = useState({ x: 0, y: 0 });
		const [pos, setPos] = useState({ ...INITIAL_POS });

		useEffect(() => {
			const map = document.getElementById("map");
			let pos1 = 0,
				pos2 = 0,
				pos3 = 0,
				pos4 = 0;

			function elementDrag(e) {
				e.preventDefault();
				// calculate the new cursor position:
				pos1 = pos3 - e.clientX;
				pos2 = pos4 - e.clientY;
				pos3 = e.clientX;
				pos4 = e.clientY;
				// set the element's new position:
				if (map) {
					map.style.top = map.offsetTop - pos2 + "px";
					map.style.left = map.offsetLeft - pos1 + "px";
				}
			}

			function closeDragElement() {
				/* stop moving when mouse button is released:*/
				const map = document.getElementById("map");
				if (map)
					setPos({
						x: map.offsetLeft,
						y: map.offsetTop,
					});
				document.onmouseup = null;
				document.onmousemove = null;
			}

			function dragMouseDown(e) {
				e.preventDefault();
				// get the mouse cursor position at startup:
				pos3 = e.clientX;
				pos4 = e.clientY;
				document.onmouseup = closeDragElement;
				// call a function whenever the cursor moves:
				document.onmousemove = elementDrag;
			}

			if (map) map.onmousedown = dragMouseDown;
		}, []);

		useEffect(() => {
			if (focusedUni) {
				const map = document.getElementById("map");
				if (map) {
					setMapScale(1);
					const x =
						INITIAL_POS.x -
						pos.x +
						mapSize.width / 2 -
						focusedUni.coordinate.left;
					const y =
						INITIAL_POS.y -
						pos.y +
						mapSize.height / 2 -
						focusedUni.coordinate.top;
					setMapTranslate({ x, y });
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [focusedUni]);

		const onZoomIn = useCallback(() => {
			if (mapScale < 1.5) setMapScale(x => 1.2 * x);
			console.log("zoom in");
		}, [mapScale]);

		const onZoomOut = useCallback(() => {
			if (mapScale > 0.5) setMapScale(x => 0.8 * x);
			console.log("zoom out");
		}, [mapScale]);

		useEffect(() => {
			const map = document.getElementById("map");
			if (map)
				map.style.transform = `scale(${mapScale}) translate(${mapTranslate.x}px, ${mapTranslate.y}px)`;
		}, [mapScale, mapTranslate.x, mapTranslate.y]);
		return (
			<div className="mapContainer">
				<div className="mapZoomButtonsContainer">
					<MapZoomButtons onPlus={onZoomIn} onMinus={onZoomOut} />
				</div>

				<div className="mapContent" draggable={true}>
					<div id="map" className="map">
						<UniversitiesMap
							focusedUniId={focusedUni ? focusedUni.id : null}
							history={history}
							pos={pos}
						/>
					</div>
				</div>
			</div>
		);
	}
);

interface MapZoomButtonsProps {
	onPlus?: () => void;
	onMinus?: () => void;
}

const MapZoomButtons: React.FC<MapZoomButtonsProps> = React.memo(
	function MapZoomButtons({ onPlus, onMinus }) {
		return (
			<div className="zoomButtonsContainer">
				<div className="zoomButton plus" onClick={onPlus}>
					<span>+</span>
				</div>
				<div className="zoomButton minus" onClick={onMinus}>
					<span>-</span>
				</div>
			</div>
		);
	}
);

interface UniversitiesMapProps {
	focusedUniId: number | null;
	history: History;
	pos: {
		x: number;
		y: number;
	};
}

const UniversitiesMap: React.FC<UniversitiesMapProps> = React.memo(
	function UniversitiesMap({ focusedUniId, history, pos }) {
		const unisWcoordinates = useUniversitiesWithCoordinates();
		return (
			<>
				{unisWcoordinates !== undefined &&
					unisWcoordinates.map((e, i) => (
						<div
							style={{
								top: e.coordinate.top + "px",
								left: e.coordinate.left + "px",
							}}
							className="mapNodeContainer"
							id={`uni_${e.id}`}
							key={i}
						>
							<UniversityMapNode
								id={e.id}
								name={e.name || ""}
								logoUrl={e.logoUrl || ""}
								logoBGColor={e.logoBGColor || ""}
								isFocused={focusedUniId === e.id}
								history={history}
								pos={pos}
							/>
						</div>
					))}
			</>
		);
	}
);

interface UniversityMapNodeProps {
	id: number;
	name: string;
	logoUrl: string;
	logoBGColor: string;
	isFocused?: boolean;
	history: History;
	pos: {
		x: number;
		y: number;
	};
}

const UniversityMapNode: React.FC<UniversityMapNodeProps> = React.memo(
	function UniversityMapNode({
		id,
		name,
		logoUrl,
		logoBGColor,
		isFocused,
		history,
		pos,
	}) {
		const [showInfo, setShowInfo] = useState(false);
		const [nodePos, setNodePos] = useState({ x: 0, y: 0 });
		const onHover = () => {
			const mapNode = document.getElementById(`uni_${id}`);
			if (mapNode) {
				const y =
					mapSize.height / 2 -
					mapNode.offsetTop +
					INITIAL_POS.y -
					pos.y;
				const x =
					mapSize.width / 2 -
					mapNode.offsetLeft +
					INITIAL_POS.x -
					pos.x;
				console.log(x);
				setNodePos({ x, y });
			}
			setShowInfo(true);
		};
		return (
			<div
				id={`mapNode${id}`}
				className={`mapNode ${isFocused ? "focusedNode" : ""}`}
				onMouseEnter={onHover}
				onMouseLeave={() => setShowInfo(false)}
				onClick={() => history.push(`/uni-expo/${id}/room`)}
			>
				<UniLogo
					style={{
						width: "100%",
						height: "100%",
						borderRadius: "100%",
					}}
					logoUrl={logoUrl}
					logoBGColor={logoBGColor}
				/>
				{showInfo && (
					<div
						className="mapNodeInfoContainer"
						style={{
							...(nodePos.y > 70 && { top: 30 }),
							...(nodePos.x > 380
								? { left: (nodePos.x - 380) * 0.1 }
								: nodePos.x < -370
								? { left: nodePos.x + 200 }
								: {}),
						}}
					>
						<div className="mapNodeInfo">
							<div className="mapNodeInfoLogoContainer">
								<UniLogo
									style={{
										width: "100%",
										height: "100%",
										borderRadius: "100%",
									}}
									logoUrl={logoUrl}
									logoBGColor={logoBGColor}
								/>
							</div>
							<p className="mapNodeInfoTitle">{name}</p>
						</div>
					</div>
				)}
			</div>
		);
	}
);

export default ExpoMainMap;
