import api from "@app/api";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { funcToPromise, _console } from "@app/commonJavascript";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import { ThunkAction } from "redux-thunk";
import {
	CHANGE_PROGRAM_SETTINGS,
	CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT,
	DELETE_PROGRAM,
	LOAD_PROGRAMS_ESSENTIAL_INFO,
	LOAD_PROGRAMS_OF_ONE_UNI,
} from "../action-types";

interface IProgramsEssentialInfoArrayAction {
	type: typeof LOAD_PROGRAMS_ESSENTIAL_INFO;
	info: IRProgram[];
	fullyLoaded: boolean | { [key: string]: boolean };
	lastUpdate: Date;
}
export const programsEssentialInfoArray = (
	programs: IRProgram[],
	fullyLoaded: boolean | { [key: string]: boolean } = false,
	lastUpdate: Date = new Date()
): IProgramsEssentialInfoArrayAction => {
	return {
		type: LOAD_PROGRAMS_ESSENTIAL_INFO,
		info: programs,
		fullyLoaded,
		lastUpdate: lastUpdate || new Date(),
	};
};

interface IChangeProgramIntraUniPositionCoefficientAction {
	type: typeof CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT;
	program_id: number;
	newCoefficient: number;
}

export const programIntraUniPositionCoefficient = (
	program_id: number,
	uni_id: number,
	newCoefficient: number
): IChangeProgramIntraUniPositionCoefficientAction => {
	api.programs
		.save({
			id: program_id,
			uni_id,
			programSettings: {
				intraUniPositionCoefficient: newCoefficient,
			},
		})
		.then();
	return {
		type: CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT,
		program_id,
		newCoefficient,
	};
};

interface ILoadProgramsOfUniAction {
	type: typeof LOAD_PROGRAMS_OF_ONE_UNI;
	programs: IRProgram[];
	uniId: number;
}

export const loadProgramsOfUni = (
	programs: IRProgram[],
	uniId: number
): ILoadProgramsOfUniAction => {
	return {
		type: LOAD_PROGRAMS_OF_ONE_UNI,
		programs,
		uniId,
	};
};

export const loadProgramsFromStorage = programsEssentialInfoArray;

export type IGetProgramsEssentialInfo = () => Promise<IRProgram[]>;

// tslint:disable-next-line:max-line-length
export const getProgramsEssentialInfo = (
	forceUpdate?: boolean
): ThunkAction<
	ReturnType<IGetProgramsEssentialInfo>,
	IRootState,
	null,
	any
> => (dispatch): ReturnType<IGetProgramsEssentialInfo> => {
	try {
		if (!forceUpdate && localStorage.programs) {
			const programs = JSON.parse(localStorage.programs);
			if (programs.info && programs.fullyLoaded === true) {
				return funcToPromise(() => {
					dispatch(
						programsEssentialInfoArray(
							programs.info,
							programs.fullyLoaded,
							programs.lastUpdate
						)
					);
					return programs.info;
				}) as ReturnType<IGetProgramsEssentialInfo>;
			}
		}
	} catch (e) {
		_console.log(e);
	}
	const locale = getLocale() as "ka" | "en";
	return api.programs.getMany({ locale }).then((data: IRProgram[]) => {
		dispatch(programsEssentialInfoArray(data, true, new Date()));
		return data;
	});
};

interface IProgramChangeArguments {
	programId: number;
	uniId: number;
	newCoefficient: number;
}
export type IChangeProgramIntraUniPositionCoefficient = (
	data: IProgramChangeArguments
) => void;

export const changeProgramIntraUniPositionCoefficient = (
	data: IProgramChangeArguments
): ThunkAction<
	ReturnType<IChangeProgramIntraUniPositionCoefficient>,
	IRootState,
	null,
	any
> => (dispatch): ReturnType<IChangeProgramIntraUniPositionCoefficient> => {
	dispatch(
		programIntraUniPositionCoefficient(
			data.programId,
			data.uniId,
			data.newCoefficient
		)
	);
	return;
};

interface IProgramChangeSettings {
	type: typeof CHANGE_PROGRAM_SETTINGS;
	program_id: number;
	info: Partial<IRProgram>;
}
export const programChangeSettings = (
	program_id: number,
	newUni: Partial<IRProgram>
): IProgramChangeSettings => {
	return {
		type: CHANGE_PROGRAM_SETTINGS,
		program_id,
		info: newUni,
	};
};

interface IDeleteProgram {
	type: typeof DELETE_PROGRAM;
	program_id: number;
}
export const deleteProgram = (program_id: number): IDeleteProgram => {
	api.programs.delete({ id: program_id }).then();
	return {
		type: DELETE_PROGRAM,
		program_id,
	};
};

// export const deleteProgram = (
// 	data: IADELETEProgram
// ): ThunkAction<
// 	ReturnType<IChangeProgramIntraUniPositionCoefficient>,
// 	IRootState,
// 	null,
// 	any
// > => (dispatch): ReturnType<IChangeProgramIntraUniPositionCoefficient> => {
// 	dispatch(deleteProgramAction(data.id));
// 	return;
// };

export type ProgramsActions =
	| IProgramsEssentialInfoArrayAction
	| IChangeProgramIntraUniPositionCoefficientAction
	| ILoadProgramsOfUniAction
	| IProgramChangeSettings
	| IDeleteProgram;
