import * as React from "react";
import EditIcon from "@material-ui/icons/Edit";
import "./styles/fancyInput.min.css";
import { CSSProperties, useEffect, useState } from "react";

interface IProps {
	defaultValue: string | null;
	title?: string;
	placeholder?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	style?: CSSProperties;
}

const FancyInput: React.FC<IProps> = props => {
	const [value, setValue] = useState<string>(props.defaultValue || "");

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		if (props.onChange) props.onChange(e);
	};

	useEffect(() => {
		setValue(props.defaultValue || "");
	}, [props.defaultValue]);

	return (
		<div className="fancyInputAndTitle" style={props.style}>
			<h2>{props.title}</h2>
			<div className="fancyInputFull">
				<div className="icon">
					<EditIcon className="fancyInputEditIcon" />
				</div>
				<input
					placeholder={props.placeholder}
					type="text"
					className="fancyInputField"
					value={value}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};

export default FancyInput;
