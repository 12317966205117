import {
	IAGETRecentStats,
	IAGETStudentsStats,
	IAGETSubscribedUnisStats,
	IAGETUniAdmissionStats,
	IAGETUniViewStats,
	IAGETViewsStats,
	IRGETRecentStats,
	IRGETSubscribedUnisStats,
	IRGETUniAdmissionStats,
	IRGETUniViewStats,
	IRGETViewsStats,
	RGETSubscribedUnisStatsSchema,
	RGETViewsStatsSchema,
} from "./validators";
import { Requests } from "@app/api";

const unisStats = {
	getRecent: (data: IAGETRecentStats): Promise<IRGETRecentStats> =>
		Requests.send("GET", "/api/unis/:uni_id/stats/recent", data, null),
	getSubscribers: (
		data: IAGETSubscribedUnisStats
	): Promise<IRGETSubscribedUnisStats> =>
		Requests.send("GET", "/api/unis/:uni_id/stats/subscribes", data, null, {
			responseSchema: RGETSubscribedUnisStatsSchema,
		}),
	getViews: (data: IAGETViewsStats): Promise<IRGETViewsStats> =>
		Requests.send("GET", "/api/unis/:uni_id/stats/views", data, null, {
			responseSchema: RGETViewsStatsSchema,
		}),
	getExcelFile: (data: IAGETStudentsStats): Promise<any> =>
		Requests.send("GET", "/api/unis/:uni_id/stats/sheets", data, {
			responseType: "blob",
		}),
	getViewersCount: (data: IAGETUniViewStats): Promise<IRGETUniViewStats> =>
		Requests.send("GET", "/api/unis/:uni_id/stats/totalViews", data, null),
	getUniAdmissionsCount: (
		data: IAGETUniAdmissionStats
	): Promise<IRGETUniAdmissionStats> =>
		Requests.send("GET", "/api/unis/:uni_id/stats/admissions", data, null),
};
export default unisStats;
