import api from "@app/api";
import { History } from "history";
import React, { useState } from "react";
import { match } from "react-router-dom";
import { isCorrectNumber } from "../Admins/sms/send-sms-page";
import Popup, { PopupContent } from "../Widgets/Popup";
import { IExamSubjectData } from "./helpers/data";
import { hasMinimumPassingScores } from "./helpers/functions";
import TouLatorPage from "./lator-page";
import TouLatorResultSuccess from "./lator-result-success";
import "./styles/lator-page.min.css";

enum Component {
	toulator,
	success,
}

interface IProps {
	history: History;
	match: match<{ user_id?: string }>;
}

enum PopupType {
	success,
	error,
	userInfo,
}

interface IStudentResults {
	programId: number;
	scores: number[];
	programName: string;
	passed: boolean;
}

export const TouLatorPageLanding: React.FC<IProps> = props => {
	const [component, setComponent] = useState<Component>(Component.toulator);
	const [studentResults, setStudentResults] = useState<IStudentResults>();
	const [popup, setPopup] = useState<PopupType | null>(null);
	const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
	const onPopupClose = () => {
		setPopup(null);
	};

	const sendCallRequest = () => {
		if (!userInfo || !studentResults) {
			alert("დაფიქსირდა შეცდომა");
			return;
		}
		api.toulator
			.saveRequest({
				user_id: props.match.params.user_id,
				fullname: userInfo.fullname,
				mobile: userInfo.mobile,
			})
			.then(e => setPopup(PopupType.success))
			.catch(e => setPopup(PopupType.error));
	};

	const handlePreCalculation = (
		programId: number,
		programName: string,
		data: IExamSubjectData[]
	) => {
		setStudentResults(calculate(programId, programName, data));
		if (!userInfo) {
			setPopup(PopupType.userInfo);
		} else handleUserInfoSubmit(userInfo);
	};

	const handleUserInfoSubmit = (user: UserInfo) => {
		onPopupClose();
		setUserInfo(user);
		handlePostCalculation(user);
	};

	const saveScores = (user: UserInfo, results: IStudentResults) => {
		const { programId, scores, passed } = results;
		api.toulator
			.saveUserActivity({
				user_id: props.match.params.user_id,
				program_id: programId,
				user_scores: scores,
				mobile: user.mobile,
				fullname: user.fullname,
				passed,
			})
			.then();
		showResults();
	};

	const handlePostCalculation = (user: UserInfo) => {
		if (!studentResults) return;
		saveScores(user, studentResults);
		showResults();
	};

	const showResults = () => {
		if (!studentResults) return;
		const { passed } = studentResults;
		if (!passed) {
			props.history.push("toulator-res");
		} else {
			setComponent(Component.success);
		}
	};

	return (
		<>
			{popup === PopupType.error && <ErrorPopup onClose={onPopupClose} />}
			{popup === PopupType.success && (
				<SuccessPopup onClose={onPopupClose} />
			)}
			{popup === PopupType.userInfo && (
				<UserInfoPopup
					onClose={onPopupClose}
					onSubmit={handleUserInfoSubmit}
				/>
			)}
			{component === Component.toulator ? (
				<TouLatorPage
					{...props}
					handleCalculate={handlePreCalculation}
				/>
			) : (
				<TouLatorResultSuccess
					{...props}
					handleCallRequest={sendCallRequest}
					programName={
						studentResults &&
						(studentResults.programId !== -1
							? studentResults.programName
							: "ნებისმიერი")
					}
					programId={studentResults && studentResults.programId}
				/>
			)}
		</>
	);
};

const calculate = (
	programId: number,
	programName: string,
	data: IExamSubjectData[]
): IStudentResults => {
	const scores: number[] = [];
	for (let i = 0; i < 5; i++) {
		scores.push(0);
	}
	data.forEach(e => {
		scores[e.subjectId] = e.score;
	});
	const passed = hasMinimumPassingScores(data);
	return { programId, scores, programName, passed };
};

const SuccessPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => (
	<Popup onClose={onClose}>
		<PopupContent
			style={{
				width: "285px",
				height: "300px",
			}}
		>
			<div className="popup_container">
				<div className="text">
					თქვენი მოთხოვნა გაგზავნილია და უნივერსიტეტის წარმომადგენელი
					აუცილებლად დაგიკავშირდებათ.
				</div>
				<button className="clear_button" onClick={onClose}>
					გასაგებია
				</button>
			</div>
		</PopupContent>
	</Popup>
);

const ErrorPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => (
	<Popup onClose={onClose}>
		<PopupContent
			style={{
				width: "285px",
				height: "210px",
			}}
		>
			<div className="popup_container">
				<div className="text">დაფიქსირდა შეცდომა, სცადეთ თავიდან.</div>
				<button className="clear_button" onClick={onClose}>
					გასაგებია
				</button>
			</div>
		</PopupContent>
	</Popup>
);

interface UserInfo {
	fullname: string;
	mobile: number;
}

export const UserInfoPopup = React.memo<{
	onClose: () => void;
	onSubmit: (userInfo: UserInfo) => void;
}>(function UserInfoPopup({ onClose, onSubmit }) {
	const [name, setName] = useState("");
	const [mobile, setMobile] = useState<number>();
	const [error, setError] = useState<string>();

	const validateData = () => {
		if (!name) {
			setError("შეიყვანეთ სახელი და გვარი.");
			return false;
		}
		if (!mobile) {
			setError("შეიყვანეთ ტელეფონის ნომერი.");
			return false;
		}
		if (!isCorrectNumber(mobile + "")) {
			setError("სწორად შეიყვანეთ ტელეფონის ნომერი.");
			return false;
		}
		setError(undefined);
		return true;
	};

	const handleSend = () => {
		if (!validateData()) return;
		onSubmit({ fullname: name!, mobile: mobile! });
	};

	return (
		<Popup onClose={onClose}>
			<PopupContent
				style={{
					width: "500px",
				}}
			>
				<h2>შედეგების გასაგებად, შეიყვანე შენი:</h2>
				<input
					placeholder="სახელი და გვარი"
					className="toulatori-large-input"
					value={name}
					onChange={e => setName(e.target.value)}
				/>
				<input
					placeholder="ტელეფონის ნომერი"
					type="number"
					className="toulatori-large-input"
					defaultValue={"" + (mobile || "")}
					onChange={e => setMobile(+e.target.value)}
				/>
				{error && (
					<div
						className="error-container"
						style={{ marginBottom: -20 }}
					>
						{error}
					</div>
				)}
				<button
					className="primary-button"
					onClick={handleSend}
					style={{ transform: "none", width: "100%" }}
				>
					შედეგები
				</button>
			</PopupContent>
		</Popup>
	);
});
