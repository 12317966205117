/*
	tslint:disable max-line-length
*/

const CSB = [
	51,
	121,
	163,
	188,
	257,
	350,
	351,
	352,
	353,
	359,
	363,
	369,
	412,
	442,
	551,
	552,
	791,
	821,
	582,
	807,
	725,
	813,
	798,
	773,
	776,
	788,
	789,
];
const CSL = [56, 57, 258, 357, 361, 365, 521, 554, 560, 729, 732, 795, 823];
const CSM = [812, 49, 845, 343, 355, 369, 426, 446, 556, 589, 579, 808, 806];
const CST = [
	34,
	38,
	40,
	811,
	41,
	101,
	102,
	103,
	108,
	109,
	105,
	114,
	112,
	116,
	120,
	122,
	123,
	127,
	140,
	142,
	145,
	151,
	154,
	167,
	168,
	187,
	230,
	232,
	237,
	245,
	259,
	260,
	261,
	262,
	809,
	263,
	264,
	277,
	366,
	367,
	792,
	418,
	419,
	420,
	476,
	535,
	562,
	578,
	581,
	713,
	800,
];
const CSG = [
	44,
	157,
	158,
	159,
	251,
	290,
	815,
	358,
	362,
	364,
	369,
	423,
	818,
	425,
	454,
	555,
	561,
	585,
	590,
	601,
	661,
	695,
	808,
	731,
	797,
	763,
	770,
	775,
];
const CSH = [
	1,
	2,
	810,
	43,
	59,
	131,
	841,
	844,
	203,
	210,
	251,
	252,
	253,
	278,
	280,
	281,
	287,
	288,
	300,
	301,
	303,
	304,
	305,
	344,
	369,
	377,
	428,
	837,
	838,
	869,
	467,
	466,
	497,
	820,
	564,
	565,
	566,
	572,
	576,
	573,
	580,
	606,
	608,
	866,
	650,
	668,
	690,
	706,
	708,
	808,
	806,
	805,
	804,
	738,
	799,
];
const CTS = [
	52,
	179,
	257,
	346,
	354,
	360,
	378,
	417,
	443,
	455,
	524,
	553,
	583,
	701,
	711,
	807,
	757,
	762,
];
const CMS = [194, 195, 369, 568, 619, 771];
const CSE = [50, 53, 213, 295, 421, 422, 832, 584, 710];

export const getCUSuggestedProgramId = (
	currentProgramId: number
): number | null => {
	if (CSB.indexOf(currentProgramId) >= 0) return 591;
	if (CSL.indexOf(currentProgramId) >= 0) return 594;
	if (CSM.indexOf(currentProgramId) >= 0) return 596;
	if (CST.indexOf(currentProgramId) >= 0) return 598;
	if (CSG.indexOf(currentProgramId) >= 0) return 602;
	if (CSH.indexOf(currentProgramId) >= 0) return 606;
	if (CTS.indexOf(currentProgramId) >= 0) return 609;
	if (CMS.indexOf(currentProgramId) >= 0) return 593;
	if (CSE.indexOf(currentProgramId) >= 0) return 611;
	return null;
};
