import api from "@app/api";
import {
	ADD_EVENT,
	ADD_NEWS,
	CHANGE_EVENT_SETTINGS,
	CHANGE_NEWS_SETTINGS,
	DISPLAY_UNI_SUBSCRIPTION_POPUP,
	LOAD_DASHBOARD_STATISTICS,
	LOAD_EVENTS,
	LOAD_EVENTS_FOR_UNI,
	LOAD_NEWS,
	LOAD_NEWS_FOR_UNI,
	LOAD_TEMP_INFO,
	LOAD_UNI_DISPLAY_INFO,
	LOAD_UNI_SHORT_STATS,
	LOAD_USER_RESERVED_EVENTS,
	REMOVE_EVENT_BY_ID,
	REMOVE_UNI_DISPLAY_INFO,
	RESERVE_EVENT,
	UNRESERVE_EVENT,
	VIEW_PROGRAM_PAGE,
	VIEW_UNI_PAGE,
} from "../action-types";
import { IEvent } from "@app/api/events/helpher-schemas";
import { ILogoutAction } from "./user";
import { INews, IRGETNewsByUniId } from "@app/api/news/validators";
import { IRGETRecentStats } from "@app/api/unis/stats/validators";
import { IRGETReservations, IRPOSTReserve } from "@app/api/events/validators";
import { IRGETUni, IRGETUniManyEvents } from "@app/api/unis/validators";
import { IRootState } from "@app/reducers/root";
import { IStateTemp } from "@app/reducers/temp";
import { IUniContent, IUniMainInfo } from "@app/api/unis/helper-schemas";
import { ThunkAction } from "redux-thunk";
import { Without } from "@app/utils/generics";

interface IUniDisplayInfoAction {
	type: typeof LOAD_UNI_DISPLAY_INFO;
	uni_id: number;
	mainInfo?: IUniMainInfo;
	contents?: IUniContent[];
	loadTime: Date;
}

export const uniDisplayInfo = (data: IRGETUni): IUniDisplayInfoAction => {
	return {
		type: LOAD_UNI_DISPLAY_INFO,
		uni_id: data.id,
		mainInfo: data.mainInfo,
		contents: data.contents,
		loadTime: new Date(),
	};
};

interface ILoadEventsAction {
	type: typeof LOAD_EVENTS;
	user_events: IEvent[];
}

export const LoadEventsAction = (user_events: IEvent[]): ILoadEventsAction => {
	return {
		type: LOAD_EVENTS,
		user_events,
	};
};

export type ILoadEvents = () => Promise<IRGETUniManyEvents>;
// tslint:disable-next-line:max-line-length
export const LoadEvents = (): ThunkAction<
	ReturnType<ILoadEvents>,
	IRootState,
	null,
	TempActions
> => (dispatch): ReturnType<ILoadEvents> => {
	return api.events.getAll().then(data => {
		const user_events = data;
		dispatch(LoadEventsAction(user_events));
		return data;
	});
};

interface ILoadUserReservedEventsAction {
	type: typeof LOAD_USER_RESERVED_EVENTS;
	user_reserved_events: number[];
}

export const loadReservedEvents = (
	user_reserved_events: number[]
): ILoadUserReservedEventsAction => {
	return {
		type: LOAD_USER_RESERVED_EVENTS,
		user_reserved_events,
	};
};

export type ILoadUserReservedEvents = () => Promise<IRGETReservations>;
// tslint:disable-next-line:max-line-length
export const loadUserReservedEvents = (): ThunkAction<
	ReturnType<ILoadUserReservedEvents>,
	IRootState,
	null,
	TempActions
> => (dispatch): ReturnType<ILoadUserReservedEvents> => {
	return api.users.events.getReservations().then(data => {
		const user_reserved_events = data;
		dispatch(loadReservedEvents(user_reserved_events));
		return data;
	});
};

interface IReserveEventAction {
	type: typeof RESERVE_EVENT;
	event_id: number;
}

export const reserveEventAction = (event_id: number): IReserveEventAction => {
	return {
		type: RESERVE_EVENT,
		event_id,
	};
};

export type IReserveEvent = (event_id: number) => Promise<IRPOSTReserve>;
// tslint:disable-next-line:max-line-length
export const reserveEvent = (
	event_id: number
): ThunkAction<ReturnType<IReserveEvent>, IRootState, null, TempActions> => (
	dispatch
): ReturnType<IReserveEvent> => {
	dispatch(reserveEventAction(event_id));
	return api.events.reserve({ id: event_id });
};

interface IUnReserveEventAction {
	type: typeof UNRESERVE_EVENT;
	event_id: number;
}

export const unReserveEventAction = (
	event_id: number
): IUnReserveEventAction => {
	return {
		type: UNRESERVE_EVENT,
		event_id,
	};
};

export type IUnReserveEvent = (event_id: number) => Promise<IRPOSTReserve>;
// tslint:disable-next-line:max-line-length
export const unReserveEvent = (
	event_id: number
): ThunkAction<ReturnType<IUnReserveEvent>, IRootState, null, TempActions> => (
	dispatch
): ReturnType<IUnReserveEvent> => {
	dispatch(unReserveEventAction(event_id));
	return api.events.reserve({ id: event_id });
};

interface ILoadEventsByUniIdAction {
	type: typeof LOAD_EVENTS_FOR_UNI;
	events: IEvent[];
	uni_id: number;
}

export const loadEventsByUniIdAction = (
	events: IEvent[],
	uni_id: number
): ILoadEventsByUniIdAction => {
	return {
		type: LOAD_EVENTS_FOR_UNI,
		events,
		uni_id,
	};
};

export type ILoadEventsByUniId = (
	uni_id: number
) => Promise<IRGETUniManyEvents>;
// tslint:disable-next-line:max-line-length
export const loadEventsByUniId = (
	uni_id: number
): ThunkAction<
	ReturnType<ILoadEventsByUniId>,
	IRootState,
	null,
	TempActions
> => (dispatch): ReturnType<ILoadEventsByUniId> => {
	return api.unis.getEvents({ uni_id }).then(data => {
		const events = data;
		dispatch(loadEventsByUniIdAction(events, uni_id));
		return data;
	});
};

export interface IDeleteEventById {
	type: typeof REMOVE_EVENT_BY_ID;
	id: number;
	uni_id: number;
}

export const deleteEventById = (
	id: number,
	uni_id: number
): IDeleteEventById => {
	return {
		type: REMOVE_EVENT_BY_ID,
		id,
		uni_id,
	};
};

interface IEventChangeSettings {
	type: typeof CHANGE_EVENT_SETTINGS;
	event_id: number;
	info: Partial<IEvent>;
	uni_id: number;
}
export const eventChangeSettings = (
	event_id: number,
	newEvent: Partial<IEvent>,
	uni_id: number
): IEventChangeSettings => {
	return {
		type: CHANGE_EVENT_SETTINGS,
		event_id,
		info: newEvent,
		uni_id,
	};
};

interface INewsChangeSettings {
	type: typeof CHANGE_NEWS_SETTINGS;
	news_id: number;
	info: Partial<INews>;
	uni_id: number;
}
export const newsChangeSettings = (
	news_id: number,
	changedNews: Partial<INews>,
	uni_id: number
): INewsChangeSettings => {
	return {
		type: CHANGE_NEWS_SETTINGS,
		news_id,
		info: changedNews,
		uni_id,
	};
};

interface IAddEvent {
	type: typeof ADD_EVENT;
	event: IEvent;
	uni_id: number;
}
export const addEvent = (newEvent: IEvent): IAddEvent => {
	return {
		type: ADD_EVENT,
		event: newEvent,
		uni_id: newEvent.uni_id,
	};
};

interface IAddNews {
	type: typeof ADD_NEWS;
	news: INews;
}

export const addNews = (newNews: INews): IAddNews => {
	return {
		type: ADD_NEWS,
		news: newNews,
	};
};

interface ILoadNewsAction {
	type: typeof LOAD_NEWS;
	user_news: INews[];
}

export const LoadNewsAction = (user_news: INews[]): ILoadNewsAction => {
	return {
		type: LOAD_NEWS,
		user_news,
	};
};

export type ILoadNews = (data: {
	limit?: number;
	offset?: number;
}) => Promise<IRGETNewsByUniId>;
export const LoadNews = (data: {
	limit?: number;
	offset?: number;
}): ThunkAction<ReturnType<ILoadNews>, IRootState, null, TempActions> => (
	dispatch
): ReturnType<ILoadNews> => {
	return api.news.getAll(data).then(data => {
		const user_news = data;
		dispatch(LoadNewsAction(user_news));
		return data;
	});
};

interface ILoadNewsByUniIdAction {
	type: typeof LOAD_NEWS_FOR_UNI;
	news: INews[];
	uni_id: number;
}

export const loadNewsByUniIdAction = (
	news: INews[],
	uni_id: number
): ILoadNewsByUniIdAction => {
	return {
		type: LOAD_NEWS_FOR_UNI,
		news,
		uni_id,
	};
};

export type ILoadNewsByUniId = (uni_id: number) => Promise<IRGETNewsByUniId>;

// tslint:disable-next-line:max-line-length
export const loadNewsByUniId = (
	uni_id: number
): ThunkAction<ReturnType<ILoadNewsByUniId>, IRootState, null, TempActions> => (
	dispatch
): ReturnType<ILoadNewsByUniId> => {
	return api.unis.getNews({ uni_id }).then(data => {
		const news = data;
		dispatch(loadNewsByUniIdAction(news, uni_id));
		return data;
	});
};

// tslint:disable-next-line:max-line-length

interface IUniRemoveInfoFromTemp {
	type: typeof REMOVE_UNI_DISPLAY_INFO;
	uni_id: number;
}

export const uniRemoveInfoFromTemp = (
	uni_id: number
): IUniRemoveInfoFromTemp => {
	return {
		type: REMOVE_UNI_DISPLAY_INFO,
		uni_id,
	};
};

interface IViewUniPageAction {
	type: typeof VIEW_UNI_PAGE;
	uni_id: number;
	date: Date;
}
export const viewUniPage = (uni_id: number): IViewUniPageAction => {
	api.unis.recordView({ uni_id }).then();
	return {
		type: VIEW_UNI_PAGE,
		uni_id,
		date: new Date(),
	};
};

interface IViewProgramPageAction {
	type: typeof VIEW_PROGRAM_PAGE;
	program_id: number;
	uni_id: number;
	date: Date;
}
export const viewProgramPage = (
	program_id: number,
	uni_id: number
): IViewProgramPageAction => {
	api.programs.recordView({ program_id, uni_id }).then();
	return {
		type: VIEW_PROGRAM_PAGE,
		program_id,
		uni_id,
		date: new Date(),
	};
};

interface ILoadTempAction {
	type: typeof LOAD_TEMP_INFO;
	temp: IStateTemp;
}

export const loadTemp = (temp: IStateTemp): ILoadTempAction => {
	const newTemp = { ...temp };
	const { viewedUnis, viewedPrograms } = temp;
	const todayDate = new Date().toISOString().slice(0, 10);
	if (typeof viewedUnis !== "undefined") {
		// leave the unis that were viewed today
		const newViewedUnis = {};
		for (const uni_id in viewedUnis) {
			if (viewedUnis.hasOwnProperty(uni_id)) {
				try {
					const date = new Date(viewedUnis[uni_id].date);
					const dateString = date.toISOString().slice(0, 10);
					if (dateString !== todayDate) continue;
					newViewedUnis[uni_id] = viewedUnis[uni_id];
					// tslint:disable-next-line:no-empty
				} catch (e) {}
			}
		}
		newTemp.viewedUnis = newViewedUnis;
	}
	if (typeof viewedPrograms !== "undefined") {
		// leave the programs that were viewed today
		const newViewedPrograms = {};
		for (const program_id in viewedPrograms) {
			if (viewedPrograms.hasOwnProperty(program_id)) {
				try {
					const date = new Date(viewedPrograms[program_id].date);
					const dateString = date.toISOString().slice(0, 10);
					if (dateString !== todayDate) continue;
					newViewedPrograms[program_id] = viewedPrograms[program_id];
					// tslint:disable-next-line:no-empty
				} catch (e) {}
			}
		}
		newTemp.viewedPrograms = newViewedPrograms;
	}
	return {
		type: LOAD_TEMP_INFO,
		temp: newTemp,
	};
};

interface ILoadDashboardStatsAction {
	type: typeof LOAD_DASHBOARD_STATISTICS;
	uni_id: number;
	data: IRGETRecentStats;
}

const loadDashboardStatsAction = (
	uni_id: number,
	data: IRGETRecentStats
): ILoadDashboardStatsAction => {
	return {
		type: LOAD_DASHBOARD_STATISTICS,
		uni_id,
		data,
	};
};

export type ILoadDashboardStats = (uni_id: number) => Promise<IRGETRecentStats>;
// tslint:disable-next-line:max-line-length
export const loadDashboardStats = (
	uni_id: number
): ThunkAction<
	ReturnType<ILoadDashboardStats>,
	IRootState,
	null,
	TempActions
> => (dispatch, getState): ReturnType<ILoadDashboardStats> => {
	const state = getState();
	if (
		typeof state.temp.dashboardStatistics !== "undefined" &&
		typeof state.temp.dashboardStatistics[uni_id] !== "undefined"
	) {
		return Promise.resolve(state.temp.dashboardStatistics[uni_id]);
	}
	return api.unis.stats.getRecent({ uni_id });
};

interface ILoadUniShortStatsAction {
	type: typeof LOAD_UNI_SHORT_STATS;
	views_verified: number;
	views_nonverified: number;
	latori_verified: number;
	latori_nonverified: number;
	uni_id: number;
}

export const loadUniShortStats = (
	data: Without<ILoadUniShortStatsAction, "type">
): ILoadUniShortStatsAction => {
	return {
		type: LOAD_UNI_SHORT_STATS,
		...data,
	};
};

interface IDisplayUniSubscriptionPopupAction {
	type: typeof DISPLAY_UNI_SUBSCRIPTION_POPUP;
	uni_id?: number;
}
export const displayUniSubscriptionPopupAction = (
	uni_id?: number
): IDisplayUniSubscriptionPopupAction => {
	return {
		type: DISPLAY_UNI_SUBSCRIPTION_POPUP,
		uni_id,
	};
};

export type TempActions =
	| IUniDisplayInfoAction
	| IViewUniPageAction
	| ILoadEventsByUniIdAction
	| IViewProgramPageAction
	| IUniRemoveInfoFromTemp
	| ILoadTempAction
	| ILoadDashboardStatsAction
	| IDeleteEventById
	| ILoadEventsAction
	| ILoadUserReservedEventsAction
	| IReserveEventAction
	| IUnReserveEventAction
	| IEventChangeSettings
	| IAddEvent
	| ILoadNewsAction
	| IAddNews
	| ILoadUniShortStatsAction
	| IDisplayUniSubscriptionPopupAction
	| ILoadNewsByUniIdAction
	| ILogoutAction
	| INewsChangeSettings;
