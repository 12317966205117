import { Requests } from "@app/api";
import { contentTypeMultipart } from "@app/api/consts";
import Joi from "@app/utils/joi";
import { IUniOffer, UniContentSchema, UniOfferSchema } from "../helper-schemas";
import { IUniContent } from "../helper-schemas";
import {
	IAGETOffers,
	IAGETUniContentsByType,
	IAPOSTUploadPhotos,
	IAPUTSave,
	IRPOSTUploadFiles,
	IRPOSTUploadPhotos,
	RPOSTUploadFilesSchema,
	RPOSTUploadPhotosSchema,
} from "./validators";

const unisContent = {
	saveContent: (data: IAPUTSave): Promise<void> =>
		Requests.send("PUT", "/api/unis/:uni_id/contents/", data, null),
	uploadPhotos: (data: IAPOSTUploadPhotos): Promise<IRPOSTUploadPhotos> =>
		Requests.send(
			"POST",
			"/api/unis/:uni_id/contents/photos",
			data,
			contentTypeMultipart,
			{
				responseSchema: RPOSTUploadPhotosSchema,
			}
		),
	uploadPDFs: (data: IAPOSTUploadPhotos): Promise<IRPOSTUploadFiles> =>
		Requests.send(
			"POST",
			"/api/unis/:uni_id/contents/pdf/multiple",
			data,
			contentTypeMultipart,
			{
				responseSchema: RPOSTUploadFilesSchema,
			}
		),
	getUniContentByType: (
		data: IAGETUniContentsByType
	): Promise<IUniContent[]> =>
		Requests.send(
			"GET",
			"/api/unis/contents-by-type",
			data,
			contentTypeMultipart,
			{
				responseSchema: Joi.array()
					.items(UniContentSchema)
					.required(),
			}
		),
	getOffers: (data: IAGETOffers): Promise<IUniOffer[]> =>
		Requests.send("GET", "/api/unis/offers", data, contentTypeMultipart, {
			responseSchema: Joi.array()
				.items(UniOfferSchema)
				.required(),
		}),
};
export default unisContent;
