import * as React from "react";
import { weekDaysAbbrGeo } from "@app/commonJavascript";
import "./styles/calendars.min.css";

export interface ICalendarProps {
	bgColor: string;
	textColor: string;
	textColor2: string; // chosen day text color
	mainColor: string;
	onDateIntervalChange?: (
		dateName: "start_time" | "end_time",
		date: Date
	) => void;
	onDateChange?: (date: Date) => void;
	start_time?: Date | null;
	end_time?: Date | null;
}

interface ICalendarBodyProps {
	textColor: string;
	textColor2: string;
	mainColor: string;
	displayDate: Date;
	selectedDate: Date;
	selectedEndDate?: Date;
	onSelectedDateChange: (time: number) => void;
	onEventDayClick?: (event_date: Date) => void;
	eventDates?: {
		[key: string]: string;
	};
}

interface IDayObj {
	day: Date;
	prevMonth: boolean;
	currentMonth: boolean;
}
const CalendarBody: React.FC<ICalendarBodyProps> = props => {
	const numOfDaysInPrevMonth = new Date(
		props.displayDate.getFullYear(),
		props.displayDate.getMonth(),
		0
	).getDate();
	const numOfDaysInThisMonth = new Date(
		props.displayDate.getFullYear(),
		props.displayDate.getMonth() + 1,
		0
	).getDate();
	const currentWeekDayIndex =
		(new Date(
			props.displayDate.getFullYear(),
			props.displayDate.getMonth(),
			1
		).getDay() -
			1 +
			7) %
		7;
	const selectedDate = new Date(
		props.selectedDate.getFullYear(),
		props.selectedDate.getMonth(),
		props.selectedDate.getDate()
	);
	let selectedEndDate: Date | undefined;
	if (props.selectedEndDate) {
		selectedEndDate = new Date(
			props.selectedEndDate.getFullYear(),
			props.selectedEndDate.getMonth(),
			props.selectedEndDate.getDate()
		);
	}
	const isSelectedDay = (dayObj: IDayObj) => {
		if (selectedEndDate) {
			return (
				dayObj.day.getTime() === selectedDate.getTime() ||
				dayObj.day.getTime() === selectedEndDate.getTime()
			);
		}
		return dayObj.day.getTime() === selectedDate.getTime();
	};

	const isBetweenDates = (dayObj: IDayObj) => {
		if (!selectedEndDate) return isSelectedDay(dayObj);
		if (dayObj.day > selectedDate && dayObj.day < selectedEndDate) {
			return true;
		}
		return false;
	};
	const datify = (date: Date) =>
		date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

	// tslint:disable-next-line
	const daysArray: IDayObj[][] = [];
	let currentDayNum = numOfDaysInPrevMonth - currentWeekDayIndex + 1;
	const currentDay = new Date(
		props.displayDate.getFullYear(),
		props.displayDate.getMonth() - 1,
		currentDayNum
	);
	let inPrevMonth = true;
	let inThisMonth = false;
	let inNextMonth = false;
	let printedThisMonthDay = false;
	let weekIndex = 0;

	do {
		printedThisMonthDay = false;
		if (inNextMonth) break;
		daysArray[weekIndex] = [];
		for (let i = 0; i < 7; ++i) {
			if (inPrevMonth && currentDayNum > numOfDaysInPrevMonth) {
				inPrevMonth = false;
				inThisMonth = true;
				currentDayNum = 1;
			} else if (inThisMonth && currentDayNum > numOfDaysInThisMonth) {
				inThisMonth = false;
				inNextMonth = true;
				currentDayNum = 1;
			}
			if (inThisMonth) printedThisMonthDay = true;
			daysArray[weekIndex].push({
				day: new Date(currentDay.getTime()),
				prevMonth: inPrevMonth,
				currentMonth: inThisMonth,
			});
			currentDayNum++;
			currentDay.setDate(currentDay.getDate() + 1);
		}
		weekIndex++;
	} while (printedThisMonthDay);
	return (
		<table className="body">
			<thead>
				<tr>
					{weekDaysAbbrGeo.map(weekName => (
						<th key={weekName} style={{ color: props.textColor }}>
							{weekName}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{daysArray.map((days, index) => (
					<tr key={index}>
						{days.map(eachDay => (
							<td key={eachDay.day.toString()}>
								<div
									className={
										"day " +
										(!eachDay.currentMonth
											? "diffMonth "
											: "") +
										(isSelectedDay(eachDay)
											? "selectedDay "
											: "") +
										(props.eventDates &&
										props.eventDates[datify(eachDay.day)]
											? props.eventDates[
													datify(eachDay.day)
											  ]
											: "")
									}
									style={
										props.eventDates
											? { color: props.textColor }
											: isSelectedDay(eachDay)
											? {
													background: props.mainColor,
													color: props.textColor2,
											  }
											: {
													color: isBetweenDates(
														eachDay
													)
														? props.mainColor
														: props.textColor,
											  }
									}
									onClick={() =>
										props.onSelectedDateChange(
											eachDay.day.getTime()
										)
									}
								>
									{eachDay.day.getDate()}
								</div>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export { CalendarBody };
