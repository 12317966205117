import * as React from "react";
import { CSSProperties } from "react";

interface IProps {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	multiple: boolean;
	text?: string;
	style?: CSSProperties;
}

const UploadButton: React.FC<IProps> = props => (
	<label
		className="custom_file_upload"
		{...(props.style && { style: props.style })}
	>
		<input
			type="file"
			multiple={props.multiple}
			onChange={props.onChange}
			accept="image/x-png,image/jpeg"
		/>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="17"
			viewBox="0 0 20 17"
		>
			<path
				d={
					"M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 " +
					"2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 " +
					"2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
				}
			/>
		</svg>
		<span>{props.text || "სურათის ატვირთვა"}</span>
	</label>
);

export const UploadFileButton: React.FC<IProps> = props => (
	<label
		className="custom_file_upload"
		{...(props.style && { style: props.style })}
	>
		<input
			type="file"
			multiple={props.multiple}
			onChange={props.onChange}
			accept=".pdf"
		/>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			enable-background="new 0 0 24 24"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="#fff"
			style={{ width: 22, height: 22 }}
		>
			<g>
				<rect fill="none" height="24" width="24" />
			</g>
			<g>
				<path d="M19.41,7.41l-4.83-4.83C14.21,2.21,13.7,2,13.17,2H6C4.9,2,4.01,2.9,4.01,4L4,20c0,1.1,0.89,2,1.99,2H18c1.1,0,2-0.9,2-2 V8.83C20,8.3,19.79,7.79,19.41,7.41z M14.8,15H13v3c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H9.21c-0.45,0-0.67-0.54-0.35-0.85l2.8-2.79 c0.2-0.19,0.51-0.19,0.71,0l2.79,2.79C15.46,14.46,15.24,15,14.8,15z M14,9c-0.55,0-1-0.45-1-1V3.5L18.5,9H14z" />
			</g>
		</svg>
		<span>{props.text || "ფაილის ატვირთვა"}</span>
	</label>
);
export default UploadButton;
