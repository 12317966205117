import {
	addFixedScoreAction,
	addLatoriContestScore,
	addLatoriUserScore,
	removeFixedScoreAction,
} from "@app/actions/lator";
import { getUnisAndPrograms } from "@app/actions/unis";
import api from "@app/api";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IProgramSubjectScores } from "@app/api/programs/validators";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { ILogScores } from "@app/api/users/subjects/validators";
import {
	erovnulSubjects,
	getProgramPlaces,
	getProgramPlaces2020,
	IErovnuliSubj,
	roundNumber,
	shortenName,
	toGeorgianOrdinalNumber,
	toScaledScore,
} from "@app/commonJavascript";
import SvgLoadingWhite from "@app/components/styles/img/LoadingWhite";
import { UniLogo } from "@app/components/Universities/FreeUniversityPage";
import Popup, { PopupContent } from "@app/components/Widgets/Popup";
import { IUserScores } from "@app/reducers/lator";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import Tooltip from "@material-ui/core/Tooltip";
import { History } from "history";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import SvgLoading from "../../styles/img/Loading";
import { CUUniId } from "../consts";
import { calculateLatorResults, IResults, ResultType } from "../functions";
import IbsuPopup from "../ibsu-popup";
import "../styles/sliders.min.css";
import Grant from "./grant";
import PlaceSlider from "./place-slider";
import SubjectScoreSlider from "./subject-score-slider";

interface IOwnProps {
	selectedSubjectId: number | null;
	selectedSubjectGroup?: number[] | null;
	selectedGrantSubjectId?: number | null;
	programId: number;
	history: History;
	onProgramPage: boolean;
	hideExtraInfoButton?: boolean;
	resultRef?: React.RefObject<HTMLDivElement>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	fixedSubjectIds: number[];
	scores: IUserScores;
	admissionScores: {
		[program_id: string]: {
			[subject_id_key: string]: IProgramSubjectScores[];
		};
	};
	place?: null | number;
	calculatingResults: boolean;
	showGrantPopup: boolean;
	result?: IResults;
	contestScaledScore: number;
	electiveSubjects: number[];
	langScoreChangedTimes: number;
}

class LatoriSliders extends React.Component<IProps, IState> {
	state: IState = {
		fixedSubjectIds: this.props.fixedScores
			? Object.keys(this.props.fixedScores).map(e => parseInt(e, 10))
			: [],
		admissionScores: {},
		scores: this.props.fixedScores || {},
		calculatingResults: false,
		showGrantPopup: false,
		contestScaledScore: 0,
		electiveSubjects: this.props.selectedSubjectId
			? [this.props.selectedSubjectId]
			: this.props.selectedSubjectGroup || [],
		langScoreChangedTimes: 0,
	};

	componentWillReceiveProps(nextProps) {
		this.setState({
			electiveSubjects: nextProps.selectedSubjectId
				? [nextProps.selectedSubjectId]
				: nextProps.selectedSubjectGroup || [],
		});
	}

	getSubjectKey = memoizeOne((subj_ids: number[] | null): string =>
		String(
			subj_ids &&
				(subj_ids.filter(id => id !== 13).length === 0
					? null
					: subj_ids.sort((a, b) => a - b).join(","))
		)
	);

	getSubjects = memoizeOne((electiveSubjects: number[]): IErovnuliSubj[] => {
		const compulsorySubjects = erovnulSubjects.filter(s => s.isCompulsory);
		const subjects = [
			...new Set([
				...compulsorySubjects,
				...(electiveSubjects
					.map(id => erovnulSubjects.find(subj => subj.id === id))
					.filter(el => el) as IErovnuliSubj[]),
			]),
		];
		if (
			!this.state.admissionScores[this.props.programId] ||
			!this.state.admissionScores[this.props.programId][
				this.getSubjectKey(electiveSubjects)
			]
		) {
			this.getData(this.state.electiveSubjects, this.props.programId);
		}
		setTimeout(() => {
			this.setState({
				place: null,
			});
		}, 0);
		return subjects.filter(subj => !subj.noScores);
	});

	getCurrentFixedSubjects = memoizeOne(
		(
			electiveSubjects: number[],
			fixedSubjectIds: number[]
		): IErovnuliSubj[] => {
			return this.getSubjects(electiveSubjects).filter(
				subj => fixedSubjectIds.indexOf(subj.id) !== -1
			);
		}
	);

	getProgramId = memoizeOne((programId: number) => {
		const key = this.getSubjectKey(this.state.electiveSubjects);
		if (
			key &&
			(!this.state.admissionScores[programId] ||
				!this.state.admissionScores[programId][key])
		) {
			this.getData(this.state.electiveSubjects, programId);
		}
		setTimeout(() => {
			this.setState({
				place: null,
			});
		}, 0);
		return programId;
	});

	placeSliderRef: React.RefObject<PlaceSlider> = React.createRef();

	getData = memoizeOne(
		async (elective_subject_ids: number[] | null, program_id: number) => {
			const filteredSubjs =
				elective_subject_ids &&
				elective_subject_ids.filter(subjId => {
					const subj = erovnulSubjects.find(
						subj => subj.id === subjId
					);
					return subj && !subj.noScores;
				});
			const scores = await api.lator.getScoresByProgramAndSubjectId({
				program_id,
				elective_subject_ids: filteredSubjs,
			});
			scores.sort((a, b) => a.total_score - b.total_score);
			const admissionScores = { ...this.state.admissionScores };
			admissionScores[program_id] = {
				...(admissionScores[program_id] || {}),
				[this.getSubjectKey(filteredSubjs)]: scores,
			};
			this.setState({
				admissionScores,
			});
			return scores;
		}
	);

	lastPlaceCalculated?: number | null = -Infinity;
	lastProgramId: number = this.props.programId;
	electiveSubjects: number[] = this.state.electiveSubjects;
	lastRandomKey = -Infinity;

	getParticularAdmissionScores = memoizeOne(
		(scores: IProgramSubjectScores[], program?: IRProgram) => {
			let adjustedScores = scores;
			const numPlaces = program
				? getProgramPlaces(program, this.state.electiveSubjects)
				: 0;
			const numPlaces2020 = program
				? getProgramPlaces2020(
						program.code,
						this.state.electiveSubjects
				  )
				: 0;
			if (program && numPlaces > 0) {
				if (numPlaces <= scores.length)
					adjustedScores = scores.slice(
						scores.length - numPlaces,
						scores.length
					);
				else {
					if (numPlaces2020 > 0 && numPlaces2020 === scores.length) {
						adjustedScores.unshift(
							...Array(numPlaces - numPlaces2020).fill(scores[0])
						);
					} else if (
						numPlaces2020 > 0 &&
						numPlaces2020 > scores.length
					) {
						adjustedScores.unshift(
							...Array(numPlaces - adjustedScores.length)
								.fill({
									...adjustedScores[0],
								})
								.map((el: IProgramSubjectScores) => {
									const newScores = {};
									for (const subjId of Object.keys(el.scores))
										newScores[subjId] = toScaledScore(
											program.minScoreLimits
												? program.minScoreLimits[subjId]
												: erovnulSubjects.find(
														subj =>
															subj.id ===
															Number(subjId)
												  )!.minScore,
											Number(subjId)
										);
									let newTotalScore = 0;
									if (program.coefficients)
										for (const subjId of Object.keys(
											el.scores
										))
											newTotalScore +=
												program.coefficients[subjId] *
												el.scores[subjId];

									return {
										...el,
										scores: newScores,
										total_score: newTotalScore,
									};
								})
						);
					}
				}
			}
			return adjustedScores.map(e => ({
				...e.scores,
				total: e.total_score,
			}));
		}
	);

	calculate = memoizeOne(
		async (
			fixedScores: IUserScores,
			programId: number,
			elective_subjects: number[],
			randomKey: number
		) => {
			this.mainParamsOfLastCalculation.programId = programId;
			this.mainParamsOfLastCalculation.electiveSubjects = elective_subjects;
			const place = this.state.place;
			const subjKey = this.getSubjectKey(elective_subjects);

			const isPlaceChosen =
				this.lastPlaceCalculated !== -Infinity &&
				this.lastRandomKey !== randomKey &&
				this.lastProgramId === programId &&
				this.getSubjectKey(this.electiveSubjects) === subjKey;

			this.lastPlaceCalculated = place;
			this.lastProgramId = programId;
			this.electiveSubjects = elective_subjects;
			this.lastRandomKey = randomKey;

			const program = this.props.programs!.find(p => p.id === programId);
			if (!program) return;
			if (!program.coefficients) return;
			if (this.state.admissionScores[programId][subjKey].length === 0) {
				if (!this.placeSliderRef.current) return;
				this.placeSliderRef.current.onChangeFromParent(null);
				return;
			}
			if (
				place &&
				this.state.admissionScores[programId][subjKey].length < place
			)
				return;

			let areSame = true;
			if (
				programId !== this.lastCalculationsResults.programId ||
				subjKey !==
					this.getSubjectKey(
						this.lastCalculationsResults.electiveSubjects
					) ||
				place !== this.lastCalculationsResults.place
			) {
				areSame = false;
			} else {
				for (const subjId in fixedScores) {
					if (
						fixedScores.hasOwnProperty(subjId) &&
						this.lastCalculationsResults.scores[subjId] !==
							fixedScores[subjId]
					) {
						areSame = false;
						break;
					}
				}
			}
			if (areSame) return;
			this.setState({
				calculatingResults: true,
			});

			const currentProgram = this.getProgram(
				programId,
				this.props.programs
			);

			setTimeout(() => {
				const result = calculateLatorResults({
					admissionScores: this.getParticularAdmissionScores(
						this.state.admissionScores[programId][subjKey],
						currentProgram
					),
					essentialScores: program.minScoreLimits,
					coefficients: program.coefficients!,
					userRawScores: fixedScores,
					electiveSubjectIds: elective_subjects,
					place: place || undefined,
				});
				if (!this.placeSliderRef.current) return;
				if (
					result.type ===
						ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE ||
					result.type === ResultType.SCORES_LESS_THAN_LIMITS
				) {
					this.setState({
						place: null,
						calculatingResults: false,
						result,
						contestScaledScore: 0,
					});
					this.placeSliderRef.current.onChangeFromParent(null);
					this.lastPlaceCalculated = null;
				} else if (result.type === ResultType.CALCULATED_SCORES) {
					this.setState({
						scores: {
							...this.state.scores,
							...result.scores,
						},
						place: result.place,
						calculatingResults: false,
						result,
						contestScaledScore: result.contestScaledScore,
					});
					this.placeSliderRef.current.onChangeFromParent(
						result.place
					);
					this.lastPlaceCalculated = result.place;
				} else {
					this.setState({
						place: result.place,
						calculatingResults: false,
						result,
						contestScaledScore: result.contestScaledScore,
					});
					this.placeSliderRef.current.onChangeFromParent(
						result.place
					);
					this.lastPlaceCalculated = result.place;
				}
				this.props.addLatoriContestScore(
					result.type ===
						ResultType.NOT_ENOUGH_SCORES_FOR_LAST_PLACE ||
						result.type === ResultType.SCORES_LESS_THAN_LIMITS
						? 0
						: result.contestScaledScore
				);
				const resultScores = ((result as any).scores ||
					{}) as IUserScores;
				this.props.addLatoriUserScore(resultScores);
				const scores = {
					...this.state.scores,
					...Object.keys(resultScores).reduce(
						(obj: ILogScores, subjId) => ({
							...obj,
							[subjId]: {
								score: resultScores[subjId],
								fixed: false,
							} as ILogScores[string],
						}),
						{}
					),
					...fixedScores,
				} as ILogScores;
				this.lastCalculationsResults = {
					programId,
					electiveSubjects: elective_subjects,
					place: (result as any).place || -1,
					scores: { ...this.state.scores, ...resultScores },
				};
				if (this.props.isLogged) {
					api.lator
						.postCalculations({
							program_id: programId,
							place: (result as any).place || -1,
							isPlaceChosen,
							scores,
						})
						.then()
						.catch();
				}
			}, 10);
		}
	);

	lastFixecScores: IState["scores"] = {};
	lastCalculationsResults: {
		programId: null | number;
		electiveSubjects: number[];
		place: null | number;
		scores: IState["scores"];
	} = {
		place: null,
		programId: null,
		electiveSubjects: [],
		scores: {},
	};

	getText = memoizeOne((place?: number | null, reason?: IState["result"]) => {
		const currentSubjects = this.getSubjects(this.state.electiveSubjects);
		const currentFixedSubjects = this.getCurrentFixedSubjects(
			this.state.electiveSubjects,
			this.state.fixedSubjectIds
		);

		if (
			reason === undefined ||
			reason.type === ResultType.CALCULATED_PLACE ||
			reason.type === ResultType.CALCULATED_SCORES
		) {
			if (!place) return "";
			const prefix = reason
				? "საკონკურსო ქულა: " +
				  roundNumber(reason.contestScaledScore, 1) +
				  "."
				: "";
			if (currentSubjects.length === currentFixedSubjects.length) {
				return (
					<>
						{prefix}
						<br /> აღნიშნული ქულებით გახვიდოდი{" "}
						{toGeorgianOrdinalNumber(place, 1)} ადგილზე.
					</>
				);
			}
			return (
				<>
					{prefix}
					<br /> {toGeorgianOrdinalNumber(place, 1)} ადგილზე გახვიდოდი
					შემდეგი ქულებით:
				</>
			);
		}
		if (reason.type === ResultType.SCORES_LESS_THAN_LIMITS) {
			const subject = currentSubjects.find(
				s => s.id === reason.subjectId
			);
			if (!subject) return "";
			return `საბაკალავრო პროგრამის მინიმალური მოთხოვნაა - ${subject.name} - ${reason.requiredScore} ქულა`;
		}
		if (reason.myScaledScore !== undefined) {
			// tslint:disable-next-line:max-line-length
			return `გამსვლელი საკონკურსო ქულაა ${reason.requiredScaledScore.toFixed(
				1
			)}, მაშინ როცა მითითებული ქულებით დააგროვებდი ${reason.myScaledScore.toFixed(
				1
			)} ქულას. სცადე რომელიმე საგანში ქულის მოხსნა ბოქლომზე დაჭერით, რათა ჩვენ დაგითვალოთ ქულა`;
		}
		// tslint:disable-next-line:max-line-length
		return (
			<>
				გამსვლელი საკონკურსო ქულაა
				{reason.requiredScaledScore.toFixed(1)}.<br />
				იმ საგნებში, რომლებშიც ქულა არ არის დაფიქსირებული, ვერ მოიძებნა
				ქულათა ისეთი კომბინაცია, რომლის მიხედვითაც დააგროვებდი გამსვლელ
				ან უფრო მაღალ საკონკურსო ქულას.
				<br />
				<strong>
					მოხსენი ბოქლომი რომელიმე სხვა საგანშიც, რათა შევძლოთ ქულების
					დათვლა.
				</strong>
			</>
		);
	});

	mainParamsOfLastCalculation: {
		programId: number | null;
		electiveSubjects: number[];
	} = {
		programId: null,
		electiveSubjects: [],
	};

	getProgram = memoizeOne((programId: number, programs?: IRProgram[]) => {
		return !programs ? undefined : programs.find(p => p.id === programId);
	});
	getUni = memoizeOne((uniId: number, unis?: IRUniversity[]) => {
		return !unis ? undefined : unis.find(u => u.id === uniId);
	});

	calcFixedScores = (
		fixedSubjectIds: IState["fixedSubjectIds"],
		scores: IState["scores"]
	) => {
		const fixedScores: IUserScores = {};
		fixedSubjectIds.forEach(subjId => {
			const subject = erovnulSubjects.find(s => s.id === subjId);
			if (!subject) return;
			if (
				!subject.isCompulsory &&
				!this.state.electiveSubjects.includes(subjId)
			)
				return;
			fixedScores[subjId] = scores[subjId];
		});
		this.props.addLatoriUserScore(fixedScores);
		return fixedScores;
	};

	getUnchangedFixedScores = (
		fixedScores: IState["scores"]
	): IState["scores"] => {
		if (this.lastFixecScores === fixedScores) return fixedScores;
		const stateScoresKeys = Object.keys(fixedScores);
		const lastScoresKeys = Object.keys(this.lastFixecScores);
		if (
			stateScoresKeys.length === lastScoresKeys.length &&
			stateScoresKeys.every(
				key => fixedScores[key] === this.lastFixecScores[key]
			)
		) {
			return this.lastFixecScores;
		}
		this.lastFixecScores = fixedScores;
		return fixedScores;
	};

	requestCalculation = () => {
		const fixedScores = this.getUnchangedFixedScores(
			this.calcFixedScores(this.state.fixedSubjectIds, this.state.scores)
		);
		const key = this.getSubjectKey(this.state.electiveSubjects);
		const programId = this.props.programId;
		if (!this.state.admissionScores[programId]) return;
		if (!this.state.admissionScores[programId][key]) return;
		if (!this.props.programs) return;
		this.calculate(
			fixedScores,
			programId,
			this.state.electiveSubjects,
			this.lastPlaceCalculated === this.state.place
				? this.lastRandomKey
				: Math.random()
		);
	};

	componentDidMount() {
		this.getData(this.state.electiveSubjects, this.props.programId);
		if (!this.props.unis || !this.props.programs) {
			this.props.getUnisAndPrograms();
		}
	}

	onScoreChange = (subjectId: number, score: number) => {
		if (
			this.props.isLogged &&
			this.state.fixedSubjectIds.indexOf(subjectId) !== -1 &&
			this.state.scores[subjectId] !== score
		) {
			this.props.addFixedScoreAction(subjectId, score);
		}

		this.setState(({ scores, langScoreChangedTimes }) => ({
			scores: { ...scores, [subjectId]: score },
			langScoreChangedTimes:
				subjectId === 3
					? langScoreChangedTimes + 1
					: langScoreChangedTimes,
		}));
	};

	onPlaceChange = (place?: number) => {
		this.setState({
			place: place || undefined,
		});
	};

	onFixChange = (subjectId: number, fixed: boolean) => {
		if (fixed && this.state.fixedSubjectIds.indexOf(subjectId) !== -1)
			return;
		this.setState(
			() => ({
				fixedSubjectIds: !!fixed
					? this.state.fixedSubjectIds.indexOf(subjectId) !== -1
						? this.state.fixedSubjectIds
						: [...this.state.fixedSubjectIds, subjectId]
					: this.state.fixedSubjectIds.filter(
							sId => sId !== subjectId
					  ),
			}),
			() => {
				if (!this.props.isLogged) return;
				if (fixed) {
					this.props.addFixedScoreAction(
						subjectId,
						this.state.scores[subjectId]
					);
				} else {
					this.props.removeFixedScoreAction(subjectId);
				}
			}
		);
	};

	gotoProgramPage = () => {
		const program = this.props.programs!.find(
			p => p.id === this.props.programId
		);
		if (!program) return;
		// this.props.history.push(`/programs/${program.urlName}`);
		window.open(`/programs/${program.urlName}`);
		triggerEvent({
			category: "Button",
			action: "Goto Program page",
			label: "from latori",
		});
	};

	onShowGrantPopup = () => {
		this.setState({
			showGrantPopup: true,
		});

		triggerEvent(
			{
				category: "Button",
				action: "Lator: caclulate grant",
				label: "",
			},
			{ program_id: this.props.programId }
		);
	};

	onCloseGrantPopup = () => {
		this.setState({
			showGrantPopup: false,
		});
	};

	render() {
		const currentSubjects = this.getSubjects(
			this.state.electiveSubjects
		).filter(subj => !subj.noScores);
		const currentFixedSubjects = this.getCurrentFixedSubjects(
			this.state.electiveSubjects,
			this.state.fixedSubjectIds
		);
		const programId = this.getProgramId(this.props.programId);

		let currentProgramUniId: number | null = null;
		const currentProgram = this.getProgram(programId, this.props.programs);
		currentProgramUniId =
			currentProgram === undefined ? null : currentProgram.uni_id;

		const currentUni =
			currentProgramUniId === null
				? undefined
				: this.getUni(currentProgramUniId, this.props.unis);

		const resultRef = this.props.resultRef;
		const subjKey = this.getSubjectKey(this.state.electiveSubjects);
		if (
			!this.props.programs ||
			!this.state.admissionScores ||
			!this.state.admissionScores[programId] ||
			!this.state.admissionScores[programId][subjKey]
		) {
			return (
				<div className="latoriResultContainer" ref={resultRef}>
					{<SvgLoading />}
				</div>
			);
		}
		if (!currentProgram) {
			return (
				<div className="latoriResultContainer" ref={resultRef}>
					აღნიშნული პროგრამა აღარ არსებობს
				</div>
			);
		}
		const ResultHeader =
			!currentUni || !currentProgram ? null : (
				<div className="latoriResultHeader">
					<Tooltip
						title={
							<span style={{ fontSize: 18 }}>
								{currentUni.name}
							</span>
						}
						placement="bottom"
					>
						<UniLogo
							logoUrl={currentUni.logoUrl}
							logoBGColor={currentUni.logoBGColor}
							className="uniLogo"
						/>
					</Tooltip>
					{shortenName(currentProgram.name, 100)}
				</div>
			);
		if (this.state.electiveSubjects.includes(13)) {
			const allSubjects = [...currentSubjects];
			const grantSubj = erovnulSubjects.find(
				subj => subj.id === this.props.selectedGrantSubjectId
			);
			if (this.props.selectedGrantSubjectId && grantSubj)
				allSubjects.push(grantSubj);
			return (
				<div className="latoriResultContainer" ref={resultRef}>
					{ResultHeader}
					<div
						className="latoriResultBody"
						style={{ textAlign: "justify" }}
					>
						მოცემულ ფაკულტეტზე მოსახვედრად ყველაზე დიდი წონა აქვს
						შემოქმედებით ტურს, რომელსაც უნივერსიტეტები
						ინდივიდუალურად ატარებენ თითოეულ აბიტურიენტთან. ამიტომ,
						ჩარიცხვებთან დაკავშირებით მურწყულატორი პროგნოზს ვერ
						გაგიკეთებს.{" "}
						<strong>
							თუმცა, გრანტის გამოთვლა შეგიძლია - ამისათვის
							საჭიროა, აირჩიო საგრანტო საგანი და მიუთითო ქულა.
						</strong>
					</div>
					<div className="latoriResultBody">
						{this.props.selectedGrantSubjectId &&
							grantSubj &&
							allSubjects.map(subj => (
								<SubjectScoreSlider
									key={subj.id}
									subject={subj}
									score={this.state.scores[subj.id]}
									onScoreChange={this.onScoreChange}
									onFixChange={this.onFixChange}
									fixed={
										this.state.fixedSubjectIds.indexOf(
											subj.id
										) !== -1
									}
								/>
							))}
						<div className="buttons">
							{currentProgram.grant_subjects &&
								this.props.selectedGrantSubjectId &&
								grantSubj && (
									<button
										className="pink"
										onClick={this.onShowGrantPopup}
									>
										გრანტის გამოთვლა
									</button>
								)}
							<button
								onClick={this.gotoProgramPage}
								className="gray"
							>
								დამატებითი ინფორმაცია
							</button>
							{this.state.showGrantPopup &&
								this.props.selectedGrantSubjectId && (
									<Popup onClose={this.onCloseGrantPopup}>
										<PopupContent>
											<Grant
												programId={this.props.programId}
												electiveSubjects={[
													this.props
														.selectedGrantSubjectId,
												]}
												scores={this.state.scores}
												contestScaledScore={
													this.state
														.contestScaledScore
												}
											/>
										</PopupContent>
									</Popup>
								)}
						</div>
					</div>
				</div>
			);
		}

		const admissionScores = this.state.admissionScores[programId][subjKey];
		if (admissionScores.length === 0) {
			this.lastProgramId = this.props.programId;
			this.electiveSubjects = this.state.electiveSubjects;
			if (
				// currentProgram.subjects
				//  .flat()
				//  .indexOf(this.electiveSubjects) === -1
				this.electiveSubjects.some(
					id => !currentProgram.subjects.flat().includes(id)
				)
			) {
				return (
					<div className="latoriResultContainer" ref={resultRef}>
						{ResultHeader}
						<div className="latoriResultBody">
							შენ მიერ თავდაპირველად არჩეული საგანი ამ ფაკულტეტზე
							არ ბარდება. თუმცა,{" "}
							<strong>
								არჩევითი საგნის მითითების შემთხვევაში, შეგიძლია,
								ნახო, რა ქულებითაა შესაძლებელი ამ ფაკულტეტზე
								მოხვედრა თუ გრანტის აღება.
							</strong>
						</div>
						<div
							className="buttons"
							style={{ display: "block", textAlign: "center" }}
						>
							<button
								onClick={this.gotoProgramPage}
								className="gray"
							>
								დამატებითი ინფორმაცია
							</button>
						</div>
					</div>
				);
			}
			return (
				<div className="latoriResultContainer" ref={resultRef}>
					{ResultHeader}
					<div className="latoriResultBody">
						არჩეულ საგანსა და საბაკალავრო პროგრამაზე გასული წლების
						სტატისტიკა ვერ მოიძებნა, რის გამოც ინფორმაციას ვერ
						მოგაწვდით.
					</div>
					<div
						className="buttons"
						style={{ display: "block", textAlign: "center" }}
					>
						{currentProgram.grant_subjects && (
							<button
								className="pink"
								onClick={this.onShowGrantPopup}
							>
								გრანტის გამოთვლა
							</button>
						)}
						<button onClick={this.gotoProgramPage} className="gray">
							დამატებითი ინფორმაცია
						</button>
					</div>
				</div>
			);
		}
		const mainText = this.getText(this.state.place, this.state.result);
		setTimeout(this.requestCalculation, 0);
		if (
			this.mainParamsOfLastCalculation.programId !== programId ||
			this.getSubjectKey(
				this.mainParamsOfLastCalculation.electiveSubjects
			) !== subjKey
		) {
			return (
				<div className="latoriResultContainer" ref={resultRef}>
					{ResultHeader}
					<div className="latoriResultBody">{<SvgLoading />}</div>
				</div>
			);
		}

		const numPlaces = getProgramPlaces(
			currentProgram,
			this.state.electiveSubjects.filter(
				subjId => !currentProgram.subjects[0].includes(subjId)
			)
		);

		return (
			<div
				className={
					"latoriResultContainer" +
					(currentProgramUniId === CUUniId ? " cuStyleSliders" : "")
				}
				ref={resultRef}
			>
				{this.state.langScoreChangedTimes === 1 && <IbsuPopup />}
				{ResultHeader}
				<div className="latoriResultBody">
					{this.state.calculatingResults && (
						<div className="darkCurtain">
							<div>{<SvgLoadingWhite />}</div>
						</div>
					)}
					<PlaceSlider
						places={numPlaces}
						defaultPlace={numPlaces}
						onChange={this.onPlaceChange}
						ref={this.placeSliderRef}
						isDisabled={
							currentFixedSubjects.length ===
							currentSubjects.length
						}
					/>
					{mainText}
					{currentSubjects.map(subj => (
						<SubjectScoreSlider
							key={subj.id}
							subject={subj}
							score={this.state.scores[subj.id]}
							onScoreChange={this.onScoreChange}
							onFixChange={this.onFixChange}
							fixed={
								this.state.fixedSubjectIds.indexOf(subj.id) !==
								-1
							}
						/>
					))}
					{this.state.place && (
						<div>
							{/* {currentProgramUniId === CUUniId && (
								<div className="cuScholarship">
									მოიპოვე შიდა გრანტი
								</div>
							)} */}
							<div className="buttons">
								<button
									className="pink"
									onClick={this.onShowGrantPopup}
								>
									გრანტის გამოთვლა
								</button>
								{!this.props.hideExtraInfoButton && (
									<button
										onClick={this.gotoProgramPage}
										className="gray"
									>
										დამატებითი ინფორმაცია
									</button>
								)}
							</div>
						</div>
					)}
					{this.state.showGrantPopup && (
						<Popup onClose={this.onCloseGrantPopup}>
							<PopupContent>
								<Grant
									programId={this.props.programId}
									electiveSubjects={
										this.state.electiveSubjects
									}
									scores={this.state.scores}
									contestScaledScore={
										this.state.contestScaledScore
									}
								/>
							</PopupContent>
						</Popup>
					)}
					{/* {this.state.showGrantPopup &&
						currentProgramUniId === CUUniId && (
							<Popup onClose={this.onCloseGrantPopup}>
								<PopupContent
									style={{
										padding: "0",
										borderRadius: "10px",
									}}
								>
									<CuPopup
                                        programId={this.props.programId}
                                        selectedSubjectId={
                                            this.props.selectedSubjectId
                                        }
                                        scores={this.state.scores}
                                        contestScaledScore={
                                            this.state.contestScaledScore
                                        }
                                    />
								</PopupContent>
							</Popup>
						)} */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	isLogged: !!state.user.loggedIn,
	unis: state.unis.info,
	programs: state.programs.info,
	fixedScores: state.lator.fixedScores,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
	addFixedScoreAction,
	removeFixedScoreAction,
	addLatoriContestScore,
	addLatoriUserScore,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(LatoriSliders);
