import * as React from "react";
import "./styles/navigation.min.css";

interface IProps {
	titles: string[];
	// tslint:disable-next-line
	onClick: (index: number) => void;
	selectedIndex: number;
}

const Navigation: React.SFC<IProps> = props => (
	<div className="navWrapper">
		<div className="navigation">
			{props.titles.map((navTitle, index) =>
				<span
					key={index}
					className={props.selectedIndex === index ? "selected" : ""}
					onClick={() => props.onClick(index)}
				>
					{navTitle}
				</span>
			)}
		</div>
	</div>
);

export default Navigation;
