import * as moment from "moment";
import * as React from "react";
import styled from "@emotion/styled";
import { IMessage } from "../../reducers/chatrooms";
import { SMarginParagraph } from "../../styled-components";

const Container = styled.div`
	padding: 30px 20px;
	color: white;
	cursor: pointer;
	background: #11121b;
	border-bottom: 1px solid #23263e;
	transition: all 0.3s;

	&:hover {
		background: #21243b;
	}
`;

const UserPic = styled.div`
	position: relative;
	display: inline-block;
	vertical-align: middle;
	background-color: black;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin-right: 10px;
	text-align: center;
	line-height: 70px;

	p {
		margin: 0;
		font-size: 32px;
	}
`;

const ChatInfo = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

const Unseen = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	display: inline-block;
	width: 25px;
	height: 25px;
	font-size: 15px;
	line-height: 25px;
	text-align: center;
	background-color: red;
	border-radius: 50%;
`;

interface IOwnProps {
	chatroomId: string;
	userId: string;
	name?: string;
	userIds: string[];
	userNames: string[];
	messages: IMessage[];
	onClick: (chatroomId: string) => void;
}

type IProps = IOwnProps;

class ChatPreview extends React.Component<IProps> {
	getUnseenMessages = (messages: IMessage[], userId: string) => {
		let numberOfUnseen = 0;
		for (let i = 0; i < messages.length; i++) {
			if (messages[i].seenBy.indexOf(userId) < 0) numberOfUnseen++;
		}

		return numberOfUnseen;
	};

	render() {
		const numberOfUnseen = this.getUnseenMessages(
			this.props.messages,
			this.props.userId
		);

		let chatroomName;
		if (this.props.name !== undefined) {
			chatroomName = this.props.name;
		} else if (this.props.userNames.length > 2) {
			chatroomName = this.props.userNames.join(", ");
		} else {
			const userIndex = this.props.userIds.indexOf(this.props.userId);
			chatroomName = this.props.userNames[userIndex === 0 ? 1 : 0];
		}

		let momentObject: moment.Moment;
		let lastMessageDate = "";
		let lastMessageTime = "";

		if (this.props.messages && this.props.messages.length > 0) {
			momentObject = (moment as any)(
				this.props.messages[this.props.messages.length - 1].createdAt
			);
			lastMessageDate = momentObject.format("DD MMM");
			lastMessageTime = momentObject.format("HH:mm");
		}

		return (
			<Container
				onClick={() => this.props.onClick(this.props.chatroomId)}
			>
				<UserPic>
					{numberOfUnseen > 0 && <Unseen>{numberOfUnseen}</Unseen>}
					<p>{chatroomName[0]}</p>
				</UserPic>
				<ChatInfo>
					<SMarginParagraph>
						<span>{chatroomName}</span>
						<span style={{ marginLeft: "20px" }}>
							{lastMessageDate}
						</span>
					</SMarginParagraph>
					<SMarginParagraph>{lastMessageTime}</SMarginParagraph>
				</ChatInfo>
			</Container>
		);
	}
}

export default ChatPreview;
