import { IUniChangedInfo } from "@app/components/Admins/AdminUniEditPage";
import Joi, { SchemaToType } from "@app/utils/joi";
import { EventSchema } from "../events/helpher-schemas";
import { IRProgram, RProgramSchema } from "../programs/helper-schemas";
import {
	ILiveMeeting,
	IRUniversity,
	IUniContent,
	IUniMainInfo,
	LiveMeetingSchema,
	LiveMeetingWithMetadataSchema,
	MainInfoFields,
	RUniversityFields,
	RUniversitySchema,
	socialMedias,
	SocialMediaTypes,
	UniContentSchema,
	UniExpoVideoSchema,
	uniMainInfoSchema,
} from "./helper-schemas";

///

export const AGETUniSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	getEssentialInfo: Joi.boolean(),
	getMainInfo: Joi.boolean(),
	getContents: Joi.boolean(),
	getPrograms: Joi.boolean(),
	locale: Joi.string()
		.valid("ka", "en")
		.optional(),
});

export type IAGETUni = SchemaToType<typeof AGETUniSchema>;

export const RGETUniSchema = RUniversitySchema.optionalKeys(
	RUniversityFields
).keys({
	id: Joi.number()
		.integer()
		.required(),
	mainInfo: uniMainInfoSchema.optional(),
	contents: Joi.array().items(UniContentSchema),
	programs: Joi.array().items(RProgramSchema),
});

export type IRGETUni = Partial<IRUniversity> & {
	id: number;
	locale?: "ka" | "en";
	mainInfo?: IUniMainInfo;
	contents?: IUniContent[];
	programs?: IRProgram[];
};

///

export const AGETManySchema = RUniversitySchema.optionalKeys(
	RUniversityFields
).keys({
	offset: Joi.number().integer(),
	limit: Joi.number().integer(),
	getEssentialInfos: Joi.boolean(),
	getMainInfos: Joi.boolean(),
	getContents: Joi.boolean(),
	getPrograms: Joi.boolean(),
});
export type IAGETMany = Partial<IRUniversity> & {
	offset?: number;
	limit?: number;
	getEssentialInfos?: boolean;
	getMainInfos?: boolean;
	getContents?: boolean;
	getPrograms?: boolean;
};

export const RGETManySchema = Joi.array()
	.items(RGETUniSchema)
	.required();
export type IRGETMany = IRGETUni[];

///

export const APUTSaveSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	uniSettings: RUniversitySchema.optionalKeys(RUniversityFields)
		.keys({
			pagePositionCoefficient: Joi.number(),
			mainCoefficient: Joi.number(),
			mainInfo: uniMainInfoSchema.optionalKeys(MainInfoFields).optional(),
		})
		.required(),
});

export interface IAPUTSave {
	id: number;
	uniSettings: IUniChangedInfo;
}

export const RPUTSaveSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
});
export type IRPUTSave = SchemaToType<typeof RPUTSaveSchema>;

///

export const APOSTCreateSchema = RUniversitySchema.concat(
	uniMainInfoSchema
).forbiddenKeys("id");
export type IAPOSTCreateSchema = Omit<IRUniversity & IUniMainInfo, "id">;

///

export const APOSTUniViewSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAPOSTUniView = SchemaToType<typeof APOSTUniViewSchema>;
export type IRPOSTUniView = void;

///

export const AGETUniManyProgramsSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAGETUniManyPrograms = SchemaToType<
	typeof AGETUniManyProgramsSchema
>;

export const RGETUniManyProgramsSchema = Joi.array().items(RProgramSchema);
export type IRGETUniManyPrograms = SchemaToType<
	typeof RGETUniManyProgramsSchema
>;

///

export const AGETUniManyEventsSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAGETUniManyEvents = SchemaToType<typeof AGETUniManyEventsSchema>;

export const RGETUniManyEventsSchema = Joi.array().items(EventSchema);
export type IRGETUniManyEvents = SchemaToType<typeof RGETUniManyEventsSchema>;

///

export const APOSTUploadSchema = Joi.object().keys({
	uni_id: Joi.number().integer(),
	picType: Joi.string()
		.valid("logo", "cover")
		.required(),
	width: Joi.number(),
	height: Joi.number(),
});
export type IAPOSTUpload = SchemaToType<typeof APOSTUploadSchema>;

export const RPOSTUploadSchema = Joi.object().keys({
	fileName: Joi.string().required(),
	fileFullUrl: Joi.string().required(),
});
export type IRPOSTUpload = SchemaToType<typeof RPOSTUploadSchema>;

///

export const AGETSentSMSNumberForUniSchema = Joi.object()
	.keys({
		uni_id: Joi.number()
			.integer()
			.required(),
	})
	.required();

export type IAGETSentSMSNumberForUni = SchemaToType<
	typeof AGETSentSMSNumberForUniSchema
>;

export const RGETSentSMSNumberForUniSchema = Joi.object()
	.keys({
		count: Joi.number()
			.integer()
			.required(),
	})
	.required();
export type IRGETSentSMSNumberForUni = SchemaToType<
	typeof RGETSentSMSNumberForUniSchema
>;

///

export const AGETLiveMeetingSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	social_media: Joi.string()
		.valid(...socialMedias)
		.optional(),
});

export interface IAGETLiveMeeting {
	uni_id: number;
	social_media?: SocialMediaTypes;
}

export const APOSTLiveMeetingSchema = LiveMeetingSchema.forbiddenKeys(
	"created_at",
	"updated_at"
)
	.optionalKeys("id")
	.required();

export interface IAPOSTLiveMeeting {
	id?: number;
	uni_id: number;
	title: string;
	videoURL: string;
	zoomURL: string | null;
	social_media: SocialMediaTypes;
	start_time: Date;
}

export const RPOSTLiveMeetingSchema = Joi.array()
	.items(LiveMeetingSchema)
	.required();

export const RGETLiveMeetingsWithMetadata = Joi.array()
	.items(LiveMeetingWithMetadataSchema)
	.required();

type ILiveBroadcastContent = "live" | "none" | "upcoming";

export type ILiveMeetingsWithMetada = ILiveMeeting & {
	liveBroadcastContent: ILiveBroadcastContent;
};

export interface IADELETEById {
	id: number;
}

///

export const APOSTUniExoVideoSchema = UniExpoVideoSchema.forbiddenKeys(
	"created_at",
	"updated_at"
)
	.optionalKeys("id")
	.required();

export interface IAPOSTUniExoVideo {
	id?: number;
	uni_id: number;
	videoURL: string;
	social_media: SocialMediaTypes;
}

export const AGETUniExpoVideoSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});

export interface IAGETUniExpoVideo {
	uni_id: number;
}
