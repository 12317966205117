import React, { useMemo } from "react";
import { History } from "history";
import { getQueryStringParams } from "@app/commonJavascript";
import { getUnisAndPrograms, IGetUnisAndPrograms } from "@app/actions/unis";
import { IRootState } from "@app/reducers/root";
import { connect } from "react-redux";
import Header from "../Header";
import { ProgramListByIds } from "../MainPageComponents/ProgramList";

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	getUnisAndPrograms: IGetUnisAndPrograms;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

class SpecialProgramsList extends React.Component<IProps> {
	searchQueries: { ids: number[]; title?: string };

	constructor(props: IProps) {
		super(props);
		const results = getQueryStringParams(props.history.location.search);
		results.ids = JSON.parse(results.ids);
		this.searchQueries = (results as any) as {
			ids: number[];
			title?: string;
		};
	}

	componentDidMount() {
		if (!this.props.unisFullyLoaded || !this.props.programsFullyLoaded) {
			this.props.getUnisAndPrograms().then(({ unis, programs }) => {});
		}
	}

	render() {
		return (
			<>
				<Header history={this.props.history} />
				{!this.props.programs || !this.props.unis ? null : (
					<div className="main">
						<div className="UniListContainer">
							<br />
							<div className="uniHeaderTitle">
								{this.searchQueries.title || "ფაკულტეტები"}
							</div>
							<br /><br />
							<ProgramListByIds
								history={this.props.history}
								unis={this.props.unis}
								programIds={this.searchQueries.ids}
								programs={this.props.programs}
							/>
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	unisFullyLoaded: state.unis.fullyLoaded === true,
	programs: state.programs.info,
	programsFullyLoaded: state.programs.fullyLoaded === true,
});

const ConnectedSpecialProgramsList = connect<
	IStateProps,
	IDispatchProps,
	IOwnProps
>(
	mapStateToProps,
	({
		getUnisAndPrograms,
	} as unknown) as IDispatchProps
)(SpecialProgramsList);

export { ConnectedSpecialProgramsList as SpecialProgramsList };
