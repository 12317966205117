import {
	IAPOSTScore,
	IAPUTSubjectFix,
	IAPUTSubjectScore,
	IRPOSTScore,
	IRPOSTSubject,
	IRPUTSubjectFix,
	IRPUTSubjectScore,
} from "./validators";
import { Requests } from "@app/api";

const apiPath = "/api/users/subjects";
const usersSubjects = {
	saveSubject: (): Promise<IRPOSTSubject> =>
		Requests.send("POST", apiPath + "/:subject_id", {}, null),
	postScores: (data: IAPOSTScore): Promise<IRPOSTScore> =>
		Requests.send("POST", apiPath + "/scores", data, null),
	fixSubject: (data: IAPUTSubjectFix): Promise<IRPUTSubjectFix> =>
		Requests.send("PUT", apiPath + "/:subject_id/fix", data, null),
	saveSubjectScores: (data: IAPUTSubjectScore): Promise<IRPUTSubjectScore> =>
		Requests.send("PUT", apiPath + "/:subject_id/scores", data, null),
};
export default usersSubjects;
