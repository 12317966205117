import api from "@app/api";
import {
	ADD_FAVORITE_PROGRAM,
	LOAD_FAVORITE_PROGRAMS,
	REMOVE_FAVORITE_PROGRAM,
} from "@app/action-types";
import {
	IAPUTInsertOrUpdateFavorite,
	IFavPrograms,
	IRPUTInsertOrUpdateFavorite,
} from "@app/api/users/programs/validators";
import { ILogoutAction } from "./user";
import { IRootState } from "@app/reducers/root";
import { ThunkAction } from "redux-thunk";

interface ILoadFavoriteProgramsAction {
	type: typeof LOAD_FAVORITE_PROGRAMS;
	programs: IFavPrograms;
	lastUpdate: Date;
}
export const loadFavoritePrograms = (
	programs: IFavPrograms,
	lastUpdate: Date = new Date()
): ILoadFavoriteProgramsAction => {
	return {
		type: LOAD_FAVORITE_PROGRAMS,
		programs,
		lastUpdate,
	};
};

interface IAddFavoriteProgramAction {
	type: typeof ADD_FAVORITE_PROGRAM;
	program_id: number;
	uni_id: number;
}

const addFavoriteProgramAction = (
	data: IAPUTInsertOrUpdateFavorite
): IAddFavoriteProgramAction => {
	return {
		type: ADD_FAVORITE_PROGRAM,
		program_id: data.program_id,
		uni_id: data.uni_id,
	};
};

const isInFavorites = (
	program_id: number,
	favorites: IRootState["favoritePrograms"]["favorites"]
): boolean => {
	if (typeof favorites !== "object" || favorites === null) return false;
	return !!favorites[program_id];
};

export type IAddFavoriteProgram = (
	data: IAPUTInsertOrUpdateFavorite
) => Promise<IRPUTInsertOrUpdateFavorite>;
// tslint:disable-next-line:max-line-length
export const addFavoriteProgram = (
	data: IAPUTInsertOrUpdateFavorite
): ThunkAction<
	ReturnType<IAddFavoriteProgram>,
	IRootState,
	null,
	FavoriteProgramsActions
> => (dispatch, getState): ReturnType<IAddFavoriteProgram> => {
	const state = getState();
	if (isInFavorites(data.program_id, state.favoritePrograms.favorites)) {
		// console.log('aleady in favorites');
		return Promise.resolve({ success: true });
	}
	dispatch(addFavoriteProgramAction(data));
	return api.users.programs
		.insertOrUpdateFovorite({
			uni_id: data.uni_id,
			program_id: data.program_id,
		})
		.catch(e => {
			dispatch(removeFavoriteProgramAction(data));
			throw e;
		});
};

interface IRemoveFavoriteProgramAction {
	type: typeof REMOVE_FAVORITE_PROGRAM;
	program_id: number;
	uni_id: number;
}

const removeFavoriteProgramAction = (
	data: IAPUTInsertOrUpdateFavorite
): IRemoveFavoriteProgramAction => {
	return {
		type: REMOVE_FAVORITE_PROGRAM,
		program_id: data.program_id,
		uni_id: data.uni_id,
	};
};

export type IRemoveFavoriteProgram = (
	data: IAPUTInsertOrUpdateFavorite
) => Promise<IRPUTInsertOrUpdateFavorite>;
// tslint:disable-next-line:max-line-length
export const removeFavoriteProgram = (
	data: IAPUTInsertOrUpdateFavorite
): ThunkAction<
	ReturnType<IAddFavoriteProgram>,
	IRootState,
	null,
	FavoriteProgramsActions
> => (dispatch, getState): ReturnType<IAddFavoriteProgram> => {
	const state = getState();
	if (!isInFavorites(data.program_id, state.favoritePrograms.favorites)) {
		// console.log('aleady deleted from favorites');
		return Promise.resolve({ success: true });
	}
	dispatch(removeFavoriteProgramAction(data));
	return api.users.programs
		.insertOrUpdateFovorite({
			uni_id: data.uni_id,
			program_id: data.program_id,
		})
		.then(ret => {
			return ret;
		})
		.catch(e => {
			dispatch(addFavoriteProgramAction(data));
			throw e;
		});
};

export type FavoriteProgramsActions =
	| ILoadFavoriteProgramsAction
	| IAddFavoriteProgramAction
	| IRemoveFavoriteProgramAction
	| ILogoutAction;
