import * as React from "react";
import api from "@app/api";
import ClockPicker from "@app/components/Widgets/ClockPicker";
import ContentLoader from "react-content-loader";
import ContentMedias from "../ContentMedias";
import FancyInput from "../../Widgets/FancyInput";
import ReactQuill from "react-quill";
import SnackNotification from "../../Widgets/SnackNotification";
import Switch from "../../Widgets/MainSwitch";
import { addLoader } from "@app/commonJavascript";
import { addEvent, eventChangeSettings } from "@app/actions/temp";
import { CalendarIntervalPicker } from "../../Widgets/CalendarIntervalPicker";
import { CalendarPicker } from "../../Widgets/CalendarPicker";
import { canEditUniSettings } from "@app/permissions";
import { connect } from "react-redux";
import { History } from "history";
import { IEvent } from "@app/api/events/helpher-schemas";
import { IRootState } from "@app/reducers/root";
import { IRUser } from "@app/api/users/helper-schemas";
import { IUniContent } from "@app/api/unis/helper-schemas";
import { match } from "react-router";
import "@app/components/styles/quillCustomTheme.min.css";
import "react-quill/dist/quill.snow.css";
import "./event_edit_page.min.css";
import "../styles/contentEdit.min.css";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string; event_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	eventChangeSettings: typeof eventChangeSettings;
	addEvent: typeof addEvent;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	event?: IEvent;
	hasEndTime: boolean;
	errorDialogOpen: boolean;
	errorMessage: string;
}

type IEventChangedInfo = Partial<IEvent>;

class AdminEventEditPage extends React.Component<IProps, IState> {
	static loaders() {
		return (
			<div style={{ textAlign: "center" }}>
				{Array(8)
					.fill(0)
					.map((e, index) => (
						<div className="fancyInputAndTitle" key={index}>
							<ContentLoader
								width={400}
								height={115}
								primaryColor="#fff"
								primaryOpacity={0.7}
								secondaryColor="#87878e"
								secondaryOpacity={1}
							>
								<rect
									y="16"
									x="25"
									width="270"
									height="23"
									rx="4"
									ry="4"
								/>
								<rect
									y="55"
									width="400"
									height="60"
									rx="27.5"
									ry="27.5"
								/>
							</ContentLoader>
						</div>
					))}
			</div>
		);
	}

	uni_id: number = +this.props.match.params.uni_id;
	event_id: number | null = +this.props.match.params.event_id;

	_isMounted: boolean;
	eventChangedInfo: IEventChangedInfo = {};

	state = {
		hasEndTime: false,
		errorDialogOpen: false,
		errorMessage: "",
	} as IState;

	constructor(props: IProps) {
		super(props);
		if (
			!this.uni_id ||
			!canEditUniSettings(this.uni_id, props.userData.permissions)
		) {
			this.props.history.replace("/admin/uni/" + this.uni_id);
		}
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.event_id) {
			api.events
				.getById({
					id: this.event_id,
				})
				.then(data => {
					if (data.event) {
						this.setState({
							event: data.event as IEvent,
							hasEndTime: Boolean(data.event.end_time),
						});
					}
				});
		} else {
			const event = {
				uni_id: this.uni_id,
				title: "",
				text: "",
				location: "",
				galleryMedias: null,
				start_time: new Date(new Date().setHours(0, 0, 0, 0)),
				end_time: null,
			} as IEvent;
			this.eventChangedInfo = event;
			delete this.eventChangedInfo.uni_id;
			this.setState({
				event,
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	saveEvent = () => {
		if (Object.keys(this.eventChangedInfo).length === 0) return;
		const removeLoader = addLoader();
		api.events
			.save({
				id: this.event_id || undefined,
				uni_id: this.uni_id,
				eventSettings: this.eventChangedInfo,
			})
			.then(data => {
				removeLoader();
				if (this.event_id)
					this.props.eventChangeSettings(
						this.event_id,
						this.eventChangedInfo,
						this.uni_id
					);
				else
					this.props.addEvent({
						...(this.eventChangedInfo as IEvent),
						uni_id: this.uni_id,
						id: data.id,
					});
				if (!this._isMounted) return;
				this.props.history.replace(
					"/admin/uni/" + this.uni_id + "/events"
				);
			})
			.catch(err => {
				removeLoader();
				if (!this._isMounted) return;
				if (err.response && Array.isArray(err.response.data)) {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "" + err.response.data[0].errorText, // TODO: translate in Georgian
					});
				} else {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "დაფიქსირდა შეცდომა",
					});
				}
			});
	};

	onEventInfoChange = (name: keyof IEvent) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		this.eventChangedInfo[name] = e.target.value as any;
	};

	onTextChange = (text: string) => {
		this.eventChangedInfo.text = text;
	};

	onDateIntervalChange = (
		dateName: "start_time" | "end_time",
		date: Date
	) => {
		if (this.eventChangedInfo[dateName]) {
			this.eventChangedInfo[dateName]!.setFullYear(
				date.getFullYear(),
				date.getMonth(),
				date.getDate()
			);
		} else {
			if (this.state.event![dateName]) {
				this.eventChangedInfo[dateName] = this.state.event![dateName]!;
			} else {
				// If this field is null, it must be end_time
				this.eventChangedInfo[dateName] = new Date(
					this.state.event!.start_time.getTime() + 24 * 3600 * 1000
				);
			}
			this.eventChangedInfo[dateName]!.setFullYear(
				date.getFullYear(),
				date.getMonth(),
				date.getDate()
			);
		}
	};

	onDateChange = (date: Date) => {
		if (this.eventChangedInfo.start_time) {
			this.eventChangedInfo.start_time.setFullYear(
				date.getFullYear(),
				date.getMonth(),
				date.getDate()
			);
		} else {
			this.eventChangedInfo.start_time = this.state.event!.start_time;
			this.eventChangedInfo.start_time.setFullYear(
				date.getFullYear(),
				date.getMonth(),
				date.getDate()
			);
		}
	};

	onStartClockChange = (unit: "hours" | "minutes", value: number) => {
		if (!this.eventChangedInfo.start_time) {
			this.eventChangedInfo.start_time = this.state.event
				? this.state.event.start_time
				: new Date();
		}
		switch (unit) {
			case "hours":
				this.eventChangedInfo.start_time.setHours(value);
				break;
			case "minutes":
				this.eventChangedInfo.start_time.setMinutes(value);
				break;
			default:
				break;
		}
	};

	onEndClockChange = (unit: "hours" | "minutes", value: number) => {
		if (!this.eventChangedInfo.end_time) {
			this.eventChangedInfo.end_time = this.state.event
				? this.state.event.end_time
				: new Date(Date.now() + 24 * 3600 * 1000);
		}
		switch (unit) {
			case "hours":
				this.eventChangedInfo.end_time!.setHours(value);
				break;
			case "minutes":
				this.eventChangedInfo.end_time!.setMinutes(value);
				break;
			default:
				break;
		}
	};

	swithEndTimeStatus = () => {
		if (this.state.hasEndTime) {
			this.eventChangedInfo.end_time = null;
		} else {
			const dateObj =
				this.eventChangedInfo.start_time ||
				this.state.event!.start_time;
			this.eventChangedInfo.end_time = new Date(
				dateObj.getTime() + 24 * 3600 * 1000
			);
			this.eventChangedInfo.end_time.setHours(0);
			this.eventChangedInfo.end_time.setMinutes(0);
			this.eventChangedInfo.end_time.setSeconds(0);
		}
		this.setState({
			hasEndTime: !this.state.hasEndTime,
			event: {
				...this.state.event!,
				end_time: this.eventChangedInfo.end_time,
			},
		});
	};

	onContentMediaChange = (newGalleryMedia: IUniContent["galleryMedias"]) => {
		this.eventChangedInfo.galleryMedias = newGalleryMedia;
	};

	handleErrorDialogClose = () => {
		this.setState({
			errorDialogOpen: false,
		});
	};

	uploadPhotos = (data: FormData) => {
		data.append("uni_id", "" + this.uni_id);
		return api.events.uploadPhotos(data as any);
	};

	render() {
		if (typeof this.state.event === "undefined")
			return AdminEventEditPage.loaders();
		return (
			<React.Fragment>
				<div className="uniEditPage">
					<FancyInput
						defaultValue={this.state.event.title}
						title="ღონისძიების სათაური"
						onChange={this.onEventInfoChange("title")}
					/>
					<FancyInput
						defaultValue={this.state.event.location}
						title="ღონისძიების ადგილმდებარეობა"
						onChange={this.onEventInfoChange("location")}
					/>
					<div className="QuillContainer quill--white--theme">
						<h2>ტექსტი</h2>
						<ReactQuill
							className="quill"
							defaultValue={this.state.event.text}
							onChange={this.onTextChange}
						/>
					</div>
					<div className="admin_event_date_edit_container">
						<div className="admin_event_date_edit">
							<h2>დაწყება/დამთავრება</h2>
							{this.state.hasEndTime ? (
								<CalendarIntervalPicker
									onDateIntervalChange={
										this.onDateIntervalChange
									}
									start_time={
										this.event_id
											? new Date(
													this.state.event.start_time
											  )
											: null
									}
									end_time={
										this.event_id
											? this.state.event.end_time
												? new Date(
														this.state.event.end_time
												  )
												: null
											: null
									}
								/>
							) : (
								<CalendarPicker
									onDateChange={this.onDateChange}
									start_time={
										this.event_id
											? new Date(
													this.state.event.start_time
											  )
											: null
									}
								/>
							)}
							<h2>დამთავრების თარიღი (არა/კი):</h2>
							<Switch
								size="big"
								checked={!!this.state.hasEndTime}
								onClick={this.swithEndTimeStatus}
							/>
						</div>
						<div className="admin_event_date_edit">
							<h2>დაწყების საათი</h2>
							<ClockPicker
								onClockChange={this.onStartClockChange}
								hour={
									this.event_id
										? new Date(
												this.state.event.start_time
										  ).getHours()
										: null
								}
								minute={
									this.event_id
										? new Date(
												this.state.event.start_time
										  ).getMinutes()
										: null
								}
							/>
						</div>
						{this.state.hasEndTime && (
							<div className="admin_event_date_edit">
								<h2>დამთავრების საათი</h2>
								<ClockPicker
									onClockChange={this.onEndClockChange}
									hour={
										this.event_id
											? this.state.event.end_time
												? new Date(
														this.state.event.end_time
												  ).getHours()
												: null
											: null
									}
									minute={
										this.event_id
											? this.state.event.end_time
												? new Date(
														this.state.event.end_time
												  ).getMinutes()
												: null
											: null
									}
								/>
							</div>
						)}
					</div>
					<ContentMedias
						galleryMedias={this.state.event.galleryMedias}
						onChange={this.onContentMediaChange}
						uploadImageFunc={this.uploadPhotos}
						galleryPath="etc"
					/>
					<button
						className="adminPrimaryButton"
						onClick={this.saveEvent}
					>
						შენახვა
					</button>
				</div>
				<SnackNotification
					autoHideDuration={6000}
					onClose={this.handleErrorDialogClose}
					message={this.state.errorMessage}
					variant="error"
					open={this.state.errorDialogOpen}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		eventChangeSettings,
		addEvent,
	}
)(AdminEventEditPage);
