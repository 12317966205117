import * as React from "react";
import memoizeOne from "memoize-one";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { Link } from "react-router-dom";
import { SmallProg } from "../Programs/ProgramCard";
import { UniLogo } from "../Universities/FreeUniversityPage";
import "./styles/favorite-programs.min.css";

interface IFaviriteProgram {
	id: number;
	fullProgram: IRProgram;
	uni: IRUniversity;
}

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

class FavoritePrograms extends React.PureComponent<IProps> {
	lastFavs = memoizeOne(
		(favoritePrograms: IRootState["favoritePrograms"]["favorites"]) => {
			if (!favoritePrograms) return [];
			const universities = this.props.unis;
			const programs = this.props.programs;

			const universitiesMap: IRUniversity[] = [];
			const programsMap: IRProgram[] = [];

			const userFavoriteUnis: IFaviriteProgram[] = [];

			for (const program in favoritePrograms) {
				if (favoritePrograms.hasOwnProperty(program)) {
					const id = favoritePrograms[program]!.program_id;
					const uniId = favoritePrograms[program]!.uni_id;

					const currentProgram = programs!.find(
						curr => curr.id === id
					);
					if (typeof currentProgram !== "undefined") {
						programsMap[id] = currentProgram;
					}

					if (typeof universitiesMap[uniId] === "undefined") {
						const currentUni = universities!.find(
							curr => curr.id === uniId
						);
						if (typeof currentUni !== "undefined") {
							universitiesMap[uniId] = currentUni;
						}
					}

					userFavoriteUnis.push({
						id,
						fullProgram: programsMap[id],
						uni: universitiesMap[uniId],
					});
				}
			}
			return userFavoriteUnis;
		}
	);

	render() {
		const userFavoriteUniversities = this.lastFavs(
			this.props.favoritePrograms
		);
		return (
			<React.Fragment>
				<div className="favorite-programs-container">
					<div className="favorite-programs-list">
						{userFavoriteUniversities.map(currentProgram => (
							<FavoriteProgramCard
								key={currentProgram.id}
								id={currentProgram.id}
								uniId={currentProgram.uni.id}
								name={currentProgram.fullProgram.name}
								uniName={currentProgram.uni.name}
								code={currentProgram.fullProgram.code}
								price={currentProgram.fullProgram.price}
								fullProgram={currentProgram.fullProgram}
								fullUni={currentProgram.uni}
								history={this.props.history}
							/>
						))}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const FavoriteProgramCard = props => (
	<Link to={"/programs/" + props.fullProgram.urlName}>
		<div className="favorite-program-card">
			<SmallProg
				className="favorite-program-image"
				program={props.fullProgram}
			>
				<Link to={"/unis/" + props.fullUni.urlName}>
					<UniLogo
						className="university-image"
						logoUrl={props.fullUni.logoUrl}
						logoBGColor={props.fullUni.logoBGColor}
					/>
				</Link>
			</SmallProg>
			<div className="favorite-program-info">
				<p>
					<b>{props.name}</b>
				</p>
				<p>{props.uniName}</p>
				<br />
				<p>
					<b>კოდი: </b>
					{props.code}
				</p>
				<p>
					<b>ფასი: </b>
					{props.price}
				</p>
			</div>
		</div>
	</Link>
);

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
	favoritePrograms: state.favoritePrograms.favorites,
});

export default connect<IStateProps, IOwnProps>(
	mapStateToProps,
	{} as any
)(FavoritePrograms);
