import { subscribeUni, unsubscribeUni } from "@app/actions/subscribed-unis";
import { getUniById } from "@app/actions/unis";
import api from "@app/api";
import {
	IExpoRoomColor,
	ILiveMeeting,
	IUniExpoVideo,
} from "@app/api/unis/helper-schemas";
import { IRGETUni } from "@app/api/unis/validators";
import {
	extractVideoIdFromYoutubeLink,
	getExpoBannerUrl,
	useWindowSize,
	YOUTUBE_API_KEY,
} from "@app/commonJavascript";
import requireLoginForAction from "@app/components/requireLoginForAction";
import LoadingSVG from "@app/components/styles/img/LoadingWhite";
import { UniLogo } from "@app/components/Universities/FreeUniversityPage";
import SocialMediaVideo from "@app/components/Widgets/SocialMediaVideo";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import axios from "axios";
import { History } from "history";
import memoizeOne from "memoize-one";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import AboutUsSVG from "../img/AboutUs.svg";
import Brochures from "../img/Brochures.svg";
import ContactSVG from "../img/Contact.svg";
import ExitSVG from "../img/Exit.svg";
import FacebookSVG from "../img/Facebook.svg";
import FBShareSVG from "../img/FBShare.svg";
import OfferSVG from "../img/Offer.svg";
import ProgramsSVG from "../img/Programs.svg";
import StartSVG from "../img/Star.svg";
import StarSelectedSVG from "../img/StarSelected.svg";
import VideoSVG from "../img/Video.svg";
import WebpageSVG from "../img/Webpage.svg";
import "../styles/expo-uni-page.min.css";
import BlueB from "./room-bg-templates/Blue-B.png";
import BlueP from "./room-bg-templates/Blue-P.png";
import GreenB from "./room-bg-templates/Green-B.png";
import GreenP from "./room-bg-templates/Green-P.png";
import LightBlueB from "./room-bg-templates/LightBlue-B.png";
import LightBlueP from "./room-bg-templates/LightBlue-P.png";
import PinkB from "./room-bg-templates/Pink-B.png";
import PinkP from "./room-bg-templates/Pink-P.png";
import RedB from "./room-bg-templates/Red-B.png";
import RedP from "./room-bg-templates/Red-P.png";
import YellowB from "./room-bg-templates/Yellow-B.png";
import YellowP from "./room-bg-templates/Yellow-P.png";

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;
interface IOwnProps {
	match: match<{ uni_id: string }>;
	history: History;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

const ExpoUniPage: React.FC<IProps> = props => {
	const uniId = Number(props.match.params.uni_id);
	const [uni, setUni] = useState<IRGETUni>();
	const [video, setVideo] = useState<ILiveMeeting | IUniExpoVideo | null>(
		null
	);
	const [mounted, setMounted] = useState<boolean>(false);
	const getBG = memoizeOne(
		(color?: IExpoRoomColor | null, premium?: boolean) => {
			switch (color) {
				case "Blue":
					return premium ? BlueP : BlueB;
				case "Green":
					return premium ? GreenP : GreenB;
				case "LightBlue":
					return premium ? LightBlueP : LightBlueB;
				case "Red":
					return premium ? RedP : RedB;
				case "Yellow":
					return premium ? YellowP : YellowB;
				case "Pink":
				default:
					return premium ? PinkP : PinkB;
			}
		}
	);

	useEffect(() => {
		api.unis
			.getById({ id: uniId, getMainInfo: true })
			.then(uni => {
				setUni(uni);
				api.unis
					.getLiveMeetings({ uni_id: uniId })
					.then(liveMeetings => {
						if (liveMeetings.length) {
							axios
								.get(
									`https://www.googleapis.com/youtube/v3/videos?key=${YOUTUBE_API_KEY}&id=${liveMeetings
										.map(el =>
											extractVideoIdFromYoutubeLink(
												el.videoURL
											)
										)
										.join(",")}&part=snippet`
								)
								.then(response => {
									const metadataObj = {};
									for (const item of response.data.items) {
										metadataObj[item.id] = item;
									}
									for (const liveMeeting of liveMeetings) {
										const id = extractVideoIdFromYoutubeLink(
											liveMeeting.videoURL
										);
										const metaData = metadataObj[id];
										if (
											metaData &&
											metaData.snippet &&
											metaData.snippet
												.liveBroadcastContent
										) {
											if (
												metaData.snippet
													.liveBroadcastContent ===
												"live"
											) {
												setVideo(liveMeeting);
												setMounted(true);
												return;
											}
										}
									}
									api.unis
										.getUniExpoVideoByUniId({
											uni_id: uniId,
										})
										.then(data => {
											setVideo(data);
											setMounted(true);
										})
										.catch(e => {
											console.log(e);
											setMounted(true);
										});
								})
								.catch(e => {
									console.log(e);
									setMounted(true);
								});
						} else {
							api.unis
								.getUniExpoVideoByUniId({
									uni_id: uniId,
								})
								.then(data => {
									setVideo(data);
									setMounted(true);
								})
								.catch(e => {
									console.log(e);
									setMounted(true);
								});
						}
					})
					.catch(e => {
						console.log(e);
						setMounted(true);
					});
			})
			.catch(e => {
				console.log(e);
				setMounted(true);
			});
	}, [props.history, uniId]);

	const onSubscribe = useCallback(() => {
		const isUniSubscribed =
			props.subscribedUnis.subscribed &&
			props.subscribedUnis.subscribed[uniId];
		triggerEvent(
			{
				category: "Button",
				action: `${isUniSubscribed ? "Unsubscribe" : "Subscribe"} uni`,
				label: "from uni page",
			},
			{ uni_id: uniId }
		);
		requireLoginForAction(() => {
			(!isUniSubscribed
				? props.subscribeUni(uniId)
				: props.unsubscribeUni(uniId)
			).then();
		});
	}, [props, uniId]);

	const { width } = useWindowSize();

	if (!mounted)
		return (
			<div className="main">
				<LoadingSVG width={50} height={50} />
			</div>
		);
	else if (!uni) return <div>უნივერსიტეტი ვერ მოიძებნა</div>;

	const BGwidth = Math.round(width * 0.6);
	const BGHeight = Math.round((BGwidth * 1081) / 1921);
	const videoWidth = Math.round(BGwidth * 0.283);
	const videoHeight = Math.round((videoWidth * 9) / 16);
	const isUniSubscribed =
		props.subscribedUnis.subscribed &&
		props.subscribedUnis.subscribed[uniId];

	const upperLogoWidth = Math.round(width * 0.084);
	const lowerLogoWidth = Math.round(width * 0.04);

	const mainBannerWidth = Math.round(BGwidth * 0.48);
	const mainBannerHeight = Math.round((mainBannerWidth * 150) / 844);
	const secondaryBannerWidth = BGwidth * 0.075;
	const secondaryBannerHeight = (secondaryBannerWidth * 168) / 67;

	const bigIconWidth = Math.round(BGwidth * 0.04);
	const mediumIconWidth = Math.round(BGwidth * 0.029);
	const smallIconWidth = Math.round(BGwidth * 0.028);

	const redirect = (href: string) => window.open(href, "_blank");

	return (
		<div className="expo-main">
			<Header uni={uni} />
			<div className="content">
				<SideBar uni={uni} />

				<div className="main-content" id="main-content">
					<div
						className="BG"
						style={{
							backgroundImage: `url(${getBG(
								uni.expoRoomColor as
									| IExpoRoomColor
									| null
									| undefined,
								Boolean(uni.premium)
							)})`,
							width: BGwidth,
							height: BGHeight,
						}}
					>
						{video && (
							<SocialMediaVideo
								url={video.videoURL}
								socialMedia={video.social_media}
								style={{
									position: "absolute",
									top: "calc(22%)",
									left: `calc(50% - ${Math.round(
										videoWidth / 2
									)}px)`,
									width: videoWidth,
									height: videoHeight,
									borderRadius: "2%",
								}}
								queryString="autoplay=1"
							/>
						)}
						{uni.logoUrl && (
							<UniLogo
								logoBGColor={uni.logoBGColor || "transparent"}
								logoUrl={uni.logoUrl}
								className="uni-logo"
								style={{
									width: upperLogoWidth,
									height: upperLogoWidth,
								}}
							/>
						)}
						{uni.logoUrl && (
							<UniLogo
								logoBGColor={uni.logoBGColor || "transparent"}
								logoUrl={uni.logoUrl}
								className="uni-logo-bottom"
								style={{
									width: lowerLogoWidth,
									height: lowerLogoWidth,
									left: `calc(50% - ${Math.round(
										lowerLogoWidth / 2
									)}px)`,
									bottom: "7%",
								}}
							/>
						)}
						{uni.expoMainBannerUrl && (
							<div
								style={{
									backgroundImage: `url(${getExpoBannerUrl(
										uni.expoMainBannerUrl!
									)})`,
									width: mainBannerWidth,
									height: mainBannerHeight,
									left: `calc(50% - ${Math.round(
										mainBannerWidth / 2
									)}px)`,
									top: "4%",
								}}
								className="main-banner"
							/>
						)}
						{uni.expoSecondaryBannerUrl && (
							<div
								className="secondary-banner"
								style={{
									backgroundImage: `url(${getExpoBannerUrl(
										uni.expoSecondaryBannerUrl!
									)})`,
									width: secondaryBannerWidth,
									height: secondaryBannerHeight,
									right: `12.785%`,
									top: "33%",
								}}
							/>
						)}
						<img
							style={{
								width: bigIconWidth,
								left: "2%",
								top: "3.4%",
							}}
							onClick={onSubscribe}
							src={isUniSubscribed ? StarSelectedSVG : StartSVG}
							alt=""
						/>
						<img
							src={WebpageSVG}
							alt=""
							onClick={() =>
								uni.mainInfo &&
								redirect(`//${uni.mainInfo.web}`)
							}
							style={{
								width: smallIconWidth,
								left: "14.4%",
								top: "52.4%",
							}}
						/>
						<img
							src={ExitSVG}
							alt=""
							onClick={() => props.history.push("/uni-expo")}
							style={{
								width: bigIconWidth,
								left: "1.5%",
								bottom: "2%",
							}}
						/>
						<img
							src={AboutUsSVG}
							alt=""
							onClick={() =>
								redirect(
									`/unis/${uni.urlName}?content=about_us`
								)
							}
							style={{
								width: smallIconWidth,
								left: "43%",
								bottom: "18%",
							}}
						/>
						<img
							src={ContactSVG}
							alt=""
							onClick={() =>
								redirect(`/unis/${uni.urlName}?contact`)
							}
							style={{
								width: smallIconWidth,
								left: "54%",
								bottom: "18%",
							}}
						/>
						{!!uni.premium && (
							<>
								<img
									src={Brochures}
									alt=""
									onClick={() =>
										redirect(
											`/unis/${uni.urlName}?content=brochures`
										)
									}
									style={{
										width: mediumIconWidth,
										right: "28.05%",
										top: "41.8%",
									}}
								/>
								<img
									src={OfferSVG}
									alt=""
									onClick={() =>
										redirect(
											`/unis/${uni.urlName}?content=offers`
										)
									}
									style={{
										width: mediumIconWidth,
										right: "15.1%",
										bottom: "21.8%",
									}}
								/>
							</>
						)}
						<img
							src={ProgramsSVG}
							alt=""
							onClick={() =>
								redirect(
									`/unis/${uni.urlName}?content=programs`
								)
							}
							style={{
								width: mediumIconWidth,
								right: "3.43%",
								top: "43%",
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Header: React.FC<{ uni: IRGETUni }> = ({ uni }) => {
	const { width } = useWindowSize();
	const fontSize = width * 0.01;
	const logoWidth = width * 0.075;
	return (
		<div className="header" style={{ fontSize }}>
			<td>
				<div className="logo-and-name-container">
					<div
						className="logo-container"
						style={{
							background: `url(/photos/unilogos/${
								uni.logoUrl
							}) center center no-repeat, ${uni.logoBGColor ||
								"#fff"}`,
							backgroundSize: "contain",
							width: logoWidth,
							height: logoWidth,
						}}
					/>
					<div className="uni-name-container">{uni.name}</div>
				</div>
			</td>
			<div
				className="uni-description-container"
				dangerouslySetInnerHTML={
					uni.shortDescription
						? { __html: uni.shortDescription }
						: undefined
				}
				style={{ display: "table-cell" }}
			/>
		</div>
	);
};

export const PremiumSidebarItemNames = {
	about_us: "ჩვენ შესახებ",
	brochures: "ბროშურები",
	videos: "ვიდეოები",
	programs: "ფაკულტეტები",
	offers: "შეთავაზებები",
};

const SideBar: React.FC<{ uni: IRGETUni }> = ({ uni }) => {
	const { width } = useWindowSize();
	const fontSize = width * 0.01;
	const iconWidth = width * 0.03;
	const style = { width: iconWidth, height: iconWidth };
	const marginSize = width * 0.004;
	const marginLeftSize = width * 0.006;

	const onShare = () => {
		(window as any).FB.ui(
			{
				display: "popup",
				method: "share",
				href: `https://unicatalog.ge/api/unis/${uni.id}/generate-share-page`,
			},
			function(response) {
				//
			}
		).catch(e => console.log(e));
	};

	const items = [
		{
			name: "ჩვენ შესახებ",
			href: `/unis/${uni.urlName}?content=about_us`,
			icon: (
				<img src={AboutUsSVG} className="icon" style={style} alt="" />
			),
		},
		{
			name: "კონტაქტი",
			href: `/unis/${uni.urlName}?contact`,
			icon: (
				<img src={ContactSVG} className="icon" style={style} alt="" />
			),
		},
		{
			name: "ვებგვერდი",
			href: uni.mainInfo && `//${uni.mainInfo.web}`,
			icon: (
				<img src={WebpageSVG} className="icon" style={style} alt="" />
			),
		},
		{
			name: "ფაკულტეტები",
			href: `/unis/${uni.urlName}?content=programs`,
			icon: (
				<img src={ProgramsSVG} className="icon" style={style} alt="" />
			),
		},
		{
			name: "ბროშურები",
			href: `/unis/${uni.urlName}?content=brochures`,
			icon: <img src={Brochures} className="icon" style={style} alt="" />,
			premium: true,
		},
		{
			name: "შეთავაზებები",
			href: `/uni-expo/offers/${uni.id}`,
			icon: <img src={OfferSVG} className="icon" style={style} alt="" />,
			premium: true,
		},
		{
			name: `ვიდეოები`,
			href: `/unis/${uni.urlName}?content=videos`,
			icon: <img src={VideoSVG} className="icon" style={style} alt="" />,
			premium: true,
		},
		{
			name: "გაზიარება",
			onClick: onShare,
			icon: (
				<img src={FBShareSVG} className="icon" style={style} alt="" />
			),
			premium: true,
		},
		{
			name: "Facebook",
			href: uni.facebookUrl || undefined,
			icon: (
				<img src={FacebookSVG} className="icon" style={style} alt="" />
			),
			premium: true,
		},
	];

	return (
		<td>
			<div className="sidebar" id="zaza">
				{items.map((item, index) => {
					if (item.premium && !uni.premium) return null;
					return (
						<React.Fragment key={index}>
							<div
								className="sidebar-item"
								onClick={
									item.href
										? () => window.open(item.href, "_blank")
										: item.onClick
								}
								style={{
									fontSize,
									margin: `${marginSize}px 0px`,
								}}
							>
								{item.icon}
								<div
									className="item-link"
									style={{ marginLeft: marginLeftSize }}
								>
									{item.name}
								</div>
							</div>
							<br />
						</React.Fragment>
					);
				})}
			</div>
		</td>
	);
};

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	tempUnis: state.temp.unis,
	subscribedUnis: state.subscribedUnis,
});

const mapDispatchToProps = {
	getUniById,
	subscribeUni,
	unsubscribeUni,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({
		getUniById,
		subscribeUni,
		unsubscribeUni,
	} as unknown) as IDispatchProps
)(ExpoUniPage);
