import React from "react";
import api from "@app/api";

export class DoAdminStuff extends React.Component<{}, {}> {
	onUpdate = () => {
		api.programs.updateMinScoreLimits().then(() => console.log("success!"));
	};
	render() {
		return <button onClick={this.onUpdate}>update</button>;
	}
}
