import { loadFavoritePrograms } from "@app/actions/favorite-programs";
import { loadLatorFromStorage } from "@app/actions/lator";
import {
	getProgramsEssentialInfo,
	loadProgramsFromStorage,
} from "@app/actions/programs";
import { loadSubscribedUnis } from "@app/actions/subscribed-unis";
import { loadTemp } from "@app/actions/temp";
import { getUnisEssentialInfo, loadUnisFromStorage } from "@app/actions/unis";
import { loadUser } from "@app/actions/user";
import { Requests } from "@app/api";
import App from "@app/App";
import { _console } from "@app/commonJavascript";
import "@app/components/styles/commonStyles.min.css";
import SubscriptionPopup from "@app/components/subscription-popup";
import { getInitialLocale } from "@app/globals";
import { GlobalHistory } from "@app/global_history";
import handleActionOfOtherTab from "@app/handleActionOfOtherTab";
import IntlWrapper from "@app/intl";
import { createModules } from "@app/modules";
import { IRootActions, IRootState } from "@app/reducers/root";
import rootReducer from "@app/root-reducer";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import dotenv from "dotenv";
import "lightbox-react/style.css";
import "polyfills";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import * as serviceWorker from "./serviceWorker";
dotenv.config();

const store = createStore(
	rootReducer,
	{
		intl: {
			locale: getInitialLocale(),
			messages: {},
		},
	},
	composeWithDevTools(
		applyMiddleware(reduxThunk as ThunkMiddleware<IRootState, IRootActions>)
	)
);
createModules({
	store,
	request: Requests,
});
export { store };
export const ReduxStoreContext = React.createContext(store);

window.addEventListener("storage", (e: StorageEvent) => {
	handleActionOfOtherTab(e, store.dispatch);
});

if (localStorage.unis) {
	try {
		const unis = JSON.parse(localStorage.unis);
		store.dispatch(
			loadUnisFromStorage(unis.info, unis.fullyLoaded, unis.lastUpdate)
		);
		const lastUpdate = new Date(unis.lastUpdate);
		if (Date.now() - lastUpdate.getTime() > 1000 * 60 * 30) {
			// less than 30 minutes passed
			getUnisEssentialInfo(true)(store.dispatch, store.getState, null);
		}
	} catch (e) {
		_console.error(e);
	}
}

if (localStorage.programs) {
	try {
		const programs = JSON.parse(localStorage.programs);
		store.dispatch(
			loadProgramsFromStorage(
				programs.info,
				programs.fullyLoaded,
				programs.lastUpdate
			)
		);
		const lastUpdate = new Date(programs.lastUpdate);
		if (Date.now() - lastUpdate.getTime() > 1000 * 60 * 30) {
			// less than 30 minutes passed
			getProgramsEssentialInfo(true)(
				store.dispatch,
				store.getState,
				null
			);
		}
	} catch (e) {
		_console.error(e);
	}
}

if (localStorage.favoritePrograms) {
	try {
		const favoritePrograms = JSON.parse(localStorage.favoritePrograms);
		store.dispatch(
			loadFavoritePrograms(
				favoritePrograms.favorites,
				favoritePrograms.lastFavUpdate
			)
		);
	} catch (e) {
		_console.error(e);
	}
}

if (localStorage.lator) {
	try {
		const lator = JSON.parse(localStorage.lator);
		store.dispatch(loadLatorFromStorage(lator));
	} catch (e) {
		_console.error(e);
	}
}

if (localStorage.subscribedUnis) {
	try {
		const subscribedUnis = JSON.parse(localStorage.subscribedUnis);
		store.dispatch(
			loadSubscribedUnis(
				subscribedUnis.subscribed,
				subscribedUnis.lastUpdate
			)
		);
	} catch (e) {
		_console.error(e);
	}
}

if (localStorage.user) {
	try {
		const user = JSON.parse(localStorage.user);
		store.dispatch(loadUser(user));
	} catch (e) {
		_console.error(e);
	}
}

if (sessionStorage.temp) {
	try {
		const temp = JSON.parse(sessionStorage.temp);
		store.dispatch(loadTemp(temp));
	} catch (e) {
		_console.error(e);
	}
}

ReactGA.initialize("UA-133455344-1");

(async () => {
	const theme = createMuiTheme({
		palette: {
			primary: {
				light: "#63bed9",
				main: "#278ea7",
				dark: "#023e5a",
			},
			secondary: {
				light: "#ffd64a",
				main: "#ffa500",
				dark: "#c67600",
			},
		},
		typography: {
			useNextVariants: true,
			fontFamily: "RobotoUpperCase",
		},
		overrides: {
			MuiButton: {
				root: {
					textTransform: "initial",
				},
			},
		},
	});
	ReactDOM.render(
		<MuiThemeProvider theme={theme}>
			<Provider store={store}>
				<IntlWrapper>
					<BrowserRouter>
						<>
							<GlobalHistory />
							<SubscriptionPopup />
							<Route component={App} />
						</>
					</BrowserRouter>
				</IntlWrapper>
			</Provider>
		</MuiThemeProvider>,
		document.getElementById("root")
	);
	serviceWorker.unregister();
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
