import { updateUserMobile } from "@app/actions/user";
import uniExpo from "@app/api/uni-expo/controller";
import unis from "@app/api/unis/controller";
import { ILiveMeetingWithMetadata } from "@app/api/unis/helper-schemas";
import { IRGETUni } from "@app/api/unis/validators";
import users from "@app/api/users/controller";
import LoginPopup from "@app/components/LoginPopup";
import { UniLogo } from "@app/components/Universities/FreeUniversityPage";
import Popup, { PopupContent } from "@app/components/Widgets/Popup";
import { IRootState } from "@app/reducers/root";
import { store } from "index";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ExpoLiveState } from ".";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";

type IStateProps = ReturnType<typeof mapStateToProps>;

export interface TimetableItemProps {
	state: ExpoLiveState;
	live: ILiveMeetingWithMetadata;
}

type IProps = IStateProps & TimetableItemProps;

const TimetableRow: React.FC<IProps> = function TimetableItem({
	user,
	state,
	live,
}) {
	const [uni, setUni] = useState<IRGETUni>();
	const [buttonState, setButtonState] = useState<ExpoLiveState>();
	const [loginPopup, setLoginPopup] = useState(false);

	const [mustAddMobile, setMustAddMobile] = useState(false);
	const [enteredMobile, setEnteredMobile] = useState("");

	useEffect(() => {
		if (live) unis.getById({ id: live.uni_id }).then(data => setUni(data));
		setButtonState(state);
	}, [live, state]);

	const handleReserveClick = useCallback(() => {
		if (user) {
			if (!user.mobile) {
				setMustAddMobile(true);
			} else {
				if (buttonState === ExpoLiveState.isNotReserved) {
					if (live) {
						uniExpo
							.saveUserReservation({ live_id: live.id })
							.catch(e => console.log(e));
						setButtonState(ExpoLiveState.isReserved);
					}
				} else {
					if (live) {
						uniExpo
							.deleteUserReservation({ live_id: live.id })
							.catch(e => console.log(e));
						setButtonState(ExpoLiveState.isNotReserved);
					}
				}
			}
		} else {
			setLoginPopup(true);
		}
	}, [buttonState, live, user]);

	const handleSaveMobileClick = useCallback(() => {
		users
			.updateUserMobile({ mob: enteredMobile })
			.then(() => {
				store.dispatch(updateUserMobile({ mobile: enteredMobile }));
				window.location.reload();
			})
			.catch(e => console.log(e));
	}, [enteredMobile]);

	const handleVideoPlayerClick = useCallback(
		(videoURL: string) => () => {
			window.open(videoURL);
		},
		[]
	);

	return (
		<>
			{loginPopup && (
				<LoginPopup
					onClose={() => setLoginPopup(false)}
					onSuccess={() => window.location.reload()}
				/>
			)}
			{mustAddMobile && (
				<Popup onClose={() => setMustAddMobile(false)}>
					<PopupContent className="enterMobilePopup">
						<input
							value={enteredMobile}
							onChange={e => setEnteredMobile(e.target.value)}
							className="enterMobileInput"
							placeholder="შეიყვანეთ მობილური"
						/>
						<button
							className="enterMobileButton"
							onClick={handleSaveMobileClick}
						>
							შენახვა
						</button>
					</PopupContent>
				</Popup>
			)}
			{live && uni && (
				<tr className="timetableItemContainer">
					<td className="timetableItemLogo">
						{uni && uni.logoUrl && uni.logoBGColor !== undefined && (
							<UniLogo
								style={{
									height: "56px",
									width: "56px",
									borderRadius: "100%",
								}}
								logoUrl={uni.logoUrl}
								logoBGColor={uni.logoBGColor}
							/>
						)}
					</td>
					<td className="timetableItemName">{uni.name}</td>
					<td className="timetableItemTitle">{live.title}</td>
					<td className="timetableItemTime fixedLength">{`${live.start_time!.getHours()}:${live
						.start_time!.getMinutes()
						.toString()
						.padStart(2, "0")}`}</td>
					{live.status === "live" ? (
						<td>
							<button
								className="liveButton"
								onClick={handleVideoPlayerClick(live.videoURL)}
							>
								<span>გადასვლა </span>
								<PlayIcon className="icon" />
							</button>
						</td>
					) : (
						<td>
							<button
								onClick={handleReserveClick}
								className={
									buttonState === ExpoLiveState.isReserved
										? "reservedButton"
										: "notReservedButton"
								}
							>
								{buttonState === ExpoLiveState.isReserved
									? "დაჯავშნილია"
									: "დაჯავშნა"}
							</button>
						</td>
					)}
				</tr>
			)}
		</>
	);
};

const mapStateToProps = (state: IRootState) => ({
	user: state.user.userData,
});

export default connect<IStateProps, null, TimetableItemProps>(mapStateToProps)(
	TimetableRow
);
