export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const LOAD_UNIS_ESSENTIAL_INFO = "LOAD_UNIS_ESSENTIAL_INFO";
export const LOAD_ONE_UNI_ESSENTIAL_INFO = "LOAD_ONE_UNI_ESSENTIAL_INFO";
export const LOAD_UNI_DISPLAY_INFO = "LOAD_UNI_DISPLAY_INFO";
export const REMOVE_UNI_DISPLAY_INFO = "REMOVE_UNI_DISPLAY_INFO";
export const LOAD_PROGRAMS_ESSENTIAL_INFO = "LOAD_PROGRAMS_ESSENTIAL_INFO";
export const LOAD_FAVORITE_PROGRAMS = "LOAD_FAVORITE_PROGRAMS";
export const ADD_FAVORITE_PROGRAM = "ADD_FAVORITE_PROGRAM";
export const REMOVE_FAVORITE_PROGRAM = "REMOVE_FAVORITE_PROGRAM";
export const CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT =
	"CHNAGE_PROGRAM_UNI_POSITION_COEFFICIENT";
export const CHANGE_UNI_POSITION_COEFFICIENT = "CHANGE_UNI_POSITION_COEFICIENT";
export const CHANGE_UNI_SETTINGS = "CHANGE_UNI_SETTINGS";
export const CHANGE_PROGRAM_SETTINGS = "CHANGE_PROGRAM_SETTINGS";
export const LOAD_PROGRAMS_OF_ONE_UNI = "LOAD_PROGRAMS_OF_ONE_UNI";
export const LOAD_SUBSCRIBED_UNIS = "LOAD_SUBSCRIBED_UNIS";
export const ADD_SUBSCRIPTION_TO_UNI = "ADD_SUBSCRIPTION_TO_UNI";
export const REMOVE_SUBSCRIPTION_FROM_UNI = "REMOVE_SUBSCRIPTION_TO_UNI";
export const USER_AUTHORIZATION_SUCCESS = "USER_AUTHORIZATION_SUCCESS";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const VIEW_UNI_PAGE = "VIEW_UNI_PAGE";
export const VIEW_PROGRAM_PAGE = "VIEW_PROGRAM_PAGE";
export const LOAD_TEMP_INFO = "LOAD_TEMP_INFO";
export const LOAD_DASHBOARD_STATISTICS = "LOAD_DASHBOARD_STATISTICS";
export const LOAD_ADMIN_NEWS = "LOAD_ADMIN_NEWS";
export const GET_ADMIN_NEWS_BY_ID = "GET_ADMIN_NEWS_BY_ID";
export const ADD_EVENT = "ADD_EVENT";
export const CHANGE_EVENT_SETTINGS = "CHANGE_EVENT_SETTINGS";
export const CHANGE_NEWS_SETTINGS = "CHANGE_NEWS_SETTINGS";
export const LOAD_EVENTS = "LOAD_EVENTS";
export const LOAD_EVENTS_FOR_UNI = "LOAD_EVENTS_FOR_UNI";
export const LOAD_NEWS_FOR_UNI = "LOAD_NEWS_FOR_UNI";
export const REMOVE_EVENT_BY_ID = "REMOVE_EVENT_BY_ID";
export const LOAD_USER_RESERVED_EVENTS = "LOAD_USER_RESERVED_EVENTS";
export const RESERVE_EVENT = "RESERVE_EVENT";
export const UNRESERVE_EVENT = "UNRESERVE_EVENT";
export const LOAD_USER_LATOR_INFO = "LOAD_USER_LATOR_INFO";
export const LOAD_LATOR = "LOAD_LATOR";
export const LATOR_ADD_SUBJECT = "LATOR_ADD_SUBJECT";
export const LATOR_ADD_SUBJECTS = "LATOR_ADD_SUBJECTS";
export const LATOR_REMOVE_SUBJECT = "LATOR_REMOVE_SUBJECT";
export const LATOR_ADD_PROGRAM = "LATOR_ADD_PROGRAM";
export const LATOR_ADD_PROGRAMS = "LATOR_ADD_PROGRAMS";
export const LATOR_REMOVE_PROGRAM = "LATOR_REMOVE_PROGRAM";
export const LATOR_ADD_FIXED_SCORE = "LATOR_ADD_FIXED_SCORE";
export const LATOR_REMOVE_FIXED_SCORE = "LATOR_REMOVE_FIXED_SCORE";
export const ADD_NEWS = "ADD_NEWS";
export const LOAD_NEWS = "LOAD_NEWS";
export const LOAD_UNI_SHORT_STATS = "LOAD_UNI_SHORT_STATS";
export const DISPLAY_UNI_SUBSCRIPTION_POPUP = "DISPLAY_UNI_SUBSCRIPTION_POPUP";
export const LOAD_USER_REJECTIONS = "LOAD_USER_REJECTIONS";
export const REJECT_MINI_POPUP = "REJECT_MINI_POPUP";
export const UPDATE_USER_MOBILE = "UPDATE_USER_MOBILE";
export const ADD_LATORI_CONTEST_SCORE = "ADD_LATORI_CONTEST_SCORE";
export const ADD_LATORI_USER_SCORE = "ADD_LATORI_USER_SCORE";
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const ADD_NEW_UNI = "ADD_NEW_UNI";
