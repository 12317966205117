import {
	addFavoriteProgram,
	IAddFavoriteProgram,
	IRemoveFavoriteProgram,
	removeFavoriteProgram,
} from "@app/actions/favorite-programs";
import { IProgramContent, IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { erovnulSubjectNames, mandatoryTourNames } from "@app/commonJavascript";
import requireLoginForAction from "@app/components/requireLoginForAction";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import { FormattedMessage, getFormattedMessage } from "@app/utils/locale";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import { sortContentByPosition } from "../MainPageComponents/search/searchAlgorithms";
import GalleryMedias from "../Universities/gallery-media";
import "./styles/programPage.min.css";

interface IOwnProps {
	program: IRProgram;
	uni: IRUniversity;
	contents?: IProgramContent[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	addFavoriteProgram: IAddFavoriteProgram;
	removeFavoriteProgram: IRemoveFavoriteProgram;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const sortContents = memoizeOne((contents?: IProgramContent[]) => {
	if (!contents) return contents;
	return sortContentByPosition(contents);
});

const ProgramDescription: React.SFC<IProps> = props => {
	let isFavorite = false;
	if (props.favorites) isFavorite = !!props.favorites[props.program.id];

	const isGeo = getLocale() === "ka";

	const contents = sortContents(props.contents);
	return (
		<div>
			<div className="programInfo">
				{!!contents &&
					!(contents.length === 1 && contents[0].text === "") && (
						<div className="programDescriptionWrapper">
							{contents.map((content, i) => (
								<div className="description" key={i}>
									<p className="descriptionHeader">
										{content.title ||
											getFormattedMessage(
												"programPage.descriptionTitle"
											)}
									</p>
									<div
										dangerouslySetInnerHTML={{
											__html: content.text || "",
										}}
									/>
									<GalleryMedias
										galleryMedias={content.galleryMedias}
									/>
								</div>
							))}
						</div>
					)}
				<div className="financedImagePlusaddToFav">
					{isGeo && (
						<div className="addToFavDiv">
							{isFavorite ? (
								<div
									onClick={e => {
										e.stopPropagation();
										requireLoginForAction(() => {
											props
												.removeFavoriteProgram({
													program_id:
														props.program.id,
													uni_id:
														props.program.uni_id,
												})
												.then();
										});
									}}
								>
									<p className="addToFavParagraph">
										რჩეულებში დამატებულია
									</p>
								</div>
							) : (
								<div
									onClick={e => {
										e.stopPropagation();
										triggerEvent(
											{
												category: "Button",
												action:
													"Add Program to favorites",
												label: "from program page",
											},
											{
												program_id: props.program.id,
												uni_id: props.program.uni_id,
											}
										);
										requireLoginForAction(() => {
											props
												.addFavoriteProgram({
													program_id:
														props.program.id,
													uni_id:
														props.program.uni_id,
												})
												.then();
										});
									}}
								>
									<p className="addToFavParagraph">
										<FormattedMessage id="programPage.addToFavorites" />{" "}
										<span
											style={{ display: "inline-flex" }}
										>
											<AddCircleIcon
												style={{ fontSize: 30 }}
											/>
										</span>
									</p>
								</div>
							)}
						</div>
					)}
					{!props.program.fullyFinancedPlaces ||
					!props.program.numsBySubjs ? null : props.program
							.fullyFinancedPlaces ===
					  props.program.numsBySubjs.reduce(
							(acc, cur) => acc + cur
					  ) ? (
						<div className="financedImage" />
					) : null}
				</div>
			</div>
			{!!props.program.grant_subjects && isGeo && (
				<div className="grantInfo">
					გრანტის ასაღებად საჭიროა შემდეგი საგნებიდან
					<strong> ერთ-ერთის </strong>
					ჩაბარება:{" "}
					<strong>
						{props.program.grant_subjects
							.map(subjId => erovnulSubjectNames[subjId])
							.join(", ")}
						.
					</strong>
				</div>
			)}
			{!!props.program.mandatory_tours && (
				<div className="grantInfo">
					ფაკულტეტზე მოსახვედრად აუცილებელია შემდეგი ტურ(ებ)ის გავლა:{" "}
					<strong>
						{props.program.mandatory_tours
							.map(tourId => mandatoryTourNames[tourId])
							.join(", ")}
						.
					</strong>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	favorites: state.favoritePrograms.favorites,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({
		addFavoriteProgram,
		removeFavoriteProgram,
	} as any) as IDispatchProps
)(ProgramDescription);
