import { Requests } from "@app/api";
import {
	IAPOSTReservation,
	IRGETReservation,
	IRPOSTReservation,
	RGETReservationsCountSchema,
	RGETReservationsSchema,
	RPOSTReservationSchema,
} from "./validators";

const uniExpo = {
	getUserReservations: (): Promise<IRGETReservation[]> =>
		Requests.send("GET", "/api/uni-expo/reservations/", {}, null, {
			responseSchema: RGETReservationsSchema,
		}),

	getUserReservationsCount: (): Promise<{ count: number }> =>
		Requests.send("GET", "/api/uni-expo/reservations/count-all", {}, null, {
			responseSchema: RGETReservationsCountSchema,
		}),

	saveUserReservation: (
		data: IAPOSTReservation
	): Promise<IRPOSTReservation> =>
		Requests.send(
			"POST",
			"/api/uni-expo/reservations/:live_id",
			data,
			null,
			{ responseSchema: RPOSTReservationSchema }
		),

	deleteUserReservation: (data: IAPOSTReservation): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/uni-expo/reservations/:live_id",
			data,
			null
		),
	exportReservations: (): Promise<any> =>
		Requests.send(
			"GET",
			"/api/uni-expo/reservations/export",
			{},
			{
				responseType: "blob",
			}
		),
};

export default uniExpo;
