import * as React from "react";
import LockIcon from "@material-ui/icons/Lock";
import Slider from "rc-slider";
import triggerEvent from "@app/utils/events";
import UnlockIcon from "@material-ui/icons/LockOpen";
import { IErovnuliSubj } from "@app/commonJavascript";

export interface ISubjectScoreSliderProps {
	subject: IErovnuliSubj;
	score?: number;
	fixed: boolean;
	onScoreChange: (subjectId: number, score: number) => void;
	onFixChange: (subjectId: number, fixed: boolean) => void;
}

interface ISubjectScoreSliderState {
	score: number;
	fixed: boolean;
	readFromState: boolean;
	hover: boolean;
}

export default class SubjectScoreSlider extends React.PureComponent<
	ISubjectScoreSliderProps,
	ISubjectScoreSliderState
> {
	state: ISubjectScoreSliderState = {
		score:
			this.props.score ||
			Math.floor(
				(this.props.subject.maxScore! + this.props.subject.minScore!) /
					2
			) ||
			0,
		fixed: this.props.fixed,
		readFromState: false,
		hover: false,
	};
	constructor(props: ISubjectScoreSliderProps) {
		super(props);
		if (this.props.score !== this.state.score) {
			this.props.onScoreChange(this.props.subject.id, this.state.score);
		}
	}
	onQuickChange = (score: number) => {
		this.setState({
			score,
			fixed: true,
			readFromState: true,
		});
	};
	onFinalChange = (score: number) => {
		this.setState({
			score,
			fixed: true,
			readFromState: false,
		});
		if (this.props.score !== score) {
			this.props.onScoreChange(this.props.subject.id, score);

			triggerEvent(
				{
					category: "Slider",
					action: "Lator: score change",
					label: "",
				},
				{ score, subjId: this.props.subject.id }
			);
		}
		this.props.onFixChange(this.props.subject.id, true);
	};
	onFixChange = () => {
		this.setState({
			fixed: !this.state.fixed,
		});
		this.props.onFixChange(this.props.subject.id, !this.state.fixed);
		triggerEvent(
			{
				category: "Button",
				action: "Lator: lock change",
				label: "",
			},
			{ subjId: this.props.subject.id }
		);
	};

	render() {
		const subj = this.props.subject;
		const score = this.state.readFromState
			? this.state.score
			: this.props.score;
		const fixed = this.state.readFromState
			? this.state.fixed
			: this.props.fixed;
		const text = !fixed
			? "დააჭირეთ ბოქლომს, თუ გსურთ, რომ ქულა უცვლელი დარჩეს სხვა პირობების/ქულების ცვლილების დროს."
			: "მოხსენით ბოქლომი, თუ გსურთ, რომ ქულა იცვლებოდეს სხვა პირობების მიხედვით.";
		return (
			<div className="subjectContainer">
				<div className="meta">
					<div className="name">{subj.name}</div>
					<div className="sliderContainer">
						<Slider
							min={subj.minScore}
							max={subj.maxScore}
							value={score}
							onChange={this.onQuickChange}
							onAfterChange={this.onFinalChange}
						/>
					</div>
					<div className="extremes">
						<span>{subj.minScore}</span>
						<span>{subj.maxScore}</span>
					</div>
				</div>
				<div className="scoreContainer">
					<span>{score}</span>
				</div>
				<div
					className="fixContainer"
					onMouseEnter={() => this.setState({ hover: true })}
					onMouseLeave={() => this.setState({ hover: false })}
				>
					<div
						className={fixed ? "locked" : undefined}
						onClick={this.onFixChange}
					>
						{fixed ? <LockIcon /> : <UnlockIcon />}
					</div>
					{this.state.hover && <span>{text}</span>}
				</div>
			</div>
		);
	}
}
