import * as React from "react";
import CheckIcon from "@material-ui/icons/Check";
import "./styles/fancyCheckbox.min.css";

interface ICkeckboxProps {
	checked: boolean;
	id: string;
	onChange: () => void;
}

const FancyCheckbox: React.SFC<ICkeckboxProps> = props => (
	<div className="FancyCheckbox">
		<input
			type="checkbox"
			id={props.id}
			checked={props.checked}
			onChange={props.onChange}
		/>
		<label htmlFor={props.id}>
			<span className={props.checked ? "checked" : "unchecked"}>
				{props.checked && <CheckIcon />}
			</span>
			<div>{props.children}</div>
		</label>
	</div>
);

export default FancyCheckbox;
