import { logout } from "@app/actions/user";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import { FormattedMessage } from "@app/utils/locale";
import BurgerIcon from "@material-ui/icons/Dehaze";
import classnames from "classnames";
import { History } from "history";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LocaleSwitcher, LocaleSwitcherMobile } from "./locale-switcher";
import requireLoginForAction from "./requireLoginForAction";
import "./styles/header.min.css";
import LogoutIcon from "./styles/img/Logout";
import logoEn from "./styles/img/Unicatalog Logo en.svg";
import logoSvg from "./styles/img/Unicatalog Logo.svg";
import ProfileSVG from "./styles/img/profile-icon.svg";
import ProfilePopup from "./Profile";

interface IOwnProps {
	solid?: boolean;
	history: History;
	selectedBlock?: string;
	selectedBlockIndex?: number;
	transparent?: boolean;
	customNav?: any;
	showOnlyLogo?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	logout: typeof logout;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	navbarShorten: boolean;
	shortNavBarOpen: boolean;
	fixed_bar: boolean;
	shortNavBarRemoving: boolean;
	murtskulatoriPopupOpen: boolean;
	displayProfilePopup: boolean;
}
// tslint:disable-next-line
const header_buttons = "header_buttons ";

const LogoutButton = props => (
	<span className="header_buttons header_logout" onClick={props.logout}>
		<span>
			<FormattedMessage id="logout" />
		</span>
		<LogoutIcon width={27} />
	</span>
);

const LoginButton = props => (
	<span
		className="header_buttons header_logout"
		onClick={() => {
			triggerEvent({
				category: "Button",
				action: "Login click",
				label: "from header",
			});
			requireLoginForAction(() => {});
		}}
	>
		<span>
			<FormattedMessage id="sign-in" />
		</span>
	</span>
);

class Header extends React.Component<IProps, IState> {
	state = {
		fixed_bar: false,
		navbarShorten: false,
		shortNavBarOpen: false,
		shortNavBarRemoving: false,
		murtskulatoriPopupOpen: false,
		displayProfilePopup: false,
	};

	navBarRef: React.RefObject<HTMLDivElement>;
	_isMounted: boolean;
	onScrollFunction: Function;
	removeLoader: Function;

	userIsLogged = memoizeOne(isLogged => {
		if (isLogged) {
			setTimeout(() => {
				this.setState({ shortNavBarOpen: false });
			}, 0);
		}
		return isLogged;
	});

	constructor(props: IProps) {
		super(props);
		this.navBarRef = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		const navbar = this.navBarRef.current;
		this.checkForResize();
		window.addEventListener("resize", this.checkForResize);

		if (typeof this.props.solid !== "undefined") {
			this.setState({ fixed_bar: false });
			const offsetnav = navbar!.offsetTop;
			const stickyNavBar = () => {
				if (window.pageYOffset >= offsetnav) {
					this.setState({ fixed_bar: true });
				} else {
					this.setState({ fixed_bar: false });
				}
			};
			stickyNavBar();
			let lastTimeScroll = Date.now();
			this.onScrollFunction = () => {
				if (Date.now() - lastTimeScroll >= 40) {
					stickyNavBar();
					lastTimeScroll = Date.now();
				}
			};
			window.addEventListener(
				"scroll",
				this.onScrollFunction as (e: Event) => void
			);
		} else {
			this.setState({ fixed_bar: true });
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener(
			"scroll",
			this.onScrollFunction as (e: Event) => void
		);
		window.removeEventListener("resize", this.checkForResize);
	}

	logoutFromThisPage = () => {
		this.props.logout();
		window.scroll(0, 0);
	};

	goto = (path: string) => () => {
		this.props.history.push(path);
		if (path === "/unis/") {
			triggerEvent({
				category: "Button",
				action: "Goto All Unis page",
				label: "from header",
			});
		} else if (path === "/latori/") {
			triggerEvent({
				category: "Button",
				action: "Goto Latori page",
				label: "from header",
			});
		}
	};

	checkForResize = () => {
		const wid = window.innerWidth;
		if (wid < 1200 && !this.state.navbarShorten) {
			this.setState({ navbarShorten: true, shortNavBarOpen: false });
		} else if (wid >= 1200 && this.state.navbarShorten) {
			this.setState({ navbarShorten: false, shortNavBarOpen: false });
		}
	};

	navBarOpenToggle = () => {
		const whatToBecome = !this.state.shortNavBarOpen;
		if (!whatToBecome) {
			this.setState({ shortNavBarRemoving: true });
			setTimeout(() => {
				this.setState({
					shortNavBarOpen: whatToBecome,
					shortNavBarRemoving: false,
				});
			}, 300);
		} else {
			this.setState({
				shortNavBarOpen: whatToBecome,
				shortNavBarRemoving: false,
			});
		}
	};

	returnToHome = () => {
		this.props.history.push("/");
	};

	logout = () => {
		this.props.logout();
		this.setState({ shortNavBarOpen: false });

		triggerEvent(
			{
				category: "Button",
				action: "Logout",
				label: "from header",
			},
			{ pathname: this.props.history.location.pathname }
		);
	};

	onTogglePopup = () => {
		this.setState({ displayProfilePopup: !this.state.displayProfilePopup });
	};

	render() {
		const locale = getLocale();
		const logo = locale === "ka" ? logoSvg : logoEn;
		const navBarClasses = classnames({
			sticky: !!this.state.fixed_bar,
			"navbar-shorten": this.state.navbarShorten,
		});
		if (this.props.showOnlyLogo) {
			return (
				<React.Fragment>
					<div
						id="navigation_bar"
						ref={this.navBarRef}
						className={navBarClasses}
					>
						<div className="main main2">
							<div
								id="Logo_div_header"
								onClick={this.returnToHome}
							>
								<img id="logo_header" alt="logo" src={logo} />
							</div>
						</div>
					</div>
					<div
						id="navigation_bar_fake"
						className={this.state.fixed_bar ? "visible" : undefined}
					/>
				</React.Fragment>
			);
		}
		const isLoggedIn = !!this.props.user.loggedIn;
		this.userIsLogged(isLoggedIn);
		let isAdmin = false;
		if (
			isLoggedIn &&
			this.props.user.userData &&
			this.props.user.userData.permissions
		) {
			isAdmin = true;
		}
		const { customNav } = this.props;
		const hideLatori = locale !== "ka";

		return (
			<React.Fragment>
				<div
					id="navigation_bar"
					ref={this.navBarRef}
					className={navBarClasses}
				>
					<div className="main main2">
						<div id="Logo_div_header" onClick={this.returnToHome}>
							<img id="logo_header" alt="logo" src={logo} />
						</div>
						{this.state.navbarShorten && (
							<div
								id="navBarBurger"
								onClick={this.navBarOpenToggle}
							>
								<div className="VM">
									<BurgerIcon />
								</div>
							</div>
						)}
						{/* {this.state.murtskulatoriPopupOpen && (
							<LatoriPopup
								onClose={() =>
									this.setState({
										murtskulatoriPopupOpen: false,
									})
								}
							></LatoriPopup>
						)} */}
						{/* {this.props.user.userData &&
							this.state.navbarShorten &&
							!this.state.shortNavBarOpen && (
								<Provider store={chatStore}>
									<div
										className="VM"
										style={{
											display: "inline-flex",
											width: "50px",
											height: "80px",
											cursor: "pointer",
											float: "right",
										}}
									>
										<ChatMessages
											unis={this.props.unis}
											userId={
												"u" +
												this.props.user.userData
													.murtskuId
											}
											userName={
												this.props.user.userData
													.username
													? this.props.user.userData
															.username
													: `${this.props.user.userData.firstname} ${this.props.user.userData.lastname}`
											}
										/>
									</div>
								</Provider>
							)} */}
						{this.state.navbarShorten &&
							this.state.shortNavBarOpen && (
								<div
									className={
										"navBarBlackCurtain " +
										(this.state.shortNavBarRemoving
											? "removing_soon "
											: "")
									}
									onClick={this.navBarOpenToggle}
								/>
							)}
						{(!this.state.navbarShorten ||
							this.state.shortNavBarOpen) && (
							<div
								className={
									"links_headers " +
									(this.state.shortNavBarRemoving
										? "removing_soon "
										: "")
								}
							>
								<div className="VM">
									{customNav === undefined ? (
										<div style={{ width: "100%" }}>
											<div className="left">
												{!hideLatori && (
													<span
														className={
															header_buttons +
															(this.props
																.selectedBlock ===
															"unis"
																? "selected"
																: "")
														}
														onClick={this.goto(
															"/unis/"
														)}
													>
														<FormattedMessage id="all-universtities" />
													</span>
												)}
												{!!isLoggedIn && !hideLatori && (
													<span
														className={
															header_buttons +
															(this.props
																.selectedBlock ===
															"news"
																? "selected"
																: "")
														}
														onClick={this.goto("/")}
													>
														<FormattedMessage id="news" />
													</span>
												)}
											</div>
											<div className="right">
												{!hideLatori && (
													<span
														className={
															header_buttons +
															(this.props
																.selectedBlock ===
															"latori"
																? "selected"
																: "selected")
														}
														onClick={this.goto(
															"/latori/"
														)}
													>
														მურწყულატორი
													</span>
												)}
												{!!isLoggedIn && (
													<img
														alt=""
														src={ProfileSVG}
														style={{
															verticalAlign:
																"middle",
															cursor: "pointer",
															marginLeft: 10,
														}}
														onClick={
															this.onTogglePopup
														}
													/>
												)}
												{isAdmin && (
													<span
														className={
															header_buttons +
															(this.props
																.selectedBlock ===
															"admin"
																? "selected"
																: "")
														}
														onClick={this.goto(
															"/admin/"
														)}
													>
														<FormattedMessage id="Admin" />
													</span>
												)}
												{isLoggedIn && (
													<LogoutButton
														logout={this.logout}
													/>
												)}
												{!isLoggedIn && <LoginButton />}
												<LocaleSwitcher />
												{this.state
													.displayProfilePopup && (
													<ProfilePopup
														onClose={
															this.onTogglePopup
														}
													/>
												)}
											</div>
										</div>
									) : (
										customNav
									)}
								</div>
							</div>
						)}
						<div style={{ clear: "both" }} />
					</div>
				</div>
				<div
					id="navigation_bar_fake"
					className={this.state.fixed_bar ? "visible" : undefined}
				/>
				<LocaleSwitcherMobile />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	user: state.user,
	unis: state.unis.info,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{ logout }
)((withRouter(Header as any) as any) as typeof Header);
