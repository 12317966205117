import { IAnyObj } from "@app/utils/generics";
import Joi, { SchemaToType } from "@app/utils/joi";
import { RGETFavoritesSchema } from "./programs/validators";
import { RGETSubscribes } from "./unis/validators";

///

export const PrivilegeAllUnisSchema = Joi.object().keys({
	allUnis: Joi.boolean()
		.valid(true)
		.required(),
	allProgramsPermissions: Joi.number()
		.integer()
		.required(),
});

export type IPrivilegeAllUnis = SchemaToType<typeof PrivilegeAllUnisSchema>;

///

export const PrivilegeOneUniAllProgramsSchema = Joi.object().keys({
	uniId: Joi.number()
		.integer()
		.required(),
	allPrograms: Joi.boolean()
		.valid(true)
		.required(),
	allProgramsPermissions: Joi.number()
		.integer()
		.required(),
});

export type IPrivilegeOneUniAllPrograms = SchemaToType<
	typeof PrivilegeOneUniAllProgramsSchema
>;

///

export const AccessibleProgramSchema = Joi.array().items(
	Joi.object()
		.keys({
			id: Joi.number()
				.integer()
				.required(),
			permissions: Joi.number()
				.integer()
				.required(),
		})
		.required()
);

export type IAccessiblePrograms = SchemaToType<typeof AccessibleProgramSchema>;

///

export const PrivilegeOneUniSeveralProgramsSchema = Joi.object().keys({
	uniId: Joi.number()
		.integer()
		.required(),
	programs: AccessibleProgramSchema.required(),
});

export type IPrivilegeOneUniSeveralPrograms = SchemaToType<
	typeof PrivilegeOneUniSeveralProgramsSchema
>;

///

export const RUserPrivilege = Joi.alternatives([
	PrivilegeAllUnisSchema,
	PrivilegeOneUniAllProgramsSchema,
	PrivilegeOneUniSeveralProgramsSchema,
]);
export type IRUserPrivilege = SchemaToType<typeof RUserPrivilege>;

///

export const RUserPermissionsSchema = Joi.object().keys({
	user_id: Joi.number()
		.integer()
		.required(),
	canAddAdmins: Joi.boolean().required(),
	uniPermissions: Joi.number()
		.integer()
		.required(),
	privilege: RUserPrivilege.required(),
});

export type IRUserPermissions = SchemaToType<typeof RUserPermissionsSchema>;

///

export const RUserSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.required(),
	mobile: Joi.string()
		.allow(null)
		.required(),
	mail: Joi.string()
		.allow(null)
		.required(),
	username: Joi.string(),
	firstname: Joi.string().allow(null),
	lastname: Joi.string().allow(null),
	country: Joi.strict().required(),
	permissions: RUserPermissionsSchema.allow(null).required(),
});

export interface IRUser {
	id: number;
	murtskuId: number | null;
	mobile: string | null;
	mail: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	country: string;
	permissions: IRUserPermissions | null;
}

///

export enum PopupRejectionEnum {
	dusringSession = 0,
	untilLogout = 1,
	forever = 2,
}
export const PopupRejections: PopupRejectionEnum[] = [
	PopupRejectionEnum.forever,
];

export enum MiniPopupTypes {
	UniSubscription = 0,
}
export const popupTypes: MiniPopupTypes[] = [MiniPopupTypes.UniSubscription];

export const RMiniPopupRejectionSchema = Joi.object().keys({
	id: Joi.number(),
	uni_id: Joi.number().allow(null),
	type: Joi.number()
		.valid(popupTypes)
		.required(),
	rejection: Joi.number()
		.valid(PopupRejections)
		.required(),
});

export const RMiniPopupRejectionFields: (keyof IRMiniPopupRejection)[] = [
	"id",
	"type",
	"uni_id",
	"rejection",
];

export type IRMiniPopupRejection = SchemaToType<
	typeof RMiniPopupRejectionSchema
>;

///

export const UserAdditionalInfoSchema = Joi.object().keys({
	favoritePrograms: RGETFavoritesSchema.required(),
	subscribedUnis: RGETSubscribes.required(),
	rejections: Joi.object()
		.keys({
			miniPopups: Joi.array()
				.items(RMiniPopupRejectionSchema)
				.required(),
		})
		.required(),
});
export type IUserAdditionalInfo = SchemaToType<typeof UserAdditionalInfoSchema>;

///

export const UserEssentialsSchema = Joi.object().keys({
	userData: RUserSchema.required(),
	additionalInfo: UserAdditionalInfoSchema.required(),
});

export interface IUserEssentials {
	userData: IRUser;
	additionalInfo: IUserAdditionalInfo;
}

///

export const AGETdminInfoSchema = Joi.object()
	.keys({
		user: Joi.object()
			.keys({
				id: Joi.number()
					.integer()
					.required(),
				murtsku_id: Joi.number()
					.integer()
					.required(),
				mob: Joi.string()
					.allow(null)
					.required(),
				mail: Joi.string()
					.allow(null)
					.required(),
				username: Joi.string().required(),
				firstname: Joi.string().allow(null),
				lastname: Joi.string().allow(null),
			})
			.required(),
		privilege: RUserPrivilege.required(),
		canAccess: Joi.boolean().required(),
	})
	.required();

export type IAGETAdminInfo = SchemaToType<typeof AGETdminInfoSchema>;

///

export const UserActionSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	user_id: Joi.number().required(),
	actions: Joi.object().required(),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});

export interface IUserAction {
	id: number;
	user_id: number;
	actions: IAnyObj;
	created_at: Date;
	updated_at: Date;
}

///

export const ConsultationRequestSchema = Joi.object().keys({
	id: Joi.number()
		.integer()
		.required(),
	user_id: Joi.number()
		.integer()
		.required(),
	uni_id: Joi.number()
		.integer()
		.required(),
	program_id: Joi.number()
		.integer()
		.allow(null)
		.required(),
	created_at: Joi.date().required(),
});

export interface IConsultationRequest {
	id: number;
	user_id: number;
	uni_id: number;
	program_id: number | null;
	created_at: Date;
}
