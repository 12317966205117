import * as React from "react";
import LogoUpload from "./LogoUpload";
import { ChromePicker } from "react-color";
import { IRUniversity } from "@app/api/unis/helper-schemas";

interface IProps {
	uni: IRUniversity;
	onLogoBGColorChange?: (color: string) => void;
}

interface IState {
	color: string;
}

class UniLogoPicker extends React.Component<IProps, IState> {
	state = {
		color: "#fff",
	} as IState;

	constructor(props: IProps) {
		super(props);
		this.state.color = props.uni.logoBGColor || "#fff";
	}

	handleChangeComplete = (color: any) => {
		this.setState({
			color: color.hex,
		});
		if (this.props.onLogoBGColorChange) {
			this.props.onLogoBGColorChange(color.hex);
		}
	};

	render() {
		return (
			<div className="uniLogoUpload">
				<LogoUpload
					url={this.props.uni.logoUrl}
					bgColor={this.state.color}
					uni_id={this.props.uni.id}
				/>
				<div className="colorSketchPicker">
					<ChromePicker
						color={this.state.color}
						onChangeComplete={this.handleChangeComplete}
					/>
				</div>
			</div>
		);
	}
}
export default UniLogoPicker;
