import {
	ADD_NEW_CHATROOM,
	ADD_RECEIVED_MESSAGE,
	ADD_SENT_MESSAGE,
	ADD_TEMP_CHATROOM,
	LOAD_ADMIN_CHATROOMS_AND_MESSAGES,
	LOAD_CHATROOMS_AND_MESSAGES,
	MARK_MESSAGES_AS_SEEN,
	REPLACE_TEMP_CHATROOM,
} from "../action-types";
import { IChatroom, IMessage, IStateChatroom } from "../reducers/chatrooms";
import { INewMessage } from "../interfaces/new-message";
import { markSeen, sendMessage } from "../socket-functions";
import { v4 as uuid } from "uuid";

export interface ISaveMessagesInStorageAction {
	type: typeof LOAD_CHATROOMS_AND_MESSAGES;
	info: IStateChatroom;
	lastUpdate: Date;
}

export const saveMessagesInStorage = (
	allChatroomInfo,
	lastUpdate = new Date()
): ISaveMessagesInStorageAction => {
	return {
		type: LOAD_CHATROOMS_AND_MESSAGES,
		info: allChatroomInfo,
		lastUpdate,
	};
};

export interface ISaveAdminMessagesInStorage {
	type: typeof LOAD_ADMIN_CHATROOMS_AND_MESSAGES;
	info: IStateChatroom;
}

export const saveAdminMessagesInStorage = (
	adminChatroomInfo: IStateChatroom
): ISaveAdminMessagesInStorage => {
	return {
		type: LOAD_ADMIN_CHATROOMS_AND_MESSAGES,
		info: adminChatroomInfo,
	};
};

export interface IAddSentMessage {
	type: typeof ADD_SENT_MESSAGE;
	chatroomId: string;
	message: IMessage;
}

const getSenderType = (id: string): "user" | "uni" | "course" => {
	if (id.indexOf("uni") >= 0) return "uni";
	if (id[0] === "c") return "course";
	return "user";
};

export const addSentMessageInStorage = (
	userId: string,
	chatroomId: string,
	message: string
): IAddSentMessage => {
	const messageObject: INewMessage = {
		specialId: getSenderType(userId) !== "user" ? userId : undefined,
		chatroomId,
		message,
		senderType: getSenderType(userId),
	};
	sendMessage(messageObject);
	const newMessage = {
		_id: uuid(),
		chatRoomId: chatroomId,
		senderId: userId,
		message,
		seenBy: [userId],
		createdAt: new Date(),
	};
	return {
		type: ADD_SENT_MESSAGE,
		chatroomId,
		message: newMessage,
	};
};

export interface IAddReceivedMessage {
	type: typeof ADD_RECEIVED_MESSAGE;
	chatroomId: string;
	message: IMessage;
}

export const addReceivedMessageInStorage = (
	chatroomId: string,
	message: IMessage
): IAddReceivedMessage => {
	return {
		type: ADD_RECEIVED_MESSAGE,
		chatroomId,
		message,
	};
};

export interface IAddNewChatroom {
	type: typeof ADD_NEW_CHATROOM;
	chatroom: IChatroom;
}

export const addNewChatroomInStorage = (
	chatroom: IChatroom
): IAddNewChatroom => {
	return {
		type: ADD_NEW_CHATROOM,
		chatroom,
	};
};

export interface IMarkMessagesAsSeen {
	type: typeof MARK_MESSAGES_AS_SEEN;
	messageIds: string[];
	userId: string;
}

export const markMessageAsSeenInStorage = (
	messageIds: string[],
	userId: string
): IMarkMessagesAsSeen => {
	markSeen(userId, messageIds);
	return {
		type: MARK_MESSAGES_AS_SEEN,
		messageIds,
		userId,
	};
};

export interface IAddTempChatroom {
	type: typeof ADD_TEMP_CHATROOM;
	tempId: string;
	chatroom: IChatroom;
}

export const addTempChatroomInStorage = (
	tempId: string,
	chatroom: IChatroom
): IAddTempChatroom => {
	return {
		type: ADD_TEMP_CHATROOM,
		tempId,
		chatroom,
	};
};

export interface IReplaceTempChatroom {
	type: typeof REPLACE_TEMP_CHATROOM;
	tempId: string;
	newId: string;
}

export const replaceTempChatroomInStorage = (
	tempId: string,
	newId: string
): IReplaceTempChatroom => {
	return {
		type: REPLACE_TEMP_CHATROOM,
		tempId,
		newId,
	};
};

export type IChatroomActions =
	| ISaveMessagesInStorageAction
	| IAddSentMessage
	| IAddReceivedMessage
	| IAddNewChatroom
	| IMarkMessagesAsSeen
	| ISaveAdminMessagesInStorage
	| IAddTempChatroom
	| IReplaceTempChatroom;
