import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as React from "react";
import "./styles/fancyMultipleSelect.min.css";

interface IProps {
	title?: string;
	items: Array<{ id: string | number; name: string; [key: string]: any }>;
	defaultValue: null | number | string;
	onChange?: (itemId: string | number) => void;
}

interface IState {
	selected: null | number | string;
}

class FancySelect extends React.Component<IProps, IState> {
	state = {
		selected: null,
	} as IState;

	constructor(props: IProps) {
		super(props);
		this.state.selected = props.defaultValue || null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.defaultValue !== this.props.defaultValue) {
			this.setState({ selected: this.props.defaultValue });
		}
	}

	changeSelect = e => {
		this.setState({
			selected: e.target.value,
		});
		if (this.props.onChange) this.props.onChange(e.target.value);
	};

	renderValues = (selected): string => {
		const selectedItem = this.props.items.find(
			item => item.id === selected
		);
		if (!selectedItem) return "";
		return selectedItem.name;
	};

	render() {
		return (
			<div className="fancyInputAndTitle">
				{this.props.title && <h2>{this.props.title}</h2>}
				<div className="fancyMultipleSelectCont">
					<Select
						className="fancyMultipleSelect"
						disableUnderline={true}
						value={this.state.selected || ""}
						onChange={this.changeSelect}
						renderValue={this.renderValues}
						classes={{
							icon: "icon",
						}}
					>
						{this.props.items.map(item => (
							<MenuItem key={item.id} value={item.id}>
								<ListItemText primary={item.name} />
							</MenuItem>
						))}
					</Select>
				</div>
			</div>
		);
	}
}

export default FancySelect;
