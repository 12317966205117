import api from "@app/api";
import {
	cities,
	erovnulSubjects,
	fields,
	getQueryStringParams,
	IErovnuliSubj,
	sectors,
} from "@app/commonJavascript";
import { getLocale } from "@app/hooks/intl";
import triggerEvent from "@app/utils/events";
import { getFormattedMessage } from "@app/utils/locale";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import { History } from "history";
import Slider, { createSliderWithTooltip, Range as rRange } from "rc-slider";
import "rc-slider/assets/index.css";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { CSSTransition } from "react-transition-group";
import { NewDesktopAd } from "../dev/ads";
import {
	FOnDetailedSearch,
	FOnInputSeach,
	IProgramsDeailedSearch,
	IUnisDeailedSearch,
} from "../MainPage";
import "./styles/searchContent.min.css";

const Range = createSliderWithTooltip(rRange);

interface IStatesBigSearchContent {
	showSearchBar: boolean;
	showDetailedSearch: boolean;
}

interface IBigSearchContentProps {
	history: History;
	onDetailedSearch: FOnDetailedSearch;
	onSeachInputChange: FOnInputSeach;
	onSearchBarToggle: (show: boolean, tab?: "unis" | "programs") => void;
}

class BigSearchContent extends React.PureComponent<
	IBigSearchContentProps,
	IStatesBigSearchContent
> {
	sHeight: number;
	_isMounted: boolean;
	unsubscribeHistory: Function;

	state = {
		showSearchBar: true,
		showDetailedSearch: true,
	};

	// tslint:disable-next-line:no-shadowed-variable
	toggleSearchBar = (show: boolean, tab?: "unis" | "programs") => {
		this.setState({ showSearchBar: !!show });
		this.props.onSearchBarToggle(show, tab);
		if (show && this.props.history.location.search)
			this.props.history.push(this.props.history.location.pathname);
	};

	toggleDetailedSearch = (show: boolean) => {
		this.setState({ showDetailedSearch: !!show });
	};

	onExiting = (element: HTMLElement) => {
		this.sHeight = element.offsetHeight;
		const elementTransition = element.style.transition;
		element.style.transition = "";
		const sectionHeight = element.scrollHeight;
		requestAnimationFrame(() => {
			element.style.height = sectionHeight + "px";
			element.style.transition = elementTransition;
			requestAnimationFrame(() => {
				element.style.height = 0 + "px";
				element.style.opacity = "0";
				element.style.marginTop = "0";
			});
		});
	};

	onEntering = (element: HTMLElement) => {
		element.style.height = this.sHeight + "px";
		element.style.opacity = "";
		element.style.marginTop = "";
	};

	onEntered = (element: HTMLElement) => {
		element.style.height = "";
	};

	componentDidMount() {
		this._isMounted = true;
		this.unsubscribeHistory = this.props.history.listen(location => {
			if (!this._isMounted) return;
			if (location.pathname === "/" && location.search === "") {
				this.toggleSearchBar(true);
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.unsubscribeHistory();
	}

	render() {
		const locale = getLocale();
		const hideAdvancedSearch = locale !== "ka";
		return (
			<div className="mainContainer">
				<div className="main main2">
					<CSSTransition
						in={this.state.showSearchBar}
						classNames="big-search-bar"
						timeout={400}
					>
						<SearchBar
							key="SearchBar"
							history={this.props.history}
							showDetailedSearch={this.state.showDetailedSearch}
							toggleDetailedSearch={this.toggleDetailedSearch}
							onSeachInputChange={this.props.onSeachInputChange}
						/>
					</CSSTransition>
					{!hideAdvancedSearch && (
						<CSSTransition
							in={this.state.showDetailedSearch}
							classNames="detailed-search-bar"
							timeout={400}
							onExiting={this.onExiting}
							onEntering={this.onEntering}
							onEntered={this.onEntered}
						>
							<DetailedSearch
								key="DetailedSearch"
								history={this.props.history}
								showSearchBar={this.state.showSearchBar}
								toggleSearchBar={this.toggleSearchBar}
								onDetailedSearch={this.props.onDetailedSearch}
							/>
						</CSSTransition>
					)}
					<div style={{ textAlign: "center" }}>
						<NewDesktopAd
							className={"desktop600"}
							style={{
								width: 900,
								maxWidth: "100%",
								marginTop: 30,
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

interface ISearchBarProps {
	history: History;
	showDetailedSearch: boolean;
	toggleDetailedSearch: (show: boolean, tab?: "unis" | "programs") => any;
	onSeachInputChange: FOnInputSeach;
}

class SearchBar extends React.PureComponent<ISearchBarProps> {
	_isMounted: boolean;
	unsubscribeHistory: Function;

	state = {
		value: "",
		prevValue: "",
	};

	getSearchLink(value: string) {
		const { pathname } = this.props.history.location;
		if (value === "") return `${pathname}`;
		return `${pathname}?q=${encodeURIComponent(value)}`;
	}

	componentDidMount() {
		this._isMounted = true;
		this.unsubscribeHistory = this.props.history.listen(
			this.onUrlQueryChange
		);
		this.onUrlQueryChange();
	}

	onUrlQueryChange = () => {
		if (!this._isMounted) return;
		const { location } = this.props.history;
		if (location.pathname === "/" && location.search === "") {
			if (this.state.value !== "") {
				this.onSetValue("");
			}
			return;
		}
		const searchQueries = getQueryStringParams(location.search);
		if (
			typeof searchQueries.q === "string" &&
			searchQueries.q !== this.state.value
		) {
			this.setState(
				{
					prevValue: searchQueries.q,
					value: searchQueries.q,
				},
				() => {
					this.props.toggleDetailedSearch(false);
					this.onSearchClick();
				}
			);
		}
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.onSetValue(e.target.value);
	};
	resetValue = () => {
		this.onSetValue("");
	};

	onSetValue = (value: string) => {
		if (value && this.props.showDetailedSearch) {
			this.props.toggleDetailedSearch(false);
		} else if (!value && !this.props.showDetailedSearch) {
			this.props.toggleDetailedSearch(true);
		}
		const { prevValue } = this.state;
		const val = this.state.value;
		this.props.onSeachInputChange(value, false);
		this.setState(
			{
				value,
			},
			() => {
				if (prevValue === val) {
					this.props.history.push(this.getSearchLink(value));
				} else {
					this.props.history.replace(this.getSearchLink(value));
				}
			}
		);
	};

	getInputQueryValue = () => {
		return this.state.value;
	};

	onSearchClick = () => {
		this.props.onSeachInputChange(this.state.value, true);
		this.setState({
			prevValue: this.state.value,
		});
	};

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			this.props.onSeachInputChange(this.state.value, true);
			this.setState({
				prevValue: this.state.value,
			});
		}
	};

	render() {
		return (
			<div className="searchBarContainer">
				<div className="partOfSearchBar">
					<input
						className="searchBar"
						type="text"
						value={this.state.value}
						placeholder={getFormattedMessage(
							"detailedSearchPlaceholder"
						)}
						onChange={this.onValueChange}
						onKeyPress={this.onKeyPress}
					/>
					{this.state.value.length > 0 && (
						<CloseIcon
							className="searchBarClose"
							onClick={this.resetValue}
						/>
					)}
				</div>
				<div className="searchIcon">
					<div className="VM" onClick={this.onSearchClick}>
						<SearchIcon style={{ fontSize: "3em" }} />
					</div>
				</div>
			</div>
		);
	}
}

interface IPropsDetailedSearch {
	history: History;
	showSearchBar: boolean;
	toggleSearchBar: (toggle: boolean, tab?: "unis" | "programs") => void;
	onDetailedSearch: FOnDetailedSearch;
}
class DetailedSearch extends React.PureComponent<IPropsDetailedSearch> {
	tab: "programs" | "unis";
	unsubscribeHistory: Function;
	_isMounted: boolean;

	state = {
		activeTab: null,
	};

	componentDidMount() {
		this._isMounted = true;
		const searchQueries = getQueryStringParams(
			this.props.history.location.search
		);
		if (searchQueries.tab === "unis") {
			this.switchToUnis();
		} else if (searchQueries.tab === "programs") {
			this.switchToPrograms();
		}
		this.unsubscribeHistory = this.props.history.listen(location => {
			if (!this._isMounted) return;
			if (location.pathname === "/" && location.search === "") {
				this.switchToInitial();
			}
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	switchToUnis = () => {
		this.props.toggleSearchBar(false, "unis");
		this.setState({ activeTab: "unis" });
		this.tab = "unis";
		triggerEvent({
			category: "Button",
			action: "Unis Button",
			label: "click",
		});
	};

	switchToPrograms = () => {
		this.props.toggleSearchBar(false, "programs");
		this.setState({ activeTab: "programs" });
		this.tab = "programs";
		triggerEvent({
			category: "Button",
			action: "Programs Button",
			label: "click",
		});
	};

	switchToInitial = () => {
		this.props.toggleSearchBar(true);
		this.setState({ activeTab: null });
	};

	onExiting = element => {
		const sectionHeight = element.scrollHeight;
		const elementTransition = element.style.transition;
		element.style.transition = "";
		requestAnimationFrame(() => {
			element.style.height = sectionHeight + "px";
			element.style.transition = elementTransition;
			requestAnimationFrame(() => {
				element.style.height = 0 + "px";
				element.style.marginTop = 0;
				element.style.opacity = 0;
			});
		});
	};

	onEntering = element => {
		element.style.height = "";
		element.style.opacity = "";
		element.style.marginTop = "";
	};

	render() {
		const uniClass = classNames({
			detaliedSearchUni: true,
			detaliedButtonActive: this.state.activeTab === "unis",
		});
		const programsClass = classNames({
			detaliedSearchProgram: true,
			detaliedButtonActive: this.state.activeTab === "programs",
		});
		return (
			<React.Fragment>
				<div className="detailedSearch1Container">
					<div
						className="detailedSearch1Content"
						style={{
							marginTop: !this.props.showSearchBar
								? 0
								: undefined,
						}}
					>
						<div>
							<div className="VM">
								{this.state.activeTab === null ? (
									getFormattedMessage("detailedSearchTitle")
								) : (
									<ArrowBackIcon
										style={{
											fontSize: 45,
											cursor: "pointer",
										}}
										onClick={this.switchToInitial}
									/>
								)}
							</div>
						</div>
						<button
							className={uniClass}
							onClick={this.switchToUnis}
						>
							<FormattedMessage id="universities" />
						</button>
						<button
							className={programsClass}
							onClick={this.switchToPrograms}
						>
							<FormattedMessage id="programs" />
						</button>
					</div>
				</div>
				<CSSTransition
					in={this.state.activeTab !== null}
					classNames="detailed-search-filter"
					timeout={400}
					unmountOnExit={true}
					onExiting={this.onExiting}
					onEntering={this.onEntering}
				>
					<DetailedSearchContent
						history={this.props.history}
						tab={this.tab}
						onDetailedSearch={this.props.onDetailedSearch}
					/>
				</CSSTransition>
			</React.Fragment>
		);
	}
}

const electiveSubjects: IErovnuliSubj[] = erovnulSubjects.filter(
	s => !s.isCompulsory
);

const uniSliderPricePercentage = [0, 40, 90, 100];
const uniSliderPriceValues = [1000, 2500, 6500, 30000];
const uniSliderPriceSteps = [50, 100, 500];
function uniPercentToPrice(prcnt: number): number {
	let a = 1;
	let b = uniSliderPricePercentage.length - 1;
	let last = 0;
	while (a <= b) {
		const mid = Math.floor((a + b) / 2);
		if (prcnt <= uniSliderPricePercentage[mid]) {
			b = mid - 1;
			last = mid;
		} else a = mid + 1;
	}
	let price =
		((prcnt - uniSliderPricePercentage[last - 1]) /
			(uniSliderPricePercentage[last] -
				uniSliderPricePercentage[last - 1])) *
			(uniSliderPriceValues[last] - uniSliderPriceValues[last - 1]) +
		uniSliderPriceValues[last - 1];
	price = Math.round(price);
	const nshtt =
		(price - uniSliderPriceValues[last - 1]) %
		uniSliderPriceSteps[last - 1];
	if (nshtt <= uniSliderPriceSteps[last - 1] / 2) price -= nshtt;
	else price += uniSliderPriceSteps[last - 1] - nshtt;
	return price;
}

interface IPropsDetailedSearchContent {
	history: History;
	tab: "programs" | "unis";
	onDetailedSearch: FOnDetailedSearch;
}
type IStateDetailedSearchContent = Omit<
	IUnisDeailedSearch & IProgramsDeailedSearch,
	"tab"
>;

class DetailedSearchContent extends React.PureComponent<
	IPropsDetailedSearchContent,
	IStateDetailedSearchContent
> {
	readonly state: IStateDetailedSearchContent = {
		sector: -1,
		city: -1,
		field: -1,
		subjects: [],
		filterByScore: false,
		prices: [
			uniSliderPriceValues[0],
			uniSliderPriceValues[uniSliderPriceValues.length - 1],
		],
		subjScores: [],
	};

	onSaveSearchStart = () => {
		if (
			this.state.city > -1 &&
			this.state.field > -1 &&
			this.state.sector > -1
		) {
			if (this.props.tab === "programs") {
				api.users.saveSearchStats({
					type: "UNI" as any,
					city: this.state.city,
					sector: this.state.sector,
					field: this.state.field,
				});
			} else if (
				this.props.tab === "unis" &&
				this.state.subjects.length > 0 &&
				this.state.filterByScore === true &&
				this.state.subjScores.length > 0
			) {
				api.users.saveSearchStats({
					type: "PROGRAM" as any,
					city: this.state.city,
					sector: this.state.sector,
					field: this.state.field,
					subjects: this.state.subjects,
					filterByScore: this.state.filterByScore,
					prices: this.state.prices,
					subjScores: this.state.subjScores,
				});
			}
		}
	};

	extractSubjects = (str: string): number[] => {
		try {
			const arr = JSON.parse(str);
			if (!Array.isArray(arr)) return [];
			if (
				!arr.every(subjId => {
					subjId = +subjId;
					const thisSubj = erovnulSubjects.find(
						subj => subj.id === subjId
					);
					if (!thisSubj) return false;
					if (thisSubj.isCompulsory) return false;
					return true;
				})
			)
				return [];
			return arr;
		} catch (e) {
			return [];
		}
	};

	extractPrices = (str: string): [number, number] => {
		const defPricesArray: [number, number] = [
			uniSliderPriceValues[0],
			uniSliderPriceValues[uniSliderPriceValues.length - 1],
		];
		try {
			const arr = JSON.parse(str);
			if (!Array.isArray(arr) || arr.length !== 2) return defPricesArray;
			if (arr[0] < defPricesArray[0] || arr[1] > defPricesArray[1])
				return defPricesArray;
			if (!Number.isInteger(arr[0]) || !Number.isInteger(arr[1]))
				return defPricesArray;
			return arr as [number, number];
		} catch (e) {
			return defPricesArray;
		}
	};

	extractSubjScores = (str: string): number[] => {
		try {
			const arr = JSON.parse(str);
			const scoresArray: number[] = [];
			arr.forEach((score, subjId) => {
				if (score === null) return;
				const subj = erovnulSubjects.find(
					eachSubj => eachSubj.id === subjId
				);
				if (typeof subj === "undefined")
					throw new Error("subj not found");
				if (
					typeof subj.minScore === "undefined" ||
					typeof subj.maxScore === "undefined"
				) {
					throw new Error(`subject ${subjId} has no scoring system`);
				}
				if (score < subj.minScore)
					throw new Error("less than min score");
				if (score > subj.maxScore)
					throw new Error("more than max score");
				scoresArray[subjId] = score;
			});
			if (
				!erovnulSubjects
					.filter(subj => subj.isCompulsory)
					.every(subj => typeof scoresArray[subj.id] !== "undefined")
			) {
				throw new Error(
					"not all compulsory subject's scores are provided"
				);
			}
			return scoresArray;
		} catch (e) {
			return [];
		}
	};

	componentDidMount() {
		const searchQueries = getQueryStringParams(
			this.props.history.location.search
		);
		if (searchQueries.tab === "unis") {
			this.setState(
				{
					sector: +searchQueries.sector || 0,
					city: +searchQueries.city || 0,
					field: +searchQueries.field || 0,
				},
				() => {
					this.onSearch();
				}
			);
		} else if (searchQueries.tab === "programs") {
			const subjects = this.extractSubjects(searchQueries.subjects);
			let filterByScore =
				searchQueries.filterByScore === "false" ? false : true;
			if (subjects.length === 0) filterByScore = false;
			const subjScores = !filterByScore
				? []
				: this.extractSubjScores(searchQueries.subjScores);
			if (subjScores.length === 0) filterByScore = false;
			this.setState(
				{
					sector: +searchQueries.sector || 0,
					city: +searchQueries.city || 0,
					field: +searchQueries.field || 0,
					subjects: this.extractSubjects(searchQueries.subjects),
					filterByScore,
					prices: this.extractPrices(searchQueries.prices),
					subjScores,
				},
				() => {
					this.onSearch();
				}
			);
		}
	}

	onSearch = () => {
		this.onSaveSearchStart();
		let newUrl = "";
		const { pathname } = this.props.history.location;
		if (this.props.tab === "unis") {
			const searchParams: IUnisDeailedSearch = {
				tab: "unis",
				sector: this.state.sector,
				city: this.state.city,
				field: this.state.field,
			};

			this.props.onDetailedSearch(searchParams);
			newUrl = `${pathname}?tab=unis&sector=${this.state.sector}&city=${this.state.city}&field=${this.state.field}`;
			this.props.history.push(newUrl);
			triggerEvent(
				{
					category: "Search",
					action: "Detailed Search",
					label: "Unis",
				},
				searchParams
			);
		} else {
			const searchParams: IProgramsDeailedSearch = {
				tab: "programs",
				sector: this.state.sector,
				city: this.state.city,
				field: this.state.field,
				subjects: this.state.subjects,
				filterByScore: this.state.filterByScore,
				prices: this.state.prices,
				subjScores: this.state.subjScores,
			};
			this.props.onDetailedSearch(searchParams);
			newUrl =
				`${pathname}?tab=programs&sector=${this.state.sector}&city=${this.state.city}&field=${this.state.field}` +
				`&subjects=${JSON.stringify(
					this.state.subjects
				)}&filterByScore=${this.state.filterByScore}` +
				`&prices=${JSON.stringify(
					this.state.prices
				)}&subjScores=${JSON.stringify(this.state.subjScores)}`;
			// console.log(this.extractArrayFromString(this.state.subjects));
			this.props.history.push(newUrl);
			triggerEvent(
				{
					category: "Search",
					action: "Detailed Search",
					label: "Programs",
				},
				searchParams
			);
		}
	};

	changeSelect = name => (e: React.ChangeEvent<HTMLSelectElement>) => {
		// {target: {value: number[]}}
		const additionalInfo: any = {};
		if (name === "subjects") {
			if (!Array.isArray(e.target.value)) return;
			const selectedSubjs: number[] = e.target.value as number[];
			this.setState(({ subjScores, subjects }) => {
				const newScores: number[] = [...subjScores];
				newScores.forEach((score: number, subjID: number) => {
					// tslint:disable-next-line
					if (
						erovnulSubjects.findIndex(
							subj => subj.id === subjID && subj.isCompulsory
						) > -1
					)
						return;
					if (selectedSubjs.indexOf(subjID) === -1)
						delete newScores[subjID]; // deleteScore
				});
				if (subjects.length < selectedSubjs.length) {
					// subject added
					const newSubjID: number | undefined = selectedSubjs.find(
						(subjID: number) => subjects.indexOf(subjID) === -1
					);
					if (typeof newSubjID === "undefined") return;
					const subj:
						| IErovnuliSubj
						| undefined = erovnulSubjects.find(
						sbj => sbj.id === newSubjID
					);
					if (typeof subj === "undefined") return;
					if (
						typeof subj.minScore !== "undefined" &&
						typeof subj.maxScore !== "undefined"
					) {
						const score: number =
							subj.minScore +
							Math.floor((subj.maxScore - subj.minScore) / 2);
						newScores[newSubjID] = score;
					}
				}
				additionalInfo.subjScores = newScores;
				return { subjects: selectedSubjs, ...additionalInfo };
			});
		} else this.setState({ [name]: e.target.value, ...additionalInfo });
	};

	changeCheckbox = name => e => {
		const additionalInfo: any = {};
		if (name === "filterByScore") {
			const newScores: number[] = [...this.state.subjScores];
			erovnulSubjects
				.filter(subj => subj.isCompulsory)
				.forEach(subj => {
					if (
						typeof subj.minScore === "undefined" ||
						typeof subj.maxScore === "undefined"
					)
						return;
					const score = Math.floor(
						(subj.maxScore + subj.minScore) / 2
					);
					if (typeof newScores[subj.id] === "undefined")
						newScores[subj.id] = score;
				});
			this.state.subjects.forEach(subjId => {
				const subj = erovnulSubjects.find(el => el.id === subjId);
				if (typeof subj === "undefined") return;
				if (
					typeof subj.minScore === "undefined" ||
					typeof subj.maxScore === "undefined"
				)
					return;
				const score = Math.floor((subj.maxScore + subj.minScore) / 2);
				if (typeof newScores[subj.id] === "undefined")
					newScores[subj.id] = score;
			});
			additionalInfo.subjScores = newScores;
		}
		this.setState({ [name]: e.target.checked, ...additionalInfo });
	};

	onPriceSliderChange = prices => {
		this.setState({ prices });
	};

	onSubjectScoreChange = (subjID: number, score: number) => {
		const newScores: number[] = [...this.state.subjScores];
		newScores[subjID] = score;
		this.setState({ subjScores: newScores });
	};

	renderSubjsAsStrings = (selected: number[]): string => {
		return selected
			.map((each: number) => {
				const thisSubj = electiveSubjects.find(
					subj => subj.id === each
				);
				if (typeof thisSubj === "undefined") return "";
				return thisSubj.name;
			})
			.join(", ");
	};

	render() {
		const scorableSubjects = this.state.subjects.filter(selectedSubj => {
			const thisSubj = electiveSubjects.find(
				subj => subj.id === selectedSubj
			);
			if (typeof thisSubj === "undefined") return false;
			return !thisSubj.noScores;
		});
		const compulsorySubjs = erovnulSubjects
			.filter(subj => subj.isCompulsory)
			.map(subj => subj.id);
		return (
			<div className="DetailedSearchContainer">
				<div className="DetailedSearchComponent">
					<div className="DetailedSearchContent">
						<SelectParts label="სექტორი">
							<Select
								value={this.state.sector}
								className="DetailedSearchSelect"
								disableUnderline={true}
								onChange={this.changeSelect("sector")}
							>
								<MenuItem value={-1}>ორივე</MenuItem>
								{sectors.map((sector, index) => (
									<MenuItem key={index} value={index}>
										{sector}
									</MenuItem>
								))}
							</Select>
						</SelectParts>
						<SelectParts label="ქალაქი">
							<Select
								value={this.state.city}
								className="DetailedSearchSelect"
								disableUnderline={true}
								onChange={this.changeSelect("city")}
							>
								<MenuItem value={-1}>ნებისმიერი</MenuItem>
								{cities.map(city => (
									<MenuItem key={city.id} value={city.id}>
										{city.name}
									</MenuItem>
								))}
							</Select>
						</SelectParts>
						<SelectParts label="მიმართულება">
							<Select
								value={this.state.field}
								className="DetailedSearchSelect"
								disableUnderline={true}
								onChange={this.changeSelect("field")}
							>
								<MenuItem value={-1}>ნებისმიერი</MenuItem>
								{fields.map(field => (
									<MenuItem key={field.id} value={field.id}>
										{field.name}
									</MenuItem>
								))}
							</Select>
						</SelectParts>
						{this.props.tab === "programs" && (
							<React.Fragment>
								<SelectParts label="არჩევითი საგნები">
									<Select
										multiple={true}
										className="DetailedSearchSelect"
										disableUnderline={true}
										value={this.state.subjects}
										onChange={this.changeSelect("subjects")}
										renderValue={this.renderSubjsAsStrings}
									>
										{electiveSubjects.map(subj => (
											<MenuItem
												key={subj.id}
												value={subj.id}
											>
												<Checkbox
													checked={
														this.state.subjects.indexOf(
															subj.id as never
														) > -1
													}
												/>
												<ListItemText
													primary={subj.name}
												/>
											</MenuItem>
										))}
									</Select>
								</SelectParts>
								<PriceRangeSlider
									onChange={this.onPriceSliderChange}
								/>
								{scorableSubjects.length > 0 && false && (
									<React.Fragment>
										<div style={{ textAlign: "center" }}>
											<FormControlLabel
												control={
													<Checkbox
														checked={
															this.state
																.filterByScore
														}
														onChange={this.changeCheckbox(
															"filterByScore"
														)}
													/>
												}
												label="ქულების მითითება"
											/>
										</div>
										{this.state.filterByScore && (
											<React.Fragment>
												{compulsorySubjs.map(subjID => (
													<SubjectScoreSlider
														key={subjID}
														subjID={subjID}
														onChange={
															this
																.onSubjectScoreChange
														}
														defaultValue={
															this.state
																.subjScores[
																subjID
															]
														}
													/>
												))}
												{scorableSubjects.map(
													subjID => (
														<SubjectScoreSlider
															key={subjID}
															subjID={subjID}
															onChange={
																this
																	.onSubjectScoreChange
															}
															defaultValue={
																this.state
																	.subjScores[
																	subjID
																]
															}
														/>
													)
												)}
											</React.Fragment>
										)}
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					</div>
					<button
						className="detailedSearchButton"
						onClick={this.onSearch}
					>
						ძებნა
					</button>
				</div>
			</div>
		);
	}
}

interface IPropsSelectParts {
	label: string;
}

const SelectParts: React.SFC<IPropsSelectParts> = ({ children, label }) => (
	<div className="DetailedSearchSelectContainer">
		<div className="DetailedSearchLabel">{label}</div>
		{children}
	</div>
);

class PriceRangeSlider extends React.PureComponent<{ onChange: Function }> {
	state = {
		prices: [
			uniSliderPriceValues[0],
			uniSliderPriceValues[uniSliderPriceValues.length - 1],
		],
		values: [0, 100],
	};

	onPriceSliderChange = e => {
		this.setState({
			prices: [uniPercentToPrice(e[0]), uniPercentToPrice(e[1])],
			values: e,
		});
	};

	onAfterChange = e => {
		this.props.onChange([uniPercentToPrice(e[0]), uniPercentToPrice(e[1])]);
	};

	displayValue = (value: number): string => {
		return `${uniPercentToPrice(value)}₾`;
	};

	render() {
		return (
			<div className="rangePriceSlider rc-slider-gray-theme">
				<div className="priceRangeLabel">ფასი</div>
				<Range
					min={0}
					max={100}
					defaultValue={[0, 100]}
					step={0.1}
					onChange={this.onPriceSliderChange}
					onAfterChange={this.onAfterChange}
					tipFormatter={this.displayValue}
				/>
				<div id="uniSrcPrcSldr">
					<div style={{ width: this.state.values[0] + "%" }} />
					<div>
						<div>
							<span className="number">
								{this.state.prices[0]}
							</span>
							<span>—</span>
							<span className="number">
								{this.state.prices[1]}
							</span>
						</div>
					</div>
					<div style={{ width: 100 - this.state.values[1] + "%" }} />
				</div>
			</div>
		);
	}
}

class SubjectScoreSlider extends React.PureComponent<{
	defaultValue: number;
	subjID: number;
	onChange: Function;
}> {
	subj: IErovnuliSubj;

	state = {
		score: this.props.defaultValue,
	};
	constructor(props) {
		super(props);
		const a = erovnulSubjects.find(subj => subj.id === props.subjID);
		if (typeof a !== "undefined") {
			this.subj = a;
		}
	}

	onChange = score => {
		this.setState({ score: Math.floor(score) });
	};

	onAfterChange = score => {
		this.props.onChange(this.props.subjID, Math.floor(score));
	};

	render() {
		return (
			<div className="subjScoreSlider rc-slider-gray-theme">
				<div>
					<span>{this.subj.name}</span>
					<span>{this.state.score}</span>
				</div>
				<Slider
					min={this.subj.minScore}
					max={this.subj.maxScore}
					defaultValue={this.state.score}
					onChange={this.onChange}
					onAfterChange={this.onAfterChange}
				/>
			</div>
		);
	}
}

export default BigSearchContent;
