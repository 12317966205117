import * as React from "react";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import { CalendarBody, ICalendarProps } from "./CalendarCommonParts";
import { monthNamesGeo } from "@app/commonJavascript";
import "./styles/calendars.min.css";

type IProps = ICalendarProps;

class CalendarPicker extends React.Component<IProps> {
	static defaultProps = {
		bgColor: "#0f0f15",
		textColor: "#fff",
		textColor2: "#fff",
		mainColor: "orange",
	};

	state = {
		selectedDate: this.props.start_time
			? this.props.start_time
			: new Date(),
		displayDate: new Date(),
	};

	nextMonth = () => {
		const newDate = new Date(this.state.displayDate.getTime());
		newDate.setMonth(newDate.getMonth() + 1);
		this.setState({
			displayDate: newDate,
		});
	};

	prevMonth = () => {
		const newDate = new Date(this.state.displayDate.getTime());
		newDate.setMonth(newDate.getMonth() - 1);
		this.setState({
			displayDate: newDate,
		});
	};

	onDateChange = (e, dateType: "day" | "month" | "year") => {
		const newDate = new Date(this.state.selectedDate.getTime());
		if (dateType === "day") {
			const newDay = parseInt(e.target.value, 10) || 0;
			newDate.setDate(newDay);
		} else if (dateType === "month") {
			const newMonth = parseInt(e.target.value, 10) || 0;
			newDate.setMonth(newMonth - 1);
		} else if (dateType === "year") {
			const newYear = parseInt(e.target.value, 10) || 0;
			newDate.setFullYear(2000 + newYear);
		}

		this.setState({
			selectedDate: newDate,
			displayDate: newDate,
		});

		if (typeof this.props.onDateChange !== "undefined")
			this.props.onDateChange(newDate);
	};

	// resetDate = () => {
	// 	this.setState({
	// 		selectedDate: new Date(),
	// 		displayDate: new Date()
	// 	});
	// };

	onSelectedDateChange = (time: number) => {
		this.setState({
			selectedDate: new Date(time),
			displayDate: new Date(time),
		});
		if (typeof this.props.onDateChange !== "undefined")
			this.props.onDateChange(new Date(time));
	};

	render() {
		return (
			<div
				className="mur-calendarPicker mur-calendarWidget"
				style={{ background: this.props.bgColor }}
			>
				<CalendarPickerHeader
					displayDate={this.state.displayDate}
					nextMonth={this.nextMonth}
					prevMonth={this.prevMonth}
					textColor={this.props.textColor}
				/>
				<CalendarBody
					displayDate={this.state.displayDate}
					selectedDate={this.state.selectedDate}
					onSelectedDateChange={this.onSelectedDateChange}
					mainColor={this.props.mainColor}
					textColor={this.props.textColor}
					textColor2={this.props.textColor2}
				/>
				<CalendarPickerInputDate
					selectedDate={this.state.selectedDate}
					onDateChange={this.onDateChange}
					textColor={this.props.textColor}
					// resetDate={this.resetDate}
				/>
			</div>
		);
	}
}

export { CalendarPicker };

interface ICalendarHeaderProps {
	textColor: string;
	displayDate: Date;
	prevMonth: (event: React.MouseEvent<HTMLDivElement>) => void;
	nextMonth: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const CalendarPickerHeader: React.SFC<ICalendarHeaderProps> = props => (
	<div className="header">
		<div className="month">
			<div
				style={{
					display: "table",
					textAlign: "center",
					color: props.textColor,
				}}
			>
				<div className="date prev" onClick={props.prevMonth}>
					<LeftIcon />
				</div>
				<div>
					{monthNamesGeo[props.displayDate.getMonth()]},{" "}
					{props.displayDate.getFullYear()}
				</div>
				<div className="date next" onClick={props.nextMonth}>
					<RightIcon />
				</div>
			</div>
		</div>
	</div>
);

export { CalendarPickerHeader };

interface ICalendarInputDateProps {
	selectedDate: Date;
	textColor: string;
	onDateChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		dateType: "day" | "month" | "year"
	) => void;
	extraText?: string;
}

const CalendarPickerInputDate: React.SFC<ICalendarInputDateProps> = props => (
	<div>
		<div className="calendarDateInputs">
			{/*<div>{props.selectedDate.getDate()}.{props.selectedDate.getMonth()}.{props.selectedDate.getFullYear()}</div>*/}
			<input
				className="dateInput no-arrows"
				style={{ margin: "0 5px 0 0", color: props.textColor }}
				type="number"
				value={props.selectedDate.getDate()}
				onChange={e => props.onDateChange(e, "day")}
			/>
			<div className="line" />
			<input
				className="dateInput no-arrows"
				style={{ margin: "0 5px 0 0", color: props.textColor }}
				type="number"
				value={props.selectedDate.getMonth() + 1}
				onChange={e => props.onDateChange(e, "month")}
			/>
			<div className="line" />
			<input
				className="dateInput no-arrows"
				style={{ color: props.textColor }}
				type="number"
				value={props.selectedDate.getFullYear() % 100}
				onChange={e => props.onDateChange(e, "year")}
			/>
			{props.extraText && (
				<span
					style={{
						color: props.textColor,
						fontSize: 30,
						verticalAlign: "middle",
					}}
				>
					{props.extraText}
				</span>
			)}
		</div>
		{/* <button className="dateResetButton" onClick={props.resetDate}>Reset</button> */}
	</div>
);

export { CalendarPickerInputDate };
