import * as React from "react";
import "./styles/mainSwitch.min.css";
import Switch from "@material-ui/core/Switch";
import { css } from "emotion";

const getBarClass = (checked: boolean, color: string): string => {
	return css({
		backgroundColor: (checked ? color : "#0e0f14") + " !important",
		border: `1px solid ${checked ? "transparent" : "#28294a"}`,
	});
};

interface IProps {
	checked: boolean;
	onClick: (event) => any;
	color?: string;
	size?: "big" | "small";
}

const MainSwitch: React.SFC<IProps> = ({
	checked,
	onClick,
	color = "#ff8a09",
	size = "small",
}) => (
	<div className={`mainSwitchWrapper ${size}`}>
		<Switch
			checked={checked}
			onChange={onClick}
			value="checkedA"
			disableRipple={true}
			className="mainSwitch"
			classes={{
				switchBase: "switchBase",
				bar: "bar " + getBarClass(checked, color),
				icon: "icon",
				iconChecked: "iconChecked",
				checked: "checked",
			}}
		/>
	</div>
);

export default MainSwitch;
