import { Cast } from "typesafe-joi";
import Joi, { SchemaToType } from "@app/utils/joi";

export enum SearchType {
	uni = "UNI",
	program = "PROGRAM",
}

export const SearchInfoSchema = Joi.alternatives(
	Joi.object({
		type: Joi.string()
			.valid(SearchType.uni)
			.required(),
		sector: Joi.number().required(),
		city: Joi.number().required(),
		field: Joi.number().required(),
	}),
	Joi.object({
		type: Joi.string()
			.valid(SearchType.program)
			.required(),
		sector: Joi.number().required(),
		city: Joi.number().required(),
		field: Joi.number().required(),
		subjects: Joi.array()
			.items(Joi.number())
			.required(),
		filterByScore: Joi.boolean().required(),
		prices: (Joi.array()
			.items(Joi.number())
			.length(2)
			.required() as any) as Cast.Any<[number, number]>,
		subjScores: Joi.array()
			.items(Joi.number())
			.required(),
	})
);
export type ISearchInfo = SchemaToType<typeof SearchInfoSchema>;

export const UserSearchInfoSchema = Joi.object({
	_id: Joi.objectId().required(),
	userId: Joi.number()
		.integer()
		.required(),
	info: SearchInfoSchema.required(),
	createdAt: Joi.objectId().required(),
	updatedAt: Joi.objectId().required(),
});
export type IUserSearchInfo = SchemaToType<typeof UserSearchInfoSchema>;
