import Joi, { SchemaToType } from "@app/utils/joi";
import {
	PrivilegeAllUnisSchema,
	PrivilegeOneUniAllProgramsSchema,
	PrivilegeOneUniSeveralProgramsSchema,
	RMiniPopupRejectionSchema,
	AGETdminInfoSchema,
} from "./helper-schemas";
import { SearchInfoSchema } from "../user-search-info/helper-schemas";

///

export const AGetSearchSchema = Joi.object().keys({
	mob: Joi.number(),
	mail: Joi.number(),
});
export type IAGetSearch = SchemaToType<typeof AGetSearchSchema>;

export const RGetSearchSchema = Joi.object().keys({
	id: Joi.number().required(),
	username: Joi.string().required(),
	firstname: Joi.string()
		.allow(null)
		.required(),
	lastname: Joi.string()
		.allow(null)
		.required(),
});
export type IRGetSearch = SchemaToType<typeof RGetSearchSchema>;

///

export const APutUserPermissionsSchema = Joi.object().keys({
	user_id: Joi.number()
		.integer()
		.required(),
	canAddAdmins: Joi.boolean().required(),
	uniPermissions: Joi.number()
		.integer()
		.required(),
	privilege: Joi.alternatives([
		PrivilegeAllUnisSchema,
		PrivilegeOneUniAllProgramsSchema,
		PrivilegeOneUniSeveralProgramsSchema,
	]).required(),
});

export type IAPutUserPermissions = SchemaToType<
	typeof APutUserPermissionsSchema
>;

export type IRPutUserPermissions = void;

///

export const RGETLatoriAdmissionSchema = Joi.object().keys({
	subjectIds: Joi.array()
		.items(Joi.number().integer())
		.required(),
	programIds: Joi.array()
		.items(Joi.number().integer())
		.required(),
	fixedSubjectScores: Joi.object()
		.pattern(/^[0-9]*$/, Joi.number().integer())
		.required(),
});
export type IRGETLatoriAdmission = SchemaToType<
	typeof RGETLatoriAdmissionSchema
>;

///

export const AGETUsersPersonalInfoSchema = Joi.object().keys({
	userIds: Joi.array()
		.items(Joi.number().integer())
		.required(),
	grad_year: Joi.number(),
});

export const UserPersonalInfoSchema = Joi.object().keys({
	id: Joi.number().required(),
	firstname: Joi.string().required(),
	lastname: Joi.string().required(),
	username: Joi.string().optional(),
	mobile: Joi.string().required(),
	mail: Joi.string().required(),
	isValidMobile: Joi.boolean().required(),
	city: Joi.string().required(),
	school: Joi.string()
		.allow(null)
		.required(),
});

export const RGETUsersPersonalInfoSchema = Joi.array().items(
	UserPersonalInfoSchema
);

export type IAGETUsersPersonalInfo = SchemaToType<
	typeof AGETUsersPersonalInfoSchema
>;
export type IRGETUsersPersonalInfo = SchemaToType<
	typeof RGETUsersPersonalInfoSchema
>;

///

export const APOSTMiniPopupRejectionSchema = RMiniPopupRejectionSchema;
export type IAPOSTMiniPopupRejection = SchemaToType<
	typeof APOSTMiniPopupRejectionSchema
>;

///

export const APUTValidateMobileSchema = Joi.object().keys({
	mob: Joi.number()
		.integer()
		.required(),
});
export type IAPUTValidateMobile = SchemaToType<typeof APUTValidateMobileSchema>;

///

export const APUTValidateMobileCodeSchema = Joi.object().keys({
	mob: Joi.number()
		.integer()
		.required(),
	code: Joi.number()
		.integer()
		.required(),
});
export type IAPUTValidateMobileCode = SchemaToType<
	typeof APUTValidateMobileCodeSchema
>;

///

export const APOSTFriendInvitationSchema = Joi.object().keys({
	info: Joi.array()
		.items(
			Joi.object().keys({
				name: Joi.string().required(),
				phone: Joi.string().required(),
			})
		)
		.required(),
});
export type IAPOSTFriendInvitation = SchemaToType<
	typeof APOSTFriendInvitationSchema
>;

///

export const AGETPlaceByTotalScore = Joi.object().keys({
	score: Joi.number().required(),
});
export type IAGETPlaceByTotalScore = SchemaToType<typeof AGETPlaceByTotalScore>;

export const RGETPlaceByTotalScore = Joi.object().keys({
	place: Joi.number().required(),
});
export type IRGETPlaceByTotalScore = SchemaToType<typeof RGETPlaceByTotalScore>;

///

export const APOSTSaveSearchInfoSchema = SearchInfoSchema;
export type IAPOSTSaveSearchInfo = SchemaToType<
	typeof APOSTSaveSearchInfoSchema
>;

export type IRPOSTSaveSearchInfo = void;

export const APOSTContractRenewal = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
});
export type IAPOSTContractRenewal = SchemaToType<typeof APOSTContractRenewal>;

///

export const REGETAllStudentsPhoneNumbersSchema = Joi.array()
	.items(
		Joi.object()
			.keys({
				phone: Joi.number()
					.integer()
					.min(514000000)
					.max(599999999)
					.required(),
			})
			.required()
	)
	.required();

export type IREGETAllStudentsPhoneNumbers = SchemaToType<
	typeof REGETAllStudentsPhoneNumbersSchema
>;

///

export const RGETAllAdminsSchema = Joi.array()
	.items(AGETdminInfoSchema)
	.required();

export type IRGETAllAdmins = SchemaToType<typeof RGETAllAdminsSchema>;

///

export const APUTActivateAdminsSchema = Joi.object().pattern(
	/^[0-9]*$/,
	Joi.object()
		.keys({
			canAccess: Joi.boolean().required(),
		})
		.required()
);

export type IAPUTActivateAdmins = SchemaToType<typeof APUTActivateAdminsSchema>;

export const RPUTSuccessSchema = Joi.object().keys({
	success: Joi.boolean().required(),
});

export type IRPUTSuccess = SchemaToType<typeof RPUTSuccessSchema>;

///

export const AGETActivationStatusForAdminSchema = Joi.object().keys({
	userId: Joi.number()
		.integer()
		.required(),
});

export type IAGETActivationStatusForAdmin = SchemaToType<
	typeof AGETActivationStatusForAdminSchema
>;

export const RGETActivationStatusForAdminSchema = Joi.object().keys({
	canAccess: Joi.boolean().required(),
});

export type IRGETActivationStatusForAdmin = SchemaToType<
	typeof RGETActivationStatusForAdminSchema
>;

///

export const APUTUserMobileSchema = Joi.object().keys({
	mob: Joi.string().required(),
});

export type IAPUTUserMobile = SchemaToType<typeof APUTUserMobileSchema>;

///

export const AGETRequestConsultationSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	program_id: Joi.number()
		.integer()
		.optional(),
});

export interface IAGETRequestConsultation {
	uni_id: number;
	program_id?: number;
}

///

export const APUTProfileSchema = Joi.object().keys({
	firstName: Joi.string().optional(),
	lastName: Joi.string().optional(),
	mail: Joi.string().optional(),
	mobile: Joi.number()
		.integer()
		.optional(),
	password: Joi.string().optional(),
});

export interface IAPUTProfile {
	firstName?: string;
	lastName?: string;
	mail?: string;
	mobile?: number;
	password?: string;
}
