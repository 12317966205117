import api from "@app/api";
import {
	ADD_LATORI_CONTEST_SCORE,
	ADD_LATORI_USER_SCORE,
	LATOR_ADD_FIXED_SCORE,
	LATOR_ADD_PROGRAM,
	LATOR_ADD_PROGRAMS,
	LATOR_ADD_SUBJECT,
	LATOR_ADD_SUBJECTS,
	LATOR_REMOVE_FIXED_SCORE,
	LATOR_REMOVE_PROGRAM,
	LATOR_REMOVE_SUBJECT,
	LOAD_LATOR,
	LOAD_USER_LATOR_INFO,
} from "../action-types";
import { erovnulSubjects } from "@app/commonJavascript";
import { ILogoutAction } from "./user";
import { IRGETLatoriAdmission } from "@app/api/users/validators";
import { IRootState } from "@app/reducers/root";
import { IStateLator, IUserScores } from "@app/reducers/lator";
import { ThunkAction } from "redux-thunk";

// tslint:disable:max-line-length

interface ILoadLatorFromStorage {
	type: typeof LOAD_LATOR;
	data: IStateLator;
}

export const loadLatorFromStorage = (
	data: IStateLator
): ILoadLatorFromStorage => {
	return {
		type: LOAD_LATOR,
		data,
	};
};

interface ILoadUserLatorInfoAction {
	type: typeof LOAD_USER_LATOR_INFO;
	data: IRGETLatoriAdmission;
}

const loadUserLatorInfoAction = (
	data: IRGETLatoriAdmission
): ILoadUserLatorInfoAction => {
	return {
		type: LOAD_USER_LATOR_INFO,
		data: {
			...data,
			subjectIds: data.subjectIds.filter(subjectId => {
				const subject = erovnulSubjects.find(e => e.id === subjectId);
				if (!subject) return false;
				return !subject.isCompulsory;
			}),
		},
	};
};

export const loadUserLatorInfo = (): ThunkAction<
	Promise<IRGETLatoriAdmission>,
	IRootState,
	null,
	LatorActions
> => dispatch => {
	return api.lator.getUserData().then(data => {
		dispatch(loadUserLatorInfoAction(data));
		return data;
	});
};

interface IAddSubjectAction {
	type: typeof LATOR_ADD_SUBJECT;
	id: number;
}

export const addSubjectAction = (subject_id: number): IAddSubjectAction => {
	api.lator
		.addSubject({
			subject_id,
			isFixed: false,
		})
		.then()
		.catch();
	return {
		type: LATOR_ADD_SUBJECT,
		id: subject_id,
	};
};

interface IRemoveSubjectAction {
	type: typeof LATOR_REMOVE_SUBJECT;
	id: number;
}

export const removeSubjectAction = (
	subject_id: number
): IRemoveSubjectAction => {
	api.lator
		.deleteSubject({
			subject_id,
		})
		.then()
		.catch();
	return {
		type: LATOR_REMOVE_SUBJECT,
		id: subject_id,
	};
};

interface IAddSubjectsAction {
	type: typeof LATOR_ADD_SUBJECTS;
	ids: number[];
}

export const addSubjectsAction = (subjectIds: number[]): IAddSubjectsAction => {
	api.lator
		.addSubjects({
			subjects: subjectIds,
		})
		.then()
		.catch();
	return {
		type: LATOR_ADD_SUBJECTS,
		ids: subjectIds,
	};
};

interface IAddProgramAction {
	type: typeof LATOR_ADD_PROGRAM;
	id: number;
}

export const addProgramAction = (program_id: number): IAddProgramAction => {
	api.lator
		.addProgram({
			program_id,
		})
		.then()
		.catch();
	return {
		type: LATOR_ADD_PROGRAM,
		id: program_id,
	};
};

interface IRemoveProgramAction {
	type: typeof LATOR_REMOVE_PROGRAM;
	id: number;
}

export const removeProgramAction = (
	program_id: number
): IRemoveProgramAction => {
	api.lator
		.deleteProgram({
			program_id,
		})
		.then()
		.catch();
	return {
		type: LATOR_REMOVE_PROGRAM,
		id: program_id,
	};
};

interface IAddProgramsAction {
	type: typeof LATOR_ADD_PROGRAMS;
	ids: number[];
}

export const addProgramsAction = (programsId: number[]): IAddProgramsAction => {
	api.lator
		.addPrograms({
			programs: programsId,
		})
		.then()
		.catch();
	return {
		type: LATOR_ADD_PROGRAMS,
		ids: programsId,
	};
};

interface IAddFixedScoreAction {
	type: typeof LATOR_ADD_FIXED_SCORE;
	subjectId: number;
	score: number;
}

export const addFixedScoreAction = (
	subject_id: number,
	score: number
): IAddFixedScoreAction => {
	api.lator
		.changeSubjectScore({
			subject_id,
			isFixed: true,
			score,
		})
		.then()
		.catch();
	return {
		type: LATOR_ADD_FIXED_SCORE,
		subjectId: subject_id,
		score,
	};
};

interface IRemoveFixedScoreAction {
	type: typeof LATOR_REMOVE_FIXED_SCORE;
	subjectId: number;
}

export const removeFixedScoreAction = (
	subject_id: number
): IRemoveFixedScoreAction => {
	api.lator
		.subjectFixation({
			subject_id,
			isFixed: false,
		})
		.then()
		.catch();
	return {
		type: LATOR_REMOVE_FIXED_SCORE,
		subjectId: subject_id,
	};
};

interface IAddLatoriContestScore {
	type: typeof ADD_LATORI_CONTEST_SCORE;
	contestScore: number;
}

export const addLatoriContestScore = (
	contestScore: number
): IAddLatoriContestScore => {
	return {
		type: ADD_LATORI_CONTEST_SCORE,
		contestScore,
	};
};

interface IAddLatoriUserScore {
	type: typeof ADD_LATORI_USER_SCORE;
	userScores: IUserScores;
}

export const addLatoriUserScore = (
	userScores: IUserScores
): IAddLatoriUserScore => {
	return {
		type: ADD_LATORI_USER_SCORE,
		userScores,
	};
};

export type LatorActions =
	| ILoadUserLatorInfoAction
	| ILoadLatorFromStorage
	| IAddSubjectAction
	| IAddSubjectsAction
	| IAddProgramAction
	| IAddProgramsAction
	| IAddFixedScoreAction
	| IRemoveFixedScoreAction
	| IRemoveSubjectAction
	| IRemoveProgramAction
	| ILogoutAction
	| IAddLatoriContestScore
	| IAddLatoriUserScore;
