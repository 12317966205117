import * as React from "react";
import { CalendarBody, ICalendarProps } from "./CalendarCommonParts";
import { CalendarPickerHeader, CalendarPickerInputDate } from "./CalendarPicker";
import "./styles/calendars.min.css";

type IProps = ICalendarProps;

class CalendarIntervalPicker extends React.Component<IProps> {
	static defaultProps = {
		bgColor: "#0f0f15",
		textColor: "#fff",
		textColor2: "#fff",
		mainColor: "orange"
	};

	state = {
		firstSelectedDate: this.props.start_time ? this.props.start_time : new Date(),
		secondSelectedDate: this.props.end_time ? this.props.end_time : new Date(Date.now() + 24 * 3600 * 1000),
		displayDate: new Date(),
	};

	nextMonth = () => {
		const newDate = new Date(this.state.displayDate.getTime());
		newDate.setMonth(newDate.getMonth() + 1);
		this.setState({
			displayDate: newDate
		});
	};

	prevMonth = () => {
		const newDate = new Date(this.state.displayDate.getTime());
		newDate.setMonth(newDate.getMonth() - 1);
		this.setState({
			displayDate: newDate
		});
	};

	onDateChange = (e, dateType: "day" | "month" | "year", whichDate: "first" | "second") => {
		const newDate = new Date((whichDate === "first" ? this.state.firstSelectedDate : this.state.secondSelectedDate).getTime());
		if (dateType === "day") {
			const newDay = parseInt(e.target.value, 10) || 0;
			newDate.setDate(newDay);
		} else if (dateType === "month") {
			const newMonth = parseInt(e.target.value, 10) || 0;
			newDate.setMonth(newMonth - 1);
		} else if (dateType === "year") {
			const newYear = parseInt(e.target.value, 10) || 0;
			newDate.setFullYear(2000 + newYear);
		}
		if (whichDate === "first" && newDate > this.state.secondSelectedDate) return;
		if (whichDate === "second" && newDate < this.state.firstSelectedDate) return;
		const whichParentDay = whichDate === "first" ? "start_time" : "end_time";
		this.setState({
			[whichDate === "first" ? "firstSelectedDate" : "secondSelectedDate"]: newDate,
			displayDate: newDate,
		});
		if (typeof this.props.onDateIntervalChange !== "undefined") {
			this.props.onDateIntervalChange(whichParentDay, newDate);
		}
	};

	onFirstDateChange = (e, dateType: "day" | "month" | "year") => {
		this.onDateChange(e, dateType, "first");
	}
	onSecondDateChange = (e, dateType: "day" | "month" | "year") => {
		this.onDateChange(e, dateType, "second");
	}

	// resetDate = () => {
	// 	this.setState({
	// 		selectedDate: new Date(),
	// 		displayDate: new Date()
	// 	});
	// };

	onSelectedDateChange = (time: number) => {
		const first = this.state.firstSelectedDate.getTime();
		const second = this.state.secondSelectedDate.getTime();
		const closerToFirst = Math.abs(first - time) < Math.abs(second - time);
		const whichParentDay = closerToFirst ? "start_time" : "end_time";
		this.setState({
			[closerToFirst ? "firstSelectedDate" : "secondSelectedDate"]: new Date(time),
			displayDate: new Date(time),
		});
		if (typeof this.props.onDateIntervalChange !== "undefined") {
			this.props.onDateIntervalChange(whichParentDay, new Date(time));
		}
	}

	render() {
		return (
			<div className="mur-calendarPicker mur-calendarWidget" style={{ background: this.props.bgColor }}>
				<CalendarPickerHeader
					displayDate={this.state.displayDate}
					nextMonth={this.nextMonth}
					prevMonth={this.prevMonth}
					textColor = {this.props.textColor}
				/>
				<CalendarBody
					displayDate={this.state.displayDate}
					selectedDate={this.state.firstSelectedDate}
					selectedEndDate={this.state.secondSelectedDate}
					onSelectedDateChange={this.onSelectedDateChange}
					mainColor={this.props.mainColor}
					textColor = {this.props.textColor}
					textColor2 = {this.props.textColor2}
				/>
				<CalendarPickerInputDate
					selectedDate={this.state.firstSelectedDate}
					onDateChange={this.onFirstDateChange}
					textColor={this.props.textColor}
					extraText=" დან"
					// resetDate={this.resetDate}
				/>
				<CalendarPickerInputDate
					selectedDate={this.state.secondSelectedDate}
					onDateChange={this.onSecondDateChange}
					textColor={this.props.textColor}
					extraText=" მდე"
					// resetDate={this.resetDate}
				/>
			</div>
		);
	}
}

export { CalendarIntervalPicker };
