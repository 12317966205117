import * as React from "react";
import ChatPreview from "./chat-preview-component";
import styled from "@emotion/styled";
import { IChatroom } from "../../reducers/chatrooms";

const Container = styled.div`
	display: inline-block;
	width: 33%;
	height: 100%;
	background-color: #091019;
	border-left: 1px solid #23263e;
	border-top: 1px solid #23263e;
	border-bottom: 1px solid #23263e;
	border-radius: 20px 0 0 20px;
	overflow-x: hidden;
`;

interface IOwnProps {
	userId: string;
	chatroomsInfo: IChatroom[];
	showBarOnMobile: boolean;
	onChange: (chatroomId: string) => void;
}

type IProps = IOwnProps;

class ChatLeftPart extends React.Component<IProps> {
	render() {
		return (
			<Container
				className={
					this.props.showBarOnMobile
						? "show-left-bar"
						: "hide-left-bar"
				}
			>
				{this.props.chatroomsInfo.map(chatroom => (
					<ChatPreview
						key={chatroom._id}
						chatroomId={chatroom._id}
						name={chatroom.name}
						userId={this.props.userId}
						userIds={chatroom.participantIds}
						userNames={chatroom.participantNames}
						messages={chatroom.messages}
						onClick={this.props.onChange}
					/>
				))}
			</Container>
		);
	}
}

export default ChatLeftPart;
