import React from "react";

const SvgFourExpandArrows = props => (
	<svg viewBox="0 0 707.1 706.8" {...props}>
		<g fill="#fff">
			<path d="M387.1 272.8c8.2 0 14.9-6.7 14.9-14.9V156.5h32.5c6.7 0 12.8-3.7 15.9-9.6 3.1-5.9 2.7-13-1.2-18.5L364.9 6.8c-3-4.3-8-6.9-13.2-6.9-5.3 0-10.2 2.6-13.2 7l-83.3 121.7c-3.8 5.5-4.2 12.6-1.1 18.5s9.2 9.6 15.9 9.6h32.5v101.4c0 8.2 6.7 14.9 14.9 14.9l69.7-.2zM320.1 433.9c-8.2 0-14.9 6.7-14.9 14.9v101.3h-32.5c-6.7 0-12.8 3.7-15.9 9.6-3.1 5.9-2.7 13 1.2 18.5l84.3 121.6c3 4.3 8 6.9 13.2 6.9 5.3 0 10.2-2.6 13.2-7L452 578.2c3.8-5.5 4.2-12.6 1.1-18.5s-9.2-9.6-15.9-9.6h-32.5l.2-101.4c0-8.3-6.7-15-14.9-15l-69.9.2zM128.6 449c5.5 3.8 12.6 4.2 18.5 1.2 5.9-3.1 9.6-9.2 9.6-15.9v-32.5h101.4c8.2 0 14.9-6.7 14.9-14.9v-69.7c0-8.3-6.7-14.9-14.9-14.9H156.7v-32.5c0-6.6-3.7-12.8-9.6-15.9-5.9-3.1-13-2.7-18.5 1.1L7.1 338.2c-4.3 3-7 7.9-7 13.2 0 5.3 2.6 10.2 6.9 13.2L128.6 449zM578.5 257.7c-5.5-3.8-12.6-4.2-18.5-1.2-5.9 3.1-9.6 9.2-9.6 15.9v32.5H449c-8.2 0-14.9 6.7-14.9 14.9v69.8c0 8.3 6.7 15 15 14.9l101.4-.2v32.5c0 6.6 3.7 12.8 9.6 15.9 5.9 3.1 13 2.7 18.5-1.1l121.7-83.3c4.3-3 7-7.9 7-13.2 0-5.3-2.6-10.2-6.9-13.2l-121.9-84.2z" />
		</g>
	</svg>
);

export default SvgFourExpandArrows;
