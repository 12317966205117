import { addProgramsAction } from "@app/actions/lator";
import { getUnisAndPrograms } from "@app/actions/unis";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "./styles/ChooseProgramsPage.min.css";
import { inject } from "@app/modules";
import { IRLatoriProgram } from "@app/api/latori-programs/helper-schemas";
import api from "@app/api";
import { History } from "history";

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = { history: History } & IStateProps & IDispatchProps;

type ISelectedValue = {
	value: number;
	label: string;
} | null;

interface IState {
	// selectedUnisAndPrograms: Array<{uniId?: number, programId?: number}>;
	selectedUnis: ISelectedValue[];
	selectedPrograms: ISelectedValue[];
}

const arr = new Array(5).fill(0);

class ChoosePrograms extends React.Component<IProps, IState> {
	state: IState = {
		selectedPrograms: [],
		selectedUnis: [],
	};

	getUnis = memoizeOne((unis: IRootState["unis"]["info"]) => {
		if (!unis) return [];
		return unis.map(uni => ({
			value: uni.id,
			label: uni.code + " - " + uni.officialName,
		}));
	});

	componentDidMount() {
		if (!this.props.unis) {
			this.props.getUnisAndPrograms().then();
		}
	}

	onUniSelect = (index: number, selectedValue: ISelectedValue) => {
		const newSelectedUnis = [...this.state.selectedUnis];
		newSelectedUnis[index] = selectedValue;
		const newSelectedPrograms = [...this.state.selectedPrograms];
		delete newSelectedPrograms[index];
		this.setState({
			selectedUnis: newSelectedUnis,
			selectedPrograms: newSelectedPrograms,
		});
	};

	onProgramSelect = (index: number, selectedValue: ISelectedValue) => {
		const newSelectedPrograms = [...this.state.selectedPrograms];
		newSelectedPrograms[index] = selectedValue;
		this.setState({
			selectedPrograms: newSelectedPrograms,
		});
	};

	onSave = () => {
		const selectedPrograms = this.state.selectedPrograms.filter(Boolean);
		if (selectedPrograms.length === 0) {
			alert("აირჩეთ მინიმუმ 1 საბაკალავრო პროგრამა");
			return;
		}
		this.props.addProgramsAction(selectedPrograms.map(e => e!.value));

		triggerEvent(
			{
				category: "Button",
				action: "Lator: choose programs",
				label: "",
				value: selectedPrograms.length,
			},
			{ numSelected: selectedPrograms.length }
		);
	};

	redirect = () => {
		this.props.history.push("/latori/choose-subjects");
	};

	render() {
		if (!this.props.unis) return null;
		const uniOptions = this.getUnis(this.props.unis);
		return (
			<div className="ChooseProgramsContainer">
				<button
					className="latoriPrimaryButton"
					style={{ fontSize: 18 }}
					onClick={this.redirect}
				>
					საგნების თავიდან არჩევა
				</button>
				<h2>
					აირჩიე შენთვის პრიორიტეტული ხუთი უნივერსიტეტი და პროგრამა
				</h2>
				{arr.map((e, index) => (
					<ChooseProgramAndUni
						key={index}
						index={index}
						uniOptions={uniOptions}
						selectedUni={this.state.selectedUnis[index]}
						selectedProgram={this.state.selectedPrograms[index]}
						onUniChange={this.onUniSelect}
						onProgramChange={this.onProgramSelect}
					/>
				))}
				<button className="latoriPrimaryButton" onClick={this.onSave}>
					დასტური
				</button>
			</div>
		);
	}
}

interface IChooseProgramAndUniProps {
	index: number;
	uniOptions: any[];
	selectedUni: ISelectedValue;
	selectedProgram: ISelectedValue;
	onUniChange: (index: number, selectedValue: ISelectedValue) => any;
	onProgramChange: (index: number, selectedValue: ISelectedValue) => any;
}

export const customTheme = theme => ({
	...theme,
	colors: {
		...theme.colors,
		primary75: "#8941f2",
		primary50: "#b080f6",
		primary25: "#d8bffb",
		primary: "#6201ee",
	},
});

export class ChooseProgramAndUni extends React.Component<
	IChooseProgramAndUniProps
> {
	getPrograms = memoizeOne((programs: IRLatoriProgram[], uni_id: number) => {
		if (!programs) return [];
		return programs
			.filter(prog => prog.uni_id === uni_id)
			.map(prog => ({
				value: prog.id,
				label: prog.officialName, // TODO: !!!!prepend program code before name
			}));
	});

	LatoriProgramModel = inject("LatoriProgramModel");
	latoriProgramsController = inject("LatoriProgramsController");

	render() {
		const index = this.props.index;
		let latoriPrograms = this.LatoriProgramModel.getAllSync();
		if (!latoriPrograms || !latoriPrograms.length) {
			this.latoriProgramsController
				.getAll()
				.then(data => (latoriPrograms = data));
		}
		const progOptions = this.getPrograms(
			latoriPrograms,
			this.props.selectedUni
				? (this.props.selectedUni as any).value
				: null
		);
		return (
			<div className="uniAndProgramSelectContainer">
				<div className="num">{index + 1}</div>
				<div className="selects">
					<div>
						<Select
							value={this.props.selectedUni}
							onChange={value =>
								this.props.onUniChange(index, value)
							}
							isSearchable={true}
							options={this.props.uniOptions}
							placeholder="აირჩიეთ უნივერსიტეტი"
							theme={customTheme}
						/>
					</div>
					{this.props.selectedUni && (
						<div>
							<Select
								value={this.props.selectedProgram}
								onChange={value =>
									this.props.onProgramChange(
										index,
										value as ISelectedValue
									)
								}
								isSearchable={true}
								options={progOptions}
								placeholder="აირჩიეთ საბაკალავრო პროგრამა"
								theme={customTheme}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	latoriPrograms: state.latoriPrograms,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
	addProgramsAction,
};

export default connect<IStateProps, IDispatchProps, {}>(
	mapStateToProps,
	mapDispatchToProps as any
)(ChoosePrograms);
