import {
	getProgramsEssentialInfo,
	IGetProgramsEssentialInfo,
} from "@app/actions/programs";
import { viewProgramPage } from "@app/actions/temp";
import { getUnisEssentialInfo, IGetUnisEssentialInfo } from "@app/actions/unis";
import api from "@app/api";
import { IProgramContent, IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import {
	erovnulSubjectNames,
	foreignLangShortNames,
	foreignLanguages,
	getMinScoreFromLimit,
	getSubjectKey,
	isForeignLang,
	_console,
} from "@app/commonJavascript";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import { FormattedMessage } from "@app/utils/locale";
import styled from "@emotion/styled";
import Clear from "@material-ui/icons/Clear";
import Done from "@material-ui/icons/Done";
import { History } from "history";
import * as React from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { match } from "react-router";
import Header from "../Header";
import { RequestConsultationButton } from "../MainPageComponents/UniList";
import requireLoginForAction from "../requireLoginForAction";
import LariImg from "../styles/img/LariSVG";
import SnackNotification, { IVariantType } from "../Widgets/SnackNotification";
import ProgramDescription from "./ProgramDescription";
import "./styles/programPage.min.css";

interface IOwnProps {
	history: History;
	match: match;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	getProgramsEssentialInfo: IGetProgramsEssentialInfo;
	getUnisEssentialInfo: IGetUnisEssentialInfo;
	viewProgramPage: typeof viewProgramPage;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	program?: IRProgram;
	uni?: IRUniversity;
	contents?: IProgramContent[];
	hasRequested: boolean;
	dialogOpen: boolean;
	dialogType: IVariantType;
	dialogMessage: string;
}

const getCellStyle = (i: number, n: number) => {
	switch (i) {
		case 0:
			return { borderBottom: "none" };
		case n - 1:
			return { borderTop: "none" };
		default:
			return { borderTop: "none", borderBottom: "none" };
	}
};

class ProgramPage extends React.Component<IProps, IState, IStateProps> {
	_isMounted: boolean;

	state = {
		dialogOpen: false,
		dialogType: "info",
		dialogMessage: "",
		hasRequested: false,
	} as IState;

	fetchProgram = (program: IRProgram) => {
		this.setState({ program });
		if (typeof this.props.unis === "undefined") {
			this.props
				.getUnisEssentialInfo()
				.then(universities => {
					if (!this._isMounted) return;
					const myUni = universities.find(
						uni => uni.id === program.uni_id
					);
					if (typeof myUni !== "undefined") {
						this.setState({ uni: myUni }, this.recordView);
					} else {
						throw new Error("my uni not found");
					}
				})
				.catch(err => {
					if (!this._isMounted) return;
					_console.error(err);
				});
		} else {
			const myUni = this.props.unis.find(
				uni => uni.id === program.uni_id
			);
			if (typeof myUni !== "undefined") {
				this.setState({ uni: myUni }, this.recordView);
			}
		}
	};

	recordView = () => {
		if (
			!this.props.viewedPrograms ||
			!this.props.viewedPrograms[this.state.program!.id]
		) {
			// unviewed program in this session
			this.props.viewProgramPage(
				this.state.program!.id,
				this.state.uni!.id
			);
		}
	};

	componentDidMount() {
		this._isMounted = true;
		const { urlName } = this.props.match.params as any;
		const locale = getLocale();
		if (this.props.programs) {
			const program = this.props.programs.find(
				prog => prog.urlName === urlName
			);
			if (program) {
				if (locale !== program.locale) this.props.history.push("/");
				if (locale !== "ka")
					api.users
						.getRequestedConsultation({
							uni_id: program.uni_id,
							program_id: program.id,
						})
						.then(data => {
							if (data.request)
								this.setState({ hasRequested: true });
						});
				this.fetchProgram(program);
				this.getProgramById(program.id).then();
				return;
			}
		}
		this.getProgramByUrlName(urlName).then(program => {
			if (!this._isMounted || !program) return;
			if (locale !== "ka")
				api.users
					.getRequestedConsultation({
						uni_id: program.uni_id,
						program_id: program!.id,
					})
					.then(data => {
						if (data.request) this.setState({ hasRequested: true });
					});
			this.fetchProgram(program);
		});
	}

	getProgramById = (program_id: number) => {
		return api.programs
			.getById({
				id: program_id,
				getContents: true,
			})
			.then(data => {
				if (!this._isMounted) return;
				const { contents, ...essentials } = data;
				this.setState({
					program: essentials as IRProgram,
					contents,
				});
				return essentials as IRProgram;
			});
	};
	getProgramByUrlName = (urlName: string) => {
		return api.programs
			.getMany({
				urlName,
				getContents: true,
			})
			.then(data => {
				if (!this._isMounted) return;
				if (!data.length || !data[0])
					throw new Error("program not found");
				const [program] = data;
				const { contents, ...essentials } = program;
				this.setState({
					program: essentials as IRProgram,
					contents,
				});
				return essentials as IRProgram;
			});
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	onConsultationRequest = () => {
		if (!this.state.program) alert("ფაკულტეტი ვერ მოიძებნა");
		else
			requireLoginForAction(() => {
				api.users
					.requestConsultation({
						uni_id: this.state.program!.uni_id,
						program_id: this.state.program!.id,
					})
					.then(() => {
						this.setState({
							dialogOpen: true,
							dialogType: "success",
							dialogMessage: "Request was successfully sent",
							hasRequested: true,
						});
					})
					.catch(e => {
						this.setState({
							dialogOpen: true,
							dialogType: "error",
							dialogMessage: "Request could not be sent",
							hasRequested: false,
						});
					});
			});
	};

	render() {
		if (typeof this.state.program === "undefined") return null;
		if (typeof this.state.uni === "undefined") return null;

		const locale = getLocale();
		const isBottomSectionVisible = locale === "ka";
		return (
			<div className="">
				<Header history={this.props.history} />
				<div className="programPage">
					<ProgramHeader
						program={this.state.program}
						uni={this.state.uni}
						history={this.props.history}
					/>
					<div className="main mainSection">
						{locale === "en" && !this.state.hasRequested && (
							<div
								style={{
									marginTop: 20,
									textAlign: "center",
								}}
							>
								<RequestConsultationButton
									onClick={this.onConsultationRequest}
									style={
										this.state.uni.coverBGColor
											? {
													background: this.state.uni
														.coverBGColor,
											  }
											: undefined
									}
								>
									Request a consultation
								</RequestConsultationButton>
							</div>
						)}
						{locale === "en" && (
							<ProgramInfoTable program={this.state.program} />
						)}
						<SnackNotification
							autoHideDuration={6000}
							onClose={() => this.setState({ dialogOpen: false })}
							message={this.state.dialogMessage}
							variant={this.state.dialogType}
							open={this.state.dialogOpen}
						/>
						{!!this.state.contents ? (
							<React.Fragment>
								<ProgramDescription
									program={this.state.program}
									contents={this.state.contents}
									uni={this.state.uni}
								/>
							</React.Fragment>
						) : (
							<div className="contentLoader">
								<ProgramPageContentLoader />
							</div>
						)}
					</div>
					{!isBottomSectionVisible && <div style={{ height: 30 }} />}
					<div
						className={
							"bottomSection " +
							(!isBottomSectionVisible ? "hidden" : "")
						}
					>
						<div className="main main2">
							{this.state.program.accreditation === true ? (
								<div className="accreditation">
									აკრედიტებულია{" "}
									<span style={{ color: "green " }}>
										<Done />
									</span>
								</div>
							) : (
								<div className="accreditation">
									არააკრედიტებულია{" "}
									<span style={{ color: "red " }}>
										<Clear />
									</span>
								</div>
							)}
							<div className="panels">
								<ProgramSubjectTable
									program={this.state.program}
								/>
								{/* <ProgramLatoriPanel
									history={this.props.history}
									programId={this.state.program.id}
								/> */}
								<div style={{ flex: 4 }}></div>
							</div>
							<div className="yearOfUpdatedInfo">
								ინფორმაცია განახლებულია 2020-2021 სასწავლო წელს
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

interface IProgramPage {
	program: IRProgram;
	uni: IRUniversity;
	history: History;
}

const UniSmallLogo = styled("div")(null, (props: { uni: IRUniversity }) => ({
	backgroundImage: `url(/photos/unilogos/${props.uni.logoUrl})`,
	backgroundColor: props.uni.logoBGColor || "white",
}));

const ProgramHeaderContainer = styled("div")(
	null,
	(props: { uni: IRUniversity }) => ({
		background:
			props.uni.coverBGColor ||
			"linear-gradient(10deg, #FF5246, #FF9646, #FFC246)",
	})
);

const ProgramHeader: React.SFC<IProgramPage> = props => (
	<ProgramHeaderContainer className="headerText" uni={props.uni}>
		<div className="h1Container">
			<UniSmallLogo
				className="uniLogo"
				uni={props.uni}
				onClick={() => props.history.push("/unis/" + props.uni.urlName)}
			/>
			<p className="h1">{props.program.code}</p>
		</div>
		<p className="main h2">{props.program.name}</p>
		<div className="main shortInfo">
			<span
				className="uniName"
				onClick={() => props.history.push("/unis/" + props.uni.urlName)}
			>
				{props.uni.name}
				{/*<UniSmallLogo className="uniLogo" uni={props.uni} />*/}
			</span>
			<span className="uniMoney">
				{props.program.locale === "en" && "$"}
				{props.program.price}
				{props.program.locale === "ka" && (
					<LariImg className="lariLogo" color="white" />
				)}
			</span>
		</div>
	</ProgramHeaderContainer>
);

interface IProgramSubjectTable {
	program: IRProgram;
}

const ProgramSubjectTable: React.FC<IProgramSubjectTable> = props => {
	if (!props.program.coefficients) return null;
	const compulsorySubjects: number[] = props.program.subjects[0];
	const halfCompulsorySubjects: number[] = props.program.subjects[1];
	const additionalHalfCompulsorySubjects: number[] =
		props.program.subjects[2];

	return (
		<div className="tableList">
			<table>
				<tbody>
					<tr>
						<th className="th1">საგნები</th>
						<th className="th2">ადგილები</th>
						<th className="th3">კოეფიციენტი</th>
						{!!props.program.minScoreLimits && (
							<th>
								<span>გამსვლელი ქულა</span>
							</th>
						)}
					</tr>
					{compulsorySubjects.map((subjId, index) => (
						<tr key={subjId}>
							<td className="tableSubjName">
								{erovnulSubjectNames[subjId]}
								{props.program.foreignLangs &&
								isForeignLang(subjId) ? (
									<>
										{" ("}
										{props.program.foreignLangs.map(
											(lang, index2) =>
												foreignLangShortNames[lang] +
												(index2 ===
												props.program.foreignLangs!
													.length -
													1
													? ""
													: " ")
										)}
										{")"}
									</>
								) : (
									""
								)}
							</td>
							<td
								className="numbers"
								style={getCellStyle(
									index,
									compulsorySubjects.length
								)}
							>
								{!halfCompulsorySubjects.length &&
									index ===
										Math.floor(
											(compulsorySubjects.length - 1) / 2
										) &&
									(props.program.totalPlaces === -1 ? (
										<FormattedMessage id="unlimited" />
									) : (
										props.program.totalPlaces
									))}
							</td>
							<td className="numbers">
								{props.program.coefficients![subjId] || ""}
							</td>
							{!!props.program.minScoreLimits && (
								<td className="numbers">
									{getMinScoreFromLimit(
										props.program.minScoreLimits![subjId],
										subjId
									) || ""}
								</td>
							)}
						</tr>
					))}
					{halfCompulsorySubjects.length > 0 && (
						<tr>
							<th className="th4" style={{ fontSize: 16 }}>
								სავალდებულო არჩევითი საგნები
							</th>
							<th className="th4" style={{ fontSize: 16 }}>
								ადგილები საგნების მიხედვით
							</th>
							<th className="th4" />
							{!!props.program.minScoreLimits && (
								<th className="th4" />
							)}
						</tr>
					)}
					{halfCompulsorySubjects.map((subjId, index) => (
						<tr key={subjId}>
							<td className="tableSubjName">
								{erovnulSubjectNames[subjId]}
							</td>
							<td
								className="numbers"
								style={
									additionalHalfCompulsorySubjects.length
										? getCellStyle(
												index,
												compulsorySubjects.length
										  )
										: {}
								}
							>
								{!additionalHalfCompulsorySubjects.length &&
									props.program.numsBySubjs![subjId]}
							</td>
							<td className="numbers">
								{props.program.coefficients![subjId] || ""}
							</td>
							{!!props.program.minScoreLimits && (
								<td className="numbers">
									{getMinScoreFromLimit(
										props.program.minScoreLimits![subjId],
										subjId
									) || ""}
								</td>
							)}
						</tr>
					))}
					{additionalHalfCompulsorySubjects.length > 0 && (
						<tr>
							<th className="th4" style={{ fontSize: 16 }}>
								დამატებითი სავალდებულო არჩევითი საგნები
							</th>
							<th className="th4" style={{ fontSize: 16 }}>
								ადგილები საგნების კომბინაციების მიხედვით
							</th>
							<th className="th4" />
							{!!props.program.minScoreLimits && (
								<th className="th4" />
							)}
						</tr>
					)}
					{additionalHalfCompulsorySubjects.map(subjId => (
						<tr key={subjId}>
							<td className="tableSubjName">
								{erovnulSubjectNames[subjId]}
							</td>
							<td className="numbers">
								{halfCompulsorySubjects.map(subj2Id => (
									<>
										{erovnulSubjectNames[subj2Id] +
											" - " +
											props.program.numsBySubjCombs![
												getSubjectKey([subjId, subj2Id])
											]}
										<br />
									</>
								))}
							</td>
							<td className="numbers">
								{props.program.coefficients![subjId] || ""}
							</td>
							{!!props.program.minScoreLimits && (
								<td className="numbers">
									{props.program.minScoreLimits![subjId]}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const ProgramInfoTable: React.FC<IProgramSubjectTable> = props => {
	const langOfInstruction = foreignLanguages.find(
		lang => lang.id === props.program.languageOfInstruction
	);
	return (
		<div className="tableList" style={{ marginBottom: 20, marginTop: 20 }}>
			<table>
				<tbody>
					<tr>
						{langOfInstruction && <th className="th1">Language</th>}
						<th className="th2">Number of places</th>
						<th className="th3">Accreditation</th>
						<th className="th4">Price (annually)</th>
						<th className="th5">Study duration</th>
					</tr>
					<tr>
						{langOfInstruction && (
							<td className="numbers">
								{langOfInstruction.engName}
							</td>
						)}
						<td className="numbers">
							{props.program.totalPlaces === -1 ? (
								<FormattedMessage id="unlimited" />
							) : (
								props.program.totalPlaces
							)}
						</td>
						<td className="numbers">
							<span style={{ color: "green " }}>
								{props.program.accreditation ? <Done /> : "—"}
							</span>
						</td>
						<td className="numbers">${props.program.price}</td>
						<td className="numbers">
							{props.program.studyDuration} years
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

const ProgramPageContentLoader = () => (
	<div className="fakeProgramContent">
		<ContentLoader width={380} height={100}>
			<rect x="5" y="5" rx="4" ry="4" width="260" height="13" />
			<rect x="5" y="25" rx="4" ry="4" width="230" height="13" />
			<rect x="5" y="45" rx="4" ry="4" width="210" height="13" />
			<rect x="5" y="65" rx="4" ry="4" width="250" height="13" />
			<rect x="285" y="5" rx="4" ry="4" width="70" height="13" />
			<circle cx="320" cy="55" r="30" />
		</ContentLoader>
	</div>
);

const mapStateToProps = (state: IRootState) => ({
	isLoggedIn: !!state.user.loggedIn,
	programs: state.programs.info,
	unis: state.unis.info,
	viewedPrograms: state.temp.viewedPrograms,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		getProgramsEssentialInfo,
		getUnisEssentialInfo,
		viewProgramPage,
	} as any
)(ProgramPage);
