import { getUnisAndPrograms } from "@app/actions/unis";
import api from "@app/api";
import {
	ILiveMeeting,
	IRUniversity,
	SocialMediaTypes,
} from "@app/api/unis/helper-schemas";
import {
	addLoader,
	extractVideoIdFromYoutubeLink,
	YOUTUBE_API_KEY,
} from "@app/commonJavascript";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "../styles/expo-live.min.css";
import {
	ExpoUpcomingLiveBox,
	ExpoOngoingLiveBox,
	ExpoFinishedLiveBox,
} from "./live-box";

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;
type IProps = IStateProps & IDispatchProps;

interface IYoutubeVideoMetadata {
	liveBroadcastContent: "live" | "none" | "upcoming";
	liveStreamingDetails?: {
		actualStartTime?: Date;
		actualEndTime?: Date;
		scheduledStartTime?: Date;
		scheduledEndTime?: Date;
		concurrentViewers?: number;
		activeLiveChatId?: string;
	};
}
export type ILiveMeetingWithMetada = ILiveMeeting & IYoutubeVideoMetadata;

interface IClassifiedLiveMeetings {
	ongoing: ILiveMeeting[];
	today: ILiveMeeting[];
	tomorrow: ILiveMeeting[];
	past: ILiveMeeting[];
}

const ExpoLive: React.FC<IProps> = React.memo(function ExpoLive(props: IProps) {
	const [classifiedLiveMeetings, setClassifiedLiveMeetings] = useState<
		IClassifiedLiveMeetings
	>({
		ongoing: [],
		today: [],
		tomorrow: [],
		past: [],
	});

	const classify = useCallback((liveMeetings: ILiveMeeting[]) => {
		axios
			.get(
				`https://www.googleapis.com/youtube/v3/videos?key=${YOUTUBE_API_KEY}&id=${liveMeetings
					.map(el => extractVideoIdFromYoutubeLink(el.videoURL))
					.join(",")}&part=snippet,liveStreamingDetails`
			)
			.then(response => {
				const classifiedLiveMeetings: IClassifiedLiveMeetings = {
					ongoing: [],
					today: [],
					tomorrow: [],
					past: [],
				};
				const metadataObj = {};
				for (const item of response.data.items) {
					metadataObj[item.id] = item;
				}
				for (const liveMeeting of liveMeetings) {
					const id = extractVideoIdFromYoutubeLink(
						liveMeeting.videoURL
					);
					const metaData = metadataObj[id];
					if (
						metaData &&
						metaData.snippet &&
						metaData.snippet.liveBroadcastContent
					) {
						if (metaData.snippet.liveBroadcastContent === "live") {
							classifiedLiveMeetings.ongoing.push(liveMeeting);
							continue;
						} else if (
							metaData.snippet.liveBroadcastContent === "none"
						) {
							classifiedLiveMeetings.past.push(liveMeeting);
						} else {
							// upcoming
							const startTime = metaData.liveStreamingDetails
								? new Date(
										metaData.liveStreamingDetails.scheduledStartTime
								  ) || liveMeeting.start_time
								: liveMeeting.start_time;
							if (startTime <= new Date()) {
								classifiedLiveMeetings.ongoing.push(
									liveMeeting
								);
								continue;
							}
							const today = new Date();
							today.setHours(23);
							today.setMinutes(59);
							today.setSeconds(59);
							today.setMilliseconds(999);
							const tomorrow = new Date(
								Date.now() + 24 * 60 * 60 * 1000
							);
							tomorrow.setHours(23);
							tomorrow.setMinutes(59);
							tomorrow.setSeconds(59);
							tomorrow.setMilliseconds(999);
							if (startTime <= today)
								classifiedLiveMeetings.today.push({
									...liveMeeting,
									start_time: startTime,
								});
							else if (startTime <= tomorrow)
								classifiedLiveMeetings.tomorrow.push({
									...liveMeeting,
									start_time: startTime,
								});
						}
					}
				}
				setClassifiedLiveMeetings(classifiedLiveMeetings);
			});
	}, []);

	useEffect(() => {
		const removeLoader = addLoader();
		api.unis.getAllLiveMeetings().then(data => {
			classify(
				data.filter(el => el.social_media === SocialMediaTypes.YouTube)
			);
			if (!props.unis) props.getUnisAndPrograms();
			removeLoader();
		});
	}, [props, classify]);

	useEffect(() => {
		const interval = setInterval(() => {
			setClassifiedLiveMeetings(old => ({
				...old,
				today: old.today.filter(live => live.start_time > new Date()),
				ongoing: [
					...old.ongoing,
					...old.today.filter(live => live.start_time <= new Date()),
				],
			}));
		}, 1000);
		return () => clearInterval(interval);
	}, [classify, classifiedLiveMeetings.ongoing, classifiedLiveMeetings.past, classifiedLiveMeetings.today, classifiedLiveMeetings.tomorrow, classifiedLiveMeetings]);

	const unisByUniIds = useMemo(() => {
		const obj: { [uniId: number]: IRUniversity } = {};
		for (const uni of props.unis || []) obj[uni.id] = uni;
		return obj;
	}, [props.unis]);

	const getDateString = (date: Date | number | string) => {
		const dateObj = new Date(date);
		return `${dateObj
			.getDate()
			.toString()
			.padStart(2, "0")}.${dateObj
			.getMonth()
			.toString()
			.padStart(2, "0")}.${dateObj.getFullYear()}`;
	};

	const onLiveEnd = (liveMeeting: ILiveMeeting) => {
		setClassifiedLiveMeetings(old => ({
			...old,
			ongoing: old.ongoing.filter(live => live.id !== liveMeeting.id),
			past: [liveMeeting, ...old.past],
		}));
	};

	return (
		<div className=" main main2 mainLive">
			<h3>LIVE ჩართვები</h3>
			{classifiedLiveMeetings.ongoing.length > 0 && (
				<>
					<div className="liveCategoryLabel">
						<span>მიმდინარე</span>
					</div>
					<div className="livesContainer">
						{classifiedLiveMeetings.ongoing.map(liveMeeting => (
							<ExpoOngoingLiveBox
								key={liveMeeting.id}
								liveMeeting={liveMeeting}
								onEnd={onLiveEnd}
							/>
						))}
					</div>
				</>
			)}
			{classifiedLiveMeetings.today.length > 0 && (
				<>
					<div className="liveCategoryLabel">
						<span>დღეს ({getDateString(Date.now())})</span>
					</div>
					<div className="livesContainer">
						{classifiedLiveMeetings.today.map(liveMeeting => (
							<ExpoUpcomingLiveBox
								key={liveMeeting.id}
								liveMeeting={liveMeeting}
								uni={unisByUniIds[liveMeeting.uni_id]}
							/>
						))}
					</div>
				</>
			)}
			{classifiedLiveMeetings.tomorrow.length > 0 && (
				<>
					<div className="liveCategoryLabel">
						<span>
							ხვალ (
							{getDateString(Date.now() + 24 * 60 * 60 * 1000)})
						</span>
					</div>
					<div className="livesContainer">
						{classifiedLiveMeetings.tomorrow.map(liveMeeting => (
							<ExpoUpcomingLiveBox
								key={liveMeeting.id}
								liveMeeting={liveMeeting}
								uni={unisByUniIds[liveMeeting.uni_id]}
							/>
						))}
					</div>
				</>
			)}
			{classifiedLiveMeetings.past.length > 0 && (
				<>
					<div className="liveCategoryLabel">
						<span>ჩატარებული</span>
					</div>
					<div className="livesContainer">
						{classifiedLiveMeetings.past.map(liveMeeting => (
							<ExpoFinishedLiveBox
								key={liveMeeting.id}
								liveMeeting={liveMeeting}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
});

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
};

export default connect<IStateProps, IDispatchProps, null>(
	mapStateToProps,
	(mapDispatchToProps as unknown) as IDispatchProps
)(ExpoLive);
