import * as React from "react";
import styled from "@emotion/styled";
import { ChromePicker } from "react-color";

const Circle = styled("div")(
	{
		width: 30,
		height: 30,
		position: "absolute",
		border: "2px solid white",
		borderRadius: 50,
		top: "50%",
		transform: "translate(-50%,-50%)",
		cursor: "pointer",
	},
	(props: { bgColor: string; pos: number; isSelected: boolean }) => ({
		background: props.bgColor,
		left: props.pos * 100 + "%",
		boxShadow: props.isSelected ? "white 0 0 1px 3px" : "black 0 0 2px",
	})
);

export interface IGradientColor {
	color: string;
	position: number; // between 0 and 1
}

const castToLinearBackground = (colors: IGradientColor[]): string => {
	return `linear-gradient(10deg, ${colors
		.map(col => col.color + " " + col.position * 100 + "%")
		.join(", ")})`;
};

export { castToLinearBackground };

interface IProps {
	colors: IGradientColor[];
	currentColorIndex: number;
	onColorChange: Function;
	onIndexChange: (index: number) => void;
}

const BackgroundTypeColorPicker: React.SFC<IProps> = props => (
	<div className="pickNewGradientColor">
		<div
			className="colorBar"
			style={{ background: castToLinearBackground(props.colors) }}
		>
			<div style={{ position: "relative", height: 20 }}>
				{props.colors.map((col, index) => (
					<Circle
						key={index}
						bgColor={col.color}
						pos={col.position}
						isSelected={index === props.currentColorIndex}
						onClick={() => props.onIndexChange(index)}
					/>
				))}
			</div>
		</div>
		<div className="colorSketchPicker">
			<ChromePicker
				color={props.colors[props.currentColorIndex].color}
				onChangeComplete={props.onColorChange}
			/>
		</div>
	</div>
);

export default BackgroundTypeColorPicker;
