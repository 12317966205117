import {
	ISubscribeUni,
	IUnsubscribeUni,
	subscribeUni,
	unsubscribeUni,
} from "@app/actions/subscribed-unis";
import { IRUniversity, IUniMainInfo } from "@app/api/unis/helper-schemas";
import { cities } from "@app/commonJavascript";
import requireLoginForAction from "@app/components/requireLoginForAction";
import { getLocale } from "@app/hooks/intl";
import { IRootState } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import {
	FormattedMessage,
	getFormattedMessage,
	isLocaleMessageDefined,
} from "@app/utils/locale";
import styled from "@emotion/styled";
import CloseIcon from "@material-ui/icons/Close";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import * as React from "react";
import { connect } from "react-redux";
import { extractColorsFromGradient } from "../Admins/AdminUniEditPage/UniBackgroundType";
import Popup, { PopupContent } from "../Widgets/Popup";
import TransCircleSVG from "./styles/img/TransCircle";
import "./styles/premiumUni.min.css";

interface IOwnProps {
	mainInfo?: IUniMainInfo;
	essentialInfo: IRUniversity;
	uni_id: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	subscribeUni: ISubscribeUni;
	unsubscribeUni: IUnsubscribeUni;
}

type IPremiumUniHeaderProps = IOwnProps & IStateProps & IDispatchProps;

const PremiumUniHeaderDiv = styled("div")(
	null,
	(props: {
		coverBGColor: IRUniversity["coverBGColor"];
		coverUrl: IRUniversity["coverUrl"];
	}) => ({
		background: props.coverUrl
			? `url(/photos/unicovers/${props.coverUrl})`
			: props.coverBGColor
			? props.coverBGColor
			: `linear-gradient(10deg, #8b2db2, #d54077, #fa7765)`,
		backgroundSize: "cover",
	})
);

class PremiumUniHeader extends React.Component<IPremiumUniHeaderProps> {
	state = {
		isPopupOpen: false,
		isChatOpened: false,
	};

	constructor(props) {
		super(props);
		if (window.location.search === "?contact")
			this.state.isPopupOpen = true;
	}

	onPopupClose = () => {
		this.setState({
			isPopupOpen: false,
		});
	};
	onPopupOpen = () => {
		this.setState({
			isPopupOpen: true,
		});
	};

	openChatroom = () =>
		this.setState({ isChatOpened: !this.state.isChatOpened });

	render() {
		const gradientColors = extractColorsFromGradient(
			this.props.essentialInfo.coverBGColor || ""
		);
		const accentColor = !gradientColors
			? "#e25371"
			: gradientColors[0] || "#e25371";

		let isSubscribed = false;
		if (this.props.subscribedUnis)
			isSubscribed = !!this.props.subscribedUnis[this.props.uni_id];

		return (
			<>
				{this.state.isPopupOpen && (
					<Popup onClose={this.onPopupClose}>
						<PopupContent style={{ padding: 0 }}>
							{this.props.mainInfo && (
								<PremiumUniMainInfo
									{...this.props.mainInfo}
									coverBGColor={
										this.props.essentialInfo.coverBGColor
									}
									cities={this.props.essentialInfo.cities}
									onClose={this.onPopupClose}
								/>
							)}
						</PopupContent>
					</Popup>
				)}
				{/* {this.state.isChatOpened && !!this.props.user && (
					<Provider store={chatStore}>
						<MiniChat
							userId={"u" + this.props.user.murtskuId}
							userName={
								this.props.user.username ||
								`${this.props.user.firstname} ${this.props.user.lastname}`
							}
							uniPicture={
								"/photos/unilogos/" +
								this.props.essentialInfo.logoUrl
							}
							destinationId={"uni" + this.props.uni_id}
							destinationName={this.props.essentialInfo.name}
							onClose={this.openChatroom}
							// unis={this.props.unis}
						/>
					</Provider>
				)}
				{!this.state.isChatOpened && <UpIcon />}
				{!this.state.isChatOpened && !!this.props.user && (
					<Provider store={chatStore}>
						<ShowMiniChat
							userId={"u" + this.props.user.murtskuId}
							uniId={"uni" + this.props.uni_id}
							onClick={this.openChatroom}
						/>
					</Provider>
				)} */}

				<PremiumUniHeaderDiv
					className="header"
					coverBGColor={this.props.essentialInfo.coverBGColor}
					coverUrl={this.props.essentialInfo.coverUrl}
				>
					<div className="headerUniName">
						{this.props.essentialInfo.name}
					</div>
					<div className="subHeaders">
						<div className="aboutUs" onClick={this.onPopupOpen}>
							<FormattedMessage id="uniPage.aboutUs" />
						</div>
						{getLocale() === "ka" && (
							<div
								className="subscribe"
								style={{
									cursor: "pointer",
									color: accentColor,
								}}
							>
								{!isSubscribed ? (
									<div
										onClick={e => {
											e.stopPropagation();
											triggerEvent(
												{
													category: "Button",
													action: "Subscribe uni",
													label: "from uni page",
												},
												{ uni_id: this.props.uni_id }
											);
											requireLoginForAction(() => {
												this.props
													.subscribeUni(
														this.props.uni_id
													)
													.then();
											});
										}}
									>
										<span>
											<FormattedMessage id="uniPage.subscribe" />
										</span>
										<span
											className="symbolPlusInHeader"
											style={{
												backgroundColor: accentColor,
											}}
										>
											&#43;
										</span>
									</div>
								) : (
									<div
										onClick={e => {
											e.stopPropagation();
											requireLoginForAction(() => {
												this.props
													.unsubscribeUni(
														this.props.uni_id
													)
													.then();
											});
										}}
									>
										<span>გამოწერილია</span>
										<span className="subscribeIcon">
											<NotificationsActive
												style={{ fill: accentColor }}
											/>
										</span>
									</div>
								)}
							</div>
						)}
					</div>
					<div className="headerImages">
						<UniLogo
							className="uniLogo"
							logoUrl={this.props.essentialInfo.logoUrl}
							logoBGColor={this.props.essentialInfo.logoBGColor}
						/>
						<div className="transCircle">
							<TransCircleSVG />
						</div>
					</div>
					<div className="leftBottomHeaderCover" />
					<div className="rightBottomHeaderCover" />
				</PremiumUniHeaderDiv>
			</>
		);
	}
}

interface IPremiumUniMainInfo {
	phone: IUniMainInfo["phone"];
	email: IUniMainInfo["email"];
	address: IUniMainInfo["address"];
	web: IUniMainInfo["web"];
	cities: IRUniversity["cities"];
	coverBGColor: string | null;
	onClose: () => void;
}

const PremiumUniMainInfo: React.FC<IPremiumUniMainInfo> = props => (
	<div className="premiumUniMainInfoContainer">
		<div
			className="aboutUs"
			style={{
				background:
					props.coverBGColor ||
					"linear-gradient(to right, #8b2db2, #f67166)",
			}}
		>
			<FormattedMessage id="uniPage.aboutUs" />
			<div className="closingButtonContainer">
				<CloseIcon className="closingButton" onClick={props.onClose} />
			</div>
		</div>
		<div className="premiumUniMainInfo">
			{props.cities && (
				<div className="contactInfoRow">
					<div className="contactInfoColumn1">
						<FormattedMessage id="uniPage.fields.city" />:
					</div>
					<div className="contactInfoColumn2">
						{props.cities
							.map(cityID => {
								const city = cities.find(
									each => each.id === cityID
								);
								if (!city) return "";
								if (
									isLocaleMessageDefined(
										`cities.${city.name}`
									)
								)
									return getFormattedMessage(
										`cities.${city.name}`
									);
								return city.name;
							})
							.join(", ")}
					</div>
				</div>
			)}
			<div className="separator" />
			<div className="contactInfoRow">
				<div className="contactInfoColumn1">
					<FormattedMessage id="uniPage.fields.phone" />:
				</div>
				<div className="contactInfoColumn2">{props.phone}</div>
			</div>
			<div className="separator" />
			<div className="contactInfoRow">
				<div className="contactInfoColumn1">
					<FormattedMessage id="uniPage.fields.email" />:
				</div>
				<div className="contactInfoColumn2">{props.email}</div>
			</div>
			<div className="separator" />
			<div className="contactInfoRow">
				<div className="contactInfoColumn1">
					<FormattedMessage id="uniPage.fields.address" />:
				</div>
				<div className="contactInfoColumn2" style={{ fontSize: 12 }}>
					{props.address}
				</div>
			</div>
			<div className="separator" />
			<div className="contactInfoRow">
				<div className="contactInfoColumn1">
					<FormattedMessage id="uniPage.fields.web" />:
				</div>
				<div className="contactInfoColumn2">
					<a
						href={`http://${props.web}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{props.web}
					</a>
				</div>
			</div>
		</div>
	</div>
);

interface IUniLogo {
	logoUrl: string;
	logoBGColor: null | string;
}
const UniLogo = styled("div")(null, (props: IUniLogo) => ({
	background: `url(/photos/unilogos/${
		props.logoUrl
	}) center center no-repeat, ${props.logoBGColor || "#fff"}`,
	backgroundSize: "80%, 100%",
	zIndex: 3,
	position: "relative",
}));

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	user: state.user.userData,
	subscribedUnis: state.subscribedUnis.subscribed,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	// tslint:disable-next-line:ter-indent
	({
		subscribeUni,
		unsubscribeUni,
	} as any) as IDispatchProps
)(PremiumUniHeader);
