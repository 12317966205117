import * as React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LariImg from "../styles/img/LariSVG";
import NotFavoriteIcon from "@material-ui/icons/FavoriteBorder";
import requireLoginForAction from "@app/components/requireLoginForAction";
import styled from "@emotion/styled";
import triggerEvent from "@app/utils/events";
import { cities, shortenName } from "@app/commonJavascript";
import {
	addFavoriteProgram,
	IAddFavoriteProgram,
	IRemoveFavoriteProgram,
	removeFavoriteProgram,
} from "@app/actions/favorite-programs";
import { connect } from "react-redux";
import { getProgramPict } from "@app/components/Programs/ProgramViews";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "@app/utils/locale";

export const SmallProg = styled("div")(
	null,
	(props: { program: IRProgram }) => ({
		background: `url(${getProgramPict(props.program)})`,
		backgroundSize: "cover",
	})
);

const progNamMaxLength = 75;

interface IOwnProps {
	history: History;
	program: IRProgram;
	uni: IRUniversity;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	addFavoriteProgram: IAddFavoriteProgram;
	removeFavoriteProgram: IRemoveFavoriteProgram;
}

type IProgramCardProps = IOwnProps & IStateProps & IDispatchProps;

const ProgramCard: React.SFC<IProgramCardProps> = props => {
	const myCity = cities.find(city => city.id === props.program.city);
	let isFavorite = false;
	if (props.favorites) isFavorite = !!props.favorites[props.program.id];
	return (
		<div
			className="programListWrapper"
			onClick={() =>
				props.history.push("/programs/" + props.program.urlName)
			}
		>
			<div className="images">
				<img
					className="uniImg"
					src={`/photos/unilogos/${props.uni.logoUrl}`}
					alt={props.uni.name}
					onClick={e => {
						e.stopPropagation();
						props.history.push("/unis/" + props.uni.urlName);
					}}
				/>
				<SmallProg className="uniProg" program={props.program}>
					<div className="programImg" />
				</SmallProg>
			</div>
			<div className="programInfo">
				<div className="programName">
					<Tooltip
						title={
							props.program.name.length > progNamMaxLength ? (
								<span style={{ fontSize: 20 }}>
									{props.program.name}
								</span>
							) : (
								""
							)
						}
						placement="bottom"
					>
						<span>
							{shortenName(props.program.name, progNamMaxLength)}
						</span>
					</Tooltip>
				</div>
				<div className="programCityAndPrice">
					<img
						className="symbolGps"
						src="http://pngimg.com/uploads/gps/gps_PNG12.png" // TODO: download this image and replace with local file url
						alt="location"
					/>
					{myCity && myCity.name}
					<hr />
					<LariImg width={18} height={18} />
					<span className="price">{props.program.price}</span>
					<hr />
				</div>
			</div>
			<div className="heartAndInfo">
				<div className="symbolHeart">
					<div className="fav">
						<div className="VM">
							{isFavorite ? (
								<div
									onClick={e => {
										e.stopPropagation();
										requireLoginForAction(() => {
											props
												.removeFavoriteProgram({
													program_id:
														props.program.id,
													uni_id:
														props.program.uni_id,
												})
												.then();
										});
									}}
								>
									<FavoriteIcon />
								</div>
							) : (
								<div
									onClick={e => {
										e.stopPropagation();
										triggerEvent(
											{
												category: "Button",
												action:
													"Add Program to favorites",
												label: "from search result",
											},
											{ program_id: props.program.id }
										);
										requireLoginForAction(() => {
											props
												.addFavoriteProgram({
													program_id:
														props.program.id,
													uni_id:
														props.program.uni_id,
												})
												.then();
										});
									}}
								>
									<NotFavoriteIcon />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="moreInfo">
					<FormattedMessage id="readMore" />
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IRootState) => ({
	favorites: state.favoritePrograms.favorites,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	// tslint:disable-next-line:ter-indent
	({
		addFavoriteProgram,
		removeFavoriteProgram,
	} as any) as IDispatchProps
)(ProgramCard);
