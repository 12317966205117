/* eslint-disable no-restricted-globals */
import * as React from "react";
import * as uuid from "uuid/v4";
import AddIcon from "@material-ui/icons/Add";
import api from "@app/api";
import ContentLoader from "react-content-loader";
import ContentMedias from "./ContentMedias";
import DeleteIcon from "@material-ui/icons/Delete";
import FancyInput from "../Widgets/FancyInput";
import SnackNotification from "../Widgets/SnackNotification";
import { addLoader } from "@app/commonJavascript";
import { canEditProgramContent } from "@app/permissions";
import { connect } from "react-redux";
import { History } from "history";
import { IProgramContent, IRProgram } from "@app/api/programs/helper-schemas";
import { IRootState } from "@app/reducers/root";
import { IRUser } from "@app/api/users/helper-schemas";
import { match } from "react-router";
import "react-quill/dist/quill.snow.css";
import "@app/components/styles/quillCustomTheme.min.css";
import "./styles/contentEdit.min.css";
import ReactQuill from "react-quill";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string; program_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	loadingInfo: boolean;
	essentialInfo?: IRProgram;
	contents?: (IProgramContent & { newlyCreated?: true })[];
	errorDialogOpen: boolean;
	errorMessage: string;
}

class AdminProgramContentEditPage extends React.Component<IProps, IState> {
	uni_id: number;
	program_id: number;
	_isMounted?: boolean;

	contentChangedInfo: {
		[id: string]: Partial<IProgramContent> & { newlyCreated?: true };
	} = {};
	contentDeletedIds: number[] = [];

	state = {
		loadingInfo: true,
		errorDialogOpen: false,
		errorMessage: "",
	} as IState;

	constructor(props: IProps) {
		super(props);
		const { uni_id, program_id } = props.match.params;
		if (!uni_id || !program_id) {
			return;
		}
		this.uni_id = +uni_id;
		this.program_id = +program_id;
		if (
			!canEditProgramContent(
				this.program_id,
				this.uni_id,
				props.userData.permissions
			)
		) {
			this.props.history.replace("/admin/uni/" + uni_id + "/programs");
			return;
		}
		if (this.props.programs) {
			const program = this.props.programs.find(
				each => each.id === this.program_id
			);
			if (typeof program !== "undefined") {
				this.state.essentialInfo = program;
			}
		}
	}

	getProgram = () => {
		return api.programs.getById({
			id: this.program_id,
			getContents: true,
		});
	};

	componentDidMount = () => {
		this._isMounted = true;
		this.getProgram().then(data => {
			if (!this._isMounted) return;
			const additionalFields = {} as IState;
			const { contents, ...essentials } = data;
			additionalFields.essentialInfo = essentials as IRProgram;
			if (contents)
				additionalFields.contents = contents as IState["contents"];
			this.program_id = essentials.id;
			this.uni_id = essentials.uni_id!;
			this.setState({
				loadingInfo: false,
				...additionalFields,
			});
		});
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	onTextChange = index => text => {
		const id = this.state.contents![index].id;
		if (!this.contentChangedInfo[id]) this.contentChangedInfo[id] = {};
		this.contentChangedInfo[id].text = text;
	};

	onTitleChange = index => e => {
		const id = this.state.contents![index].id;
		if (!this.contentChangedInfo[id]) this.contentChangedInfo[id] = {};
		this.contentChangedInfo[id].title = e.target.value;
	};

	addContent = () => {
		const newContent = {
			id: (uuid() as any) as number,
			galleryMedias: null,
			position:
				this.state.contents!.reduce(
					(prev, now) => Math.max(now.position, prev),
					0
				) + 1,
			text: "",
			title: null,
			program_id: this.program_id,
			newlyCreated: true as true,
		};
		this.setState({
			contents: [
				...this.state.contents!,
				{
					...newContent,
					created_at: new Date(),
					updated_at: new Date(),
				},
			],
		});
		this.contentChangedInfo[newContent.id] = newContent;
		delete this.contentChangedInfo[newContent.id].id;
	};

	deleteContent = index => () => {
		if (confirm("ნამდვილად გსურთ ამოშლა?")) {
			const id = this.state.contents![index].id;
			if (!this.state.contents![index].newlyCreated) {
				this.contentDeletedIds.push(id);
			}
			delete this.contentChangedInfo[id];
			this.setState({
				contents: this.state.contents!.filter(
					content => content.id !== id
				),
			});
		}
	};

	handleErrorDialogClose = () => {
		this.setState({
			errorDialogOpen: false,
		});
	};

	saveProgramContent = () => {
		if (
			Object.keys(this.contentChangedInfo).length === 0 &&
			this.contentDeletedIds.length === 0
		)
			return;
		const removeLoader = addLoader();
		api.programs.content
			.save({
				program_id: this.program_id,
				changedInfo: this.contentChangedInfo,
				deletedIds: this.contentDeletedIds,
			})
			.then(() => {
				removeLoader();
				// this.props.programRemoveInfoFromTemp(this.program_id);
				if (!this._isMounted) return;
				this.props.history.push(
					"/admin/uni/" + this.uni_id + "/programs"
				);
			})
			.catch(err => {
				removeLoader();
				if (!this._isMounted) return;
				if (err.response && Array.isArray(err.response.data)) {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "" + err.response.data[0].errorText, // TODO: translate in Georgian
					});
				} else {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "დაფიქსირდა შეცდომა",
					});
				}
			});
	};

	onGalleryChange = (index: number) => (
		galleryMedia: IProgramContent["galleryMedias"]
	) => {
		const id = this.state.contents![index].id;
		if (this.contentChangedInfo[id] === undefined)
			this.contentChangedInfo[id] = {};
		this.contentChangedInfo[id].galleryMedias = galleryMedia;
	};

	render() {
		if (!this.state.contents) return <AdminProgramAndUniContentLoader />;
		return (
			<div className="contentEditPage">
				{this.state.contents.map((content, index) => (
					<div key={content.id} className="editDiv">
						<button
							className="deleteContent"
							onClick={this.deleteContent(index)}
						>
							<span>ამოშლა</span>
							<DeleteIcon />
						</button>
						<div className="stick lh" />
						<div className="stick lv" />
						<div className="stick rh" />
						<div className="stick rv" />
						<FancyInput
							defaultValue={content.title || ""}
							title="პანელის სახელი"
							onChange={this.onTitleChange(index)}
						/>
						<h1>ტექსტი</h1>
						<div className="QuillContainer quill--white--theme">
							<ReactQuill
								defaultValue={content.text || ""}
								onChange={this.onTextChange(index)}
							/>
						</div>
						<ContentMedias
							galleryMedias={content.galleryMedias}
							onChange={this.onGalleryChange(index)}
							uploadImageFunc={
								api.unis.content.uploadPhotos as any
							}
							galleryPath="gallery"
						/>
					</div>
				))}
				<button className="addContent" onClick={this.addContent}>
					<span>პანელის დამატება</span>
					<AddIcon />
				</button>
				<br />
				<button
					className="adminPrimaryButton"
					onClick={this.saveProgramContent}
				>
					შენახვა
				</button>
				<SnackNotification
					autoHideDuration={6000}
					onClose={this.handleErrorDialogClose}
					message={this.state.errorMessage}
					variant="error"
					open={this.state.errorDialogOpen}
				/>
			</div>
		);
	}
}

export const AdminProgramAndUniContentLoader = () => (
	<div className="uniCard fakeUniContent">
		<ContentLoader width={380} height={370}>
			<rect x="300" y="5" rx="8" ry="8" width="60" height="13" />
			<rect x="30" y="15" rx="4" ry="4" width="80" height="12" />
			<rect x="20" y="33" rx="10" ry="10" width="130" height="18" />
			<rect x="30" y="57" rx="4" ry="4" width="50" height="12" />
			<rect x="20" y="75" rx="10" ry="10" width="330" height="200" />
			<rect x="125" y="290" rx="10" ry="10" width="100" height="18" />
			<rect x="127" y="320" rx="10" ry="10" width="96" height="16" />
		</ContentLoader>
	</div>
);

const mapStateToProps = (state: IRootState) => ({
	programs: state.programs.info,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({} as unknown) as IDispatchProps
)(AdminProgramContentEditPage);
