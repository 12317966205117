import { SocialMediaTypes } from "@app/api/unis/helper-schemas";
import { extractVideoIdFromYoutubeLink } from "@app/commonJavascript";
import React, { CSSProperties, useMemo } from "react";

interface IProps {
	url: string;
	socialMedia: SocialMediaTypes;
	style?: CSSProperties;
	queryString?: string;
	width?: number;
	height?: number;
}
const SocialMediaVideo: React.FC<IProps> = ({
	url,
	socialMedia,
	style,
	queryString,
	width,
	height,
}) => {
	const fullURL = useMemo(() => {
		switch (socialMedia) {
			case SocialMediaTypes.Facebook:
				return `https://www.facebook.com/plugins/video.php?href=${url}${
					queryString ? `&${queryString}` : ""
				}`;
			case SocialMediaTypes.YouTube:
				return `https://www.youtube.com/embed/${extractVideoIdFromYoutubeLink(
					url
				)}${queryString ? `?${queryString}` : ""}`;
			default:
				return `${url}${queryString ? `?${queryString}` : ""}`;
		}
	}, [queryString, socialMedia, url]);

	return (
		<iframe
			src={fullURL}
			title={`${socialMedia} video player`}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			style={style}
			{...(width && { width })}
			{...(height && { height })}
		></iframe>
	);
};

export default SocialMediaVideo;
