import api from "@app/api";
import {
	ILiveMeeting,
	socialMedias,
	SocialMediaTypes,
} from "@app/api/unis/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import { match } from "react-router-dom";
import { CalendarPicker } from "../Widgets/CalendarPicker";
import ClockPicker from "../Widgets/ClockPicker";
import FancyInput from "../Widgets/FancyInput";
import FancySelect from "../Widgets/FancySelect";
import SimpleExpansionPanel from "../Widgets/SimpleExpansionPanel";
import SnackNotification, { IVariantType } from "../Widgets/SnackNotification";
import SocialMediaVideo from "../Widgets/SocialMediaVideo";
import DeleteIcon from "@material-ui/icons/Delete";

const AdminLiveVideosPage: React.FC<{ match: match<{ uni_id: string }> }> = ({
	match,
}) => {
	const [liveMeetings, setLiveMeetings] = useState<ILiveMeeting[]>([]);
	const [add, setAddStatus] = useState<boolean>(false);

	useEffect(() => {
		const removeLoader = addLoader();
		api.unis
			.getLiveMeetings({ uni_id: +match.params.uni_id })
			.then(data => {
				removeLoader();
				setLiveMeetings(data);
			});
	}, [match.params.uni_id]);

	const onAdd = () => setAddStatus(true);

	const onSaveCallback = (newMeeting: ILiveMeeting) => {
		setLiveMeetings(oldMeetings => [...oldMeetings, newMeeting]);
		setAddStatus(false);
	};

	const onDelete = (id?: number) => () => {
		if (id) {
			if (
				window.confirm("ნამდვილად გსურთ არსებული ვიდეოშეხვედრის წაშლა?")
			) {
				const removeLoader = addLoader();
				api.unis.deleteLiveMeetingById({ id }).then(() => {
					setLiveMeetings(old => old.filter(doc => doc.id !== id));
					removeLoader();
				});
			}
		}
		setAddStatus(false);
	};

	return (
		<div className="main">
			<h2 style={{ textAlign: "center" }}>LIVE ვიდეოები</h2>
			{liveMeetings.map(doc => (
				<SingleLiveVideoItem
					key={doc.id}
					liveMeeting={doc}
					uni_id={+match.params.uni_id}
					onDelete={onDelete(doc.id)}
				/>
			))}
			{add ? (
				<SingleLiveVideoItem
					uni_id={+match.params.uni_id}
					onDelete={onDelete()}
					onSaveCallback={onSaveCallback}
				/>
			) : (
				<Center>
					<button
						className="adminPrimaryButton add"
						onClick={onAdd}
						style={{ marginTop: 40 }}
					>
						დამატება
					</button>
				</Center>
			)}
		</div>
	);
};

const SingleLiveVideoItem: React.FC<{
	liveMeeting?: ILiveMeeting;
	uni_id: number;
	onDelete: () => void;
	onSaveCallback?: (newMeeting: ILiveMeeting) => void;
}> = ({ liveMeeting, uni_id, onDelete, onSaveCallback }) => {
	const [title, setTitle] = useState<ILiveMeeting["title"] | null>(
		liveMeeting ? liveMeeting.title : null
	);
	const [socialMedia, setSocialMedia] = useState<
		ILiveMeeting["social_media"]
	>(liveMeeting ? liveMeeting.social_media : SocialMediaTypes.YouTube);
	const [videoURL, setVideoURL] = useState<ILiveMeeting["videoURL"] | null>(
		liveMeeting ? liveMeeting.videoURL : null
	);
	const [zoomURL, setZoomURL] = useState<ILiveMeeting["zoomURL"]>(
		liveMeeting ? liveMeeting.zoomURL : null
	);
	const [startTime, setStartTime] = useState<ILiveMeeting["start_time"]>(
		liveMeeting
			? liveMeeting.start_time
			: new Date(new Date().setSeconds(0))
	);

	const [notificationType, setNotificationType] = useState<IVariantType>();
	const [dialogBoxOpen, setDialogBoxOpen] = useState<boolean>(false);
	const [dialogBoxMessage, setDialogMessage] = useState<string>("");

	const onClockChange = useCallback(
		(unit: "hours" | "minutes", value: number) => {
			switch (unit) {
				case "hours":
					setStartTime(date => {
						const newDate = new Date(date.toString());
						newDate.setHours(value);
						return newDate;
					});
					break;
				case "minutes":
					setStartTime(date => {
						const newDate = new Date(date.toString());
						newDate.setMinutes(value);
						return newDate;
					});
					break;
				default:
					break;
			}
		},
		[]
	);

	const onDateChange = useCallback((date: Date) => {
		setStartTime(oldDate => {
			const newDate = new Date(oldDate.toString());
			newDate.setFullYear(date.getFullYear());
			newDate.setMonth(date.getMonth());
			newDate.setDate(date.getDate());
			return newDate;
		});
	}, []);

	const onSave = () => {
		if (!title) {
			setNotificationType("warning");
			setDialogMessage("მიუთითეთ სათაური");
			setDialogBoxOpen(true);
			return;
		}
		if (!videoURL) {
			setNotificationType("warning");
			setDialogMessage("მიუთითეთ ვიდეოს ლინკი");
			setDialogBoxOpen(true);
			return;
		}
		const removeLoader = addLoader();
		api.unis
			.saveLiveMeeting({
				id: liveMeeting ? liveMeeting.id : undefined,
				uni_id,
				title,
				social_media: socialMedia,
				videoURL,
				zoomURL,
				start_time: startTime,
			})
			.then(data => {
				data.start_time = new Date(data.start_time);
				setNotificationType("success");
				setDialogMessage("ცვლილებები წარმატებით შეინახა");
				setDialogBoxOpen(true);
				if (onSaveCallback) onSaveCallback(data);
				removeLoader();
			})
			.catch(e => {
				setNotificationType("error");
				setDialogMessage("შენახვისას დაფიქსირდა შეცდომა: " + e);
				setDialogBoxOpen(true);
			});
	};

	const handleDialogClose = useCallback(() => setDialogBoxOpen(false), []);
	return (
		<SimpleExpansionPanel title={title || ""}>
			<DeleteButton onClick={onDelete}>
				<span>ამოშლა</span>
				<DeleteIcon />
			</DeleteButton>
			<FancyInput
				defaultValue={title}
				title="სათაური"
				onChange={e => setTitle(e.target.value)}
			/>
			<FancySelect
				defaultValue={socialMedia}
				title="სოციალური მედია"
				items={socialMedias.map(el => ({ id: el, name: el }))}
				onChange={(socialMedia: SocialMediaTypes) =>
					setSocialMedia(socialMedia)
				}
			/>
			<VideoContainer>
				<FancyInput
					defaultValue={videoURL}
					title="LIVE ვიდეოს ლინკი"
					onChange={e => setVideoURL(e.target.value)}
				/>
				<br />
				{videoURL && socialMedia && (
					<SocialMediaVideo
						url={videoURL}
						socialMedia={socialMedia}
					/>
				)}
			</VideoContainer>
			<FancyInput
				defaultValue={zoomURL}
				title="ZOOM-ის ლინკი"
				onChange={e => setZoomURL(e.target.value)}
			/>
			<AdminDateTimePicker>
				<h3>დაწყების დრო</h3>
				<CalendarPicker
					onDateChange={onDateChange}
					start_time={startTime}
				/>
				<ClockPicker
					onClockChange={onClockChange}
					hour={startTime.getHours()}
					minute={startTime.getMinutes()}
				/>
			</AdminDateTimePicker>
			<Center>
				<button
					className="adminPrimaryButton add"
					onClick={onSave}
					style={{ margin: "0 auto" }}
				>
					შენახვა
				</button>
			</Center>
			<SnackNotification
				autoHideDuration={6000}
				onClose={handleDialogClose}
				message={dialogBoxMessage}
				variant={notificationType}
				open={dialogBoxOpen}
			/>
		</SimpleExpansionPanel>
	);
};

const Center = styled.div`
	text-align: center;
`;

const VideoContainer = styled.div`
	display: inline-block;
	vertical-align: top;
	text-align: center;
`;

const AdminDateTimePicker = styled.div`
	margin: 40px;
`;

const DeleteButton = styled.button`
	position: absolute;
	top: 70px;
	right: 5px;
	border: 1px solid #23263e;
	color: white;
	font-family: "RobotoLowerCase";
	background: transparent;
	font-size: 18px;
	padding: 10px 25px;
	border-radius: 30px;
	transition: 0.3s;
	cursor: pointer;

	& > * {
		vertical-align: middle;
	}

	&:hover {
		background: #23263e;
	}
`;

export default AdminLiveVideosPage;
