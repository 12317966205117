import React, { useState } from "react";
import { History } from "history";
import ExpoMainPageHeader from "./expo-main-header";
import ExpoMainMap from "./expo-main-map";
import ExpoMainSearch from "./expo-main-search";
import "../styles/expo-main-page.min.css";
import { UniversityWithCoordinates } from "../hooks/unis-with-coordinates";

export const testunis = ["სხვა უნი", "წრუპ უნი", "ბუზღ უნი", "მაკარ უნი"];
export const testCoordinates: { top: number; left: number }[] = [
	{ top: 690, left: 660 },
	{ top: 820, left: 1490 },
	{ top: 700, left: 100 },
	{ top: 400, left: 650 },
];

const ExpoMainPage: React.FC<{ history: History }> = React.memo(
	function ExpoMainPage({ history }) {
		const [focusedUni, setFocusedUni] = useState<
			UniversityWithCoordinates
		>();

		return (
			<>
				<ExpoMainPageHeader history={history} />
				<div className="main main2 mainexpo">
					<ExpoMainSearch setFocusedUni={x => setFocusedUni(x)} />
					<ExpoMainMap focusedUni={focusedUni} history={history} />
				</div>
			</>
		);
	}
);

export default ExpoMainPage;
