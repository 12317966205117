import api from "@app/api";
import dotenv from "dotenv";
import { initializeConnection } from "@chat-app/socket-functions";
dotenv.config();

const socketURL = process.env.REACT_APP_CHAT_WEBSOCKET_URL || "http://127.0.0.1:8087";
const socketPath = process.env.REACT_APP_CHAT_WEBSOCKET_PATH;

export const handleExpiration = () => {
	api.auth.getChatAccessToken().then((token) => {
		socketConnection(token);
	}).catch((err) => {
		console.log(err);
	});
};

export const handleValidationError = () => {
	// TODO: Handle invalid token
};

export const socketConnection = (token) => {
	try {
		initializeConnection({
			socketServer: socketURL,
			userToken: token,
			options: {
				onTokenExpired: handleExpiration,
				onInvalidToken: handleValidationError,
				path: socketPath
			}
		});
	} catch (e) {
		console.log(e);
	}
};
