import api from "@app/api";
import { _console, funcToPromise } from "@app/commonJavascript";
import {
	CHANGE_UNI_POSITION_COEFFICIENT,
	CHANGE_UNI_SETTINGS,
	LOAD_ONE_UNI_ESSENTIAL_INFO,
	LOAD_UNIS_ESSENTIAL_INFO,
	ADD_NEW_UNI,
} from "../action-types";
import {
	getProgramsEssentialInfo,
	ProgramsActions,
	programsEssentialInfoArray,
} from "./programs";
import { IAGETMany, IAGETUni, IRGETUni } from "@app/api/unis/validators";
import { IRootActions, IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity, IUniMainInfo } from "@app/api/unis/helper-schemas";
import { uniDisplayInfo } from "./temp";
import { ThunkAction } from "redux-thunk";
import { IUniChangedInfo } from "@app/components/Admins/AdminUniEditPage";
import { getLocale } from "@app/hooks/intl";

interface IUnisEssentialInfoArrayAction {
	type: typeof LOAD_UNIS_ESSENTIAL_INFO;
	info: IRUniversity[];
	fullyLoaded: boolean;
	lastUpdate: Date;
}
export const unisEssentialInfoArray = (
	unis: IRUniversity[],
	fullyLoaded = false,
	lastUpdate: Date = new Date()
): IUnisEssentialInfoArrayAction => {
	return {
		type: LOAD_UNIS_ESSENTIAL_INFO,
		info: unis,
		fullyLoaded,
		lastUpdate: lastUpdate || new Date(),
	};
};

export const loadUnisFromStorage = unisEssentialInfoArray;

interface IUni1EssentialAction {
	type: typeof LOAD_ONE_UNI_ESSENTIAL_INFO;
	info: IRUniversity;
	id: IRUniversity["id"];
}
export const uni1Essential = (uni: IRUniversity): IUni1EssentialAction => {
	return {
		type: LOAD_ONE_UNI_ESSENTIAL_INFO,
		info: uni,
		id: uni.id,
	};
};

export type IGetUnisEssentialInfo = () => Promise<IRUniversity[]>;

// tslint:disable-next-line:max-line-length
export const getUnisEssentialInfo = (
	forceUpdate?: boolean
): ThunkAction<
	ReturnType<IGetUnisEssentialInfo>,
	IRootState,
	null,
	UnisActions
> => (dispatch): ReturnType<IGetUnisEssentialInfo> => {
	try {
		if (!forceUpdate && localStorage.unis) {
			const unis = JSON.parse(localStorage.unis);
			if (unis.info && unis.fullyLoaded === true) {
				return funcToPromise(() => {
					dispatch(
						unisEssentialInfoArray(
							unis.info,
							unis.fullyLoaded,
							unis.lastUpdate
						)
					);
					return unis.info;
				}) as ReturnType<IGetUnisEssentialInfo>;
			}
		}
	} catch (e) {
		_console.error(e);
	}
	const locale = getLocale() as "ka" | "en";
	return api.unis.getMany({ locale }).then(data => {
		dispatch(
			unisEssentialInfoArray(data as IRUniversity[], true, new Date())
		);
		return data as IRUniversity[];
	});
};

export type IGetUniById = (data: IAGETUni) => Promise<IRGETUni>;

export const getUniById = (
	sData: IAGETUni
): ThunkAction<ReturnType<IGetUniById>, IRootState, null, IRootActions> => (
	dispatch
): ReturnType<IGetUniById> => {
	return api.unis.getById(sData).then(uni => {
		const { mainInfo, contents, programs, ...essentials } = uni;
		if (essentials) dispatch(uni1Essential(essentials as IRUniversity));
		// tslint:disable-next-line:max-line-length
		if (programs) dispatch(programsEssentialInfoArray(programs, false)); // TODO: instead of false, write uni_id in object
		dispatch(uniDisplayInfo({ ...uni }));
		return uni;
	});
};

export type IGetUniByUrlName = (data: IAGETMany) => Promise<IRGETUni>;

export const getUniByUrlName = (
	sData: IAGETMany
): ThunkAction<Promise<IRGETUni>, IRootState, null, IRootActions> => (
	dispatch
): Promise<IRGETUni> => {
	return api.unis.getMany(sData).then(data => {
		if (!data.length || !data[0]) throw new Error("uni not found");
		const [uni] = data;
		const { mainInfo, contents, programs, ...essentials } = uni;
		if (essentials) dispatch(uni1Essential(essentials as IRUniversity));
		if (programs) dispatch(programsEssentialInfoArray(programs, false));
		dispatch(uniDisplayInfo({ ...uni }));
		return uni as any;
	});
};

interface IGetUnisAndProgramsObj {
	unis: IRUniversity[];
	programs: IRProgram[];
}
export type IGetUnisAndPrograms = () => Promise<IGetUnisAndProgramsObj>;

interface IChangeUniPositionCoefficientAction {
	type: typeof CHANGE_UNI_POSITION_COEFFICIENT;
	uni_id: number;
	newCoefficient: number;
}

export const uniPositionCoefficient = (
	uni_id: number,
	newCoefficient: number
): IChangeUniPositionCoefficientAction => {
	api.unis
		.save({
			id: uni_id,
			uniSettings: {
				pagePositionCoefficient: newCoefficient,
			},
		})
		.then();
	return {
		type: CHANGE_UNI_POSITION_COEFFICIENT,
		uni_id,
		newCoefficient,
	};
};

interface IchangeUniblaaArguments {
	uni_id: number;
	newCoefficient: number;
}
export type IChangeUniPositionCoefficient = (
	data: IchangeUniblaaArguments
) => void;

export const changeUniPositionCoefficient = (
	data: IchangeUniblaaArguments
): ThunkAction<
	ReturnType<IChangeUniPositionCoefficient>,
	IRootState,
	null,
	any
> => (dispatch): ReturnType<IChangeUniPositionCoefficient> => {
	dispatch(uniPositionCoefficient(data.uni_id, data.newCoefficient));
	return;
};

export const getUnisAndPrograms = (
	forceUpdate?: boolean
): ThunkAction<
	ReturnType<IGetUnisAndPrograms>,
	IRootState,
	null,
	UnisActions | ProgramsActions
> => (dispatch, getState, extraArgument): ReturnType<IGetUnisAndPrograms> => {
	return Promise.all([
		getUnisEssentialInfo(forceUpdate)(
			dispatch,
			getState,
			extraArgument
		) as Promise<IRUniversity[]>,
		getProgramsEssentialInfo(forceUpdate)(
			dispatch,
			getState,
			extraArgument
		) as Promise<IRProgram[]>,
	]).then(data => {
		return {
			unis: data[0],
			programs: data[1],
		};
	});
};

export interface IUniChangeSettings {
	type: typeof CHANGE_UNI_SETTINGS;
	uni_id: number;
	info: IUniChangedInfo;
}
export const uniChangeSettings = (
	uni_id: number,
	newUni: IUniChangedInfo
): IUniChangeSettings => {
	return {
		type: CHANGE_UNI_SETTINGS,
		uni_id,
		info: newUni,
	};
};

export interface IAddNewUni {
	type: typeof ADD_NEW_UNI;
	newUni: IRUniversity & IUniMainInfo;
}
export const addNewUni = (newUni: IRUniversity & IUniMainInfo): IAddNewUni => {
	return {
		type: ADD_NEW_UNI,
		newUni,
	};
};
export type UnisActions =
	| IUnisEssentialInfoArrayAction
	| IUni1EssentialAction
	| IChangeUniPositionCoefficientAction
	| IUniChangeSettings
	| IAddNewUni;
