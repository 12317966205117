import {
	IRGETReservations,
	RGETReservationsSchema,
} from "@app/api/events/validators";
import { Requests } from "@app/api";

const usersEvents = {
	getReservations: (): Promise<IRGETReservations> =>
		Requests.send("GET", "/api/users/reservations", {}, null, {
			responseSchema: RGETReservationsSchema,
		}),
};
export default usersEvents;
