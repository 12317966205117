export interface ITOUFaculty {
	label: string;
	value: number;
}

export interface IExamSubjectData {
	subjectId: number;
	score: number;
}

export interface ITOUAvaliableSubject {
	id: number;
	name: string;
	isCompulsory: boolean;
	halfCompulsory?: boolean;
	minScore: number;
	maxScore: number;
}

export const TOUsubjects: ITOUAvaliableSubject[] = [
	{
		id: 1,
		name: "ქართული ენა",
		isCompulsory: true,
		minScore: 17.6,
		maxScore: 70,
	},
	{
		id: 2,
		name: "უცხო ენა",
		isCompulsory: true,
		minScore: 16.1,
		maxScore: 80,
	},
	{
		id: 3,
		name: "მათემატიკა",
		isCompulsory: false,
		halfCompulsory: true,
		minScore: 11.9,
		maxScore: 59,
	},
	{
		id: 4,
		name: "ისტორია",
		isCompulsory: false,
		halfCompulsory: true,
		minScore: 15.1,
		maxScore: 60,
	},
];
