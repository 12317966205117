import { Requests } from "@app/api";
import {
	IAPOSTUserMobile,
	IUserMobile,
	RGetAllUserMobilesSchema,
	UserMobileSchema,
} from "./validators";

const userMobiles = {
	getUserMobile: (): Promise<IUserMobile | null> =>
		Requests.send("GET", "/api/users/mobiles", {}, null, {
			responseSchema: UserMobileSchema.allow(null),
		}),
	saveUserMobile: (data: IAPOSTUserMobile): Promise<IUserMobile> =>
		Requests.send("POST", "/api/users/mobiles", data, null),
	getAll: (): Promise<IUserMobile[]> =>
		Requests.send("GET", "/api/users/mobiles/all", {}, null, {
			responseSchema: RGetAllUserMobilesSchema,
		}),
};
export default userMobiles;
