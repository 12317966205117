import * as React from "react";
import classNames from "classnames";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { green, amber } from "@material-ui/core/colors";

interface IProps {
	onClose: (event: React.SyntheticEvent<any>, reason: string) => void;
	autoHideDuration?: number;
	anchorOrigin?: SnackbarOrigin;
	message: string;
	className?: string;
	variant?: IVariantType;
	open: boolean;
}

export type IVariantType = "success" | "warning" | "error" | "info";

const SnackNotification: React.SFC<IProps> = ({
	onClose,
	autoHideDuration = 4000,
	anchorOrigin = {
		vertical: "bottom",
		horizontal: "left",
	} as SnackbarOrigin,
	className = "",
	message,
	variant = "info",
	open,
}) => (
	<Snackbar
		anchorOrigin={anchorOrigin}
		open={open}
		autoHideDuration={autoHideDuration}
		onClose={onClose}
	>
		<MySnackbarContentWrapper
			variant={variant}
			className={className}
			message={message}
			onClose={onClose}
		/>
	</Snackbar>
);

export default SnackNotification;

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles1 = theme => ({
	success: {
		backgroundColor: green[600],
		marginTop: "15px",
	},
	error: {
		backgroundColor: theme.palette.error.dark,
		marginTop: "15px",
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
		marginTop: "15px",
	},
	warning: {
		backgroundColor: amber[700],
		marginTop: "15px",
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing.unit,
	},
	message: {
		display: "flex",
		alignItems: "center",
	},
});

function MySnackbarContent(props) {
	const { classes, className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];
	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			classes={{
				root: "snack-notification-top-center-style",
			}}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon /> {message}
				</span>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					className={classes.close}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>,
			]}
			{...other}
		/>
	);
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
