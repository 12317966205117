/* eslint-disable no-restricted-globals */
import * as React from "react";
import api from "@app/api";
import ContentLoader from "react-content-loader";
import Delete from "@material-ui/icons/Delete";
import DownIcon from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import fileDownload from "js-file-download";
import { addLoader } from "@app/commonJavascript";
import { connect } from "react-redux";
import { getUniById, IGetUniById } from "@app/actions/unis";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { match } from "react-router";
import Tooltip from "@material-ui/core/Tooltip";
import "./styles/adminPrograms.min.css";
import {
	deleteEventById,
	ILoadEventsByUniId,
	loadEventsByUniId,
} from "@app/actions/temp";
import { canAccessUni, canEditUniSettings } from "@app/permissions";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	getUniById: IGetUniById;
	loadEventsByUniId: ILoadEventsByUniId;
	deleteEventById: typeof deleteEventById;
}

const eventNamemaxLength = 60;
const progShortName = (name: string): string => {
	return name.length <= eventNamemaxLength
		? name
		: name.substr(0, eventNamemaxLength - 2).trim() + "..";
};

type IProps = IOwnProps & IStateProps & IDispatchProps;

class AdminEvents extends React.Component<IProps> {
	uni_id: number;
	_isMounted: boolean;
	uni?: IRUniversity;
	allowDrag = false;

	constructor(props: IProps) {
		super(props);
		this.uni_id = +props.match.params.uni_id;
		if (canAccessUni(this.uni_id, props.userData.permissions)) {
			this.allowDrag = true;
		} else {
			this.props.history.replace("/");
		}
		if (props.unis) {
			const myUni = props.unis.find(uni => uni.id === this.uni_id);
			if (typeof myUni !== "undefined") {
				this.uni = myUni;
			}
		}
	}

	componentDidMount() {
		this._isMounted = true;
		if (!this.props.events_by_unis) {
			this.props.loadEventsByUniId(this.uni_id);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	redirectToEventAddPage = () => {
		this.props.history.push("event/add");
	};

	deleteEvent = (id: number, uni_id: number) => () => {
		if (confirm("ნამდვილად გსურთ ღონისძიების წაშლა?")) {
			api.events.delete({ id, uni_id }).then(() => {
				this.props.deleteEventById(id, uni_id);
			});
		}
	};

	onFileRequest = (eventId: number) => {
		const removeLoader = addLoader();
		api.events
			.getExcelFile({ id: eventId, uni_id: this.uni_id })
			.then(data => {
				removeLoader();
				if (!this._isMounted) return;
				const now = new Date();
				let dateStr = now
					.toLocaleString()
					.replace(",", "")
					.replace(/\//g, "-")
					.replace(/:/g, "-");
				dateStr = dateStr.substr(0, dateStr.length - 3);
				fileDownload(data, `unicatalog - ${dateStr}.xlsx`);
				console.log(typeof data);
			})
			.catch(e => {
				removeLoader();
				if (!this._isMounted) return;
				alert("დაფიქსირდა შეცდომა");
				console.error(e);
			});
	};

	render() {
		if (!this.props.events_by_unis) return null;
		const events = this.props.events_by_unis[this.uni_id];
		if (!events) {
			return (
				<div className="main loaderProgramWrapper">
					<ContentLoader width={400} height={50}>
						<rect
							x="135"
							y="28"
							rx="4"
							ry="4"
							width="100"
							height="12"
						/>
					</ContentLoader>
					<SingleAdminProgramLoader />
					<SingleAdminProgramLoader />
					<SingleAdminProgramLoader />
					<SingleAdminProgramLoader />
				</div>
			);
		}
		return (
			<div>
				<div className="main programWrapper">
					<div className="header">ღონისძიებები</div>
					{events.map((event, index) => (
						<div className="program" key={event.id}>
							<div className="adminSingleItemContainer">
								<div>
									<Tooltip
										title={progShortName(event.title)}
										placement="bottom"
									>
										<span>
											{progShortName(event.title)}
										</span>
									</Tooltip>
								</div>
							</div>
							{canEditUniSettings && this.uni_id !== 57 && (
								<div
									className="penIcon"
									onClick={() => this.onFileRequest(event.id)}
								>
									<DownIcon />
								</div>
							)}
							{canEditUniSettings && (
								<div
									className="penIcon"
									onClick={() =>
										this.props.history.push(
											`event/${event.id}/edit`
										)
									}
								>
									<EditIcon />
								</div>
							)}
							{canEditUniSettings && (
								<div
									className="penIconRed"
									onClick={this.deleteEvent(
										event.id,
										event.uni_id
									)}
								>
									<Delete />
								</div>
							)}
						</div>
					))}
					<button
						className="adminPrimaryButton"
						style={{ width: 300 }}
						onClick={this.redirectToEventAddPage}
					>
						ღონისძიების დამატება
					</button>
				</div>
			</div>
		);
	}
}

const SingleAdminProgramLoader: React.SFC<{}> = props => (
	<ContentLoader width={400} height={35}>
		<circle cx="15" cy="12" r="8" />
		<rect x="30" y="5" rx="10" ry="10" width="317" height="15" />
		<circle cx="365" cy="12" r="10" />
	</ContentLoader>
);

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	events_by_unis: state.temp.events_by_unis,
});

export default connect(mapStateToProps, ({
	getUniById,
	loadEventsByUniId,
	deleteEventById,
} as unknown) as IDispatchProps)(AdminEvents);
