import * as React from "react";
import AllOffersPopup from "./all-offers-popup";
import api from "@app/api";
import cuLogo from "./styles/img/cu-logo.png";
import GlowingBar from "../../sliders/glowing-bar";
import memoizeOne from "memoize-one";
import Popup, { PopupContent } from "@app/components/Widgets/Popup";
import SvgLoading from "@app/components/styles/img/Loading";
import { calculateGrant, IGrant } from "../../functions";
import { connect } from "react-redux";
import {
	cuProgramInfo,
	cuScholarship,
	standard,
	top500,
} from "./scholarship-texts";
import { getUnisAndPrograms } from "@app/actions/unis";
import { GrantLegend } from "../../sliders/grant";
import { grantLimitScores } from "@app/commonJavascript";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IUserScores } from "@app/reducers/lator";
import { PointSVG } from "../../sliders/place-slider";
import "./styles/cu-popup.min.css";

interface IOwnProps {
	programId: number;
	// selectedSubjectId: number;
	electiveSubjects: number[];
	scores: IUserScores;
	contestScaledScore: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	result?: IGrant;
	grantSubjectId?: number;
	studentPlace?: number;
	showPopup: boolean;
}

class CuPopup extends React.Component<IProps, IState> {
	state: IState = {
		showPopup: false,
	};

	calculate = memoizeOne(
		(electiveSubjects: number[], scores: IUserScores) => {
			const results = calculateGrant({
				electiveSubjects,
				rawScores: scores,
			});
			let bestResult = results[electiveSubjects[0]];
			let grantSubjectId = electiveSubjects[0];
			for (let i = 1; i < electiveSubjects.length; i++) {
				const subjId = electiveSubjects[i];
				if (results[subjId].grant > bestResult.grant)
					bestResult = results[subjId];
				grantSubjectId = subjId;
			}
			this.setState({
				result: bestResult,
				grantSubjectId,
			});
		}
	);

	getStyle = memoizeOne(
		(
			electiveSubjects: number[],
			bestResult: IGrant,
			grantSubjectId: number
		) => {
			const maxScore = Math.max(
				bestResult.totalScaledScore,
				grantLimitScores[grantSubjectId][0] + 150
			);
			const minScore = Math.min(
				bestResult.totalScaledScore - 100,
				grantLimitScores[grantSubjectId][2] - 200
			);

			const range = maxScore - minScore;

			const treshold = (40 / range) * 100;

			const grant100 =
				((maxScore - grantLimitScores[grantSubjectId][0]) / range) *
				100;
			const grant70 =
				grant100 +
				((grantLimitScores[grantSubjectId][0] -
					grantLimitScores[grantSubjectId][1]) /
					range) *
					100;
			const grant50 =
				grant70 +
				((grantLimitScores[grantSubjectId][1] -
					grantLimitScores[grantSubjectId][2]) /
					range) *
					100;

			const color100 = "#9c30a4";
			const color70 = "#ec5d6a";
			const color50 = "#f9abb2";
			const color0 = "#ffecee";

			return {
				barStyle: {
					// tslint:disable-next-line:max-line-length
					background: `linear-gradient(to right, ${color100} 0%, ${color100} ${grant100 -
						treshold}%, ${color70} ${grant100 +
						treshold}%, ${color70} ${grant70 -
						treshold}%, ${color50} ${grant70 +
						treshold}%, ${color50} ${grant50 -
						treshold}%, ${color0} ${grant50 +
						treshold}%, ${color0} 100%)`,
				},
				percentages: {
					grant100,
					grant70,
					grant50,
					me:
						((maxScore - bestResult.totalScaledScore) / range) *
						100,
				},
			};
		}
	);

	getStudentPlace = (score: number) => {
		api.lator
			.getPlaceByTotalScore({ score })
			.then(data => {
				this.setState({ studentPlace: data.place });
			})
			.catch(err => console.log(err));
	};

	onClosePopup = () => this.setState({ showPopup: false });

	render() {
		setTimeout(
			() =>
				// this.calculate(this.props.selectedSubjectId, this.props.scores),
				this.calculate(this.props.electiveSubjects, this.props.scores),
			0
		);
		setTimeout(
			() => this.getStudentPlace(this.props.contestScaledScore),
			0
		);

		if (
			!this.state.result ||
			!this.state.studentPlace ||
			!this.state.grantSubjectId
		) {
			return (
				<div className="latoriGrantResultContainer">
					{<SvgLoading />}
				</div>
			);
		}

		const currentProgram =
			this.props.programs !== undefined
				? this.props.programs.find(
						program => program.id === this.props.programId
				  )
				: undefined;

		const { barStyle, percentages } = this.getStyle(
			this.props.electiveSubjects,
			this.state.result,
			this.state.grantSubjectId
		);
		let tuitionFee =
			currentProgram !== undefined ? currentProgram.price / 2 : 2250 / 2;

		let scholarshipInfo;
		if (this.props.contestScaledScore > 2138.4) {
			scholarshipInfo = top500(this.state.studentPlace);
			tuitionFee = 0;
		} else if (percentages.me < percentages.grant70) {
			scholarshipInfo = cuScholarship;
			if (cuProgramInfo[this.props.programId] !== undefined)
				scholarshipInfo.text = cuProgramInfo[this.props.programId].text;
			else scholarshipInfo.text = standard.text;
			if (percentages.me < percentages.grant100) {
				if (cuProgramInfo[this.props.programId])
					tuitionFee =
						cuProgramInfo[this.props.programId].scholarship100;
				else tuitionFee -= 2250 / 2;
			} else {
				if (cuProgramInfo[this.props.programId])
					tuitionFee =
						cuProgramInfo[this.props.programId].scholarship70;
				else tuitionFee -= 1575 / 2;
			}
		} else {
			if (percentages.me < percentages.grant50) tuitionFee -= 562.5;
			scholarshipInfo = standard;
		}

		if (tuitionFee < 0) tuitionFee = 0;

		return (
			<div className="cu-popup">
				<div className="latoriGrantResultContainer scholarship-part">
					<div className="point">
						<div style={{ left: percentages.me + "%" }}>
							<PointSVG backgroundColor="white" />
							<span style={{ color: "#1675bf" }}>მე</span>
						</div>
					</div>
					<GlowingBar style={barStyle} />
					<GrantLegend percentages={percentages} showArrow={false} />
				</div>
				<div className="cu-scholarship-part">
					<div className="info-part">
						<p style={{ textAlign: "center" }}>
							<b>{scholarshipInfo.name}</b>
						</p>
						<p>{scholarshipInfo.text}</p>
						<hr />
						<p>დარჩენილი სემესტრული გადასახდელი: {tuitionFee}ლ</p>
						<br />
						<div className="cu-see-all-button">
							<button
								onClick={() =>
									this.setState({ showPopup: true })
								}
							>
								შემოთავაზებები
							</button>
						</div>
					</div>
					<img className="logo-part" src={cuLogo} alt="CU Logo" />
				</div>
				{this.state.showPopup && (
					<Popup onClose={this.onClosePopup}>
						<PopupContent
							style={{
								padding: "0",
								borderRadius: "10px",
								background: "transparent",
								boxShadow: "none",
							}}
						>
							<AllOffersPopup closePopup={this.onClosePopup} />
						</PopupContent>
					</Popup>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	programs: state.programs.info,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(CuPopup);
