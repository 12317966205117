export const top500 = place => ({
	name: "TOP 500",
	text:
		"ამ ქულებით გასულ წელს მთელი საქართველოს მასშტაბით შენი საკონკურსო ადგილი იქნებოდა " +
		place +
		"." +
		" თუ საკონკურსო ქულის მიხედვით წელსაც მოხვდები 500 საუკეთესოს შორის და კავკასიის უნივერსიტეტის" +
		" ნებისმიერ საგანმანათლებლო პროგრამას პირველ პრიორიტეტად აირჩევ, მაშინ 4 წლის განმავლობაში" +
		" ისწავლი უფასოდ!",
});

export const cuScholarship = {
	name: "CU გრანტი",
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის" +
		" უნივერსიტეტის ბიზნესის სკოლის პროგრამებს, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას, წელიწადში" +
		" 2900 ლარს, 4 წლის განმავლობაში.",
};

export const standard = {
	name: "შიდა დაფინანსება",
	text:
		"კავკასიის უნივერსიტეტი გთავაზობთ სწავლის დაფინანსების შესაძლებლობას.",
};

export const cuProgramInfo = {};
cuProgramInfo[600] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის" +
		" უნივერსიტეტის ტექნოლოგიების სკოლის არქიტექტურის პროგრამას, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას," +
		" წელიწადში 2000 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 1162.5,
	scholarship100: 825,
};
cuProgramInfo[598] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის" +
		" ტექნოლოგიების სკოლის პროგრამებს: ინფორმაციული ტექნოლოგიები, ელექტრონიკა და" +
		" კომპიუტერული ინჟინერია, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას, წელიწადში 500 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 712.5,
	scholarship100: 375,
};
cuProgramInfo[794] = cuProgramInfo[598];
cuProgramInfo[601] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის სახელმწიფო" +
		" მართვის სკოლის პროგრამებს: საერთაშორისო ურთიერთობები და დიპლომატია, საჯარო მმართველობა, მიიღებ შიდა საუნივერსიტეტო" +
		" დაფინანსებას, წელიწადში 2000 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 1162.5,
	scholarship100: 825,
};
cuProgramInfo[602] = cuProgramInfo[601];
cuProgramInfo[606] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის" +
		" ჰუმანიტარულ და სოციალურ მეცნიერებათა სკოლის პროგრამებს: სოცილოგია, ფსიქოლოგია, მიიღებ შიდა საუნივერსიტეტო" +
		" დაფინანსებას, წელიწადში 500 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 712.5,
	scholarship100: 375,
};
cuProgramInfo[607] = cuProgramInfo[606];
cuProgramInfo[609] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის" +
		" უნივერსიტეტის ტურიზმის სკოლის პროგრამას, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას, წელიწადში" +
		" 500 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 712.5,
	scholarship100: 375,
};
cuProgramInfo[593] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის" +
		" მედიცინისა და ჯანდაცვის მენეჯმენტის სკოლის პროგრამას, ჯანდაცვის მენეჯმენტი, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას," +
		" წელიწადში 2000 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 1162.5,
	scholarship100: 825,
};
cuProgramInfo[611] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის" +
		" ეკონომიკის სკოლის პროგრამას, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას, წელიწადში 500 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 712.5,
	scholarship100: 375,
};
cuProgramInfo[591] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის" +
		" ბიზნესის სკოლის პროგრამებს: ბიზნესის ადმინისტრირება, საერთაშორისო ბიზნესი მიიღებ შიდა საუნივერსიტეტო დაფინანსებას," +
		" წელიწადში 2900 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 1512.5,
	scholarship100: 1175,
};
cuProgramInfo[592] = cuProgramInfo[591];
cuProgramInfo[594] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის სამართლის" +
		" სკოლის პროგრამას, მიიღებ შიდა საუნივერსიტეტო დაფინანსებას, წელიწადში 2000 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 1162.5,
	scholarship100: 825,
};
cuProgramInfo[596] = {
	text:
		"თუ მოიპოვებ 100%-იან ან 70%-იან სახელმწიფო გრანტს და პირველ პრიორიტეტად აირჩევ კავკასიის უნივერსიტეტის მედიის" +
		" სკოლის პროგრამებს: ჟურნალისტიკა და მასობრივი კომუნიკაცია, საზოგადოებასთან ურთიერთობა(PR) მიიღებ შიდა საუნივერსიტეტო" +
		" დაფინანსებას, წელიწადში 2000 ლარს, სრული პროგრამის განმავლობაში",
	scholarship70: 1162.5,
	scholarship100: 825,
};
cuProgramInfo[597] = cuProgramInfo[596];
