import * as moment from "moment";
import * as React from "react";
import ChatLeftPart from "../chat-left-part/index";
import MiniChatMessages from "../mini-chat/messages";
import MiniChatTextArea from "../mini-chat/text-area";
import styled from "@emotion/styled";
import {
	addSentMessageInStorage,
	markMessageAsSeenInStorage,
} from "../../actions/chatrooms";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import { IChatroom, IMessage } from "../../reducers/chatrooms";
import { IMessageContainerStyle } from "../mini-chat/message-wrappers";
import { IChatRootState, ToDispatchType } from "../../rootReducer";

interface IOwnProps {
	userId: string;
	uniPicture: string;
	messageContainerStyle?: IMessageContainerStyle;
}
type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	selectedChat: string | null;
	showChat: boolean;
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	border-radius: 20px;
	position: relative;

	@media (max-width: 425px) {
		.show-left-bar {
			display: block;
			width: 100%;
			position: absolute;
			top: 0px;
			left: 0px;
			border-radius: 0;
		}

		.hide-left-bar {
			display: none;
		}

		.show-chat {
			display: flex;
			width: 100%;
			border-radius: 0;
		}

		.hide-chat {
			display: none;
		}
	}
`;

const MessageContainer = styled.div`
	vertical-align: top;
	background-color: white;
	border-radius: 0 20px 20px 0;
	display: inline-flex;
	flex-direction: column;
	width: 67%;
	height: 100%;
	justify-content: space-between;

	h2 {
		text-align: center;
	}
`;

const BackArrowBar = styled.div`
	display: none;
	width: 100%;
	height: 34px;
	padding: 5px 15px;

	@media (max-width: 425px) {
		display: block;
		color: black;
	}
`;

class FullChat extends React.Component<IProps, IState> {
	state: IState = {
		selectedChat: null,
		showChat: true,
	};

	createChatroomArray = (chatroomsInfo: IProps["chatroomsInfo"]) => {
		if (!chatroomsInfo) return undefined;
		const objKeys = Object.keys(chatroomsInfo);
		const chatroomArray: IChatroom[] = [];

		for (let i = 0; i < objKeys.length; i++) {
			if (
				chatroomsInfo[objKeys[i]].participantIds.indexOf(
					this.props.userId
				) >= 0
			) {
				chatroomArray.push(chatroomsInfo[objKeys[i]]);
			}
		}

		chatroomArray.sort((a, b) => {
			if (a.messages.length <= 0 && b.messages.length <= 0) return 0;
			if (a.messages.length <= 0 && b.messages.length > 0) return 1;
			if (a.messages.length > 0 && b.messages.length <= 0) return -1;

			const aTime: moment.Moment = (moment as any)(
				a.messages[a.messages.length - 1].createdAt
			);
			const bTime: moment.Moment = (moment as any)(
				b.messages[b.messages.length - 1].createdAt
			);

			return bTime.unix() - aTime.unix();
		});

		return chatroomArray;
	};

	getUnseenMessages = (messages: IMessage[], userId: string) => {
		const unseenMessageIds: string[] = [];

		for (let i = 0; i < messages.length; i++) {
			if (messages[i].seenBy.indexOf(userId) < 0)
				unseenMessageIds.push(messages[i]._id);
		}

		return unseenMessageIds;
	};

	markMessages = (messageIds: string[]) => {
		if (messageIds.length > 0)
			this.props.markMessagesAsSeen(messageIds, this.props.userId);
	};

	changeChatroom = (chatroomId: string): void => {
		this.markMessages(
			this.getUnseenMessages(
				this.props.chatroomsInfo![chatroomId].messages,
				this.props.userId
			)
		);
		this.setState({ selectedChat: chatroomId, showChat: true });
	};

	sendMessage = messageText => {
		if (this.state.selectedChat === null) return;
		this.props.dispatchMessage(
			this.props.userId,
			this.state.selectedChat,
			messageText
		);
	};

	showChatSelector = () => this.setState({ showChat: false });

	render() {
		const chatroomsArray = this.createChatroomArray(
			this.props.chatroomsInfo
		);
		if (
			!this.props.chatroomsInfo ||
			!chatroomsArray ||
			chatroomsArray.length <= 0
		) {
			return (
				<Container>
					<h3>შეტყობინებები არაა შემოსული</h3>
				</Container>
			);
		}
		if (this.state.selectedChat === null) {
			setTimeout(() => this.changeChatroom(chatroomsArray[0]._id), 0);
		}
		const { selectedChat } = this.state;

		return (
			<Container>
				<ChatLeftPart
					userId={this.props.userId}
					chatroomsInfo={chatroomsArray}
					onChange={this.changeChatroom}
					showBarOnMobile={!this.state.showChat}
				/>
				<MessageContainer
					className={this.state.showChat ? "show-chat" : "hide-chat"}
				>
					<BackArrowBar>
						<ArrowBack
							style={{ verticalAlign: "middle" }}
							onClick={this.showChatSelector}
						/>
						{selectedChat && (
							<span
								style={{
									verticalAlign: "middle",
									marginLeft: "5px",
								}}
							>
								{
									this.props.chatroomsInfo[selectedChat]
										.participantNames[
										this.props.chatroomsInfo[
											selectedChat
										].participantIds.indexOf(
											this.props.userId
										) === 0
											? 1
											: 0
									]
								}
							</span>
						)}
					</BackArrowBar>
					{selectedChat && (
						<React.Fragment>
							<MiniChatMessages
								userId={this.props.userId}
								uniPicture={this.props.uniPicture}
								messages={
									this.props.chatroomsInfo[selectedChat]
										.messages
								}
								participantIds={
									this.props.chatroomsInfo[selectedChat]
										.participantIds
								}
								participantNames={
									this.props.chatroomsInfo[selectedChat]
										.participantNames
								}
								style={
									!this.props.messageContainerStyle
										? undefined
										: {
												messageContainer: {
													userColor: this.props
														.messageContainerStyle
														.userColor,
													otherColor: this.props
														.messageContainerStyle
														.otherColor,
												},
										  }
								}
							/>
							<MiniChatTextArea onSend={this.sendMessage} />
						</React.Fragment>
					)}
					{selectedChat === undefined ||
						(selectedChat === null && <h2>აირჩიეთ ჩატი</h2>)}
				</MessageContainer>
			</Container>
		);
	}

	componentDidMount() {
		if (!this.props.chatroomsInfo) return;
		const chatroomsArray = this.createChatroomArray(
			this.props.chatroomsInfo
		);
		if (chatroomsArray && chatroomsArray[0])
			this.changeChatroom(chatroomsArray[0]._id);
	}
}

const mapStateToProps = (state: IChatRootState) => ({
	chatroomsInfo: state.chatrooms.info,
});

const mapDispatchToProps = {
	dispatchMessage: addSentMessageInStorage,
	markMessagesAsSeen: markMessageAsSeenInStorage,
};

export default connect<IStateProps, IDispatchProps, any>(
	mapStateToProps,
	mapDispatchToProps
)(FullChat);
