import { getUnisEssentialInfo, IGetUnisEssentialInfo } from "@app/actions/unis";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import {
	animateWindowScroll,
	getQueryStringParams,
} from "@app/commonJavascript";
import { IRootState } from "@app/reducers/root";
import triggerEvent from "@app/utils/events";
import { FormattedMessage } from "@app/utils/locale";
import styled from "@emotion/styled";
import { History } from "history";
import memoize from "memoize-one";
import * as React from "react";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { DesktopAd1050 } from "../dev/ads";
import Paginator, { PageNumLoader } from "../Widgets/Paginator";
import { sortUnisByCoeff } from "./search/searchAlgorithms";
import "./styles/UniList.min.css";

interface IOwnProps {
	history: History;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
	getUnisEssentialInfo: IGetUnisEssentialInfo;
}
type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	loading: boolean;
	page: number;
	itemsPerPage: number;
}

class UniGrid extends React.PureComponent<IProps, IState> {
	_isMounted: boolean;
	unsubscribeHistory: Function;
	listContainerRef: HTMLDivElement;

	state = {
		loading: !!this.props.unis,
		page: 0,
		itemsPerPage: 12,
	};

	sortUnis = memoize((unis: IRUniversity[] | undefined) => {
		if (unis && this.state.loading) {
			if (this._isMounted) this.setState({ loading: false });
			else {
				setTimeout(() => {
					this.setState({ loading: false });
				}, 0);
			}
		}
		if (!Array.isArray(unis)) return unis;
		return sortUnisByCoeff(unis);
	});

	componentDidMount = () => {
		this._isMounted = true;
		const searchQueries = getQueryStringParams(
			this.props.history.location.search
		);
		if (+searchQueries.page !== this.state.page + 1) {
			this.setState({
				page: Math.floor(+searchQueries.page) - 1,
				loading: false,
			});
		} else this.setState({ loading: false });
		this.unsubscribeHistory = this.props.history.listen(location => {
			if (!this._isMounted) return;
			const searchQueriess = getQueryStringParams(location.search);
			if (+searchQueriess.page !== this.state.page + 1)
				this.setState({ page: Math.floor(+searchQueriess.page) - 1 });
		});
	};

	componentWillUnmount = () => {
		this._isMounted = false;
		if (this.unsubscribeHistory) this.unsubscribeHistory();
	};

	pageSwitch = page => () => {
		this.setState({ page });
		this.props.history.push(`/?page=${page + 1}`);
		if (!this.listContainerRef) return;

		const element = this.listContainerRef;
		const rect = element.getBoundingClientRect();
		const scrollTop = window.pageYOffset;
		const whereToScrollTop = rect.top + scrollTop - 100;
		animateWindowScroll(whereToScrollTop, 300);

		triggerEvent(
			{
				category: "Button",
				action: "UniList page switch",
				label: "",
				value: page,
			},
			{
				page,
			}
		);
	};

	gotoUni = uniID => (e: React.MouseEvent<any>) => {
		if (!this.props.unis) return;
		const uni = this.props.unis.find(u => u.id === uniID);
		if (!uni) return;

		const pathname = this.props.history.location.pathname;
		triggerEvent(
			{
				category: "Click",
				action: "Goto Uni page",
				label: "from unilist",
			},
			{
				isLogged: this.props.isLogged,
				pathname,
				uni_id: uniID,
			}
		);
		this.props.history.push("/unis/" + uni.urlName);
	};

	render() {
		let { page, itemsPerPage } = this.state;
		if (!Number.isInteger(page)) page = 0;
		const unis = this.sortUnis(this.props.unis);
		if (!unis) itemsPerPage = 0;
		else if (unis.length < page * itemsPerPage) {
			page = Math.floor((unis.length - 1) / itemsPerPage);
		}
		return (
			<div
				className="UniListContainer"
				ref={ref => (this.listContainerRef = ref!)}
			>
				<div className="main main2">
					<h1 className="uniHeaderTitle">
						<FormattedMessage id="universities" />
					</h1>
					<div className="uniCardsGrid">
						{this.state.loading || !unis || !unis
							? Array(6)
									.fill(0)
									.map((e, index) => (
										<UniContentLoader key={index} />
									))
							: unis
									.slice(
										page * itemsPerPage,
										(page + 1) * itemsPerPage
									)
									.map(uni => (
										<UniCard
											key={uni.id}
											uni={uni}
											onClick={this.gotoUni(uni.id)}
										/>
									))}
					</div>
					<DesktopAd1050
						className={"desktop600"}
						style={{ width: "100%", marginTop: 30 }}
					/>
					<div style={{ textAlign: "center", marginTop: 20 }}>
						{this.state.loading || !unis ? (
							<PageNumLoader numOfPages={5} />
						) : (
							<Paginator
								numOfPages={Math.ceil(
									unis.length / itemsPerPage
								)}
								currentPage={page}
								onPageClick={this.pageSwitch}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	isLogged: !!state.user.loggedIn,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	({
		getUnisEssentialInfo,
	} as unknown) as IDispatchProps
)(UniGrid);

const UniContentLoader = () => (
	<div className="uniCard fakeUniContent">
		<ContentLoader width={380} height={150}>
			<circle cx="72.5" cy="75" r="60" />
			<rect x="145" y="0" width="1" height="150" />
			<rect x="156" y="45" rx="4" ry="4" width="213" height="13" />
			<rect x="156" y="68.47" rx="4" ry="4" width="195" height="13" />
			<rect x="156" y="92" rx="4" ry="4" width="180" height="13" />
		</ContentLoader>
	</div>
);

const LogoInCard = styled("div")({}, (props: { bgColor?: string | null }) => ({
	background: props.bgColor || "#fff",
}));

export const RequestConsultationButton = styled.button`
	width: 240px;
	height: 45px;
	border: none;
	border-radius: 10px;
	font-size: 18px;
	background: rgba(178, 178, 178, 0.15);
	cursor: pointer;
	font-weight: bold;
	transition: 0.2s;
	color: white;
	border-radius: 100px;
	:focus {
		outline: none;
	}
	:hover {
		/* background-color: rgba(178, 178, 178, 0.3); */
	}
`;

interface IUniCardProps {
	onClick: (event: React.MouseEvent<any>) => void;
	uni: IRUniversity;
}

const UniCard = (props: IUniCardProps) => (
	<div className="uniCard trueUniContent" onClick={props.onClick}>
		<LogoInCard className="uniCardLogo" bgColor={props.uni.logoBGColor}>
			<div className="VM">
				<img
					src={`/photos/unilogos/${props.uni.logoUrl}`}
					alt={props.uni.name}
				/>
			</div>
		</LogoInCard>
		<div className="uniCardName">
			<div className="VM">{props.uni.name}</div>
		</div>
	</div>
);

export { UniCard };
export { UniListByIds };

interface IUniListByIdsProps {
	history: History;
	uni_ids: number[];
	unis: IRUniversity[];
}

const UniListByIds: React.SFC<IUniListByIdsProps> = props => (
	<React.Fragment>
		{props.uni_ids.map(uni_id => {
			if (!props.unis) return null;
			const uni = props.unis.find(eachUni => eachUni.id === uni_id);
			if (!uni) return null;
			return (
				<UniCard
					key={uni.id}
					uni={uni}
					onClick={() => props.history.push("/unis/" + uni.urlName)}
				/>
			);
		})}
	</React.Fragment>
);
