import { Store } from "redux";
import {
	latoriProgramCRUDActionTypes,
	latoriProgramModelWrapper,
	ILatoriProgramModel,
} from "./models/latori-program";
import { IModel } from "./redux/common/model";
import { IRootActions, IRootState } from "./reducers/root";
import { LatoriProgramsController } from "./api/latori-programs/controller";
import { RequestsType } from "./api";

export const Container = {
	// --------------------------- MODELS ---------------------------
	LatoriProgramModel: {} as ILatoriProgramModel,
	// --------------------------- SERVICES ---------------------------
	// --------------------------- CONTROLLERS ---------------------------
	LatoriProgramsController: {} as LatoriProgramsController,
	// --------------------------- SPECIALS ---------------------------
	rootDispatch: ((() => {}) as any) as (action: IRootActions) => void,
	getAllModels: (): IModel[] => {
		const keys = Object.keys(Container);
		const models: IModel[] = [];
		const modelLength = "Model".length;
		for (const key of keys) {
			const lastIndex = key.lastIndexOf("Model");
			if (lastIndex === key.length - modelLength) {
				models.push((Container[key as any] as any) as IModel);
			}
		}
		return models;
	},
};

export function createModules({
	store,
	request,
}: {
	store: Store<IRootState, IRootActions>;
	request: RequestsType;
}) {
	// --------------------------- SPECIALS ---------------------------
	Container.rootDispatch = store.dispatch;
	// --------------------------- MODELS ---------------------------
	Container.LatoriProgramModel = latoriProgramModelWrapper(
		store,
		latoriProgramCRUDActionTypes
	);
	Container.LatoriProgramModel.initialize();
	// --------------------------- SERVICES ---------------------------
	// --------------------------- CONTROLLERS ---------------------------
	Container.LatoriProgramsController = new LatoriProgramsController(request);
}

export function inject<T extends keyof typeof Container>(
	key: T
): typeof Container[T] {
	return Container[key];
}

(window as any).mainRootContainer = Container;
