import * as React from "react";
import api from "@app/api";
import { connect } from "react-redux";
import { getQueryStringParams } from "@app/commonJavascript";
import { getUniById } from "@app/actions/unis";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

class SMSPage extends React.Component<IProps> {
	searchQueries = getQueryStringParams(this.props.history.location.search);

	componentDidMount() {
		// api.sms.sendSMSafterClick({ userId: +this.searchQueries.userId });
		this.props.getUniById({ id: +this.searchQueries.uniId }).then(data => {
			this.props.history.push(`/unis/${data.urlName}`);
		});
	}

	render() {
		return <div />;
	}
}

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
	getUniById,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(SMSPage);
