import { History } from "history";
import * as React from "react";
import { match } from "react-router-dom";
import Header from "../Header";
import "./styles/lator-page.min.css";

interface IProps {
	history: History;
	match: match;
}

const TouLatorResultFail: React.FC<IProps> = (props: IProps) => (
	<>
		<Header showOnlyLogo history={props.history} />
		<div className="toulator-container">
			<div className="progress-bar">
				<div
					className="progress-ball fail"
					style={{ left: "calc(20%)" }}
				/>
			</div>
			<div className="tou-image fail results-page-div"></div>
			<div className="results-page-text results-page-div">
				<p>
					მართალია, თქვენი შედეგებით ვერ ახერხებთ ეროვნული გამოცდების
					ზღვრის გადალახვას, თუმცა ყველაფრი წინ არის!
					<br />
					<span>
						თავის დროზე შენს მდგომარეობაში ბევრი წარმატებული
						ადამიანი ყოფილა, თუმცა მათ, მიუხედავად ამისა, მაინც
						მოახერხეს მსოფლიოს უკეთესობისკენ შეცვლა!
						<br />
						წინ ბევრი წარმატება გელოდება!
					</span>
				</p>
			</div>
		</div>
	</>
);
export default TouLatorResultFail;
