import * as React from "react";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { IRProgram } from "@app/api/programs/helper-schemas";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { SmallProg } from "../../Programs/ProgramCard";
import { UniLogo } from "../../Universities/FreeUniversityPage";

const Container = styled.div`
	width: 510px;
	padding: 30px;
	margin: 50px auto;
	font-family: "RobotoUpperCase";
	background-color: white;
	border-radius: 15px;
`;

const Title = styled.div`
	font-weight: bold;
	fint-size: 20px;
	padding-bottom: 20px;
	text-align: left;
	color: ${(props: { color?: string }) => props.color || "blue"};
`;

const LogoContainer = styled.div`
	position: relative;
	display: inline-block;
	width: 170px;
	height: 170px;
	border-radius: 5px;
	overflow: hidden;
`;

const InfoContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 30px;
	width: calc(100% - 170px);
	height: 170px;
	vertical-align: top;

	h3 {
		margin: 0;
		font-size: 20px;
		color: black;
	}

	p {
		margin: 0;
		font-size: 20px;
		color: grey;
	}

	div.see-button {
		vertical-align: bottom;
		text-align: center;
	}
`;

const SeeMoreButton = styled.button`
	padding: 13px 35px 10px;
	color: white;
	background-color: ${(props: { bgColor?: string }) =>
		props.bgColor || "blue"};
	border: none;
	border-radius: 150px;
	outline: none;
	font-family: "RobotoUpperCase";
	font-size: 18px;
	cursor: pointer;
`;

interface ISuggestedProgramProps {
	program: IRProgram;
	uni: IRUniversity;
	title?: string;
	titleColor?: string;
	textToShow: string;
	onMainButtonClick: () => void;
	seeMainButtonColor?: string;
}

export const LatoriSuggestedProgram: React.FC<
	ISuggestedProgramProps
> = props => {
	return (
		<Container>
			<Title color={props.titleColor}>{props.title}</Title>
			<Link to={"/programs/" + props.program.urlName}>
				<LogoContainer>
					<SmallProg
						style={{ width: "100%", height: "100%" }}
						program={props.program}
					>
						<Link to={"/unis/" + props.uni.urlName}>
							<UniLogo
								logoUrl={props.uni.logoUrl}
								logoBGColor={props.uni.logoBGColor}
								style={{
									width: "50%",
									height: "50%",
									position: "absolute",
									left: "0",
									bottom: "0",
									borderRadius: "0 5px 0 5px",
								}}
							/>
						</Link>
					</SmallProg>
				</LogoContainer>
			</Link>
			<InfoContainer>
				<div>
					<h3>{props.program.name}</h3>
					<hr />
					<p>{props.textToShow}</p>
				</div>
				<div className="see-button">
					<SeeMoreButton
						onClick={props.onMainButtonClick}
						bgColor={props.seeMainButtonColor}
					>
						ვრცლად
					</SeeMoreButton>
				</div>
			</InfoContainer>
		</Container>
	);
};
LatoriSuggestedProgram.defaultProps = {
	title: "რეკომენდებული ფაკულტეტი",
} as ISuggestedProgramProps;
