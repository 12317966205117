import {
	IAPUTSubscribes,
	IRGETSubscribes,
	IRInsertOrUpdateUniSubscription,
	RGETSubscribes,
} from "./validators";
import { Requests } from "@app/api";

const apiPath = "/api/users";
const usersUnis = {
	getSubscriptions: (): Promise<IRGETSubscribes> =>
		Requests.send("GET", apiPath + "/subscriptions", {}, null, {
			responseSchema: RGETSubscribes,
		}),
	saveSubscribtions: (
		data: IAPUTSubscribes
	): Promise<IRInsertOrUpdateUniSubscription> =>
		Requests.send("PUT", apiPath + "/subscriptions", data, null),
};
export default usersUnis;
