import { Requests } from "@app/api";
import { contentTypeMultipart } from "../consts";
import { IAGETSearch } from "../events/validators";
import {
	IAGETNewsByUniId,
	IRGETNewsByUniId,
	RGETNewsByUniIdSchema,
} from "../news/validators";
import unisContent from "./contents/controller";
import {
	ILiveMeeting,
	ILiveMeetingWithMetadata,
	IUniExpoVideo,
	LiveMeetingSchema,
	UniExpoVideoSchema,
} from "./helper-schemas";
import unisStats from "./stats/controller";
import {
	IADELETEById,
	IAGETLiveMeeting,
	IAGETMany,
	IAGETUni,
	IAGETUniExpoVideo,
	IAGETUniManyPrograms,
	IAPOSTCreateSchema,
	IAPOSTLiveMeeting,
	IAPOSTUniExoVideo,
	IAPOSTUniView,
	IAPOSTUpload,
	IAPUTSave,
	IRGETMany,
	IRGETUni,
	IRGETUniManyEvents,
	IRGETUniManyPrograms,
	IRPOSTUniView,
	IRPOSTUpload,
	IRPUTSave,
	RGETLiveMeetingsWithMetadata,
	RGETManySchema,
	RGETUniManyEventsSchema,
	RGETUniManyProgramsSchema,
	RGETUniSchema,
	RPOSTLiveMeetingSchema,
	RPOSTUploadSchema,
	RPUTSaveSchema,
} from "./validators";

const unis = {
	stats: unisStats,
	content: unisContent,
	getMany: (data: IAGETMany): Promise<IRGETMany> =>
		Requests.send("GET", "/api/unis", data, null, {
			responseSchema: RGETManySchema,
		}),
	getById: (data: IAGETUni): Promise<IRGETUni> =>
		Requests.send("GET", "/api/unis/:id", data, null, {
			responseSchema: RGETUniSchema,
		}),
	recordView: (data: IAPOSTUniView): Promise<IRPOSTUniView> =>
		Requests.send("POST", "/api/unis/:uni_id/views", data, null),
	getNews: (data: IAGETNewsByUniId): Promise<IRGETNewsByUniId> =>
		Requests.send("GET", "/api/unis/:uni_id/news", data, null, {
			responseSchema: RGETNewsByUniIdSchema,
		}),
	getEvents: (data: IAGETSearch): Promise<IRGETUniManyEvents> =>
		Requests.send("GET", "/api/unis/:uni_id/events", data, null, {
			responseSchema: RGETUniManyEventsSchema,
		}),
	getPrograms: (data: IAGETUniManyPrograms): Promise<IRGETUniManyPrograms> =>
		Requests.send("GET", "/api/unis/:uni_id/programs", data, null, {
			responseSchema: RGETUniManyProgramsSchema,
		}),
	uploadPhotos: (data: IAPOSTUpload): Promise<IRPOSTUpload> =>
		Requests.send("POST", "/api/unis/photos", data, contentTypeMultipart, {
			responseSchema: RPOSTUploadSchema,
		}),
	save: (data: IAPUTSave): Promise<IRPUTSave> =>
		Requests.send("PUT", "/api/unis/:id", data, null, {
			responseSchema: RPUTSaveSchema,
		}),
	create: (data: IAPOSTCreateSchema): Promise<IRPUTSave> =>
		Requests.send("POST", "/api/unis/create", data, null, {
			responseSchema: RPUTSaveSchema,
		}),
	saveLiveMeeting: (data: IAPOSTLiveMeeting): Promise<ILiveMeeting> =>
		Requests.send("POST", "/api/unis/live-meeting", data, null, {
			responseSchema: LiveMeetingSchema,
		}),
	getAllLiveMeetings: (): Promise<ILiveMeeting[]> =>
		Requests.send("GET", "/api/unis/live-meetings/all", {}, null, {
			responseSchema: RPOSTLiveMeetingSchema,
		}),
	getAllOngoingLiveMeetings: (): Promise<ILiveMeetingWithMetadata[]> =>
		Requests.send("GET", "/api/unis/ongoing-live-meetings/all", {}, null, {
			responseSchema: RGETLiveMeetingsWithMetadata,
		}),
	getLiveMeetings: (data: IAGETLiveMeeting): Promise<ILiveMeeting[]> =>
		Requests.send("GET", "/api/unis/:uni_id/live-meetings/", data, null, {
			responseSchema: RPOSTLiveMeetingSchema,
		}),
	deleteLiveMeetingById: (data: IADELETEById): Promise<void> =>
		Requests.send("DELETE", "/api/unis/:id/live-meeting", data, null),
	saveUniExpoVideo: (data: IAPOSTUniExoVideo): Promise<IUniExpoVideo> =>
		Requests.send("PUT", "/api/unis/:uni_id/uni-expo-video", data, null, {
			responseSchema: UniExpoVideoSchema,
		}),
	getUniExpoVideoByUniId: (
		data: IAGETUniExpoVideo
	): Promise<IUniExpoVideo | null> =>
		Requests.send("GET", "/api/unis/:uni_id/uni-expo-video", data, null),
	deleteUniExpoVideoById: (data: IADELETEById): Promise<void> =>
		Requests.send("DELETE", "/api/unis/:id/uni-expo-video", data, null),
};
export default unis;
