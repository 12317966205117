import { Requests } from "..";
import userActions from "./actions/controller";
import userMobiles from "./mobiles/controller";
import usersEvents from "./events/controllet";
import usersPrograms from "./programs/controller";
import usersSubjects from "./subjects/controller";
import usersUnis from "./unis/controller";
import {
	IAGetSearch,
	IAPOSTContractRenewal,
	IAPOSTMiniPopupRejection,
	IAPOSTSaveSearchInfo,
	IAPUTActivateAdmins,
	IAPutUserPermissions,
	IAPUTValidateMobile,
	IAPUTValidateMobileCode,
	IRGETAllAdmins,
	IRGetSearch,
	IRPOSTSaveSearchInfo,
	IRPUTSuccess,
	IRPutUserPermissions,
	REGETAllStudentsPhoneNumbersSchema,
	RGETAllAdminsSchema,
	RGetSearchSchema,
	RPUTSuccessSchema,
	IAGETActivationStatusForAdmin,
	RGETActivationStatusForAdminSchema,
	IAPUTUserMobile,
	IAGETRequestConsultation,
	IAPUTProfile,
} from "./validators";
import {
	ConsultationRequestSchema,
	IConsultationRequest,
} from "./helper-schemas";
import Joi from "@app/utils/joi";

const users = {
	programs: usersPrograms,
	subjects: usersSubjects,
	unis: usersUnis,
	events: usersEvents,
	userActions: userActions,
	userMobiles: userMobiles,
	search: (data: IAGetSearch): Promise<IRGetSearch> =>
		Requests.send("GET", "/api/users/", data, null, {
			responseSchema: RGetSearchSchema,
		}),
	upsertAdminPermissions: (
		data: IAPutUserPermissions
	): Promise<IRPutUserPermissions> =>
		Requests.send("PUT", "/api/users/admins/", data, null),
	rejectMiniPopup: (data: IAPOSTMiniPopupRejection): Promise<void> =>
		Requests.send("POST", "/api/users/mini-popup-rejections/", data, null),
	checkIfMobValid: (): Promise<{ isValidated: boolean }> =>
		Requests.send("GET", "/api/users/check-if-mob-valid/"),
	validateMobile: (data: IAPUTValidateMobile): Promise<void> =>
		Requests.send("PUT", "/api/users/validate-mobile/", data, null),
	validateMobileCode: (data: IAPUTValidateMobileCode): Promise<void> =>
		Requests.send("PUT", "/api/users/validate-mobile-code/", data, null),
	saveSearchStats: (
		data: IAPOSTSaveSearchInfo
	): Promise<IRPOSTSaveSearchInfo> =>
		Requests.send("PUT", "/api/users/v/save-search-info/", data, null),
	contractRenewal: (data: IAPOSTContractRenewal): Promise<void> =>
		Requests.send("POST", "/api/users/contract-renewal", data, null),
	getAllStudentsPhoneNumbers: () =>
		Requests.send(
			"GET",
			"/api/users/get-all-students-phone-numbers",
			{},
			null,
			{ responseSchema: REGETAllStudentsPhoneNumbersSchema }
		),
	getAllAdmins: (): Promise<IRGETAllAdmins> =>
		Requests.send("GET", "/api/users/all-admins", {}, null, {
			responseSchema: RGETAllAdminsSchema,
		}),
	activateAdmins: (data: IAPUTActivateAdmins): Promise<IRPUTSuccess> =>
		Requests.send("PUT", "/api/users/activate-admins", data, null, {
			responseSchema: RPUTSuccessSchema,
		}),
	getActivationStatusForAdmin: (data: IAGETActivationStatusForAdmin) =>
		Requests.send(
			"GET",
			"/api/users/:userId/activation-status",
			data,
			null,
			{
				responseSchema: RGETActivationStatusForAdminSchema,
			}
		),

	getMedicineStudents: () =>
		Requests.send("GET", "api/users/get-medicine-students"),
	getMedicineStudents2: () =>
		Requests.send("GET", "api/users/get-medicine-students2"),

	updateUserMobile: (data: IAPUTUserMobile) =>
		Requests.send("PUT", "/api/users/put-user-mobile", data),

	requestConsultation: (data: IAGETRequestConsultation) =>
		Requests.send("POST", "/api/users/request-consultation", data),

	getRequestedConsultation: (
		data: IAGETRequestConsultation
	): Promise<{ request: IConsultationRequest | null }> =>
		Requests.send("GET", "/api/users/consultation-request", data, null, {
			responseSchema: Joi.object().keys({
				request: ConsultationRequestSchema.allow(null).required(),
			}),
		}),
	getAllConsultationRequests: (): Promise<{ count: number }> =>
		Requests.send("GET", "/api/users/consultation-requests/all", {}, null, {
			responseSchema: Joi.object()
				.keys({
					count: Joi.number()
						.integer()
						.required(),
				})
				.required(),
		}),
	exportUserRequests: (): Promise<any> =>
		Requests.send(
			"GET",
			"/api/users/export-user-requests",
			{},
			{
				responseType: "blob",
			}
		),
	updateProfile: (data: IAPUTProfile): Promise<void> =>
		Requests.send("PUT", "/api/users/update-profile", data),
};
export default users;
