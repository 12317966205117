import Joi, { SchemaToType } from "@app/utils/joi";
import { RSMSGroupSchema } from "../helper-schemas";

/* GetSMSGroupInfo */
export const RGETSMSGroupInfoSchema = Joi.object().keys({
	text: Joi.string().required(),
	success: Joi.number().required(),
	failures: Joi.number().required(),
	total_number: Joi.number().required(),
});

export type IRGETSMSGroupInfo = SchemaToType<typeof RGETSMSGroupInfoSchema>;

export const AGETSMSGroupInfoSchema = Joi.object().keys({
	uni_id: Joi.number().required(),
	sms_group_id: Joi.number().required(),
});

export type IAGETSMSGroupInfo = SchemaToType<typeof AGETSMSGroupInfoSchema>;

/* GetSMSGroups */
export const RGETSMSGroupsSchema = Joi.object().keys({
	smsGroups: Joi.array()
		.items(RSMSGroupSchema)
		.required(),
});

export type IRGETSMSGroups = SchemaToType<typeof RGETSMSGroupsSchema>;

export const AGETSMSGroupsSchema = Joi.object().keys({
	uni_id: Joi.number().required(),
});

export type IAGETSMSGroups = SchemaToType<typeof AGETSMSGroupsSchema>;

/* PostSendSMS */
export const APOSTSendSMSSchema = Joi.object().keys({
	uni_id: Joi.number()
		.integer()
		.required(),
	sender_id: Joi.number()
		.integer()
		.required(),
	text: Joi.string().required(),
	numbers: Joi.array()
		.items(Joi.string())
		.required(),
});

export type IAPOSTSendSMS = SchemaToType<typeof APOSTSendSMSSchema>;

export const RPOSTSendSMSSchema = Joi.object().keys({
	success: Joi.boolean()
		.required()
		.valid(true),
	sendingPhoneNumbers: Joi.number().required(),
	smsGroupId: Joi.number().required(),
});

export type IRPOSTSendSMS = SchemaToType<typeof RPOSTSendSMSSchema>;
