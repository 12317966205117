import * as Color from "color";
import * as React from "react";
import AvatarEditor from "react-avatar-editor";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
	bgColor?: string;
	width: number;
	height: number;
	borderRadius: number;
	border: number;
	image: File;
	onSubmit: (imageBlob: Blob) => void;
	onCancel: () => void;
}

interface IState {
	zoom: number;
	cropLoading: boolean;
}

class ImageCrop extends React.Component<IProps, IState> {
	state: IState = {
		zoom: 1.25,
		cropLoading: false,
	};
	uploadRef: React.RefObject<AvatarEditor> = React.createRef();

	onZoomScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			zoom: +e.target.value,
		});
	};

	getImage = (): Promise<Blob> => {
		return new Promise((resolve, reject) => {
			if (!this.uploadRef.current) {
				reject();
				return;
			}
			const canvas = this.uploadRef.current.getImage();
			canvas.toBlob(blob => {
				if (blob === null) {
					reject();
				} else {
					resolve(blob);
				}
			});
		});
	};
	uploadLogo = () => {
		this.setState({
			cropLoading: true,
		});
		this.getImage().then(this.props.onSubmit);
	};
	render() {
		const color = Color(this.props.bgColor || "#fff");
		return (
			<div>
				<CloseIcon onClick={this.props.onCancel} />
				<br />
				<AvatarEditor
					ref={this.uploadRef}
					image={this.props.image}
					borderRadius={this.props.borderRadius}
					width={this.props.width}
					height={this.props.height}
					border={this.props.border}
					color={color
						.rgb()
						.alpha(0.5)
						.array()}
					style={{ background: this.props.bgColor || "#fff" }}
					scale={this.state.zoom}
					rotate={0}
				/>
				<br />
				<input
					type="range"
					step={0.01}
					min={1}
					max={5}
					value={this.state.zoom}
					onChange={this.onZoomScaleChange}
				/>
				<br />
				{this.state.cropLoading ? (
					"იტვირთება..."
				) : (
					<button
						onClick={this.uploadLogo}
						className="adminPrimaryButton"
					>
						სურათის შეცვლა
					</button>
				)}
			</div>
		);
	}
}

export default ImageCrop;
