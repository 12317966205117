import { useWindowSize } from "@app/commonJavascript";
import { useLocale } from "@app/hooks/intl";
import React from "react";
import "./styles/index.min.css";

const commonStyles =
	"<style>body{margin: 0; text-align: center;} image, img{display: block; width: 100% !important; height: 100% !important;}</style>";

const useAreAdsVisible = () => {
	const locale = useLocale();
	return locale === "ka";
};

// export const DesktopAd800 = React.memo<{
// 	style?: React.CSSProperties;
// 	className?: string;
// }>(function DesktopAd({ style, className }) {
// 	const areAdsVisible = useAreAdsVisible();
// 	if (!areAdsVisible) return null;
// 	return (
// 		<iframe
// 			title="tvvvv"
// 			src={
// 				"data:text/html;charset=utf-8," +
// 				encodeURI(desktopTwixAd800) +
// 				commonStyles
// 			}
// 			className={"ad " + (className || "")}
// 			style={style}
// 		></iframe>
// 	);
// });

export const DesktopAd800 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	const areAdsVisible = useAreAdsVisible();
	const handleClick = () => {
		window.open("https://cavea.plus/ka");
	};
	if (!areAdsVisible) return null;
	return (
		<a>
			<img
				title="tvvvv"
				src={"https://iili.io/HJzet3u.jpg"}
				className={"ad " + (className || "")}
				style={style}
				onClick={handleClick}
			/>
		</a>
	);
});

export const DesktopAd1050 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	const windowSize = useWindowSize();
	const areAdsVisible = useAreAdsVisible();
	if (!areAdsVisible) return null;
	return (
		<iframe
			title="tvvvv"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(desktopTwixAd1050) +
				commonStyles
			}
			className={"ad " + (className || "")}
			style={{
				...style,
				height: (Math.min(1200, windowSize.width) * 110) / 1050,
			}}
		/>
	);
});

export const NewDesktopAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function DesktopAd({ style, className }) {
	const windowSize = useWindowSize();
	const areAdsVisible = useAreAdsVisible();
	if (!areAdsVisible) return null;
	return (
		<iframe
			title="tvvvv"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(newDesktopTwix) +
				commonStyles
			}
			className={"ad " + (className || "")}
			style={{
				...style,
				height:
					(Math.min(
						style ? Number(style.width) || 1000 : 1000,
						windowSize.width
					) *
						90) /
					728,
			}}
		/>
	);
});

export const Ad2 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function Ad({ style, className }) {
	const areAdsVisible = useAreAdsVisible();
	if (!areAdsVisible) return null;
	return (
		<iframe
			title="tvvvv"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(Banner2) +
				commonStyles
			}
			className={"ad " + (className || "")}
			style={style}
		/>
	);
});

export const MobileAd170 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function MobileAd({ style, className }) {
	const areAdsVisible = useAreAdsVisible();
	if (!areAdsVisible) return null;
	return (
		<iframe
			title="tvvvv2"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(mobileTwixAd170) +
				commonStyles
			}
			className={"ad " + (className || "")}
			style={style}
		/>
	);
});

export const MobileAd240 = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function MobileAd({ style, className }) {
	const areAdsVisible = useAreAdsVisible();
	if (!areAdsVisible) return null;
	return (
		<iframe
			title="tvvvv2"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(mobileTwixAd240) +
				commonStyles
			}
			className={"ad " + (className || "") + " mobile"}
			style={style}
		/>
	);
});

export const NewMobileAd = React.memo<{
	style?: React.CSSProperties;
	className?: string;
}>(function MobileAd({ style, className }) {
	const areAdsVisible = useAreAdsVisible();
	if (!areAdsVisible) return null;
	return (
		<iframe
			title="tvvvv2"
			src={
				"data:text/html;charset=utf-8," +
				encodeURI(newMobileAd) +
				commonStyles
			}
			className={"ad " + (className || "") + " mobile"}
			style={style}
		/>
	);
});

const desktopTwixAd800 = `
<!-- Desktop 800X120 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc468035 = window.plc468035 || 0;
document.write('<'+'div id="placement_468035_'+plc468035+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 468035, [800,120], 'placement_468035_'+opt.place, opt); }, opt: { place: plc468035++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const desktopTwixAd1050 = `<!-- Desktop 1050X110 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc468036 = window.plc468036 || 0;
document.write('<'+'div id="placement_468036_'+plc468036+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 468036, [1050,110], 'placement_468036_'+opt.place, opt); }, opt: { place: plc468036++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const newDesktopTwix = `<!-- New Home Desktop [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483995 = window.plc483995 || 0;
document.write('<'+'div id="placement_483995_'+plc483995+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483995, [728,90], 'placement_483995_'+opt.place, opt); }, opt: { place: plc483995++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>
`;

const mobileTwixAd170 = `<!-- Mobile 320X170 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc468038 = window.plc468038 || 0;
document.write('<'+'div id="placement_468038_'+plc468038+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 468038, [320,170], 'placement_468038_'+opt.place, opt); }, opt: { place: plc468038++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const mobileTwixAd240 = `<!-- Mobile 320X240 [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc468037 = window.plc468037 || 0;
document.write('<'+'div id="placement_468037_'+plc468037+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 468037, [320,240], 'placement_468037_'+opt.place, opt); }, opt: { place: plc468037++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;

const newMobileAd = `<!-- New Home Mobile [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc483996 = window.plc483996 || 0;
document.write('<'+'div id="placement_483996_'+plc483996+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 483996, [300,250], 'placement_483996_'+opt.place, opt); }, opt: { place: plc483996++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>
`;

const Banner2 = `
<!-- Desktop Home [async] -->
<script type="text/javascript">if (!window.AdButler){(function(){var s = document.createElement("script"); s.async = true; s.type = "text/javascript";s.src = 'https://servedbyadbutler.com/app.js';var n = document.getElementsByTagName("script")[0]; n.parentNode.insertBefore(s, n);}());}</script>
<script type="text/javascript">
var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];
var abkw = window.abkw || '';
var plc474771 = window.plc474771 || 0;
document.write('<'+'div id="placement_474771_'+plc474771+'"></'+'div>');
AdButler.ads.push({handler: function(opt){ AdButler.register(180004, 474771, [728,90], 'placement_474771_'+opt.place, opt); }, opt: { place: plc474771++, keywords: abkw, domain: 'servedbyadbutler.com', click:'CLICK_MACRO_PLACEHOLDER' }});
</script>`;
