import { ILogin, IRegister, login, register } from "@app/actions/user";
import api from "@app/api";
import { IAPOSTLogin } from "@app/api/auth/validators";
import { UserTypes } from "@app/api/helpher-schemas";
import {
	COUNTRIES_LIST,
	USER_TYPES,
	USER_TYPE_GEO_TITLES,
	_console,
} from "@app/commonJavascript";
import LoadingSVG from "@app/components/styles/img/LoadingWhite";
import { getLocale } from "@app/hooks/intl";
import triggerEvent from "@app/utils/events";
import { FormattedMessage } from "@app/utils/locale";
import styled from "@emotion/styled";
import { Checkbox } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "./en-login-popup.min.css";
import LoginPopup from "./LoginPopup";
import Popup from "./Widgets/Popup";

interface IOwnProps {
	onClose: (loggedInSuccessfully: boolean) => void;
	onSuccess?: Function;
}

type IStateProps = null;

enum ModeTypes {
	Registration = "Registration",
	Login = "Login",
	Reset = "Reset",
	MurtskuLogin = "MurtskuLogin",
}

interface IDispatchProps {
	login: ILogin;
	register: IRegister;
}

interface IErrorType {
	fullName?: boolean;
	mail?: boolean;
	mobile?: boolean;
	country?: boolean;
	password?: boolean;
	userType?: boolean;
}

interface IState {
	fullName: string;
	mail: string;
	mobile: string;
	emailOrMobile: string;
	userType?: UserTypes;
	country: string;
	password: string;
	agreedOnTerms: boolean;
	signLoading: boolean;
	errors: IErrorType;
	mode: ModeTypes;
	errText: string;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

class AuthPopup extends React.Component<IProps> {
	_isMounted: boolean;
	state = {
		fullName: "",
		mail: "",
		mobile: "",
		emailOrMobile: "",
		password: "",
		errText: "",
		country: getLocale() === "ka" ? "GE" : "",
		agreedOnTerms: false,
		signLoading: false,
		errors: {},
		mode: ModeTypes.Login,
	} as IState;
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	onInputFieldChange = fieldName => e => {
		this.setState({
			[fieldName]: e.target.value,
			errors: {
				password: false,
				mail: false,
				mobile: false,
				country: false,
				fullName: false,
				userType: false,
			},
		});
	};

	onEmailOrMobileChange = e => {
		const val = e.target.value;
		if (isNaN(val)) {
			this.setState({
				mobile: "",
				mail: val,
			});
		} else {
			this.setState({
				mobile: val,
				mail: "",
			});
		}
	};

	onCountrySelect = option => {
		this.setState({
			country: option.value,
			errors: {
				password: false,
				mail: false,
				mobile: false,
				country: false,
				fullName: false,
				userType: false,
			},
		});
	};

	onUserTypeSelect = option => {
		this.setState({
			userType: option.value,
			errors: {
				password: false,
				mail: false,
				mobile: false,
				country: false,
				fullName: false,
				userType: false,
			},
		});
	};

	onAgreeTermsToggle = () => {
		this.setState({ agreedOnTerms: !this.state.agreedOnTerms });
	};

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			this.onLoginClick();
		}
	};

	onRegister = () => {
		const locale = getLocale();
		if (!this.state.agreedOnTerms) {
			const msg =
				locale === "ka"
					? "გთხოვთ, წაიკითხოთ და დაეთანხმოთ მოხმარების წესებსა და პირობებს"
					: "You need to read and agree with the Terms and Conditions";
			alert(msg);
			return;
		}
		if (
			!this.state.mail ||
			!this.state.mobile ||
			!this.state.fullName ||
			!this.state.password ||
			!this.state.country ||
			(!this.state.userType && locale === "ka")
		) {
			const msg =
				locale === "ka"
					? "გთხოვთ, შეავსოთ ყველა ველი"
					: "You need to fill all the fields";
			alert(msg);
			return;
		}
		if (locale !== "ka" && !this.checkPassword(this.state.password)) {
			alert(
				"Choose a unique password with minimum 8 characters. Include at least 1 number, a mix of lowercase and uppercase letters and 1 symbol to make your password strong"
			);
			return;
		}
		this.props
			.register({
				fullName: this.state.fullName,
				mobile: this.state.mobile,
				mail: this.state.mail,
				password: this.state.password,
				country: this.state.country,
				userType: this.state.userType,
			})
			.then(data => {
				this.props.onClose(true);
				if (this.props.onSuccess) this.props.onSuccess();
			})
			.catch(err => {
				_console.error(err);
				if (!this._isMounted) {
					return;
				}
				const errors: IErrorType = {};
				if (err.response && err.response.data) {
					if (err.response.data.mail) {
						this.setState({ errText: err.response.data.mail });
						errors.mail = true;
					}
					if (err.response.data.mobile) {
						this.setState({ errText: err.response.data.mobile });
						errors.mobile = true;
					}
					if (err.response.data.password) {
						this.setState({ errText: err.response.data.password });
						errors.password = true;
					}
					if (err.response.data.name) {
						this.setState({ errText: err.response.data.name });
						errors.fullName = true;
					}
				}
				this.setState({
					errors,
					signLoading: false,
				});
				triggerEvent(
					{
						category: "Popup",
						action: "Registration error",
						label: "from popup",
					},
					(err.response || {}).data
				);
			});
	};

	checkPassword = (password: string): boolean => {
		if (password.length < 8) return false;
		let hasNumber = false;
		let hasLowerCase = false;
		let hasUpperCase = false;
		let hasSymbol = false;
		const symbols = `~\`!@#$%^&*()_-+={[}]|\\:;"'<,>.?/"`;
		for (let i = 0; i < password.length; i++) {
			if (!isNaN(+password[i])) hasNumber = true;
			if (password[i] >= "a" && password[i] <= "z") hasLowerCase = true;
			if (password[i] >= "A" && password[i] <= "Z") hasUpperCase = true;
			if (password[i] >= "A" && password[i] <= "Z") hasUpperCase = true;
			if (symbols.indexOf(password[i]) >= 0) hasSymbol = true;
		}
		return hasNumber && hasLowerCase && hasUpperCase && hasSymbol;
	};

	onLoginClick = () => {
		if (this.state.signLoading) {
			return;
		}
		if (!this.state.emailOrMobile || !this.state.password) {
			this.setState({
				errors: {
					mail: !this.state.emailOrMobile,
					mobile: !this.state.emailOrMobile,
					password: !this.state.password,
				},
			});
			return;
		}
		this.setState({
			signLoading: true,
		});
		const whereObj: IAPOSTLogin = {
			password: this.state.password,
			mail: undefined,
			mobile: undefined,
		};
		if (isNaN(+this.state.emailOrMobile))
			whereObj.mail = this.state.emailOrMobile;
		else whereObj.mobile = +this.state.emailOrMobile;
		this.props
			.login(whereObj)
			.then(data => {
				if (!this._isMounted) {
					return;
				}
				triggerEvent({
					category: "Popup",
					action: "Login",
					label: "from popup",
				});
				this.setState({
					signLoading: false,
				});
				this.props.onClose(true);
				if (this.props.onSuccess) this.props.onSuccess();
			})
			.catch(err => {
				_console.error(err);
				if (!this._isMounted) {
					return;
				}
				const errors: IErrorType = {};
				if (err.response && err.response.data) {
					if (err.response.data.mail) {
						errors.mail = true;
					}
					if (err.response.data.mobile) {
						errors.mobile = true;
					}
					if (err.response.data.password) {
						errors.password = true;
					}
				}
				this.setState({
					errors,
					signLoading: false,
				});
				triggerEvent(
					{
						category: "Popup",
						action: "Login error",
						label: "from popup",
					},
					(err.response || {}).data
				);
			});
	};

	onMurtskuRegisterClick = () => {
		triggerEvent({
			category: "Button",
			action: "Registration Click",
			label: "from popup",
		});
		window.open("https://murtsku.com/index.php?reg", "__blank");
	};

	onMurtskuResetPasswdClick = () => {
		triggerEvent({
			category: "Button",
			action: "Reset Password Click",
			label: "from popup",
		});
		window.open("https://murtsku.com/index.php?reset", "__blank");
	};

	onWhatIsMurtskuClick = () => {
		window.open("https://murtsku.com/", "__blank");
	};

	onModeChange = (mode: ModeTypes) => () => {
		this.setState({ mode });
	};

	onResetPassword = () => {
		const locale = getLocale();
		api.auth
			.resetPassword(
				locale === "ka"
					? { mobile: this.state.mobile }
					: { mail: this.state.mail }
			)
			.then(resp => {
				this.props.onClose(false);
			})
			.catch(err => {
				const txt1 =
					locale !== "ka"
						? "Could not issue the request."
						: "მოთხოვნა ვერ გაიგზავნა";
				const txt2 =
					locale !== "ka"
						? "\nMake sure your email is correct"
						: "\nდარწმუნდით, რომ თქვენი მობილურის ნომერი სწორადაა შეყვანილი";
				if (err.response && err.response.data && err.response.data.src)
					alert(txt1 + " " + txt2);
				else alert(txt1);
			});
	};

	onMurtskuLogin = () => {
		this.setState({ mode: ModeTypes.MurtskuLogin });
	};

	render() {
		let errorText = "";
		const locale = getLocale();
		if (this.state.fullName && this.state.errors.fullName) {
			errorText =
				locale === "ka"
					? "გთხოვთ, სწორად შეიყვანოთ სახელი და გვარი"
					: "Please, enter both first name and last name";
		} else if (
			this.state.mail &&
			this.state.errors.mail &&
			!this.state.errors.password
		) {
			errorText = locale === "ka" ? "ელფოსტა არასწორია" : "Wrong email";
		} else if (
			this.state.password &&
			this.state.errors.password &&
			!this.state.errors.mail
		) {
			errorText = locale === "ka" ? "პაროლი არასწორია" : "Wrong password";
		} else if (this.state.errors.mail || this.state.errors.password) {
			errorText =
				locale === "ka"
					? "ელფოსტა ან პაროლი არასწორია"
					: "Wrong email or password";
		}
		if (this.state.errText) errorText = this.state.errText;
		if (this.state.mode === ModeTypes.MurtskuLogin)
			return <LoginPopup onClose={() => this.props.onClose(false)} />;

		const userTypeOptions = USER_TYPES.map(userType => ({
			label: USER_TYPE_GEO_TITLES[userType],
			value: userType,
		}));

		return (
			<Popup onClose={() => this.props.onClose(false)}>
				<div className="loginPopupContent">
					<div className="Header">
						<LoginButton
							style={
								this.state.mode === ModeTypes.Registration
									? {
											backgroundColor:
												"rgba(178, 178, 178, 0.3)",
									  }
									: undefined
							}
							onClick={this.onModeChange(ModeTypes.Registration)}
						>
							<FormattedMessage id="authFields.registration" />
						</LoginButton>
						<Margin10 />
						<LoginButton
							style={
								this.state.mode === ModeTypes.Login
									? {
											backgroundColor:
												"rgba(178, 178, 178, 0.3)",
									  }
									: undefined
							}
							onClick={this.onModeChange(ModeTypes.Login)}
						>
							<FormattedMessage id="authFields.login" />
						</LoginButton>
					</div>
					<div className="Body">
						<div className="Input">
							{this.state.mode === ModeTypes.Registration && (
								<>
									<FormattedMessage id="authFields.country">
										{formattedValue => (
											<Select
												className="login-select"
												classNamePrefix="select"
												isSearchable={true}
												name="color"
												options={COUNTRIES_LIST.map(
													country => ({
														label: country.name,
														value: country.code,
													})
												)}
												onChange={this.onCountrySelect}
												placeholder={String(
													formattedValue
												)}
												{...(locale === "ka"
													? {
															defaultValue: {
																label:
																	"საქართველო",
																value: "GE",
															},
													  }
													: {})}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id="authFields.fullName">
										{formattedValue => (
											<input
												type="text"
												name="name"
												className={classNames({
													"no-arrows": true,
													incorrect: !!this.state
														.errors.mail,
												})}
												value={this.state.fullName}
												onChange={this.onInputFieldChange(
													"fullName"
												)}
												onKeyPress={this.onKeyPress}
												placeholder={String(
													formattedValue
												)}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id="authFields.email">
										{formattedValue => (
											<input
												type="text"
												name="email"
												className={classNames({
													"no-arrows": true,
													incorrect: !!this.state
														.errors.mail,
												})}
												value={this.state.mail}
												onChange={this.onInputFieldChange(
													"mail"
												)}
												onKeyPress={this.onKeyPress}
												placeholder={String(
													formattedValue
												)}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id="authFields.mobile">
										{formattedValue => (
											<input
												type="number"
												name="mobile"
												className={classNames({
													"no-arrows": true,
													incorrect: !!this.state
														.errors.mobile,
												})}
												value={this.state.mobile}
												onChange={this.onInputFieldChange(
													"mobile"
												)}
												onKeyPress={this.onKeyPress}
												placeholder={String(
													formattedValue
												)}
											/>
										)}
									</FormattedMessage>
									{locale === "ka" && (
										<Select
											className="login-select"
											classNamePrefix="select"
											isSearchable={false}
											name="color"
											options={userTypeOptions}
											onChange={this.onUserTypeSelect}
											placeholder="ვინ ხართ?"
										/>
									)}
								</>
							)}
							{this.state.mode === ModeTypes.Login && (
								<div className="Buttons" style={{ margin: 0 }}>
									{locale === "ka" && (
										<button onClick={this.onMurtskuLogin}>
											მურწყუთი ავტორიზაცია
										</button>
									)}
									<FormattedMessage id="authFields.emailMobile">
										{formattedValue => (
											<input
												type="text"
												name="email/mobile"
												className={classNames({
													"no-arrows": true,
													incorrect: !!this.state
														.errors.mail,
												})}
												value={this.state.emailOrMobile}
												onChange={this.onInputFieldChange(
													"emailOrMobile"
												)}
												onKeyPress={this.onKeyPress}
												placeholder={String(
													formattedValue
												)}
											/>
										)}
									</FormattedMessage>
								</div>
							)}
							{(this.state.mode === ModeTypes.Registration ||
								this.state.mode === ModeTypes.Login) && (
								<FormattedMessage id="authFields.password">
									{formattedValue => (
										<input
											type="password"
											className={classNames({
												incorrect: !!this.state.errors
													.password,
											})}
											value={this.state.password}
											onChange={this.onInputFieldChange(
												"password"
											)}
											onKeyPress={this.onKeyPress}
											placeholder={String(formattedValue)}
										/>
									)}
								</FormattedMessage>
							)}
							{this.state.mode === ModeTypes.Registration &&
								locale !== "ka" && (
									<PasswordInstruction>
										*{" "}
										<FormattedMessage id="authFields.passwordInstructions" />
									</PasswordInstruction>
								)}
							{this.state.mode === ModeTypes.Reset &&
								(locale === "ka" ? (
									<>
										<ResetInstruction>
											*{" "}
											<FormattedMessage id="authFields.resetPasswordMobile" />
										</ResetInstruction>
										<FormattedMessage id="authFields.mobile">
											{formattedValue => (
												<input
													type="number"
													name="mobile"
													className={classNames({
														"no-arrows": true,
														incorrect: !!this.state
															.errors.mail,
													})}
													value={this.state.mobile}
													onChange={
														this
															.onEmailOrMobileChange
													}
													onKeyPress={this.onKeyPress}
													placeholder={String(
														formattedValue
													)}
												/>
											)}
										</FormattedMessage>
									</>
								) : (
									<>
										<ResetInstruction>
											*{" "}
											<FormattedMessage id="authFields.resetPasswordEmail" />
										</ResetInstruction>
										<FormattedMessage id="authFields.email">
											{formattedValue => (
												<input
													type="text"
													name="email"
													className={classNames({
														"no-arrows": true,
														incorrect: !!this.state
															.errors.mail,
													})}
													value={this.state.mail}
													onChange={
														this
															.onEmailOrMobileChange
													}
													onKeyPress={this.onKeyPress}
													placeholder={String(
														formattedValue
													)}
												/>
											)}
										</FormattedMessage>
									</>
								))}
							{this.state.mode === ModeTypes.Registration && (
								<AgreeOnTermsDiv>
									<div
										style={{
											display: "table-cell",
											verticalAlign: "top",
										}}
									>
										<Checkbox
											style={{
												padding: 0,
												verticalAlign: "top",
												color: "white",
											}}
											checked={this.state.agreedOnTerms}
											onClick={this.onAgreeTermsToggle}
										/>
									</div>
									<AgreeText>
										<FormattedMessage id="authFields.agree" />{" "}
										<HyperLink
											href="/terms-of-use"
											target="_blank"
										>
											<FormattedMessage id="authFields.terms" />
										</HyperLink>
									</AgreeText>
								</AgreeOnTermsDiv>
							)}
						</div>
						{errorText && <span>{errorText}</span>}
						<div className="Buttons">
							{this.state.mode === ModeTypes.Login && (
								<>
									<button onClick={this.onLoginClick}>
										<span>
											<FormattedMessage id="authFields.login" />
										</span>
										{this.state.signLoading && (
											<LoadingSVG
												width={25}
												height={25}
											/>
										)}
									</button>
									<button
										onClick={this.onModeChange(
											ModeTypes.Reset
										)}
									>
										<FormattedMessage id="authFields.resetPassword" />
									</button>
								</>
							)}
							{this.state.mode === ModeTypes.Registration && (
								<button onClick={this.onRegister}>
									<FormattedMessage id="authFields.registration" />
								</button>
							)}
							{this.state.mode === ModeTypes.Reset && (
								<button onClick={this.onResetPassword}>
									<FormattedMessage id="authFields.reset" />
								</button>
							)}
						</div>
					</div>
				</div>
			</Popup>
		);
	}
}

const Margin10 = styled.div`
	display: inline-block;
	width: 10px;
	height: 1px;
`;

const LoginButton = styled.button`
	font-family: "RobotoUpperCase";
	width: 130px;
	height: 45px;
	border: none;
	border-radius: 10px;
	font-size: 16px;
	background-color: rgba(178, 178, 178, 0.15);
	cursor: pointer;
	font-weight: bold;
	transition: 0.2s;
	color: white;
	:focus {
		outline: none;
	}
	:hover {
		/* background-color: rgba(178, 178, 178, 0.3); */
	}
`;

const AgreeOnTermsDiv = styled.div`
	display: table;
	margin: 10px 0px;

	input {
		margin: 0 !important;
	}
`;

const AgreeText = styled.div`
	display: table-cell;
	font-size: 16px;
	padding-left: 10px;
	vertical-align: top;
	text-align: left;
`;

const HyperLink = styled.a`
	text-decoration: underline;
	color: white;
`;

const PasswordInstruction = styled.div`
	width: 100%;
	font-size: 10px;
`;

const ResetInstruction = styled.div`
	width: 100%;
	font-size: 13px;
`;

export default connect<IStateProps, IDispatchProps, IOwnProps>(null, ({
	login,
	register,
} as any) as IDispatchProps)(AuthPopup);
