import * as React from "react";
import * as ReactDOM from "react-dom";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { IRUser } from "./api/users/helper-schemas";
import { useEffect, useState } from "react";
import memoizeOne from "memoize-one";
import { UserTypes } from "./api/helpher-schemas";

export function addLoader() {
	const loaderDiv = document.createElement("div");
	loaderDiv.setAttribute("class", "loaderDiv");
	ReactDOM.render(
		<div className="loaderChild" />,
		document.body.appendChild(loaderDiv)
	);
	return () => {
		if (
			typeof loaderDiv.parentNode !== "undefined" &&
			loaderDiv.parentNode !== null
		) {
			loaderDiv.parentNode.removeChild(loaderDiv);
		}
	};
}

function toGeorgianOrdinalNumber(num: number, mode?: 0 | 1 | 2): string {
	// რიგითობაში
	if (num === 1) {
		if (typeof mode === "undefined") return "I";
		if (mode === 0) return "1-ლი";
		if (mode === 1) return "1-ელ";
		return "1-ელმა";
	}
	if (
		num <= 20 ||
		(num <= 100 && num % 20 === 0) ||
		(num <= 1000 && num % 100 === 0)
	)
		return "მე-" + num;
	return num + "-ე";
}

export { toGeorgianOrdinalNumber };

const _console: { log: Function; error: Function } = {
	log: (...args) => {
		// tslint:disable-next-line
		console.log(...args);
	},
	error: (...args) => {
		// tslint:disable-next-line
		console.error(...args);
	},
};
export { _console };

const weekDaysGeo: string[] = [
	"ორშაბათი",
	"სამშაბათი",
	"ოთხშაბათი",
	"ხუთშაბათი",
	"პარასკევი",
	"შაბათი",
	"კვირა",
];
const weekDaysAbbrGeo: string[] = [
	"ორშ",
	"სამ",
	"ოთხ",
	"ხუთ",
	"პარ",
	"შაბ",
	"კვ",
];
// tslint:disable-next-line
const weekDaysGeoNatesaobiti: string[] = [
	"ორშაბათის",
	"სამშაბათის",
	"ოთხშაბათის",
	"ხუთშაბათის",
	"პარასკევის",
	"შაბათის",
	"კვირის",
];
// tslint:disable-next-line
const monthNamesGeo = [
	"იანვარი",
	"თებერვალი",
	"მარტი",
	"აპრილი",
	"მაისი",
	"ივნისი",
	"ივლისი",
	"აგვისტო",
	"სექტემბერი",
	"ოქტომბერი",
	"ნოემბერი",
	"დეკემბერი",
];
export { weekDaysGeo, weekDaysAbbrGeo, weekDaysGeoNatesaobiti, monthNamesGeo };

const funcToPromise = (func: Function) =>
	new Promise((resolve, reject) => {
		try {
			resolve(func());
		} catch (e) {
			reject();
		}
	});
export { funcToPromise };

export const animateHTMLElement = (
	element: HTMLElement,
	propertyName: string,
	endPosition: number,
	duration: number
) => {
	const startPosition = element[propertyName];
	const startTime = performance.now();
	const animate = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		element.scrollTo(
			0,
			percentage * (endPosition - startPosition) + startPosition
		);
		if (percentage < 1) window.requestAnimationFrame(animate);
	};
	window.requestAnimationFrame(animate);
};

const animateWindowScroll = (whereToScrollTop: number, duration: number) => {
	const scrollTop = window.pageYOffset || document.documentElement!.scrollTop;
	const startTime = performance.now();
	const animateScroll = () => {
		const percentage = Math.min(
			1,
			(performance.now() - startTime) / duration
		);
		window.scrollTo(
			0,
			percentage * (whereToScrollTop - scrollTop) + scrollTop
		);
		if (percentage < 1) window.requestAnimationFrame(animateScroll);
	};
	window.requestAnimationFrame(animateScroll);
};
export { animateWindowScroll };

const getQueryStringParams = (query: string): { [key: string]: string } =>
	query
		? (/^[?#]/.test(query) ? query.slice(1) : query)
				.split("&")
				.reduce((params, param) => {
					const [key, value] = param.split("=");
					params[key] = value
						? decodeURIComponent(value.replace(/\+/g, " "))
						: "";
					return params;
				}, {})
		: {};
export { getQueryStringParams };

export const USER_TYPES = [
	UserTypes.GRADE_11,
	UserTypes.GRADE_12,
	UserTypes.HIGH_SCHOOL_GRADUATE,
	UserTypes.STUDENT,
	UserTypes.TEACHER_OR_TUTOR,
	UserTypes.OTHER,
];

export const USER_TYPE_GEO_TITLES: { [value in UserTypes]: string } = {
	[UserTypes.GRADE_11]: "მე-11 კლასელი",
	[UserTypes.GRADE_12]: "მე-12 კლასელი",
	[UserTypes.HIGH_SCHOOL_GRADUATE]: "სკოლადამთავრებული აბიტურიენტი",
	[UserTypes.STUDENT]: "სტუდენტი",
	[UserTypes.TEACHER_OR_TUTOR]: "მასწავლებელი ან/და რეპეტიტორი",
	[UserTypes.OTHER]: "სხვა",
};

interface ICity {
	id: number;
	name: string;
	engName: string;
}
const cities: ICity[] = [
	{ id: 1, name: "თბილისი", engName: "Tbilisi" },
	{ id: 2, name: "ქუთაისი", engName: "Kutaisi" },
	{ id: 3, name: "ბათუმი", engName: "Batumi" },
	{ id: 4, name: "რუსთავი", engName: "Rustavi" },
	{ id: 5, name: "ზუგდიდი", engName: "Zugdidi" },
	{ id: 6, name: "გორი", engName: "Gori" },
	{ id: 7, name: "ფოთი", engName: "Poti" },
	{ id: 8, name: "ახალციხე", engName: "Akhaltsikhe" },
	{ id: 10, name: "თელავი", engName: "Telavi" },
	{ id: 17, name: "ახალქალაქი", engName: "Akhalkalaki" },
];
const fields = [
	{ id: 1, name: "სოციალური და პოლიტიკური" },
	{ id: 2, name: "ჰუმანიტარული" },
	{ id: 3, name: "ტექნიკური" },
	{ id: 4, name: "ზუსტი და საბუნებისმეტყველო" },
	{ id: 5, name: "იურიდიული" },
	{ id: 6, name: "ბიზნესი და ეკონომიკა" },
	{ id: 7, name: "სამედიცინო" },
	{ id: 8, name: "სახელოვნებო" },
];
const sectors = ["სახელმწიფო", "კერძო"];
export { cities, fields, sectors };

export interface IErovnuliSubj {
	id: number;
	name: string;
	isCompulsory: boolean;
	halfCompulsory?: boolean;
	minScore?: number;
	maxScore?: number;
	noScores?: boolean;
	displayPriority: number;
}

const erovnulSubjects: IErovnuliSubj[] = [
	{
		id: 1,
		name: "ქართული ენა და ლიტერატურა",
		isCompulsory: true,
		minScore: 16,
		maxScore: 60,
		displayPriority: 1,
	},
	{
		id: 2,
		name: "ზოგადი უნარები",
		isCompulsory: false,
		minScore: 25,
		maxScore: 80,
		displayPriority: 10,
	},
	{
		id: 3,
		name: "უცხოური ენა",
		isCompulsory: true,
		minScore: 15,
		maxScore: 70,
		displayPriority: 2,
	},
	{
		id: 4,
		name: "მათემატიკა",
		isCompulsory: false,
		halfCompulsory: true,
		minScore: 11,
		maxScore: 51,
		displayPriority: 3,
	},
	{
		id: 5,
		name: "ისტორია",
		isCompulsory: false,
		halfCompulsory: true,
		minScore: 16,
		maxScore: 60,
		displayPriority: 4,
	},
	{
		id: 6,
		name: "გეოგრაფია",
		isCompulsory: false,
		minScore: 15,
		maxScore: 59,
		displayPriority: 5,
	},
	{
		id: 7,
		name: "ლიტერატურა",
		isCompulsory: false,
		minScore: 18,
		maxScore: 70,
		displayPriority: 6,
	},
	{
		id: 8,
		name: "ფიზიკა",
		isCompulsory: false,
		minScore: 16,
		maxScore: 63,
		displayPriority: 7,
	},
	{
		id: 9,
		name: "ქიმია",
		isCompulsory: false,
		minScore: 16,
		maxScore: 63,
		displayPriority: 8,
	},
	{
		id: 10,
		name: "ბიოლოგია",
		isCompulsory: false,
		minScore: 18,
		maxScore: 70,
		displayPriority: 9,
	},
	{
		id: 11,
		name: "სამოქალაქო განათლება",
		isCompulsory: false,
		minScore: 16,
		maxScore: 60,
		displayPriority: 11,
	},
	{
		id: 12,
		name: "ხელოვნება",
		isCompulsory: false,
		minScore: 18,
		maxScore: 70,
		displayPriority: 12,
	},
	{
		id: 13,
		name: "შემოქმედებითი ტური",
		isCompulsory: false,
		noScores: true,
		displayPriority: 20,
	},
	{
		id: 14,
		name: "ზოგადი უნარები (აზერ)",
		isCompulsory: false,
		minScore: 25,
		maxScore: 80,
		displayPriority: 14,
	},
	{
		id: 15,
		name: "ზოგადი უნარები (სომხ)",
		isCompulsory: false,
		minScore: 25,
		maxScore: 80,
		displayPriority: 15,
	},
	{
		id: 16,
		name: "აფხაზური ენა",
		isCompulsory: false,
		minScore: 16,
		maxScore: 60,
		displayPriority: 17,
	},
	{
		id: 17,
		name: "ოსური ენა",
		isCompulsory: false,
		minScore: 16,
		maxScore: 60,
		displayPriority: 18,
	},
];

export const erovnulSubjectNames = {
	1: "ქართული ენა და ლიტერატურა",
	2: "ზოგადი უნარები",
	3: "უცხოური ენა",
	4: "მათემატიკა",
	5: "ისტორია",
	6: "გეოგრაფია",
	7: "ლიტერატურა",
	8: "ფიზიკა",
	9: "ქიმია",
	10: "ბიოლოგია",
	11: "სამოქალაქო განათლება",
	12: "ხელოვნება",
	13: "შემოქმედებითი ტური",
	14: "ზოგადი უნარები (აზერ)",
	15: "ზოგადი უნარები (სომხ)",
	16: "აფხაზური ენა",
	17: "ოსური ენა",
};

export interface IMandatoryTour {
	id: number;
	name: string;
}

export const mandatoryTours = [
	{ id: 1, name: "შემოქმედებითი" },
	{ id: 2, name: "სპორტული" },
	{ id: 3, name: "ფიზიკური" },
	{ id: 4, name: "ფსიქოლოგიური" },
	{ id: 5, name: "სამედიცინო" },
];

export const mandatoryTourNames = {
	1: "შემოქმედებითი",
	2: "სპორტული",
	3: "ფიზიკური",
	4: "ფსიქოლოგიური",
	5: "სამედიცინო",
};

export const isForeignLang = (subjId: number): boolean => subjId === 3;

export const getMinScoreFromLimit = (
	percent: number,
	subjId: number
): number | undefined => {
	const subject = erovnulSubjects.find(subj => subj.id === subjId);
	if (!subject) throw new Error("subject not found!");
	if (subject.maxScore)
		return Math.floor((subject.maxScore * percent) / 100) + 1;
};

const foreignLanguages = [
	{ id: "ENG", name: "ინგლისური", engName: "English" },
	{ id: "RUS", name: "რუსული", engName: "Russian" },
	{ id: "DEU", name: "გერმანული", engName: "German" },
	{ id: "FRA", name: "ფრანგული", engName: "French" },
];
export { erovnulSubjects, foreignLanguages };

export const foreignLangShortNames = {
	ENG: "ინგ.",
	RUS: "რუს.",
	DEU: "გერ.",
	FRA: "ფრან.",
};

// Scaled scores from the previous year: filled with linear interpolation
const scaledScores: number[][] = [
    [],
    [	// 1
        -1,
        94.9,
        97.1,
        99.6,
        101.9,
        103.7,
        105,
        106.2,
        107.4,
        108.9,
        110.5,
        112,
        113.7,
        115.3,
        117,
        118.4,
        120,
        121.6,
        123.2,
        124.6,
        125.9,
        127.3,
        128.8,
        130.2,
        131.6,
        133,
        133.6,
        135.8,
        137,
        137.5,
        139.6,
        140.2,
        141.5,
        143.4,
        144.5,
        145.2,
        146.5,
        148.3,
        149,
        150.7,
        151.9,
        153.1,
        154.4,
        155.3,
        156.7,
        158.4,
        159.6,
        161.3,
        162.4,
        163.8,
        165.1,
        166.7,
        168,
        169.4,
        171,
        172.4,
        173.5,
        174.9,
        176.2,
        177.4,
        178.3
    ],
    [	// 2
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1,
		-1
	],
    [	// 3
        -1,
        -1,
        117.9,
        119.2,
        120.2,
        121.2,
        121.6,
        121.9,
        122.1,
        122.4,
        123.2,
        124,
        124.6,
        125.3,
        126,
        126.7,
        127.3,
        128.1,
        128.8,
        129.6,
        130.2,
        131,
        131.8,
        132.5,
        133,
        133.8,
        134.6,
        135.4,
        136.1,
        137.1,
        137.9,
        138.8,
        139.6,
        140.4,
        141.2,
        142,
        142.4,
        143.2,
        144.3,
        144.7,
        145.8,
        146.5,
        146.9,
        148,
        148.6,
        149.3,
        149.9,
        150.6,
        150.8,
        151.5,
        152.5,
        153,
        153.3,
        154.3,
        155,
        155.6,
        156.2,
        156.8,
        157.4,
        158,
        158.4,
        159.3,
        159.7,
        160.6,
        161,
        161.7,
        162.3,
        162.9,
        163.5,
        164.1,
        164.6
    ],
    [	// 4
        -1,
        122.1,
        123.5,
        125.6,
        126.6,
        127.6,
        128.5,
        129.7,
        131.1,
        132.8,
        133.9,
        135.4,
        137.3,
        138.4,
        140.3,
        141.3,
        143.2,
        144.6,
        146.2,
        147.8,
        148.6,
        150.4,
        151.2,
        152.9,
        154.3,
        155.6,
        156.7,
        158.3,
        159.5,
        160.8,
        162.1,
        163.5,
        164.7,
        166.2,
        167.4,
        168.7,
        170.2,
        171.7,
        173.2,
        174.7,
        176.1,
        177.4,
        178.6,
        180.1,
        181.6,
        182.9,
        184.2,
        185.8,
        187.3,
        188.9,
        190.3,
        191.3
    ],
    [	// 5
        -1,
        -1,
        112.4,
        113.7,
        115.5,
        117.1,
        118,
        118.7,
        119.4,
        120.5,
        121.7,
        123,
        124.3,
        125.5,
        126.9,
        128.2,
        129.8,
        131.1,
        132.2,
        133.6,
        134.9,
        136.3,
        137.6,
        139.5,
        140.8,
        141.7,
        143.5,
        144.3,
        145.6,
        147.4,
        148.7,
        150,
        151.2,
        151.9,
        153.3,
        155.1,
        156.4,
        157.7,
        159.1,
        159.8,
        161.1,
        162.3,
        164.3,
        164.9,
        166.2,
        168.1,
        169.3,
        169.9,
        171.2,
        172.4,
        173.5,
        174.7,
        175.9,
        177.2,
        178.9,
        180,
        181.1,
        182.1,
        182.8,
        183.8,
        184.4
    ],
    [	// 6
        -1,
        -1,
        122.5,
        124,
        125.4,
        126.8,
        128.2,
        129.6,
        131,
        132.5,
        133.9,
        135.3,
        136.7,
        138.1,
        139.5,
        141,
        142.4,
        143.8,
        145.2,
        146.6,
        148,
        149.5,
        150.9,
        152.3,
        153.7,
        155.1,
        156.5,
        158,
        159.4,
        160.8,
        162.2,
        163.6,
        165,
        166.4,
        167.9,
        169.3,
        170.7,
        172.1,
        173.5,
        174.9,
        176.4,
        177.8,
        179.2,
        180.6,
        182,
        183.4,
        184.9,
        186.3,
        187.7,
        189.1,
        190.5,
        191.9,
        193.4,
        194.8,
        196.2,
        197.6,
        199,
        200.4,
        201.8,
        203.2
    ],
    [	// 7
        -1,
        109.1,
        110.2,
        111.4,
        112.6,
        113.8,
        115,
        116.2,
        117.4,
        118.6,
        119.8,
        120.9,
        122.1,
        123.3,
        124.5,
        125.7,
        126.9,
        128.1,
        129.3,
        130.5,
        131.7,
        132.8,
        134,
        135.2,
        136.4,
        137.6,
        138.8,
        140,
        141.2,
        142.4,
        143.5,
        144.7,
        145.9,
        147.1,
        148.3,
        149.5,
        150.7,
        151.9,
        153.1,
        154.3,
        155.4,
        156.6,
        157.8,
        159,
        160.2,
        161.4,
        162.6,
        163.8,
        165,
        166.1,
        167.3,
        168.5,
        169.7,
        170.9,
        172.1,
        173.3,
        174.5,
        175.7,
        176.9,
        178,
        179.2,
        180.4,
        181.6,
        182.8,
        184,
        185.2,
        186.4,
        187.6,
        188.8,
        190,
        191.2
    ],
    [	// 8
        -1,
        -1,
        127.5,
        128.4,
        129.4,
        130.3,
        131.2,
        132.1,
        133,
        133.9,
        134.8,
        135.7,
        136.6,
        137.5,
        138.4,
        139.3,
        140.3,
        141.2,
        142.1,
        143,
        143.9,
        144.8,
        145.7,
        146.6,
        147.5,
        148.4,
        149.4,
        150.3,
        151.2,
        152.1,
        153,
        153.9,
        154.8,
        155.7,
        156.6,
        157.5,
        158.5,
        159.4,
        160.3,
        161.2,
        162.1,
        163,
        163.9,
        164.8,
        165.7,
        166.6,
        167.5,
        168.5,
        169.4,
        170.3,
        171.2,
        172.1,
        173,
        173.9,
        174.8,
        175.7,
        176.6,
        177.6,
        178.5,
        179.4,
        180.3,
        181.2,
        182.1,
        183
    ],
    [	// 9
        -1,
        116.4,
        117.4,
        118.4,
        119.4,
        120.4,
        121.4,
        122.4,
        123.4,
        124.4,
        125.4,
        126.4,
        127.4,
        128.4,
        129.4,
        130.4,
        131.4,
        132.4,
        133.4,
        134.4,
        135.4,
        136.4,
        137.4,
        138.4,
        139.4,
        140.4,
        141.4,
        142.4,
        143.4,
        144.4,
        145.4,
        146.4,
        147.4,
        148.4,
        149.4,
        150.4,
        151.4,
        152.4,
        153.4,
        154.4,
        155.4,
        156.4,
        157.4,
        158.4,
        159.4,
        160.4,
        161.4,
        162.4,
        163.4,
        164.4,
        165.4,
        166.4,
        167.4,
        168.4,
        169.4,
        170.4,
        171.4,
        172.4,
        173.4,
        174.4,
        175.4,
        176.4,
        177.4,
        178.4
    ],
    [	// 10
        -1,
        -1,
        -1,
        -1,
        -1,
        117.8,
        118.7,
        119.6,
        120.5,
        121.4,
        122.3,
        123.2,
        124.1,
        125,
        125.9,
        126.8,
        127.6,
        128.5,
        129.4,
        130.3,
        131.2,
        132.1,
        133,
        133.9,
        134.8,
        135.7,
        136.6,
        137.5,
        138.3,
        139.2,
        140.1,
        141,
        141.9,
        142.8,
        143.7,
        144.6,
        145.5,
        146.4,
        147.3,
        148.2,
        149,
        149.9,
        150.8,
        151.7,
        152.6,
        153.5,
        154.4,
        155.3,
        156.2,
        157.1,
        158,
        158.8,
        159.7,
        160.6,
        161.5,
        162.4,
        163.3,
        164.2,
        165.1,
        166,
        166.9,
        167.8,
        168.7,
        169.5,
        170.4,
        171.3,
        172.2,
        173.1,
        174,
        174.9,
        175.8
    ],
    [	// 11
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        120.9,
        122,
        123.2,
        124.3,
        125.5,
        126.6,
        127.7,
        128.9,
        130,
        131.1,
        132.3,
        133.4,
        134.6,
        135.7,
        136.8,
        138,
        139.1,
        140.2,
        141.4,
        142.5,
        143.7,
        144.8,
        145.9,
        147.1,
        148.2,
        149.3,
        150.5,
        151.6,
        152.8,
        153.9,
        155,
        156.2,
        157.3,
        158.4,
        159.6,
        160.7,
        161.9,
        163,
        164.1,
        165.3,
        166.4,
        167.5,
        168.7,
        169.8,
        171,
        172.1,
        173.2,
        174.4,
        175.5,
        176.6,
        177.8,
        178.9,
        180,
        181.1,
        182.3
    ],
    [	// 12
        -1,
        -1,
        -1,
        -1,
        119.5,
        120.5,
        121.5,
        122.5,
        123.5,
        124.5,
        125.5,
        126.5,
        127.5,
        128.5,
        129.5,
        130.5,
        131.5,
        132.5,
        133.5,
        134.5,
        135.5,
        136.5,
        137.5,
        138.5,
        139.4,
        140.4,
        141.4,
        142.4,
        143.4,
        144.4,
        145.4,
        146.4,
        147.4,
        148.4,
        149.4,
        150.4,
        151.4,
        152.4,
        153.4,
        154.4,
        155.4,
        156.4,
        157.4,
        158.4,
        159.4,
        160.3,
        161.3,
        162.3,
        163.3,
        164.3,
        165.3,
        166.3,
        167.3,
        168.3,
        169.3,
        170.3,
        171.3,
        172.3,
        173.3,
        174.3,
        175.3,
        176.3,
        177.3,
        178.3,
        179.3,
        180.3,
        181.3,
        182.3,
        183.3,
        184.3,
        185.3
    ],
    [/* 13 */],
    [	// 14
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        121.5,
        122.7,
        123.9,
        125.1,
        126.3,
        127.5,
        128.7,
        129.9,
        131.1,
        132.3,
        133.5,
        134.7,
        135.9,
        137.1,
        138.4,
        139.6,
        140.8,
        142,
        143.2,
        144.4,
        145.6,
        146.8,
        148,
        149.2,
        150.4,
        151.6,
        152.8,
        154,
        155.2,
        156.4,
        157.7,
        158.9,
        160.1,
        161.3,
        162.5,
        163.7,
        164.9,
        166.1,
        167.3,
        168.5,
        169.7,
        170.9,
        172.1,
        173.3,
        174.5,
        175.7,
        177,
        178.2,
        179.4,
        180.6,
        181.8,
        183,
        184.2,
        185.4,
        186.6,
        187.8,
        189,
        190.2,
        191.4,
        192.6,
        193.8,
        195.1,
        196.3,
        197.5,
        198.7,
        199.9,
        201.1,
        202.3,
        203.5,
        204.7,
        205.9,
        207.1,
        208.3,
        209.5
    ],
    [	// 15
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        126.3,
        127.5,
        128.7,
        129.9,
        131.1,
        132.3,
        133.5,
        134.7,
        135.9,
        137.1,
        138.4,
        139.6,
        140.8,
        142,
        143.2,
        144.4,
        145.6,
        146.8,
        148,
        149.2,
        150.4,
        151.6,
        152.8,
        154,
        155.2,
        156.4,
        157.7,
        158.9,
        160.1,
        161.3,
        162.5,
        163.7,
        164.9,
        166.1,
        167.3,
        168.5,
        169.7,
        170.9,
        172.1,
        173.3,
        174.5,
        175.7,
        177,
        178.2,
        179.4,
        180.6,
        181.8,
        183,
        184.2,
        185.4,
        186.6,
        187.8,
        189,
        190.2,
        191.4,
        192.6,
        193.8,
        195.1,
        196.3,
        197.5,
        198.7,
        199.9,
        201.1,
        202.3,
        203.5,
        204.7,
        205.9,
        207.1,
        208.3,
        209.5
    ],
    [/* 16 */],
    [	// 17
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        118.1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        144.9,
        -1,
        -1,
        -1,
        149.4,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        -1,
        158.3,
        -1,
        -1,
        -1,
        162.8,
        -1,
        -1,
        -1,
        167.2
    ]
];

// Program places
export const placesByProgramsAndSubjects = {
    "1140135": 16,
    "1140136": 16,
    "1300102": 20,
    "1300103": 7,
    "1300104": 12,
    "1300105": 17,
    "1300110": 1,
    "1300112": 1,
    "1330113": 30,
    "1330118": 7,
    "1330119": 7,
    "1550105": 2,
    "1550107": 2,
    "1860101": 122,
    "1860106": 2,
    "1860107": 2,
    "11401014": 5,
    "11401015": 35,
    "11401019": 5,
    "11401024": 36,
    "11401028": 4,
    "11401034": 100,
    "11401035": 100,
    "11401044": 3,
    "11401049": 3,
    "11401054": 3,
    "11401055": 10,
    "11401056": 12,
    "11401065": 4,
    "11401067": 1,
    "11401074": 3,
    "11401075": 10,
    "11401076": 3,
    "11401078": 3,
    "11401079": 3,
    "11401084": 45,
    "11401094": 9,
    "11401098": 1,
    "11401104": 40,
    "11401105": 40,
    "11401115": 9,
    "11401117": 1,
    "11401125": 4,
    "11401127": 1,
    "11401135": 90,
    "11401137": 10,
    "11401145": 45,
    "11401147": 5,
    "11401154": 30,
    "11401158": 10,
    "11401164": 20,
    "11401168": 5,
    "11401184": 45,
    "11401188": 5,
    "11401194": 8,
    "11401198": 1,
    "11401199": 1,
    "11401205": 4,
    "11401207": 1,
    "11401214": 6,
    "11401215": 25,
    "11401216": 18,
    "11401224": 10,
    "11401225": 80,
    "11401234": 5,
    "11401235": 10,
    "11401236": 5,
    "11401254": 25,
    "11401255": 100,
    "11401256": 25,
    "11401274": 5,
    "11401278": 5,
    "11401294": 2,
    "11401295": 11,
    "11401297": 2,
    "11401305": 4,
    "11401307": 1,
    "11401314": 5,
    "11401315": 35,
    "11401325": 50,
    "11401327": 30,
    "11401334": 1,
    "11401339": 7,
    "11401342": 79,
    "11401372": 79,
    "11501014": 20,
    "11501015": 120,
    "11501019": 30,
    "11501024": 25,
    "11501025": 150,
    "11501034": 15,
    "11501035": 60,
    "11501036": 30,
    "11501037": 20,
    "11501044": 20,
    "11501045": 70,
    "11501046": 70,
    "11501094": 20,
    "11501095": 90,
    "11501096": 90,
    "11501104": 30,
    "11501105": 120,
    "11501114": 100,
    "11501124": 15,
    "11501125": 15,
    "12001014": 60,
    "12001015": 40,
    "12001024": 40,
    "12001025": 30,
    "12001034": 6,
    "12001035": 40,
    "12001044": 8,
    "12001045": 45,
    "12001046": 12,
    "12001054": 70,
    "12001058": 10,
    "12001064": 30,
    "12001068": 10,
    "12001075": 40,
    "12001077": 20,
    "12001085": 10,
    "12001087": 20,
    "12001095": 17,
    "12001097": 3,
    "12101014": 3,
    "12101015": 10,
    "12101017": 2,
    "12101024": 72,
    "12101028": 8,
    "12101034": 10,
    "12101035": 20,
    "12101044": 100,
    "12101045": 50,
    "12101054": 5,
    "12101055": 5,
    "12101064": 30,
    "12101074": 15,
    "12101078": 10,
    "12101085": 15,
    "12101087": 10,
    "12101095": 5,
    "12101097": 5,
    "12101104": 30,
    "12101108": 20,
    "12101114": 3,
    "12101118": 2,
    "12101125": 22,
    "12101127": 3,
    "12101134": 5,
    "12101138": 5,
    "12101154": 2,
    "12101155": 14,
    "12101156": 2,
    "12101164": 7,
    "12101165": 42,
    "12101166": 7,
    "12101167": 7,
    "12101174": 7,
    "12101175": 49,
    "12101176": 7,
    "12101194": 35,
    "12101195": 245,
    "12101204": 6,
    "12101205": 34,
    "12101206": 14,
    "12101224": 10,
    "12101225": 70,
    "12101227": 10,
    "12101234": 8,
    "12101235": 27,
    "12101236": 15,
    "12101254": 10,
    "12101255": 80,
    "12101265": 15,
    "12101267": 15,
    "12101275": 5,
    "12101277": 2,
    "12101284": 10,
    "12101285": 15,
    "12201014": 210,
    "12201015": 50,
    "12201024": 40,
    "12201025": 10,
    "12201034": 22,
    "12201035": 3,
    "12201044": 25,
    "12201045": 25,
    "12201064": 13,
    "12201065": 97,
    "12201074": 5,
    "12201075": 20,
    "12201076": 10,
    "12201077": 10,
    "12201084": 15,
    "12201085": 65,
    "12201086": 20,
    "12201094": 45,
    "12201098": 5,
    "12201104": 40,
    "12201108": 10,
    "12201114": 115,
    "12201118": 15,
    "12201124": 22,
    "12201128": 3,
    "12201134": 22,
    "12201138": 3,
    "12201144": 20,
    "12201145": 20,
    "12201154": 8,
    "12201155": 40,
    "12201156": 24,
    "12201164": 3,
    "12201165": 11,
    "12201166": 8,
    "12201174": 5,
    "12201175": 25,
    "12201176": 10,
    "12201185": 20,
    "12201187": 10,
    "12201195": 20,
    "12201197": 10,
    "12201204": 3,
    "12201205": 27,
    "12201214": 3,
    "12201215": 27,
    "12201224": 13,
    "12201225": 104,
    "12201234": 5,
    "12201235": 40,
    "12201244": 15,
    "12201245": 30,
    "12201246": 15,
    "12201254": 5,
    "12201255": 15,
    "12201256": 5,
    "12201264": 90,
    "12201274": 25,
    "12201284": 12,
    "12201285": 18,
    "12901054": 25,
    "12901055": 20,
    "13001014": 13,
    "13001018": 2,
    "13001065": 8,
    "13001067": 2,
    "13001074": 2,
    "13001075": 10,
    "13001084": 2,
    "13001085": 8,
    "13001086": 2,
    "13001094": 2,
    "13001095": 6,
    "13001097": 2,
    "13001112": 7,
    "13001132": 7,
    "13101014": 70,
    "13101018": 10,
    "13101024": 100,
    "13101028": 20,
    "13101034": 80,
    "13101038": 20,
    "13101044": 80,
    "13101048": 20,
    "13101054": 70,
    "13101058": 10,
    "13101064": 30,
    "13101065": 170,
    "13301014": 20,
    "13301015": 50,
    "13301024": 5,
    "13301029": 5,
    "13301034": 30,
    "13301044": 20,
    "13301045": 40,
    "13301055": 25,
    "13301057": 10,
    "13301064": 25,
    "13301068": 5,
    "13301075": 25,
    "13301077": 5,
    "13301084": 10,
    "13301088": 5,
    "13301094": 2,
    "13301095": 6,
    "13301096": 3,
    "13301097": 6,
    "13301104": 12,
    "13301105": 85,
    "13301114": 5,
    "13301115": 15,
    "13301116": 10,
    "13301124": 10,
    "13301125": 55,
    "13301126": 15,
    "13301144": 5,
    "13301145": 15,
    "13301146": 10,
    "13301154": 3,
    "13301155": 17,
    "13301165": 10,
    "13301167": 20,
    "13301172": 37,
    "13301202": 37,
    "14001014": 20,
    "14001016": 15,
    "14001018": 5,
    "14001024": 20,
    "14001028": 5,
    "14001034": 15,
    "14001038": 5,
    "14201014": 10,
    "14201015": 20,
    "14201019": 9,
    "14201024": 56,
    "14201025": 56,
    "14201034": 56,
    "14201035": 56,
    "14201045": 30,
    "14201047": 24,
    "14201054": 30,
    "14201058": 24,
    "14201064": 5,
    "14201065": 20,
    "14201066": 10,
    "14201067": 15,
    "14201074": 31,
    "14201075": 50,
    "14201084": 12,
    "14201085": 50,
    "14201086": 25,
    "14201095": 27,
    "14201097": 27,
    "14201105": 25,
    "14201107": 15,
    "14301014": 10,
    "14301015": 50,
    "14301024": 30,
    "14301034": 15,
    "14301035": 110,
    "14301044": 8,
    "14301045": 12,
    "14301046": 14,
    "14301047": 14,
    "14301054": 8,
    "14301055": 30,
    "14301056": 22,
    "14301064": 7,
    "14301065": 46,
    "14501014": 10,
    "14501015": 70,
    "14501024": 15,
    "14501025": 60,
    "14501034": 63,
    "14501038": 7,
    "14501044": 8,
    "14501045": 62,
    "14501054": 20,
    "14501055": 40,
    "14501064": 8,
    "14501065": 40,
    "14501066": 12,
    "14501075": 35,
    "14501077": 5,
    "14501084": 6,
    "14501085": 24,
    "14501086": 30,
    "14501095": 5,
    "14501097": 35,
    "14501104": 8,
    "14501105": 62,
    "15201014": 40,
    "15201015": 357,
    "15201024": 4,
    "15201025": 33,
    "15201044": 21,
    "15201045": 186,
    "15201054": 16,
    "15201055": 77,
    "15201056": 16,
    "15201057": 32,
    "15201064": 26,
    "15201065": 177,
    "15201066": 27,
    "15201074": 61,
    "15201075": 485,
    "15201084": 31,
    "15201085": 207,
    "15201086": 69,
    "15201094": 97,
    "15201104": 40,
    "15201105": 317,
    "15301014": 20,
    "15301015": 5,
    "15301017": 5,
    "15301024": 40,
    "15301025": 35,
    "15301034": 30,
    "15301044": 5,
    "15301045": 15,
    "15301046": 5,
    "15301054": 7,
    "15301055": 25,
    "15301056": 13,
    "15301064": 10,
    "15301065": 35,
    "15301074": 20,
    "15301075": 20,
    "15301076": 10,
    "15301084": 10,
    "15301085": 20,
    "15301094": 10,
    "15301095": 35,
    "15301104": 10,
    "15301105": 10,
    "15301107": 10,
    "15401054": 10,
    "15401055": 10,
    "15501014": 10,
    "15501015": 35,
    "15501024": 25,
    "15501028": 5,
    "15501034": 25,
    "15501035": 5,
    "15501044": 25,
    "15501048": 5,
    "15501062": 8,
    "15501082": 8,
    "17101054": 15,
    "17101055": 15,
    "17101064": 20,
    "17101068": 10,
    "17101074": 5,
    "17101075": 15,
    "17101076": 10,
    "17101084": 5,
    "17101085": 15,
    "17101086": 5,
    "17101094": 5,
    "17101095": 15,
    "17101104": 10,
    "17101105": 35,
    "17101114": 27,
    "17101115": 3,
    "17101134": 10,
    "17101135": 10,
    "17101139": 17,
    "17201014": 30,
    "17201018": 15,
    "17201024": 20,
    "17201025": 40,
    "17201034": 6,
    "17201035": 13,
    "17201036": 5,
    "17201044": 15,
    "17201045": 50,
    "17201054": 10,
    "17201055": 28,
    "18101014": 60,
    "18101015": 40,
    "18101024": 10,
    "18101025": 30,
    "18101026": 30,
    "18101034": 20,
    "18101035": 40,
    "18101036": 40,
    "18301014": 20,
    "18301015": 10,
    "18301034": 3,
    "18301035": 15,
    "18301036": 9,
    "18301054": 5,
    "18301055": 17,
    "18601034": 4,
    "18601035": 36,
    "18601042": 12,
    "18601052": 12,
    "19501014": 235,
    "19501015": 115,
    "19501024": 100,
    "19501034": 100,
    "19501035": 50,
    "19501044": 405,
    "19501048": 45,
    "19701014": 18,
    "19701018": 2,
    "19701024": 180,
    "19701028": 20,
    "19701034": 135,
    "19701035": 15,
    "19801014": 5,
    "19801015": 15,
    "19801017": 5,
    "19801024": 6,
    "19801025": 48,
    "19801034": 5,
    "19801035": 35,
    "19801037": 5,
    "19801044": 10,
    "19801045": 10,
    "19901014": 30,
    "19901015": 20,
    "19901024": 10,
    "19901025": 20,
    "19901026": 10,
    "20201014": 30,
    "20201015": 20,
    "20201024": 20,
    "20201025": 20,
    "20201027": 5,
    "20201034": 20,
    "20201035": 15,
    "20201036": 10,
    "114010110": 5,
    "114010410": 24,
    "114010710": 3,
    "114012111": 6,
    "114012211": 10,
    "114012311": 10,
    "114013110": 10,
    "114013310": 2,
    "115010110": 30,
    "115010211": 75,
    "115010311": 25,
    "115010411": 40,
    "120010311": 14,
    "120010411": 15,
    "121011511": 2,
    "121011611": 7,
    "121011711": 7,
    "121011911": 70,
    "121012011": 6,
    "121012211": 10,
    "121012510": 10,
    "121012712": 3,
    "122010611": 20,
    "122010711": 5,
    "122011412": 10,
    "122011511": 8,
    "122011611": 3,
    "122011711": 10,
    "122012210": 13,
    "122012310": 5,
    "130010612": 2,
    "130010911": 2,
    "133010210": 10,
    "133010911": 3,
    "133011011": 13,
    "133011211": 10,
    "133011510": 10,
    "142010110": 15,
    "142010611": 4,
    "142010711": 31,
    "142010811": 25,
    "142011012": 14,
    "143010311": 25,
    "143010411": 12,
    "143010610": 7,
    "145010111": 20,
    "145010210": 15,
    "145010611": 20,
    "152010511": 16,
    "152010611": 27,
    "152010711": 61,
    "152011010": 40,
    "153010112": 20,
    "153010411": 5,
    "153010511": 5,
    "153010611": 5,
    "153010910": 5,
    "153011011": 5,
    "154010510": 10,
    "155010111": 5,
    "171010811": 5,
    "171010911": 10,
    "171011010": 5,
    "171011248": 9,
    "171011249": 9,
    "171011258": 1,
    "171011259": 1,
    "171011310": 18,
    "172010311": 6,
    "172010411": 15,
    "172010510": 12,
    "181010211": 30,
    "183010311": 3,
    "183010511": 8,
    "198010111": 5,
    "198010210": 6,
    "198010311": 5,
    "199010211": 10,
    "202010211": 5,
    "202010311": 5,
    "1140117410": 12,
    "1140117810": 12,
    "1140117910": 16,
    "1140124410": 8,
    "1140124810": 8,
    "1140124910": 9,
    "1140126410": 5,
    "1140126810": 5,
    "1140126910": 5,
    "1140128410": 3,
    "1140128810": 3,
    "1140128910": 4,
    "1150105410": 45,
    "1150105810": 45,
    "1150105910": 60,
    "1150106410": 45,
    "1150106810": 45,
    "1150106910": 60,
    "1150107410": 45,
    "1150107810": 45,
    "1150107910": 60,
    "1150108410": 10,
    "1150108810": 10,
    "1150108910": 10,
    "1200110410": 12,
    "1200110810": 12,
    "1200110910": 16,
    "1210114410": 3,
    "1210114810": 3,
    "1210114910": 4,
    "1210118410": 5,
    "1210118810": 5,
    "1210118910": 5,
    "1210121410": 3,
    "1210121810": 3,
    "1210121910": 4,
    "1210124410": 5,
    "1210124810": 5,
    "1210124910": 5,
    "1220105410": 20,
    "1220105810": 20,
    "1220105910": 20,
    "1290101410": 25,
    "1290101810": 25,
    "1290101910": 25,
    "1290102410": 5,
    "1290102810": 5,
    "1290102910": 5,
    "1290103410": 30,
    "1290103810": 30,
    "1290103910": 30,
    "1290104410": 30,
    "1290104810": 30,
    "1290104910": 30,
    "1520103410": 12,
    "1520103810": 12,
    "1520103910": 13,
    "1540101410": 6,
    "1540101810": 6,
    "1540101910": 6,
    "1540102410": 6,
    "1540102810": 6,
    "1540102910": 6,
    "1540103410": 6,
    "1540103810": 6,
    "1540103910": 6,
    "1540104410": 6,
    "1540104810": 6,
    "1540104910": 6,
    "1710101410": 39,
    "1710101810": 39,
    "1710101910": 52,
    "1710102410": 3,
    "1710102810": 3,
    "1710102910": 4,
    "1710103410": 18,
    "1710103810": 18,
    "1710103910": 24,
    "1710104410": 3,
    "1710104810": 3,
    "1710104910": 4,
    "1710112410": 9,
    "1710112510": 1,
    "1830102410": 6,
    "1830102810": 6,
    "1830102910": 8,
    "1830104410": 3,
    "1830104810": 3,
    "1830104910": 4,
    "1860102410": 15,
    "1860102810": 15,
    "1860102910": 20,
    "1920101410": 3,
    "1920101810": 3,
    "1920101910": 4,
    "1920102410": 2,
    "1920102810": 2,
    "1920102910": 2,
    "00101015": 160,
    "00101017": 40,
    "00101025": 135,
    "00101027": 15,
    "00101034": 3,
    "00101035": 24,
    "00101037": 3,
    "00101044": 3,
    "00101045": 24,
    "00101047": 3,
    "00101055": 585,
    "00101057": 65,
    "001010612": 4,
    "00101065": 32,
    "00101067": 4,
    "001010712": 2,
    "00101074": 2,
    "00101075": 16,
    "001010812": 1,
    "00101084": 1,
    "00101085": 2,
    "00101087": 1,
    "00101094": 4,
    "00101095": 36,
    "00101104": 4,
    "00101105": 32,
    "00101107": 4,
    "00101114": 2,
    "00101115": 11,
    "00101117": 2,
    "00101124": 1,
    "00101125": 3,
    "00101127": 1,
    "00101135": 4,
    "00101137": 1,
    "00101144": 80,
    "00101148": 20,
    "00101154": 50,
    "00101158": 50,
    "001011610": 10,
    "00101164": 10,
    "00101169": 80,
    "001011710": 80,
    "00101174": 10,
    "00101179": 10,
    "00101184": 105,
    "00101188": 15,
    "001011910": 42,
    "00101194": 6,
    "00101195": 6,
    "00101199": 6,
    "00101204": 10,
    "00101205": 10,
    "00101206": 50,
    "00101214": 5,
    "00101215": 5,
    "00101216": 25,
    "00101224": 30,
    "00101228": 10,
    "001012310": 10,
    "00101234": 10,
    "00101235": 10,
    "00101236": 10,
    "00101238": 10,
    "00101239": 10,
    "00101244": 90,
    "00101248": 10,
    "00101254": 25,
    "00101258": 5,
    "00101264": 10,
    "00101268": 5,
    "001012710": 3,
    "00101274": 14,
    "00101279": 13,
    "001012810": 8,
    "00101284": 4,
    "00101289": 8,
    "00101294": 4,
    "00101295": 4,
    "00101296": 12,
    "00101304": 24,
    "00101305": 96,
    "00101314": 12,
    "00101315": 28,
    "00101316": 40,
    "00101324": 12,
    "00101325": 48,
    "001013311": 27,
    "00101334": 18,
    "00101335": 99,
    "00101336": 36,
    "001013411": 8,
    "00101344": 8,
    "00101345": 32,
    "00101346": 32,
    "001013511": 13,
    "00101354": 13,
    "00101355": 65,
    "00101356": 13,
    "00101357": 26,
    "001013611": 1,
    "00101364": 1,
    "00101365": 5,
    "00101366": 1,
    "00101367": 2,
    "00101374": 500,
    "00101384": 350,
    "00101385": 350,
    "00101394": 30,
    "00101395": 135,
    "00101396": 135,
    "00101404": 150,
    "001014111": 60,
    "00101414": 200,
    "00101415": 340,
    "001014211": 10,
    "00101424": 25,
    "00101425": 45,
    "001014310": 65,
    "00101434": 65,
    "00101435": 95,
    "00101444": 60,
    "00101445": 40,
    "00101454": 25,
    "00101455": 25,
    "0010146410": 30,
    "0010146810": 30,
    "0010146910": 40,
    "0010147410": 3,
    "0010147810": 3,
    "0010147910": 4,
    "0010148410": 12,
    "0010148810": 12,
    "0010148910": 16,
    "0010149410": 8,
    "0010149810": 6,
    "0010149910": 6,
    "00101502": 326,
    "0010151": 65,
    "0010152": 65,
    "00101532": 326,
    "0020101": 13,
    "0020102": 7,
    "0020103": 8,
    "0020104": 8,
    "0020105": 10,
    "0020106": 2,
    "0020107": 2,
    "0020108": 1,
    "0020109": 1,
    "0020110": 2,
    "0020111": 1,
    "0020112": 2,
    "0020113": 1,
    "0020114": 2,
    "0020115": 1,
    "0020116": 2,
    "0020117": 1,
    "0020118": 8,
    "0020119": 3,
    "0020120": 3,
    "002012112": 1,
    "00201214": 6,
    "00201215": 3,
    "00201222": 5,
    "00201232": 5,
    "0020124": 1,
    "0020125": 1,
    "00301014": 180,
    "00301018": 70,
    "00301024": 6,
    "00301028": 4,
    "00301034": 10,
    "00301038": 5,
    "00301044": 15,
    "00301048": 5,
    "00301054": 180,
    "00301058": 50,
    "00301064": 10,
    "00301065": 90,
    "00301074": 15,
    "00301078": 5,
    "00301084": 20,
    "00301085": 15,
    "00301086": 15,
    "00301094": 40,
    "00301096": 40,
    "00301098": 10,
    "00301104": 20,
    "00301106": 15,
    "00301108": 5,
    "003011110": 5,
    "00301114": 15,
    "00301115": 15,
    "00301119": 5,
    "00301124": 4,
    "00301128": 2,
    "00301129": 4,
    "003011310": 10,
    "00301134": 10,
    "00301138": 10,
    "00301139": 10,
    "003011410": 10,
    "00301144": 5,
    "00301149": 15,
    "00301154": 4,
    "00301158": 3,
    "00301159": 3,
    "00301164": 4,
    "00301168": 3,
    "00301169": 3,
    "00301174": 5,
    "00301176": 10,
    "00301178": 5,
    "00301179": 5,
    "003011810": 3,
    "00301184": 5,
    "00301188": 3,
    "00301189": 4,
    "0030119410": 12,
    "0030119810": 13,
    "0030119910": 15,
    "00301204": 30,
    "00301208": 10,
    "00301214": 10,
    "00301218": 5,
    "00301224": 8,
    "00301229": 2,
    "00301234": 20,
    "00301238": 10,
    "003012410": 2,
    "00301244": 3,
    "00301245": 10,
    "00301248": 3,
    "00301249": 2,
    "00301254": 10,
    "00301264": 30,
    "00301265": 90,
    "00301274": 160,
    "00301278": 20,
    "00301284": 10,
    "00301288": 5,
    "003012912": 3,
    "00301294": 4,
    "00301295": 8,
    "003013011": 22,
    "00301304": 22,
    "00301305": 176,
    "003013111": 16,
    "00301314": 7,
    "00301315": 40,
    "00301316": 7,
    "003013211": 2,
    "00301324": 2,
    "00301325": 9,
    "00301326": 2,
    "00301334": 190,
    "00301338": 60,
    "00301344": 12,
    "00301348": 8,
    "00301354": 20,
    "00301358": 20,
    "00301364": 20,
    "00301368": 10,
    "00301374": 10,
    "00301378": 20,
    "003013810": 10,
    "00301384": 20,
    "00301388": 20,
    "003013910": 3,
    "00301394": 4,
    "00301398": 3,
    "00301404": 20,
    "00301408": 10,
    "00301414": 15,
    "00301418": 10,
    "00301424": 20,
    "00301428": 20,
    "003014312": 10,
    "00301434": 20,
    "00301435": 20,
    "003014410": 10,
    "00301444": 20,
    "00301445": 20,
    "00301449": 10,
    "003014510": 30,
    "00301454": 30,
    "00301455": 30,
    "00301459": 30,
    "003014610": 15,
    "00301464": 15,
    "00301465": 15,
    "00301469": 15,
    "003014710": 30,
    "00301474": 30,
    "00301475": 30,
    "00301478": 30,
    "00301479": 30,
    "003014810": 5,
    "00301484": 5,
    "00301485": 5,
    "00301489": 5,
    "003014910": 3,
    "00301494": 4,
    "00301499": 3,
    "003015010": 3,
    "00301504": 4,
    "00301509": 3,
    "003015110": 15,
    "00301514": 15,
    "00301515": 15,
    "00301519": 15,
    "00301524": 50,
    "00301525": 100,
    "00301534": 5,
    "00301535": 5,
    "00301544": 5,
    "003015511": 9,
    "00301554": 6,
    "00301555": 25,
    "00301556": 15,
    "00301564": 5,
    "00301565": 15,
    "00301566": 10,
    "00301574": 3,
    "00301575": 27,
    "00301584": 5,
    "00301585": 10,
    "00301594": 35,
    "00301604": 3,
    "00301605": 19,
    "00301607": 3,
    "003016111": 3,
    "00301614": 3,
    "00301615": 12,
    "00301617": 12,
    "003016210": 3,
    "00301624": 5,
    "00301625": 7,
    "00301626": 5,
    "00301634": 10,
    "00301635": 15,
    "00301636": 15,
    "00301644": 10,
    "00301645": 10,
    "00301654": 3,
    "00301656": 3,
    "00301658": 2,
    "00301659": 2,
    "00301662": 256,
    "00301672": 189,
    "0030168": 0,
    "0030169": 9,
    "0040101": 16,
    "0040102": 8,
    "0040103": 5,
    "0040104": 16,
    "0040105": 10,
    "0040106": 7,
    "0040107": 7,
    "0040108": 7,
    "004010912": 15,
    "00401095": 15,
    "00401097": 15,
    "004011011": 3,
    "00401104": 2,
    "00401105": 5,
    "00401107": 5,
    "00401114": 5,
    "00401115": 10,
    "00401116": 15,
    "00401122": 9,
    "00401132": 9,
    "0040114": 2,
    "0040115": 2,
    "005010110": 11,
    "00501014": 27,
    "00501015": 56,
    "00501019": 11,
    "005010210": 7,
    "00501024": 8,
    "00501025": 15,
    "00501028": 5,
    "00501029": 5,
    "005010310": 4,
    "00501034": 18,
    "00501039": 8,
    "005010410": 8,
    "00501044": 9,
    "00501045": 10,
    "00501049": 3,
    "00501054": 68,
    "00501058": 10,
    "00501064": 50,
    "00501065": 10,
    "00501074": 10,
    "00501075": 20,
    "0060101410": 75,
    "0060101810": 75,
    "0060101910": 100,
    "0060102410": 6,
    "0060102810": 6,
    "0060102910": 8,
    "0060103410": 33,
    "0060103810": 33,
    "0060103910": 44,
    "0060104410": 3,
    "0060104810": 3,
    "0060104910": 4,
    "0060105410": 9,
    "0060105810": 9,
    "0060105910": 12,
    "0060106410": 38,
    "0060106810": 33,
    "0060106910": 39,
    "0060107410": 14,
    "0060107810": 12,
    "0060107910": 14,
    "0060108410": 45,
    "0060108810": 45,
    "0060108910": 60,
    "0060109410": 27,
    "0060109810": 27,
    "0060109910": 36,
    "00601102": 46,
    "0060111": 9,
    "0060112": 9,
    "00601132": 46,
    "00901014": 20,
    "00901015": 180,
    "009010210": 8,
    "00901024": 8,
    "00901025": 51,
    "00901029": 8,
    "00901034": 3,
    "00901035": 24,
    "00901037": 3,
    "00901045": 36,
    "00901047": 4,
    "00901054": 135,
    "00901055": 15,
    "009010610": 32,
    "00901064": 4,
    "00901069": 4,
    "009010710": 14,
    "00901074": 2,
    "00901075": 2,
    "00901079": 2,
    "009010812": 4,
    "00901084": 4,
    "00901085": 32,
    "00901094": 6,
    "00901096": 10,
    "00901098": 3,
    "00901099": 6,
    "00901104": 5,
    "00901105": 5,
    "00901106": 30,
    "00901115": 18,
    "00901117": 2,
    "0090112410": 20,
    "0090112810": 20,
    "0090112910": 20,
    "009011310": 20,
    "00901134": 5,
    "00901135": 5,
    "00901136": 5,
    "00901138": 5,
    "00901139": 5,
    "00901144": 100,
    "00901154": 58,
    "00901158": 7,
    "00901165": 90,
    "00901167": 15,
    "00901175": 72,
    "00901177": 8,
    "00901184": 5,
    "00901189": 5,
    "00901194": 75,
    "00901198": 10,
    "00901204": 45,
    "00901208": 5,
    "009012111": 3,
    "00901214": 3,
    "00901215": 16,
    "00901217": 3,
    "00901224": 20,
    "00901225": 30,
    "00901234": 3,
    "00901235": 13,
    "00901238": 2,
    "00901239": 2,
    "00901244": 40,
    "00901248": 10,
    "00901255": 8,
    "00901257": 2,
    "009012611": 5,
    "00901264": 5,
    "00901265": 35,
    "00901266": 5,
    "009012710": 4,
    "00901274": 4,
    "00901275": 24,
    "00901278": 4,
    "00901279": 4,
    "009012811": 15,
    "00901284": 15,
    "00901285": 120,
    "009012910": 3,
    "00901294": 9,
    "00901295": 12,
    "00901298": 3,
    "00901299": 3,
    "00901304": 5,
    "00901308": 5,
    "00901314": 20,
    "00901318": 5,
    "0090132": 20,
    "00901334": 10,
    "00901335": 25,
    "00901338": 5,
    "00901339": 5,
    "009013411": 5,
    "00901344": 5,
    "00901345": 35,
    "00901346": 5,
    "00901354": 5,
    "00901355": 40,
    "00901356": 5,
    "0090136410": 10,
    "0090136810": 10,
    "0090136910": 10,
    "009013710": 2,
    "00901374": 16,
    "00901379": 2,
    "00901384": 15,
    "00901385": 90,
    "00901386": 15,
    "009013910": 8,
    "00901394": 6,
    "00901398": 3,
    "00901399": 8,
    "0090140410": 8,
    "0090140810": 8,
    "0090140910": 9,
    "00901414": 5,
    "00901418": 5,
    "0090142410": 10,
    "0090142810": 10,
    "0090142910": 10,
    "00901434": 2,
    "00901435": 16,
    "00901437": 2,
    "00901445": 8,
    "00901447": 2,
    "00901455": 130,
    "00901457": 20,
    "009014610": 2,
    "00901464": 2,
    "00901469": 11,
    "00901472": 133,
    "0090148": 27,
    "0090149": 27,
    "00901502": 133,
    "01001014": 25,
    "01001015": 90,
    "010010211": 60,
    "01001024": 40,
    "01001025": 185,
    "01001026": 40,
    "01001034": 3,
    "01001035": 9,
    "01001037": 8,
    "010010410": 50,
    "01001044": 100,
    "01001045": 240,
    "01001055": 190,
    "01001057": 85,
    "010010612": 4,
    "01001065": 26,
    "01001067": 5,
    "01001075": 35,
    "01001077": 30,
    "01001085": 45,
    "01001087": 20,
    "01001094": 5,
    "01001095": 25,
    "01001097": 5,
    "010011012": 2,
    "01001104": 1,
    "01001105": 5,
    "01001107": 2,
    "010011112": 12,
    "01001114": 8,
    "01001115": 50,
    "010011211": 70,
    "01001124": 100,
    "01001125": 200,
    "01001134": 393,
    "01001135": 392,
    "01001144": 10,
    "01001145": 10,
    "01001154": 230,
    "01001164": 77,
    "01001165": 77,
    "01001166": 76,
    "01001174": 25,
    "01001175": 25,
    "01001184": 25,
    "01001188": 25,
    "01001194": 25,
    "01001198": 25,
    "01001204": 5,
    "01001208": 5,
    "01001214": 8,
    "01001218": 7,
    "01001224": 15,
    "01001228": 15,
    "01001234": 5,
    "01001238": 5,
    "01001244": 25,
    "01001248": 10,
    "01001254": 10,
    "01001255": 10,
    "01001256": 15,
    "01001258": 5,
    "01001259": 5,
    "010012610": 20,
    "01001264": 5,
    "01001265": 5,
    "01001266": 8,
    "01001268": 5,
    "01001269": 7,
    "010012710": 35,
    "01001274": 10,
    "01001279": 5,
    "01001284": 8,
    "01001288": 7,
    "0100129410": 3,
    "0100129810": 3,
    "0100129910": 4,
    "01001302": 195,
    "0100131": 39,
    "0100132": 39,
    "01001332": 195,
    "01201014": 22,
    "01201018": 3,
    "01201024": 35,
    "01201028": 5,
    "012010310": 2,
    "01201034": 2,
    "01201039": 16,
    "012010410": 14,
    "01201044": 3,
    "01201049": 3,
    "01201054": 2,
    "01201058": 3,
    "0120106410": 10,
    "0120106810": 10,
    "0120106910": 10,
    "012010710": 6,
    "01201074": 2,
    "01201075": 4,
    "01201076": 4,
    "01201078": 2,
    "01201079": 2,
    "01201084": 3,
    "01201085": 3,
    "01201086": 14,
    "012010910": 2,
    "01201094": 4,
    "01201098": 4,
    "01201105": 20,
    "01201107": 25,
    "01201114": 5,
    "01201115": 10,
    "01201117": 10,
    "01201125": 30,
    "01201127": 5,
    "01201135": 10,
    "01201137": 15,
    "01201145": 2,
    "01201147": 3,
    "01201155": 2,
    "01201157": 3,
    "01201165": 2,
    "01201167": 3,
    "01201175": 2,
    "01201177": 3,
    "01201185": 3,
    "01201187": 2,
    "01201194": 30,
    "01201204": 20,
    "01201205": 30,
    "01201214": 5,
    "01201215": 20,
    "012012210": 3,
    "01201224": 3,
    "01201225": 24,
    "012012311": 2,
    "01201234": 2,
    "01201235": 14,
    "01201236": 2,
    "012012411": 3,
    "01201244": 3,
    "01201245": 21,
    "01201246": 3,
    "01201254": 15,
    "01201255": 85,
    "012012611": 39,
    "01201264": 11,
    "01201265": 60,
    "01201272": 42,
    "01201282": 42,
    "0120129": 8,
    "0120130": 8,
    "01401015": 50,
    "01401017": 10,
    "01401025": 10,
    "01401027": 30,
    "01401035": 5,
    "01401037": 15,
    "01401045": 25,
    "01401047": 25,
    "01401054": 10,
    "01401055": 50,
    "01401064": 20,
    "01401065": 30,
    "01401074": 50,
    "01401084": 20,
    "014010911": 5,
    "01401094": 5,
    "01401095": 40,
    "014011011": 10,
    "01401104": 10,
    "01401105": 60,
    "01401106": 10,
    "01401114": 35,
    "01401118": 5,
    "014011210": 5,
    "01401124": 10,
    "01401125": 10,
    "01401126": 5,
    "01401128": 5,
    "01401129": 5,
    "014011310": 10,
    "01401134": 10,
    "01401135": 10,
    "01401139": 10,
    "014011410": 10,
    "01401144": 10,
    "01401145": 15,
    "01401149": 10,
    "01401152": 24,
    "01401162": 24,
    "0140117": 0,
    "0140118": 0,
    "01401192": 18,
    "01401202": 18,
    "0140121": 0,
    "0140122": 0,
    "01901014": 20,
    "01901015": 30,
    "01901024": 50,
    "019010311": 20,
    "01901034": 10,
    "01901035": 20,
    "01901044": 10,
    "01901045": 20,
    "01901046": 20,
    "0200101410": 40,
    "0200101810": 40,
    "0200101910": 50,
    "0200102410": 18,
    "0200102810": 18,
    "0200102910": 24,
    "020010311": 5,
    "02001034": 5,
    "02001035": 30,
    "020010411": 4,
    "02001044": 4,
    "02001045": 11,
    "02001046": 12,
    "02001047": 4,
    "02001054": 10,
    "02001055": 22,
    "02001064": 20,
    "02001068": 5,
    "02001074": 5,
    "02001075": 15,
    "02001076": 15,
    "020010811": 3,
    "02001084": 3,
    "02001085": 9,
    "02001086": 10,
    "02001095": 15,
    "02001097": 5,
    "0330101410": 36,
    "0330101810": 36,
    "0330101910": 48,
    "0330102410": 12,
    "0330102810": 12,
    "0330102910": 16,
    "033010311": 40,
    "03301034": 20,
    "03301035": 90,
    "033010411": 25,
    "03301044": 10,
    "03301045": 45,
    "033010511": 10,
    "03301054": 6,
    "03301055": 25,
    "03301056": 9,
    "033010611": 5,
    "03301064": 3,
    "03301065": 12,
    "03301066": 7,
    "03301074": 40,
    "03301075": 40,
    "03301084": 50,
    "03301085": 50,
    "03301094": 15,
    "03301095": 5,
    "03301104": 50,
    "03301114": 10,
    "03301115": 30,
    "03301116": 40,
    "03301124": 5,
    "03301125": 20,
    "03301126": 25,
    "033011311": 20,
    "03301134": 9,
    "03301135": 25,
    "03301136": 15,
    "03301137": 11,
    "033011410": 49,
    "03301144": 11,
    "03301145": 40,
    "03601014": 145,
    "03601015": 35,
    "036010211": 8,
    "03601024": 8,
    "03601025": 51,
    "03601026": 8,
    "036010311": 12,
    "03601034": 8,
    "03601035": 55,
    "03601044": 49,
    "03601048": 6,
    "03601054": 30,
    "03601058": 10,
    "03601064": 15,
    "03601068": 7,
    "03601074": 7,
    "03601075": 56,
    "03601076": 7,
    "0360108": 33,
    "03601094": 27,
    "03601098": 3,
    "03601104": 20,
    "03601108": 7,
    "0400101410": 10,
    "0400101810": 10,
    "0400101910": 10,
    "0400102410": 15,
    "0400102810": 15,
    "0400102910": 15,
    "04701014": 10,
    "04701015": 10,
    "04701024": 3,
    "04701025": 2,
    "047010311": 5,
    "04701034": 5,
    "04701035": 10,
    "0470104410": 15,
    "0470104810": 15,
    "0470104910": 15,
    "0470105410": 5,
    "0470105810": 5,
    "0470105910": 5,
    "0470106410": 2,
    "0470106810": 2,
    "0470106910": 2,
    "0470107410": 7,
    "0470107810": 6,
    "0470107910": 7,
    "047010810": 5,
    "04701084": 5,
    "04701085": 15,
    "0520101410": 18,
    "0520101810": 18,
    "0520101910": 24,
    "0520102410": 10,
    "0520102810": 10,
    "0520102910": 12,
    "05301014": 160,
    "05301016": 20,
    "05301018": 20,
    "05301024": 19,
    "05301026": 3,
    "05301028": 3,
    "05301034": 112,
    "05301038": 13,
    "05301044": 58,
    "05301048": 7,
    "05301054": 18,
    "05301058": 2,
    "05301064": 12,
    "05301065": 108,
    "05301074": 15,
    "05301084": 15,
    "05301092": 33,
    "05301102": 33,
    "0530111": 7,
    "0530112": 7,
    "06401014": 7,
    "06401015": 43,
    "06401017": 10,
    "06401024": 35,
    "06401028": 5,
    "06401034": 30,
    "06401035": 30,
    "06401044": 40,
    "06401054": 30,
    "06401065": 60,
    "06401067": 10,
    "06401074": 63,
    "06401078": 7,
    "06401084": 63,
    "06401088": 7,
    "06401094": 20,
    "06401095": 20,
    "06401104": 15,
    "06401105": 15,
    "06401114": 20,
    "06401115": 20,
    "06401124": 15,
    "06401125": 15,
    "064011311": 5,
    "06401134": 5,
    "06401135": 10,
    "06401136": 5,
    "06401137": 25,
    "06401144": 40,
    "06401154": 30,
    "064011611": 15,
    "06401164": 10,
    "06401165": 30,
    "06401166": 10,
    "064011711": 15,
    "06401174": 10,
    "06401175": 30,
    "06401176": 10,
    "064011811": 30,
    "06401184": 8,
    "06401185": 32,
    "064011911": 20,
    "06401194": 10,
    "06401195": 50,
    "06401196": 20,
    "06401204": 25,
    "06401205": 10,
    "06401206": 15,
    "06401214": 15,
    "06401215": 10,
    "06401216": 10,
    "06401224": 40,
    "06401234": 30,
    "06601014": 3,
    "06601015": 22,
    "066010210": 5,
    "06601024": 5,
    "06601025": 40,
    "06601032": 10,
    "0660104": 2,
    "0660105": 2,
    "06601062": 10,
    "071010110": 5,
    "07101014": 5,
    "07101015": 35,
    "07101019": 5,
    "071010210": 11,
    "07101024": 11,
    "07101025": 66,
    "07101028": 11,
    "07101029": 11,
    "07101034": 4,
    "07101035": 36,
    "07101045": 15,
    "07101047": 20,
    "07101055": 36,
    "07101057": 4,
    "07101065": 22,
    "07101067": 3,
    "071010710": 19,
    "07101074": 3,
    "07101079": 3,
    "07101084": 9,
    "07101088": 1,
    "07101094": 10,
    "07101095": 20,
    "07101104": 3,
    "07101105": 10,
    "07101106": 12,
    "07101114": 45,
    "071011211": 6,
    "07101124": 4,
    "07101125": 30,
    "071011311": 3,
    "07101134": 3,
    "07101135": 9,
    "07101136": 10,
    "07101142": 28,
    "07101152": 28,
    "0710116": 6,
    "0710117": 6,
    "08501014": 50,
    "08501015": 100,
    "08501025": 90,
    "08501027": 10,
    "0850103410": 16,
    "0850103810": 16,
    "0850103910": 18,
    "085010411": 9,
    "08501044": 9,
    "08501045": 54,
    "08501046": 9,
    "08501047": 9,
    "085010511": 9,
    "08501054": 9,
    "08501055": 63,
    "08501056": 9,
    "085010611": 40,
    "08501064": 15,
    "08501065": 95,
    "0850107410": 7,
    "0850107810": 7,
    "0850107910": 7,
    "08501084": 15,
    "08501085": 120,
    "08501086": 15,
    "085010910": 10,
    "08501094": 10,
    "08501095": 80,
    "08801014": 24,
    "08801015": 56,
    "08801024": 5,
    "08801025": 5,
    "08801034": 8,
    "08801035": 36,
    "08801036": 36,
    "088010410": 9,
    "08801044": 9,
    "08801045": 72,
    "088010511": 6,
    "08801054": 3,
    "08801055": 18,
    "08801056": 3,
    "088010611": 6,
    "08801064": 18,
    "08801065": 3,
    "08801066": 3,
    "08801074": 5,
    "08801075": 20,
    "08801085": 12,
    "08801087": 3,
    "088010911": 24,
    "08801094": 12,
    "08801095": 84,
    "09701014": 15,
    "09701015": 15,
    "09701024": 4,
    "09701025": 6,
    "09701035": 10,
    "09701037": 5,
    "09701045": 10,
    "09701047": 5,
    "097010511": 7,
    "09701054": 3,
    "09701055": 20,
    "097010611": 7,
    "09701064": 6,
    "09701065": 40,
    "09701066": 7,
    "0970107410": 3,
    "0970107810": 3,
    "0970107910": 4,
    "09701085": 8,
    "09701087": 7,
    "09701092": 10,
    "0970110": 2,
    "0970111": 2,
    "09701122": 10,
    "09801014": 58,
    "09801018": 7,
    "09801024": 13,
    "09801028": 2,
    "09801034": 13,
    "09801038": 2,
    "0980104": 28,
    "0980105": 10,
    "0980106": 10,
    "0980107": 10,
    "0980108": 10,
    "0980109": 10,
    "0980110": 10,
    "0980111": 7,
    "0980112": 40,
    "0980113": 6,
    "0980114": 7,
    "0980115": 6,
    "0980116": 5,
    "0980117": 7,
    "0980118": 6,
    "098011912": 15,
    "09801194": 10,
    "09801195": 5,
    "098012012": 7,
    "09801204": 2,
    "09801205": 1,
    "0980121": 10,
    "098012212": 7,
    "09801225": 7,
    "09801227": 6,
    "09801234": 2,
    "09801235": 5,
    "09801239": 3,
    "09801244": 1,
    "09801245": 2,
    "09801248": 1,
    "09801249": 1,
    "0980125": 4,
    "09801262": 20,
    "0980127": 4,
    "09801282": 20
};

export const getProgramPlaces2020 = (
	programCode: IRProgram["code"],
	electiveSubjects?: number[]
): number => {
	let key = programCode;
	if (electiveSubjects)
		key += electiveSubjects.sort((a, b) => a - b).join("");
	return placesByProgramsAndSubjects[key] || -1;
};

const grantLimitScores: [number, number, number][] = [
	[Infinity, Infinity, Infinity],
	[Infinity, Infinity, Infinity], // ქართული
	[6216.5, 6092.0, 5741.0], // ზოგადი უნარები
	[Infinity, Infinity, Infinity], // უცხოური ენა
	[6102.0, 5964.5, 5713.5], // მათემატიკა
	[6086.0, 5990.5, 5810.5], // ისტორია
	[5817.0, 5520.0, 4934.5], // გეოგრაფია
	[5856.5, 5714.5, 5280.0], // ლიტერატურა
	[5651.0, 5116.5, 4769.0], // ფიზიკა
	[5981.0, 5886.0, 5638.0], // ქიმია
	[5992.0, 5907.0, 5719.5], // ბიოლოგია
	[5881.0, 5616.0, 4879.0], // სამოქალაქო განათლება
	[5906.5, 5815.5, 5284.0], // ხელოვნება
	[Infinity, Infinity, Infinity], // შემოქმედებითი ტური
	[Infinity, Infinity, Infinity], // ზოგადი უნარები (აზერ)
	[Infinity, Infinity, Infinity], // ზოგადი უნარები (სომხ)
]; // 2020

export { scaledScores, grantLimitScores };

export const getProgramPlaces = (
	program: IRProgram,
	electiveSubjects: number[]
): number => {
	if (!program.numsBySubjs && !program.numsBySubjCombs)
		return program.totalPlaces;
	else if (program.numsBySubjs && !program.numsBySubjCombs)
		return program.numsBySubjs[electiveSubjects[0]];
	else if (electiveSubjects.length > 1)
		return program.numsBySubjCombs![getSubjectKey(electiveSubjects)];
	return -1; // Error
};

export const getSubjectKey = memoizeOne((subj_ids: number[]): string =>
	subj_ids.sort((a, b) => a - b).join(",")
);

export function toScaledScore(score: number, subjectId: number): number {
	const result = scaledScores[subjectId][score];
	if (isNaN(result)) {
		throw new Error(
			"scaled score for subject " +
				subjectId +
				" and score " +
				score +
				" does not exist"
		);
	}
	return result;
}

(window as any).toScaledScore = toScaledScore;

export function scaledScoreToScore(
	scaledScore: number,
	subjectId: number
): number {
	const subject = erovnulSubjects.find(s => s.id === subjectId);
	if (!subject) throw new Error(`subject ${subjectId} not found`);
	if (
		typeof subject.minScore === "undefined" ||
		typeof subject.maxScore === "undefined"
	) {
		throw new Error("incorrect subjectId");
	}

	const minScore: number = subject.minScore as number;
	const maxScore: number = subject.maxScore as number;
	if (scaledScore < minScore) return minScore;
	for (let i = minScore; i < maxScore; i++) {
		if (
			scaledScores[subjectId][i] >= scaledScore &&
			scaledScore < scaledScores[subjectId][i + 1]
		)
			return i;
	}
	return maxScore;
}

export function scoresToScaledScore(
	rawScores: number[],
	subjectsIds: number[],
	coefficients: IRProgram["coefficients"]
): number {
	subjectsIds = subjectsIds.filter(subj => subj !== 13);
	if (rawScores.length !== subjectsIds.length) {
		throw new Error(
			"Lengths not equal of " + rawScores + " and " + subjectsIds
		);
	}
	if (coefficients === null) {
		return 0;
		// throw new Error("coefficients is null");
	}
	// console.log('subjs: ', subjectsIds, ' scores: ', scores);
	if (
		!erovnulSubjects
			.filter(subj => subj.isCompulsory)
			.every(subj => subjectsIds.indexOf(subj.id) !== -1)
	) {
		throw new Error("not all compulsory subjects are presented");
	}

	let scaledScore = 0;
	for (let i = 0; i < subjectsIds.length; ++i) {
		const coefficient = coefficients[subjectsIds[i]];
		if (coefficient === 0) continue;
		const inc = toScaledScore(rawScores[i], subjectsIds[i]) * coefficient;
		if (isNaN(inc)) {
			throw new Error("scaled score is not a number");
		}
		scaledScore += inc;
	}

	return scaledScore;
}

function isUniNotAProgram(
	something: IRUniversity | IRProgram
): something is IRUniversity {
	return (something as IRProgram).uni_id === undefined;
}
function isProgramNotAUni(
	something: IRUniversity | IRProgram
): something is IRUniversity {
	return (something as IRProgram).uni_id !== undefined;
}
export { isUniNotAProgram, isProgramNotAUni };

export const shortenName = (name: string, maxLength = 75): string => {
	return name.length <= maxLength
		? name
		: name.substr(0, maxLength - 2).trim() + "..";
};

export function getHTMLElementCoords(elem: HTMLElement) {
	// crossbrowser version
	const box = elem.getBoundingClientRect();

	const body = document.body;
	const docEl = document.documentElement;

	const scrollTop = window.pageYOffset || docEl!.scrollTop || body.scrollTop;
	const scrollLeft =
		window.pageXOffset || docEl!.scrollLeft || body.scrollLeft;

	const clientTop = docEl!.clientTop || body.clientTop || 0;
	const clientLeft = docEl!.clientLeft || body.clientLeft || 0;

	const top = box.top + scrollTop - clientTop;
	const left = box.left + scrollLeft - clientLeft;

	return { top, left };
}
(window as any).getHTMLElementCoords = getHTMLElementCoords;

export function extractVideoIdFromYoutubeLink(link: string): string {
	if (link.indexOf("youtu.be") >= 0) {
		const endIndex = link.indexOf("?");
		return link.substring(
			link.lastIndexOf("/") + 1,
			endIndex > -1 ? endIndex : undefined
		);
	}
	if (link.indexOf("youtube") >= 0) {
		const endIndex = link.indexOf("&");
		return link.substring(
			link.indexOf("=") + 1,
			endIndex > -1 ? endIndex : undefined
		);
	}
	return "";
}

export function getVideoThumbNailUrl(videoLink: string): string {
	const youtubeVideoId = extractVideoIdFromYoutubeLink(videoLink);
	return `https://img.youtube.com/vi/${youtubeVideoId}/0.jpg`;
}

export const getGalleryMediaUrl = (
	type: "PHOTO" | "VIDEO" | "PDF",
	url: string
): string => {
	if (type === "PHOTO") {
		return `/photos/gallery/${url}`;
	} else if (type === "PDF") {
		return `/files/uni-content/${url}`;
	} else return getVideoThumbNailUrl(url);
};

export const getNewsOrEventMediaUrl = (
	type: "PHOTO" | "VIDEO",
	url: string
): string => {
	if (type === "PHOTO") {
		return `/photos/etc/${url}`;
	}
	return getVideoThumbNailUrl(url);
};

export const getExpoBannerUrl = (url: string) => `/photos/etc/${url}`;

export function roundNumber(num: number | string, scale: number) {
	if (!("" + num).includes("e")) {
		return +(Math.round(+(num + "e+" + scale)) + "e-" + scale);
	}
	const arr = ("" + num).split("e");
	let sig = "";
	if (+arr[1] + scale > 0) {
		sig = "+";
	}
	return +(
		Math.round(+(+arr[0] + "e" + sig + (+arr[1] + scale))) +
		"e-" +
		scale
	);
}

export const programsWithCompulsoryBiology: number[] = [
	56,
	57,
	58,
	118,
	190,
	191,
	192,
	193,
	194,
	195,
	196,
	197,
	198,
	225,
	226,
	227,
	228,
	229,
	274,
	340,
	341,
	349,
	371,
	372,
	377,
	381,
	382,
	469,
	539,
	540,
	541,
	542,
	557,
	558,
	559,
	569,
	618,
	620,
	622,
	669,
	730,
	735,
	736,
	737,
	739,
	767,
	769,
	774,
	778,
	787,
	817,
	819,
	822,
	840,
	864,
	865,
];

export interface ICountry {
	name: string;
	code: string;
}

export const COUNTRIES_LIST: ICountry[] = [
	{ name: "Afghanistan", code: "AF" },
	{ name: "Albania", code: "AL" },
	{ name: "Algeria", code: "DZ" },
	{ name: "American Samoa", code: "AS" },
	{ name: "Andorra", code: "AD" },
	{ name: "Angola", code: "AO" },
	{ name: "Anguilla", code: "AI" },
	{ name: "Antarctica", code: "AQ" },
	{ name: "Antigua and Barbuda", code: "AG" },
	{ name: "Argentina", code: "AR" },
	{ name: "Armenia", code: "AM" },
	{ name: "Aruba", code: "AW" },
	{ name: "Australia", code: "AU" },
	{ name: "Austria", code: "AT" },
	{ name: "Azerbaijan", code: "AZ" },
	{ name: "Bahamas", code: "BS" },
	{ name: "Bahrain", code: "BH" },
	{ name: "Bangladesh", code: "BD" },
	{ name: "Barbados", code: "BB" },
	{ name: "Belarus", code: "BY" },
	{ name: "Belgium", code: "BE" },
	{ name: "Belize", code: "BZ" },
	{ name: "Benin", code: "BJ" },
	{ name: "Bermuda", code: "BM" },
	{ name: "Bhutan", code: "BT" },
	{ name: "Bolivia", code: "BO" },
	{ name: "Bosnia and Herzegovina", code: "BA" },
	{ name: "Botswana", code: "BW" },
	{ name: "Bouvet Island", code: "BV" },
	{ name: "Brazil", code: "BR" },
	{ name: "British Indian Ocean Territory", code: "IO" },
	{ name: "Brunei Darussalam", code: "BN" },
	{ name: "Bulgaria", code: "BG" },
	{ name: "Burkina Faso", code: "BF" },
	{ name: "Burundi", code: "BI" },
	{ name: "Cambodia", code: "KH" },
	{ name: "Cameroon", code: "CM" },
	{ name: "Canada", code: "CA" },
	{ name: "Cape Verde", code: "CV" },
	{ name: "Cayman Islands", code: "KY" },
	{ name: "Central African Republic", code: "CF" },
	{ name: "Chad", code: "TD" },
	{ name: "Chile", code: "CL" },
	{ name: "China", code: "CN" },
	{ name: "Christmas Island", code: "CX" },
	{ name: "Cocos (Keeling) Islands", code: "CC" },
	{ name: "Colombia", code: "CO" },
	{ name: "Comoros", code: "KM" },
	{ name: "Congo", code: "CG" },
	{ name: "Congo, The Democratic Republic of the", code: "CD" },
	{ name: "Cook Islands", code: "CK" },
	{ name: "Costa Rica", code: "CR" },
	{ name: 'Cote D"Ivoire', code: "CI" },
	{ name: "Croatia", code: "HR" },
	{ name: "Cuba", code: "CU" },
	{ name: "Cyprus", code: "CY" },
	{ name: "Czech Republic", code: "CZ" },
	{ name: "Denmark", code: "DK" },
	{ name: "Djibouti", code: "DJ" },
	{ name: "Dominica", code: "DM" },
	{ name: "Dominican Republic", code: "DO" },
	{ name: "Ecuador", code: "EC" },
	{ name: "Egypt", code: "EG" },
	{ name: "El Salvador", code: "SV" },
	{ name: "Equatorial Guinea", code: "GQ" },
	{ name: "Eritrea", code: "ER" },
	{ name: "Estonia", code: "EE" },
	{ name: "Ethiopia", code: "ET" },
	{ name: "Falkland Islands (Malvinas)", code: "FK" },
	{ name: "Faroe Islands", code: "FO" },
	{ name: "Fiji", code: "FJ" },
	{ name: "Finland", code: "FI" },
	{ name: "France", code: "FR" },
	{ name: "French Guiana", code: "GF" },
	{ name: "French Polynesia", code: "PF" },
	{ name: "French Southern Territories", code: "TF" },
	{ name: "Gabon", code: "GA" },
	{ name: "Gambia", code: "GM" },
	{ name: "Georgia", code: "GE" },
	{ name: "Germany", code: "DE" },
	{ name: "Ghana", code: "GH" },
	{ name: "Gibraltar", code: "GI" },
	{ name: "Greece", code: "GR" },
	{ name: "Greenland", code: "GL" },
	{ name: "Grenada", code: "GD" },
	{ name: "Guadeloupe", code: "GP" },
	{ name: "Guam", code: "GU" },
	{ name: "Guatemala", code: "GT" },
	{ name: "Guernsey", code: "GG" },
	{ name: "Guinea", code: "GN" },
	{ name: "Guinea-Bissau", code: "GW" },
	{ name: "Guyana", code: "GY" },
	{ name: "Haiti", code: "HT" },
	{ name: "Heard Island and Mcdonald Islands", code: "HM" },
	{ name: "Holy See (Vatican City State)", code: "VA" },
	{ name: "Honduras", code: "HN" },
	{ name: "Hong Kong", code: "HK" },
	{ name: "Hungary", code: "HU" },
	{ name: "Iceland", code: "IS" },
	{ name: "India", code: "IN" },
	{ name: "Indonesia", code: "ID" },
	{ name: "Iran, Islamic Republic Of", code: "IR" },
	{ name: "Iraq", code: "IQ" },
	{ name: "Ireland", code: "IE" },
	{ name: "Isle of Man", code: "IM" },
	{ name: "Israel", code: "IL" },
	{ name: "Italy", code: "IT" },
	{ name: "Jamaica", code: "JM" },
	{ name: "Japan", code: "JP" },
	{ name: "Jersey", code: "JE" },
	{ name: "Jordan", code: "JO" },
	{ name: "Kazakhstan", code: "KZ" },
	{ name: "Kenya", code: "KE" },
	{ name: "Kiribati", code: "KI" },
	{ name: 'Korea, Democratic People"S Republic of', code: "KP" },
	{ name: "Korea, Republic of", code: "KR" },
	{ name: "Kuwait", code: "KW" },
	{ name: "Kyrgyzstan", code: "KG" },
	{ name: 'Lao People"S Democratic Republic', code: "LA" },
	{ name: "Latvia", code: "LV" },
	{ name: "Lebanon", code: "LB" },
	{ name: "Lesotho", code: "LS" },
	{ name: "Liberia", code: "LR" },
	{ name: "Libyan Arab Jamahiriya", code: "LY" },
	{ name: "Liechtenstein", code: "LI" },
	{ name: "Lithuania", code: "LT" },
	{ name: "Luxembourg", code: "LU" },
	{ name: "Macao", code: "MO" },
	{ name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
	{ name: "Madagascar", code: "MG" },
	{ name: "Malawi", code: "MW" },
	{ name: "Malaysia", code: "MY" },
	{ name: "Maldives", code: "MV" },
	{ name: "Mali", code: "ML" },
	{ name: "Malta", code: "MT" },
	{ name: "Marshall Islands", code: "MH" },
	{ name: "Martinique", code: "MQ" },
	{ name: "Mauritania", code: "MR" },
	{ name: "Mauritius", code: "MU" },
	{ name: "Mayotte", code: "YT" },
	{ name: "Mexico", code: "MX" },
	{ name: "Micronesia, Federated States of", code: "FM" },
	{ name: "Moldova, Republic of", code: "MD" },
	{ name: "Monaco", code: "MC" },
	{ name: "Mongolia", code: "MN" },
	{ name: "Montenegro", code: "ME" },
	{ name: "Montserrat", code: "MS" },
	{ name: "Morocco", code: "MA" },
	{ name: "Mozambique", code: "MZ" },
	{ name: "Myanmar", code: "MM" },
	{ name: "Namibia", code: "NA" },
	{ name: "Nauru", code: "NR" },
	{ name: "Nepal", code: "NP" },
	{ name: "Netherlands", code: "NL" },
	{ name: "Netherlands Antilles", code: "AN" },
	{ name: "New Caledonia", code: "NC" },
	{ name: "New Zealand", code: "NZ" },
	{ name: "Nicaragua", code: "NI" },
	{ name: "Niger", code: "NE" },
	{ name: "Nigeria", code: "NG" },
	{ name: "Niue", code: "NU" },
	{ name: "Norfolk Island", code: "NF" },
	{ name: "Northern Mariana Islands", code: "MP" },
	{ name: "Norway", code: "NO" },
	{ name: "Oman", code: "OM" },
	{ name: "Pakistan", code: "PK" },
	{ name: "Palau", code: "PW" },
	{ name: "Palestinian Territory, Occupied", code: "PS" },
	{ name: "Panama", code: "PA" },
	{ name: "Papua New Guinea", code: "PG" },
	{ name: "Paraguay", code: "PY" },
	{ name: "Peru", code: "PE" },
	{ name: "Philippines", code: "PH" },
	{ name: "Pitcairn", code: "PN" },
	{ name: "Poland", code: "PL" },
	{ name: "Portugal", code: "PT" },
	{ name: "Puerto Rico", code: "PR" },
	{ name: "Qatar", code: "QA" },
	{ name: "Reunion", code: "RE" },
	{ name: "Romania", code: "RO" },
	{ name: "Russian Federation", code: "RU" },
	{ name: "RWANDA", code: "RW" },
	{ name: "Saint Helena", code: "SH" },
	{ name: "Saint Kitts and Nevis", code: "KN" },
	{ name: "Saint Lucia", code: "LC" },
	{ name: "Saint Pierre and Miquelon", code: "PM" },
	{ name: "Saint Vincent and the Grenadines", code: "VC" },
	{ name: "Samoa", code: "WS" },
	{ name: "San Marino", code: "SM" },
	{ name: "Sao Tome and Principe", code: "ST" },
	{ name: "Saudi Arabia", code: "SA" },
	{ name: "Senegal", code: "SN" },
	{ name: "Serbia", code: "RS" },
	{ name: "Seychelles", code: "SC" },
	{ name: "Sierra Leone", code: "SL" },
	{ name: "Singapore", code: "SG" },
	{ name: "Slovakia", code: "SK" },
	{ name: "Slovenia", code: "SI" },
	{ name: "Solomon Islands", code: "SB" },
	{ name: "Somalia", code: "SO" },
	{ name: "South Africa", code: "ZA" },
	{ name: "South Georgia and the South Sandwich Islands", code: "GS" },
	{ name: "Spain", code: "ES" },
	{ name: "Sri Lanka", code: "LK" },
	{ name: "Sudan", code: "SD" },
	{ name: "Suriname", code: "SR" },
	{ name: "Svalbard and Jan Mayen", code: "SJ" },
	{ name: "Swaziland", code: "SZ" },
	{ name: "Sweden", code: "SE" },
	{ name: "Switzerland", code: "CH" },
	{ name: "Syrian Arab Republic", code: "SY" },
	{ name: "Taiwan, Province of China", code: "TW" },
	{ name: "Tajikistan", code: "TJ" },
	{ name: "Tanzania, United Republic of", code: "TZ" },
	{ name: "Thailand", code: "TH" },
	{ name: "Timor-Leste", code: "TL" },
	{ name: "Togo", code: "TG" },
	{ name: "Tokelau", code: "TK" },
	{ name: "Tonga", code: "TO" },
	{ name: "Trinidad and Tobago", code: "TT" },
	{ name: "Tunisia", code: "TN" },
	{ name: "Turkey", code: "TR" },
	{ name: "Turkmenistan", code: "TM" },
	{ name: "Turks and Caicos Islands", code: "TC" },
	{ name: "Tuvalu", code: "TV" },
	{ name: "Uganda", code: "UG" },
	{ name: "Ukraine", code: "UA" },
	{ name: "United Arab Emirates", code: "AE" },
	{ name: "United Kingdom", code: "GB" },
	{ name: "United States", code: "US" },
	{ name: "United States Minor Outlying Islands", code: "UM" },
	{ name: "Uruguay", code: "UY" },
	{ name: "Uzbekistan", code: "UZ" },
	{ name: "Vanuatu", code: "VU" },
	{ name: "Venezuela", code: "VE" },
	{ name: "Viet Nam", code: "VN" },
	{ name: "Virgin Islands, British", code: "VG" },
	{ name: "Virgin Islands, U.S.", code: "VI" },
	{ name: "Wallis and Futuna", code: "WF" },
	{ name: "Western Sahara", code: "EH" },
	{ name: "Yemen", code: "YE" },
	{ name: "Zambia", code: "ZM" },
	{ name: "Zimbabwe", code: "ZW" },
];

export const isMainAdmin = (permissions: IRUser["permissions"]): boolean =>
	!!permissions &&
	!!permissions.privilege &&
	!!permissions.privilege["allUnis"];

export const useWindowSize = () => {
	const [state, setState] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handler = () => {
			setState({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener("resize", handler);
		return () => window.removeEventListener("resize", handler);
	}, []);

	return state;
};

export const YOUTUBE_API_KEY = "AIzaSyDWcBreIZeICrwcCcEXQkEDpY6FHMb5F18";
