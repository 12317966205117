import * as React from "react";
import ContentLoader from "react-content-loader";

interface IPaginatorProps {
	numOfPages: number;
	currentPage: number;
	onPageClick: (page: number) => () => any;
}
const Paginator: React.SFC<IPaginatorProps> = props => (
	<div className="mur-paginator">
		{Array(props.numOfPages).fill(0).map((e, index) => (
			<div
				key={index}
				onClick={props.onPageClick(index)}
				className={"paginatorPageNum" + (props.currentPage === index ? " paginatorPageNumCurrent" : "") }
			>
				{index + 1}
			</div>
		))}
	</div>
);

export default Paginator;

interface IPageNumLoaderProps {
	numOfPages?: number;
}
const PageNumLoader: React.SFC<IPageNumLoaderProps> = ({ numOfPages = 5 }) => (
	<div className="mur-paginator">
		{Array(numOfPages).fill(0).map((e, index) => (
			<div className="fakePaginatorPageNum" key={index}>
				<ContentLoader width={30} height={30}>
					<rect x="0" y="0" rx="4" ry="4" width="30" height="30" />
				</ContentLoader>
			</div>
		))}
	</div>
);

export { PageNumLoader };
