import React from "react";
import { getLocale } from "@app/hooks/intl";
import { loadAndUpdateIntlMessages } from "@app/intl";
import GeFlag from "./styles/img/flags/ge.png";
import GBFlag from "./styles/img/flags/gb.png";

export const LocaleSwitcher: React.FC = () => {
	const size = 32;
	// const locale = getLocale();
	return (
		<div className="locale-switch">
			<img
				alt="flag ge"
				src={GeFlag}
				width={size}
				className="flag left-flag"
				onClick={() => loadAndUpdateIntlMessages("ka", null)}
			/>
			<img
				alt="flag gb"
				src={GBFlag}
				width={size}
				className="flag right-flag"
				onClick={() => loadAndUpdateIntlMessages("en", null)}
			/>
		</div>
	);
};

export const LocaleSwitcherMobile: React.FC = () => {
	const size = 16;
	// const locale = getLocale();
	return (
		<div className="locale-switch-mobile">
			<img
				alt="flag ge"
				src={GeFlag}
				width={size}
				className="flag left-flag"
				onClick={() => loadAndUpdateIntlMessages("ka", null)}
			/>
			<img
				alt="flag gb"
				src={GBFlag}
				width={size}
				className="flag right-flag"
				onClick={() => loadAndUpdateIntlMessages("en", null)}
			/>
		</div>
	);
};
