import React from "react";
import styled from "@emotion/styled";

export const Table = styled.table`
	font-size: 12px;
	font-family: "Roboto Geo Nus";
	border-spacing: 0px;
`;

export const TableHead = styled.thead`
	text-align: left;
	font-weight: bold;
	color: #808080;
`;

export const TableBody = styled.tbody``;
export const TableCell = styled.td``;

export const TableRow = styled.tr`
	td:first-of-type,
	th:first-of-type {
		padding-left: 12px;
	}
	td:last-of-type,
	th:last-of-type {
		padding-right: 12px;
	}
	border-bottom: 1px solid #e3e2e2;
	&:last-of-type {
		border-bottom: none;
	}
	&:hover {
		background: #e6e6e6;
	}
`;
export const TopStickyTableHeadCell = styled.th`
	position: sticky;
	top: 0;
	background: white;
	padding-top: 12px;
	padding-bottom: 12px;
	&:first-of-type {
		border-top-left-radius: 4px;
	}
	&:last-of-type {
		border-top-right-radius: 4px;
	}
`;
