import { IRProgram } from "@app/api/programs/helper-schemas";
import AddIcon from "@material-ui/icons/AddRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import * as React from "react";
import {
	erovnulSubjects,
	erovnulSubjectNames,
	getSubjectKey,
} from "../../commonJavascript";
import FancySelect from "../Widgets/FancySelect";

interface IProps {
	program: IRProgram;
	onChange: (
		name: "numsBySubjs" | "coefficients" | "minScoreLimits",
		newValue: number,
		subjId: number,
		subj2Id?: number
	) => void;
	onSubjectDelete: (subjId: number, type: SubjTypes) => void;
	onSubjectSwitch: (
		oldSubjId: number,
		newSubjId: number,
		type: SubjTypes
	) => void;
}

interface IState {
	addSubj?: SubjTypes;
}

export enum SubjTypes {
	COMPULSORY = 1,
	HALF_COMPULSORY = 2,
	ADDITIONAL_HALF_COMPULSORY = 3,
}

class ProgramEditTable extends React.Component<IProps, IState> {
	state = {} as IState;

	onCharacteristicChange = (
		name: "numsBySubjs" | "coefficients" | "minScoreLimits",
		subjId: number,
		subj2Id?: number
	) => (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(name, +e.target.value || 0, subjId, subj2Id);
	};

	onSubjectSwitch = (oldSubjectId: number, type: SubjTypes) => (
		newSubjectId: number
	) => {
		this.setState({ addSubj: undefined });
		this.props.onSubjectSwitch(oldSubjectId, newSubjectId, type);
	};

	onSubjectAdd = (type: SubjTypes) => this.setState({ addSubj: type });

	onSubjectDelete = (subjId: number, type: SubjTypes) => {
		if (subjId === 0) this.setState({ addSubj: undefined });
		else this.props.onSubjectDelete(subjId, type);
	};

	getHeaderBySubjType = (type: SubjTypes) => {
		switch (type) {
			case SubjTypes.COMPULSORY:
				return (
					<tr className="admin-table-head">
						<th>
							<span>სავალდებული საგნები</span>
						</th>
						<th>
							<span>კოეფიციენტი</span>
						</th>
						<th colSpan={2}>
							<span>ბარიერი</span>
						</th>
						<th></th>
					</tr>
				);
			case SubjTypes.HALF_COMPULSORY:
				return (
					<tr className="admin-table-head">
						<th>
							<span>სავალდებულო არჩევითი საგნები</span>
						</th>
						<th>
							<span>ადგილები</span>
						</th>
						<th>
							<span>კოეფიციენტი</span>
						</th>
						<th>
							<span>ბარიერი</span>
						</th>
						<th></th>
					</tr>
				);
			case SubjTypes.ADDITIONAL_HALF_COMPULSORY:
				return (
					<tr className="admin-table-head">
						<th>
							<span>დამატებითი სავალდებულო არჩევითი საგნები</span>
						</th>
						<th>
							<span>ადგილები</span>
						</th>
						<th>
							<span>კოეფიციენტი</span>
						</th>
						<th>
							<span>ბარიერი</span>
						</th>
						<th></th>
					</tr>
				);
			default:
				throw new Error("Incorrect subject type");
		}
	};

	//{"2-5":15,"2-4":5,"6-5":45,"6-4":5}
	render() {
		const subjects: IRProgram["subjects"] = this.props.program.subjects;
		return (
			<table className="editTable">
				<tbody>
					{subjects.map((subjGroup, groupIndex) => (
						<React.Fragment key={groupIndex}>
							{this.getHeaderBySubjType(groupIndex + 1)}
							{subjGroup.map((subjId, index) => (
								<ProgramEditTableRow
									key={index}
									subjId={subjId}
									subjType={groupIndex + 1}
									subjects={subjects}
									coefficients={
										this.props.program.coefficients
									}
									minScoreLimits={
										this.props.program.minScoreLimits
									}
									numsBySubjs={this.props.program.numsBySubjs}
									numsBySubjCombs={
										this.props.program.numsBySubjCombs
									}
									onCharacteristicChange={
										this.onCharacteristicChange
									}
									onSubjectSwitch={this.onSubjectSwitch}
									onSubjectDelete={this.onSubjectDelete}
								/>
							))}
							{this.state.addSubj === groupIndex + 1 && (
								<ProgramEditTableRow
									subjId={0}
									subjType={groupIndex + 1}
									subjects={subjects}
									coefficients={
										this.props.program.coefficients
									}
									minScoreLimits={
										this.props.program.minScoreLimits
									}
									numsBySubjs={this.props.program.numsBySubjs}
									numsBySubjCombs={
										this.props.program.numsBySubjCombs
									}
									onCharacteristicChange={
										this.onCharacteristicChange
									}
									onSubjectSwitch={this.onSubjectSwitch}
									onSubjectDelete={this.onSubjectDelete}
								/>
							)}
							<tr>
								<td
									colSpan={5}
									className="new-row"
									onClick={() =>
										this.onSubjectAdd(groupIndex + 1)
									}
								>
									<AddIcon />
								</td>
							</tr>
						</React.Fragment>
					))}
				</tbody>
			</table>
		);
	}
}

interface IRowProps {
	subjId: number;
	subjType: SubjTypes;
	subjects: IRProgram["subjects"];
	coefficients: IRProgram["coefficients"];
	minScoreLimits: IRProgram["minScoreLimits"];
	numsBySubjs: IRProgram["numsBySubjs"];
	numsBySubjCombs: IRProgram["numsBySubjCombs"];
	onSubjectSwitch: (
		oldSubjectId: number,
		type: SubjTypes
	) => (newSubjectId: number) => void;
	onCharacteristicChange: (
		name:
			| "numsBySubjs"
			| "coefficients"
			| "minScoreLimits"
			| "numsBySubjCombs",
		subjId: number,
		subj2Id?: number
	) => (e: React.ChangeEvent<HTMLInputElement>) => void;
	onSubjectDelete: (subjId: number, type: SubjTypes) => void;
}
export const ProgramEditTableRow: React.FC<IRowProps> = props => {
	return (
		<tr>
			<td>
				<FancySelect
					defaultValue={props.subjId || null}
					items={erovnulSubjects}
					onChange={props.onSubjectSwitch(
						props.subjId,
						props.subjType
					)}
				/>
			</td>
			{props.subjType === SubjTypes.HALF_COMPULSORY && (
				<td>
					<input
						type={"number"}
						value={
							(props.numsBySubjs &&
								props.numsBySubjs[props.subjId]) ||
							""
						}
						onChange={props.onCharacteristicChange(
							"numsBySubjs",
							props.subjId
						)}
					/>
					<EditIcon className="editIcon" />
				</td>
			)}
			{props.subjType === SubjTypes.ADDITIONAL_HALF_COMPULSORY && (
				<td>
					<table className="inner-table">
						<tbody>
							{props.subjects[SubjTypes.HALF_COMPULSORY - 1].map(
								subjId => (
									<tr key={subjId}>
										<td>{erovnulSubjectNames[subjId]}</td>
										<td>
											<input
												type={"number"}
												value={
													(props.numsBySubjCombs &&
														props.numsBySubjCombs[
															getSubjectKey([
																props.subjId,
																subjId,
															])
														]) ||
													""
												}
												onChange={props.onCharacteristicChange(
													"numsBySubjCombs",
													props.subjId,
													subjId
												)}
											/>
										</td>
										<td>
											<EditIcon
											// className="editIcon"
											/>
										</td>
									</tr>
								)
							)}
						</tbody>
					</table>
				</td>
			)}
			<td>
				<input
					type={"number"}
					value={
						(props.coefficients &&
							props.coefficients[props.subjId]) ||
						""
					}
					onChange={props.onCharacteristicChange(
						"coefficients",
						props.subjId
					)}
				/>
				<EditIcon className="editIcon" />
			</td>
			<td colSpan={props.subjType !== SubjTypes.COMPULSORY ? 1 : 2}>
				<input
					type={"number"}
					value={
						(props.minScoreLimits &&
							props.minScoreLimits[props.subjId]) ||
						""
					}
					onChange={props.onCharacteristicChange(
						"minScoreLimits",
						props.subjId
					)}
				/>
			</td>
			<td>
				<DeleteIcon
					className="delete-icon"
					onClick={() =>
						props.onSubjectDelete(props.subjId, props.subjType)
					}
				/>
			</td>
		</tr>
	);
};
export default ProgramEditTable;
