import * as React from "react";
import api from "@app/api";
import ImageCrop from "@app/components/Widgets/ImageCrop";
import UploadButton from "@app/components/Widgets/UploadButton";
import { connect } from "react-redux";
import { uniChangeSettings } from "@app/actions/unis";

interface IOwnProps {
	url: string;
	uni_id: number;
	width: number;
	height: number;
}

type IStateProps = null;

interface IDispatchProps {
	uniChangeSettings: typeof uniChangeSettings;
}

interface ICoverUploadState {
	pictureIsUploaded: boolean;
	file: null | File;
	uploading: boolean;
	lastSuccessPhoto?: string;
}

type IProps = IOwnProps & IDispatchProps;

class CoverUpload extends React.Component<IProps, ICoverUploadState> {
	state: ICoverUploadState = {
		pictureIsUploaded: false,
		file: null,
		uploading: false,
	};
	fileChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return;
		}
		const file = e.target.files[0];
		this.setState({
			pictureIsUploaded: !this.state.pictureIsUploaded,
			file,
		});
	};

	cancelImageUpload = () => {
		this.setState({
			pictureIsUploaded: false,
			file: null,
		});
	};

	uploadLogo = (img: Blob) => {
		const formData = new FormData();
		formData.append("pict", img);
		formData.append("uni_id", "" + this.props.uni_id);
		formData.append("picType", "cover");

		api.unis
			.uploadPhotos(formData as any)
			.then(data => {
				this.props.uniChangeSettings(this.props.uni_id, {
					coverUrl: data.fileName,
				});
				this.setState({
					uploading: false,
					pictureIsUploaded: false,
					file: null,
					lastSuccessPhoto: data.fileName,
				});
			})
			.catch(err => {
				this.setState({
					uploading: false,
				});
			});
	};
	render() {
		return (
			<div className="uniCoverUpload">
				{!this.state.pictureIsUploaded && (
					<React.Fragment>
						<div
							className="cover"
							style={{
								backgroundImage: `url(/photos/unicovers/${this
									.state.lastSuccessPhoto ||
									this.props.url})`,
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
							}}
						/>
						<UploadButton
							onChange={this.fileChangedHandler}
							multiple={false}
						/>
						{this.state.lastSuccessPhoto && (
							<div>ქავერი წარმატებით აიტვირთა</div>
						)}
					</React.Fragment>
				)}
				{!!this.state.pictureIsUploaded && !!this.state.file && (
					<React.Fragment>
						<ImageCrop
							border={0}
							borderRadius={0}
							width={this.props.width}
							height={this.props.height}
							image={this.state.file}
							onCancel={this.cancelImageUpload}
							onSubmit={this.uploadLogo}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(null, {
	uniChangeSettings,
})(CoverUpload);
