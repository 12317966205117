import api from "@app/api";
import { addLoader } from "@app/commonJavascript";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";

export const AdminLatorPage: React.FC<{}> = () => {
	const [subscriptionCount, setSubscriptionCount] = useState<number>(0);
	const [userCount, setUserCount] = useState<number>(0);
	useEffect(() => {
		const removeLoader = addLoader();
		api.users.userActions.getActionCount().then(data => {
			setSubscriptionCount(data.count);
			api.users.userActions.getLatorUsersCount().then(data => {
				setUserCount(data.count);
				removeLoader();
			});
		});
	});

	const onSubscribersDownload = () => {
		const removeLoader = addLoader();
		api.users.userActions
			.importUserSubscriptions()
			.then(data => {
				removeLoader();
				const now = new Date();
				let dateStr = now
					.toLocaleString()
					.replace(",", "")
					.replace(/\//g, "-")
					.replace(/:/g, "-");
				dateStr = dateStr.substr(0, dateStr.length - 3);
				fileDownload(data, `latori_subscriptions_${dateStr}.xlsx`);
			})
			.catch(e => {
				removeLoader();
				alert("დაფიქსირდა შეცდომა");
				console.error(e);
			});
	};

	const onLatoriUsersDownload = () => {
		const removeLoader = addLoader();
		api.users.userActions
			.importAllUsersLatorInfo()
			.then(data => {
				removeLoader();
				const now = new Date();
				let dateStr = now
					.toLocaleString()
					.replace(",", "")
					.replace(/\//g, "-")
					.replace(/:/g, "-");
				dateStr = dateStr.substr(0, dateStr.length - 3);
				fileDownload(data, `latori_users_${dateStr}.xlsx`);
			})
			.catch(e => {
				removeLoader();
				alert("დაფიქსირდა შეცდომა");
				console.error(e);
			});
	};

	return (
		<div>
			<div className="header">მურწყულატორი</div>
			<div>
				გამომწერთა რაოდენობა: <span>{subscriptionCount}</span>
			</div>
			<div>
				მომხმარებელთა რაოდენობა: <span>{userCount}</span>
			</div>
			<button
				className="adminPrimaryButton"
				style={{ height: "100%" }}
				onClick={onSubscribersDownload}
			>
				<span>გამომწერთა ჩამოტვირთვა</span>
				<DownloadIcon style={{ marginLeft: 5, fontSize: 35 }} />
			</button>
			<button
				className="adminPrimaryButton"
				style={{ height: "100%", marginLeft: 20 }}
				onClick={onLatoriUsersDownload}
			>
				<span>მომხმარებელთა ჩამოტვირთვა</span>
				<DownloadIcon style={{ marginLeft: 5, fontSize: 35 }} />
			</button>
		</div>
	);
};
