import * as React from "react";
import api from "@app/api";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Fab from "@material-ui/core/Fab";
import { History } from "history";
import { INews } from "@app/api/news/validators";
import { match } from "react-router";
import "./styles/adminNewsPage.min.css";

interface IProps {
	history: History;
	match: match<{ uni_id: string }>;
}

interface IState {
	news: INews[];
}

class AdminNewsPage extends React.Component<IProps, IState> {
	_isMounted: boolean;
	uni_id = +this.props.match.params.uni_id;

	state: IState = {
		news: [],
	};

	deleteSelectedNews = newsId => {
		if (!window.confirm("გსურთ სიახლის წაშლა?")) return;
		const { uni_id } = this.props.match.params;
		api.news
			.delete({ id: newsId, uni_id: +uni_id })
			.then(data => {
				console.log("Success: ", data.success);
				this.setState({
					news: this.state.news.filter(
						currentNews => currentNews.id !== newsId
					),
				});
			})
			.catch(err => {
				console.log(err);
			});
	};

	componentDidMount() {
		this._isMounted = true;
		api.unis.getNews({ uni_id: this.uni_id }).then(data => {
			this.setState({
				news: data,
			});
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const { params } = this.props.match;
		const { news } = this.state;

		return (
			<div className="main adminNewsPage">
				<div className="header">სიახლეები</div>
				<div className="adminPageNewsList">
					{news.map(currentNews => (
						<NewsPreviewComponent
							key={currentNews.id}
							uniId={currentNews.uni_id}
							newsId={currentNews.id}
							newsText={currentNews.text}
							deleteNews={() =>
								this.deleteSelectedNews(currentNews.id)
							}
							history={this.props.history}
						/>
					))}
				</div>
				<div className="addNewsButtonContainer">
					<button
						className="adminPrimaryButton add"
						onClick={() =>
							this.props.history.push(
								"/admin/uni/" + params.uni_id + "/news/add"
							)
						}
					>
						სიახლის დამატება
					</button>
				</div>
			</div>
		);
	}
}

const NewsPreviewComponent = props => (
	<React.Fragment>
		<div
			className="currentNews"
			dangerouslySetInnerHTML={{ __html: props.newsText }}
		/>
		<div className="newsActionsContainer">
			<Fab
				className="newsEditButton"
				onClick={() =>
					props.history.push(
						"/admin/uni/" +
							props.uniId +
							"/news/edit/" +
							props.newsId
					)
				}
			>
				<EditIcon />
			</Fab>
			<Fab className="newsDeleteButton" onClick={props.deleteNews}>
				<DeleteIcon />
			</Fab>
		</div>
	</React.Fragment>
);

export default AdminNewsPage;
