const bodyAttributes = {
	style: "background-color : #e5e5e5" as any,
};

const attrs = {
	title: "მურწყულატორი",
	bodyAttributes,
};

export default attrs;
