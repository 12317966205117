import * as React from "react";
import ClockHourArrow from "./styles/img/ClockArrows01";
import ClockMinuteArrow from "./styles/img/ClockArrows02";
import "./styles/clocks.min.css";

interface IProps {
	clockWidth: number;
	mainColor: string;
	onClockChange?: (unit: "hours" | "minutes", value: number) => void;
	hour?: number | null;
	minute?: number | null;
}

interface IState {
	hour: number | "";
	minute: number | "";
}

class ClockPicker extends React.Component<IProps, IState> {
	static defaultProps = {
		clockWidth: 275,
		mainColor: "#ff8d00",
	};

	state = {
		hour: this.props.hour ? this.props.hour : 0,
		minute: this.props.minute ? this.props.minute : 0,
	} as IState;

	hourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let hour: number | "" = parseInt(e.target.value.substr(0, 2), 10);
		if (Number.isNaN(hour) || hour > 24) hour = "";
		if (hour === 24) hour = 0;
		this.setState({ hour });
		if (typeof this.props.onClockChange !== "undefined")
			this.props.onClockChange("hours", +hour);
	};

	minuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let minute: number | "" = parseInt(e.target.value.substr(0, 2), 10);
		if (Number.isNaN(minute) || minute > 60) minute = "";
		if (minute === 60) minute = 0;
		this.setState({ minute });
		if (typeof this.props.onClockChange !== "undefined")
			this.props.onClockChange("minutes", +minute);
	};

	render() {
		return (
			<div
				className="mur-clockPicker"
				style={{ width: this.props.clockWidth + 50 }}
			>
				<div>
					<MyClock
						width={this.props.clockWidth}
						color={this.props.mainColor}
						hour={
							this.state.hour < 12
								? this.state.hour || 0
								: (this.state.hour || 0) - 12
						}
						minute={this.state.minute || 0}
					/>
				</div>
				<div>
					<ClockInput
						hour={this.state.hour}
						minute={this.state.minute}
						hourChange={this.hourChange}
						minuteChange={this.minuteChange}
					/>
				</div>
			</div>
		);
	}
}

interface IMyClockProps {
	width: number;
	hour: number;
	minute: number;
	color: string;
}

const MyClock: React.SFC<IMyClockProps> = props => {
	const minuteX = props.width / 2;
	const minuteY = props.width / 2;
	const arrowProportions = 0.105978;
	const bigStrokeWidth = props.width * 0.1;
	const minuteRadius = minuteX - bigStrokeWidth / 2;
	const progress = Math.PI * 2 * minuteRadius;
	const minuteOffset = progress * (1 - props.minute / 60);
	const hourStroke = bigStrokeWidth / 5;
	const minuteArrowHeight = 0.9 * (props.width / 2 - bigStrokeWidth);
	const minuteArrowWidth = minuteArrowHeight * arrowProportions;
	const hourArrowHeight = minuteArrowHeight;
	const hourArrowWidth = minuteArrowWidth;
	const textWidth = 20;
	const textHeight = 20;
	const hours = [12, 3, 6, 9];
	return (
		<div
			className="body"
			style={{ width: props.width, height: props.width }}
		>
			<svg width={props.width} height={props.width} className="clockSvg">
				<circle
					fill="none"
					cx={minuteX}
					cy={minuteY}
					r={minuteRadius}
					stroke="#2e2f35"
					strokeWidth={bigStrokeWidth}
				/>
				<circle
					className="minuteCircle"
					style={{ transition: "0.3s" }}
					fill="none"
					cx={minuteX}
					cy={minuteY}
					r={minuteRadius}
					stroke={props.color}
					strokeWidth={bigStrokeWidth}
					strokeDasharray={progress}
					strokeDashoffset={minuteOffset}
					strokeLinecap="round"
				/>
			</svg>
			<div
				className="rotatableBar"
				style={{
					transform: `translate(-50%) rotate(${(Math.PI *
						(props.hour + props.minute / 60)) /
						6}rad) `,
				}}
			>
				<div
					style={{
						height: props.width / 2,
						width: hourArrowWidth,
						paddingTop:
							props.width / 2 -
							(1 - arrowProportions / 2) * hourArrowHeight,
					}}
				>
					<ClockHourArrow />
				</div>
			</div>
			<div
				className="rotatableBar"
				style={{
					transform: `translate(-50%) rotate(${6 *
						props.minute}deg) `,
				}}
			>
				<div
					style={{
						height: props.width / 2,
						width: minuteArrowWidth,
						paddingTop:
							props.width / 2 -
							(1 - arrowProportions / 2) * minuteArrowHeight,
					}}
				>
					<ClockMinuteArrow />
				</div>
			</div>
			<div
				className="rotatableBar"
				style={{
					transform: `translate(-50%) rotate(${(Math.PI *
						(props.hour + props.minute / 60)) /
						6}rad) `,
				}}
			>
				<div
					className="hourIndicator"
					style={{
						border: `${hourStroke}px solid white`,
						width: bigStrokeWidth,
						height: bigStrokeWidth,
					}}
				/>
			</div>
			{hours.map(hour => {
				const deg = -((hour % 12) / 12) * 2 * Math.PI;
				const [hourTextX, hourTextY] = rotatePointAroundPoint(
					props.width / 2,
					-textHeight / 2,
					props.width / 2,
					props.width / 2,
					deg
				); //tslint:disable-line
				return (
					<div
						key={hour}
						className="hourValue"
						style={{
							lineHeight: textHeight + "px",
							width: textWidth,
							height: textHeight,
							top: hourTextY,
							left: hourTextX,
						}}
					>
						{hour}
					</div>
				);
			})}
		</div>
	);
};

const rotatePointAroundPoint = (
	x1: number,
	y1: number,
	Ox: number,
	Oy: number,
	theta: number
): [number, number] => {
	const x = x1 - Ox;
	const y = y1 - Oy;
	const newX = x * Math.cos(theta) + y * Math.sin(theta);
	const newY = -x * Math.sin(theta) + y * Math.cos(theta);
	return [newX + Ox, newY + Oy];
};

interface IClockInputProps {
	hour: number | "";
	minute: number | "";
	hourChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	minuteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ClockInput: React.SFC<IClockInputProps> = props => (
	<div className="inputs">
		<input
			className="hour no-arrows"
			type="number"
			min="0"
			max="23"
			value={props.hour}
			onChange={props.hourChange}
		/>
		<span className="colons"> : </span>
		<input
			className="minute no-arrows"
			type="number"
			min="0"
			max="59"
			value={props.minute}
			onChange={props.minuteChange}
		/>
	</div>
);

export default ClockPicker;
