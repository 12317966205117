import { USER_TYPES } from "@app/commonJavascript";
import Joi, { SchemaToType } from "@app/utils/joi";
import {
	IUserAdditionalInfo,
	RUserPermissionsSchema,
	UserAdditionalInfoSchema,
} from "../users/helper-schemas";

/////

export const APOSTLoginSchema = Joi.object().keys({
	mobile: Joi.number(),
	mail: Joi.string(),
	password: Joi.string().required(),
});

export type IAPOSTLogin = SchemaToType<typeof APOSTLoginSchema>;

export const RPOSTLoginSchema = Joi.object().keys({
	userData: Joi.object()
		.keys({
			id: Joi.number()
				.integer()
				.required(),
			murtskuId: Joi.number()
				.integer()
				.required()
				.allow(null),
			mobile: Joi.string()
				.allow(null)
				.required(),
			mail: Joi.string()
				.allow(null)
				.required(),
			username: Joi.string(),
			firstname: Joi.string().allow(null),
			lastname: Joi.string().allow(null),
			country: Joi.string().required(),
			permissions: RUserPermissionsSchema.allow(null).required(),
		})
		.required(),
	additionalInfo: UserAdditionalInfoSchema.required(),
	accessToken: Joi.string().required(),
	refreshToken: Joi.string().required(),
	chatToken: Joi.string(),
});
//export type IRPOSTLogin = SchemaToType<typeof RPOSTLoginSchema>;

export interface IRPOSTLogin {
	userData: {
		id: number;
		murtskuId: number | null;
		mobile: string | null;
		mail: string | null;
		username?: string;
		firstname?: string | null;
		lastname?: string | null;
		country: string;
		permissions: {
			user_id: number;
			canAddAdmins: boolean;
			uniPermissions: number;
			privilege: any;
		} | null;
	};
	accessToken: string;
	refreshToken: string;
	chatToken?: string;
	additionalInfo: IUserAdditionalInfo;
}

/////

export const APOSTRegisterSchema = Joi.object().keys({
	fullName: Joi.string().required(),
	mobile: Joi.string().required(),
	mail: Joi.string().required(),
	country: Joi.string().required(),
	password: Joi.string().required(),
	userType: Joi.string()
		.valid(...USER_TYPES)
		.optional(),
});

export type IAPOSTRegister = SchemaToType<typeof APOSTRegisterSchema>;

/////

export const APUTLoginByAccessTokenSchema = Joi.object().keys({
	murtskuUserId: Joi.number().required(),
	token: Joi.string().required(),
});
export type IAPUTLoginByAccessToken = SchemaToType<
	typeof APUTLoginByAccessTokenSchema
>;

export const RPUTLoginByAccessTokenSchema = RPOSTLoginSchema;
export type IRPUTLoginByAccessToken = IRPOSTLogin;

/////

export const APOSTLogoutSchema = Joi.object().keys({
	userId: Joi.number().required(),
	refreshToken: Joi.string().required(),
});

export type IAPOSTLogout = SchemaToType<typeof APOSTLogoutSchema>;
export type IRPOSTLogout = void;

/////

export const APUTAccessTokensSchema = Joi.object().keys({
	userId: Joi.number().required(),
	refreshToken: Joi.string().required(),
});
export type IAPUTAccessTokens = SchemaToType<typeof APUTAccessTokensSchema>;

export const RPUTAccessTokensSchema = RPOSTLoginSchema;
export type IRPUTAccessTokens = IRPOSTLogin;

// Murtsku login validators
export const APOSTMurtskuLoginSchema = Joi.object().keys({
	mail: Joi.string(),
	password: Joi.string(),
});
export type IAPOSTMurtskuLogin = SchemaToType<typeof APOSTMurtskuLoginSchema>;

export const RPOSTMurtskuLoginSchema = Joi.object()
	.keys({
		id: Joi.number().integer(),
		murtsku_id: Joi.number()
			.integer()
			.allow(null),
		username: Joi.string(),
		firstname: Joi.string().allow(null),
		lastname: Joi.string().allow(null),
		mail: Joi.string(),
		mob: Joi.string().allow(null),
		vld_mob: Joi.number().integer(),
		type: Joi.string().valid("DEVELOPER", "ADMIN", "UNIADMIN", "USER"),
		hashed_password: Joi.string(),
		hash_algo: Joi.string().allow(null),
		city: Joi.number()
			.integer()
			.allow(null),
		school: Joi.number()
			.integer()
			.allow(null),
		terms: Joi.number().integer(),
		created_at: Joi.date(),
		updated_at: Joi.date(),
	})
	.allow(null);
export type IRPOSTMurtskuLogin = SchemaToType<typeof RPOSTMurtskuLoginSchema>;

// Murtsku login access token validators
export const APUTMurtskuTokenSchema = Joi.object().keys({
	murtskuUserId: Joi.number().integer(),
	API_KEY: Joi.string(),
	firstname: Joi.string().allow(null),
	lastname: Joi.string().allow(null),
	mobile: Joi.string().allow(null),
	username: Joi.string(),
});
export type IAPUTMurtskuToken = SchemaToType<typeof APUTMurtskuTokenSchema>;

export const RPUTMurtskuTokenSchema = Joi.object().keys({
	token: Joi.string(),
	murtskuUserId: Joi.number().integer(),
});
export type IRPUTMurtskuToken = SchemaToType<typeof RPUTMurtskuTokenSchema>;

///

export const APOSTResetPasswordSchema = Joi.object().keys({
	mail: Joi.string().optional(),
	mobile: Joi.string().optional(),
});

export interface IAPOSTResetPassword {
	mail?: string;
	mobile?: string;
}

export const RPOSTResetPasswordSchema = Joi.object().keys({
	success: Joi.boolean().required(),
});

export interface IRPOSTResetPassword {
	success: boolean;
}
