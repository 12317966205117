import { Requests } from "..";
import {
	IAGETProgramSubjectScores,
	IRGETProgramSubjectScores,
	RGETProgramSubjectScoresSchema,
} from "../programs/validators";
import {
	IADELETEUserAdmissionProgram,
	IAPOSTUserAdmissionProgram,
	IAPOSTUserAdmissionPrograms,
} from "../users/programs/validators";
import {
	IADELETESubject,
	IAPOSTScore,
	IAPOSTSubject,
	IAPOSTSubjects,
	IAPUTSubjectFix,
	IAPUTSubjectScore,
	IRDELETESubject,
	IRPOSTScore,
	IRPOSTSubject,
	IRPOSTSubjects,
	IRPUTSubjectFix,
	IRPUTSubjectScore,
} from "../users/subjects/validators";
import {
	IAGETPlaceByTotalScore,
	IAPOSTFriendInvitation,
	IRGETLatoriAdmission,
	IRGETPlaceByTotalScore,
	RGETLatoriAdmissionSchema,
} from "../users/validators";

const lator = {
	addSubject: (data: IAPOSTSubject): Promise<IRPOSTSubject> =>
		Requests.send("POST", "/api/users/subjects/:subject_id", data),
	addSubjects: (data: IAPOSTSubjects): Promise<IRPOSTSubjects> =>
		Requests.send("POST", "/api/users/subjects", data),
	deleteSubject: (data: IADELETESubject): Promise<IRDELETESubject> =>
		Requests.send("DELETE", "/api/users/subjects/:subject_id", data),
	postCalculations: (data: IAPOSTScore): Promise<IRPOSTScore> =>
		Requests.send("POST", "/api/users/subjects/scores", data),
	subjectFixation: (data: IAPUTSubjectFix): Promise<IRPUTSubjectFix> =>
		Requests.send("PUT", "/api/users/subjects/:subject_id/fix", data),
	changeSubjectScore: (data: IAPUTSubjectScore): Promise<IRPUTSubjectScore> =>
		Requests.send("PUT", "/api/users/subjects/:subject_id/scores", data),
	addProgram: (data: IAPOSTUserAdmissionProgram): Promise<void> =>
		Requests.send(
			"POST",
			"/api/users/admission-programs/:program_id",
			data,
			null
		),
	addPrograms: (data: IAPOSTUserAdmissionPrograms): Promise<void> =>
		Requests.send("POST", "/api/users/admission-programs", data, null),
	deleteProgram: (data: IADELETEUserAdmissionProgram): Promise<void> =>
		Requests.send(
			"DELETE",
			"/api/users/admission-programs/:program_id",
			data,
			null
		),
	getUserData: (): Promise<IRGETLatoriAdmission> =>
		Requests.send("GET", "/api/users/admissions", {}, null, {
			responseSchema: RGETLatoriAdmissionSchema,
		}),
	getScoresByProgramAndSubjectId: (
		data: IAGETProgramSubjectScores
	): Promise<IRGETProgramSubjectScores> =>
		Requests.send(
			"GET",
			"/api/programs/:program_id/scores-by-elective-subjects",
			data,
			null,
			{
				requestSchema: RGETProgramSubjectScoresSchema,
			}
		),
	inviteFriend: (data: IAPOSTFriendInvitation): Promise<void> =>
		Requests.send("POST", "/api/users/invitations/latori", data, null),
	getPlaceByTotalScore: (
		data: IAGETPlaceByTotalScore
	): Promise<IRGETPlaceByTotalScore> =>
		Requests.send("GET", "/api/users/admissions/places", data, null),
};
export default lator;
