import * as React from "react";
import CalendarOfEvents from "../Widgets/CalendarOfEvents";
import EventPopup from "../Universities/event-popup";
import memoizeOne from "memoize-one";
import MultipleEventsPopup from "./multiple-events-popup";
import { ICalendarProps } from "../Widgets/CalendarCommonParts";
import { IEvent } from "@app/api/events/helpher-schemas";
import { IRootState } from "@app/reducers/root";
import { IRUniversity } from "@app/api/unis/helper-schemas";

interface IEventDates {
	[key: string]: string;
}

interface IProps extends Partial<ICalendarProps> {
	events: IEvent[];
	user_reserved_events: IRootState["temp"]["user_reserved_events"];
	unis?: IRUniversity[];
	onEventButtonClick: (
		id: number,
		is_reserved: boolean,
		label: string
	) => void;
	redirect: (address: string) => void;
}

interface IState {
	showEventsPopup: boolean;
	chosenEventDate: Date | null;
	showAllUpcomingEvents: boolean;
}

class CalendarOfEventsContainer extends React.Component<IProps, IState> {
	state: IState = {
		showEventsPopup: false,
		chosenEventDate: null,
		showAllUpcomingEvents: false,
	};

	getUpcomingEvents = memoizeOne((events: IEvent[]) => {
		const today = new Date();
		return events
			.filter(e => e.start_time >= today)
			.sort(
				(e1, e2) => e2.created_at.getTime() - e1.created_at.getTime()
			);
	});

	onEventDayClick = (event_date: Date) => {
		this.setState({
			showEventsPopup: true,
			chosenEventDate: event_date,
		});
	};

	onEventPopupClose = () =>
		this.setState({ showEventsPopup: false, showAllUpcomingEvents: false });

	datify = (date: Date) =>
		date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

	onShowUpcomingEvents = () => {
		this.setState({
			showAllUpcomingEvents: true,
		});
	};

	render() {
		const { user_reserved_events } = this.props;

		if (!user_reserved_events) return null;

		const upcomingEvents = this.getUpcomingEvents(this.props.events);

		const eventDates: IEventDates = {};

		this.props.events.forEach(event => {
			const reservationIndicatorClass = user_reserved_events.some(
				id => id === event.id
			)
				? "reserved "
				: "";
			const dateIndex = this.datify(new Date(event.start_time));
			if (eventDates[dateIndex] === undefined) {
				eventDates[dateIndex] =
					" eventDay " + reservationIndicatorClass;
			} else {
				eventDates[dateIndex] += reservationIndicatorClass;
			}
		});

		const currentDay = this.datify(new Date());
		eventDates[currentDay] =
			(eventDates[currentDay] || "") + " currentDate";

		const chosenEvent = this.state.chosenEventDate
			? this.props.events.filter(
					event =>
						this.datify(new Date(event.start_time)) ===
						this.datify(this.state.chosenEventDate!)
			  )
			: null;

		return (
			<React.Fragment>
				{this.state.showEventsPopup &&
					chosenEvent &&
					chosenEvent.length > 0 &&
					(chosenEvent.length === 1 ? (
						<EventPopup
							event={chosenEvent[0]}
							is_reserved={
								!!this.props.user_reserved_events &&
								this.props.user_reserved_events.some(
									id => id === chosenEvent[0].id
								)
							}
							onClose={this.onEventPopupClose}
							onReserve={this.props.onEventButtonClick}
						/>
					) : (
						<MultipleEventsPopup
							events={chosenEvent}
							onClose={this.onEventPopupClose}
							user_reserved_events={
								this.props.user_reserved_events
							}
							unis={this.props.unis}
							onEventButtonClick={this.props.onEventButtonClick}
							redirect={this.props.redirect}
						/>
					))}
				<CalendarOfEvents
					{...this.props}
					onEventDayClick={this.onEventDayClick}
					eventDates={eventDates}
					onSeeMore={this.onShowUpcomingEvents}
				/>
				{this.state.showAllUpcomingEvents && (
					<MultipleEventsPopup
						events={upcomingEvents}
						onClose={this.onEventPopupClose}
						user_reserved_events={this.props.user_reserved_events}
						unis={this.props.unis}
						onEventButtonClick={this.props.onEventButtonClick}
						redirect={this.props.redirect}
						title="მომავალი ღონისძიებები"
					/>
				)}
			</React.Fragment>
		);
	}
}

export default CalendarOfEventsContainer;
