import {
	IADELETEDelete,
	IAGETExportSheet,
	IAGETGetOne,
	IAPOSTNewsImages,
	IAPOSTReserve,
	IAPUTSave,
	IRDELETEDelete,
	IRGETAll,
	IRGETGetOne,
	IRPOSTNewsImages,
	IRPOSTReserve,
	IRPUTSave,
	RGETAllSchema,
	RGETGetOneSchema,
	RPOSTNewsImagesSchema,
	RPUTSaveSchema,
} from "./validators";
import { Requests } from "@app/api";

const apiPath = "/api/events";
const events = {
	getById: (data: IAGETGetOne): Promise<IRGETGetOne> =>
		Requests.send("GET", apiPath + "/:id", data, null, {
			responseSchema: RGETGetOneSchema,
		}),
	delete: (data: IADELETEDelete): Promise<IRDELETEDelete> =>
		Requests.send("DELETE", apiPath + "/:id", data, null),
	save: (data: IAPUTSave): Promise<IRPUTSave> =>
		Requests.send("PUT", apiPath + "/", data, null, {
			responseSchema: RPUTSaveSchema,
		}),
	reserve: (data: IAPOSTReserve): Promise<IRPOSTReserve> =>
		Requests.send("POST", apiPath + "/:id", data, null),
	getAll: (): Promise<IRGETAll> =>
		Requests.send("GET", apiPath + "/", {}, null, {
			responseSchema: RGETAllSchema,
		}),
	uploadPhotos: (data: IAPOSTNewsImages): Promise<IRPOSTNewsImages> =>
		Requests.send("POST", apiPath + "/photos", data, null, {
			responseSchema: RPOSTNewsImagesSchema,
		}),
	getExcelFile: (data: IAGETExportSheet): Promise<any> =>
		Requests.send("GET", apiPath + "/:id/sheets", data, {
			responseType: "blob",
		}),
};
export default events;
