import { IProgramContent, IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity, IUniContent } from "@app/api/unis/helper-schemas";
import { IProgramsDeailedSearch, IUnisDeailedSearch } from "../../MainPage";

function detailedSearchUnis(
	unis: IRUniversity[],
	searchSettings: IUnisDeailedSearch
): IRUniversity[] {
	return unis.filter(uni => {
		if (
			searchSettings.sector !== -1 &&
			uni.sector !== searchSettings.sector
		) {
			return false;
		}
		if (
			searchSettings.city !== -1 &&
			(!uni.cities || uni.cities.indexOf(searchSettings.city) === -1)
		) {
			return false;
		}
		if (
			searchSettings.field !== -1 &&
			(!uni.fields || uni.fields!.indexOf(searchSettings.field) === -1)
		) {
			return false;
		}
		return true;
	});
}
export { detailedSearchUnis };
export { detailedSearchPrograms };
export { sortUnisByCoeff };
export { sortProgramsByCoeff };
export { sortContentByPosition };
export { sortUniContentByPosition };

// tslint:disable-next-line
function detailedSearchPrograms(
	programs: IRProgram[],
	searchSettings: IProgramsDeailedSearch
): IRProgram[] {
	return programs.filter(program => {
		if (
			searchSettings.sector !== -1 &&
			program.sector !== searchSettings.sector
		) {
			return false;
		}
		if (
			searchSettings.city !== -1 &&
			program.city !== searchSettings.city
		) {
			return false;
		}
		if (
			searchSettings.field !== -1 &&
			(!program.fields ||
				program.fields.indexOf(searchSettings.field) === -1)
		) {
			return false;
		}

		// const validSubject: boolean[] = [];
		if (searchSettings.subjects.length > 0) {
			let found = false;
			for (const subjId of searchSettings.subjects) {
				for (const subjGroup of program.subjects) {
					if (subjGroup.indexOf(subjId) >= 0) {
						found = true;
						break;
					}
				}
			}
			if (!found) return false;
		}

		if (searchSettings.filterByScore) {
			/*
			const scores = searchSettings.subjScores; // scores chosen by the user
			const percentagesBySubject = program.minScoreLimits || [];
			const subjects = erovnulSubjects;

			let additionalPassed = false;
			// tslint:disable-next-line
			for (const subjectId in scores) {
				// iterate over subjects the scores of which are provided
				if (
					typeof scores[subjectId] === "undefined" ||
					scores[subjectId] === null
				)
					continue;
				const subject = subjects.find(s => s.id === Number(subjectId));
				if (typeof subject === "undefined") {
					throw new Error("subject not found");
				}

				const { minScore } = subject;
				const { maxScore } = subject;
				if (
					typeof minScore !== "undefined" &&
					typeof maxScore !== "undefined"
				) {
					const scoreNeeded = !percentagesBySubject![subjectId]
						? 0
						: (maxScore * percentagesBySubject![subjectId]) / 100 +
						  1;
					const scoreChosen = scores[subjectId];

					if (subject.isCompulsory) {
						if (
							!(
								scoreChosen >= minScore &&
								scoreChosen >= scoreNeeded
							)
						) {
							return false;
						}
					} else if (!additionalPassed) {
						const scoresForScale: number[] = [];
						const subjectsForScale: number[] = [];
						// tslint:disable-next-line
						for (const eachSubjectId in scores) {
							if (scores.hasOwnProperty(eachSubjectId)) {
								if (!scores[eachSubjectId]) continue;
								const subj = subjects.find(
									s => s.id === +eachSubjectId
								);
								if (typeof subj === "undefined")
									throw new Error(
										`subject of id: ${eachSubjectId} not found`
									);
								if (
									eachSubjectId === subjectId ||
									subj.isCompulsory
								) {
									scoresForScale.push(scores[eachSubjectId]);
									subjectsForScale.push(+eachSubjectId);
								}
							}
						}
						const scaledScore = scoresToScaledScore(
							scoresForScale,
							subjectsForScale,
							program.coefficients
						);
						if (
							validSubject[subjectId] &&
							(!program.minScores ||
								(scaledScore > program.minScores[subjectId] &&
									scoreChosen >= minScore &&
									scoreChosen >= scoreNeeded))
						) {
							additionalPassed = true;
						}
					}
				}
			}
			if (!additionalPassed) {
				return false;
			}
			*/
		}

		const minPrice = searchSettings.prices[0];
		const maxPrice = searchSettings.prices[1];
		if (program.price < minPrice || program.price > maxPrice) {
			return false;
		}

		return true;
	});
}

function sortUnisByCoeff(unis: IRUniversity[]): IRUniversity[] {
	return [...unis].sort(
		(a, b) => b.pagePositionCoefficient - a.pagePositionCoefficient
	);
}

function sortProgramsByCoeff(programs: IRProgram[]): IRProgram[] {
	return [...programs].sort((pr1, pr2) => {
		if (pr1.uniMainCoefficient !== pr2.uniMainCoefficient) {
			return pr2.uniMainCoefficient - pr1.uniMainCoefficient;
		}
		return (
			pr2.intraUniPositionCoefficient - pr1.intraUniPositionCoefficient
		);
	});
}

function sortContentByPosition(contents: IProgramContent[]): IProgramContent[] {
	return [...contents].sort(
		(cont1, cont2) => cont1.position - cont2.position
	);
}

function sortUniContentByPosition(contents: IUniContent[]): IUniContent[] {
	return [...contents].sort(
		(cont1, cont2) => cont1.position - cont2.position
	);
}
