import chatrooms, { IStateChatRooms } from "./reducers/chatrooms";
import { combineReducers } from "redux";
import { IChatroomActions } from "./actions/chatrooms";

const rootReducer = combineReducers({ chatrooms });

export interface IChatRootState {
	chatrooms: IStateChatRooms;
}

export type IChatRootActions = IChatroomActions;

export type ToDispatchType<T extends {}> = {
	[key in keyof T]: T[key] extends (
		...args: infer Args
	) => (...args: any[]) => infer R
		? (...args: Args) => R
		: T[key];
};

export default rootReducer;
