import * as React from "react";
import api from "@app/api";
import ContentLoader from "react-content-loader";
import FancyInput from "../../Widgets/FancyInput";
import FancyMultipleSelect from "../../Widgets/FancyMultipleSelect";
import FancySelect from "../../Widgets/FancySelect";
import ProgramEditTable, { SubjTypes } from "../ProgramEditTable";
import ProgramThumbNail from "./ProgramThumbNail";
import SnackNotification from "../../Widgets/SnackNotification";
import Switch from "../../Widgets/MainSwitch";
import {
	addLoader,
	cities,
	erovnulSubjects,
	fields,
	foreignLanguages,
	getSubjectKey,
	mandatoryTours,
} from "../../../commonJavascript";
import { canEditProgramSettings } from "@app/permissions";
import { connect } from "react-redux";
import {
	getProgramsEssentialInfo,
	IGetProgramsEssentialInfo,
	programChangeSettings,
} from "@app/actions/programs";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { match } from "react-router";
import { UniAliasEdit } from "../AdminUniEditPage";
import "../styles/programEditPage.min.css";
import { getLocale } from "@app/hooks/intl";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string; program_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	programChangeSettings: typeof programChangeSettings;
	getProgramsEssentialInfo: IGetProgramsEssentialInfo;
}

interface IState {
	program?: IRProgram;
	errorDialogOpen: boolean;
	errorMessage: string;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

class AdminProgramEditPage extends React.Component<IProps, IState> {
	static loaders() {
		return (
			<div style={{ textAlign: "center" }}>
				{Array(8)
					.fill(0)
					.map((e, index) => (
						<div className="fancyInputAndTitle" key={index}>
							<ContentLoader
								width={400}
								height={115}
								primaryColor="#fff"
								primaryOpacity={0.7}
								secondaryColor="#87878e"
								secondaryOpacity={1}
							>
								<rect
									y="16"
									x="25"
									width="270"
									height="23"
									rx="4"
									ry="4"
								/>
								<rect
									y="55"
									width="400"
									height="60"
									rx="27.5"
									ry="27.5"
								/>
							</ContentLoader>
						</div>
					))}
			</div>
		);
	}
	_isMounted: boolean;
	programChangedInfo: Partial<IRProgram> = {};

	program_id: number | null = +this.props.match.params.program_id;
	uni_id: number = +this.props.match.params.uni_id;

	state = {
		errorDialogOpen: false,
		errorMessage: "",
	} as IState;

	constructor(props: IProps) {
		super(props);
		const { uni_id, program_id } = props.match.params;
		if (!uni_id) {
			this.props.history.replace("/admin/uni/" + uni_id + "/programs");
			return;
		}
		if (
			program_id &&
			!canEditProgramSettings(
				+program_id,
				+uni_id,
				props.userData.permissions
			)
		) {
			this.props.history.replace("/admin/uni/" + uni_id + "/programs");
			return;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		if (this.program_id) {
			api.programs
				.getById({
					id: this.program_id,
					getContents: false,
				})
				.then(data => {
					this.setState({
						program: data as IRProgram,
					});
				});
		} else {
			let minCoefficient = Infinity;
			if (this.props.programs && this.props.programs.length) {
				this.props.programs.forEach(el => {
					if (el.uni_id !== this.uni_id) return;
					if (typeof el.intraUniPositionCoefficient !== "number")
						return;
					if (el.intraUniPositionCoefficient < minCoefficient)
						minCoefficient = el.intraUniPositionCoefficient;
				});
			}
			if (minCoefficient === Infinity) minCoefficient = 512;
			let uniMainCoefficient = -Infinity;
			if (this.props.programs) {
				for (let i = 0; i < this.props.programs.length; i++) {
					if (this.props.programs[i]!.uni_id === this.uni_id) {
						uniMainCoefficient = this.props.programs[i]
							.uniMainCoefficient;
						break;
					}
				}
			}
			if (uniMainCoefficient === -Infinity) uniMainCoefficient = 0;
			const programData: IRProgram = {
				id: 0,
				uni_id: this.uni_id,
				code: "",
				name: "",
				urlName: "",
				officialName: "",
				alias: null,
				accreditation: false,
				financed: false,
				fullyFinancedPlaces: 0,
				price: 0,
				studyDuration: 4,
				foreignLangs: null,
				languageOfInstruction: null,
				subjects: [[], [], []],
				totalPlaces: 0,
				numsBySubjs: null,
				numsBySubjCombs: null,
				coefficients: null,
				minScoreLimits: Array(erovnulSubjects.length).fill(0),
				minScores: null,
				medianScores: null,
				city: 0,
				sector: 0,
				fields: null,
				photoUrl: "",
				yearOfUpdatedInfo: new Date().getFullYear(),
				premium: 0,
				pagePositionCoefficient: 0,
				intraUniPositionCoefficient: minCoefficient / 2,
				uniMainCoefficient,
				locale: getLocale() as "ka" | "en",
			};
			this.setState({
				program: programData,
			});
			this.programChangedInfo = { ...programData };
			delete this.programChangedInfo.id;
			delete this.programChangedInfo.uni_id;
			// delete this.programChangedInfo.minScoreLimits;
			delete this.programChangedInfo.minScores;
			delete this.programChangedInfo.medianScores;
			delete this.programChangedInfo.photoUrl;
			delete this.programChangedInfo.premium;
			delete this.programChangedInfo.pagePositionCoefficient;
			delete this.programChangedInfo.subjects;
			delete this.programChangedInfo.yearOfUpdatedInfo;
		}
		this.props.getProgramsEssentialInfo().then();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	saveProgram = () => {
		if (Object.keys(this.programChangedInfo).length === 0) {
			if (this.state.program!.urlName)
				this.props.history.push(
					"/admin/uni/" + this.uni_id + "/programs"
				);
			return;
		}
		const removeLoader = addLoader();
		api.programs
			.save({
				id: this.program_id || undefined,
				uni_id: this.uni_id,
				programSettings: this.programChangedInfo,
			})
			.then(() => {
				removeLoader();
				if (this.program_id)
					this.props.programChangeSettings(
						this.program_id,
						this.programChangedInfo
					);
				if (!this._isMounted) return;
				this.props.history.push(
					"/admin/uni/" + this.uni_id + "/programs"
				);
			})
			.catch(err => {
				console.log(err);
				removeLoader();
				if (!this._isMounted) return;
				if (err.response && Array.isArray(err.response.data)) {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "" + err.response.data[0].errorText, // TODO: translate in Georgian
					});
				} else {
					this.setState({
						errorDialogOpen: true,
						errorMessage: "დაფიქსირდა შეცდომა",
					});
				}
			});
	};

	onSubjectAdd = (subjId: number, oldSubjId: number, type: SubjTypes) => {
		if (!this.state.program) return;
		this.addSubject(subjId, oldSubjId, type);
		this.setState({
			program: {
				...this.state.program,
				subjects: this.programChangedInfo.subjects!,
			},
		});
	};

	addSubject = (subjId: number, type: SubjTypes, index?: number) => {
		if (subjId < 1 || subjId > erovnulSubjects.length)
			throw new Error("Incorrect subject id");
		let newSubjects;
		if (this.programChangedInfo.subjects)
			newSubjects = [...this.programChangedInfo.subjects];
		else newSubjects = [...this.state.program!.subjects];
		if (typeof index === "number") newSubjects[type - 1][index] = subjId;
		else newSubjects[type - 1].push(subjId);
		this.programChangedInfo.subjects = newSubjects;
	};

	onSubjectDelete = (subjId: number, type: SubjTypes) => {
		if (!this.state.program) return;
		this.deleteSubject(subjId, type);
		this.setState({
			program: {
				...this.state.program!,
				...this.programChangedInfo,
			},
		});
	};

	deleteSubject = (subjId: number, type: SubjTypes) => {
		if (subjId < 1 || subjId > erovnulSubjects.length)
			throw new Error("Incorrect subject id");
		const newSubjects: any[] = [];
		let newNumsBySubjs: IRProgram["numsBySubjs"] = this.state.program!
			.numsBySubjs && [...this.state.program!.numsBySubjs];
		let newNumsBySubjsCombs: IRProgram["numsBySubjCombs"] = this.state
			.program!.numsBySubjCombs && {
			...this.state.program!.numsBySubjCombs,
		};
		for (let i = 0; i < this.state.program!.subjects.length; i++) {
			const subjGroup = this.state.program!.subjects[i];
			if (i !== type - 1) newSubjects[i] = [...subjGroup];
			else {
				newSubjects[i] = [];
				for (let j = 0; j < subjGroup.length; j++) {
					const id = subjGroup[j];
					if (subjId !== id) {
						newSubjects[i].push(id);
					}
				}
			}
		}
		if (newNumsBySubjs) newNumsBySubjs[subjId] = 0;
		if (newNumsBySubjsCombs && this.state.program) {
			for (const subj2Id of this.state.program.subjects[
				SubjTypes.HALF_COMPULSORY - 1
			])
				delete newNumsBySubjsCombs[getSubjectKey([subjId, subj2Id])];
		}
		if (newNumsBySubjs && newNumsBySubjs.findIndex(el => el !== 0) < 0) {
			newNumsBySubjs = null;
		}
		if (
			newNumsBySubjsCombs &&
			Object.keys(newNumsBySubjsCombs).length < 1
		) {
			newNumsBySubjsCombs = null;
		}
		const newMinScoreLimits = this.state.program!.minScoreLimits
			? [...this.state.program!.minScoreLimits]
			: null;
		const newCoefficients = this.state.program!.coefficients
			? [...this.state.program!.coefficients]
			: null;
		if (newMinScoreLimits) {
			newMinScoreLimits[subjId] = 0;
			this.programChangedInfo.minScoreLimits = newMinScoreLimits;
		}
		if (newCoefficients) {
			newCoefficients[subjId] = 0;
			this.programChangedInfo.coefficients = newCoefficients;
		}

		this.programChangedInfo.subjects = newSubjects;
		this.programChangedInfo.numsBySubjs = newNumsBySubjs;
		this.programChangedInfo.numsBySubjCombs = newNumsBySubjsCombs;
	};

	onSubjectSwitch = (
		oldSubjId: number,
		newSubjId: number,
		type: SubjTypes
	) => {
		if (!this.state.program) return;
		let index;
		if (oldSubjId) {
			const subjGroup = this.state.program.subjects[type - 1];
			for (let i = 0; i < subjGroup.length; i++) {
				if (oldSubjId === subjGroup[i]) {
					index = i;
					break;
				}
			}
			const newMinScoreLimits = [...this.state.program!.minScoreLimits];
			const newCoefficients = [...this.state.program!.coefficients];
			newMinScoreLimits[oldSubjId] = 0;
			newCoefficients[oldSubjId] = 0;
			this.programChangedInfo.coefficients = newCoefficients;
			this.programChangedInfo.minScoreLimits = newMinScoreLimits;
		}
		this.addSubject(newSubjId, type, index);
		this.setState({
			program: {
				...this.state.program!,
				...this.programChangedInfo,
			},
		});
	};

	onProgramChange = (name: keyof IRProgram) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		this.programChangedInfo[name] = e.target.value.trim() as any;
	};

	onProgramCharacteristicChange = (
		name:
			| "numsBySubjs"
			| "coefficients"
			| "minScoreLimits"
			| "numsBySubjCombs",
		value: number,
		subjId: number,
		subj2Id?: number
	) => {
		if (!this.state.program) return;
		if (
			typeof this.programChangedInfo[name] === "undefined" ||
			this.programChangedInfo[name] === null
		) {
			if (this.state.program && this.state.program[name]) {
				this.programChangedInfo[name] = this.state.program[
					name
				]! as any;
			} else {
				if (subj2Id) this.programChangedInfo[name] = {} as any;
				else
					this.programChangedInfo[name] = Array(
						erovnulSubjects.length + 1
					).fill(0) as any;
			}
		}
		if (subj2Id)
			this.programChangedInfo[name]![
				getSubjectKey([subjId, subj2Id])
			] = value;
		else this.programChangedInfo[name]![subjId] = value;
		this.setState({
			program: {
				...this.state.program,
				...this.programChangedInfo,
			},
		});
	};

	switchAccreditation = () => {
		if (this.programChangedInfo.accreditation === undefined) {
			this.programChangedInfo.accreditation = this.state.program!.accreditation;
		}
		this.programChangedInfo.accreditation = !this.programChangedInfo
			.accreditation;
		this.setState({
			program: {
				...this.state.program!,
				accreditation: !this.state.program!.accreditation,
			},
		});
	};

	switchFinantialStatus = () => {
		if (this.programChangedInfo.financed === undefined) {
			this.programChangedInfo.financed = this.state.program!.financed;
		}
		this.programChangedInfo.financed = !this.programChangedInfo.financed;
		this.setState({
			program: {
				...this.state.program!,
				financed: !this.state.program!.financed,
			},
		});
	};

	onAliasChange = (newAlias: string[]) => {
		this.programChangedInfo.alias = newAlias.filter(
			name => name.length > 0
		);
	};

	onCityChange = (newCity: number) => {
		this.programChangedInfo.city = newCity;
	};

	onSectorChange = (newSector: "private" | "public") => {
		this.programChangedInfo.sector = newSector === "public" ? 0 : 1;
	};

	onFieldsChange = (newFields: number[]) => {
		this.programChangedInfo.fields = newFields;
	};

	onLanguagesChange = (
		newLanguages: NonNullable<IRProgram["foreignLangs"]>
	) => {
		this.programChangedInfo.foreignLangs = newLanguages;
	};

	onLanguageChange = (
		newLanguage: NonNullable<IRProgram["languageOfInstruction"]>
	) => {
		this.programChangedInfo.languageOfInstruction = newLanguage;
	};

	onGrantSubjectChange = (newGrantSubjects: number[]) => {
		this.programChangedInfo.grant_subjects =
			newGrantSubjects.length > 0 ? newGrantSubjects : null;
	};

	onMandatoryTourChange = (tours: number[]) => {
		this.programChangedInfo.mandatory_tours =
			tours.length > 0 ? tours : null;
	};

	onMakeTotalPlacesUnlimitedToggle = () => {
		if (this.programChangedInfo.totalPlaces === -1)
			this.programChangedInfo.totalPlaces = 0;
		else this.programChangedInfo.totalPlaces = -1;
		this.setState({
			program: {
				...this.state.program!,
				totalPlaces: this.programChangedInfo.totalPlaces,
			},
		});
	};

	handleErrorDialogClose = () => {
		this.setState({
			errorDialogOpen: false,
		});
	};

	render() {
		if (typeof this.state.program === "undefined")
			return AdminProgramEditPage.loaders();
		if (!this.state.program) return null;
		const { program } = this.state;
		const currency = program.locale === "ka" ? "₾" : "$";
		const cityOptions = cities.map(city => ({
			id: city.id,
			name: program.locale === "ka" ? city.name : city.engName,
		}));
		return (
			<div className="programEditPage">
				<FancyInput
					defaultValue={program.name || ""}
					title="ფაკულტეტის სახელი"
					onChange={this.onProgramChange("name")}
				/>
				<FancyInput
					defaultValue={"" + program.officialName || ""}
					title="ოფიციალური სახელი"
					onChange={this.onProgramChange("officialName")}
				/>
				<FancyInput
					defaultValue={"" + program.urlName || ""}
					title="URL სახელი(უნდა იწყებოდეს 7-ნიშნა კოდითა და ქვედა ტირეთი)"
					onChange={this.onProgramChange("urlName")}
				/>
				<FancyInput
					defaultValue={program.price ? "" + program.price : ""}
					title={`ფასი (${currency})`}
					onChange={this.onProgramChange("price")}
				/>
				<FancyInput
					defaultValue={program.studyDuration + ""}
					title={"სწავლის ხანგრძლივობა (წლებში)"}
					onChange={this.onProgramChange("studyDuration")}
				/>
				<FancyInput
					defaultValue={program.code || ""}
					title="კოდი (7-ნიშნა)"
					onChange={this.onProgramChange("code")}
				/>
				<div className="fancyInputAndTitle">
					<h2>აკრედიტაცია</h2>
					<Switch
						size="big"
						checked={!!program.accreditation}
						onClick={this.switchAccreditation}
					/>
				</div>
				<FancyInput
					defaultValue={
						program.fullyFinancedPlaces
							? "" + program.fullyFinancedPlaces
							: ""
					}
					title="რამდენი ადგილი ფინანსდება (უფასო ფაკულტეტის შემთხვევაში)"
					onChange={this.onProgramChange("fullyFinancedPlaces")}
				/>
				<FancyInput
					defaultValue={
						program.totalPlaces
							? "" +
							  (program.totalPlaces === -1
									? "Unlimited"
									: program.totalPlaces)
							: ""
					}
					title="ადგილების რაოდენობა"
					onChange={this.onProgramChange("totalPlaces")}
				/>
				<div className="fancyInputAndTitle">
					<h2>Unlimited</h2>
					<Switch
						size="small"
						checked={program.totalPlaces === -1}
						onClick={this.onMakeTotalPlacesUnlimitedToggle}
					/>
				</div>
				<div className="fancyInputAndTitle">
					<h2>ფინანსდება</h2>
					<Switch
						size="big"
						checked={!!program.financed}
						onClick={this.switchFinantialStatus}
					/>
				</div>
				<FancySelect
					title={"ქალაქი"}
					defaultValue={program.city}
					items={cityOptions}
					onChange={this.onCityChange}
				/>
				<FancySelect
					title={"სექტორი"}
					defaultValue={program.sector ? "private" : "public"}
					items={[
						{ id: "public", name: "საჯარო" },
						{ id: "private", name: "კერძო" },
					]}
					onChange={this.onSectorChange}
				/>
				<FancyMultipleSelect
					title={"სფეროები"}
					defaultValue={program.fields || []}
					items={fields}
					onChange={this.onFieldsChange}
				/>
				<FancyMultipleSelect
					title={"უცხო ენები"}
					defaultValue={program.foreignLangs || []}
					items={foreignLanguages}
					onChange={this.onLanguagesChange}
				/>
				<FancySelect
					title={"სწავლების ენა"}
					defaultValue={program.languageOfInstruction}
					items={foreignLanguages}
					onChange={this.onLanguageChange}
				/>
				<FancyMultipleSelect
					title={"საგრანტო საგნები"}
					defaultValue={program.grant_subjects || null}
					items={erovnulSubjects}
					onChange={this.onGrantSubjectChange}
				/>
				<FancyMultipleSelect
					title={"სავალდებულო ტურები"}
					defaultValue={program.mandatory_tours || null}
					items={mandatoryTours}
					onChange={this.onMandatoryTourChange}
				/>
				<UniAliasEdit
					defaultValue={program.alias || []}
					onChange={this.onAliasChange}
				/>
				{!!this.program_id && (
					<ProgramThumbNail
						url={program.photoUrl || ""}
						program_id={this.program_id}
						uni_id={this.uni_id}
						width={250}
						height={250}
					/>
				)}
				<ProgramEditTable
					program={program || {}}
					onChange={this.onProgramCharacteristicChange}
					onSubjectDelete={this.onSubjectDelete}
					onSubjectSwitch={this.onSubjectSwitch}
				/>
				<button
					className="adminPrimaryButton"
					onClick={this.saveProgram}
				>
					დადასტურება
				</button>
				<SnackNotification
					autoHideDuration={6000}
					onClose={this.handleErrorDialogClose}
					message={this.state.errorMessage}
					variant="error"
					open={this.state.errorDialogOpen}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	programs: state.programs.info,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{
		programChangeSettings,
		getProgramsEssentialInfo,
	} as any
)(AdminProgramEditPage);
