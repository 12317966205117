import favoritePrograms from "./reducers/favorite-programs";
import lator from "./reducers/lator";
import programs from "./reducers/programs";
import subscribedUnis from "./reducers/subscribed-unis";
import temp from "./reducers/temp";
import unis from "./reducers/unis";
import user from "./reducers/user";
import { combineReducers } from "redux";
import { LatoriProgramsReducer } from "./models/latori-program";
import { intlReducer } from "react-intl-redux";

const appReducer = combineReducers({
	unis,
	user,
	temp,
	programs,
	latoriPrograms: LatoriProgramsReducer,
	favoritePrograms,
	subscribedUnis,
	lator,
	intl: intlReducer,
});

export default appReducer;
