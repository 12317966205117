import { getUnisAndPrograms } from "@app/actions/unis";
import { IRProgram } from "@app/api/programs/helper-schemas";
import {
	animateWindowScroll,
	erovnulSubjects,
	getHTMLElementCoords,
} from "@app/commonJavascript";
import { inject } from "@app/modules";
import { IRootState } from "@app/reducers/root";
import { ToDispatchType } from "@chat-app/rootReducer";
import { History } from "history";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import LatoriInviteFriend from "./invite-friend";
import ProgramPicker from "./program-picker-panel";
import LatoriSliders from "./sliders";
import "./styles/resultsPage.min.css";
import SubjectPicker from "./subject-picker-panel";
import CuSuggested from "./suggestions/cuniversity/cu-suggested-component";
import { getCUSuggestedProgramId } from "./suggestions/cuniversity/cu-suggestions";
import TouImg from "./suggestions/tou/styles/img/background.png";
import TouSuggested from "./suggestions/tou/tou-suggested-component";
import { getTouSuggestedProgramId } from "./suggestions/tou/tou-suggestions";

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

interface IOwnProps {
	subjectId: number | null;
	subjectGroup: number[];
	programId: number | null;
	history: History;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	selectedSubjectId: number | null;
	selectedProgramId: number | null;
	selectedSubjectGroup: number[] | null;
	selectedGrantSubjectId: number | null;
}

function shuffle<T>(arr: T[]): T[] {
	let j = 0;
	const a = [...arr];
	for (let i = a.length - 1; i > 0; i--) {
		j = Math.floor(Math.random() * (i + 1));
		const x = a[i];
		a[i] = a[j];
		a[j] = x;
	}
	return a;
}

class LatoriResults extends React.PureComponent<IProps, IState> {
	state: IState = {
		selectedSubjectId: this.props.subjectId,
		selectedProgramId: this.props.programId,
		selectedSubjectGroup: null,
		selectedGrantSubjectId: null,
	};

	LatoriProgramModel = inject("LatoriProgramModel");

	suggestedComponents: Array<{
		component: null | React.ReactElement<any>;
	}> = new Array(2).fill(0).map(e => ({
		component: null,
	}));

	shuffledKeys = shuffle(new Array(2).fill(0).map((e, i) => i));

	resultRef: React.RefObject<HTMLDivElement> = React.createRef();

	onSubjectSelect = (subjectId: number | null) => {
		this.setState({
			selectedSubjectId: subjectId,
			selectedSubjectGroup: null,
		});
	};

	onGrantSubjectSelect = (subjectId: number | null) => {
		this.setState({
			selectedGrantSubjectId: subjectId,
		});
	};

	onSubjectGroupSelect = (subjectIds: number[] | null) => {
		this.setState({
			selectedSubjectId: null,
			selectedSubjectGroup: subjectIds,
		});
	};

	onProgramSelect = (programId: number | null) => {
		const newStateObj: Partial<IState> = { selectedProgramId: programId };
		if (programId) {
			const program = this.getProgram(programId, this.props.programs);
			if (program) {
				if ((program.subjects[2] as number[]).length > 0) {
					newStateObj.selectedSubjectGroup = [
						program.subjects[1][0],
						program.subjects[2][0],
					];
					if ((program.subjects[0] as number[]).length > 2)
						newStateObj.selectedSubjectGroup.unshift(
							program.subjects[0][2]
						);
					newStateObj.selectedSubjectId = null;
				} else if ((program.subjects[1] as number[]).length > 0) {
					if ((program.subjects[0] as number[]).length > 2) {
						newStateObj.selectedSubjectGroup = [
							program.subjects[0][2],
							program.subjects[1][0],
						];
						newStateObj.selectedSubjectId = null;
					} else {
						newStateObj.selectedSubjectGroup = null;
						newStateObj.selectedSubjectId = program.subjects[1][0];
					}
				} else {
					newStateObj.selectedSubjectGroup = null;
					newStateObj.selectedSubjectId = (program
						.subjects[0] as number[]).find(id =>
						erovnulSubjects.some(
							subj => subj.id === id && !subj.isCompulsory
						)
					);
				}
			}
		}
		this.setState({ ...this.state, ...newStateObj });
		if (this.resultRef.current) {
			const coordinates = getHTMLElementCoords(this.resultRef.current);
			animateWindowScroll(coordinates.top - 100, 250);
		}
	};

	getProgram = memoizeOne((programId: number, programs?: IRProgram[]) => {
		return !programs ? undefined : programs.find(p => p.id === programId);
	});

	render() {
		const cuSuggestedProgramId = this.state.selectedProgramId
			? getCUSuggestedProgramId(this.state.selectedProgramId)
			: undefined;
		if (this.state.selectedSubjectId && cuSuggestedProgramId) {
			this.suggestedComponents[0].component = (
				<CuSuggested
					key={"CU"}
					suggestedProgramId={cuSuggestedProgramId}
					// FIXME: Fix this if we ever need this component
					electiveSubjects={this.state.selectedSubjectGroup || []}
					history={this.props.history}
				/>
			);
		} else {
			this.suggestedComponents[0].component = null;
		}

		const touSuggestedId = this.state.selectedProgramId
			? getTouSuggestedProgramId(this.state.selectedProgramId)
			: null;
		if (
			this.state.selectedSubjectId &&
			typeof touSuggestedId === "number"
		) {
			this.suggestedComponents[1].component = (
				<TouSuggested
					key={"TOU"}
					suggestedProgramId={touSuggestedId}
					selectedSubjectId={this.state.selectedSubjectId}
					history={this.props.history}
				/>
			);
		} else {
			this.suggestedComponents[1].component = null;
		}
		const currentProgram =
			this.state.selectedProgramId &&
			this.getProgram(this.state.selectedProgramId, this.props.programs);

		return (
			<div className="latoriResultsPage">
				<div className="left">
					<SubjectPicker
						onSubjectChange={this.onSubjectSelect}
						onSubjectGroupChange={this.onSubjectGroupSelect}
						selectedSubjectId={this.state.selectedSubjectId}
						selectedSubjectGroup={this.state.selectedSubjectGroup}
						programId={this.state.selectedProgramId}
						label={
							this.state.selectedSubjectGroup
								? "არჩევითი საგნები"
								: "არჩევითი საგანი"
						}
					/>

					{currentProgram && currentProgram.grant_subjects && (
						<SubjectPicker
							onSubjectChange={this.onGrantSubjectSelect}
							selectedSubjectId={
								this.state.selectedGrantSubjectId
							}
							programId={this.state.selectedProgramId}
							label={"საგრანტო საგანი"}
							grant={true}
						/>
					)}
					<ProgramPicker
						onChange={this.onProgramSelect}
						value={this.state.selectedProgramId}
						listenToReduxState={true}
					/>
				</div>
				<div className="right">
					{!!(
						this.state.selectedSubjectId ||
						this.state.selectedSubjectGroup
					) &&
						!!this.state.selectedProgramId && (
							<>
								<LatoriSliders
									selectedSubjectId={
										this.state.selectedSubjectId
									}
									selectedSubjectGroup={
										this.state.selectedSubjectGroup
									}
									selectedGrantSubjectId={
										this.state.selectedGrantSubjectId
									}
									programId={this.state.selectedProgramId}
									history={this.props.history}
									onProgramPage={false}
									resultRef={this.resultRef}
								/>
								{/* {this.shuffledKeys.map(index => {
									if (!this.suggestedComponents[index])
										return null;
									return this.suggestedComponents[index]
										.component;
								})} */}
								<div style={invisiblePixelCSS} />
								<LatoriInviteFriend />
							</>
						)}
				</div>
			</div>
		);
	}
}

const invisiblePixelCSS: React.CSSProperties = {
	background: `url(${TouImg})`,
	width: 1,
	height: 1,
	opacity: 0.05,
	position: "absolute",
	top: 0,
	left: 0,
};

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(LatoriResults);
