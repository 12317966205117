import * as React from "react";
import ContentLoader, { List as ListContentLoader } from "react-content-loader";
import styled from "@emotion/styled";
import UniPrograms from "./UniPrograms";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import {
	IRUniversity,
	IUniContent,
	IUniMainInfo,
} from "@app/api/unis/helper-schemas";
import "./styles/freeUni.min.css";
import { cities } from "@app/commonJavascript";
import { PremiumSidebarItemNames } from "../uni-expo/expo-uni-page";
import { useEffect } from "react";
import {
	FormattedMessage,
	getFormattedMessage,
	isLocaleMessageDefined,
} from "@app/utils/locale";
import { getLocale } from "@app/hooks/intl";

interface IOwnProps {
	history: History;
	uni_id: number;
	essentialInfo: IRUniversity;
	mainInfo?: IUniMainInfo;
	contents?: IUniContent[];
	programs?: IRProgram[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = null;

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {}

class FreeUniversityPage extends React.Component<IProps, IState> {
	uniID?: number;
	_isMounted?: boolean;

	state = {} as IState;

	componentDidMount = () => {
		this._isMounted = true;
		const locale = getLocale();
		if (locale !== this.props.essentialInfo.locale)
			this.props.history.push("/");
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	render() {
		return (
			<>
				<UniHeader
					essentialInfo={this.props.essentialInfo}
					mainInfo={this.props.mainInfo}
				/>
				<FreeUniContent
					history={this.props.history}
					contents={this.props.contents}
					essentialInfo={this.props.essentialInfo}
				/>
				<UniPrograms
					history={this.props.history}
					programs={this.props.programs}
				/>
			</>
		);
	}
}

interface IUniHeader {
	essentialInfo: IRUniversity;
	mainInfo?: IUniMainInfo;
}

const UniHeaderDiv = styled("div")(
	null,
	(props: {
		coverBGColor: IRUniversity["coverBGColor"];
		coverUrl: IRUniversity["coverUrl"];
	}) => ({
		background: props.coverUrl
			? `url(/photos/unicovers/${props.coverUrl})`
			: props.coverBGColor
			? props.coverBGColor
			: `linear-gradient(10deg, #8b2db2, #d54077, #fa7765)`,
	})
);

interface IUniLogo {
	logoUrl: string;
	logoBGColor: string | null;
}
export const UniLogo = styled("div")({}, (props: IUniLogo) => ({
	background: `url(/photos/unilogos/${
		props.logoUrl
	}) center center no-repeat, ${props.logoBGColor || "#fff"}`,
	backgroundSize: "90%, 100%",
}));

const UniHeader: React.FC<IUniHeader> = props => (
	<UniHeaderDiv
		className="freeUniHeader"
		coverBGColor={props.essentialInfo.coverBGColor}
		coverUrl={props.essentialInfo.coverUrl}
	>
		<div className="main">
			<div className="freeUniHeaderContent">
				<div>
					<div className="freeUniLeftSide">
						<UniLogo
							className="freeUniLogo"
							logoUrl={props.essentialInfo.logoUrl}
							logoBGColor={props.essentialInfo.logoBGColor}
						/>
						<div className="freeUniName">
							<span>{props.essentialInfo.code}</span>
							<span>{props.essentialInfo.name}</span>
						</div>
					</div>
				</div>
				<div className="freeUniMainInfoContent">
					{props.mainInfo ? (
						<>
							<FreeUniMainInfo
								{...props.mainInfo}
								cities={props.essentialInfo.cities}
							/>
						</>
					) : (
						<FreeUniMainInfoContentLoader />
					)}
				</div>
			</div>
		</div>
	</UniHeaderDiv>
);

interface IFreeUniMainInfo {
	phone: IUniMainInfo["phone"];
	email: IUniMainInfo["email"];
	address: IUniMainInfo["address"];
	web: IUniMainInfo["web"];
	cities: IRUniversity["cities"];
}

const FreeUniMainInfo: React.SFC<IFreeUniMainInfo> = props => (
	<div className="freeUniMainInfo">
		{props.cities && (
			<div>
				<span>
					<FormattedMessage id="uniPage.fields.city" />
				</span>
				<span>
					{props.cities
						.map(cityID => {
							const city = cities.find(
								each => each.id === cityID
							);
							if (!city) return "";
							if (isLocaleMessageDefined(`cities.${city.name}`))
								return getFormattedMessage(
									`cities.${city.name}`
								);
							return city.name;
						})
						.join(", ")}
				</span>
			</div>
		)}
		<div className="separator" />
		<div>
			<span>
				<FormattedMessage id="uniPage.fields.phone" />
			</span>
			<span>{props.phone}</span>
		</div>
		<div className="separator" />
		<div>
			<span>
				<FormattedMessage id="uniPage.fields.email" />
			</span>
			<span>{props.email}</span>
		</div>
		<div className="separator" />
		<div>
			<span>
				<FormattedMessage id="uniPage.fields.address" />
			</span>
			<span style={{ fontSize: 12 }}>{props.address}</span>
		</div>
		<div className="separator" />
		<div>
			<span>
				<FormattedMessage id="uniPage.fields.web" />
			</span>
			<span>
				<a
					href={`http://${props.web}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{props.web}
				</a>
			</span>
		</div>
	</div>
);

const FreeUniMainInfoContentLoader = () => (
	<ContentLoader
		width={380}
		height={110}
		primaryColor="#f3f3f3"
		secondaryColor="#d0c7c7"
		primaryOpacity={0.2}
		secondaryOpacity={0.05}
	>
		<rect x="0" y="0" rx="4" ry="4" width="65" height="20" />
		<rect x="67.5" y="0" rx="4" ry="4" width="312.5" height="20" />
		<rect x="0" y="22.5" rx="4" ry="4" width="65" height="20" />
		<rect x="67.5" y="22.5" rx="4" ry="4" width="312.5" height="20" />
		<rect x="0" y="45" rx="4" ry="4" width="65" height="20" />
		<rect x="67.5" y="45" rx="4" ry="4" width="312.5" height="20" />
		<rect x="0" y="67.5" rx="4" ry="4" width="65" height="20" />
		<rect x="67.5" y="67.5" rx="4" ry="4" width="312.5" height="20" />
		<rect x="0" y="90" rx="4" ry="4" width="65" height="20" />
		<rect x="67.5" y="90" rx="4" ry="4" width="312.5" height="20" />
	</ContentLoader>
);

interface IFreeUniContent {
	contents?: IUniContent[];
	essentialInfo: IRUniversity;
	history: History;
}

const UniContentContainer = styled("div")(
	{
		//   height: 500
	},
	(props: {
		logoBGColor: IRUniversity["logoBGColor"];
		coverBGcolor: IRUniversity["coverBGColor"];
	}) => ({
		background: props.coverBGcolor
			? props.coverBGcolor
			: props.logoBGColor
			? props.logoBGColor
			: `linear-gradient(to right, #8b2db2, #d54077, #fa7765)`,
	})
);

const FreeUniContent: React.FC<IFreeUniContent> = ({
	contents,
	essentialInfo,
	history,
}) => {
	const c = contents || [{} as IUniContent];
	useEffect(() => {
		const search = window.location.search;
		const key = "content=";
		let index = search.indexOf(key);
		if (index >= 0) {
			index += key.length;
			const content = PremiumSidebarItemNames[search.substring(index)];
			if (content === PremiumSidebarItemNames.about_us) {
				history.push(
					`${window.location.pathname}#freeUniContentContainer`
				);
			}
			if (content === PremiumSidebarItemNames.programs)
				history.push(
					`${window.location.pathname}#progsSearchBarContainer`
				);
		}
	}, [history]);

	return (
		<div className="main">
			<UniContentContainer
				id="freeUniContentContainer"
				className="freeUniContentContainer"
				logoBGColor={essentialInfo.logoBGColor}
				coverBGcolor={essentialInfo.coverBGColor}
			>
				{c.map((content, index) => (
					<React.Fragment key={index}>
						<div
							className="uniContentPapers"
							id={`programContent${index}`}
						>
							<div className="papers">
								<div className="back2" />
								<div className="back1" />
								{typeof content.text === "string" ||
								content.text === null ? (
									<div
										className="front htmlContentText"
										dangerouslySetInnerHTML={{
											__html:
												(content.title
													? `<h1 class="headline">${content.title}</h1>`
													: "") +
												(content.text ||
													"ტექსტი არ მოიძებნა"),
										}}
									/>
								) : (
									<div className="front htmlContentText">
										<FreeUniContentLoader />
									</div>
								)}
							</div>
						</div>
					</React.Fragment>
				))}
			</UniContentContainer>
		</div>
	);
};

const FreeUniContentLoader = () => <ListContentLoader />;

// TODO: load uni from temp table
const mapStateToProps = (state: IRootState) => ({});

export default connect<IStateProps, IDispatchProps, IOwnProps>(mapStateToProps)(
	FreeUniversityPage
);
