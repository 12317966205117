import * as React from "react";
import AdminChatComponent from "./admin-chat-component";
import { chatStore } from "@chat-app/socket-functions";
import { connect, Provider } from "react-redux";
import { IRootState } from "@app/reducers/root";
import { match } from "react-router";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

class AdminChat extends React.Component<IProps> {
	render() {
		const uniId = this.props.match.params.uni_id;
		let uniPicture;
		if (this.props.unis) {
			const currentUni = this.props.unis.find(uni => uni.id === +uniId);
			if (currentUni) uniPicture = currentUni.logoUrl;
		}

		return (
			<Provider store={chatStore}>
				<AdminChatComponent
					uniId={uniId}
					uniPicture={"/photos/unilogos/" + uniPicture}
				/>
			</Provider>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	user: state.user.userData,
	unis: state.unis.info,
});

export default connect<IStateProps, any, IOwnProps>(mapStateToProps)(AdminChat);
