import Joi, { SchemaToType } from "@app/utils/joi";
import { ToulatorUserActivitySchema } from "./helper-schemas";

///

export const APUTSaveRequestSchema = Joi.object().keys({
	user_id: Joi.string().allow(null),
	fullname: Joi.string().required(),
	mobile: Joi.number()
		.integer()
		.required(),
});

export type IAPUTSaveRequest = SchemaToType<typeof APUTSaveRequestSchema>;

///

export const APUTSaveUserActivitySchema = Joi.object().keys({
	user_id: Joi.string(),
	program_id: Joi.number()
		.integer()
		.required(),
	user_scores: Joi.array()
		.items(Joi.number().integer())
		.required(),
	passed: Joi.boolean().required(),
	mobile: Joi.number()
		.integer()
		.required(),
	fullname: Joi.string().required(),
});

export type IAPUTSaveUserActivity = SchemaToType<
	typeof APUTSaveUserActivitySchema
>;

///

export const APUTSavePageViewSchema = Joi.object().keys({
	user_id: Joi.string().required(),
});

export type IAPUTSavePageView = SchemaToType<typeof APUTSavePageViewSchema>;

///

export const RGETAllRequestsWithScoresSchema = Joi.array().items(
	ToulatorUserActivitySchema.keys({
		requestedCall: Joi.boolean()
			.truthy(1)
			.falsy(0)
			.required(),
		programName: Joi.string()
			.allow("", null)
			.required(),
	})
);
export type IRGETAllRequestsWithScores = SchemaToType<
	typeof RGETAllRequestsWithScoresSchema
>;
