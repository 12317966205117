import * as React from "react";
import ContentLoader from "react-content-loader";
import DragSVG from "./styles/img/FourExpandArrows";
import EditIcon from "@material-ui/icons/Edit";
import Done from "@material-ui/icons/Done";
import memoizeOne from "memoize-one";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import {
	canAccessAllProgramsOfUni,
	canAccessAllUnis,
	canAccessUni,
	canEditProgramContentByPermission,
	canEditProgramSettingsByPermission,
	getPermissionsOfProgram,
} from "@app/permissions";
import {
	changeProgramIntraUniPositionCoefficient,
	deleteProgram,
} from "@app/actions/programs";
import { connect } from "react-redux";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import { getUniById, IGetUniById } from "@app/actions/unis";
import { History } from "history";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import { IRUser } from "@app/api/users/helper-schemas";
import { match } from "react-router";
import { sortProgramsByCoeff } from "../MainPageComponents/search/searchAlgorithms";
import Tooltip from "@material-ui/core/Tooltip";
import "./styles/adminPrograms.min.css";
import api from "@app/api";

interface IOwnProps {
	history: History;
	match: match<{ uni_id: string }>;
	userData: IRUser;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

const progNamemaxLength = 60;
const progShortName = (name: string): string => {
	return name.length <= progNamemaxLength
		? name
		: name.substr(0, progNamemaxLength - 2).trim() + "..";
};

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	programs: IRProgram[];
}

function reorder<T extends any[]>(
	list: T,
	startIndex: number,
	endIndex: number
): T {
	const result = [...list] as T;
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

class AdminPrograms extends React.Component<IProps, IState> {
	uni_id: number;
	_isMounted: boolean;
	uni?: IRUniversity;
	programPermissions: number[] = []; // program_id as index
	allowDrag = false;

	state = {
		programs: [],
	} as IState;

	sortPrograms = memoizeOne(
		(programs: IRProgram[] | undefined): IRProgram[] => {
			if (!programs) return [];
			const newPrograms = sortProgramsByCoeff(
				programs.filter(prog => prog.uni_id === this.uni_id)
			);
			setTimeout(() => {
				if (!this._isMounted) return;
				this.setState({ programs: newPrograms });
			}, 0);
			return newPrograms;
		}
	);

	constructor(props: IProps) {
		super(props);
		this.uni_id = +props.match.params.uni_id;
		if (!canAccessUni(this.uni_id, props.userData.permissions)) {
			this.props.history.replace("/");
		}
		if (props.unis) {
			const myUni = props.unis.find(uni => uni.id === this.uni_id);
			if (typeof myUni !== "undefined") {
				this.uni = myUni;
			}
		}
		if (props.programs) {
			this.calculatePermissions(props.programs);
		}
		if (this.props.userData.permissions) {
			if (
				canAccessUni(this.uni_id, this.props.userData.permissions) &&
				(canAccessAllUnis(this.props.userData.permissions.privilege) ||
					canAccessAllProgramsOfUni(
						this.props.userData.permissions.privilege
					))
			) {
				this.allowDrag = true;
			} else {
				this.allowDrag = false;
			}
		}
	}

	calculatePermissions = (programs: IRProgram[]) => {
		programs.forEach(program => {
			this.programPermissions[program.id] =
				getPermissionsOfProgram(
					program.id,
					program.uni_id,
					this.props.userData.permissions
				) || 0;
		});
	};

	getUni = (): ReturnType<IGetUniById> => {
		return this.props.getUniById({
			id: this.uni_id,
			getEssentialInfo: false,
			getMainInfo: false,
			getContents: false,
			getPrograms: true,
		});
	};

	componentDidMount() {
		this._isMounted = true;
		if (!this.uni || this.state.programs.length === 0) {
			this.getUni().then(data => {
				if (!this._isMounted) return;
				this.calculatePermissions(data.programs!);
				this.setState({
					programs: data.programs!,
				});
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const endIndex: number = result.destination.index;
		const currentIndex: number = result.source.index;
		let newCoefficient = 0;

		if (endIndex === currentIndex) return;

		if (endIndex < currentIndex) {
			if (endIndex === 0) {
				newCoefficient =
					this.state.programs[endIndex].intraUniPositionCoefficient +
					1;
			} else {
				newCoefficient =
					(this.state.programs[endIndex].intraUniPositionCoefficient +
						this.state.programs[endIndex - 1]
							.intraUniPositionCoefficient) /
					2;
			}
		} else if (endIndex > currentIndex) {
			if (endIndex === this.state.programs.length - 1) {
				newCoefficient =
					this.state.programs[endIndex].intraUniPositionCoefficient /
					2;
			} else {
				newCoefficient =
					(this.state.programs[endIndex].intraUniPositionCoefficient +
						this.state.programs[endIndex + 1]
							.intraUniPositionCoefficient) /
					2;
			}
		}

		this.props.changeProgramIntraUniPositionCoefficient({
			programId: this.state.programs[result.source.index].id,
			uniId: this.state.programs[result.source.index].uni_id,
			newCoefficient,
		});

		// this.state.programs[result.source.index].intraUniPositionCoefficient = newCoefficient;

		const programs = reorder(
			this.state.programs,
			result.source.index,
			result.destination.index
		);

		this.setState({
			programs,
		});
	};

	onProgramDelete = (programId: number) => {
		// eslint-disable-next-line no-restricted-globals
		if (confirm("ნამდვილად გსურთ ამ ფაკლუტეტის წაშლა?"))
			this.props.deleteProgram(programId);
	};

	redirectToProgramAddPage = () => {
		this.props.history.push("programs/add");
	};

	render() {
		const programs = this.sortPrograms(this.props.programs);
		if (!programs) {
			return (
				<div className="main loaderProgramWrapper">
					<ContentLoader width={400} height={50}>
						<rect
							x="135"
							y="28"
							rx="4"
							ry="4"
							width="100"
							height="12"
						/>
					</ContentLoader>
					<SingleAdminProgramLoader />
					<SingleAdminProgramLoader />
					<SingleAdminProgramLoader />
					<SingleAdminProgramLoader />
				</div>
			);
		}
		return (
			<div>
				<div className="main programWrapper">
					<div className="header">ფაკულტეტები</div>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div ref={provided.innerRef}>
									{programs.map((program, index) => (
										<Draggable
											isDragDisabled={!this.allowDrag}
											key={program.id}
											draggableId={program.id.toString()}
											index={index}
										>
											{(provided2, snapshot2) => (
												<div
													ref={provided2.innerRef}
													{...provided2.draggableProps}
													{...provided2.dragHandleProps}
												>
													<SingleAdminProgram
														program={program}
														onDelete={
															this.onProgramDelete
														}
														index={index}
														history={
															this.props.history
														}
														canEditSettings={canEditProgramSettingsByPermission(
															this
																.programPermissions[
																program.id
															]
														)}
														canEditContents={canEditProgramContentByPermission(
															this
																.programPermissions[
																program.id
															]
														)}
														canDeleteProgram={canAccessAllUnis(
															this.props.userData
																.permissions
																? this.props
																		.userData
																		.permissions
																		.privilege
																: null
														)}
													/>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<button
						className="adminPrimaryButton"
						style={{ width: 300 }}
						onClick={this.redirectToProgramAddPage}
					>
						ფაკულტეტის დამატება
					</button>
				</div>
			</div>
		);
	}
}

interface ISingleAdminProgramProps {
	index: number;
	program: IRProgram;
	history: History;
	onDelete: (programId: number) => void;
	canEditSettings: boolean;
	canEditContents: boolean;
	canDeleteProgram?: boolean;
}

const SingleAdminProgram: React.SFC<ISingleAdminProgramProps> = ({
	index,
	program,
	onDelete,
	history,
	canEditSettings,
	canEditContents,
	canDeleteProgram,
}) => {
	return (
		<div className="program" key={program.id}>
			<div className="programNumber">{index + 1}</div>
			<div className="adminSingleItemContainer">
				<div>
					<Tooltip
						title={
							program.name.length > progNamemaxLength ? (
								<span style={{ fontSize: 20 }}>
									{program.name}
								</span>
							) : (
								""
							)
						}
						placement="bottom"
					>
						<span>{progShortName(program.name)}</span>
					</Tooltip>
				</div>
				<div>
					<DragSVG />
				</div>
			</div>
			{canEditSettings && (
				<div
					className="penIcon"
					onClick={() => history.push(`program/${program.id}/edit`)}
				>
					<SettingsIcon />
				</div>
			)}
			{canEditContents && (
				<div
					className="penIcon"
					onClick={() =>
						history.push(`program/${program.id}/edit_content`)
					}
				>
					<EditIcon />
				</div>
			)}
			{canDeleteProgram && (
				<div className="penIcon" onClick={() => onDelete(program.id)}>
					<DeleteIcon />
				</div>
			)}
			{program.yearOfUpdatedInfo === new Date().getFullYear() ? (
				<div className="penIcon">
					<Done />
				</div>
			) : (
				<div
					className="penIcon"
					style={{ background: "transparent" }}
				/>
			)}
		</div>
	);
};

const SingleAdminProgramLoader: React.SFC<{}> = props => (
	<ContentLoader width={400} height={35}>
		<circle cx="15" cy="12" r="8" />
		<rect x="30" y="5" rx="10" ry="10" width="317" height="15" />
		<circle cx="365" cy="12" r="10" />
	</ContentLoader>
);

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	programs: state.programs.info,
});

const mapDispatchToProps = {
	getUniById,
	changeProgramIntraUniPositionCoefficient,
	deleteProgram,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps as any
)(AdminPrograms);
