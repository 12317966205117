import { loadUserLatorInfo } from "@app/actions/lator";
import { getUnisAndPrograms } from "@app/actions/unis";
import api from "@app/api";
import { erovnulSubjects } from "@app/commonJavascript";
import { inject } from "@app/modules";
import { IRootState, ToDispatchType } from "@app/reducers/root";
import styled from "@emotion/styled";
import { TextField } from "@material-ui/core";
import { css } from "emotion";
import { History } from "history";
import * as React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import SvgLoading from "../styles/img/Loading";
import Popup, { PopupContent } from "../Widgets/Popup";
import ChoosePrograms from "./choose-programs";
import ChooseSubjects from "./choose-subjects";
import attrs from "./html-attrs";
import PremiumUnisLogos from "./premium-unis-logos";
import LatoriResults from "./results";
import "./styles/sliders.min.css";

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	isValidMob?: boolean;
	mob?: number;
	smsCode?: number;
	isValidSMSCode: boolean;
	smsCodeSent: boolean;
	loadingPopup: boolean;
}
class LatoriPage extends React.Component<IProps, IState> {
	state: IState = {
		smsCodeSent: false,
		isValidSMSCode: false,
		loadingPopup: false,
	};

	componentDidMount() {
		api.users.checkIfMobValid().then(data => {
			this.setState({ isValidMob: !!data.isValidated });
		});
		if (!this.props.unis) {
			this.props.getUnisAndPrograms().then();
		}
		if (!this.props.subjectIds || !this.props.programIds) {
			this.props.loadUserLatorInfo().then();
		}
	}

	onMobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = (e.target.value as unknown) as number;
		this.setState({ mob: value });
	};

	onMobCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = (e.target.value as unknown) as number;
		this.setState({ smsCode: value });
	};

	validateMobileFormat = (): boolean => {
		if (!this.state.mob) {
			alert("შეიყვანეთ მობილურის ნომერი");
			return false;
		}
		const mobString: string = this.state.mob + "";
		if (mobString.length !== 9) {
			alert("მობილურის ნომერი უნდა შედგებოდეს 9 ციფრისაგან");
			return false;
		}
		if (mobString.charAt(0) !== "5") {
			alert('მობილურის ნომერი უნდა იწყებოდეს ციფრით "5"');
			return false;
		}
		return true;
	};

	validateSMSCodeFormat = (): boolean => {
		if (!this.state.smsCode) {
			alert("შეიყვანეთ SMS-ით გამოგზავნილი ხუთნიშნა კოდი");
			return false;
		}
		const codeString: string = this.state.smsCode + "";
		if (codeString.length !== 5) {
			alert("კოდი უნდა იყოს ხუთნიშნა");
			return false;
		}
		return true;
	};

	onValidateMob = () => {
		const isValid: boolean = this.validateMobileFormat();
		if (isValid) {
			this.setState({ loadingPopup: true });
			api.users
				.validateMobile({ mob: this.state.mob! })
				.then(() => {
					this.setState({
						isValidMob: true,
						smsCodeSent: true,
						loadingPopup: false,
					});
				})
				.catch(err => {
					switch (err.response.data) {
						case "invalid mobile":
							alert("ნომერი არასწორია");
							break;
						case "message_not_sent":
							alert("მესიჯი ვერ გაიგზავნა");
							break;
						case "too_many_sms":
							alert(
								"თქვენ ამოწურეთ მესიჯების გაგზავნის დღიური ლიმიტი sms-ით ვერიფიკაციისთვის"
							);
							break;
						case "mobile exists":
							alert(
								"ამ მობილურის ნომრით უკვე დარეგისტრირებულია მომხმარებელი"
							);
							break;
						default:
							alert("დაფიქსირდა შეცდომა");
							break;
					}
					this.setState({ loadingPopup: false });
				});
		}
	};

	onValidateMobCode = () => {
		const isValidMob: boolean = this.validateMobileFormat();
		const isValidCode: boolean = this.validateSMSCodeFormat();
		if (isValidMob && isValidCode) {
			this.setState({ loadingPopup: true });
			api.users
				.validateMobileCode({
					mob: this.state.mob!,
					code: this.state.smsCode!,
				})
				.then(() => {
					this.setState({
						isValidSMSCode: true,
						loadingPopup: false,
					});
				})
				.catch(err => {
					switch (err.response.data) {
						case "user not found":
							alert("მომხმარებელი ვერ მოიძებნა");
							break;
						case "mob_time_out":
							alert(
								"კოდის შეყვანისთვის განკუთვნილი დრო ამოიწურა"
							);
							break;
						case "wrong_code":
							alert("კოდი არასწორია. სცადეთ თავიდან");
							break;
						default:
							alert("დაფიქსირდა შეცდომა");
							break;
					}
					this.setState({ loadingPopup: false });
				});
		}
	};

	LatoriProgramModel = inject("LatoriProgramModel");

	render() {
		if (
			!this.props.unis ||
			!this.props.subjectIds ||
			!this.props.programIds
		) {
			return (
				<>
					<div className="main center main2">
						<Helmet {...attrs} />
						<SvgLoading />
					</div>
				</>
			);
		}
		if (this.props.subjectIds.length === 0) {
			return (
				<>
					<div className="main main2">
						<Helmet {...attrs} title="აირჩიეთ საგნები" />
						<ChooseSubjects />
					</div>
				</>
			);
		} else if (this.props.programIds.length === 0) {
			return (
				<>
					<div className="main main2">
						<Helmet {...attrs} title="აირჩიეთ ფაკულტეტები" />
						<ChoosePrograms history={this.props.history} />
					</div>
				</>
			);
		}

		const programId =
			this.props.programIds.length === 0
				? null
				: this.props.programIds[0];

		const currentProgram = !programId
			? undefined
			: this.props.programs &&
			  this.props.programs.find(program => program.id === programId);

		let subjectGroup: number[] = [];

		let selectedSubjectId = undefined as number | undefined;
		if (currentProgram !== undefined) {
			const subjects = this.props.subjectIds.filter(subjId =>
				erovnulSubjects.find(
					subj => subj.id === subjId && !subj.isCompulsory
				)
			);
			if (
				(currentProgram.subjects[2] as number[]).length === 0 &&
				(currentProgram.subjects[1] as number[]).length === 0
			) {
				selectedSubjectId = (currentProgram
					.subjects[0] as number[]).find(subjId =>
					subjects.includes(subjId)
				);
				if (!selectedSubjectId)
					selectedSubjectId = (currentProgram
						.subjects[0] as number[]).find(subjId =>
						erovnulSubjects.find(
							subj => subj.id === subjId && !subj.isCompulsory
						)
					);
			} else if ((currentProgram.subjects[2] as number[]).length === 0) {
				selectedSubjectId = (currentProgram
					.subjects[1] as number[]).find(subjId =>
					subjects.includes(subjId)
				);
				if (!selectedSubjectId)
					selectedSubjectId = (currentProgram
						.subjects[1] as number[])[0];
			} else if ((currentProgram.subjects[2] as number[]).length > 0) {
				subjectGroup = [...currentProgram.subjects[2]];
				if ((currentProgram.subjects[0] as number[]).length > 2)
					subjectGroup.unshift(currentProgram.subjects[0][2]);
			}
		}
		return (
			<div className="main main2">
				<Helmet {...attrs} title="მურწყულატორის შედეგები" />
				<PremiumUnisLogos history={this.props.history} />
				{this.state.isValidMob === false &&
					!this.state.loadingPopup && (
						<ValidationPopup
							text="მურწყულატორის გამოსაყენებლად აუცილებელია, დაადასტუროთ ტელეფონის ნომერი"
							placeholder="შეიყვანეთ ტელეფონის ნომერი"
							onInputChange={this.onMobChange}
							onConfirm={this.onValidateMob}
						/>
					)}
				{this.state.loadingPopup && <LoadingPopup />}
				{this.state.isValidMob &&
					this.state.smsCodeSent &&
					!this.state.isValidSMSCode &&
					!this.state.loadingPopup && (
						<ValidationPopup
							text="შეიყვანეთ კოდი, რომელიც SMS-ის სახით მიიღეთ ტელეფონზე"
							placeholder="შეიყვანეთ კოდი"
							onInputChange={this.onMobCodeChange}
							onConfirm={this.onValidateMobCode}
						/>
					)}
				<LatoriResults
					subjectId={selectedSubjectId || null}
					subjectGroup={subjectGroup}
					programId={programId}
					history={this.props.history}
				/>
			</div>
		);
	}
}

interface IPopupProps {
	text: string;
	placeholder: string;
	onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onConfirm: () => void;
}

const ValidationPopup: React.FC<IPopupProps> = (props: IPopupProps) => (
	<Popup onClose={() => null}>
		<PopupContent>
			<PopupContentDiv>{props.text}</PopupContentDiv>
			<TextField
				className={InputStyles}
				type={"number"}
				placeholder={props.placeholder}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					props.onInputChange(e)
				}
			/>
			<button className={PopupButtonStyls} onClick={props.onConfirm}>
				ნომრის დადასტურება
			</button>
		</PopupContent>
	</Popup>
);

const LoadingPopup = () => (
	<Popup onClose={() => null}>
		<PopupContent>
			<PopupContentDiv>
				<div style={{ width: "100%", textAlign: "center" }}>
					<SvgLoading />
				</div>
			</PopupContentDiv>
		</PopupContent>
	</Popup>
);

const PopupContentDiv = styled.div`
	font-family: RobotoUpperCase;
	font-size: 18px;
	margin-bottom: 20px;
`;

const InputStyles = css`
	width: 300px !important;
`;

const PopupButtonStyls = css`
	font-family: RobotoUpperCase;
	font-size: 16px;
	border-radius: 5px;
	padding: 10px 30px;
	background-color: #fe2f73;
	color: white;
	border: none;
	cursor: pointer;
	outline: none;
	margin: 20px;
	&:hover {
		background-color: #ce3769;
	}
`;

const mapStateToProps = (state: IRootState) => ({
	unis: state.unis.info,
	subjectIds: state.lator.subjectIds,
	programIds: state.lator.programIds,
	programs: state.programs.info,
});

const mapDispatchToProps = {
	getUnisAndPrograms,
	loadUserLatorInfo,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(LatoriPage);
