import * as React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Field1Img from "./styles/img/fields/political-min.jpg";
import Field2Img from "./styles/img/fields/humanitarian-min.jpg";
import Field3Img from "./styles/img/fields/technical-min.jpg";
import Field4Img from "./styles/img/fields/natural-science-min.jpg";
import Field5Img from "./styles/img/fields/jurisdiction-min.jpg";
import Field6Img from "./styles/img/fields/business-min.jpg";
import Field7Img from "./styles/img/fields/medicine-min.jpg";
import Field8Img from "./styles/img/fields/art-min.jpg";
import FieldUnknownImg from "./styles/img/fields/unknown-min.jpg";
import NotFavoriteIcon from "@material-ui/icons/FavoriteBorder";
import requireLoginForAction from "@app/components/requireLoginForAction";
import styled from "@emotion/styled";
import triggerEvent from "@app/utils/events";
import {
	addFavoriteProgram,
	IAddFavoriteProgram,
	IRemoveFavoriteProgram,
	removeFavoriteProgram,
} from "@app/actions/favorite-programs";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/reducers/root";
import { IRProgram } from "@app/api/programs/helper-schemas";
import Tooltip from "@material-ui/core/Tooltip";

const getProgramPict = (program: IRProgram) => {
	if (program.photoUrl) return `/photos/programphotos/${program.photoUrl}`;
	if (!program.fields || program.fields.length === 0) return FieldUnknownImg;
	switch (program.fields[0]) {
		case 1:
			return Field1Img;
		case 2:
			return Field2Img;
		case 3:
			return Field3Img;
		case 4:
			return Field4Img;
		case 5:
			return Field5Img;
		case 6:
			return Field6Img;
		case 7:
			return Field7Img;
		case 8:
			return Field8Img;
		default:
			return FieldUnknownImg;
	}
};
export { getProgramPict };

const progNamemaxLength = 20;
const progShortName = (name: string): string => {
	return name.length <= progNamemaxLength
		? name
		: name.substr(0, progNamemaxLength - 2).trim() + "..";
};

const SmallProg = styled("div")(null, (props: { program: IRProgram }) => ({
	background: `url(${getProgramPict(props.program)})`,
	backgroundSize: "cover",
}));

interface IOwnProps {
	program: IRProgram;
	isFavorite?: boolean;
	onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	addFavoriteProgram: IAddFavoriteProgram;
	removeFavoriteProgram: IRemoveFavoriteProgram;
}

type ISmallProgram = IOwnProps & IStateProps & IDispatchProps;

const SmallProgram: React.SFC<ISmallProgram> = props => {
	let isFavorite = false;
	if (props.favorites) isFavorite = !!props.favorites[props.program.id];
	const isFinanced = props.program.fullyFinancedPlaces > 0;
	const currency = props.program.locale === "ka" ? "ლარი" : "დოლარი";
	return (
		<SmallProg
			className="uniProg"
			onClick={props.onClick}
			program={props.program}
		>
			<div className="content">
				<div className="info">
					{isFinanced && (
						<div
							style={{
								fontFamily: "RobotoUpperCase",
								fontWeight: "bold",
							}}
						>
							სრულად დაფინანსებული
						</div>
					)}
					<div className="separator" style={{ width: "100%" }} />
					<div className="f" style={{ height: 55 }}>
						<span className="price">
							{props.program.locale === "en" && "USD"}{" "}
							{props.program.price}{" "}
							{props.program.locale === "ka" && "ლარი"}
						</span>
						<Tooltip
							title={
								props.program.name.length >
								progNamemaxLength ? (
									<span style={{ fontSize: 20 }}>
										{props.program.name}
									</span>
								) : (
									""
								)
							}
							placement="bottom"
						>
							<span className="name">
								{progShortName(props.program.name)}
							</span>
						</Tooltip>
					</div>
				</div>
				<div className="fav">
					<div className="VM">
						{isFavorite ? (
							<div
								onClick={e => {
									e.stopPropagation();
									requireLoginForAction(() => {
										props
											.removeFavoriteProgram({
												program_id: props.program.id,
												uni_id: props.program.uni_id,
											})
											.then();
									});
								}}
							>
								<FavoriteIcon />
							</div>
						) : (
							<div
								onClick={e => {
									e.stopPropagation();
									triggerEvent(
										{
											category: "Button",
											action: "Add Program to favorites",
											label: "from card",
										},
										{
											program_id: props.program.id,
											uni_id: props.program.uni_id,
										}
									);
									requireLoginForAction(() => {
										props
											.addFavoriteProgram({
												program_id: props.program.id,
												uni_id: props.program.uni_id,
											})
											.then();
									});
								}}
							>
								<NotFavoriteIcon />
							</div>
						)}
					</div>
				</div>
			</div>
		</SmallProg>
	);
};

export { SmallProgram };

const mapStateToProps = (state: IRootState) => ({
	favorites: state.favoritePrograms.favorites,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	// tslint:disable-next-line:ter-indent
	({
		addFavoriteProgram,
		removeFavoriteProgram,
	} as any) as IDispatchProps
)(SmallProgram);
