import api from "@app/api";
import {
	ADD_SUBSCRIPTION_TO_UNI,
	LOAD_SUBSCRIBED_UNIS,
	REMOVE_SUBSCRIPTION_FROM_UNI,
} from "@app/action-types";
import { ILogoutAction, rejectMiniPopup } from "./user";
import {
	IRInsertOrUpdateUniSubscription,
	ISubscribedUnis,
} from "@app/api/users/unis/validators";
import { IRootActions, IRootState } from "@app/reducers/root";
import {
	MiniPopupTypes,
	PopupRejectionEnum,
} from "@app/api/users/helper-schemas";
import { ThunkAction } from "redux-thunk";

interface ILoadSubscribedUnisAction {
	type: typeof LOAD_SUBSCRIBED_UNIS;
	unis: ISubscribedUnis;
	lastUpdate: Date;
}
export const loadSubscribedUnis = (
	unis: ISubscribedUnis,
	lastUpdate: Date = new Date()
): ILoadSubscribedUnisAction => {
	return {
		type: LOAD_SUBSCRIBED_UNIS,
		unis,
		lastUpdate,
	};
};

interface ISubscribeUniAction {
	type: typeof ADD_SUBSCRIPTION_TO_UNI;
	id: number;
}

const subscribeUniAction = (uni_id: number): ISubscribeUniAction => {
	return {
		type: ADD_SUBSCRIPTION_TO_UNI,
		id: uni_id,
	};
};

const isSubscribed = (
	uni_id: number,
	subscribed: IRootState["subscribedUnis"]["subscribed"]
): boolean => {
	if (typeof subscribed !== "object" || subscribed === null) return false;
	return !!subscribed[uni_id];
};

export type ISubscribeUni = (
	uni_id: number
) => Promise<IRInsertOrUpdateUniSubscription>;
// tslint:disable-next-line:max-line-length
export const subscribeUni = (
	data: number
): ThunkAction<
	ReturnType<ISubscribeUni>,
	IRootState,
	null,
	SubscribedUnisActions
> => (dispatch, getState): ReturnType<ISubscribeUni> => {
	const state = getState();
	if (isSubscribed(data, state.subscribedUnis.subscribed)) {
		// console.log('aleady subscribed');
		return Promise.resolve();
	}
	dispatch(subscribeUniAction(data));
	return api.users.unis
		.saveSubscribtions({
			uni_id: data,
		})
		.then(ret => {
			return ret;
		})
		.catch(e => {
			dispatch(unsubscribeUniAction(data));
			throw e;
		});
};

interface IUnsubscribeUniAction {
	type: typeof REMOVE_SUBSCRIPTION_FROM_UNI;
	id: number;
}

const unsubscribeUniAction = (id: number): IUnsubscribeUniAction => {
	return {
		type: REMOVE_SUBSCRIPTION_FROM_UNI,
		id,
	};
};

export type IUnsubscribeUni = (
	uni_id: number
) => Promise<IRInsertOrUpdateUniSubscription>;
// tslint:disable-next-line:max-line-length
export const unsubscribeUni = (
	uni_id: number
): ThunkAction<ReturnType<IUnsubscribeUni>, IRootState, null, IRootActions> => (
	dispatch,
	getState
): ReturnType<IUnsubscribeUni> => {
	const state = getState();
	if (!isSubscribed(uni_id, state.subscribedUnis.subscribed)) {
		// console.log('aleady unsubscribed');
		return Promise.resolve();
	}
	dispatch(unsubscribeUniAction(uni_id));
	dispatch(
		rejectMiniPopup(
			{
				rejection: PopupRejectionEnum.untilLogout,
				type: MiniPopupTypes.UniSubscription,
				uni_id,
			},
			false
		)
	);
	return api.users.unis
		.saveSubscribtions({
			uni_id,
		})
		.then(ret => {
			return ret;
		})
		.catch(e => {
			dispatch(subscribeUniAction(uni_id));
			throw e;
		});
};

export type SubscribedUnisActions =
	| ILoadSubscribedUnisAction
	| ISubscribeUniAction
	| IUnsubscribeUniAction
	| ILogoutAction;
