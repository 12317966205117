import React from "react";

const SvgLoading = props => (
  <svg
    className="lds-spinner"
    width={200}
    height={200}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{ background: "0 0" }}
    {...props}
  >
    <rect x={46} y={5} rx={41.4} ry={4.5} width={8} height={20} fill="#278ea7">
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-1.1916666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-1.0833333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.9750000000000001s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.8666666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.7583333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.65s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.5416666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.43333333333333335s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.325s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.21666666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="-0.10833333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x={46}
      y={5}
      rx={41.4}
      ry={4.5}
      width={8}
      height={20}
      fill="#278ea7"
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1.3s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </svg>
);

export default SvgLoading;
