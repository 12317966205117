import * as React from "react";
import { IRUniversity } from "@app/api/unis/helper-schemas";
import memoizeOne from "memoize-one";
import Paginator from "../Widgets/Paginator";
import ProgramCard from "../Programs/ProgramCard";
import { animateWindowScroll } from "@app/commonJavascript";
import { History } from "history";
import { IRProgram } from "@app/api/programs/helper-schemas";
import "./styles/programList.min.css";
import { sortProgramsByCoeff } from "./search/searchAlgorithms";

interface IProgramListByIdsProps {
	history: History;
	programIds: number[];
	unis: IRUniversity[];
	programs: IRProgram[];
}

class ProgramListByIds extends React.Component<IProgramListByIdsProps> {
	uniIdToUni: { [key: string]: IRUniversity };

	constructor(props: IProgramListByIdsProps) {
		super(props);
		this.uniIdToUni = {};
		props.unis.forEach(uni => {
			this.uniIdToUni[uni.id] = uni;
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.props.programIds.map(programId => {
					const program = this.props.programs.find(
						prog => prog.id === +programId
					);
					if (!program) return null;
					if (!this.uniIdToUni[program.uni_id]) return null;
					return (
						<ProgramCard
							key={program.id}
							history={this.props.history}
							program={program}
							uni={this.uniIdToUni[program.uni_id]}
						/>
					);
				})}
			</React.Fragment>
		);
	}
}

interface IProgramListProps {
	history: History;
	unis: IRUniversity[];
	programs: IRProgram[];
	itemsPerPage: number;
}

class ProgramList extends React.Component<IProgramListProps> {
	static defaultProps = {
		itemsPerPage: 100,
	};

	listContainerRef?: HTMLDivElement;

	state = {
		page: 0,
	};

	getUnis = memoizeOne((unis: IRUniversity[]): {
		[key: string]: IRUniversity;
	} => {
		const uniIdToUni: { [key: string]: IRUniversity } = {};
		unis.forEach(uni => {
			uniIdToUni[uni.id] = uni;
		});
		return uniIdToUni;
	});

	sortPrograms = memoizeOne((programs: IRProgram[]): IRProgram[] => {
		this.scrollToTop();
		return sortProgramsByCoeff(programs);
	});

	pageSwitch = (page: number) => () => {
		this.setState({ page });
		this.scrollToTop();
	};

	scrollToTop = () => {
		const element = this.listContainerRef;
		if (typeof element === "undefined") return;
		const rect = element.getBoundingClientRect();
		const scrollTop = window.pageYOffset;
		const whereToScrollTop = rect.top + scrollTop - 200;
		animateWindowScroll(whereToScrollTop, 300);
	};

	componentDidMount() {
		this.scrollToTop();
	}

	render() {
		const uniIdToUni = this.getUnis(this.props.unis);
		const programs = this.sortPrograms(this.props.programs).slice(
			this.state.page * this.props.itemsPerPage,
			(1 + this.state.page) * this.props.itemsPerPage
		);
		return (
			<div
				className="programList"
				ref={ref => (this.listContainerRef = ref!)}
			>
				{programs.map(program => {
					if (!uniIdToUni[program.uni_id]) return null;
					return (
						<ProgramCard
							key={program.id}
							history={this.props.history}
							program={program}
							uni={uniIdToUni[program.uni_id]}
						/>
					);
				})}
				<Paginator
					numOfPages={Math.ceil(
						this.props.programs.length / this.props.itemsPerPage
					)}
					currentPage={this.state.page}
					onPageClick={this.pageSwitch}
				/>
			</div>
		);
	}
}

export { ProgramList, ProgramListByIds };
