import { IExamSubjectData, TOUsubjects } from "./data";

export const hasMinimumPassingScores = (
	studentScores: IExamSubjectData[]
): boolean => {
	let hasMinimum = true;
	for (let i = 0; i < studentScores.length; i++) {
		const cur = studentScores[i];
		const subjectData = TOUsubjects.find(e => e.id === cur.subjectId)!;
		if (cur.score < subjectData.minScore) hasMinimum = false;
	}
	return hasMinimum;
};
