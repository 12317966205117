import * as React from "react";
import FullChat from "@chat-app/components/full-chat";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { IChatRootState } from "@chat-app/rootReducer";
import { loadAdminChatrooms } from "@chat-app/socket-functions";

const Container = styled.div`
	width: 100%;
	height: calc(100vh - 100px);
	background-color: #0f0e17;
`;

interface IOwnProps {
	uniId: string;
	uniPicture: string;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

class AdminChatComponent extends React.Component<IProps> {
	render() {
		return (
			<Container>
				<FullChat
					userId={"uni" + this.props.uniId}
					uniPicture={this.props.uniPicture}
				/>
			</Container>
		);
	}

	componentWillReceiveProps(nextProps) {
		if (
			!this.props.lastLoadTime ||
			this.props.lastLoadTime.getTime() !==
				nextProps.lastLoadTime.getTime()
		) {
			loadAdminChatrooms({ type: "uni", id: +this.props.uniId });
		}
	}

	componentDidMount() {
		loadAdminChatrooms({ type: "uni", id: +this.props.uniId });
	}
}

const mapStateToProps = (state: IChatRootState) => ({
	lastLoadTime: state.chatrooms.lastUpdate,
});

export default connect<IStateProps, any, IOwnProps>(mapStateToProps)(
	AdminChatComponent
);
