import * as React from "react";
import Header from "@app/components/Header";
import LoginPopup from "@app/components/LoginPopup";
import { connect } from "react-redux";
import { History, Path } from "history";
import { IRootState } from "@app/reducers/root";
import { Redirect, Route } from "react-router-dom";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const UserRoute = ({
	component: Component,
	isAuthenticated,
	location,
	user,
	...rest
}: IProps) => {
	return (
		<Route
			location={location}
			{...rest}
			render={props =>
				user.userData ? (
					<div>
						<div className="user-page">
							<Header history={props.history} />
							<div className="user-page-without-header">
								<Component
									{...{ ...rest, ...props }}
									userData={user.userData}
									history={props.history}
								/>
							</div>
						</div>
					</div>
				) : !isAuthenticated ? (
					<LoginPopup onClose={() => window.location.reload()} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	UserRoute
);
