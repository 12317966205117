// import areIntlLocalesSupported from "intl-locales-supported";
import { getUnisAndPrograms } from "@app/actions/unis";
import { mergeRecursive } from "@app/utils/common";
import { IAnyObj } from "@app/utils/generics";

// (window as any).areIntlLocalesSupported = areIntlLocalesSupported;

export async function loadLocale(
	locale: "ka" | "en",
	specialFlags: string[] | null
): Promise<{ messages: IAnyObj }> {
	const promises: Promise<any>[] = [loadJSONs(locale, null)];
	// getUnisAndPrograms(true);
	if (specialFlags) {
		for (const specialFlag of specialFlags) {
			promises.push(loadJSONs(locale, specialFlag));
		}
	}
	return Promise.all(promises).then(messagesArr => ({
		messages: messagesArr.reduce(
			(prev, current) => mergeRecursive(prev, current),
			{}
		),
	}));
}

export function readTextFile(fileUrl: string) {
	return new Promise<string>((resolve, reject) => {
		const rawFile = new XMLHttpRequest();
		rawFile.overrideMimeType("application/json");
		rawFile.open("GET", fileUrl, true);
		rawFile.onreadystatechange = function() {
			if (rawFile.readyState === 4) {
				if (rawFile.status >= 200 && rawFile.status < 300) {
					resolve(rawFile.responseText);
				}
				if (rawFile.status >= 300) {
					reject();
				}
			}
		};
		rawFile.send(null);
	});
}

const version = 4;

const loadJSONs = async (locale: string, specialFlag: string | null) => {
	const file = !specialFlag ? "index.json" : `${specialFlag}.json`;
	return flattenObject(
		JSON.parse(
			await readTextFile(`/locales/${locale}/${file}?v=${version}`)
		)
	);
};

const flattenObject = (obj: IAnyObj, separator = ".", prefix = "") => {
	const newObj: IAnyObj = {};
	for (const key in obj) {
		const value = obj[key];
		if (
			typeof value === "object" &&
			value !== null &&
			!Array.isArray(value)
		) {
			const flatValue = flattenObject(value, separator, prefix);
			for (const subKey in flatValue) {
				newObj[prefix + key + separator + subKey] = flatValue[subKey];
			}
		} else {
			newObj[prefix + key] = value;
		}
	}
	return newObj;
};
