import * as React from "react";
import triggerEvent from "@app/utils/events";
import { _console } from "@app/commonJavascript";
import { connect } from "react-redux";
import { History } from "history";
import { ILoginByToken, loginByToken } from "@app/actions/user";
import { match } from "react-router";

interface IOwnProps {
	history: History;
	match: match<{ murtskuUserId: string; token: string }>;
}

type IStateProps = null;

interface IDispatchProps {
	loginByToken: ILoginByToken;
}

interface IErrorType {
	mobile?: boolean;
	password?: boolean;
}

interface IState {
	mobile: string;
	password: string;
	signLoading: boolean;
	errors: IErrorType;
}

type IProps = IOwnProps & IDispatchProps;

class LoginByTokenPage extends React.Component<IProps> {
	_isMounted: boolean;
	state = {
		mobile: "",
		password: "",
		signLoading: false,
		errors: {},
	} as IState;
	constructor(props: IProps) {
		super(props);
		localStorage.removeItem("credentials");
	}
	componentDidMount() {
		this._isMounted = true;
		localStorage.clear();
		this.onLoginClick();
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	onLoginClick = () => {
		this.props
			.loginByToken({
				murtskuUserId: +this.props.match.params.murtskuUserId,
				token: this.props.match.params.token,
			})
			.then(() => {
				const urlParams = new URLSearchParams(window.location.search);
				const redirectTo = urlParams.get("redirect") || "";
				this.props.history.replace("/" + redirectTo);
				triggerEvent({
					category: "Redirect",
					action: "Login",
					label: "from Murtsku",
				});
			})
			.catch(err => {
				_console.error(err);
			});
	};
	render() {
		return <div>დაელოდეთ...</div>;
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	null,
	({
		loginByToken,
	} as any) as IDispatchProps
)(LoginByTokenPage);
