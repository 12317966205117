import { IGalleryMedia } from "@app/api/helpher-schemas";
import { IUniContent } from "@app/api/unis/helper-schemas";
import {
	extractVideoIdFromYoutubeLink,
	getGalleryMediaUrl,
	getNewsOrEventMediaUrl,
} from "@app/commonJavascript";
import Lightbox from "lightbox-react";
import memoizeOne from "memoize-one";
import * as React from "react";
import YouTube from "react-youtube";

interface IProps {
	type: "UnisAndPrograms" | "NewsAndEvents";
	galleryMedias: IUniContent["galleryMedias"];
	onClose: () => void;
	defaultMediaIndex?: number;
}

interface IState {
	mediaIndex: number;
}

class GalleryLightbox extends React.Component<IProps> {
	state: IState = {
		mediaIndex: this.props.defaultMediaIndex || 0,
	};

	videoTargets: any[] = [];

	getMedias = memoizeOne((galleryMedias: IGalleryMedia[]) => {
		return galleryMedias.map((media, index) => {
			if (media.type === "PHOTO") {
				if (this.props.type === "UnisAndPrograms")
					return getGalleryMediaUrl(media.type, media.url);
				return getNewsOrEventMediaUrl(media.type, media.url);
			}
			return (
				<YoutubeVideoInLightbox
					key={media.url}
					videoUrl={media.url}
					onClose={this.props.onClose}
					onReady={event => {
						this.videoTargets[index] = event.target;
						if (index !== this.state.mediaIndex) {
							event.target.pauseVideo();
						} else {
							event.target.playVideo();
						}
					}}
				/>
			);
		});
	});

	onMovePrevRequest = () => {
		const newIndex =
			(this.state.mediaIndex + this.props.galleryMedias!.length - 1) %
			this.props.galleryMedias!.length;
		this.setState({
			mediaIndex: newIndex,
		});
		this.pauseAllVideosExcept(newIndex);
	};

	onMoveNextRequest = () => {
		const { mediaIndex } = this.state;
		const newIndex = (mediaIndex + 1) % this.props.galleryMedias!.length;
		this.setState({
			mediaIndex: newIndex,
		});
		this.pauseAllVideosExcept(newIndex);
	};

	pauseAllVideosExcept = (index: number) => {
		this.videoTargets.forEach((el, i) => {
			if (i === index) {
				el.playVideo();
				return;
			}
			el.pauseVideo();
		});
	};

	render() {
		if (!this.props.galleryMedias) return null;
		const { mediaIndex } = this.state;
		const { galleryMedias } = this.props;
		const medias = this.getMedias(this.props.galleryMedias);
		const nextIndex = (mediaIndex + 1) % galleryMedias.length;
		const prevIndex =
			(mediaIndex + galleryMedias.length - 1) % galleryMedias.length;
		return (
			<Lightbox
				mainSrc={medias[mediaIndex] as any}
				nextSrc={
					medias.length > 2
						? (medias[nextIndex] as any)
						: medias.length === 1
						? undefined
						: mediaIndex === 0
						? (medias[nextIndex] as any)
						: undefined
				}
				prevSrc={
					medias.length > 2
						? (medias[prevIndex] as any)
						: medias.length === 1
						? undefined
						: mediaIndex === 1
						? (medias[prevIndex] as any)
						: undefined
				}
				onCloseRequest={this.props.onClose}
				onMovePrevRequest={this.onMovePrevRequest}
				onMoveNextRequest={this.onMoveNextRequest}
			/>
		);
	}
}

export default GalleryLightbox;

interface IYoutubeVideoInLightboxProps {
	videoUrl: string;
	onClose: () => void;
	onReady?: (event: any) => void;
}

const YoutubeVideoInLightbox: React.FC<IYoutubeVideoInLightboxProps> = props => (
	<div
		className="youtubeVideoPopupContainer"
		onClick={e => {
			if (e.target === e.currentTarget) props.onClose();
		}}
	>
		<div className="box">
			<YouTube
				videoId={extractVideoIdFromYoutubeLink(props.videoUrl)}
				onReady={props.onReady}
			/>
		</div>
	</div>
);
